import React, { ReactElement, useEffect, useState, useCallback } from 'react';
import { useZccService } from 'utils/hooks';
import { ZccPractitionerType } from 'apis/zcc';
import { PractitionerType } from 'apis/practitioner';
import { ZCCApi, PractitionerApi } from 'apis';
import { SearchField } from 'components';
import PersonaCard from './PersonaCard';
import { useStyles } from './PractitionerSearch.styles';
import { XIcon } from '@zeel-dev/ui-icons';
import { grey5 } from '@zeel-dev/ui-styles';
import { AddressType } from 'apis/medical';
import { ZRefAddressType } from 'apis/zref';
import { joinString } from 'utils/helper';

// TODO: use Rollup in ui-molecules to auto-export types
type SearchResultItem = { id: string; value: string; index?: number };

export type PractitionerSearchProps = {
  showBorder?: boolean;
  error?: string;
  npi?: string;
  requestedLocation?: ZRefAddressType;
  selectNewLocation?: boolean;
  onMatchAddressChange: (address?: AddressType, needConfirm?: boolean) => void;
  onPractitionerSelected: (practitioner?: PractitionerType) => void;
};

export default function PractitionerSearch(props: PractitionerSearchProps): ReactElement {
  const [, setPractitionerList] = useState<ZccPractitionerType[]>([]);
  const [searchResults, setSearchResults] = useState<SearchResultItem[]>();
  const [searchText, setSearchText] = useState(props.npi || '');
  const [autoSelectFirst, setAutoSelectFirst] = useState<boolean>();
  const [selectedPractitionerText, setSelectedPractitionerText] = useState('');
  const [selectedPractitioner, setSelectedPractitioner] = useState<PractitionerType>();
  const [isFocused, setIsFocused] = useState(false);
  const service = useZccService<ZccPractitionerType>(ZCCApi.fetchPractitioners, {
    initialParameters: {
      pageSize: 5,
      filters: {
        npi: props.npi,
      },
    },
    bypassUrlRewrite: true,
    fetchOnMount: !!props.npi,
  });
  const classes = useStyles();

  const debounceCallback = useCallback(
    (value: string) => {
      if (value.length > 0) {
        const parsedNPI = parseInt(value);
        if (!isNaN(parsedNPI)) {
          service.setFilters({ npi: value });
          service.refresh();
        }
      } else {
        setSearchResults(undefined);
        if (autoSelectFirst) {
          setSearchText('');
        }
      }
    },
    [service, autoSelectFirst]
  );

  const handleOnChange = (value: string) => {
    setSearchResults(value.length > 0 ? [] : undefined);
    setSearchText(value);
  };

  const handlePractitionerSelected = async (practitioner?: SearchResultItem) => {
    if (practitioner && practitioner.index != undefined) {
      let fullPractitioner;
      try {
        fullPractitioner = await PractitionerApi.getPractitioner(practitioner.id);
      } catch (e) {
        console.log(e);
      }
      props.onPractitionerSelected(fullPractitioner);

      setSelectedPractitioner(fullPractitioner);
      setSelectedPractitionerText(fullPractitioner?.npi + ' ' + fullPractitioner?.flattenName);
      setIsFocused(false);
    }
  };

  // if npi is provided, trigger search and prefill if possible
  useEffect(() => {
    if (props.npi) {
      setAutoSelectFirst(true);
    }
  }, []);

  useEffect(() => {
    const handleData = async () => {
      if (service.data && service.data.length > 0) {
        const results: SearchResultItem[] = [];
        service.data.forEach((practitioner, index) => {
          results.push({
            id: practitioner._id,
            value: practitioner.npi + ' ' + practitioner.practitioner_name,
            index,
          });
        });
        setSearchResults(results);
        setPractitionerList(service.data);

        if (autoSelectFirst) {
          if (service.data.length === 1) {
            let fullPractitioner;
            try {
              fullPractitioner = await PractitionerApi.getPractitioner(service.data[0]._id);
            } catch (e) {
              console.log(e);
            }
            props.onPractitionerSelected(fullPractitioner);
            setSelectedPractitioner(fullPractitioner);
            setSelectedPractitionerText(fullPractitioner?.npi + ' ' + fullPractitioner?.flattenName);
            setIsFocused(false);
          } else {
            if (autoSelectFirst) {
              setSearchText('');
            }
          }
          setAutoSelectFirst(false);
        }
      } else {
        setSearchResults(searchText.length > 0 ? [] : undefined);
        setPractitionerList([]);

        if (autoSelectFirst) {
          setSearchText('');
        }
      }
    };

    handleData();
  }, [service.data]);

  return (
    <div>
      <SearchField
        searchResults={selectedPractitioner || service.loading || !isFocused ? undefined : searchResults}
        searching={service.loading}
        label={'Provider'}
        placeholder={"Start typing a provider's NPI..."}
        disabled={!!selectedPractitioner}
        hideClearButton={!!selectedPractitioner}
        errorMessage={!selectedPractitioner ? props.error : ''}
        onChange={handleOnChange}
        debounceCallback={debounceCallback}
        onItemSelect={handlePractitionerSelected}
        value={selectedPractitioner ? selectedPractitionerText : searchText}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setTimeout(() => setIsFocused(false), 200)}
        required
      />
      {selectedPractitioner && (
        <div className={classes.personaCardWrapper}>
          <PersonaCard
            selectNewLocation={props.selectNewLocation}
            npi={selectedPractitioner.npi}
            name={selectedPractitioner.flattenName}
            phone={selectedPractitioner.phone}
            email={selectedPractitioner.email}
            officeLocations={selectedPractitioner.office_based_address}
            requestedLocation={props.requestedLocation}
            showBorder={props.showBorder}
            error={props.error}
            removeIcon={<XIcon size={'18px'} color={grey5} />}
            onRemoveClicked={() => {
              setSelectedPractitioner(undefined);
              props.onPractitionerSelected();
            }}
            onMatchAddressChange={props.onMatchAddressChange}
          />
          {props.error && <p className={classes.errorMessage}>{props.error}</p>}
        </div>
      )}
      {!props.selectNewLocation && (
        <>
          {props.requestedLocation && (
            <>
              <p className={classes.officeAddressLabel}>Provider&apos;s office address:</p>{' '}
              <p className={classes.officeAddress}>
                {joinString([props.requestedLocation.line_1, props.requestedLocation.line_2], ', ')}
              </p>
              <p className={classes.officeAddress}>
                {joinString(
                  [props.requestedLocation.locality, props.requestedLocation.region, props.requestedLocation.postal],
                  ', '
                )}
              </p>
            </>
          )}
          {!props.requestedLocation && (
            <p className={classes.errorMessage}>
              No provider office address was found on the referral. Please manually check the address on the referral.
            </p>
          )}
          <p className={classes.officeNote}>
            If the address on the referral does not match an existing approved office for this provider, please pause on
            adding this referral and contact Trust & Safety.
          </p>
        </>
      )}
    </div>
  );
}
