import { FC, useCallback } from 'react';
import { Modal } from '../templates';
import { RFSSnoozeProps } from './RFSSnooze.type';
import styles from './style.module.scss';
import { useUIStore } from 'utils/hooks';
import moment from 'moment';

const RFSSnooze: FC<RFSSnoozeProps> = ({ onClose, modalProps }) => {
  const { openModal } = useUIStore();

  const handleSnoozeUntil = useCallback(() => {
    openModal({
      id: 'pick-calendar-date',
      props: {
        title: 'Snooze RFS Until...',
        description: `We'll re-surface this RFS in the Task list for uploading on this date.`,
        buttonTitle: 'Snooze RFS',
      },
      callback: (date?: Date) => {
        if (date) {
          onClose?.({ approve: true, holdUntilDate: moment(date).format('YYYY-MM-DD') });
        }
      },
    });
  }, [openModal]);

  return (
    <Modal
      {...modalProps}
      className={styles.base}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      title='When should this RFS be submitted?'
      actions={[
        {
          label: 'Submit Immediately',
          state: 'secondary',
          onClick: () => onClose?.({ approve: true }),
        },
        {
          label: 'Snooze Until...',
          state: 'primary',
          onClick: handleSnoozeUntil,
        },
      ]}></Modal>
  );
};

export default RFSSnooze;
