import api from 'apis';
import qs from 'qs';

export const getAuthToken = async (): Promise<any> => {
  const data = qs.stringify({
    grant_type: 'client_credentials',
    client_id: process.env.REACT_APP_ALTERYX_API_KEY,
    client_secret: process.env.REACT_APP_ALTERYX_API_SECRET,
  });
  const response = await api.post({
    path: `/oauth2/token`,
    version: 'alteryx',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  });

  return response['access_token'];
};

export const runConsentFormWorkflow = async (token: string, memberId: string): Promise<any> => {
  const response = await api.post({
    path: `/v1/workflows/${process.env.REACT_APP_ALTERYX_CONSENT_WEBFLOW_ID}/jobs`,
    version: 'alteryx',
    auth: `Bearer ${token}`,
    data: {
      questions: [
        {
          name: memberId,
          value: '',
        },
      ],
      priority: '0',
    },
  });

  return response;
};
