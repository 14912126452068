import React, { useState, useEffect } from 'react';
import { PatientType } from 'apis/medical';
import PatientInformation from './PatientInformation';
import CaregiverInformation from './CaregiverInformation';
import HoldInformation from './HoldInformation';
import { PatientFormProps } from './PatientForm.types';

export default function PatientForm({ patient: propsPatient, collapsible, editable, onSavePatient }: PatientFormProps) {
  const [patient, setPatient] = useState(propsPatient);
  const [editingPatient, setEditingPatient] = useState(false);
  // const [editCaregiver, setEditCaregiver] = useState(false);

  useEffect(() => {
    setPatient(propsPatient);
    setEditingPatient(false);
    // setEditCaregiver(false);
  }, [propsPatient]);

  const handleSavePatient = (updatedPatient?: PatientType) => {
    setEditingPatient(false);
    if (updatedPatient) {
      setPatient(updatedPatient);
      onSavePatient?.();
    }
  };

  // TODO: Implement Edit Caregiver
  // const handleSaveCaregiver = (updatedPatient?: PatientType) => {
  //   setEditCaregiver(false);
  //   if (updatedPatient) {
  //     setPatient(updatedPatient);
  //   }
  // };

  return (
    <>
      <PatientInformation
        patient={patient}
        editing={editable && editingPatient}
        collapsible={collapsible}
        onEdit={() => setEditingPatient(true)}
        onUndo={() => setEditingPatient(false)}
        onError={() => setEditingPatient(true)}
        onSave={handleSavePatient}
      />
      <CaregiverInformation
        patient={patient}
        collapsible={collapsible}
        // edit={editable && editCaregiver}
        // onEdit={() => setEditCaregiver(true)}
        // onUndo={() => setEditCaregiver(false)}
        // onError={() => setEditCaregiver(true)}
        // onSave={handleSaveCaregiver}
      />
      {patient?.hold_status && <HoldInformation patient={patient} collapsible={collapsible} />}
    </>
  );
}
