import { useState } from 'react';

import Segments from 'components/Segments';
import Modal from '../templates/Modal';
import styles from './style.module.scss';
import { SwitchToInHomeReferralProps } from './SwitchToInHomeReferral.types';
import { useUIStore } from 'utils/hooks';
import { MedicalApi } from 'apis';

export default function SwitchToInHomeReferral({
  serviceRequestId,
  scrollTop,
  onClose,
  modalProps,
}: SwitchToInHomeReferralProps) {
  const { openAlert } = useUIStore();
  const [error, setError] = useState<string>();
  const [createNew, setCreateNew] = useState('yes');

  const handleSubmit = async () => {
    try {
      await MedicalApi.updateServiceRequest(serviceRequestId, {
        office_based_referral: false,
      });
      openAlert({
        title: 'All appointments created from this point forward will be in-home.',
      });
      onClose?.(createNew === 'yes');
    } catch (e) {
      setError('An error occurred while updating the referral');
      scrollTop();
      console.error(e);
    }
  };

  return (
    <Modal
      {...modalProps}
      alert={error}
      title='Switch to In-Home Referral'
      description={
        // eslint-disable-next-line max-len
        'This referral will be switched to in-home for new appointments created from this point forward. Any existing appointments will remain as they were.'
      }
      className={styles.base}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={handleSubmit}
      actions={[
        {
          label: 'Switch to In-Home',
          action: 'submit',
        },
      ]}>
      <Segments
        name='createNew'
        value={createNew}
        onChange={setCreateNew}
        label='Create a new in-home appointment now?'
        items={[
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ]}
        subText={createNew === 'yes' ? "You'll be guided through creating a new appointment for this patient." : ''}
      />
    </Modal>
  );
}
