import React, { MouseEventHandler, ReactElement } from 'react';
import { useStyles } from './Expandable.styles';
import classNames from 'classnames';

export type AccordionProps = {
  expanded?: boolean;
  children?: ReactElement | string;
  collapsedHeight?: string;
  expandedHeight?: string;
  footerText?: string;
  scrollWhileCollapsed?: boolean;
  footerGradient?: boolean;
  onFooterClicked?: MouseEventHandler;
};

export default function Expandable(props: AccordionProps): ReactElement {
  const classes = useStyles(props);

  return (
    <div className={classes.expandableWrapper}>
      {props.children}
      {props.footerText && (
        <div
          className={classNames(classes.expandableFooter, {
            [classes.expandableFooterClickable]: !!props.onFooterClicked,
          })}
          onClick={props.onFooterClicked}>
          <div className={classes.expandableFooterText}>{props.footerText}</div>
        </div>
      )}
    </div>
  );
}
