import React from 'react';
import cn from 'classnames';
import { useState, useDimensions } from 'utils/hooks';
import Icon from '../Icon';
import styles from './style.module.scss';

export type ToggleSectionPropType = {
  icon?: string;
  iconColor?: string;
  title: any;
  value?: boolean; // to control value
  defaultValue?: boolean;
  onChange?: (value?: boolean) => void; // when controlled value, on change handler
  children?: any;
  className?: string;
  contentClassName?: string;
  borders?: boolean;
  level?: number;
  animate?: boolean; // won't work if children size changes dynamically
  id?: string;
  disabled?: boolean;
};

export default function ToggleSection({
  icon,
  iconColor,
  title,
  value,
  onChange,
  defaultValue = false,
  children,
  className,
  contentClassName,
  borders,
  level,
  animate,
  id,
  disabled,
}: ToggleSectionPropType) {
  const isControlled = value !== null && value !== undefined;
  const [toggled, setToggled] = useState(isControlled ? value : defaultValue);
  const [ref, dimensions]: any = useDimensions();
  const levelValue = (level || 0) * 16;
  const toggledValue = isControlled ? value : toggled;

  const onToggle = () => {
    const newValue = isControlled ? (value ? false : true) : toggled ? false : true;
    setToggled(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div id={id} className={cn(styles.base, { [styles.disabled]: disabled }, className)}>
      <div
        className={cn(styles.header, { [styles['header--borders']]: borders })}
        style={{ paddingLeft: `${levelValue}px`, paddingRight: `${levelValue}px` }}
        onClick={onToggle}>
        <p className={styles.title} style={{ fontSize: levelValue > 0 ? '18px' : '20px' }}>
          {icon && <Icon name={icon} color={iconColor} size={25} className={styles.prefixIcon} />}
          {title}
        </p>
        <Icon
          name='caret-down'
          className={cn(styles.icon, { [styles.iconAnimate]: animate, [styles.iconRotate]: toggledValue })}
        />
      </div>
      <div
        className={cn(styles.content, contentClassName, { [styles['content--borders']]: toggledValue && borders })}
        style={{
          maxHeight: toggledValue ? (animate ? `${dimensions?.height || 0}px` : '100000px') : '0px',
          transitionDuration: animate ? '0.2s' : '0s',
          paddingLeft: `${levelValue}px`,
          paddingRight: `${levelValue}px`,
        }}>
        <div ref={ref}>{children}</div>
      </div>
    </div>
  );
}
