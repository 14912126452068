import React from 'react';
import PatientForm from 'components/forms/Patient';
import { ServiceRequestForm } from 'components/forms/ServiceRequest';
import { TextField, FieldTitle } from 'components';
import Grid from '@mui/material/Grid';
import { ProviderNotesReviewAdditionalInformationProps } from './ProviderNotesReview.types';

function ProviderNotesReviewAdditionalInformation({
  patient,
  serviceRequest,
  provider,
}: ProviderNotesReviewAdditionalInformationProps) {
  const additionalFieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputProps: {
      readOnly: true,
    },
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <>
      {patient && <PatientForm patient={patient} marginBottom />}
      {provider && (
        <Grid container spacing={3} style={{ marginBottom: '24px' }}>
          <Grid item xs={12}>
            <FieldTitle icon='user'>Provider</FieldTitle>
          </Grid>
          <Grid item xs={6}>
            <TextField value={provider?.fname} label='First Name' {...additionalFieldProps} />
          </Grid>
          <Grid item xs={6}>
            <TextField value={provider?.lname} label='Last Name' {...additionalFieldProps} />
          </Grid>
          <Grid item xs={6}>
            <TextField value={provider?.npi} label='NPI' {...additionalFieldProps} />
          </Grid>
        </Grid>
      )}
      {serviceRequest && <ServiceRequestForm serviceRequest={serviceRequest} marginBottom />}
    </>
  );
}

export default ProviderNotesReviewAdditionalInformation;
