import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { ToggleSection } from 'components';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import styles from './style.module.scss';
import { ReadOnlySoapNoteProps } from './ReadOnlySoapNotesModal.types';
import { joinString } from 'utils/helper';

export default function ReadOnlySoapNote({
  previousVersion,
  soapNotes,
  reviewedBy,
  practitioner,
}: ReadOnlySoapNoteProps) {
  const rejectionNotes = useMemo(() => {
    return (
      soapNotes?.rejection_note?.split('\n\n').reduce((acc: Record<string, string>, cur: string) => {
        const [heading, text] = cur.split('\n');
        const title = heading.split(' ')[0].toLowerCase();
        return {
          ...acc,
          [title]: text,
        };
      }, {}) || {}
    );
  }, [soapNotes?.rejection_note]);

  const getPreviousNoteText = (title: string): string | undefined => {
    return previousVersion?.notes?.find((n: any) => n.title === title)?.text;
  };

  return (
    <>
      {soapNotes?.notes.map((note: any) => {
        const { title, text } = note;
        const previousNoteText = getPreviousNoteText(title);
        const rejectionNote = rejectionNotes[title];
        const isApproved = !previousVersion || !rejectionNote || rejectionNote === 'No edits required.';
        const [firstName = '', lastName = ''] = (reviewedBy || '').split(' ');
        const reviewerInitialName = reviewedBy
          ? `${(firstName[0] || '').toUpperCase()}${(lastName[0] || '').toUpperCase()}`
          : '';
        const providerName = joinString(
          [...(practitioner?.name?.[0]?.given || []), practitioner?.name?.[0]?.family],
          ' '
        );

        return (
          <Grid key={title} item xs={12} spacing={0}>
            <Grid container>
              <ToggleSection
                icon={isApproved ? 'checkmark-circle' : 'flag'}
                iconColor={isApproved ? 'lush' : 'orange'}
                title={capitalize(title)}
                defaultValue={true}>
                {previousVersion && !isApproved && (
                  <>
                    <div className={styles.previousVersionContainer}>
                      <p className={styles.versionLabel}>
                        <span>Version {previousVersion.submission_count || 0}:</span>&nbsp;Submitted{' '}
                        {moment(previousVersion.date_submitted || previousVersion.meta?.lastUpdated).format('LLL')}
                      </p>
                      <p className={styles.previousVersion}>{previousNoteText}</p>
                      <div className={styles.rejectContainer}>
                        <div className={styles.avatar}>{reviewerInitialName}</div>
                        <div>
                          <p className={styles.rejectHeader}>
                            {reviewedBy && <span className={styles.reviewerName}>{reviewedBy}&nbsp;</span>}
                            {joinString([
                              providerName ? `to ${providerName}` : '',
                              soapNotes?.date_submitted ? moment(soapNotes?.date_submitted).format('LLL') : '',
                            ])}
                          </p>
                          <p className={styles.rejectionNote}>{rejectionNote}</p>
                        </div>
                      </div>
                    </div>
                    <p className={styles.versionLabel}>
                      <span>Version {soapNotes?.submission_count}:</span>&nbsp;Submitted{' '}
                      {moment(soapNotes.date_submitted || soapNotes.meta?.lastUpdated).format('LLL')}
                    </p>
                  </>
                )}
                <p className={styles.note}>{text}</p>
              </ToggleSection>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}
