import React, { useMemo } from 'react';
import _ from 'lodash';
import { useState, useDispatch, useZccGraphQLService } from 'utils/hooks';
import { UIStore } from 'store';
import { SectionLayout, Table, IconMenu, FilterButtonV2 } from 'components';
import PatientSidePanel from 'components/sidePanels/PatientGraphQL';
import styles from './style.module.scss';

import { Patient, useGetPatientsLazyQuery } from 'generated/graphql';

// enum PatientActions {
//   UploadConsent = 'upload_consent',
// }

export default function PatientsGraphQL() {
  const [fetchPatients, { data, error, loading }] = useGetPatientsLazyQuery();

  const service = useZccGraphQLService('patient', (parameters: any) => {
    return fetchPatients({
      variables: {
        ...parameters.filters,
        page: parameters.page,
        pageSize: parameters.pageSize,
      },
    });
  });

  const [open, setOpen] = useState<boolean>(false);
  const [activeRowData, setActiveRowData] = useState<any>(null);
  const [highlightedRowId, setHighlightedRowId] = useState<string | null | undefined>();
  const dispatch = useDispatch();

  const rowClicked = (row: any) => {
    const { id } = row || {};
    if (open && activeRowData && id === activeRowData?.id) setOpen(false);
    else {
      setOpen(true);
      setActiveRowData(row);
    }
  };

  const sortChangeHandler = (sortModel: any) => {
    const sortBy: any = {};
    sortModel?.forEach(({ field, sort }: any) => {
      sortBy[field] = sort;
    });
    if (!_.isEqual(service.parameters.sortBy, sortBy)) {
      service.setSortBy(sortBy);
    }
  };

  const highlightRow = (rowId: string) => {
    setHighlightedRowId(rowId);
    setTimeout(() => setHighlightedRowId(null), 5000);
  };

  // Adding appointments and notes
  const rows: Array<any> = useMemo(() => {
    data?.patient;
    const r = [
      ...(data?.patient || []).map((item: any, index: number) => {
        const { id, name, icn, phone, last4, address, birthDate, gender, zeelId, memberId } = item as Patient;

        return {
          id: index,
          entity_id: id,
          address,
          birth_date: birthDate,
          gender,
          icn,
          memberId,
          patient_name: [...(name?.[0]?.given ?? []), name?.[0]?.family].join(' '),
          phone,
          last4,
          zeelId,
          actions: [],
          data: item,
        };
      }),
    ];
    return r;
  }, [data]);

  // Actions
  const openUploadConsentModal = (patientId: string) => {
    dispatch(
      UIStore.openModal({
        id: 'upload-consent',
        props: {
          patientId,
        },
        callback: (response: any) => {
          if (response) {
            service.refresh();
          }
        },
      })
    );
  };

  const columns = useMemo(
    () => [
      { id: 'entity_id', label: 'Patient ID', width: 150 },
      { id: 'patient_name', label: 'Patient Name', width: 300 },
      { id: 'icn', label: 'ICN', width: 250 },
      { id: 'phone', label: 'Phone #', width: 200 },
      { id: 'last4', label: 'SSN (last 4)', width: 150 },
      {
        id: 'action',
        label: 'Actions',
        width: 500,
        sortable: false,
        renderCell: ({ row }: any) => {
          const actionItems = [
            { icon: 'upload', label: 'Upload Consent', onClick: () => openUploadConsentModal(row?.entity_id) },
          ];

          return <IconMenu items={actionItems} />;
        },
      },
    ],
    []
  );

  const sideContent = (
    <PatientSidePanel
      open={open}
      onClose={() => setOpen(false)}
      id={activeRowData?.entity_id}
      onChange={() => {
        highlightRow(activeRowData?.id);
        service.refresh();
      }}
    />
  );

  return (
    <SectionLayout alert={error?.message} title='Patients' sideContent={sideContent}>
      <div className={styles.base}>
        <div className={styles.tableHeader}>
          <FilterButtonV2
            initialValue={service.parameters.filters}
            onChange={(newFilters: any) => {
              service.setFilters(newFilters);
            }}
            filters={[
              { id: 'id', label: 'Patient ID', type: 'text', size: 'half' },
              { id: 'name', label: 'Patient Name', type: 'text', size: 'half' },
            ]}
          />
        </div>
        <Table
          loading={loading}
          name={`${rows.length} Patients`}
          columns={columns}
          rows={rows}
          onRowClick={(row: any) => rowClicked(row)}
          activeRowId={open ? activeRowData?.rowId : undefined}
          highlightedRowId={highlightedRowId}
          page={service.parameters.page}
          pageSize={service.parameters.pageSize}
          serverPagination
          serverRowCount={10000}
          onPageChange={(page: any) => service.setPage(page)}
          onPageSizeChange={(pageSize: any) => service.setPageSize(pageSize)}
          rowsPerPageOptions={[20]}
          onSortModelChange={sortChangeHandler}
        />
      </div>
    </SectionLayout>
  );
}
