import React from 'react';
import Modal from '../templates/Modal';
import Grid from '@mui/material/Grid';
import LoadingBar from 'react-redux-loading-bar';
import { Icon } from 'components';
import styles from './style.module.scss';
import { CreatingPDFProps } from './CreatingPDF.types';

export default function CreatingPDF({ filename, onClose, modalProps }: CreatingPDFProps) {
  return (
    <Modal
      {...modalProps}
      title='Creating PDF'
      description={
        <>
          This may take a minute...
          <br />
          Feel free to close this window. You’ll get a notification when the file is ready.
        </>
      }
      className={styles.base}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={() => onClose?.()}>
      <Grid className={styles.section} container>
        <Icon name='notes' size={24} />
        {filename}
        <LoadingBar
          updateTime={200}
          maxProgress={95}
          progressIncrease={5}
          scope='creatingPDFBar'
          className={styles.loading}
        />
      </Grid>
    </Modal>
  );
}
