import React, { useState, useEffect } from 'react';
import Modal from '../templates/Modal';
import styles from './style.module.scss';
import { EvaluationPDFViewProps } from './EvaluationPDFView.types';
import { TemplateApi } from 'apis';
import { helper } from 'utils';
import { PDFView } from 'components';

export default function EvaluationPDFView({ appointmentId, onClose, modalProps }: EvaluationPDFViewProps) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [base64String, setBase64String] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseBlob = await TemplateApi.getEvaluationDocumentByAppointmentId(appointmentId);
        const base64 = await helper.blobToBase64(responseBlob);
        setBase64String(base64);
      } catch (e) {
        setError('An error occurred while loading the evaluation pdf');
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Modal
      {...modalProps}
      loading={loading}
      alert={error}
      actions={[]}
      title={loading ? 'Evaluation' : ''}
      className={styles.base}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={() => onClose?.()}>
      {!loading && !error && <PDFView file={base64String} />}
    </Modal>
  );
}
