import React, { FC, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { pdfjs } from 'react-pdf';
import { PDFViewProps } from './PDFView.types';
import { CaretBoldLeftIcon, CaretBoldRightIcon, Group } from '@zeel-dev/zeel-ui';
import classNames from 'classnames';
import styles from './style.module.scss';

const PDFView: FC<PDFViewProps> = ({ file, width = 960, showPagination }) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = (pdf: pdfjs.PDFDocumentProxy) => {
    setNumPages(pdf.numPages);
  };

  const disablePrev = pageNumber === 1;
  const disableNext = pageNumber === numPages;

  return (
    <Document file={file} onLoadSuccess={onDocumentLoadSuccess} renderMode='svg' loading=''>
      {!showPagination &&
        Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={width} />
        ))}
      {showPagination && (
        <>
          <Group className={styles.pagination} verticalAlign='center' horizontalGutter={2}>
            <CaretBoldLeftIcon
              className={classNames({
                [styles.disabled]: disablePrev,
              })}
              size={18}
              onClick={() => !disablePrev && setPageNumber(pageNumber - 1)}
              aria-disabled={disablePrev}
            />
            <span>
              {pageNumber} of {numPages}
            </span>
            <CaretBoldRightIcon
              className={classNames({
                [styles.disabled]: disableNext,
              })}
              size={18}
              onClick={() => !disableNext && setPageNumber(pageNumber + 1)}
              aria-disabled={disableNext}
            />
          </Group>
          <Page pageNumber={pageNumber} width={width} />
        </>
      )}
    </Document>
  );
};

export default PDFView;
