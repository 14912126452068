import { FC, useState, SyntheticEvent } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps as MuiAccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import { AccordionProps } from './Accordion.types';
import { grey2 } from '@zeel-dev/ui-styles';
import Icon from '../Icon';
import FieldTitle from '../FieldTitle';

const StyledAccordion = styled((props: MuiAccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `1px solid ${grey2}`,
  borderRadius: 12,
  '&:before': {
    display: 'none',
  },
}));

const StyledAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const Accordion: FC<AccordionProps> = ({ icon, title, expended: isExpended = true, marginBottom, children }) => {
  const [expanded, setExpanded] = useState(isExpended);
  const handleChange = (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <StyledAccordion
      expanded={expanded}
      onChange={handleChange}
      style={{ marginBottom: marginBottom ? (marginBottom === true ? '32px' : marginBottom) : '0' }}>
      <MuiAccordionSummary expandIcon={<Icon name='caret-down' />}>
        <FieldTitle icon={icon}>{title}</FieldTitle>
      </MuiAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
      <Grid container justifyContent='center' paddingBottom={2}>
        <Icon name='caret-up' onClick={() => setExpanded(false)} />
      </Grid>
    </StyledAccordion>
  );
};

export default Accordion;
