import React, { FC } from 'react';
import cn from 'classnames';
import Icon from '../Icon';
import MenuList from '../MenuList';
import Popover from '@mui/material/Popover';
import { IconMenuPropType } from './IconMenu.types';
import styles from './style.module.scss';

const IconMenu: FC<IconMenuPropType> = ({ iconProps, onClick, popoverProps, minWidth, items }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onClickHandler = async (e: any) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    if (onClick && typeof onClick === 'function') {
      await onClick(e);
    }
  };

  const isPopoverOpen = Boolean(anchorEl);
  const popoverId = isPopoverOpen ? 'popover-element' : undefined;

  return (
    <>
      <Icon
        name='dot-menu-vertical'
        size={24}
        onClick={onClickHandler}
        {...(iconProps || {})}
        className={cn(styles.icon, iconProps?.className)}
      />
      <Popover
        {...(popoverProps || {})}
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <MenuList items={items} minWidth={minWidth} onItemClick={() => setAnchorEl(null)} />
      </Popover>
    </>
  );
};

export default IconMenu;
