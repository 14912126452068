import React from 'react';
// import cn from 'classnames';
// import { history, routes } from 'utils';
import { useState /*, useDispatch*/ } from 'utils/hooks';
import PatientForm from 'components/forms/Patient';
import { ServiceRequestForm } from 'components/forms/ServiceRequest';
import Grid from '@mui/material/Grid';
import { useUIStore, useZccSoapNotesQuery } from 'utils/hooks';
import { SidePanel, FieldTitle, TextField, Button } from 'components';
// import styles from './style.module.scss';
import moment from 'moment';
import { CompositionStatusType } from 'apis/medical';
import capitalize from 'lodash/capitalize';

export type NoteSidePanelPropType = {
  open?: boolean;
  onClose?: () => void;
  id: string; // the service request id,
  onAction?: (action: string, data?: any) => void;
};

export default function Note({ open, onClose, id }: NoteSidePanelPropType) {
  const [error, setError] = useState<string | null>();
  const { openModal } = useUIStore();

  const {
    loading,
    soapNotes: note,
    appointment,
    serviceRequest,
    patient,
    reviewedBy,
  } = useZccSoapNotesQuery({
    noteId: id,
    needCompare: false,
    needPractitioner: false,
    onError: (err) => setError(err),
  });

  const close = () => {
    if (onClose) {
      onClose();
    }
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputProps: {
      readOnly: true,
    },
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <SidePanel title='SOAP Note Record' loading={loading} alert={error} open={open} onClose={close}>
      {note && [CompositionStatusType.Approved, CompositionStatusType.Rejected].includes(note.status) && (
        <Grid container justifyContent='space-between'>
          <Grid item>
            <p>Status</p>
            <p>
              {capitalize(note.status)}
              {reviewedBy ? ` by ${reviewedBy}` : ''}
            </p>
            {note.meta?.lastUpdated && <p>{moment(note.meta.lastUpdated).format('LL')}</p>}
          </Grid>
          <Grid>
            <Button
              variant='outlined'
              color='primary'
              size='large'
              disabled={loading}
              onClick={() =>
                openModal({
                  id: 'read-only-soap-notes',
                  props: { noteId: note.id, status: note.status },
                })
              }>
              View SOAP
            </Button>
          </Grid>
        </Grid>
      )}

      {note && (
        <Grid container spacing={3} style={{ marginTop: '16px', marginBottom: '24px' }}>
          <Grid item xs={12}>
            <FieldTitle icon='pad-with-star'>Note Information</FieldTitle>
          </Grid>
          <Grid item xs={6}>
            <TextField value={note?.id} label='ID' {...fieldProps} />
          </Grid>
          <Grid item xs={6}>
            <TextField value={note?.status} label='Status' {...fieldProps} />
          </Grid>
          <Grid item xs={6}>
            <TextField value={note?.title} label='Title' {...fieldProps} />
          </Grid>
          <Grid item xs={6}>
            <TextField value={note?.type} label='Type' {...fieldProps} />
          </Grid>
          <Grid item xs={6}>
            <TextField value={moment(note?.date_submitted).format('LLL')} label='Date Submitted' {...fieldProps} />
          </Grid>
        </Grid>
      )}
      {patient && <PatientForm patient={patient} marginBottom />}
      {appointment?.provider && (
        <Grid container spacing={3} style={{ marginBottom: '24px' }}>
          <Grid item xs={12}>
            <FieldTitle icon='user'>Provider</FieldTitle>
          </Grid>
          <Grid item xs={6}>
            <TextField value={appointment?.provider?.fname} label='First Name' {...fieldProps} />
          </Grid>
          <Grid item xs={6}>
            <TextField value={appointment?.provider?.lname} label='Last Name' {...fieldProps} />
          </Grid>
          <Grid item xs={6}>
            <TextField value={appointment?.provider?.npi} label='NPI' {...fieldProps} />
          </Grid>
        </Grid>
      )}
      {serviceRequest && <ServiceRequestForm serviceRequest={serviceRequest} marginBottom />}
    </SidePanel>
  );
}
