import { OfficeLocationType, OfficeLocationStatusEnum } from 'apis/practitioner';
import React, { ReactElement, useState, useEffect } from 'react';
import { formatPhoneNumber, joinString } from 'utils/helper';
import { useStyles } from './PersonaCard.styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AddressType } from 'apis/medical';
import classNames from 'classnames';
import moment from 'moment';
import { Icon } from 'components';

type PersonaCardProps = {
  name?: string;
  phone?: string;
  email?: string;
  npi?: string;
  showBorder?: boolean;
  error?: string;
  officeLocations?: OfficeLocationType[];
  requestedLocation?: AddressType;
  removeIcon?: ReactElement;
  selectNewLocation?: boolean;
  onMatchAddressChange?: (address?: AddressType, needConfirm?: boolean) => void;
  onRemoveClicked?: () => void;
};

export default function PersonaCard(props: PersonaCardProps): ReactElement {
  const classes = useStyles();
  const [selectedOfficeLocationIndex, setSelectedOfficeLocationIndex] = useState<number>();

  const getAddress = (address?: AddressType) => {
    if (!address) return '';
    return joinString([...(address.line || []), address.city, address.state, address.postal_code], ', ');
  };

  const isOfficeUnavailable = (status: string) => {
    return status === OfficeLocationStatusEnum.PAUSED || status === OfficeLocationStatusEnum.CLOSED;
  };

  useEffect(() => {
    if (!props.officeLocations) {
      return;
    }
    if (!props.requestedLocation) {
      if (props.officeLocations.length) {
        const status = (props.officeLocations[0] as OfficeLocationType).status;
        props.onMatchAddressChange?.(props.officeLocations[0]?.address, isOfficeUnavailable(status));
        setSelectedOfficeLocationIndex(0);
      }
      return;
    }
    const officeIndex = props.officeLocations.findIndex(
      (officeLocation) =>
        getAddress(officeLocation?.address).toLowerCase() === getAddress(props.requestedLocation).toLowerCase()
    );
    if (officeIndex > -1) {
      const status = (props.officeLocations[officeIndex] as OfficeLocationType).status;
      props.onMatchAddressChange?.(props.requestedLocation, isOfficeUnavailable(status));
      setSelectedOfficeLocationIndex(officeIndex);
    } else if (props.officeLocations.length) {
      const status = (props.officeLocations[0] as OfficeLocationType).status;
      props.onMatchAddressChange?.(props.officeLocations[0]?.address, isOfficeUnavailable(status));
      setSelectedOfficeLocationIndex(0);
    }
  }, [props.selectNewLocation]);

  const selectedOffice =
    selectedOfficeLocationIndex !== undefined
      ? (props.officeLocations as OfficeLocationType[])?.[selectedOfficeLocationIndex]
      : null;

  const renderOfficeStatus = (officeLocation?: OfficeLocationType) => {
    if (
      !officeLocation ||
      (officeLocation.status !== OfficeLocationStatusEnum.PAUSED &&
        officeLocation.status !== OfficeLocationStatusEnum.CLOSED)
    ) {
      return null;
    }
    return (
      <>
        <Icon
          className={classNames(officeLocation.status === OfficeLocationStatusEnum.CLOSED && classes.closedMessage)}
          name={officeLocation.status === OfficeLocationStatusEnum.CLOSED ? 'x-circle' : 'calendar'}
          size={16}
        />
        <span
          className={classNames(officeLocation.status === OfficeLocationStatusEnum.CLOSED && classes.closedMessage)}>
          {officeLocation.status === OfficeLocationStatusEnum.CLOSED ? 'Closed' : 'Paused'}
          {officeLocation.paused_to_date ? ` until ${moment(officeLocation.paused_to_date).format('MMM D')}` : ''}
        </span>
      </>
    );
  };

  return (
    <>
      <div
        className={classNames(classes.personaCardWrapper, {
          [classes.border]: props.showBorder,
          [classes.error]: !!props.error,
        })}>
        <div className={classes.cardSection}>
          {props.name && <div className={classes.personaCardName}>{props.name}</div>}
          {props.phone && <div className={classes.personaText}>{formatPhoneNumber(props.phone)}</div>}
          {props.email && <div className={classes.personaText}>{props.email}</div>}
          {props.npi && (
            <div>
              <span className={classes.personaText}>NPI</span>{' '}
              <span className={classes.personaNpiText}>{props.npi}</span>
            </div>
          )}
        </div>
        {props.officeLocations && (
          <div className={classes.addressSection}>
            <RadioGroup
              onChange={(e) => {
                const newIndex = +e.target.value;
                setSelectedOfficeLocationIndex(newIndex);
                props.onMatchAddressChange?.(props.officeLocations?.[newIndex]?.address);
              }}>
              {props.officeLocations.map((officeLocation, index) => (
                <FormControlLabel
                  key={index}
                  value={index}
                  className={classes.officeLocationRadio}
                  control={<Radio checked={selectedOfficeLocationIndex === index} />}
                  label={
                    <div className={classes.officeLocationLabel}>
                      <span className={classes.officeLocation}>{getAddress(officeLocation.address)}</span>
                      <div className={classes.officeStatus}>{renderOfficeStatus(officeLocation)}</div>
                    </div>
                  }
                />
              ))}
            </RadioGroup>
          </div>
        )}
        {props.removeIcon && (
          <button
            type={'button'}
            onClick={() => props.onRemoveClicked && props.onRemoveClicked()}
            className={classes.removeIconWrapper}>
            {props.removeIcon}
          </button>
        )}
      </div>
      {selectedOffice?.status === OfficeLocationStatusEnum.CLOSED && (
        <p className={classNames(classes.statusMessage, classes.closedMessage)}>
          {selectedOffice?.closed_note || 'This provider’s office is closed.'}
        </p>
      )}
      {selectedOffice?.status === OfficeLocationStatusEnum.PAUSED && (
        <p className={classNames(classes.statusMessage, classes.pausedMessage)}>
          {selectedOffice?.paused_to_date
            ? ` This provider’s office is not taking any referrals until ${moment(selectedOffice.paused_to_date).format(
                'dddd, LL'
              )}`
            : ''}
          {selectedOffice?.paused_note ? (
            <>
              {' '}
              <br />
              Reason: {selectedOffice?.paused_note}
            </>
          ) : (
            ''
          )}
        </p>
      )}
    </>
  );
}
