import React from 'react';
import { MedicalApi, DocumentApi } from 'apis';
import { useForm, useUIStore, useState, useEffect } from 'utils/hooks';
import { v4 as uuid } from 'uuid';
import Modal, { ModalPropType } from '../templates/Modal';
import Dropzone from 'react-dropzone';
import NoteAdd from '@mui/icons-material/NoteAdd';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { Icon, TextField } from 'components';
import styles from './style.module.scss';

export type UploadConsentType = ModalPropType & {
  patientId: string;
  serviceRequestId?: string;
};

export default function UploadConsent({
  patientId,
  serviceRequestId,
  onClose,
  scrollTop,
  modalProps,
}: UploadConsentType) {
  const { openAlert } = useUIStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [serviceRequests, setServiceRequests] = useState<any>([]);
  const [files, setFiles] = useState<any>([]);
  const { form, bind } = useForm();

  const fetchData = async () => {
    setLoading(true);
    try {
      const patient = await MedicalApi.fetchPatient(patientId);
      setServiceRequests(patient?.service_requests || []);
    } catch (error) {
      form.setFormError('An error occured while retrieving the service requests');
      console.error(error);
    }
    setLoading(false);
  };

  // fetch data on mount
  useEffect(() => {
    if (patientId) fetchData();
  }, [patientId]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (values: any) => {
    const allFiles = (files || []).map((f: any) => {
      return {
        ...f,
        serviceRequestId: values[`serviceRequestId-${f?.id}`],
      };
    });

    try {
      // uploading all files
      const uploadPromises: Array<any> = [];
      let uploadedFilesUrls: Array<string> = [];

      (allFiles || []).forEach((f: any) => {
        if (f.uploadedUrl) {
          uploadedFilesUrls.push(f.uploadedUrl);
        } else {
          uploadPromises.push(DocumentApi.uploadDocument(f.file as File));
        }
      });

      const uploadedFiles = await Promise.all(uploadPromises);
      const newUploadedFilesUrls = uploadedFiles?.map((f: any) => f?.location);
      uploadedFilesUrls = [...uploadedFilesUrls, ...(newUploadedFilesUrls || [])];

      // updating files list to store documentUrl and avoid multi-upload in case contextualUploadHandler fails
      setFiles(
        files.map((f: any, fIndex: any) => {
          return {
            ...f,
            uploadedUrl: uploadedFilesUrls[fIndex],
          };
        })
      );

      // Create consent objects
      const consentPromises: Array<any> = [];
      (allFiles || []).forEach((f: any, i: any) => {
        const srId = f.serviceRequestId;
        const url = (uploadedFilesUrls || [])[i];
        consentPromises.push(
          MedicalApi.createConsent({
            patientId,
            consentType: 'informed_consent_form',
            serviceRequestId: srId,
            url,
            status: 'active',
          })
        );
      });

      await Promise.all(consentPromises);

      openAlert({
        title: `${uploadedFilesUrls.length} Consent${uploadedFilesUrls.length > 1 ? 's' : ''} Uploaded Successfully`,
      });
      onClose?.(true);
    } catch (error) {
      form.setFormError(`${typeof error === 'string' ? error : 'An error occured while uploading the files.'}`);
      scrollTop();
      console.error(error);
      return;
    }
  };

  const onSubmitError = async () => {
    form.setFormError('Please fix the errors below');
    scrollTop();
  };

  const fileAdded = (f: Array<File>) => {
    const newFiles = [...files];
    f.forEach((raw) => {
      newFiles.push({
        id: uuid(),
        file: raw,
      });
    });
    setFiles(newFiles);
  };

  const deleteFile = (fileIndex: number) => {
    const updatedFiles = [...(files || [])];
    const deletedFile = updatedFiles.splice(fileIndex, 1)?.[0];
    if (deletedFile) {
      form.deleteField(`fileType-${deletedFile?.id}`);
    }
    setFiles(updatedFiles);
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <Modal
      {...modalProps}
      loading={loading}
      alert={form.formError}
      title='Upload Consent Forms'
      className={styles.base}
      backdropClose={false}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      warnBeforeClosing={files?.length > 0}
      onSubmit={form.handleSubmit(onSubmit, onSubmitError)}
      actions={[
        { label: 'Cancel', action: 'close' },
        { label: 'Finish', action: 'submit' },
      ]}>
      <Grid className={styles.fields} container spacing={3}>
        <Grid item xs={12}>
          <Dropzone onDropAccepted={fileAdded}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: styles.dropzone })}>
                <input {...getInputProps()} />
                <NoteAdd fontSize='large' />
                <p>Drag and drop files or click to upload</p>
              </div>
            )}
          </Dropzone>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.filesContainer}>
            {files?.map((file: any, fileIndex: any) => {
              const { id, uploadedUrl } = file;
              const { name } = file?.file;
              return (
                <>
                  <p className={styles.filesContainerLabel}>File {fileIndex + 1}</p>
                  <div className={styles.fileBlock}>
                    <div key={`${name}-${fileIndex}`} className={styles.file}>
                      {name}
                      {!uploadedUrl && (
                        <Icon
                          className={styles.fileDeleteIcon}
                          name='trash'
                          size={24}
                          onClick={() => deleteFile(fileIndex)}
                        />
                      )}
                      {uploadedUrl && <Icon className={styles.fileCheckmarkIcon} name='checkmark' size={24} />}
                    </div>
                    <div className={styles.extraFields}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <TextField
                            {...bind(`serviceRequestId-${id}`, { required: true, defaultValue: serviceRequestId })}
                            select
                            label='Link to Referral'
                            {...fieldProps}>
                            {serviceRequests?.map((sr: any, i: number) => (
                              <MenuItem
                                key={`${sr?.id}-${i}`}
                                value={sr?.id}
                                /* eslint-disable max-len */
                              >{`${sr?.referral_number} ${sr?.duration}min ${sr?.quantity?.available}/${sr?.quantity?.value} (${sr?.id})`}</MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
}
