import { FC, useState, useEffect, useCallback, useMemo } from 'react';
import Modal from '../templates/Modal';

import Button from '@mui/material/Button';
import styles from './style.module.scss';
import { BookAppointmentProps, RequestType, TimeType } from './BookAppointment.types';
import { v4 as uuid } from 'uuid';
import { Icon } from 'components';
import {
  Group,
  Card,
  Segment,
  UserPersonAvatarIcon,
  PinLocationAddressMapIcon,
  EditPencilBoldIcon,
  GarbageTrashDeleteBoldIcon,
  AddPlusOutlineBoldIcon,
  CalendarIcon,
  CaretBoldRightIcon,
  BuildingOfficeWorkIcon,
} from '@zeel-dev/zeel-ui';
import { useUIStore } from 'utils/hooks';
import { MedicalApi } from 'apis';
import RequestItem from './RequestItem';
import cn from 'classnames';
import { joinString } from 'utils/helper';
import { OGAddressType, OGBookingData, OGBookingDate } from 'apis/medical';
import format from 'date-fns/format';

const BookAppointment: FC<BookAppointmentProps> = ({
  serviceRequestId,
  ogMemberId,
  onClose,
  modalProps,
  scrollTop,
}) => {
  const initialId = uuid();
  const [bookingLoading, setBookingLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [bookingData, setBookingData] = useState<OGBookingData>();
  const [bookingDates, setBookingDates] = useState<OGBookingDate[]>();
  const [addresses, setAddresses] = useState<OGAddressType[]>([]);
  const [mainAddressId, setMainAddressId] = useState<string | undefined>();
  const [gender, setGender] = useState<string | undefined>('b');
  const [requests, setRequests] = useState<Array<RequestType>>([
    { id: initialId, type: 'range', date: null, times: [] },
  ]);
  const [editingId, setEditingId] = useState<string | null>(initialId);

  const { openModal, openAlert } = useUIStore();
  const isOfficeBasedReferral = useMemo(() => !!bookingData?.office_based_referral, [bookingData]);

  useEffect(() => {
    const fetchBooking = async () => {
      setBookingLoad(true);

      try {
        const newBookingData = await MedicalApi.fetchOGBookingData(ogMemberId, serviceRequestId);
        const locationId = newBookingData.booking.location?.id?.toString();
        const genderRequested = newBookingData.booking.appointments?.[0]?.therapist_gender_requested || 'b';
        const newBookingDates = await MedicalApi.fetchOGBookingTimes(ogMemberId, {
          locationId,
          appointments: newBookingData.booking.appointments,
          gender: genderRequested,
        });
        const newBookingAddresses = await MedicalApi.fetchOGBookingAddresses(ogMemberId);
        setBookingData(newBookingData.booking);
        setMainAddressId(locationId);
        setGender(genderRequested);
        setBookingDates(newBookingDates.dates);
        if (newBookingData.booking.office_based_referral) {
          const address = newBookingAddresses.editable_addresses.find(({ id }) => id === locationId);
          if (address) {
            setAddresses([address]);
          }
        } else {
          setAddresses(newBookingAddresses.editable_addresses);
        }
      } catch (e: any) {
        setError(e?.toString() || 'An error occurred while fetching booking data.');
        scrollTop();
        console.error(e);
      }
      setBookingLoad(false);
    };
    fetchBooking();
  }, [serviceRequestId, ogMemberId]);

  const addRequest = useCallback(() => {
    const _id = uuid();
    setRequests((prev) => [...prev, { id: _id, type: 'range', date: null, times: [] }]);
    setEditingId(_id);
  }, []);

  const handleDelete = useCallback(
    (requestId: string) => {
      const updatedRequests = requests.filter((r) => r.id !== requestId);
      setRequests(updatedRequests);
      if (updatedRequests.length === 0) {
        addRequest();
      }
    },
    [requests]
  );

  const handleEdit = useCallback((requestId: string) => {
    setEditingId(requestId);
  }, []);

  const handleChange = useCallback(
    (request: RequestType) => {
      setRequests((prev) => {
        return (prev || []).map((r) => {
          if (r.id === request.id) {
            return request;
          }
          return r;
        });
      });
    },
    [requests]
  );

  const canSubmit = useMemo(() => {
    return requests?.some(
      (request) =>
        (request.type === 'range' && request.times.length) || (request.type === 'specific' && request.times.length)
    );
  }, [requests]);

  const areAllRequestsValid = useMemo(() => {
    let valid = true;
    requests?.forEach((request) => {
      if (
        !request.date ||
        !request.times ||
        request.times.length === 0 ||
        (request.times.filter((r) => !r.range)?.length < 1 && request.times.filter((r) => r.range)?.length === 0)
      ) {
        valid = false;
      }
    });
    return valid;
  }, [requests]);

  const highlightedDates = useMemo(() => {
    return requests.reduce<Date[]>((acc, r) => {
      if (r.date) {
        acc.push(r.date);
      }
      return acc;
    }, []);
  }, [requests]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    const allRequestedTimes: any[] = [];
    requests.forEach((r: RequestType) => {
      r.times?.forEach((t: TimeType) => {
        allRequestedTimes.push({
          start: t.start,
          end: t.end,
        });
      });
    });
    try {
      await MedicalApi.createOGBooking(ogMemberId, {
        type: bookingData?.appointments && bookingData.appointments.length > 1 ? 'back_to_back' : 'single',
        locationId: mainAddressId,
        serviceRequestId,
        clientId: bookingData?.client?.id,
        gender,
        appointments: bookingData?.appointments,
        times: allRequestedTimes,
        ...(isOfficeBasedReferral
          ? {
              providerId: bookingData?.provider?.id,
            }
          : {}),
      });
      const address = addresses.find(({ id }) => id === mainAddressId);
      openAlert({
        title: `Appointment Submitted`,
        description: (
          <>
            {requests.map(
              (request) =>
                request.date && (
                  <p key={request.id}>
                    {format(request.date, 'MMMM d, yyyy')}: {request.times.length}{' '}
                    {request.type === 'range' ? 'time range' : 'time'}
                    {request.times.length > 1 ? 's' : ''}
                  </p>
                )
            )}
            <p>
              {joinString([address?.address1, address?.address2])}, {address?.city}, {address?.state}, {address?.zip}
            </p>
          </>
        ),
        severity: 'success',
      });
      onClose?.(true);
    } catch (e: any) {
      setError(e?.toString() || 'An error occurred while creating an appointment.');
      scrollTop();
      console.error(e);
    }
    setLoading(false);
  }, [requests, gender, ogMemberId, bookingData, mainAddressId, serviceRequestId, addresses, isOfficeBasedReferral]);

  const handleAddNewLocation = useCallback(() => {
    openModal({
      id: 'add-edit-location',
      props: {},
      callback: async (newAddress: OGAddressType) => {
        if (newAddress) {
          setLoading(true);
          try {
            const { id } = await MedicalApi.createOrUpdateOGBookingAddress(ogMemberId, {
              ...newAddress,
              client_id: bookingData?.location?.client_id?.toString() || '',
            });
            setAddresses((prevAddresses) =>
              prevAddresses.concat({
                ...newAddress,
                client_id: bookingData?.location?.client_id?.toString() || '',
                id,
              })
            );
          } catch (e: any) {
            setError(e?.toString() || 'An error occurred while adding the address.');
            scrollTop();
            console.error(e);
          }
          setLoading(false);
        }
      },
    });
  }, [ogMemberId]);

  const getGenderIcon = useCallback((providerGender?: string) => {
    switch (providerGender?.toLowerCase()) {
      case 'm':
        return 'avatar-male';
      case 'f':
        return 'avatar-female';
      default:
        return 'avatar-neutral';
    }
  }, []);

  const handleEditAddress = useCallback(
    (address: OGAddressType) => {
      openModal({
        id: 'add-edit-location',
        props: { location: address },
        callback: async (newAddress?: OGAddressType) => {
          if (newAddress) {
            setLoading(true);
            try {
              await MedicalApi.createOrUpdateOGBookingAddress(ogMemberId, {
                ...address,
                ...newAddress,
              });
              setAddresses((prevAddresses) => {
                const newAddresses = [...prevAddresses];
                const updatedAddressIndex = newAddresses.findIndex(({ id }) => id === address.id);
                if (updatedAddressIndex >= 0) {
                  newAddresses[updatedAddressIndex] = {
                    ...address,
                    ...newAddress,
                  };
                }
                return newAddresses;
              });
            } catch (e: any) {
              setError(e?.toString() || 'An error occurred while editing the address.');
              scrollTop();
              console.error(e);
            }
            setLoading(false);
          }
        },
      });
    },
    [ogMemberId]
  );

  const handleRemoveAddress = useCallback(
    async (addressId: string) => {
      setLoading(true);
      try {
        await MedicalApi.removeOGBookingAddress(ogMemberId, addressId);
        if (mainAddressId === addressId) {
          setMainAddressId(undefined);
        }
        setAddresses((prevAddresses) => prevAddresses.filter(({ id }) => id !== addressId));
      } catch (e: any) {
        setError(e?.toString() || 'An error occurred while removing the address.');
        scrollTop();
        console.error(e);
      }
      setLoading(false);
    },
    [ogMemberId, mainAddressId]
  );

  return (
    <Modal
      {...modalProps}
      title='Book New Appointment'
      alert={error}
      className={styles.base}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      backdropClose={false}
      loading={bookingLoading || loading}
      onSubmit={handleSubmit}
      actions={[
        {
          label: 'Submit Booking',
          action: 'submit',
          disabled: !mainAddressId || requests.length === 0 || !canSubmit,
        },
      ]}>
      <label className={styles.label}>
        <CalendarIcon size={24} />
        Date and Time
      </label>
      {!bookingLoading && (
        <>
          {requests.map((request) => {
            return (
              <RequestItem
                key={request.id}
                request={request}
                editing={request.id === editingId}
                deletable={!(requests.length === 1 && !request.date)}
                onDelete={() => handleDelete(request.id)}
                onEdit={() => handleEdit(request.id)}
                onChange={(r) => handleChange(r)}
                bookingDates={bookingDates}
                highlightedDates={highlightedDates}
              />
            );
          })}
          <div
            className={cn(styles.addAnotherDay, { [styles['addAnotherDay--disabled']]: !areAllRequestsValid })}
            onClick={() => addRequest()}>
            Add Another Day <AddPlusOutlineBoldIcon size={24} />
          </div>
          <div className={styles.breakline} />
        </>
      )}
      {!isOfficeBasedReferral && (
        <>
          <label className={styles.label}>
            <UserPersonAvatarIcon size={24} />
            Therapist Gender Preference
          </label>
          <Segment
            items={[
              {
                id: 'f',
                label: 'Female',
              },
              {
                id: 'fp',
                label: 'Female Preferred',
              },
              {
                id: 'b',
                label: 'Any',
              },
              {
                id: 'mp',
                label: 'Male Preferred',
              },
              {
                id: 'm',
                label: 'Male',
              },
            ]}
            value={gender}
            onChange={(newValue) => newValue && setGender(newValue as string)}
            fill
          />
        </>
      )}
      {isOfficeBasedReferral && (
        <>
          <label className={styles.label}>
            <UserPersonAvatarIcon size={24} />
            Therapist
          </label>
          <div className={styles.therapist}>
            {bookingData?.provider.profile_pic ? (
              <img className={styles.avatar} src={bookingData?.provider.profile_pic} />
            ) : (
              <Icon name={getGenderIcon(bookingData?.provider?.sex)} size={80} />
            )}
            <div>
              <p className={styles.name}>
                {joinString(
                  [
                    joinString([bookingData?.provider?.fname, bookingData?.provider?.lname]),
                    bookingData?.provider?.memberships,
                  ],
                  ', '
                )}
                <CaretBoldRightIcon size={24} />
              </p>
              <div className={styles.officeChip}>
                <BuildingOfficeWorkIcon size={18} />
                In-Office
              </div>
            </div>
          </div>
        </>
      )}
      <label className={styles.label}>
        <PinLocationAddressMapIcon size={24} />
        Location
      </label>
      <Group columns={1} verticalGutter fullWidth>
        {addresses.map((address: OGAddressType) => (
          <Card
            key={address.id}
            full
            actions={
              isOfficeBasedReferral
                ? []
                : [
                    { icon: <EditPencilBoldIcon size={24} onClick={() => handleEditAddress(address)} /> },
                    { icon: <GarbageTrashDeleteBoldIcon size={24} onClick={() => handleRemoveAddress(address.id)} /> },
                  ]
            }
            selected={address.id === mainAddressId}
            onClick={!isOfficeBasedReferral ? () => setMainAddressId(address.id) : undefined}>
            <p className={styles.line}>{joinString([address.address1, address.address2], ', ')}</p>
            <p className={styles.line}>{joinString([address.city, address.state, address.zip], ', ')}</p>
          </Card>
        ))}
        {!bookingLoading && !isOfficeBasedReferral && (
          <Button className={styles.addButton} onClick={handleAddNewLocation}>
            <AddPlusOutlineBoldIcon size={24} />
            &nbsp;&nbsp;Add New
          </Button>
        )}
      </Group>
    </Modal>
  );
};

export default BookAppointment;
