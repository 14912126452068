import api from 'apis';
import { AddressType } from '../medical';
import { transformApiPractitioners } from './helper';

export enum OfficeLocationStatusEnum {
  PRELIMINARY = 'preliminary',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PAUSED = 'paused',
  CLOSED = 'closed',
  ENTERED_IN_ERROR = 'entered-in-error',
}

export interface Identifier {
  system: string;
  value: string;
}

export interface Name {
  family: string;
  given: string[];
  use?: string;
}

export interface Photo {
  content_type: string;
  title: string;
  url: string;
}

export interface Qualification {
  expiration_date: string;
  issuer: string;
  license_number: string;
}

export interface Telecom {
  rank?: number;
  system: string;
  use?: string;
  value: string;
}

export interface Extension {
  url: string;
  value_boolean?: boolean;
  value_code?: string;
  value_integer?: number;
  value_string?: string;
}

export interface PractitionerType {
  active: boolean;
  address: AddressType[];
  automated_review_threshold: number;
  birth_date: string;
  communication: string[];
  gender: string;
  id: string;
  identifier: Identifier[];
  name: Name[];
  flattenName: string;
  network_status: string;
  service_area: string;
  npi: string;
  photo: Photo[];
  qualification: Qualification[];
  telecom: Telecom[];
  email: string;
  phone: string;
  vaccinated_provider: boolean;
  validated_provider: boolean;
  office_based_address: OfficeLocationType[];
  extension: Extension[];
  zeelId: string;
}

export interface OfficeLocationType {
  ada_compliant: boolean;
  address: AddressType;
  office_note: string;
  rejection_note: string;
  paused_note: string;
  paused_to_date: string;
  closed_note: string;
  reviewed_by: string;
  reviewed_date?: string;
  status: OfficeLocationStatusEnum;
}

/**
 * Get a practitioner by id
 * https://alb.zeel-tech.dev/practitioner/v1/ui/#/practitioner/practitioner.app.controllers.practitioner.fetch
 */
export const getPractitioner = async (practitionerId: string): Promise<PractitionerType> => {
  const response = await api.get({
    path: `/practitioner/v1/practitioner/${practitionerId}`,
    auth: true,
  });
  return transformApiPractitioners([response])[0];
};

/**
 * Update a practitioner by id
 * https://alb.zeel-tech.dev/practitioner/v1/ui/#/practitioner/practitioner.app.controllers.practitioner.update
 */
export const updatePractitioner = async (
  practitionerId: string,
  data: Partial<PractitionerType>
): Promise<PractitionerType> => {
  const response = await api.patch({
    path: `/practitioner/v1/practitioner/${practitionerId}`,
    auth: true,
    data,
  });
  return transformApiPractitioners([response])[0];
};

export const fetchPractitionerByZeelId = async (zeelId: string): Promise<PractitionerType> => {
  const response = await api.get({
    // eslint-disable-next-line max-len
    path: `/practitioner/v1/practitioner`,
    auth: true,
    data: {
      params: {
        identifier: `http://www.zeel.com/fhir/va/zeel_id|${zeelId}`,
      },
    },
  });

  return transformApiPractitioners(response)[0];
};
