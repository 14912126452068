import React, { useState, useEffect, SyntheticEvent } from 'react';
import Modal from '../templates/Modal';
import Grid from '@mui/material/Grid';
import { MedicalApi, DocumentApi, EvaluationApi } from 'apis';
import { useUIStore } from 'utils/hooks';
import { ToggleSection, Icon } from 'components';
import moment from 'moment-timezone';
import { CompositionStatusType, CompositionType, DocumentType, CompositionCategorizeType } from 'apis/medical';
import { QuestionnaireResponseType } from 'apis/evaluation';
import FileRow from './FileRow';
import styles from './style.module.scss';
import { CombineFilesProps } from './CombineFiles.types';
import { convertUTCDateToLocalDate } from 'utils/helper';

export default function CombineFiles({
  serviceRequest,
  notes: propsNotes,
  evaluations: propsEvaluations,
  approvedOnly,
  onClose,
  title,
  hideBack,
  modalProps,
}: CombineFilesProps) {
  const { openModal } = useUIStore();
  const [selected, setSelected] = useState<string[]>([]);
  const [hiddenNotes, setHiddenNotes] = useState<string[]>([]);
  const [notes, setNotes] = useState<CompositionType[]>(
    [...(propsNotes || [])]
      .filter(
        ({ type, status }) =>
          type === CompositionCategorizeType.SOAP_NOTES && (!approvedOnly || status === CompositionStatusType.Approved)
      ) // exclude medical review notes
      .sort(
        (a: CompositionType, b: CompositionType) =>
          new Date(b.appointment_date).getTime() - new Date(a.appointment_date).getTime()
      )
  );
  const [evaluations, setEvaluations] = useState<DocumentType[]>(propsEvaluations || []);
  const [questionnaireResponses, setQuestionnaireResponses] = useState<QuestionnaireResponseType[]>([]);
  const [nativeFiles, setNativeFiles] = useState<{ fileName: string; id: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      if (!serviceRequest) return;
      setLoading(true);
      try {
        const promises: Array<Promise<any>> = [
          EvaluationApi.searchQuestionnaireResponseByServiceRequestId(serviceRequest.id),
        ];
        let documents: DocumentType[] = [];

        if (!propsNotes) {
          promises.push(MedicalApi.fetchAllSoapNote(serviceRequest.id));
        } else {
          promises.push(Promise.resolve([]));
        }
        if (!propsEvaluations) {
          documents =
            serviceRequest.additional_documents
              ?.filter((doc) => doc?.type === 'evaluation')
              .map(
                (doc: DocumentType): DocumentType => ({
                  url: doc.url,
                  description: doc.description,
                  id: (doc.url || '').split('/').pop() as string,
                  type: doc?.type,
                })
              ) ?? [];
          documents.forEach((d: any) => {
            promises.push(DocumentApi.fetchDocumentMeta(d.id).catch(() => false));
          });
        }

        const [qrs, pNotes, ...fileMetas] = await Promise.all(promises);
        // Filter out unique appointments because one appointment can have multiple questionnaire responses
        qrs.sort((a: QuestionnaireResponseType, b: QuestionnaireResponseType) => b.session_number - a.session_number);
        const filteredQrs: QuestionnaireResponseType[] = !approvedOnly
          ? qrs
          : qrs.filter((qr: QuestionnaireResponseType) => qr.status === 'approved');

        const filteredNativeFiles: { fileName: string; id: string }[] = [];
        const sessionMap = new Set<number>();
        for (const qr of filteredQrs) {
          if (!sessionMap.has(qr.session_number)) {
            sessionMap.add(qr.session_number);
            filteredNativeFiles.push({
              id: qr.appointment_id,
              fileName: qr.appointment_date,
            });
          }
        }

        const idsToRemove: Array<string> = [];
        (fileMetas || []).forEach((meta: any, i: number) => {
          if (!meta) {
            idsToRemove.push(documents[i].id);
          } else {
            const { filename: fileName, filesize: fileSize, mimetype: mimeType, tags, created_utc: created } = meta;
            documents[i].meta = {
              fileName,
              fileSize,
              mimeType,
              tags,
              created: convertUTCDateToLocalDate(created),
            };
          }
        });

        // removing ids where fetching meta failed (doc doesn't exist 404)
        documents = documents.filter((doc: DocumentType) => !idsToRemove.includes(doc.id));

        setQuestionnaireResponses(filteredQrs);
        setNativeFiles(filteredNativeFiles);
        if (!propsNotes) {
          setNotes(
            pNotes
              // exclude medical review notes
              .filter(
                (note: CompositionType) =>
                  note.type === CompositionCategorizeType.SOAP_NOTES &&
                  (!approvedOnly || note.status === CompositionStatusType.Approved)
              )
              .sort(
                (a: CompositionType, b: CompositionType) =>
                  new Date(b.appointment_date).getTime() - new Date(a.appointment_date).getTime()
              )
          );
        }
        if (!propsEvaluations) {
          setEvaluations(documents);
        }
      } catch (e) {
        console.log(e);
        setError('An error occurred while fetching notes.');
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const unCheckNote = (id: string) => {
    setSelected(selected.filter((s) => s !== id));
  };

  const handleCheck = (noteId: string): void => {
    if (selected.includes(noteId)) {
      unCheckNote(noteId);
    } else {
      setSelected([...selected, noteId]);
    }
  };

  const handleClickNote = (note: CompositionType) => (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    openModal({
      id: 'soap-note-pdf-view',
      props: { soapNoteId: note.id },
      callback: (noteId?: string) => {
        if (noteId) {
          if (selected.includes(`note-${noteId}`)) {
            unCheckNote(noteId);
            setHiddenNotes([...hiddenNotes, `note-${noteId}`]);
          }
        }
      },
    });
  };

  const handleClickEvaluation = (evaluation: DocumentType) => async (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    openModal({
      id: 'document-pdf-view',
      props: { docId: evaluation.id, title: evaluation?.meta?.fileName },
    });
  };

  const handleClickQuestionnaireResponse = (appointmentId: string) => async (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    openModal({
      id: 'evaluation-pdf-view',
      props: { appointmentId },
    });
  };

  const handleSubmit = () => {
    const selectedNotes: string[] = [];
    const selectedAppointments: string[] = [];
    const selectedEvaluations: string[] = [];
    selected.forEach((doc) => {
      if (doc.startsWith('note')) {
        selectedNotes.push(doc.slice('note'.length + 1));
      } else if (doc.startsWith('appointment')) {
        selectedAppointments.push(doc.slice('appointment'.length + 1));
      } else if (doc.startsWith('evaluation')) {
        selectedEvaluations.push(doc.slice('evaluation'.length + 1));
      }
    });

    // Order soap notes
    const soapNoteIds = notes.filter((note: CompositionType) => selectedNotes.includes(note.id)).map(({ id }) => id);

    // Order appointments(questionnaire responses)
    const appointmentIds = nativeFiles.filter(({ id }) => selectedAppointments.includes(id)).map(({ id }) => id);
    const questionnaireResponseIds = questionnaireResponses
      .filter(({ appointment_id }) => selectedAppointments.includes(appointment_id))
      .map(({ id }) => id);

    // Order evaluations in original order
    const evaluationIds = evaluations
      .filter((evaluation: DocumentType) => selectedEvaluations.includes(evaluation.id))
      .map(({ id }) => id);

    onClose?.({
      soapNoteIds,
      appointmentIds,
      questionnaireResponseIds,
      evaluationIds,
    });
  };

  return (
    <Modal
      {...modalProps}
      loading={loading}
      alert={error}
      title={title || 'Create PDF from Multiple Files'}
      description={
        <>
          Select files to package into a new PDF.
          <br />
          All original files will be maintained.
        </>
      }
      className={styles.base}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      backdropClose={false}
      onSubmit={handleSubmit}
      actions={[
        ...(!hideBack ? [{ label: 'Back', action: 'close' }] : []),
        {
          label: selected.length
            ? `Create PDF from ${selected.length} file${selected.length > 1 ? 's' : ''}`
            : 'Create PDF',
          action: 'submit',
          disabled: selected.length === 0,
        },
      ]}>
      <Grid className={styles.fileHeader} container>
        <div>
          File <Icon name='arrow-down' size={21} />
        </div>
        <span>Include?</span>
      </Grid>
      {notes.length > 0 && (
        <Grid className={styles.section} container>
          <ToggleSection className={styles.toggleSection} icon='notes' title='SOAP Notes'>
            {notes.map(
              (note: CompositionType) =>
                !hiddenNotes.includes(`note-${note.id}`) &&
                note.status !== CompositionStatusType.EnteredInError && (
                  <FileRow
                    key={note.id}
                    fileName={moment(note.appointment_date).tz('America/New_York').format('YYYY-MM-DD HH:mm z')}
                    checked={selected.includes(`note-${note.id}`)}
                    onClick={() => handleCheck(`note-${note.id}`)}
                    onClickName={handleClickNote(note)}
                  />
                )
            )}
          </ToggleSection>
        </Grid>
      )}
      {(evaluations.length > 0 || nativeFiles.length > 0) && (
        <Grid className={styles.section} container>
          <ToggleSection className={styles.toggleSection} icon='pad-with-note' title='Evaluations'>
            {nativeFiles.map((file) => {
              return (
                <FileRow
                  key={file.id}
                  fileName={file.fileName}
                  checked={selected.includes(`appointment-${file.id}`)}
                  onClick={() => handleCheck(`appointment-${file.id}`)}
                  onClickName={handleClickQuestionnaireResponse(file.id)}
                />
              );
            })}
            {evaluations.map((evaluation: DocumentType) => (
              <FileRow
                key={evaluation.id}
                fileName={evaluation?.meta?.fileName || `file-${new Date().getTime()}`}
                checked={selected.includes(`evaluation-${evaluation.id}`)}
                onClick={() => handleCheck(`evaluation-${evaluation.id}`)}
                onClickName={handleClickEvaluation(evaluation)}
              />
            ))}
          </ToggleSection>
        </Grid>
      )}
    </Modal>
  );
}
