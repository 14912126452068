/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { Formik, Field } from 'formik';
import Form, { FormProps } from 'components/Form';
import { TextField, TextFieldProps, fieldToTextField } from 'formik-mui';
import { PhoneField, FieldTitle } from 'components';
import { useAuth, useUIStore } from 'utils/hooks';
import * as yup from 'yup';
import 'yup-phone';
import { MedicalApi } from 'apis';
import { getCalendarTime, joinString } from 'utils/helper';
import { FacilityType } from 'apis/medical';
import moment from 'moment';
import isNil from 'lodash/isNil';
import { Segment } from '@zeel-dev/zeel-ui';
import styles from './style.module.scss';

export type FacilityFormV2Props = FormProps & {
  facility?: FacilityType | null;
  title?: string;
  onSavePatient?: () => void;
};

function FormikPhoneField(props: TextFieldProps) {
  return <PhoneField {...fieldToTextField(props)} />;
}

const FacilityV2Form: FC<FacilityFormV2Props> = ({
  facility,
  title,
  collapsible,
  editable,
  editing,
  onEdit,
  onUndo,
  onSave,
  onError,
}) => {
  const { openAlert } = useUIStore();
  const { user } = useAuth();

  const validationSchema = useMemo(
    () =>
      yup.object({
        internalNotes: facility?.internal_note
          ? yup.string().required('Internal notes cannot be removed.')
          : yup.string().notRequired(),
        externalNotes: facility?.external_note
          ? yup.string().required('External notes cannot be removed.')
          : yup.string().notRequired(),
        referral_method: yup.string().required('Please fill out this field.'),
        contact_information: yup.string().max(1000, 'Contact information should not exceed 1000 characters.'),
      }),
    [facility]
  );

  const fieldProps = {
    variant: editing ? 'outlined' : 'standard',
    fullWidth: true,
    InputProps: { disableUnderline: !editing, readOnly: !editing },
    InputLabelProps: {
      shrink: true,
    },
  };

  const readOnlyProps = { variant: 'standard', InputProps: { disableUnderline: true, readOnly: true } };
  const updateFacility = async (values: any, setSubmitting: (submitting: boolean) => void) => {
    if (facility) {
      const pccAgentName = user?.pccAgentName;

      try {
        const data: any = {};
        const isInternalNotesUpdated = values.internalNotes && values.internalNotes !== facility.internal_note?.text;
        const isExternalNotesUpdated = values.externalNotes && values.externalNotes !== facility.external_note?.text;
        if (isInternalNotesUpdated) {
          data['internal_note'] = {
            pcc_agent: pccAgentName,
            text: values.internalNotes,
          };
        }
        if (isExternalNotesUpdated) {
          data['external_note'] = {
            pcc_agent: pccAgentName,
            text: values.externalNotes,
          };
        }
        if (!isNil(values.massage_therapy_new)) {
          data['massage_therapy_new'] = values.massage_therapy_new;
        }
        if (!isNil(values.ready_for_orientation)) {
          data['ready_for_orientation'] = values.ready_for_orientation;
        }
        await MedicalApi.updateFacility(facility.id, {
          ...data,
          referral_method: values.referral_method,
          contact_information: values.contact_information,
        });
        openAlert({
          title: `Facility information was successfully updated`,
        });
        onSave?.({
          ...facility,
          massage_therapy_new: values.massage_therapy_new,
          ready_for_orientation: values.ready_for_orientation,
          referral_method: values.referral_method,
          contact_information: values.contact_information,
          ...(isInternalNotesUpdated
            ? {
                internal_note: {
                  pcc_agent: pccAgentName,
                  text: values.internalNotes,
                  timestamp: new Date().toUTCString(),
                },
              }
            : {}),
          ...(isExternalNotesUpdated
            ? {
                external_note: {
                  pcc_agent: pccAgentName,
                  text: values.externalNotes,
                  timestamp: new Date().toUTCString(),
                },
              }
            : {}),
        });
      } catch (error) {
        console.error(error);
        openAlert({ title: `Failed to update facility information`, severity: 'error' });
        onError?.();
      }
    } else {
      onSave?.();
    }
    setSubmitting(false);
  };

  const facilityNote = useMemo(() => {
    if (facility?.internal_note && facility?.external_note) {
      if (moment(facility.internal_note.timestamp).isAfter(facility.external_note.timestamp)) {
        return facility.internal_note;
      } else {
        return facility.external_note;
      }
    }
    return facility?.internal_note || facility?.external_note;
  }, [facility]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        facilityId: facility?.facility_id ?? '',
        facilityName: facility?.name ?? '',
        facilityPhone: facility?.phone ?? '',
        facilityAddress: facility?.address
          ? joinString(
              [
                ...(facility.address.line || []),
                facility.address.city,
                facility.address.state,
                facility.address.postal_code,
              ],
              ', '
            )
          : '',
        internalNotes: facility?.internal_note?.text ?? '',
        externalNotes: facility?.external_note?.text ?? '',
        contact_information: facility?.contact_information ?? '',
        referral_method: facility?.referral_method ?? '',
        massage_therapy_new: facility?.massage_therapy_new,
        ready_for_orientation: facility?.ready_for_orientation,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSave?.();
        updateFacility(values, setSubmitting);
      }}>
      {({ resetForm, handleSubmit, isSubmitting, dirty, values, setFieldTouched, setFieldValue }) => (
        <Form
          icon='military'
          title={title || 'VA Facility'}
          collapsible={collapsible}
          editable={editable}
          editing={!isSubmitting ? editing : undefined}
          marginBottom
          onEdit={() => onEdit?.()}
          onUndo={() => {
            if (dirty) {
              const confirm = window.confirm('You have unsaved changes that will be lost');
              if (confirm) {
                onUndo?.();
                resetForm();
              }
            } else {
              onUndo?.();
            }
          }}
          dirty={dirty}
          onSave={handleSubmit}>
          <Grid item xs={12}>
            <Field
              component={TextField}
              type='text'
              label='Facility Name'
              name='facilityName'
              multiline
              {...fieldProps}
              {...readOnlyProps}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextField}
              type='text'
              label='Facility ID'
              name='facilityId'
              {...fieldProps}
              {...readOnlyProps}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={FormikPhoneField}
              type='text'
              label='Facility Phone Number'
              name='facilityPhone'
              {...fieldProps}
              {...readOnlyProps}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextField}
              type='text'
              label='Facility Address'
              multiline
              name='facilityAddress'
              {...fieldProps}
              {...readOnlyProps}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextField}
              type='text'
              multiline
              label='Internal Facility Notes (private within Zeel)'
              name='internalNotes'
              maxRows={editing ? 10 : undefined}
              {...fieldProps}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextField}
              type='text'
              multiline
              label='External Facility Notes (visible to providers)'
              name='externalNotes'
              maxRows={editing ? 10 : undefined}
              {...fieldProps}
            />
          </Grid>
          <Grid item xs={12}>
            {facilityNote && (
              <p className={styles.name}>
                Last edit {getCalendarTime(facilityNote.timestamp)},{' '}
                {moment.utc(facilityNote.timestamp).local().format('h:mma z')} by {facilityNote.pcc_agent}
              </p>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <FieldTitle icon='users'>Contacts</FieldTitle>
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextField}
              type='text'
              multiline
              label='Contact Information'
              name='contact_information'
              maxRows={editing ? 7 : undefined}
              {...fieldProps}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextField}
              type='text'
              select
              label='How was the VAMC referred?'
              name='referral_method'
              {...fieldProps}>
              <MenuItem value='tpa'>TPA (Optum or Triwest)</MenuItem>
              <MenuItem value='ppms'>Located Zeel in PPMS</MenuItem>
              <MenuItem value='word_of_mouth'>Word of mouth/patient request</MenuItem>
              <MenuItem value='other'>Other</MenuItem>
            </Field>
          </Grid>
          <Grid item xs={12}>
            <label className={styles.label}>Is massage therapy a new or existing program for this VAMC?</label>
            <Segment
              value={values.massage_therapy_new}
              items={[
                { label: 'New', id: true },
                { label: 'Existing', id: false },
              ]}
              onChange={(val) => {
                if (!editing || val === undefined) {
                  return;
                }
                setFieldTouched('massage_therapy_new');
                setFieldValue('massage_therapy_new', val);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <label className={styles.label}>Ready to Schedule Orientation?</label>
            <Segment
              value={values.ready_for_orientation}
              items={[
                { label: 'Yes', id: true },
                { label: 'No', id: false },
              ]}
              onChange={(val) => {
                if (!editing || val === undefined) {
                  return;
                }
                setFieldTouched('ready_for_orientation');
                setFieldValue('ready_for_orientation', val);
              }}
            />
          </Grid>
          {editing && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default FacilityV2Form;
