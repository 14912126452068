/* eslint-disable max-len */
import React from 'react';
import moment from 'moment';
import { MedicalApi } from 'apis';
import store from 'store';
import { selectUser } from 'store/Auth';
import { ServiceRequestType, AppointmentType, PatientType, CompositionTitleType } from 'apis/medical';
import { useState, useUIStore, useForm } from 'utils/hooks';
import PatientForm from 'components/forms/Patient';
import { ServiceRequestForm } from 'components/forms/ServiceRequest';
import AppointmentForm from 'components/forms/Appointment';
import Modal, { ModalPropType } from '../templates/Modal';
import Grid from '@mui/material/Grid';
import { TextField, ToggleSection, FieldTitle, Icon } from 'components';
import styles from './style.module.scss';

export type AddSoapNoteProps = ModalPropType & {
  appointment: AppointmentType;
  patient: PatientType;
  serviceRequest?: ServiceRequestType;
};

export default function AddSoapNote({
  appointment,
  patient,
  serviceRequest,
  onClose,
  scrollTop,
  modalProps,
}: AddSoapNoteProps) {
  const { openAlert, openModal } = useUIStore();
  const { bind, form } = useForm();
  const { memberId } = selectUser(store.getState());

  // const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  // const [providers, setProviders] = useState<Array<ProviderCtrlType>>([]);
  // const [selectedProvider, setSelectedProvider] = useState<{ id: string; name: string } | null>(null);
  // const [touched, setTouched] = useState(false);

  // const fetchProviders = async (search: string) => {
  //   setLoading(true);
  //   try {
  //     const p = await MedicalApi.searchProviders(search);
  //     setProviders(p);
  //   } catch (e) {
  //     console.error(e);
  //   }
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   if (appointment?.provider_id) {
  //     setSelectedProvider({
  //       id: appointment?.provider_id,
  //       name: appointment?.provider_id,
  //     });
  //   }
  // }, []);

  const soapFields: Array<{ name: string; label: string; modalContent?: any }> = [
    {
      name: 'subjective',
      label: 'Subjective',
      modalContent: (
        <div className={styles.planModalBody}>
          <p>
            Include patient’s history, information on patient’s injury, past/ongoing treatments, and the patient’s
            report of their current condition.
          </p>
          <br />
          Need more help? Contact Clinical Care Team
        </div>
      ),
    },
    {
      name: 'objective',
      label: 'Objective',
      modalContent: (
        <div className={styles.planModalBody}>
          <p>Your summary of the assessment of the patient&apos;s posture, temperature, range of motion.</p>
          <br />
          Need more help? Contact Clinical Care Team
        </div>
      ),
    },
    {
      name: 'assessment',
      label: 'Assessment',
      modalContent: (
        <div className={styles.planModalBody}>
          <p>
            Include what the patient&apos;s massage therapy referral was for as well as your clinical judgement. For
            example: “the patient is a 42 year old female referred to MT with a diagnosis of low back pain. She presents
            with pain, increased soft tissue tension and muscle spasm preventing her from performing self care
            activities and household chores.”
          </p>
          <br />
          Need more help? Contact Clinical Care Team
        </div>
      ),
    },
    {
      name: 'treatment',
      label: 'Treatment',
      modalContent: (
        <div className={styles.planModalBody}>
          <p>
            Describe the treatment you provided to the patient including type(s) of massage treatment, skills used, and
            the specific areas that received specialized care. For example: “The patient tolerated treatment well with
            decreased pain and muscle spasm.”
          </p>
          <br />
          Need more help? Contact Clinical Care Team
        </div>
      ),
    },
    {
      name: 'plan',
      label: 'Plan',
      modalContent: (
        <div className={styles.planModalBody}>
          <p>
            <strong>If this session is your first session</strong>, add at least 2 long-term and 2 short-term goals.
            These goals should be measurable with functional outcomes (always relate everything back to function) and
            should include approximate time frames for meeting these goals. For example: “STG 2 weeks: Decrease pain and
            muscle spasm in the lumbar spine to improve flexion to partially limited to assist with performing self care
            activities such as donning shoes and socks.”
          </p>
          <br />
          <p>
            <strong>For subsequent sessions</strong> indicate progress towards goals and any updates to the treatment
            plan. Or, if no changes, indicate: “Continue with the current plan of care.”
          </p>
          <br />
          <p>
            <strong>Examples of updated goals:</strong>
          </p>
          <ul>
            <li>Goal achieved</li>
            <li>Progressing towards goal</li>
            <li>Goal partially met</li>
            <li>Goal not met due to ….</li>
          </ul>
          <p>
            This will help you if you anticipate requesting additional services. **make sure your goals are measurable
            and related to function**
          </p>
          <br />
          Need more help? Contact Clinical Care Team
        </div>
      ),
    },
  ];

  const createNote = async (values: any = {}) => {
    const procedureId = appointment.procedure_id;
    // const providerId = appointment.provider_id;
    // const details = values.details;
    try {
      await MedicalApi.createSoapNote({
        title: CompositionTitleType.SOAP_NOTES,
        status: 'preliminary',
        procedure_id: procedureId as string,
        date: values.date,
        memberId,
        details: values.details,
        notes: soapFields.map((f) => {
          const { name } = f;
          return {
            title: name,
            text: values[name] || '',
          };
        }),
      });

      openAlert({ title: `The composition was created` });
      onClose?.(true);
    } catch (e) {
      setError('An error occured while creating the note');
      scrollTop();
      console.error(e);
    }
  };
  const openNoteModal = (title: string, content: any) => {
    openModal({
      id: 'info',
      props: {
        className: styles.planModal,
        title,
        content,
        showButton: true,
      },
    });
  };

  // const onProviderInputHandler = (value: string) => {
  //   if (!value || (selectedProvider && selectedProvider.name === value)) return;
  //   fetchProviders(value);
  // };

  // const onProviderChangeHandler = (provider: { id: string; name: string }) => {
  //   setTouched(true);
  //   setSelectedProvider(provider);
  // };

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  // const providerOptions: Array<{ id: string; name: string }> = (providers || []).map((provider) => {
  //   return {
  //     id: provider?.id,
  //     name: `${provider?.fname} ${provider?.lname}`,
  //   };
  // });
  // if (selectedProvider && !providerOptions.find((p) => p.id === selectedProvider.id)) {
  //   providerOptions.push(selectedProvider);
  // }

  return (
    <Modal
      {...modalProps}
      alert={error}
      title='Add New SOAP Note'
      description={
        <>
          For patient {patient?.given_names?.join(' ')} {patient?.family_name}
          <br />
          Appointment Date {moment(appointment.date).format('MM/DD/YYYY')}
          <br />
          {appointment?.provider &&
            `Submitted by ${appointment?.provider?.fname} ${appointment?.provider?.lname} (NPI ${appointment?.provider?.npi})`}
        </>
      }
      className={styles.base}
      footerClassName={styles.footer}
      backdropClose={false}
      warnBeforeClosing
      onSubmit={form.handleSubmit(createNote)}
      actions={[
        { label: 'Cancel', action: 'close' },
        { label: 'Save Changes', action: 'submit' },
      ]}>
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={6}>
          <TextField
            {...bind('date', {
              required: true,
              validate: (val: any) => {
                if (val && moment().diff(moment(val, 'YYYY-MM-DD')) < 0) return 'Cannot be greater than today';
                return null;
              },
            })}
            label='Date Submitted'
            type='date'
            {...fieldProps}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <AutocompleteField
            filterOptions={(x: any) => x}
            getOptionLabel={(o: any) => `${o?.name}`}
            onInputChange={onProviderInputHandler}
            onChange={onProviderChangeHandler}
            options={providerOptions}
            ListBoxProps={{ style: { maxHeight: '250px' } }}
            label='Provider'
            value={selectedProvider?.id}
            error={!selectedProvider?.id && touched ? 'Please fill out this field' : null}
            {...fieldProps}
          />
        </Grid> */}

        {soapFields.map((field) => {
          const { name, label, modalContent } = field;

          return (
            <Grid key={name} item xs={12}>
              <FieldTitle className={styles.soapFieldTitle}>
                {label}
                {modalContent && (
                  <Icon name='information' size={20} onClick={() => openNoteModal(`${label} Note`, modalContent)} />
                )}
              </FieldTitle>
              <TextField {...bind(name, { required: true })} multiline {...fieldProps} />
            </Grid>
          );
        })}

        <Grid item xs={12}>
          <div className={styles.separator} />
        </Grid>

        <Grid item xs={12}>
          <FieldTitle>Details About this SOAP Submission</FieldTitle>
          <TextField {...bind('details', { required: true })} multiline {...fieldProps} />
        </Grid>

        <Grid item xs={12}>
          <ToggleSection title='Additional Information'>
            {patient && <PatientForm patient={patient} marginBottom />}
            {appointment && <AppointmentForm appointment={appointment} marginBottom />}
            {serviceRequest && <ServiceRequestForm serviceRequest={serviceRequest} marginBottom />}
          </ToggleSection>
        </Grid>
      </Grid>
    </Modal>
  );
}
