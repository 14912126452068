import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useDebouncedCallback } from 'utils/hooks';
import TextField from '@mui/material/TextField';
// import styles from './style.module.scss';

export type AutocompleteFieldPropType = any & {
  loading?: boolean;
  options?: any;
  inputDebounce?: number;
  getOptionSelected?: any;
  getOptionLabel?: any;
  onInputChange?: any;
  filterOptions?: any;
  ListBoxProps?: any;
  error?: any;
  onChange?: any;
  onBlur?: any;
};

export default function AutocompleteField({
  loading,
  error,
  options = [],
  inputDebounce = 600,
  ListBoxProps,
  getOptionSelected,
  getOptionLabel,
  onInputChange,
  filterOptions,
  value,
  onChange,
  onBlur,
  ...rest
}: AutocompleteFieldPropType) {
  // TODO: fix types
  const inputHandler = useDebouncedCallback((v) => {
    if (onInputChange) onInputChange(v);
  }, inputDebounce);
  const selectedValue =
    value && options.find((v: any) => v.id === value) ? options.find((v: any) => v.id === value) : null;
  return (
    <Autocomplete
      isOptionEqualToValue={getOptionSelected ? getOptionSelected : (o) => o?.id}
      getOptionLabel={getOptionLabel ? getOptionLabel : (o) => o?.label}
      filterOptions={filterOptions}
      options={options}
      loading={loading}
      value={selectedValue}
      onChange={onChange ? (e, val: any) => onChange(val) : undefined}
      onBlur={onBlur}
      onInputChange={(e, v) => inputHandler.callback(v)}
      ListboxProps={ListBoxProps}
      renderInput={(params) => <TextField error={!!error} helperText={error} {...rest} {...params} />}
    />
  );
}
