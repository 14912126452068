import React, { useMemo } from 'react';
import moment from 'moment';
import { history, routes } from 'utils';
import { useState, useEffect, useUIStore, useCombineFiles } from 'utils/hooks';
import { MedicalApi, TemplateApi, ZCCApi } from 'apis';
import {
  AppointmentType,
  PatientType,
  ServiceRequestType,
  CommentType,
  CompositionType,
  CompositionStatusType,
} from 'apis/medical';
import { AppointmentForm, PatientForm, ServiceRequestForm } from 'components/forms';
import { MainNote, ActionTakenNotes } from 'components/forms/Notes';
import Grid from '@mui/material/Grid';
import { SidePanel, Button, FilesContainerV2, Comments } from 'components';
import { joinString } from 'utils/helper';
import capitalize from 'lodash/capitalize';

import styles from './style.module.scss';
import { ZccNote } from 'apis/zcc';

export type AppointmentSidePanelPropType = {
  open?: boolean;
  onClose?: () => void;
  onChange?: () => void;
  showCustomActions?: boolean;
  ogAppointmentId: string;
  ngAppointmentId?: string;
};

export default function Appointment({
  open,
  ogAppointmentId,
  ngAppointmentId,
  showCustomActions,
  onClose,
  onChange,
}: AppointmentSidePanelPropType) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>();
  const [appointment, setAppointment] = useState<AppointmentType | null>();
  const [comments, setComments] = useState<Array<CommentType> | null>();
  const [serviceRequest, setServiceRequest] = useState<ServiceRequestType | null>();
  const [patient, setPatient] = useState<PatientType | null>();
  const [soapNotes, setSoapNotes] = useState<Array<CompositionType>>([]);
  const [mainNote, setMainNote] = useState<ZccNote>();
  const [actionNote, setActionNote] = useState<ZccNote[]>();

  const { openModal, openAlert } = useUIStore();

  const { openCombineFiles } = useCombineFiles({
    serviceRequest,
    patient,
    notes: soapNotes,
    onError: (e) => {
      openAlert({ title: e, severity: 'error' });
    },
    downloadOnSuccess: true,
  });

  const fetchComments = async (appointmentId: string) => {
    const commentsRes = await MedicalApi.fetchOGAppointmentComments(appointmentId);
    setComments(commentsRes);
  };

  const fetchServiceRequestAndPatient = async (serviceRequestId: string) => {
    let p: PatientType | null = null;
    try {
      const sr = await MedicalApi.fetchServiceRequest(serviceRequestId);
      setServiceRequest(sr);

      if (sr.patient_id) {
        try {
          p = await MedicalApi.fetchPatient(sr.patient_id);
          setPatient(p);
        } catch (e) {
          setError('An error occurred while fetching the patient');
          setPatient(null);
        }
      }
      return {
        serviceRequest: sr,
        patient: p,
      };
    } catch (e) {
      setError('An error occurred while fetching the referral #');
      setServiceRequest(null);
      setPatient(null);
      throw e;
    }
  };

  const fetchNotes = async (appt: AppointmentType): Promise<void> => {
    try {
      const notes = await MedicalApi.fetchProcedureNotes(appt.procedure_id as string);
      setSoapNotes(notes);
    } catch (e) {
      setError('An error occurred while fetching the notes');
      setSoapNotes([]);
      throw e;
    }
  };

  const fetchData = async () => {
    setError(null);
    setLoading(true);
    try {
      const [appt, notes] = await Promise.all([
        MedicalApi.fetchOGAppointment(ogAppointmentId),
        ngAppointmentId
          ? ZCCApi.fetchNotesFromAppointment(ngAppointmentId)
          : Promise.resolve({
              mainNote: undefined,
              actionNote: undefined,
            }),
      ]);
      setAppointment(appt);
      setMainNote(notes.mainNote);
      setActionNote(notes.actionNote);

      const promises: Promise<any>[] = [fetchComments(ogAppointmentId)];
      if (appt?.service_request_id) promises.push(fetchServiceRequestAndPatient(appt.service_request_id));
      if (appt?.procedure_id && appt?.stage !== 'confirmed') promises.push(fetchNotes(appt));

      await Promise.allSettled(promises);
    } catch (e) {
      console.error(e);
      setError('An error occurred while fetching the appointment');
      setAppointment(null);
      setServiceRequest(null);
      setPatient(null);
      setMainNote(undefined);
      setActionNote(undefined);
    }
    setLoading(false);
  };

  // fetch data on mount
  useEffect(() => {
    if (ogAppointmentId) fetchData();
  }, [ogAppointmentId]); // eslint-disable-line react-hooks/exhaustive-deps

  const close = () => {
    if (onClose) {
      onClose();
    }
  };

  const openServiceRequest = () => {
    if (!appointment?.service_request_id) return;
    history.push(`${routes.MEDICAL_REFERRALS}?id=${appointment.service_request_id}`);
  };

  const openUpdateReferralExpirationModal = (serviceRequestId?: string, expirationDate?: string) => {
    openModal({
      id: 'update-referral-expiration-date',
      props: { serviceRequestId, expirationDate },
      callback: (newExpirationDate: string) => {
        if (serviceRequest && newExpirationDate) {
          setServiceRequest({
            ...serviceRequest,
            expiration_date: newExpirationDate,
          });
        }
      },
    });
  };

  const addNewComment = async (newComment: string) => {
    try {
      const newComments = await MedicalApi.addComment({
        appointmentId: ogAppointmentId,
        text: newComment,
      });
      setComments(newComments);
    } catch (e) {
      console.error(e);
    }
  };

  const editComment = async (commentId: string, newComment: string) => {
    try {
      const updatedComments = await MedicalApi.updateComment({
        appointmentId: ogAppointmentId,
        commentId,
        text: newComment,
      });
      setComments(updatedComments);
    } catch (e) {
      console.error(e);
    }
  };

  const deleteComment = async (commentId: string) => {
    try {
      await MedicalApi.deleteComment({
        appointmentId: ogAppointmentId,
        commentId,
      });
      setComments([...(comments || []).filter((c: any) => c.id !== commentId)]);
    } catch (e) {
      console.error(e);
    }
  };

  const preCheckBeforeAddingNote = async () => {
    if (soapNotes?.length > 0) {
      openModal({
        id: 'confirm',
        props: {
          title: 'SOAP note exists',
          description: 'Are you sure you want to add a SOAP note? This appointment already has one.',
        },
        callback: async (response: boolean) => {
          if (response) {
            addSoapNote();
          }
        },
      });
    } else {
      addSoapNote();
    }
  };

  const addSoapNote = async () => {
    openModal({
      id: 'add-soap-note',
      props: {
        appointment,
        patient,
        serviceRequest,
      },
      callback: async (response: boolean) => {
        if (response) {
          setLoading(true);
          await fetchNotes(appointment as AppointmentType);
          setLoading(false);
        }
      },
    });
  };

  const openCopyModal = () => {
    if (appointment) {
      openModal({
        id: 'copy-appointment-to-hsrm',
        props: { appointment },
        callback: (response: any) => {
          if (response) {
            onChange?.();
            fetchData();
          }
        },
      });
    }
  };

  const customActions = useMemo(() => {
    if (!showCustomActions) {
      return null;
    }
    if (appointment?.can_be_copied_into_hsrm) {
      let actionButtonName = 'Copy to HSRM';
      if (
        appointment?.first_service_request_appointment === true &&
        (appointment?.stage === 'confirmed' || (appointment?.stage === 'completed' && !appointment?.copied_into_hsrm))
      ) {
        actionButtonName = 'Add to HSRM';
      }
      return (
        <Button state='primary' onClick={() => openCopyModal()}>
          {actionButtonName}
        </Button>
      );
    }
    return null;
  }, [appointment, showCustomActions]);

  return (
    <SidePanel
      title='Appointment Record'
      loading={loading}
      alert={error}
      open={open}
      onClose={close}
      menuItems={
        !error
          ? [
              {
                icon: 'download',
                label: 'Download Patient Records',
                onClick: () => openCombineFiles(),
              },
            ]
          : []
      }>
      {customActions && <div className={styles.customActions}>{customActions}</div>}

      <Grid className={styles.section} container spacing={3}>
        {ngAppointmentId && (
          <>
            <Grid item xs={12}>
              <MainNote
                key={ngAppointmentId}
                note={mainNote}
                patientId={patient?.id}
                id={ngAppointmentId}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <ActionTakenNotes
                key={ngAppointmentId}
                notes={actionNote}
                patientId={patient?.id}
                id={ngAppointmentId}
                onChange={onChange}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Comments
            comments={comments}
            onAddComment={addNewComment}
            onEditComment={editComment}
            onDeleteComment={deleteComment}
          />
        </Grid>
      </Grid>

      <AppointmentForm appointment={appointment} marginBottom />
      <PatientForm patient={patient} marginBottom />
      <ServiceRequestForm serviceRequest={serviceRequest} marginBottom />

      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}>
          <FilesContainerV2
            title='SOAP Notes'
            files={soapNotes?.map((note: CompositionType, index: number) => {
              const { zcc_entry, date_authored, date_submitted } = note;
              let fileName = '';
              fileName += `${zcc_entry ? 'ZCC_' : ''}SOAP_Notes-${moment(date_authored || date_submitted).format(
                'YYYY-MM-DD'
              )}`;
              return {
                id: note.id,
                url: '',
                meta: {
                  fileName,
                  fileSize: 0,
                  mimeType: 'application/pdf',
                },
                description: joinString([
                  appointment?.provider?.fname,
                  appointment?.provider?.lname,
                  appointment?.provider?.npi ? `NPI (${appointment?.provider?.npi})` : '',
                ]),
                rightDescription: capitalize(soapNotes[index].status.replaceAll('-', ' ')),
              };
            })}
            onRowClick={(rowIndex: number) => {
              if (
                [CompositionStatusType.Approved, CompositionStatusType.Rejected].includes(soapNotes[rowIndex]?.status)
              ) {
                openModal({
                  id: 'read-only-soap-notes',
                  props: { noteId: soapNotes[rowIndex].id, status: soapNotes[rowIndex].status },
                });
              }
            }}
            downloadDocument={(soapNoteId: string) => TemplateApi.combineSoapNotesAndEvaluations([soapNoteId], [])}
            condensed
            {...(appointment?.status === 'fulfilled'
              ? {
                  actionText: 'Add New SOAP Note',
                  onActionClick: preCheckBeforeAddingNote,
                }
              : {})}
          />
        </Grid>
      </Grid>

      <div className={styles.actions}>
        <Button
          className={styles.customActions}
          state='primary'
          onClick={() => openUpdateReferralExpirationModal(serviceRequest?.id, serviceRequest?.expiration_date)}>
          Update Referral&apos;s Expiration Date
        </Button>
        <Button variant='outlined' color='primary' size='large' onClick={() => openServiceRequest()}>
          Open Referral
        </Button>
      </div>
    </SidePanel>
  );
}
