import Modal from '../templates/Modal';
import React, { useState, useCallback } from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useForm, useUIStore } from 'utils/hooks';
import { TextField } from 'components';
import { MedicalApi } from 'apis';
import format from 'date-fns/format';
import styles from './style.module.scss';
import { PutPatientOnHoldProps, OnHoldType } from './PutPatientOnHold.types';

export default function PutPatientOnHold({ patientId, patientName, onClose, modalProps }: PutPatientOnHoldProps) {
  const [selectedOnHoldOption, setSelectedOnHoldOption] = useState<OnHoldType>(OnHoldType.Scheduling);
  const [error, setError] = useState<string>();
  const { bind, form } = useForm();
  const { openModal } = useUIStore();

  const pickCalendarDate = useCallback(() => {
    return new Promise<Date | undefined>((resolve) => {
      openModal({
        id: 'pick-calendar-date',
        props: {
          description: `We'll resume scheduling ${patientName} on this date`,
        },
        callback: (date?: Date) => {
          resolve(date);
        },
      });
    });
  }, [patientName]);

  const onSubmit = useCallback(
    async (values: any, isHoldUntil: boolean) => {
      if (!selectedOnHoldOption) {
        return;
      }
      let body: any = {
        hold_status: selectedOnHoldOption,
        hold_status_reason: values[selectedOnHoldOption],
      };
      if (isHoldUntil) {
        const selectedDate = await pickCalendarDate();
        if (!selectedDate) {
          return;
        }
        body = {
          ...body,
          hold_status_date: format(selectedDate, 'yyyy-MM-dd'),
        };
      }
      try {
        await MedicalApi.updatePatient(patientId, body);
        onClose?.({
          ...body,
        });
      } catch (e) {
        console.log(e);
        setError('An error occurred while putting patient on hold.');
      }
    },
    [patientId, patientName, pickCalendarDate, selectedOnHoldOption]
  );

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <Modal
      {...modalProps}
      className={styles.base}
      alert={error}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={form.handleSubmit(onSubmit)}
      title={`Put ${patientName} on hold`}
      actions={[
        ...(selectedOnHoldOption !== OnHoldType.Scheduling
          ? [
              {
                label: 'Hold Indefinitely',
                state: 'secondary',
                onClick: form.handleSubmit((values: any) => onSubmit(values, false)),
              },
            ]
          : []),
        {
          label: 'Hold Until...',
          state: 'primary',
          onClick: form.handleSubmit((values: any) => onSubmit(values, true)),
        },
      ]}>
      <RadioGroup
        onChange={(e) => {
          setSelectedOnHoldOption(e.target.value as OnHoldType);
        }}>
        <FormControlLabel
          value={OnHoldType.Scheduling}
          control={<Radio checked={selectedOnHoldOption === OnHoldType.Scheduling} />}
          label='Scheduling hold (set a date to resume)'
        />
        <TextField
          {...bind(OnHoldType.Scheduling, { requiredIf: () => selectedOnHoldOption === OnHoldType.Scheduling })}
          className={selectedOnHoldOption === OnHoldType.Scheduling && styles.reason}
          style={selectedOnHoldOption !== OnHoldType.Scheduling ? { visibility: 'hidden', height: 0 } : {}}
          placeholder='Add a reason...'
          {...fieldProps}
        />
        <FormControlLabel
          value={OnHoldType.Medical}
          control={<Radio checked={selectedOnHoldOption === OnHoldType.Medical} />}
          label='Medical hold (optionally set a date to resume)'
        />
        <TextField
          {...bind(OnHoldType.Medical, { requiredIf: () => selectedOnHoldOption === OnHoldType.Medical })}
          className={selectedOnHoldOption === OnHoldType.Medical && styles.reason}
          style={selectedOnHoldOption !== OnHoldType.Medical ? { visibility: 'hidden', height: 0 } : {}}
          placeholder='Add a reason...'
          {...fieldProps}
        />
        <FormControlLabel
          value={OnHoldType.PatientReview}
          control={<Radio checked={selectedOnHoldOption === OnHoldType.PatientReview} />}
          label='Patient Review (optionally set a date to resume)'
        />
      </RadioGroup>
    </Modal>
  );
}
