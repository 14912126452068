import { useState, useEffect } from 'utils/hooks';
import { ZCCApi } from 'apis';
import { EFaxNotes } from 'components/forms/Notes';
import Grid from '@mui/material/Grid';
import { SidePanel } from 'components';

import styles from './style.module.scss';
import { ZccNote } from 'apis/zcc';

export type EFaxSidePanelPropType = {
  open?: boolean;
  onClose?: () => void;
  onChange?: () => void;
  eFaxId: string;
};

export default function EFax({ open, eFaxId, onClose, onChange }: EFaxSidePanelPropType) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>();
  const [eFaxNotes, setEFaxNotes] = useState<ZccNote[]>();

  const fetchData = async () => {
    setError(null);
    setLoading(true);
    try {
      const { notes } = await ZCCApi.fetchEFax(eFaxId);
      setEFaxNotes(notes);
    } catch (e) {
      console.error(e);
      setError('An error occurred while fetching the eFax notes');
      setEFaxNotes(undefined);
    }
    setLoading(false);
  };

  // fetch data on mount
  useEffect(() => {
    if (eFaxId) {
      fetchData();
    }
  }, [eFaxId]); // eslint-disable-line react-hooks/exhaustive-deps

  const close = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <SidePanel title='eFax Record' loading={loading} alert={error} open={open} onClose={close} menuItems={[]}>
      <Grid className={styles.section} container spacing={3}>
        {eFaxId && (
          <Grid item xs={12}>
            <EFaxNotes key={eFaxId} notes={eFaxNotes} eFaxId={eFaxId} onChange={onChange} />
          </Grid>
        )}
      </Grid>
    </SidePanel>
  );
}
