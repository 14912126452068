import React, { FC, useState } from 'react';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import MuiTextField from '@mui/material/TextField';
import { Formik, Field } from 'formik';
import Form from 'components/Form';
import { OfficeLocationStatusEnum } from 'apis/practitioner';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import { DatePicker } from 'formik-mui-x-date-pickers';
import { Icon, FieldTitle } from 'components';
import { useAuth } from 'utils/hooks';
import * as yup from 'yup';
import { OfficeLocationFormProps } from './OfficeLocationForm.types';
import { joinString } from 'utils/helper';
import styles from './style.module.scss';
import moment from 'moment';

const validationSchema = yup.object({
  address1: yup.string().required('Please fill out this field.'),
  unit: yup.string().notRequired(),
  city: yup.string().required('Please fill out this field.'),
  state: yup.string().required('Please fill out this field.'),
  zip: yup
    .string()
    .required('Please fill out this field.')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits'),
  status: yup.string().required('Please fill out this field.'),
  adaCompliant: yup.boolean().notRequired(),
  rejectionNote: yup.string().when('status', {
    is: OfficeLocationStatusEnum.REJECTED,
    then: yup.string().required('Please fill out this field.'),
    otherwise: yup.string().notRequired(),
  }),
  pausedNote: yup.string().when('status', {
    is: OfficeLocationStatusEnum.PAUSED,
    then: yup.string().required('Please fill out this field.'),
    otherwise: yup.string().notRequired(),
  }),
  pausedEndDate: yup.date().nullable().typeError('Invalid Date Format').notRequired(),
  closedNote: yup.string().notRequired(),
  officeNote: yup.string().notRequired(),
});

const OfficeLocationForm: FC<OfficeLocationFormProps> = ({
  location,
  title,
  addingNew,
  removable,
  editable,
  onEdit,
  onUndo,
  onSave,
  onRemove,
}) => {
  const [editing, setEditing] = useState(!!addingNew);
  const { user } = useAuth();

  const fieldProps = {
    fullWidth: true,
    InputProps: { disableUnderline: !editing, readOnly: !editing },
    InputLabelProps: {
      shrink: true,
    },
    variant: editing ? 'outlined' : 'standard',
  };

  const updatePractitioner = async (values: any, setSubmitting: (submitting: boolean) => void) => {
    setEditing(false);
    await onSave?.({
      ...location,
      address: {
        line: [values.address1].concat(values.unit ? [values.unit] : []),
        city: values.city,
        state: values.state,
        postal_code: values.zip,
      },
      status: values.status,
      ada_compliant: values.adaCompliant,
      office_note: values.officeNote,
      rejection_note: values.status === OfficeLocationStatusEnum.REJECTED ? values.rejectionNote : '',
      paused_note: values.status === OfficeLocationStatusEnum.PAUSED ? values.pausedNote : '',
      paused_to_date:
        values.status === OfficeLocationStatusEnum.PAUSED && values.pausedEndDate
          ? moment(values.pausedEndDate).format('MM/DD/YYYY')
          : '',
      closed_note: values.status === OfficeLocationStatusEnum.CLOSED ? values.closedNote : '',
      reviewed_by: user?.pccAgentName,
    });
    setSubmitting(false);
  };

  const handleEdit = () => {
    onEdit?.();
    setEditing(true);
  };

  const handleRemove = () => {
    setEditing(false);
    onRemove?.();
  };

  const renderFieldIcon = (isSubmitting: boolean): React.ReactElement | undefined => {
    if (isSubmitting || !editable || addingNew) {
      return;
    }
    if (!editing) {
      return <Icon name='edit-pencil' size={24} onClick={handleEdit} />;
    }
    if (removable) {
      return <Icon name='trash' size={24} onClick={handleRemove} />;
    }
    return;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        address1: location?.address?.line?.[0] || '',
        unit: location?.address?.line?.[1] || '',
        city: location?.address?.city || '',
        state: location?.address?.state || '',
        zip: location?.address?.postal_code || '',
        status: location?.status || OfficeLocationStatusEnum.PRELIMINARY,
        adaCompliant: location?.ada_compliant || false,
        rejectionNote: location?.rejection_note,
        pausedNote: location?.paused_note || '',
        pausedEndDate: location?.paused_to_date || '',
        closedNote: location?.closed_note || '',
        officeNote: location?.office_note || '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        updatePractitioner(values, setSubmitting);
      }}>
      {({ resetForm, handleSubmit, dirty, values, isSubmitting }) => (
        <Form
          editing={editing}
          onUndo={() => {
            if (dirty) {
              const confirm = window.confirm('You have unsaved changes that will be lost');
              if (confirm) {
                setEditing(false);
                resetForm();
              }
            } else {
              setEditing(false);
            }
            onUndo?.();
          }}
          dirty={dirty}
          onSave={handleSubmit}>
          <Grid item xs={12} alignItems='center'>
            <FieldTitle leftContent={renderFieldIcon(isSubmitting)}>{title}</FieldTitle>
          </Grid>
          <Grid item xs={6}>
            <Field component={TextField} type='text' label='Address' name='address1' {...fieldProps} />
          </Grid>
          <Grid item xs={6}>
            <Field component={TextField} type='text' label='Unit #' name='unit' {...fieldProps} />
          </Grid>
          <Grid item xs={6}>
            <Field component={TextField} type='text' label='City' name='city' {...fieldProps} />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              label='State'
              name='state'
              inputProps={{ maxLength: 2 }}
              {...fieldProps}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              label='Zip'
              name='zip'
              inputProps={{ maxLength: 5 }}
              {...fieldProps}
            />
          </Grid>
          <Grid item xs={6}>
            {editing ? (
              <Field
                component={CheckboxWithLabel}
                type='checkbox'
                Label={{ label: 'ADA Compliant' }}
                name='adaCompliant'
                {...fieldProps}
              />
            ) : (
              <MuiTextField
                label='ADA Compliant'
                {...fieldProps}
                disabled={isSubmitting}
                value={values.adaCompliant ? 'YES' : 'NO'}
                variant={'standard'}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <Field component={TextField} type='text' select label='Status' name='status' {...fieldProps}>
              <MenuItem value={OfficeLocationStatusEnum.PRELIMINARY}>Preliminary</MenuItem>
              <MenuItem value={OfficeLocationStatusEnum.APPROVED}>Approved</MenuItem>
              <MenuItem value={OfficeLocationStatusEnum.REJECTED}>Rejected</MenuItem>
              <MenuItem value={OfficeLocationStatusEnum.PAUSED}>Paused</MenuItem>
              <MenuItem value={OfficeLocationStatusEnum.CLOSED}>Closed</MenuItem>
              <MenuItem value={OfficeLocationStatusEnum.ENTERED_IN_ERROR}>Other</MenuItem>
            </Field>
          </Grid>
          <Grid item xs={6} container alignItems='center'>
            {!editing && location?.reviewed_by && (
              <p
                className={classNames(styles.lastSetBy, {
                  [styles.disabled]: isSubmitting,
                })}>
                {joinString([
                  'Last set by',
                  location.reviewed_by,
                  location.reviewed_date ? moment(location.reviewed_date).format('MM/DD/YY') : '',
                ])}
              </p>
            )}
          </Grid>
          {values.status === OfficeLocationStatusEnum.REJECTED && (
            <Grid item xs={12}>
              <Field component={TextField} type='text' label='Rejection Reason' name='rejectionNote' {...fieldProps} />
            </Grid>
          )}
          {values.status === OfficeLocationStatusEnum.PAUSED && (
            <>
              <Grid item xs={6}>
                <Field
                  component={DatePicker}
                  disablePast
                  readOnly={!editing}
                  format='MM/DD/yyyy'
                  placeholder={editing ? '10/10/2023' : ''}
                  label='Paused End Date'
                  name='pausedEndDate'
                  invalidDateMessage=''
                  clearable
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  {...fieldProps}
                  variant='inline'
                  inputVariant={fieldProps.variant}
                />
              </Grid>
              <Grid item xs={12}>
                <Field component={TextField} type='text' label='Paused Reason' name='pausedNote' {...fieldProps} />
              </Grid>
            </>
          )}
          {values.status === 'closed' && (
            <Grid item xs={12}>
              <Field component={TextField} type='text' label='Closed Reason' name='closedNote' {...fieldProps} />
            </Grid>
          )}
          {(editing || location?.office_note) && (
            <Grid item xs={12}>
              <Field
                component={TextField}
                type='text'
                label='Office Notes'
                name='officeNote'
                {...fieldProps}
                multiline
              />
            </Grid>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default OfficeLocationForm;
