import { FC, useEffect, useState } from 'react';
import Icon from '../../Icon';
import FieldTitle from '../../FieldTitle';
import Note from './Note';
import { useAuth, useUIStore } from 'utils/hooks';
import { ZccNote } from 'apis/zcc';
import { ZCCApi } from 'apis';
import { getCalendarTime } from 'utils/helper';
import moment from 'moment';
import { MainNoteProps } from './Notes.types';
import styles from './style.module.scss';

const MainNote: FC<MainNoteProps> = ({ note: originalNote, patientId, id, onChange }) => {
  const [editing, setEditing] = useState(false);
  const [note, setNote] = useState<ZccNote | undefined>(originalNote);

  const { user } = useAuth();
  const { openAlert } = useUIStore();

  useEffect(() => {
    setEditing(false);
    setNote(originalNote);
  }, [originalNote]);

  useEffect(() => {
    setEditing(false);
  }, [id]);

  const handleSave = async (newNote: string) => {
    if (!patientId) {
      return;
    }
    setEditing(false);
    const prevNote = note ? { ...note } : undefined;
    try {
      const pccAgentName = user?.pccAgentName;
      const noteAction = !note?.text ? ZCCApi.createNote : ZCCApi.updateNote;
      setNote({
        text: newNote,
        timestamp: new Date().toUTCString(),
        pcc_agent: pccAgentName,
      });
      await noteAction({
        note: {
          text: newNote,
          pcc_agent: pccAgentName,
        },
        noteType: 'main',
        patientId,
      });
      onChange?.();
    } catch {
      openAlert({ title: `Failed to save the main note`, severity: 'error' });
      setNote(prevNote);
    }
  };

  return (
    <>
      <FieldTitle icon='notes' margin>
        Main Note
      </FieldTitle>
      {note?.text && !editing && (
        <>
          <p className={styles.name}>
            Last edit {getCalendarTime(note.timestamp, true)}, {moment.utc(note.timestamp).local().format('h:mma z')} by{' '}
            {note.pcc_agent}
          </p>
          <div className={styles.mainNote}>
            <p className={styles.note}>{note.text}</p>
            <span>
              <Icon name='edit-pencil' size={24} onClick={() => setEditing(true)} />
            </span>
          </div>
        </>
      )}
      {(editing || !note?.text) && (
        <Note
          label='Type a Main Note...'
          note={note?.text}
          saveButtonLabel='Save Note'
          showActions={editing}
          onSave={handleSave}
          onClick={() => setEditing(true)}
          onCancel={() => setEditing(false)}
        />
      )}
    </>
  );
};

export default MainNote;
