import React from 'react';
import cn from 'classnames';
import Icon from '../Icon';
import styles from './style.module.scss';

export type FieldTitlePropType = {
  icon?: string | React.ReactElement;
  children?: React.ReactNode;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  margin?: boolean;
  className?: string;
};

export default function FieldTitle({
  icon,
  children,
  leftContent,
  rightContent,
  margin,
  className,
}: FieldTitlePropType) {
  return (
    <div className={cn(styles.base, { [styles.margin]: margin }, className)}>
      <div className={styles.content}>
        {icon &&
          (typeof icon === 'string' ? (
            <Icon name={icon as string} className={styles.icon} />
          ) : (
            React.cloneElement(icon, {
              className: cn(styles.icon, icon.props.className),
            })
          ))}
        <p className={styles.title}>{children}</p>
        {leftContent && <div className={styles.leftContent}>{leftContent}</div>}
      </div>
      {rightContent && <div>{rightContent}</div>}
    </div>
  );
}
