import { ModalPropType } from '../templates/Modal';
import {
  PatientType,
  ProviderType,
  ServiceRequestType,
  CompositionType,
  FacilityType,
  SoapNoteHistoryType,
} from 'apis/medical';
import { PractitionerType } from 'apis/practitioner';

export type ProviderNotesReviewProps = ModalPropType & {
  noteId: any;
};

export enum ExtensionRequestType {
  MANUAL_APPROVED = 'manual-approved',
  REJECTED = 'rejected',
}

export type ProviderNotesReviewHeaderProps = {
  loading: boolean;
  serviceRequest?: ServiceRequestType;
  notes: {
    rejectionNote?: string;
    noteStatus?: string;
    cptCode?: string;
    soapNoteHistory?: Array<SoapNoteHistoryType>;
  };
  appointment: {
    timeSegment?: string;
    practitioner?: PractitionerType;
    date?: string;
    start?: string;
    sessionNumber?: number;
  };
  patient?: PatientType;
  facility?: FacilityType;
};

export type SoapNoteHistoryReviewProps = {
  loading: boolean;
  histories: Array<SoapNoteHistoryType>;
};

export enum AdditionalNoteType {
  ADDENDUM = 'addendum',
  INSTRUCTION = 'instruction',
}

export type ProviderNotesAdditionalNoteProps = {
  id: string;
  title: string;
  text: string;
  hasPriorVersion: boolean;
  previousVersion?: any;
  priorNote?: string;
  soapNotes: CompositionType;
  user: any;
  form: any;
  bind: any;
  canAddAddendum: boolean;
  canAddInstruction: boolean;
  onStartEdit: (additionalNoteType: AdditionalNoteType) => void;
  onFinishEdit: () => void;
  onDeleteAdditionalNote: (additionalNoteType: AdditionalNoteType) => void;
};

export type ProviderNotesNotesReviewExtensionRequestProps = {
  extension: ExtensionRequestType;
  onExtensionChange: (ext: ExtensionRequestType) => void;
  bind: any;
};

export type ProviderNotesReviewAdditionalInformationProps = {
  patient?: PatientType;
  serviceRequest?: ServiceRequestType;
  provider?: ProviderType;
};
