import React, { FC, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Accordion, Button, FieldTitle, Icon } from 'components';
import { FormProps } from './Form.types';
import { isNil } from 'lodash';

const Form: FC<FormProps> = ({
  form,
  formRef,
  className,
  header = true,
  title,
  icon,
  marginBottom,
  children,
  editing,
  onEdit,
  onUndo,
  onSave,
  dirty,
  collapsible,
}) => {
  useEffect(() => {
    if (formRef) formRef(form);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderContent = () => {
    return (
      <Grid
        className={className}
        style={{ marginBottom: !collapsible && marginBottom ? (marginBottom === true ? '32px' : marginBottom) : '0' }}
        container
        spacing={3}>
        {!collapsible && title && header && (
          <Grid item xs={12}>
            <Grid container xs={12} direction='row' alignItems='center'>
              <FieldTitle icon={icon}>{title}</FieldTitle>
              {!isNil(editing) && (
                <Grid item style={{ marginLeft: 'auto', marginRight: 12 }}>
                  {!editing && <Icon name='edit-pencil' size={24} onClick={() => onEdit && onEdit()} />}
                  {editing && (
                    <>
                      <Icon name='undo-filled' size={24} onClick={onUndo} />
                      {dirty === true && (
                        <Icon name='checkmark-filled' style={{ marginLeft: 16 }} size={24} onClick={onSave} />
                      )}
                    </>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {children}
        {editing && (
          <>
            <Grid item xs={6}>
              <Button
                variant='outlined'
                state='primary'
                fullWidth
                startIcon={<Icon name='undo' size={24} />}
                onClick={onUndo}>
                {dirty ? 'Discard Changes' : 'Exit Edit Mode'}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                state='primary'
                fullWidth
                startIcon={<Icon name='checkmark' size={24} />}
                onClick={onSave}
                disabled={dirty === false}>
                Save Changes
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    );
  };
  return collapsible ? (
    <Accordion icon={icon} title={title} marginBottom={marginBottom}>
      {renderContent()}
    </Accordion>
  ) : (
    renderContent()
  );
};

export default Form;
