import React from 'react';
import { useEffect, useState, useUIStore } from 'utils/hooks';
import { SidePanel } from 'components';
import { PractitionerForm, AppointmentsForm } from 'components/forms/Practitioner';
import { MedicalApi, PractitionerApi } from 'apis';
import { PractitionerType } from 'apis/practitioner';
import { OfficeLocationForms } from 'components/forms/OfficeLocationForm';
import { AppointmentType } from 'apis/medical';

export type PractitionerSidePanelPropType = {
  open?: boolean;
  onClose?: () => void;
  practitionerId: string;
  onChange?: () => void;
  soapNoteAutoApprove?: boolean;
};

export default function PractitionerSidePanel(props: PractitionerSidePanelPropType) {
  const { openModal } = useUIStore();
  const [loading, setLoading] = useState(false);
  const [practitionerFromNG, setPractitionerFromNG] = useState<PractitionerType>();
  const [appointments, setAppointments] = useState<AppointmentType[]>();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const [_practitionerFromNG, appts] = await Promise.all([
        PractitionerApi.getPractitioner(props.practitionerId),
        MedicalApi.fetchAppointmentsOfPractitioner(props.practitionerId),
      ]);
      setPractitionerFromNG(_practitionerFromNG);
      setAppointments(appts);
      setLoading(false);
    };

    fetchData();
  }, [props.practitionerId]);

  const updatePractitionerAutomatedReview = (): void => {
    if (!practitionerFromNG) {
      return;
    }
    openModal({
      id: 'automated-review-settings',
      props: {
        practitionerId: practitionerFromNG.id,
        practitionerName: practitionerFromNG?.flattenName,
        automatedReviewThreshold: practitionerFromNG.automated_review_threshold,
        validated: practitionerFromNG.validated_provider,
      },
      callback: (response: any) => {
        if (response) {
          setPractitionerFromNG({
            ...practitionerFromNG,
            validated_provider: response.validated,
            automated_review_threshold: response.automatedReviewThreshold,
          });
        }
      },
    });
  };

  return (
    <SidePanel
      title='Practitioner Details'
      loading={loading}
      // alert={error?.message} TODO: Handle roles not existing for practitioners
      open={props.open}
      onClose={() => props.onClose && props.onClose()}
      menuItems={
        props.soapNoteAutoApprove
          ? [
              {
                icon: 'approve',
                label: 'Automated Review Settings',
                onClick: updatePractitionerAutomatedReview,
              },
            ]
          : []
      }>
      {practitionerFromNG && (
        <>
          <PractitionerForm practitioner={practitionerFromNG} />
          <OfficeLocationForms
            key={practitionerFromNG.id}
            practitionerId={practitionerFromNG.id}
            officeLocations={practitionerFromNG.office_based_address}
          />
          {appointments && appointments.length > 0 && <AppointmentsForm appointments={appointments} />}
        </>
      )}
    </SidePanel>
  );
}
