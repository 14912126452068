import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
    position: 'relative',
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  button: {
    position: 'absolute',
    height: '56px',
    width: '56px',
    right: '0',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    '&:hover, &:focus, &:active': {
      background: 'none',
      border: 'none',
      outline: 'none',
    },
  },
});
