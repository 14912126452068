import { PractitionerType } from './index';
import { joinString } from 'utils/helper';

export function transformApiPractitioners(apiPractitioners: Array<PractitionerType> = []): Array<PractitionerType> {
  let transformed: Array<any> = [];
  try {
    transformed = (apiPractitioners || [])
      .filter((i) => i)
      .map((apiPractitioner: PractitionerType): PractitionerType => {
        const { telecom, name, extension, identifier } = apiPractitioner;
        const email = telecom?.find((i) => i.system === 'email')?.value || '';
        const phone = telecom?.find((i) => i.system === 'phone')?.value || '';
        const automated_review_threshold =
          extension?.find((i) => i.url.includes('automated_review_threshold'))?.value_integer || 0;
        const zeelId = identifier?.find((i) => i.system === 'http://www.zeel.com/fhir/va/zeel_id')?.value || '';

        return {
          ...apiPractitioner,
          flattenName: joinString([...name[0].given, name[0].family]),
          email,
          phone,
          automated_review_threshold,
          zeelId,
        };
      });
  } catch (error) {
    console.log(error);
  }

  return transformed;
}
