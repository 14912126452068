class Validator {
  isEmail(email = '') {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    return re.test(email.toLowerCase());
  }

  isOnlyNumbers(text: any = '') {
    return /^\d+$/.test(text);
  }

  isPhone(text: any = '') {
    return /^[0-9 \-+()]+$/.test(text);
  }

  isOnlyLetters(text: any = '') {
    return /^[a-zA-Z -]+$/.test(text);
  }

  isUrl(str = '') {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator

    return !!pattern.test(str);
  }
}

export default new Validator();
