const routes = {
  LOGIN: `/login`,
  LOGIN_ROOT: `/login/:code?`,
  CODE_ONLY_ROOT: `/codeOnly/:code?`,

  DEFAULT: `/medical`, // only while there is no home page
  HOME: `/home`,
  MEDICAL: `/medical`,
  MANAGEMENT: `/management`,

  // Medical pages
  MEDICAL_DASHBOARD: `/medical/dashboard`,
  MEDICAL_REFERRALS: `/medical/referrals`,
  MEDICAL_PATIENTS: `/medical/patients`,
  MEDICAL_APPOINTMENTS: `/medical/appointments`,
  MEDICAL_TASKS: `/medical/tasks`,
  MEDICAL_MEDICAL_REVIEW: `/medical/medical-review`,
  MEDICAL_FACILITIES: `/medical/facilities`,
  MEDICAL_PATIENTS_GRAPH_QL: `/medical/patients-graphql`,
  MEDICAL_PRACTITIONERS: `/medical/practitioners`,
  MEDICAL_EFAX: `/medical/efax`,
  MEDICAL_RFS: `/medical/rfs`,

  MEDICAL_REFERRALS_OLD: `/medical/referrals-old`,
  MEDICAL_REFERRALS_OLD_ROOT: `/medical/referrals-old/:id?`,
  MEDICAL_APPOINTMENTS_OLD: `/medical/appointments-old`,
  MEDICAL_APPOINTMENTS_OLD_ROOT: `/medical/appointments-old/:id?`,
  MEDICAL_MEDICAL_REVIEW_OLD: `/medical/medical-review-old`,
  MEDICAL_MEDICAL_REVIEW_OLD_ROOT: `/medical/medical-review-old/:id?`,

  MEDICAL_REVIEW_SOAP_NOTES: `/medical/review-soap-notes`,

  // Test pages
  TABLE_TEST: '/medical/table-new',

  // Other
  APPOINTMENTS: `/appointments`,
  PROVIDERS: `/providers`,
  CUSTOMERS: `/customers`,
  BUSINESSES: `/businesses`,
  SETTINGS: `/settings`,

  PRIVATE_ROUTE_KICKBACK: `/login`,
};

export default routes;
