import React, { useLayoutEffect } from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { history, routes, c } from 'utils';
import { themeV5 } from 'styles/theme';
import { Route, Idler, AlertManager } from 'components';
import ModalManager from 'components/modals';

// disabled lazy loading since it doesn't work well with custom Route component and Redirect
import { Login, CodeOnly, Home, Medical } from 'pages';
import { useDispatch, useSelector } from 'react-redux';
import { isTokenExpired } from 'store/Auth';
import { AuthStore } from 'store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const dispatch = useDispatch();
  const storeState = useSelector((state) => state);

  useLayoutEffect(() => {
    if (isTokenExpired(storeState)) {
      dispatch(AuthStore.logout());
    }
  }, []);

  return (
    <div className='App'>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProviderV5 theme={themeV5}>
          <Router history={history}>
            <Switch>
              <Route public path={routes.LOGIN_ROOT} component={Login} />
              <Route public path={routes.CODE_ONLY_ROOT} component={CodeOnly} />
              <Route path={routes.HOME} component={Home} />
              <Route path={routes.MEDICAL} component={Medical} role={[c.ROLES.MEDICAL.ALL]} />
              <Redirect to={routes.DEFAULT} />
            </Switch>
          </Router>
          <Idler />
          <ModalManager />
          <AlertManager />
        </ThemeProviderV5>
      </LocalizationProvider>
      <ToastContainer />
    </div>
  );
}

export default App;
