import React, { useEffect, ReactNode } from 'react';
import Alert from '@mui/material/Alert';
import { UIStore } from 'store';
import { useSelector, useDispatch } from 'utils/hooks';
import styles from './style.module.scss';

export type AlertProps = {
  id: string;
  title: string;
  description?: ReactNode;
  severity?: 'success' | 'info' | 'warning' | 'error' | undefined;
  duration?: number;
  onClose?: () => void;
};

function AlertWrapper(props: AlertProps) {
  const { title, description, severity = 'success', duration = 5000, onClose } = props;
  useEffect(() => {
    if (onClose) {
      setTimeout(() => {
        onClose();
      }, duration);
    }
  }, [duration, onClose]);
  return (
    <Alert className={styles.alert} severity={severity}>
      {title}
      {description && <div className={styles.description}>{description}</div>}
    </Alert>
  );
}
export default function AlertManager() {
  const dispatch = useDispatch();
  const alerts = useSelector(UIStore.getAlerts);
  const closeAlert = (id: string) => {
    dispatch(UIStore.closeAlert({ id }));
  };

  return (
    <div className={styles.alertsContainer}>
      {alerts.map((alert: AlertProps) => {
        const { id } = alert;
        return <AlertWrapper key={id} {...alert} onClose={() => closeAlert(id)} />;
      })}
    </div>
  );
}
