import React from 'react';
import cn from 'classnames';
import { useEffect, useState } from 'utils/hooks';
import Alert from '@mui/material/Alert';
import { Icon, Button } from 'components';
import LinearProgress from '@mui/material/LinearProgress';
import Backdrop from '../Backdrop';
import styles from './style.module.scss';

export interface ActionType {
  label: string | React.ReactNode;
  action?: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  main?: boolean;
  disabled?: boolean;
  state?: string; // button state
  className?: string;
}

export interface ModalPropType {
  id?: string;
  title: string;
  description?: string | React.ReactNode;
  loading: boolean;
  alert?: string;
  alertSeverity?: 'error' | 'success' | 'info' | 'warning' | undefined;
  icon?: string;
  iconColor?: string;
  actions?: Array<ActionType>;
  stackActions?: boolean;
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
  footerClassName?: string;
  backdropClose?: boolean;
  warnBeforeClosing?: boolean;
  closable?: boolean;
  onBack?: (e?: React.MouseEvent<HTMLElement>) => void;
  children?: any;
  closing?: boolean;
  onClose?: (response?: any) => void | null;
  onSubmit?: (e?: React.FormEvent<EventTarget>) => void;
  scrollTop?: any; // scrolls to top of modal
  modalProps?: any;
}

export default function Modal({
  id,
  title,
  description,
  loading,
  alert,
  alertSeverity = 'error',
  icon,
  iconColor = 'lush',
  actions,
  stackActions,
  className,
  headerClassName,
  bodyClassName,
  footerClassName,
  backdropClose = true,
  warnBeforeClosing,
  closable = true,
  onBack,
  children,
  closing,
  onClose,
  onSubmit,
}: ModalPropType) {
  const [show, setShow] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    });
  }, []);

  const close = async (response: any = null) => {
    if (onClose) {
      if (warnBeforeClosing) {
        const result = await window.confirm(
          typeof warnBeforeClosing === 'string'
            ? warnBeforeClosing
            : 'Are you sure you want to close this modal? All unsaved changes will be lost.'
        );
        if (!result) return;
      }
      onClose(response);
    }
  };

  const submit = async (e: React.FormEvent<EventTarget>) => {
    if (e) e.preventDefault();
    setSubmitting(true);
    if (onSubmit) await onSubmit(e);
    setSubmitting(false);
  };

  return (
    <Backdrop closing={closing} onClick={closable && backdropClose ? () => close() : null}>
      <form
        id={id}
        onSubmit={submit}
        onClick={(e) => e.stopPropagation()}
        className={cn(styles.base, { [styles.show]: show && !closing }, className)}>
        <div className={styles.iconsBar}>
          <div className={styles.iconsWrapper}>{onBack && <Icon name='arrow-left' size={24} onClick={onBack} />}</div>
          <div className={styles.iconsWrapper}>{closable && <Icon name='x' size={24} onClick={() => close()} />}</div>
        </div>
        <div className={cn(styles.header, headerClassName)}>
          {alert && (
            <Alert className={styles.alert} severity={alertSeverity}>
              {alert}
            </Alert>
          )}
          {icon && <Icon className={styles.mainIcon} name={icon} color={iconColor} size={60} />}
          {title && <h1 className={styles.title}>{title}</h1>}
          {description && <p className={styles.description}>{description}</p>}
        </div>
        <div className={cn(styles.body, bodyClassName)}>{children}</div>
        <div
          className={cn(
            styles.footer,
            { [styles['footer--noHeight']]: !actions || actions.length === 0 },
            footerClassName
          )}>
          <div className={cn(styles.actions, { [styles['actions--stack']]: stackActions })}>
            {actions?.map((action: ActionType) => {
              const { label, action: presetAction, onClick, main, disabled, state, className: _className } = action;
              const presetActions: any = {
                close: () => close(),
                confirm: () => close(true),
              };
              const handler = (presetAction && presetActions[presetAction]) || onClick;
              return (
                <Button
                  key={label}
                  className={_className}
                  state={state || (main || presetAction === 'submit' ? 'primary' : 'secondary')}
                  loading={presetAction === 'submit' && !onClick && submitting}
                  type={(main || presetAction === 'submit') && !onClick ? 'submit' : 'button'}
                  fullWidth
                  disabled={disabled}
                  onClick={handler}>
                  {label}
                </Button>
              );
            })}
          </div>
        </div>
        {loading && (
          <div className={styles.loaderOverlay}>
            <div className={styles.loader}>
              <LinearProgress />
            </div>
          </div>
        )}
      </form>
    </Backdrop>
  );
}
