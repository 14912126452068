import React from 'react';
import { DocumentApi } from 'apis';
import { useForm, useUIStore, useState } from 'utils/hooks';
import { v4 as uuid } from 'uuid';
import Modal, { ModalPropType } from '../templates/Modal';
import Dropzone from 'react-dropzone';
import NoteAdd from '@mui/icons-material/NoteAdd';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { Icon, TextField } from 'components';
import { DocumentType } from 'types/document';
import styles from './style.module.scss';

type UploadedDocumentType = {
  documentId: string;
  url: string;
  documentType: DocumentType;
  fileName: string;
};

export type ContextualDocumentUploadProps = ModalPropType & {
  documentTypes: Array<{
    key: DocumentType;
    label: string;
  }>;
  contextualUploadHandler: (arg0: { [key: string]: Array<UploadedDocumentType> }) => void;
};

export default function ContextualDocumentUpload({
  documentTypes,
  contextualUploadHandler,
  onClose,
  scrollTop,
  modalProps,
}: ContextualDocumentUploadProps) {
  const { openAlert } = useUIStore();
  const [files, setFiles] = useState<any>([]);
  const { form, bind } = useForm();

  const onSubmit = async (values: any) => {
    const allFiles = (files || []).map((f: any) => {
      return {
        ...f,
        documentType: values[`fileType-${f?.id}`],
      };
    });

    // uploading all files
    const uploadPromises: Array<any> = [];
    let uploadedFilesUrls: Array<string> = [];

    (allFiles || []).forEach((f: any) => {
      if (f.uploadedUrl) {
        uploadedFilesUrls.push(f.uploadedUrl);
      } else {
        uploadPromises.push(DocumentApi.uploadDocument(f.file as File));
      }
    });

    try {
      const uploadedFiles = await Promise.all(uploadPromises);
      const newUploadedFilesUrls = uploadedFiles?.map((f: any) => f?.location);
      uploadedFilesUrls = [...uploadedFilesUrls, ...(newUploadedFilesUrls || [])];

      // calling contextualUploadHanler with types and uploaded document urls
      const data: { [key: string]: Array<UploadedDocumentType> } = {};
      (allFiles || []).forEach((f: any, i: any) => {
        const documentType = f.documentType;
        const url = (uploadedFilesUrls || [])[i];
        const documentId = (url || '').split('/').pop() || '';
        if (!data[documentType]) data[documentType] = [];
        data[documentType].push({
          fileName: f?.file?.name,
          documentType,
          documentId,
          url,
        });
      });

      // updating files list to store documentUrl and avoid multi-upload in case contextualUploadHandler fails
      setFiles(
        files.map((f: any, fIndex: any) => {
          return {
            ...f,
            uploadedUrl: uploadedFilesUrls[fIndex],
          };
        })
      );

      await contextualUploadHandler(data); // If error throws, it will catch and not close the modal

      openAlert({
        title: `${uploadedFilesUrls.length} File${uploadedFilesUrls.length > 1 ? 's' : ''} Uploaded Successfully`,
      });
      if (onClose) onClose?.(true);
    } catch (error: any) {
      form.setFormError(error?.message || 'An error occured while uploading the files.');
      scrollTop();
      console.error(error);
      return;
    }
  };

  const onSubmitError = async () => {
    form.setFormError('Please fix the errors below');
    scrollTop();
  };

  const fileAdded = (f: Array<File>) => {
    const newFiles = [...files];
    f.forEach((raw) => {
      newFiles.push({
        id: uuid(),
        file: raw,
      });
    });
    setFiles(newFiles);
  };

  const deleteFile = (fileIndex: number) => {
    const updatedFiles = [...(files || [])];
    const deletedFile = updatedFiles.splice(fileIndex, 1)?.[0];
    if (deletedFile) {
      form.deleteField(`fileType-${deletedFile?.id}`);
    }
    setFiles(updatedFiles);
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <Modal
      {...modalProps}
      alert={form.formError}
      title='Upload Files'
      className={styles.base}
      backdropClose={false}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      warnBeforeClosing={files?.length > 0}
      onSubmit={form.handleSubmit(onSubmit, onSubmitError)}
      actions={[
        { label: 'Cancel', action: 'close' },
        { label: 'Finish', action: 'submit' },
      ]}>
      <Grid className={styles.fields} container spacing={3}>
        <Grid item xs={12}>
          <Dropzone onDropAccepted={fileAdded}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: styles.dropzone })}>
                <input {...getInputProps()} />
                <NoteAdd fontSize='large' />
                <p>Drag and drop files or click to upload</p>
              </div>
            )}
          </Dropzone>
        </Grid>
        <Grid item xs={12}>
          {(files || []).length > 0 && (
            <p className={styles.filesContainerLabel}>
              {(files || []).length} File{(files || []).length > 1 ? 's' : ''}
            </p>
          )}
          <div className={styles.filesContainer}>
            {files?.map((file: any, fileIndex: any) => {
              const { id, uploadedUrl } = file;
              const { name } = file?.file;
              return (
                <div key={`${name}-${fileIndex}`} className={styles.fileRow}>
                  <div className={styles.file}>
                    {name}
                    {!uploadedUrl && (
                      <Icon
                        className={styles.fileDeleteIcon}
                        name='trash'
                        size={24}
                        onClick={() => deleteFile(fileIndex)}
                      />
                    )}
                    {uploadedUrl && <Icon className={styles.fileCheckmarkIcon} name='checkmark' size={24} />}
                  </div>
                  <div className={styles.fileType}>
                    <TextField
                      {...bind(`fileType-${id}`, { required: true })}
                      select
                      label={fileIndex === 0 ? 'File Type' : null}
                      {...fieldProps}>
                      {documentTypes?.map((type: any, i: number) => (
                        <MenuItem key={`${type?.key}-${i}`} value={type?.key}>
                          {type?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
}
