import React, { useState, useEffect } from 'react';
import Modal from '../templates/Modal';
import styles from './style.module.scss';
import { SoapNotePDFViewProps } from './SoapNotePDFView.types';
import { TemplateApi } from 'apis';
import { useInvalidateNote } from 'utils/hooks';
import { helper } from 'utils';
import { PDFView } from 'components';

export default function SoapNotePDFView({ soapNoteId, onClose, modalProps }: SoapNotePDFViewProps) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [base64String, setBase64String] = useState('');

  const { invalidateNote } = useInvalidateNote(() => onClose?.(soapNoteId));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseBlob = await TemplateApi.combineSoapNotesAndEvaluations([soapNoteId], []);
        const base64 = await helper.blobToBase64(responseBlob);
        setBase64String(base64);
      } catch (e) {
        setError('An error occurred while loading the SOAP note pdf');
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Modal
      {...modalProps}
      loading={loading}
      alert={error}
      actions={[
        {
          label: 'Invalidate SOAP Note',
          state: 'danger',
          onClick: () => invalidateNote(soapNoteId),
        },
      ]}
      title={loading ? 'SOAP Note' : ''}
      className={styles.base}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={() => onClose?.()}>
      {!loading && !error && <PDFView file={base64String} />}
    </Modal>
  );
}
