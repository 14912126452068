/* eslint-disable max-len */
import React, { useEffect, Fragment } from 'react';
import moment from 'moment';
import { MedicalApi, DocumentApi, ZrefApi } from 'apis';
import { useState, useUIStore, useForm } from 'utils/hooks';
import PatientForm from 'components/forms/Patient';
import Modal, { ModalPropType } from '../templates/Modal';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import ReplayIcon from '@mui/icons-material/Replay';
import { TextField, ToggleSection, ToggleSections } from 'components';
import styles from './style.module.scss';

export type RequestForServicesProps = ModalPropType & {
  requestId: string;
  serviceRequestId?: string;
};

export default function RequestForServices({
  requestId,
  serviceRequestId,
  onClose,
  scrollTop,
  modalProps,
}: RequestForServicesProps) {
  const { openAlert, openModal } = useUIStore();
  const { bind, form } = useForm();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [conditionOriginalShown, setConditionOriginalShown] = useState(false);
  const [reasonOriginalShown, setReasonOriginalShown] = useState(false);
  const [request, setRequest] = useState<any>();
  const [notes, setNotes] = useState<any>();

  const mapToKey: any = {
    numberOfVisits: 'number_of_visits',
    dateStart: 'anticipated_date_care_begins',
    dateEnd: 'anticipated_date_care_ends',
    condition: 'provisional_diagnosis',
    reason: 'reason_for_requested_service_and_scheduling_instructions',
  };

  useEffect(() => {
    async function fetchData() {
      // fetch request
      try {
        const r: any = await ZrefApi.fetchRequest(requestId);
        setRequest(r);

        form.setFields({
          numberOfVisits: r.number_of_visits,
          condition: r.provisional_diagnosis,
          reason: r.reason_for_requested_service_and_scheduling_instructions,
          dateStart: r.anticipated_date_care_begins,
          dateEnd: r.anticipated_date_care_ends,
        });
      } catch (e) {
        setError('An error occured while fetching the request');
      }

      // fetch notes
      if (serviceRequestId) {
        try {
          const procedure = await MedicalApi.fetchProcedureByServiceRequestId(serviceRequestId);
          if (procedure && procedure.id) {
            const n = await MedicalApi.fetchProcedureNotes(procedure.id);
            setNotes(n);
          }
        } catch (e) {
          // setError('An error occured while fetching the soap notes')
        }
      }

      setLoading(false);
    }
    fetchData();
  }, [requestId, serviceRequestId]); // eslint-disable-line react-hooks/exhaustive-deps

  const acceptRequest = async (values: any = {}) => {
    const { numberOfVisits, dateStart, dateEnd, condition, reason } = values;

    const {
      facility,
      patient,
      provider,
      initiating_referral_number,
      medical_specialties = [],
      surgical_specialties = [],
      supporting_services = [],
      type_of_request,
      service_requested,
      service_type,
      preference_for_location_of_service,
    } = request;

    try {
      const template = await DocumentApi.getFormTemplateFields(
        MedicalApi.constants.formTemplates.VA_REQUEST_FOR_ADDITIONAL_SERVICES
      );

      const servicesRequested: any = {};
      const typeOfRequest: any = {};
      const serviceType: any = {};
      const medicalSpecialties: any = {};
      const surgicalSpecialties: any = {};
      const supportingServices: any = {};
      const preferenceForLocation: any = {};

      Object.keys(template).forEach((key: string) => {
        const value = template[key];
        if (
          value?.field_name?.includes('ServiceRequested') &&
          value?.alternate_field_name?.includes(service_requested)
        ) {
          servicesRequested[key] = true;
        }
        if (value?.field_name?.includes('TypeRequest') && value?.alternate_field_name?.includes(type_of_request)) {
          typeOfRequest[key] = true;
        }
        if (value?.field_name?.includes('ServiceType') && value?.alternate_field_name?.includes(service_type)) {
          serviceType[key] = true;
        }
        if (
          value?.field_name?.includes('Preference') &&
          value?.alternate_field_name?.includes(preference_for_location_of_service)
        ) {
          preferenceForLocation[key] = true;
        }
        medical_specialties.forEach((specialty: string) => {
          if (value?.field_name?.includes('MedicalSpeciality') && value?.alternate_field_name?.includes(specialty)) {
            medicalSpecialties[key] = true;
          }
        });
        surgical_specialties.forEach((specialty: string) => {
          if (value?.field_name?.includes('SurgicalSpeciality') && value?.alternate_field_name?.includes(specialty)) {
            surgicalSpecialties[key] = true;
          }
        });
        supporting_services.forEach((service: string) => {
          if (value?.field_name?.includes('SupportingServices') && value?.alternate_field_name?.includes(service)) {
            supportingServices[key] = true;
          }
        });
      });

      // create pdf
      const pdfFields = {
        'UniqueID[0]': initiating_referral_number,
        'TodayDate[0]': moment().format('MM-DD-YYYY'),
        'AnticipatedDateBegins[0]': moment(dateStart).format('MM-DD-YYYY'),
        'AnticipatedDateEnds[0]': moment(dateEnd).format('MM-DD-YYYY'),
        'NumberVisits[0]': numberOfVisits + '',
        'ProvisionalDiagnosis[0]': condition,
        'Reason[0]': reason,
        'FacilityAddress[0]': [facility?.line_1, facility?.line_2].filter((a) => a).join(', '),
        'FacilityName[0]': facility?.name,
        'PhoneNumber[0]': facility?.phone,

        // provider
        'ProviderName[0]': `${provider?.given_name?.join(' ')} ${provider?.family_name}`,
        'DaytimeNumber[0]': provider?.phone,
        'EmergencyNumber[0]': provider?.emergency_contact,
        'ProviderEmail[0]': provider?.email,
        'ProviderFax[0]': provider?.fax,
        'PracticeName[0]': provider?.individual_practice_name || provider?.group_practice_name,
        'PracticeNPI[0]': provider?.individual_practice_npi || provider?.group_practice_npi,
        'Speciality[0]': provider?.specialty_type,

        // vet
        'VetName[0]': `${patient?.given_name?.join(' ')} ${patient?.family_name}`,
        'BirthDate[0]': moment(patient.birth_date).format('MM-DD-YYYY'),
        'SSN[0]': patient?.ssn,

        // checkboxes
        ...servicesRequested,
        ...typeOfRequest,
        ...serviceType,
        ...preferenceForLocation,
        ...medicalSpecialties,
        ...surgicalSpecialties,
        ...supportingServices,

        // signature
        'SignatureDate[0]': moment().format('MM-DD-YYYY'),
      };

      const pdfResponse = await DocumentApi.fillFormTemplate(
        MedicalApi.constants.formTemplates.VA_REQUEST_FOR_ADDITIONAL_SERVICES,
        pdfFields
      );
      await ZrefApi.updateRequest(requestId, { document_url: pdfResponse.location });
      await ZrefApi.acceptRequest(requestId);

      openAlert({ title: `The request was accepted` });
      onClose?.(true);
    } catch (e) {
      setError('An error occured while accepting the request');
      scrollTop();
      console.error(e);
    }
  };

  const rejectRequest = async () => {
    openModal({
      id: 'reject-request-for-additional-services',
      props: {
        requestId,
      },
      callback: (response: any) => {
        if (response) {
          onClose?.(true);
        }
      },
    });
  };

  const revertField = (fieldId: string) => {
    openModal({
      id: 'confirm',
      props: {
        title: 'Revert this field to original submission?',
        description:
          'Are you sure that you want to remove all changes you’ve made to this field and revert to the provider’s original entry?',
      },
      callback: (response: any) => {
        if (response) {
          form.setField(fieldId, getOriginal(fieldId));
        }
      },
    });
  };

  const isEdited = (fieldId: string) => {
    const apiKey = mapToKey[fieldId];
    const dirty = request && request?.[apiKey] && request[apiKey] !== form.getField(fieldId);
    return dirty;
  };

  const getOriginal = (fieldId: string) => {
    const apiKey = mapToKey[fieldId];
    return request?.[apiKey];
  };

  const fieldProps = (fieldId: string) => {
    const dirty = isEdited(fieldId);
    return {
      fullWidth: true,
      variant: 'outlined',
      InputLabelProps: {
        shrink: true,
      },
      InputProps: {
        endAdornment: (
          <InputAdornment position='end'>
            {dirty && <ReplayIcon onClick={() => revertField(fieldId)} style={{ cursor: 'pointer' }} />}
          </InputAdornment>
        ),
      },
    };
  };

  return (
    <Modal
      {...modalProps}
      loading={loading}
      alert={error}
      title='Review Request for Additional Services'
      description={
        <>
          Please review this provider’s request before submitting to HSRM. You may edit any of the fields prior to
          submission. We’ll keep a record of the original request. Need help? Read the Process Overview
        </>
      }
      className={styles.base}
      footerClassName={styles.footer}
      backdropClose={false}
      onSubmit={form.handleSubmit(acceptRequest)}
      actions={[
        { label: 'Accept Request & Publish PDF', action: 'submit' },
        { label: 'Reject Request', state: 'danger', className: styles.rejectButton, onClick: rejectRequest },
      ]}
      stackActions>
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}>
          <p className={styles.title}>1. Review Requested Session Information</p>
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...bind('numberOfVisits', { required: true })}
            label='Number of Additional Visits'
            autoFocus
            {...fieldProps('numberOfVisits')}
          />
        </Grid>
      </Grid>
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={6}>
          <TextField
            {...bind('dateStart', { required: true })}
            label='Anticipated Date When Care Begins'
            type='date'
            {...fieldProps('dateStart')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...bind('dateEnd', { required: true })}
            label='Anticipated Date When Care Ends'
            type='date'
            {...fieldProps('dateEnd')}
          />
        </Grid>
      </Grid>
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}>
          <p className={styles.title}>2. Review Provisional Diagnosis</p>
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...bind('condition', { required: true })}
            label='Description of Patient’s Condition'
            multiline
            {...fieldProps('condition')}
          />
          {isEdited('condition') && (
            <p
              className={styles.toggleOriginal}
              onClick={() => setConditionOriginalShown(conditionOriginalShown ? false : true)}>
              {conditionOriginalShown ? 'Hide' : 'Show'} Original
            </p>
          )}
          {conditionOriginalShown && <p className={styles.originalText}>{getOriginal('condition')}</p>}
        </Grid>
      </Grid>
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}>
          <p className={styles.title}>3. Review Reason for Requested Service</p>
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...bind('reason', { required: true })}
            label='Why Patient Needs Additional Services'
            multiline
            {...fieldProps('reason')}
          />
          {isEdited('reason') && (
            <p
              className={styles.toggleOriginal}
              onClick={() => setReasonOriginalShown(reasonOriginalShown ? false : true)}>
              {reasonOriginalShown ? 'Hide' : 'Show'} Original
            </p>
          )}
          {reasonOriginalShown && <p className={styles.originalText}>{getOriginal('reason')}</p>}
        </Grid>
      </Grid>

      <Grid className={styles.section} container spacing={3}>
        <ToggleSection title='SOAP Notes' disabled={!notes || loading}>
          <div className={styles.soapNotesContainer}>
            <ToggleSections
              sections={notes?.map((note: any) => {
                const { id, date_submitted, meta, notes: _notes } = note;
                return {
                  id,
                  icon: 'notes',
                  title: moment(date_submitted || meta?.lastUpdated).format('LLL'),
                  level: 1,
                  borders: true,
                  children: (
                    <div className={styles.noteContent}>
                      {_notes?.map((n: any) => {
                        const { title, text } = n;
                        return (
                          <Fragment key={title}>
                            <p className={styles.noteTitle}>{title}</p>
                            <p className={styles.noteDescription}>{text}</p>
                          </Fragment>
                        );
                      })}
                    </div>
                  ),
                };
              })}
            />
          </div>
        </ToggleSection>
      </Grid>

      <Grid className={styles.section} container spacing={3}>
        <ToggleSection title='Additional Information' disabled={loading}>
          {request?.patient && <PatientForm patient={request.patient} marginBottom />}
        </ToggleSection>
      </Grid>
    </Modal>
  );
}
