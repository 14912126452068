import React from 'react';
import { ZrefApi } from 'apis';
import { useState, useUIStore, useForm } from 'utils/hooks';
import Modal, { ModalPropType } from '../templates/Modal';
import Grid from '@mui/material/Grid';
import { TextField } from 'components';
import styles from './style.module.scss';

export type RejectRFSProps = ModalPropType & {
  requestId: string;
};

export default function RejectRFS({ requestId, onClose, scrollTop, modalProps }: RejectRFSProps) {
  const { openAlert } = useUIStore();
  const { bind, form } = useForm();
  const [error, setError] = useState<string>();

  const onSubmit = async (values: any) => {
    const { reason } = values;

    try {
      await ZrefApi.rejectRequest(requestId, reason);

      openAlert({ title: `The request was rejected`, severity: 'info' });
      onClose?.(true);
    } catch (e) {
      setError('An error occurred while rejecting the request');
      scrollTop();
      console.error(e);
    }
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'filled',
    InputProps: {
      disableUnderline: true,
    },
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <Modal
      {...modalProps}
      alert={error}
      title='How should the Provider improve this submission?'
      description={
        <>Your comments will be returned to the Provider and they&apos;ll be asked to re-submit an improvement</>
      }
      className={styles.base}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={form.handleSubmit(onSubmit)}
      actions={[
        { label: 'Back', action: 'close' },
        { label: 'Send to Provider', action: 'submit' },
      ]}>
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}>
          <TextField
            {...bind('reason', { required: true })}
            multiline
            rows={5}
            label='How to Improve this Submission'
            placeholder='Type a note to the provider...'
            {...fieldProps}
          />
        </Grid>
      </Grid>
    </Modal>
  );
}
