import React, { useEffect } from 'react';
import moment from 'moment';
import { MedicalApi } from 'apis';
import { AppointmentType, ServiceRequestType } from 'apis/medical';
import { useState, useUIStore, useForm, useCopyToClipboard, useAuth } from 'utils/hooks';
import Modal, { ModalPropType } from '../templates/Modal';
import Grid from '@mui/material/Unstable_Grid2';
import InputAdornment from '@mui/material/InputAdornment';
import { TextField } from 'components';
import cn from 'classnames';
import styles from './style.module.scss';
import { DuplicateCopyBoldIcon } from '@zeel-dev/zeel-ui';

export type CopyAppointmentToHsrmV2Props = ModalPropType & {
  ogAppointmentId: string;
  entityId: string;
  toAdd?: boolean;
  title?: string;
  completedByAttribute: string;
};

export default function CopyAppointmentToHsrmV2({
  ogAppointmentId,
  entityId,
  toAdd,
  title,
  completedByAttribute,
  onClose,
  scrollTop,
  modalProps,
}: CopyAppointmentToHsrmV2Props) {
  const { openAlert } = useUIStore();
  const { copyToClipboard } = useCopyToClipboard();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [appointment, setAppointment] = useState<any>();
  const [serviceRequest, setServiceRequest] = useState<any>();
  const { bind, form } = useForm({
    expirationDate: null,
  });
  const { user } = useAuth();

  useEffect(() => {
    async function fetchData() {
      let sr: ServiceRequestType | null = null;
      let _appointment: AppointmentType | null = null;

      try {
        _appointment = await MedicalApi.fetchOGAppointment(ogAppointmentId);
        setAppointment(_appointment);
      } catch (e) {
        setError('An error occured while fetching the OG appointment');
      }

      if (_appointment?.service_request_id) {
        try {
          sr = (await MedicalApi.fetchServiceRequest(_appointment.service_request_id)) as ServiceRequestType;
          setServiceRequest(sr);
        } catch (e) {
          setError('An error occured while fetching the referral #');
        }
      }

      setLoading(false);
    }
    fetchData();
  }, [ogAppointmentId]);

  const onSubmit = async (values: any) => {
    try {
      await MedicalApi.updateAppointment(entityId, {
        copied_to_hsrm: true,
        [completedByAttribute]: user.memberId,
      });

      // if first appt (add to hsrm), update expiration date using the field value
      const hasToUpdateExpirationDate = values?.expirationDate && serviceRequest?.id;
      if (hasToUpdateExpirationDate) {
        await MedicalApi.updateServiceRequest(serviceRequest?.id, {
          expiration_date: values.expirationDate,
        });
      }

      openAlert({
        title: `Appointment Marked as Copied to HSRM${
          hasToUpdateExpirationDate ? `, and expiration date updated.` : ''
        }`,
      });

      onClose?.(true);
    } catch (e) {
      setError('An error occured while marking the appointment as copied to HSRM');
      scrollTop();
      console.error(e);
    }
  };

  const onSubmitError = async () => {
    form.setFormError('Please fix the errors below');
    scrollTop();
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    disabled: true,
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      style: { pointerEvents: 'none' },
      endAdornment: (
        <InputAdornment position='end'>
          <DuplicateCopyBoldIcon size={24} />
        </InputAdornment>
      ),
    },
  };

  const expirationFieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  const copy = (value = '') => {
    copyToClipboard(value);
  };

  /*
    If an appointment is “added” to hsrm for the first time, we show the expiration date to be editable.
    If an appointment needs to be “updated” in hsrm, no need to show the expiration date
  */
  const showExpiration = toAdd && !appointment?.copied_into_hsrm && appointment?.first_service_request_appointment;

  return (
    <Modal
      {...modalProps}
      loading={loading}
      alert={error}
      title={title || 'Add Appointment to HSRM'}
      description={
        <>
          Follow these steps to move the appointment details to HSRM.
          <br />
          Need help? Read the Process Overview.
          <p className={cn(styles.copyWrapper, styles.appointmentId)} onClick={() => copy(ogAppointmentId)}>
            Appointment ID {ogAppointmentId}
            <DuplicateCopyBoldIcon size={24} />
          </p>
        </>
      }
      className={styles.base}
      footerClassName={styles.footer}
      onSubmit={form.handleSubmit(onSubmit, onSubmitError)}
      actions={[
        { label: 'Back', action: 'close' },
        { label: 'Finish', action: 'submit' },
      ]}>
      <Grid className={styles.section} container spacing={3}>
        <Grid xs={12}>
          <p className={styles.title}>1. Find referral in HSRM portal</p>
        </Grid>
        <Grid xs={6} className={styles.copyWrapper} onClick={() => copy(serviceRequest?.referral_number)}>
          <TextField value={serviceRequest?.referral_number} label='Referral #' {...fieldProps} />
        </Grid>
      </Grid>
      <Grid className={styles.section} container spacing={3}>
        <Grid xs={12}>
          <p className={styles.title}>2. Add Appointment Date and Time in HSRM</p>
        </Grid>
        <Grid
          xs={6}
          className={styles.copyWrapper}
          onClick={() => copy(moment(appointment?.date).tz('America/New_York').format('YYYY-MM-DD'))}>
          <TextField
            value={moment(appointment?.date).tz('America/New_York').format('YYYY-MM-DD')}
            label='Appointment Date'
            type='date'
            {...fieldProps}
          />
        </Grid>
        <Grid
          xs={6}
          className={styles.copyWrapper}
          onClick={() => copy(moment(appointment?.date).tz('America/New_York').format('HH:mm z'))}>
          <TextField
            value={moment(appointment?.date).tz('America/New_York').format('HH:mm z')}
            label='Appointment Time (Military Time)'
            {...fieldProps}
          />
        </Grid>
        {appointment?.provider && (
          <>
            <Grid xs={6} className={styles.copyWrapper} onClick={() => copy(appointment?.provider?.npi)}>
              <TextField value={appointment?.provider?.npi} label='Provider NPI' {...fieldProps} />
            </Grid>
            <Grid
              xs={6}
              className={styles.copyWrapper}
              onClick={() => copy(`${appointment?.provider?.fname} ${appointment?.provider?.lname}`)}>
              <TextField
                value={`${appointment?.provider?.fname} ${appointment?.provider?.lname}`}
                label='Provider Name'
                {...fieldProps}
              />
            </Grid>
          </>
        )}
      </Grid>
      {showExpiration && (
        <Grid className={styles.section} container spacing={3}>
          <Grid xs={12}>
            <p className={styles.title}>3. Update referral’s expiration date</p>
          </Grid>
          <Grid xs={6}>
            <TextField
              {...bind('expirationDate', {
                required: true,
                validate: (val: any) => {
                  if (val && moment(val, 'YYYY-MM-DD').diff(moment()) < 0)
                    return 'Needs to be greater than the issue date';
                  return null;
                },
              })}
              label='New Expiration Date'
              type='date'
              {...expirationFieldProps}
            />
            <p className={styles.currentExpDateHelper}>
              Current expiration date is {moment(serviceRequest?.expiration_date).format('MM/DD/YYYY')}
            </p>
          </Grid>
        </Grid>
      )}
    </Modal>
  );
}
