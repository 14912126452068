import React from 'react';
import Modal from '../templates/Modal';
import Grid from '@mui/material/Grid';
import moment from 'moment-timezone';
import { NoteType } from 'apis/medical';
import { useInvalidateNote } from 'utils/hooks';
import styles from './style.module.scss';
import { SoapNoteViewProps } from './SoapNoteView.types';

export default function SoapNoteView({ note, appointment, patient, onClose, modalProps }: SoapNoteViewProps) {
  const titleMap: Record<string, string> = {
    subjective: 'Subjective',
    objective: 'Objective',
    assessment: 'Assessment',
    treatment: 'Treatment',
    plan: 'Plan',
  };

  const { invalidateNote } = useInvalidateNote(() => onClose?.(note.id));

  return (
    <Modal
      {...modalProps}
      title={`SOAP Notes${note.appointment_date ? ' ' + moment(note.appointment_date).format('YYYY-MM-DD') : ''}`}
      description={
        <>
          {appointment?.provider &&
            `Submitted by ${appointment.provider?.fname} ${appointment.provider?.lname} 
              ${appointment.provider?.npi ? `(NPI ${appointment.provider.npi})` : ''}`}
          <br />
          For patient {patient?.given_names?.join(' ')} {patient?.family_name}{' '}
          {note.appointment_date
            ? `on ${moment(note.appointment_date).tz('America/New_York').format('MMMM D HH:mm z')}`
            : ''}
        </>
      }
      actions={[
        {
          label: 'Invalidate SOAP Note',
          state: 'danger',
          onClick: () => invalidateNote(note.id),
        },
      ]}
      className={styles.base}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={() => onClose?.()}>
      {note.notes.map((n: NoteType) => (
        <Grid key={n.title} className={styles.section} container>
          <Grid xs={12} className={styles.title} item>
            {titleMap[n.title] ?? n.title}
          </Grid>
          <Grid xs={12} className={styles.text} item>
            {n.text}
          </Grid>
        </Grid>
      ))}
    </Modal>
  );
}
