import React, { useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';
import { useState } from 'utils/hooks';
import { history } from 'utils';
import { MedicalApi } from 'apis';
import { AppointmentType } from 'apis/medical';
import { SectionLayout, Table, FilterButtonV2 } from 'components';
import AppointmentSidePanel from 'components/sidePanels/Appointment';
import styles from './style.module.scss';

export default function Appointments() {
  const [open, setOpen] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<string>();
  const [appointments, setAppointments] = useState<Array<AppointmentType>>([]);
  const [activeRowId, setActiveRowId] = useState<any>(null);

  // hooks for data fetching
  const urlFilters = qs.parse(history?.location?.search, { ignoreQueryPrefix: true });
  const [filters, setFilters] = useState<any>({
    page: urlFilters?.page || 0,
    filter: {},
  });

  const rowClicked = (rowId: any) => {
    if (open && activeRowId && rowId === activeRowId) setOpen(false);
    else {
      setOpen(true);
      setActiveRowId(rowId);
    }
  };

  const transformFilterForOG = (f: any) => {
    const combinedFilters: any = {};

    Object.keys(f || {}).forEach((key) => {
      const value = f[key];

      // Needs Attention
      if (key === 'needs-attention') {
        if (value === 'can-be-copied-to-hsrm') {
          combinedFilters['onlyCopyable'] = true;
        }
        if (value === 'soap-notes-added') {
          combinedFilters['noSoapNotesSubmitted'] = true;
          combinedFilters['status'] = 'fulfilled';
        }
      } else {
        combinedFilters[key] = value;
      }
    });

    return combinedFilters;
  };

  // fetch data on mount
  const fetchData = async (cancelTokenSource?: any) => {
    setLoading(true);
    try {
      // updating url with filters for easier refresh
      history.push({ search: qs.stringify(filters) });
      const ogFilters = transformFilterForOG(filters?.filter);
      const { appointments: _appointments } = await MedicalApi.fetchOGAppointments(
        {
          page: Number(filters.page) + 1,
          filters: ogFilters,
        },
        cancelTokenSource?.token
      );
      setAppointments(_appointments);
    } catch (e: any) {
      if (axios.isCancel(e)) return;
      setError(e?.message || 'An error occured while fetching the appointments');
    }
    setLoading(false);
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    fetchData(cancelTokenSource);
    return () => cancelTokenSource.cancel();
  }, [filters.page, filters.filter]); // eslint-disable-line react-hooks/exhaustive-deps

  const pageChangeHandler = (page: any) => {
    if (filters.page != page) {
      setFilters({
        ...filters,
        page,
      });
    }
  };

  const sortChangeHandler = (sortModel: any) => {
    console.log(sortModel);
  };

  const columns = [
    { id: 'appointmentId', label: 'OG Appt ID', width: 200, sortable: false },
    { id: 'name', label: 'Patient Name', width: 300, sortable: false },
    { id: 'date', label: 'Date/Time', width: 300, type: 'moment', sortable: false },
    { id: 'stage', label: 'OG Stage', width: 200, sortable: false },
    { id: 'status', label: 'Status', width: 200, sortable: false },
  ];

  const appointmentRows = (appointments || []).map((appointment: AppointmentType, index: number) => {
    const {
      id,
      fname,
      lname,
      date,
      stage,
      status,
      // can_be_copied_into_hsrm,
      // first_service_request_appointment,
      // copied_into_hsrm,
      // priority_review,
    } = appointment;
    return {
      id: `${id}-${index}`, // need to add index since multiple same appt ids can be returned here
      appointmentId: id,
      name: `${fname} ${lname}`,
      date,
      stage,
      status: status,
      meta: appointment,
    };
  });

  const filtersChangeHandler = (values: any) => {
    setFilters({
      ...filters,
      page: 0,
      filter: values,
    });
  };

  // Pick which side panel to show depending on the active row type (referral or service-request)
  const activeRow = activeRowId ? appointmentRows?.find((row) => row?.id === activeRowId) : null;

  const sideContent = (
    <AppointmentSidePanel
      open={open}
      onClose={() => setOpen(false)}
      ogAppointmentId={activeRow?.appointmentId as string}
      showCustomActions
      onChange={() => {
        fetchData();
      }}
    />
  );

  return (
    <SectionLayout title='Appointments' alert={error} sideContent={sideContent}>
      <div className={styles.tableHeader}>
        <FilterButtonV2
          onChange={(newFilters: any) => {
            filtersChangeHandler(newFilters);
          }}
          filters={[
            { id: 'id', label: 'OG Appointment ID', type: 'text', size: 'half' },
            { id: 'name', label: 'Patient Name', type: 'text', size: 'half' },
            {
              id: 'status',
              label: 'Status',
              type: 'select',
              size: 'half',
              options: [
                { label: 'Proposed', value: 'proposed' },
                { label: 'Pending', value: 'pending' },
                { label: 'Booked', value: 'booked' },
                { label: 'Fulfilled', value: 'fulfilled' },
                { label: 'Cancelled', value: 'cancelled' },
              ],
            },
            {
              id: 'needs-attention',
              label: 'Needs Attention',
              type: 'select',
              size: 'half',
              options: [
                { label: 'Can be Copied to HSRM', value: `can-be-copied-to-hsrm` },
                { label: 'SOAP notes missing', value: 'soap-notes-added' },
              ],
            },
          ]}
        />
      </div>
      <Table
        className={styles.table}
        loading={loading}
        activeRowId={open && activeRowId}
        columns={columns}
        rows={appointmentRows}
        onRowClick={(row: any) => rowClicked(row?.id)}
        pageSize={50}
        serverPagination={true}
        serverRowCount={10000} // temporarily while backend does not return the right amount of rows bug
        onPageChange={pageChangeHandler}
        rowsPerPageOptions={[50]}
        onSortModelChange={sortChangeHandler}
      />
    </SectionLayout>
  );
}
