import api from 'apis';

export const getRfsDocumentById = async (rfsId: string): Promise<Blob> => {
  const response = await api.get({
    path: `/template/v1/rfs/${rfsId}?as_pdf=true&dummy=1`,
    auth: true,
    responseType: 'blob',
  });

  return response;
};

export const getEvaluationDocumentByAppointmentId = async (appointmentId: string): Promise<any> => {
  const response = await api.get({
    path: `/template/v1/appointment/${appointmentId}/evaluation`,
    auth: true,
    responseType: 'blob',
  });

  return response;
};

export const combineSoapNotesAndEvaluations = async (soapNoteIds: string[], appointmentIds: string[]): Promise<any> => {
  const response = await api.post({
    path: `/template/v1/records`,
    auth: true,
    responseType: 'blob',
    data: {
      soap_notes_ids: soapNoteIds,
      appointments_ids: appointmentIds,
      as_pdf: true,
    },
  });

  return response;
};
