import React, { useMemo, useState } from 'react';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import { useZccService, useUIStore } from 'utils/hooks';
import { PractitionerApi, ZCCApi } from 'apis';
import { ZccPractitionerType, ZCCSortByParameters, ZccPractitionerNetworkStatus } from 'apis/zcc';
import { SectionLayout, Table, IconMenu, FilterButtonV2 } from 'components';
import { MenuListItemType } from 'components/MenuList';
import { PractitionerActions } from 'types/practitioner';
import PractitionerSidePanel from 'components/sidePanels/Practitioner';
import styles from './style.module.scss';

export type PractitionerRow = {
  id: string;
  entity_id: string;
  practitioner_name: string;
  npi: string;
  phone: string;
  specialties: string;
  email: string;
  gender: string;
  service_area: string;
  data: ZCCApi.ZccPractitionerType;
};

export default function Practitioners() {
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [activeRowData, setActiveRowData] = useState<PractitionerRow>();
  const [highlightedRowId, setHighlightedRowId] = useState<string | null | undefined>();
  const service = useZccService<ZccPractitionerType>(ZCCApi.fetchPractitioners, {
    paramTypes: { validated_provider: 'boolean' },
  });
  const { openAlert, openModal } = useUIStore();
  const [loading, setLoading] = useState(false);

  const sortChangeHandler = (sortModel: any) => {
    const sortBy: ZCCSortByParameters = {};
    sortModel?.forEach(({ field, sort }: any) => {
      sortBy[field] = sort;
    });
    if (!isEqual(service.parameters.sortBy, sortBy)) {
      service.setSortBy(sortBy);
    }
  };

  const updatePractitionerAutomatedReview = (
    practitionerName: string,
    practitionerId: string,
    validated: boolean
  ): void => {
    openModal({
      id: 'automated-review-settings',
      props: {
        practitionerId,
        practitionerName,
        validated,
        needFetchAutomatedReviewThreshold: true,
      },
      callback: (res: any) => {
        if (res) {
          service.refresh();
        }
      },
    });
  };

  const removePractitionerFromNetwork = async (practitionerName: string, practitionerId: string) => {
    openModal({
      id: 'confirm',
      props: {
        title: `Remove ${practitionerName} from Network?`,
        // eslint-disable-next-line max-len
        description: `Are you sure that you want to remove this practitioner from the network? They will no longer be able to take appointments.`,
        okButtonState: 'danger-contained',
        cancelButtonState: 'secondary',
      },
      callback: async (response: boolean) => {
        if (response) {
          try {
            setLoading(true);
            await PractitionerApi.updatePractitioner(practitionerId, {
              network_status: ZccPractitionerNetworkStatus.RemovedFromNetwork,
            });
            setLoading(false);
            openAlert({ title: `${practitionerName} Removed from the Network` });
            service.refresh();
          } catch (e) {
            setLoading(false);
            openAlert({
              title: `Error occurred while Removing ${practitionerName} from the Network`,
              severity: 'error',
            });
            console.log(e);
          }
        }
      },
    });
  };

  const rows = useMemo(() => {
    return [
      ...(service.data || []).map((item: ZccPractitionerType, index: number) => {
        const { _id, practitioner_name, npi, specialties, phone, email, gender, address } = item;

        const addressItems = address.split(',');
        const state = addressItems.pop()?.trim().split(' ')[0];
        const county = addressItems.pop()?.trim();

        const practitionerRow: PractitionerRow = {
          id: index.toString(),
          entity_id: _id.toString(),
          practitioner_name,
          npi,
          phone,
          specialties,
          email,
          gender,
          service_area: [state, county].filter(Boolean).join(' - '),
          data: item,
        };

        return practitionerRow;
      }),
    ];
  }, [service.data]);

  const columns = useMemo(
    () => [
      { id: 'entity_id', label: 'ID', width: 100 },
      { id: 'practitioner_name', label: 'Practitioner Name', width: 250 },
      { id: 'npi', label: 'NPI', width: 150 },
      { id: 'specialties', label: 'Services', width: 200 },
      { id: 'phone', label: 'Phone #', width: 150 },
      { id: 'email', label: 'Email', width: 150 },
      { id: 'gender', label: 'Gender', width: 150 },
      { id: 'service_area', label: 'Service Area', width: 300 },
      {
        id: 'action',
        label: 'Actions',
        width: 500,
        sortable: false,
        renderCell: ({ row }: any) => {
          const actionItems: MenuListItemType[] = [];
          if (
            row?.data?.actions?.includes(PractitionerActions.ApproveValidatedProvider) ||
            row?.data?.actions?.includes(PractitionerActions.UnApproveValidatedProvider)
          ) {
            actionItems.push({
              label: 'Automated Review Settings',
              icon: 'approve',
              onClick: () =>
                updatePractitionerAutomatedReview(
                  row?.practitioner_name,
                  row?.entity_id,
                  row?.data?.actions?.includes(PractitionerActions.UnApproveValidatedProvider)
                ),
            });
          }
          if (row?.data?.actions?.includes(PractitionerActions.RemoveFromNetwork)) {
            actionItems.push({
              label: 'Remove from Network',
              icon: 'unavailable-minus-negative',
              severity: 'danger',
              onClick: () => removePractitionerFromNetwork(row?.practitioner_name, row.data._id),
            });
          }

          return <IconMenu items={actionItems} />;
        },
      },
    ],
    []
  );

  const highlightRow = (rowId?: string) => {
    if (!rowId) {
      return;
    }
    setHighlightedRowId(rowId);
    setTimeout(() => setHighlightedRowId(null), 5000);
  };

  const sideContent = useMemo(() => {
    if (activeRowData) {
      return (
        <PractitionerSidePanel
          practitionerId={activeRowData.entity_id}
          open={sidePanelOpen}
          onClose={() => setSidePanelOpen(false)}
          onChange={() => {
            highlightRow(activeRowData?.id);
            service.refresh();
          }}
          soapNoteAutoApprove={
            activeRowData?.data.actions.includes(PractitionerActions.ApproveValidatedProvider) ||
            activeRowData?.data.actions.includes(PractitionerActions.UnApproveValidatedProvider)
          }
        />
      );
    }
    return;
  }, [sidePanelOpen, activeRowData]);

  const rowClicked = (row: PractitionerRow) => {
    const { id } = row || {};
    if (sidePanelOpen && activeRowData && id === activeRowData?.id) setSidePanelOpen(false);
    else {
      setSidePanelOpen(true);
      setActiveRowData(row);
    }
  };

  return (
    <SectionLayout
      alert={service.error}
      title={!isNil(service.total) && service.total >= 0 ? `${service.total} Practitioners` : ''}
      sideContent={sideContent}>
      <div className={styles.tableHeader}>
        <FilterButtonV2
          initialValue={service.parameters.filters}
          onChange={(newFilters: any) => {
            service.setFilters(newFilters);
          }}
          filters={[
            { id: '_id', label: 'Practitioner ID', type: 'text', size: 'half' },
            { id: 'practitioner_name', label: 'Practitioner Name', type: 'text', size: 'half' },
            { id: 'npi', label: 'NPI', type: 'text', size: 'half' },
            { id: 'phone', label: 'Phone Number', type: 'text', size: 'half' },
            {
              id: 'network_status',
              label: 'Status',
              type: 'select',
              size: 'half',
              options: [
                { label: 'Active In Network', value: ZccPractitionerNetworkStatus.ActiveInNetwork },
                { label: 'Removed From Network', value: ZccPractitionerNetworkStatus.RemovedFromNetwork },
              ],
            },
            { id: 'zeel_id', label: 'Zeel ID', type: 'text', size: 'half' },
            { id: 'email', label: 'Email', type: 'text', size: 'half' },
            {
              id: 'gender',
              label: 'Gender',
              type: 'select',
              options: [
                { label: '-', value: '' },
                { label: 'Male', value: 'MALE' },
                { label: 'Female', value: 'FEMALE' },
                { label: 'Other', value: 'OTHER' },
              ],
              size: 'half',
            },
            { id: 'address', label: 'Service Area', type: 'text', size: 'half' },
            {
              id: 'actions',
              label: 'Action Type',
              type: 'select-multiple',
              options: [
                { label: 'Remove from Network', value: PractitionerActions.RemoveFromNetwork },
                { label: 'Approve for Automated Review', value: PractitionerActions.ApproveValidatedProvider },
                { label: 'Unapprove for Automated Review', value: PractitionerActions.UnApproveValidatedProvider },
              ],
              size: 'half',
            },
            {
              id: 'validated_provider',
              label: 'Approved for Automated Review',
              type: 'select',
              size: 'half',
              options: [
                { label: '-', value: '' },
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ],
            },
          ]}
        />
      </div>
      <Table
        className={styles.table}
        loading={service.loading || loading}
        columns={columns}
        rows={rows}
        highlightedRowId={highlightedRowId}
        page={service.parameters.page}
        pageSize={service.parameters.pageSize}
        serverPagination
        serverRowCount={service.total}
        onPageChange={(page: any) => service.setPage(page)}
        onPageSizeChange={(pageSize: any) => service.setPageSize(pageSize)}
        rowsPerPageOptions={[100]}
        sortModel={service.sortModel}
        onSortModelChange={sortChangeHandler}
        onRowClick={rowClicked}
      />
    </SectionLayout>
  );
}
