import React, { useEffect, useMemo } from 'react';
import { ServiceRequestStatusType, ServiceRequestType } from 'apis/medical';
import { useForm } from 'utils/hooks';
import Grid from '@mui/material/Grid';
import { TextField, ServiceRequestFiles } from 'components';
import Form, { FormProps } from 'components/Form';
import { joinString } from 'utils/helper';
// import styles from './style.module.scss';

export type ServiceRequestFormProps = FormProps & {
  serviceRequest?: ServiceRequestType | null;
  showFiles?: boolean;
};

export default function ServiceRequestForm({
  serviceRequest,
  showFiles = true,
  editing,
  ...rest
}: ServiceRequestFormProps) {
  const { bind, form } = useForm();
  useEffect(() => {
    form.setFields({
      chiefComplaint: serviceRequest?.chief_complaint,
      provisionalDiagnosis: serviceRequest?.provisional_diagnosis,
      comorbidities: serviceRequest?.comorbidities,
      bodyParts: (serviceRequest?.body_parts || [])?.map((bp) => bp.display)?.join(', '),
      diagnosisCodes: (serviceRequest?.diagnosis_codes || [])?.map((dc) => dc)?.join(', '),

      status: serviceRequest?.status,
      revocationReason: serviceRequest?.revocation_reason,
      referralNumber: serviceRequest?.referral_number,
      servicesTotal: serviceRequest?.quantity?.value,
      servicesCompleted: joinString(
        [serviceRequest?.quantity.completed?.toString(), serviceRequest?.quantity?.value?.toString()],
        '/'
      ),

      issueDate: serviceRequest?.issue_date,
      expirationDate: serviceRequest?.expiration_date,
      serviceLength: serviceRequest?.duration ? `${serviceRequest?.duration} min` : null,
      treatmentCadence: serviceRequest?.treatment_cadence,
      inOffice: serviceRequest?.office_based_referral ? 'Yes' : 'No',
      conditionType: serviceRequest?.condition_type,
      referring_organization: serviceRequest?.referring_organization,
    });
    if (serviceRequest?.referring_organization === 'ahf' && serviceRequest?.insurance) {
      serviceRequest.insurance.forEach((insurance, id) => {
        form.setField(`insurance-${id}`, insurance.insurer);
        form.setField(`priority-${id}`, insurance.priority);
        form.setField(`subscriber-${id}`, insurance.subscriber_id);
      });
    }
  }, [serviceRequest]); // eslint-disable-line react-hooks/exhaustive-deps

  const fieldProps = useMemo(
    () => ({
      fullWidth: true,
      InputProps: {
        disableUnderline: !editing,
        readOnly: !editing,
      },
      InputLabelProps: {
        shrink: true,
      },
    }),
    [editing]
  );

  const serviceRequestsListProp = useMemo(() => {
    return serviceRequest ? [serviceRequest] : undefined;
  }, [serviceRequest]);

  return (
    <Form title='Referral Information' icon='pad-with-star' form={form} {...rest}>
      <Grid item xs={6}>
        <TextField {...bind('referralNumber', { required: editing })} label='Referral Number' {...fieldProps} />
      </Grid>
      <Grid item xs={6}>
        <TextField {...bind('referring_organization', { required: true })} label='Referer' {...fieldProps} />
      </Grid>
      {serviceRequest?.insurance?.map((_insurance, insuranceIndex) => (
        <React.Fragment key={insuranceIndex}>
          <Grid item xs={6}>
            <TextField
              {...bind(`insurance-${insuranceIndex}`, { required: editing })}
              label={`Insurance ${insuranceIndex > 0 ? insuranceIndex + 1 : ''}`}
              {...fieldProps}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...bind(`priority-${insuranceIndex}`, { required: editing })}
              label={'Priority'}
              {...fieldProps}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...bind(`subscriber-${insuranceIndex}`, { required: editing })}
              label={'Subscriber Id'}
              {...fieldProps}
            />
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={6}>
        <TextField {...bind('status', { required: editing })} label='Status' {...fieldProps} />
      </Grid>
      {serviceRequest?.status === ServiceRequestStatusType.Revoked && (
        <Grid item xs={6}>
          <TextField {...bind('revocationReason', { required: editing })} label='Revocation Reason' {...fieldProps} />
        </Grid>
      )}
      <Grid item xs={6}>
        <TextField
          {...bind('servicesTotal', { required: editing })}
          label='# of Total Authorized Services'
          {...fieldProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...bind('servicesCompleted', { required: editing })}
          label='# Services Completed '
          {...fieldProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField {...bind('issueDate', { required: editing })} label='Issue Date' type='date' {...fieldProps} />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...bind('expirationDate', { required: editing })}
          label='Expiration Date'
          type='date'
          {...fieldProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField {...bind('serviceLength', { required: editing })} label='Service Length' {...fieldProps} />
      </Grid>
      <Grid item xs={6}>
        <TextField {...bind('treatmentCadence', { required: editing })} label='Treatment Cadence' {...fieldProps} />
      </Grid>
      <Grid item xs={6}>
        <TextField {...bind('inOffice', { required: editing })} label='In-Office' {...fieldProps} />
      </Grid>
      <Grid item xs={6}>
        <TextField {...bind('conditionType', { required: editing })} label='Referral Type' {...fieldProps} />
      </Grid>
      <Grid item xs={12}>
        <TextField {...bind('chiefComplaint', { required: editing })} label='Chief Complaint' {...fieldProps} />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...bind('provisionalDiagnosis', { required: editing })}
          label='Provisional Diagnosis'
          multiline
          {...fieldProps}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...bind('comorbidities', { required: editing })}
          label='Patient History / Clinical Findings / Diagnosis (Co-Morbidities)'
          multiline
          {...fieldProps}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField {...bind('bodyParts', { required: editing })} label='Body Parts' multiline {...fieldProps} />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...bind('diagnosisCodes', { required: editing })}
          label='Diagnosis Codes'
          multiline
          {...fieldProps}
        />
      </Grid>
      {showFiles && (
        <Grid item xs={12}>
          <ServiceRequestFiles serviceRequests={serviceRequestsListProp} condensed />
        </Grid>
      )}
    </Form>
  );
}
