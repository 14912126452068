import useAuth from './useAuth';
import { MedicalApi } from 'apis';
import { CompositionStatusType } from 'apis/medical';
import { useUIStore } from 'utils/hooks';

export default function useInvalidateNote(
  onClose?: () => void,
  setError?: (error?: string) => void,
  scrollTop?: () => void
) {
  const { user } = useAuth();
  const { openAlert, openModal } = useUIStore();

  const updateSoapNote = async (soapNoteId: string) => {
    try {
      await MedicalApi.updateSoapNote(soapNoteId, {
        status: CompositionStatusType.EnteredInError,
        reviewed_by: user?.pccAgentName,
      });

      openAlert({ title: 'SOAP Note Invalidated' });
      onClose?.();
    } catch (e) {
      setError?.(`An error occured while invalidating the note`);
      scrollTop?.();
      console.error(e);
    }
  };

  const invalidateNote = (soapNoteId: string) => {
    openModal({
      id: 'confirm',
      props: {
        title: 'Invalidate SOAP Note?',
        description: `Are you sure that you want to invalidate this SOAP Note?`,
        okButtonState: 'danger-contained',
        cancelButtonState: 'secondary',
      },
      callback: async (response: boolean) => {
        if (response) {
          updateSoapNote(soapNoteId);
        }
      },
    });
  };

  return { invalidateNote };
}
