import React, { useState } from 'react';
import cn from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import Button, { ButtonProps } from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import styles from './style.module.scss';

export type ButtonPropType = ButtonProps & {
  popover?: any;
};

export default function _Button(props: any) {
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onClickHandler = async (e: any) => {
    if (props.popover) {
      setAnchorEl(e.currentTarget);
    }
    setLoading(true);
    if (props.onClick && typeof props.onClick === 'function') {
      await props.onClick(e);
    }
    setLoading(false);
  };
  const isPrimary = props.state === 'primary';
  const isSecondary = props.state === 'secondary';
  const isAccent = props.state === 'accent';
  const isDanger = props.state === 'danger';
  const isDangerContained = props.state === 'danger-contained';
  const isLoading = loading || props.loading;

  const isPopoverOpen = Boolean(anchorEl);
  const popoverId = isPopoverOpen ? 'popover-element' : undefined;

  return (
    <>
      <Button
        {...props}
        aria-describedby={popoverId}
        variant={props.variant || ((isPrimary || isDangerContained) && 'contained') || (isSecondary && 'outlined')}
        color={
          props.color ||
          (isPrimary && 'primary') ||
          ((isSecondary || isDanger || isDangerContained) && 'primary') ||
          (isAccent && 'secondary')
        }
        className={cn(
          styles.button,
          { [styles.danger]: isDanger, [styles.dangerContained]: isDangerContained },
          props.className
        )}
        onClick={isLoading ? (a) => a : onClickHandler}
        size={props.size || 'large'}
        type={(isLoading ? 'button' : props.type) || 'button'}>
        {!isLoading && props.children}
        {isLoading && (
          <CircularProgress
            className={cn(styles.submitLoading, { [styles['submitLoading--dark']]: isSecondary })}
            size={20}
          />
        )}
      </Button>
      {props.popover && (
        <Popover
          id={popoverId}
          open={isPopoverOpen}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          {props.popover}
        </Popover>
      )}
    </>
  );
}
