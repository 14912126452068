import api from 'apis';

export interface QuestionnaireResponseType {
  appointment_date: string;
  appointment_id: string;
  based_on: BasedOn[];
  encounter: Encounter;
  extension: Extension[];
  id: string;
  identifier?: Identifier;
  item: Item[];
  meta: Meta;
  provider_name: string;
  provider_npi: string;
  questionnaire: string;
  resource_type: string;
  session_number: number;
  status: string;
  subject: Subject;
}

export interface BasedOn {
  reference: string;
  type: string;
}

export interface Encounter {
  reference: string;
  type: string;
}

export interface Extension {
  url: string;
  value_code: string;
}

export interface Identifier {
  system: string;
  value: string;
}

export interface Item {
  answer: Answer[];
  link_id: string;
}

export interface Answer {
  value_coding: ValueCoding;
}

export interface ValueCoding {
  code: string;
  display: string;
  system: string;
}

export interface Meta {
  last_updated: string;
  source: string;
  tag: Tag[];
  version_id: string;
}

export interface Tag {
  code: string;
  display: string;
  system: string;
}

export interface Subject {
  reference: string;
  type: string;
}

export const searchQuestionnaireResponseByServiceRequestId = async (
  serviceRequestId: string
): Promise<QuestionnaireResponseType[]> => {
  const response = await api.get({
    path: `/evaluation/v1/questionnaire-response?params[based-on]=ServiceRequest/${serviceRequestId}`,
    auth: true,
  });
  return response;
};

export const updateQuestionnaireResponse = async (questionnaireResponseId: string, data?: any): Promise<void> => {
  await api.patch({
    path: `/evaluation/v1/questionnaire-response/${questionnaireResponseId}`,
    auth: true,
    data,
  });
};
