import { useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { useFetchFacilities, useIsTestDataCreator, useState, useUIStore, useZccService } from 'utils/hooks';
import { ZCCApi } from 'apis';
import { ZccRFSType, ZCCSortByParameters } from 'apis/zcc';
import { SectionLayout, Table, Button, Tooltip, FilterButtonV3 } from 'components';
import styles from './style.module.scss';
import moment from 'moment';
import { FilterType } from 'components/FilterButtonV3/FilterButtonV3.types';

enum RFSActions {
  MarkAsRejected = 'mark_as_vamc_rejected',
}

const defaultFilters = {
  test_data: false,
  referral_received: false,
};

export default function RFS() {
  const isTestDataCreator = useIsTestDataCreator();

  const service = useZccService<ZccRFSType>(ZCCApi.fetchRFSItems, {
    initialParameters: {
      sortBy: {
        rfs_submitted_date: 'desc',
      },
      filters: defaultFilters,
    },
    paramTypes: {
      referral_received: 'boolean',
      vamc_rejected: 'boolean',
      test_data: 'boolean',
    },
    transformRequest: (params) => {
      if ((!isTestDataCreator && params.filters) || params.filters?.test_data === false) {
        delete params.filters.test_data;
      }
      return params;
    },
  });

  const [open, setOpen] = useState<boolean>(false);
  const [activeRowData, setActiveRowData] = useState<any>(null);
  const [highlightedRowId, setHighlightedRowId] = useState<string | null | undefined>();
  const { loading: loadingFacilities, facilities } = useFetchFacilities();
  const { openModal } = useUIStore();

  const rowClicked = (row: any) => {
    const { id } = row || {};
    if (open && activeRowData && id === activeRowData?.id) setOpen(false);
    else {
      setOpen(true);
      setActiveRowData(row);
    }
  };

  const sortChangeHandler = (sortModel: any) => {
    const sortBy: ZCCSortByParameters = {};
    sortModel?.forEach(({ field, sort }: any) => {
      sortBy[field] = sort;
    });
    if (!isEqual(service.parameters.sortBy, sortBy)) {
      service.setSortBy(sortBy);
    }
  };

  const highlightRow = (rowId?: string) => {
    if (!rowId) {
      return;
    }
    setHighlightedRowId(rowId);
    setTimeout(() => setHighlightedRowId(null), 5000);
  };

  const rows = useMemo(() => {
    const r = [
      ...(service.data || []).map((item: ZccRFSType, index: number) => {
        const {
          _id,
          rfs_submitted_date,
          va_facility_name,
          referral_type,
          referral,
          patient_name,
          provider_name,
          referral_received_date,
          vamc_rejected_date,
          vamc_rejected_category,
          referral_count,
          actions,
        } = item;

        return {
          _id,
          id: index.toString(),
          entity_id: _id.toString(),
          rfs_submitted_date,
          va_facility_name,
          referral_type,
          referral,
          patient_name,
          provider_name,
          referral_received_date,
          vamc_rejected_date,
          vamc_rejected_category,
          referral_count,
          actions,
          data: item,
        };
      }),
    ];
    return r;
  }, [service.data]);

  // Actions
  const openMedicalReviewModal = (rfsId: string) => {
    if (rfsId) {
      openModal({
        id: 'mark-rfs-as-rejected',
        props: { rfsId },
        callback: (response: any) => {
          const previousRow = rows?.find((row: any) => row.entity_id === rfsId);
          highlightRow(previousRow?.id);
          if (response) {
            service.refresh();
          }
        },
      });
    } else {
      alert(`RFS ID is missing for this record, which prevents from opening the rejecting modal.`);
    }
  };

  const columns = useMemo(
    () => [
      {
        id: 'rfs_submitted_date',
        label: 'Date RFS submitted',
        width: 180,
        sortable: true,
        renderCell: ({ value }: any) => {
          if (!value) {
            return '';
          }
          const date = moment(value).format('MMM DD, YYYY');
          const time = moment(value).format('h:mm A');
          return (
            <div className={styles.multilineCell}>
              <span>{date}</span>
              <span>{time}</span>
            </div>
          );
        },
      },
      {
        id: 'va_facility_name',
        label: 'VA Facility Name',
        width: 250,
        renderCell: ({ value }: any) => {
          return (
            <Tooltip title={<span className={styles.tooltip}>{value}</span>} placement='top'>
              <span className={styles.ellipsisText}>{value}</span>
            </Tooltip>
          );
        },
      },
      {
        id: 'referral_type',
        label: 'SEOC type',
        width: 150,
        sortable: true,
      },
      {
        id: 'referral',
        label: 'Referral ID',
        width: 180,
        sortable: false,
      },
      { id: 'patient_name', label: 'Patient Name', width: 200, sortable: true },
      { id: 'provider_name', label: 'Provider Name', width: 200, sortable: true },
      {
        id: 'referral_received_date',
        label: 'Subseq. Referral Date',
        width: 200,
        sortable: true,
        renderCell: ({ value }: any) => {
          if (!value) {
            return '';
          }
          const date = moment(value).format('MMM DD, YYYY');
          const time = moment(value).format('h:mm A');
          return (
            <div className={styles.multilineCell}>
              <span>{date}</span>
              <span>{time}</span>
            </div>
          );
        },
      },
      {
        id: 'rejection',
        label: 'Rejection Date/Category',
        width: 200,
        sortable: false,
        renderCell: ({ row }: any) => {
          return (
            <div className={styles.multilineCell}>
              {row?.vamc_rejected_date && <span>{moment(row.vamc_rejected_date).format('lll')}</span>}
              {row?.vamc_rejected_category && <span>{row.vamc_rejected_category}</span>}
            </div>
          );
        },
      },
      { id: 'referral_count', label: 'Referral #', width: 100, sortable: false },
      {
        id: 'actions',
        label: 'Next Step',
        width: 250,
        sortable: false,
        renderCell: ({ value, row }: any) => {
          const actionsList = [];
          if (value?.includes(RFSActions.MarkAsRejected)) {
            actionsList.push(
              <Button
                state='primary'
                variant='outlined'
                onClick={(e: any) => {
                  openMedicalReviewModal(row?.entity_id);
                  e.stopPropagation();
                }}>
                Mark as Rejected
              </Button>
            );
          }

          return <div className={styles.actions}>{actionsList}</div>;
        },
      },
    ],
    []
  );

  return (
    <SectionLayout alert={service.error} title='RFS Items'>
      <div className={styles.tableHeader}>
        <FilterButtonV3
          initialValue={service.parameters.filters}
          defaultFilters={defaultFilters}
          onChange={(newFilters: any) => {
            service.setFilters(newFilters);
          }}
          searchTestDataOnly={isTestDataCreator && service.parameters.filters?.test_data}
          searches={[
            {
              id: 'patient_name',
              label: 'Patient Name',
              type: 'auto-complete-search',
              fetchItems: ZCCApi.searchPatientName,
            },
            { id: 'referral', label: 'Referral #', type: 'text' },
            {
              id: 'provider_name',
              label: 'Provider Name',
              type: 'auto-complete-search',
              fetchItems: ZCCApi.searchPractitionerName,
            },
          ]}
          filters={[
            {
              id: 'referral_received',
              label: 'Subsequent Referral Received',
              type: 'segments',
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ],
            },
            {
              id: 'vamc_rejected',
              label: 'Rejected',
              type: 'segments',
              options: [
                { label: 'Yes', value: true },
                { label: 'All', value: '' },
                { label: 'No', value: false },
              ],
            },
            {
              id: 'referral_type',
              label: 'Referral Type',
              type: 'select',
              options: [
                { label: 'All', value: '' },
                { label: 'Acute', value: 'ACUTE' },
                { label: 'Chronic', value: 'CHRONIC' },
                { label: 'Initial Care', value: 'INITIAL CARE' },
                { label: 'Continuation Care', value: 'CONTINUATION CARE' },
              ],
            },
            {
              id: 'va_facility_name',
              label: 'VAMC',
              placeholder: 'Search VAMC',
              type: 'auto-complete-multiple',
              maxSelections: 10,
              maxSelectionsHelperText: 'Maximum VAMC selections reached.',
              options: facilities,
              loading: loadingFacilities,
            },
            ...(isTestDataCreator
              ? [
                  {
                    id: 'test_data',
                    label: 'Test Data',
                    type: 'segments',
                    permanent: true,
                    options: [
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ],
                  } as FilterType,
                ]
              : []),
          ]}
        />
      </div>
      <Table
        className={styles.table}
        loading={service.loading}
        name={`${rows.length} Items Need Review`}
        tableCellClassName={styles.tableCellClassName}
        columns={columns}
        rows={rows}
        rowHeight={76}
        onRowClick={(row: any) => rowClicked(row)}
        activeRowId={open ? activeRowData?.rowId : undefined}
        highlightedRowId={highlightedRowId}
        page={service.parameters.page}
        pageSize={service.parameters.pageSize}
        serverPagination
        serverRowCount={service.total}
        onPageChange={(page: any) => service.setPage(page)}
        onPageSizeChange={(pageSize: any) => service.setPageSize(pageSize)}
        rowsPerPageOptions={[100]}
        sortModel={service.sortModel}
        onSortModelChange={sortChangeHandler}
      />
    </SectionLayout>
  );
}
