import { FC, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import { useAuth, useUIStore } from 'utils/hooks';
import { ZCCApi } from 'apis';
import { ZccNote } from 'apis/zcc';
import FieldTitle from '../../FieldTitle';
import Note from './Note';
import ActionNote from './ActionNote';
import { EFaxNotesProps } from './Notes.types';
import styles from './style.module.scss';

const EFaxNotes: FC<EFaxNotesProps> = ({ notes: originalNotes, eFaxId, onChange }) => {
  const [notes, setNotes] = useState<ZccNote[] | undefined>(originalNotes);
  const [editingNewNote, setEditingNewNote] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const { user } = useAuth();
  const { openAlert } = useUIStore();

  useEffect(() => {
    setEditingNewNote(false);
    setNotes(originalNotes);
  }, [originalNotes]);

  useEffect(() => {
    setEditingNewNote(false);
  }, [eFaxId]);

  const handleSaveNewNote = async (newNoteText: string) => {
    if (!eFaxId) {
      return;
    }
    setEditingNewNote(false);
    const prevNotes = notes ? [...notes] : undefined;
    try {
      const pccAgentName = user?.pccAgentName;
      const newNotes = [
        {
          text: newNoteText,
          timestamp: new Date().toISOString(),
          pcc_agent: pccAgentName,
        },
        ...(notes || []),
      ];
      setNotes(newNotes);
      await ZCCApi.updateEFax(eFaxId, {
        notes: newNotes,
      });
      onChange?.();
    } catch {
      openAlert({ title: `Failed to save the eFax note`, severity: 'error' });
      setNotes(prevNotes);
    }
  };

  const handleDeleteNote = async (noteIndex: number) => {
    if (!eFaxId) {
      return;
    }
    const prevNotes = [...(notes || [])];
    try {
      const newNotes = [...prevNotes];
      newNotes.splice(noteIndex, 1);
      setNotes(newNotes);
      await ZCCApi.updateEFax(eFaxId, {
        notes: newNotes,
      });
    } catch {
      openAlert({ title: `Failed to delete the eFax note`, severity: 'error' });
      setNotes(prevNotes);
    }
  };

  return (
    <div>
      <FieldTitle icon='bullet-list' margin>
        eFax Notes
      </FieldTitle>
      <Note
        label='Add an eFax Note...'
        note={''}
        saveButtonLabel='Save'
        showActions={editingNewNote}
        onSave={handleSaveNewNote}
        onClick={() => setEditingNewNote(true)}
        onCancel={() => setEditingNewNote(false)}
      />
      {notes?.slice(0, 2).map((note: ZccNote, index: number) => (
        <ActionNote key={index} note={note} onDelete={() => handleDeleteNote(index)} />
      ))}
      {notes && notes.length > 2 && (
        <>
          <Collapse in={!collapsed}>
            {notes?.slice(2).map((note: ZccNote, index: number) => (
              <ActionNote key={index} note={note} onDelete={() => handleDeleteNote(index)} />
            ))}
          </Collapse>
          <Button className={styles.expandBtn} onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? 'View' : 'Hide'} {notes.length - 2} Older eFax Note{notes.length > 3 ? 's' : ''}
          </Button>
        </>
      )}
    </div>
  );
};

export default EFaxNotes;
