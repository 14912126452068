import React, { useMemo } from 'react';
import { useForm, useDebouncedCallback } from 'utils/hooks';
import { Button, TextField, PhoneField, Checkbox, Icon } from 'components';
import FilterList from '@mui/icons-material/FilterList';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import isNil from 'lodash/isNil';
import styles from './style.module.scss';

export type FilterSelectOptionType = {
  label: string;
  value?: any;
  header?: boolean;
};

export type FilterType = {
  id: string;
  label: string;
  placeholder?: string;
  size?: 'full' | 'half';
  type: 'text' | 'phone' | 'number' | 'select' | 'select-multiple' | 'checkbox' | 'date';
  valueType?: 'string' | 'boolean';
  hidden?: boolean;
  options?: Array<FilterSelectOptionType>;
};

export type ActiveFilterType = {
  id: string;
  label: string;
  value?: any;
};

export type FilterButtonPropType = {
  initialValue?: any;
  filters: Array<FilterType>;
  onChange: (values: any) => void;
};

export default function FilterButtonV2({ initialValue, filters, onChange }: FilterButtonPropType) {
  // const initialFilters = initialValue && (Array.isArray(initialValue) ? initialValue : [initialValue]);
  // const [filterList, setFilterList] = useState<Array<FilterType>>([])

  const onFilterChangeHandler = useDebouncedCallback((values) => {
    onChange(values);
  }, 600);

  const { bind, bindCustom, form } = useForm(initialValue, {
    onChange: (e: any, values: any) => {
      onFilterChangeHandler.callback(values);
    },
  });

  const resetFilters = () => {
    form.clear();
    onChange({}); // not calling the debounced callback to make clear instant
  };

  const getActiveFilters = (): ActiveFilterType[] => {
    const activeFilters: ActiveFilterType[] = [];
    Object.keys(form.values).forEach((key) => {
      const value = form.values[key];
      const originalFilterObj: FilterType | undefined = filters?.find((f) => f.id === key);
      const isActive =
        !!originalFilterObj &&
        !originalFilterObj.hidden &&
        (Array.isArray(value) ? value.length > 0 : !isNil(value) && value !== '');
      if (isActive) {
        activeFilters.push({
          id: originalFilterObj.id,
          label: originalFilterObj.label,
          value: typeof value == 'boolean' ? (value ? 'Yes' : 'No') : value,
        });
      }
    });
    return activeFilters;
  };

  const clearFilter = (field: string) => {
    form.clearField(field);
    setTimeout(() => {
      onChange(form.values);
    });
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  const activeFilters = useMemo(() => getActiveFilters(), [form.values, filters]);

  return (
    <Button
      className={styles.button}
      startIcon={<FilterList />}
      variant='outlined'
      size='small'
      state={activeFilters?.length > 0 ? 'accent' : 'primary'}
      popover={
        <>
          <div className={styles.popoverContainer}>
            <Grid container spacing={3}>
              {(filters || []).map((filter: FilterType) => {
                const { id, label, placeholder, type, options, size, hidden } = filter;
                if (hidden) {
                  return null;
                }

                const inputCommonProps = {
                  ...(type === 'date' ? bindCustom : bind)(id, {
                    required: false,
                    defaultValue: type === 'select-multiple' ? [] : '',
                  }),
                  id: `filter-field-${id}`,
                  label,
                  placeholder,
                  ...fieldProps,
                };

                let fieldJsx = null;

                if (['text', 'number'].includes(type)) {
                  fieldJsx = <TextField {...inputCommonProps} type={type} />;
                }

                if (type === 'phone') {
                  fieldJsx = <PhoneField {...inputCommonProps} />;
                }

                if (type === 'select' || type === 'select-multiple') {
                  fieldJsx = (
                    <TextField
                      {...inputCommonProps}
                      select
                      {...(type === 'select-multiple' ? { SelectProps: { multiple: true } } : {})}>
                      {options?.map((option: FilterSelectOptionType) => {
                        const { label: _label, value, header } = option;
                        return !header ? (
                          <MenuItem
                            key={value}
                            value={value}
                            {...(type === 'select' ? { style: { textTransform: 'capitalize' } } : {})}>
                            {_label}
                          </MenuItem>
                        ) : (
                          <MenuItem disabled>{_label}</MenuItem>
                        );
                      })}
                    </TextField>
                  );
                }

                if (type === 'checkbox') {
                  fieldJsx = <Checkbox {...inputCommonProps} />;
                }

                if (type === 'date') {
                  fieldJsx = (
                    <DatePicker
                      componentsProps={{
                        actionBar: { actions: ['clear'] },
                      }}
                      label={inputCommonProps.label}
                      value={!inputCommonProps.value ? null : inputCommonProps.value}
                      onChange={(date) => {
                        if (!date?.isValid()) {
                          inputCommonProps.onChange('');
                        } else {
                          inputCommonProps.onChange(date?.format('YYYY-MM-DD'));
                        }
                      }}
                      inputFormat='MM/DD/YYYY'
                      renderInput={(params) => <TextField {...params} {...fieldProps} />}
                    />
                  );
                }

                if (!fieldJsx) return null;
                return (
                  <Grid key={id} item xs={size === 'half' ? 6 : 12}>
                    {fieldJsx}
                  </Grid>
                );
              })}
            </Grid>
            <p className={styles.clearFilters} onClick={resetFilters}>
              Clear Filters
            </p>
          </div>
        </>
      }>
      {activeFilters.length > 0 &&
        activeFilters.map((filter: ActiveFilterType) => {
          return (
            <div key={filter.id} className={styles.inlineFilter}>
              {filter.label}: {Array.isArray(filter.value) ? filter.value.join(', ') : filter.value}
              <Icon
                className={styles.deleteFilterIcon}
                name='x'
                size={20}
                onClick={(e: any) => {
                  e?.stopPropagation();
                  clearFilter(filter.id);
                }}
              />
            </div>
          );
        })}
      {!activeFilters.length && 'Filter and Search'}
    </Button>
  );
}
