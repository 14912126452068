/* eslint-disable */
import React /*, { useEffect }*/ from 'react';
// import moment from 'moment';
import cn from 'classnames';
import { useState, useDebouncedCallback } from 'utils/hooks';
import { Icon } from 'components';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import styles from './style.module.scss';

export type TableNewPropType = {
  loading?: boolean;
  name?: string;
  columns: Array<ColumnType>;
  rows: Array<any>; // any since structure varies depending on columns
  onRowClick?: Function;
  activeRowId?: string;
  allowSearch?: boolean;
  searchPlaceholder?: string;
  rowCount?: number;
  filtersContent?: any;
  globalSearch?: boolean;
  globalSearchInitialValue?: string;
  onSearchChange?: Function;
  onSortChange?: Function;
  onPageChange?: any;
  onPageSizeChange?: any;
  page?: any;
  pageSize?: any;
  className?: string;
};

type ColumnType = {
  id: string;
  label: string;
  width?: number;
  description?: string;
  type?: string;
  getter?: Function;
  formatter?: Function;
  sort?: Function;
  sortable?: boolean;
  options?: any;
  renderCell?: Function;
};

export default function TableNew({
  // loading,
  name,
  columns,
  rows,
  onRowClick,
  // activeRowId,
  // allowSearch = true,
  searchPlaceholder = 'Search',
  // rowCount,
  filtersContent,
  globalSearch = true,
  globalSearchInitialValue = '',
  onSearchChange,
  onSortChange,
  // onPageChange,
  // onPageSizeChange,
  // page,
  // pageSize,
  className,
}: TableNewPropType) {
  const [searchTerm, setSearchTerm] = useState<string>(globalSearchInitialValue);

  // Handlers
  // const rowClickHandler = (row: any) => {
  //   if (onRowClick) {
  //     onRowClick(row.data)
  //   }
  // }

  // Debounced handlers
  const searchHandler = useDebouncedCallback((value) => {
    if (onSearchChange) onSearchChange(value);
  }, 600);

  // const sortHandler = useDebouncedCallback((value) => {
  //   if (onSortChange) onSortChange(value);
  // }, 100);

  // Columns
  // const gridColumns = (columns || []).map((column, i) => {
  //   const { id, label, width, description, type, getter, formatter, sort, sortable = true, options, renderCell } = column;
  //   let data: any = {
  //     field: id,
  //     headerName: label,
  //     description,
  //     headerClassName: styles.tableHeaderCell,
  //     sortable,
  //     options,
  //     cellClassName: () => cn(styles.tableCell)
  //   };
  //   if (formatter) data['valueFormatter'] = formatter;
  //   if (getter) data['valueGetter'] = getter;
  //   if (sort) data['sortComparator'] = sort;
  //   if (width) data['width'] = width;
  //   if (renderCell) data['renderCell'] = renderCell;
  //   if (type === 'moment') {
  //     data = {
  //       ...data,
  //       valueFormatter: ({ value }: any) => value.format('LLL'),
  //       sortComparator: (v1: any, v2: any) => v1.valueOf() - v2.valueOf()
  //     };
  //   } else if (type === 'status') {
  //     data = {
  //       ...data,
  //       renderCell: ({ value }: any) => {
  //         if (value === 'alert' || value === false) return <Icon name="exclamation" color="error" size={25} />
  //         if (value === 'valid' || value === true) return <Icon name="checkmark-circle" color="lush" size={25}  />
  //       }
  //     }
  //   } else if (type) {
  //     data['type'] = type;
  //   }
  //   return data;
  // });

  // Rows
  // let gridRows: Array<any> = [...rows];

  return (
    <div className={cn(styles.tableWrapper, className)}>
      <div className={styles.topContent}>
        <p className={styles.tableName}>{name}</p>
        <div className={styles.fieldsWrapper}>
          {filtersContent}
          {globalSearch && (
            <TextField
              placeholder={searchPlaceholder}
              size='small'
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                searchHandler.callback(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Icon color='grey-5' name='search' size={28} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </div>
      </div>
      hello
    </div>
  );
}
