import api from 'apis';
import { helper } from 'utils';
import qs from 'qs';
import { joinString } from 'utils/helper';

export const authorize = async () => {
  const redirectUri = process.env.REACT_APP_NG_AUTH_REDIRECTION_URI;
  // eslint-disable-next-line
  (window.location as any) = `${process.env.REACT_APP_NG_AUTH_API}/login?response_type=code&client_id=${process.env.REACT_APP_NG_AUTH_CLIENT_ID}&redirect_uri=${redirectUri}`;
};

export const getUserFromIdToken: (idToken: string) => any = (idToken: string) => {
  const cognitoUser = helper.parseJwt(idToken);
  const user = {
    username: cognitoUser?.['cognito:username'],
    roles: cognitoUser?.['cognito:groups'] || [],
    memberId: cognitoUser?.['custom:member_id'],
  };
  return user;
};

export const authenticateWithCode: (code: string) => Promise<TokenType> = async (code: string) => {
  const requestBody = qs.stringify({
    grant_type: 'authorization_code',
    code,
    client_id: process.env.REACT_APP_NG_AUTH_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_NG_AUTH_REDIRECTION_URI,
  });

  const response = await api.post({
    path: '/oauth2/token',
    version: 'ng-auth',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: requestBody,
  });

  const { id_token, access_token, refresh_token, expires_in, token_type } = response;

  const user = await getZccUserIdentity(id_token, `${token_type} ${access_token}`);

  return {
    access: `${token_type} ${access_token}`,
    refresh: refresh_token,
    expiresAt: new Date().getTime() + expires_in * 1000,
    user,
  };
};

export const refreshToken: (token: string) => Promise<TokenType> = async (rt: string) => {
  const requestBody = qs.stringify({
    grant_type: 'refresh_token',
    refresh_token: rt,
    client_id: process.env.REACT_APP_NG_AUTH_CLIENT_ID,
  });

  const response = await api.post({
    path: '/oauth2/token',
    version: 'ng-auth',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: requestBody,
  });

  const { id_token, access_token, expires_in, token_type } = response;

  const user = await getZccUserIdentity(id_token, `${token_type} ${access_token}`);

  return {
    access: `${token_type} ${access_token}`,
    refresh: rt,
    expiresAt: new Date().getTime() + expires_in * 1000,
    user,
  };
};

export const fetchOGMember: (forcedToken?: string) => Promise<OGMemberType> = async (forcedToken) => {
  const { response } = await api.post({
    path: '/api/v1/member/status',
    version: 'og',
    auth: forcedToken || true, // will either used forced token or session token
  });

  return response?.member;
};

export const getZccUserIdentity: (id_token: string, access_token: string) => Promise<ZccUserType> = async (
  id_token,
  access_token
) => {
  let user = getUserFromIdToken(id_token);

  let ogMember = null;
  try {
    ogMember = await fetchOGMember(access_token);
  } catch (error) {
    console.log(error);
  }

  // Setting user meta data from identity if available
  if (ogMember) {
    user = {
      ...user,
      fname: ogMember?.fname,
      lname: ogMember?.lname,
      pccAgentName: joinString([ogMember?.fname, ogMember?.lname]),
    };
  }

  return user;
};

// Not used for now as we use the hosted cognito login form UI and redirect_uri
export const signIn = async (/*data*/) => {
  // const { email, password } = data;
  // const response = await api.get({
  //   path: '/oauth2/authorize',
  //   version: 'ng-auth',
  //   debug: true,
  // });
  // return {
  //   access: 'Bearer 8q7nc89juc93jrc902',
  //   refresh: 'Bearer im9dm2j309fjnqionmer',
  //   expiresAt: new Date().getTime() + 3600000,
  // }
};

// TYPES
export interface TokenType {
  access: string; // Bearer [token]
  refresh: string; // [token]
  expiresAt: number; // milli
  user: ZccUserType;
}

export interface ZccUserType {
  cognito_user: boolean;
  email: string;
  fhir_user: boolean;
  id: string;
  related_ids: any[];
  sections: {
    og: {
      email: string;
      identifier: Array<{
        member_id: string;
      }>;
      lname: string[];
      postal_code: string;
    };
    zcc: {
      email: string;
      first_name: string;
      last_name: string;
    };
  };
}

export interface OGMemberType {
  id: number;
  fname: string;
  lname: string;
  email: string;
  sex: string;
  zip: string;
  state_code: string;
}
