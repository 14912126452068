import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector, useDispatch, useLocation, useRole, useAuth } from 'utils/hooks';
import routes from 'utils/routes';
import { AuthStore } from 'store';
import NoPermissions from 'pages/NoPermissions';

export type RoutePropType = RouteProps & {
  public?: boolean;
  private?: boolean;
  role?: any; // role/roles required to see the route, or else it will show the NoPermission UI
};

export default function _Route({
  component,
  private: isPrivate = true,
  public: isPublic,
  role,
  ...rest
}: RoutePropType) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const fullUrl = `${location?.pathname}${location?.search}`;
  const { isAuthenticated } = useAuth();
  const hasRoles = useRole(role);
  const isTokenExpired = useSelector(AuthStore.isTokenExpired);

  // On mount and on route change, checks if the token is set but expired, and refresh it before loading the route
  useEffect(() => {
    async function check() {
      if (isTokenExpired) {
        setLoading(true);
        await dispatch(AuthStore.refreshToken());
      }
      setLoading(false);
    }
    check();
  }, [fullUrl, dispatch, isTokenExpired]);

  if (loading) return null;
  const C: any = component; // TODO: fix any type
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated && !isPublic && isPrivate)
          return (
            <Redirect
              to={{
                pathname: routes.PRIVATE_ROUTE_KICKBACK,
                state: { from: `${props.location.pathname}${props.location.search}` },
              }}
            />
          );
        if (!hasRoles) return <NoPermissions from={props.location.pathname} />;
        return <C {...props} />;
      }}
    />
  );
}
