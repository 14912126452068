import useUIStore from './useUIStore';

export default function useCopyToClipboard() {
  const { openAlert } = useUIStore();

  const copyToClipboard = async (text: string, showAlert = true) => {
    try {
      await navigator.clipboard.writeText(text);
      if (showAlert) {
        openAlert({ title: `Successfully copied to clipboard`, duration: 1000 });
      }
    } catch {
      if (showAlert) {
        openAlert({ title: `Failed to copy to clipboard`, severity: 'error', duration: 1000 });
      }
    }
  };

  return {
    copyToClipboard,
  };
}
