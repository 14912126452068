import React from 'react';
import { history, routes } from 'utils';
import { Layout } from 'components';
import styles from './style.module.scss';

type Props = {
  from?: string;
};

export default function NoPermissions({ from }: Props) {
  return (
    <Layout
      external
      alert={from && `You don't have the required permissions to view ${from}`}
      alertSeverity='warning'
      margin={true}>
      <p className={styles.goBack} onClick={() => history.goBack()}>
        Go Back
      </p>
      <br />
      <p className={styles.goBack} onClick={() => history.replace(routes.HOME)}>
        Home
      </p>
    </Layout>
  );
}
