import React from 'react';
import { ServiceRequestType } from 'apis/medical';
import { FieldTitle, Table } from 'components';
import styles from './style.module.scss';

export type ServiceRequestListProps = {
  showTitle?: boolean;
  serviceRequests?: Array<ServiceRequestType>;
  onClick?: (referralNumber: string) => void;
};

export default function ServiceRequestList({ showTitle = true, serviceRequests, onClick }: ServiceRequestListProps) {
  const rowClicked = ({ id }: any) => {
    const serviceRequest = serviceRequests?.find((s) => s.id === id);
    serviceRequest?.referral_number && onClick?.(serviceRequest.referral_number);
  };
  const columns = [
    {
      id: 'referralNumber',
      label: 'Referral #',
      width: 200,
      renderCell: ({ row }: any) => {
        return <span className={styles.referralNumber}>{row.referralNumber}</span>;
      },
    },
    { id: 'expiration', label: 'Expiration', width: 150 },
    { id: 'appointments', label: 'Appointments', width: 150 },
  ];

  const rows = (serviceRequests || []).map((serviceRequest: ServiceRequestType) => {
    const { id, referral_number, expiration_date, quantity } = serviceRequest;

    return {
      id,
      referralNumber: referral_number,
      expiration: expiration_date,
      appointments: `${quantity?.available}/${quantity?.value} remaining`,
    };
  });
  return (
    <div>
      {showTitle && <FieldTitle icon='pad-with-star'>Linked Referrals</FieldTitle>}
      <Table
        className={styles.table}
        allowSearch={false}
        footer={false}
        columns={columns}
        rows={rows}
        autoHeight
        onRowClick={rowClicked}
      />
    </div>
  );
}
