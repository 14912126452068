import React from 'react';
import TextField from '../TextField';
import MaskedInput from 'react-text-mask';
import { normalizePhoneNumber } from 'utils/helper';
// import styles from './style.module.scss';

const PhoneMaskField = React.forwardRef(function PhoneMaskField(props, ref) {
  return (
    <MaskedInput
      {...props}
      ref={ref as any}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      guide
    />
  );
});

export default function PhoneField(props: any) {
  return (
    <TextField
      {...props}
      value={normalizePhoneNumber(props.value as string)}
      InputProps={{ ...(props.InputProps ?? {}), inputComponent: PhoneMaskField }}
    />
  );
}
