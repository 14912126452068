import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// import styles from './style.module.scss';

export type CheckboxPropTypes = any;

export default function _Checkbox(props: any) {
  // TODO: fix types
  const checkboxProps = { ...props };
  delete checkboxProps.value;
  delete checkboxProps.label;
  delete checkboxProps.error;

  return (
    <FormControl error={!!props.error} component='fieldset'>
      <FormGroup>
        <FormControlLabel control={<Checkbox {...props} checked={props.value} />} label={props.label} />
      </FormGroup>
      <FormHelperText>{props.error}</FormHelperText>
    </FormControl>
  );
}
