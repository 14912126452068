import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  SimpleQuantity: any;
  base64Binary: any;
  canonical: any;
  code: any;
  date: any;
  dateTime: any;
  decimal: any;
  id: any;
  instant: any;
  markdown: any;
  oid: any;
  time: any;
  unsignedInt: any;
  uri: any;
  url: any;
  uuid: any;
};

/**
 * Account
 *     A financial tool for tracking value accrued for a particular purpose.  In the
 *     healthcare field, used to track charges for a patient, cost centers, etc.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Account = {
  __typename?: 'Account';
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The party(s) that are responsible for covering the payment of this account,
   * and what order should they be applied to the account.
   */
  coverage?: Maybe<Array<Maybe<AccountCoverage>>>;
  /**
   * Provides additional information about what the account tracks and how it is
   * used.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The parties responsible for balancing the account if other payment options
   * fall short.
   */
  guarantor?: Maybe<Array<Maybe<AccountGuarantor>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Unique identifier used to reference the account.  Might or might not be
   * intended for human use (e.g. credit card number).
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Name used for the account when displaying it to humans in reports, etc. */
  name?: Maybe<Scalars['code']>;
  /**
   * Indicates the service area, hospital, department, etc. with responsibility for
   * managing the Account.
   */
  owner?: Maybe<Organization>;
  /** Reference to a parent Account. */
  partOf?: Maybe<Account>;
  resourceType?: Maybe<Scalars['String']>;
  /** The date range of services associated with this account. */
  servicePeriod?: Maybe<Period>;
  /** Indicates whether the account is presently used/usable or not. */
  status: Scalars['code'];
  /**
   * Identifies the entity which incurs the expenses. While the immediate
   * recipients of services or goods might be entities related to the subject, the
   * expenses were ultimately incurred by the subject of the Account.
   */
  subject?: Maybe<Array<Maybe<AccountSubject>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** Categorizes the account for reporting and searching purposes. */
  type?: Maybe<CodeableConcept>;
};

/**
 * Account.Coverage
 *     A financial tool for tracking value accrued for a particular purpose.  In the
 *     healthcare field, used to track charges for a patient, cost centers, etc.
 */
export type AccountCoverage = {
  __typename?: 'AccountCoverage';
  /**
   * The party(s) that contribute to payment (or part of) of the charges applied to
   * this account (including self-pay).
   *
   * A coverage may only be responsible for specific types of charges, and the
   * sequence of the coverages in the account could be important when processing
   * billing.
   */
  coverage: Coverage;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The priority of the coverage in the context of this account. */
  priority?: Maybe<Scalars['code']>;
};

/**
 * Account.Guarantor
 *     A financial tool for tracking value accrued for a particular purpose.  In the
 *     healthcare field, used to track charges for a patient, cost centers, etc.
 */
export type AccountGuarantor = {
  __typename?: 'AccountGuarantor';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A guarantor may be placed on credit hold or otherwise have their role
   * temporarily suspended.
   */
  onHold?: Maybe<Scalars['code']>;
  /** The entity who is responsible. */
  party: AccountGuarantorParty;
  /**
   * The timeframe during which the guarantor accepts responsibility for the
   * account.
   */
  period?: Maybe<Period>;
};

export type AccountGuarantorParty = Organization | Patient | RelatedPerson;

export type AccountSubject = Device | HealthcareService | Location | Organization | Patient | Practitioner | PractitionerRole;

/**
 * ActivityDefinition
 *     This resource allows for the definition of some activity to be performed,
 *     independent of a particular patient, practitioner, or other performance
 *     context.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ActivityDefinition = {
  __typename?: 'ActivityDefinition';
  /**
   * The date on which the resource content was approved by the publisher. Approval
   * happens once when the content is officially approved for usage.
   */
  approvalDate?: Maybe<Scalars['code']>;
  /**
   * An individiual or organization primarily involved in the creation and
   * maintenance of the content.
   */
  author?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * Indicates the sites on the subject's body where the procedure should be
   * performed (I.e. the target sites).
   */
  bodySite?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Detailed description of the type of activity; e.g. What lab test, what
   * procedure, what kind of encounter.
   */
  code?: Maybe<CodeableConcept>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the activity definition and/or its contents.
   * Copyright statements are generally legal restrictions on the use and
   * publishing of the activity definition.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the activity definition was published.
   * The date must change when the business version changes and it must change if
   * the status code changes. In addition, it should change when the substantive
   * content of the activity definition changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the activity definition from a
   * consumer's perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * Set this to true if the definition is to indicate that a particular activity
   * should NOT be performed. If true, this element should be interpreted to
   * reinforce a negative coding. For example NPO as a code with a doNotPerform of
   * true would still indicate to NOT perform the action.
   */
  doNotPerform?: Maybe<Scalars['code']>;
  /**
   * Provides detailed dosage instructions in the same way that they are described
   * for MedicationRequest resources.
   */
  dosage?: Maybe<Array<Maybe<Dosage>>>;
  /**
   * Dynamic values that will be evaluated to produce values for elements of the
   * resulting resource. For example, if the dosage of a medication must be
   * computed based on the patient's weight, a dynamic value would be used to
   * specify an expression that calculated the weight, and the path on the request
   * resource that would contain the result.
   */
  dynamicValue?: Maybe<Array<Maybe<ActivityDefinitionDynamicValue>>>;
  /**
   * An individual or organization primarily responsible for internal coherence of
   * the content.
   */
  editor?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * The period during which the activity definition content was or is planned to
   * be in active use.
   */
  effectivePeriod?: Maybe<Period>;
  /**
   * An individual or organization responsible for officially endorsing the content
   * for use in some setting.
   */
  endorser?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * A Boolean value to indicate that this activity definition is authored for
   * testing purposes (or education/evaluation/marketing) and is not intended to be
   * used for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this activity definition when it
   * is represented in other formats, or referenced in a specification, model,
   * design or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * Indicates the level of authority/intentionality associated with the activity
   * and where the request should fit into the workflow chain.
   */
  intent?: Maybe<Scalars['code']>;
  /**
   * A legal or geographic region in which the activity definition is intended to
   * be used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * A description of the kind of resource the activity definition is representing.
   * For example, a MedicationRequest, a ServiceRequest, or a CommunicationRequest.
   * Typically, but not always, this is a Request resource.
   */
  kind?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The date on which the resource content was last reviewed. Review happens
   * periodically after approval but does not change the original approval date.
   */
  lastReviewDate?: Maybe<Scalars['code']>;
  /**
   * A reference to a Library resource containing any formal logic used by the
   * activity definition.
   */
  library?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Identifies the facility where the activity will occur; e.g. home, hospital,
   * specific clinic, etc.
   */
  location?: Maybe<Location>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the activity definition. This name should
   * be usable as an identifier for the module by machine processing applications
   * such as code generation.
   */
  name?: Maybe<Scalars['code']>;
  /**
   * Defines observation requirements for the action to be performed, such as body
   * weight or surface area.
   */
  observationRequirement?: Maybe<Array<Maybe<ObservationDefinition>>>;
  /** Defines the observations that are expected to be produced by the action. */
  observationResultRequirement?: Maybe<Array<Maybe<ObservationDefinition>>>;
  /** Indicates who should participate in performing the action described. */
  participant?: Maybe<Array<Maybe<ActivityDefinitionParticipant>>>;
  /**
   * Indicates how quickly the activity  should be addressed with respect to other
   * requests.
   */
  priority?: Maybe<Scalars['code']>;
  /** None */
  productCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  productReference?: Maybe<ActivityDefinitionProductReference>;
  /**
   * A profile to which the target of the activity definition is expected to
   * conform.
   */
  profile?: Maybe<Scalars['code']>;
  /**
   * The name of the organization or individual that published the activity
   * definition.
   */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this activity definition is needed and why it has been
   * designed as it has.
   */
  purpose?: Maybe<Scalars['code']>;
  /**
   * Identifies the quantity expected to be consumed at once (per dose, per meal,
   * etc.).
   */
  quantity?: Maybe<Quantity>;
  /**
   * Related artifacts such as additional documentation, justification, or
   * bibliographic references.
   */
  relatedArtifact?: Maybe<Array<Maybe<RelatedArtifact>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * An individual or organization primarily responsible for review of some aspect
   * of the content.
   */
  reviewer?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * Defines specimen requirements for the action to be performed, such as required
   * specimens for a lab test.
   */
  specimenRequirement?: Maybe<Array<Maybe<SpecimenDefinition>>>;
  /**
   * The status of this activity definition. Enables tracking the life-cycle of the
   * content.
   */
  status: Scalars['code'];
  /** None */
  subjectCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  subjectReference?: Maybe<Group>;
  /**
   * An explanatory or alternate title for the activity definition giving
   * additional information about its content.
   */
  subtitle?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** None */
  timingAge?: Maybe<Quantity>;
  /** None */
  timingDateTime?: Maybe<Scalars['code']>;
  /** None */
  timingDuration?: Maybe<Quantity>;
  /** None */
  timingPeriod?: Maybe<Period>;
  /** None */
  timingRange?: Maybe<Range>;
  /** None */
  timingTiming?: Maybe<Timing>;
  /** A short, descriptive, user-friendly title for the activity definition. */
  title?: Maybe<Scalars['code']>;
  /**
   * Descriptive topics related to the content of the activity. Topics provide a
   * high-level categorization of the activity that can be useful for filtering and
   * searching.
   */
  topic?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * A reference to a StructureMap resource that defines a transform that can be
   * executed to produce the intent resource using the ActivityDefinition instance
   * as the input.
   */
  transform?: Maybe<Scalars['code']>;
  /**
   * An absolute URI that is used to identify this activity definition when it is
   * referenced in a specification, model, design or an instance; also called its
   * canonical identifier. This SHOULD be globally unique and SHOULD be a literal
   * address at which at which an authoritative instance of this activity
   * definition is (or will be) published. This URL can be the target of a
   * canonical reference. It SHALL remain the same when the activity definition is
   * stored on different servers.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * A detailed description of how the activity definition is used from a clinical
   * perspective.
   */
  usage?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate activity
   * definition instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the activity
   * definition when it is referenced in a specification, model, design or
   * instance. This is an arbitrary value managed by the activity definition author
   * and is not expected to be globally unique. For example, it might be a
   * timestamp (e.g. yyyymmdd) if a managed version is not available. There is also
   * no expectation that versions can be placed in a lexicographical sequence. To
   * provide a version consistent with the Decision Support Service specification,
   * use the format Major.Minor.Revision (e.g. 1.0.0). For more information on
   * versioning knowledge assets, refer to the Decision Support Service
   * specification. Note that a version is required for non-experimental active
   * assets.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * ActivityDefinition.DynamicValue
 *     This resource allows for the definition of some activity to be performed,
 *     independent of a particular patient, practitioner, or other performance
 *     context.
 */
export type ActivityDefinitionDynamicValue = {
  __typename?: 'ActivityDefinitionDynamicValue';
  /** An expression specifying the value of the customized element. */
  expression: Expression;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The path to the element to be customized. This is the path on the resource
   * that will hold the result of the calculation defined by the expression. The
   * specified path SHALL be a FHIRPath resolveable on the specified target type of
   * the ActivityDefinition, and SHALL consist only of identifiers, constant
   * indexers, and a restricted subset of functions. The path is allowed to contain
   * qualifiers (.) to traverse sub-elements, as well as indexers ([x]) to traverse
   * multiple-cardinality sub-elements (see the [Simple FHIRPath
   * Profile](fhirpath.html#simple) for full details).
   */
  path: Scalars['code'];
};

/**
 * ActivityDefinition.Participant
 *     This resource allows for the definition of some activity to be performed,
 *     independent of a particular patient, practitioner, or other performance
 *     context.
 */
export type ActivityDefinitionParticipant = {
  __typename?: 'ActivityDefinitionParticipant';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The role the participant should play in performing the described action. */
  role?: Maybe<CodeableConcept>;
  /** The type of participant in the action. */
  type: Scalars['code'];
};

export type ActivityDefinitionProductReference = Medication | Substance;

/**
 * Address
 *     An address expressed using postal conventions (as opposed to GPS or other
 *     location definition formats).  This data type may be used to convey addresses
 *     for use in delivering mail as well as for visiting locations which might not
 *     be valid for mail delivery.  There are a variety of postal address formats
 *     defined around the world.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Address = {
  __typename?: 'Address';
  /**
   * The name of the city, town, suburb, village or other community or delivery
   * center.
   */
  city?: Maybe<Scalars['code']>;
  /** Country - a nation as commonly understood or generally accepted. */
  country?: Maybe<Scalars['code']>;
  /** The name of the administrative area (county). */
  district?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * This component contains the house number, apartment number, street name,
   * street direction,  P.O. Box number, delivery hints, and similar address
   * information.
   */
  line?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Time period when address was/is in use. */
  period?: Maybe<Period>;
  /** A postal code designating a region defined by the postal service. */
  postalCode?: Maybe<Scalars['code']>;
  /**
   * Sub-unit of a country with limited sovereignty in a federally organized
   * country. A code may be used if codes are in common use (e.g. US 2 letter state
   * codes).
   */
  state?: Maybe<Scalars['code']>;
  /**
   * Specifies the entire address as it should be displayed e.g. on a postal label.
   * This may be provided instead of or as well as the specific parts.
   */
  text?: Maybe<Scalars['code']>;
  /**
   * Distinguishes between physical addresses (those you can visit) and mailing
   * addresses (e.g. PO Boxes and care-of addresses). Most addresses are both.
   */
  type?: Maybe<Scalars['code']>;
  /** The purpose of this address. */
  use?: Maybe<Scalars['code']>;
};

/**
 * AdverseEvent
 *     Actual or  potential/avoided event causing unintended physical injury
 *     resulting from or contributed to by medical care, a research study or other
 *     healthcare setting factors that requires additional monitoring, treatment, or
 *     hospitalization, or that results in death.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type AdverseEvent = {
  __typename?: 'AdverseEvent';
  /**
   * Whether the event actually happened, or just had the potential to. Note that
   * this is independent of whether anyone was affected or harmed or how severely.
   */
  actuality: Scalars['code'];
  /** The overall type of event, intended for search and filtering purposes. */
  category?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Parties that may or should contribute or have contributed information to the
   * adverse event, which can consist of one or more activities.  Such information
   * includes information leading to the decision to perform the activity and how
   * to perform the activity (e.g. consultant), information that the activity
   * itself seeks to reveal (e.g. informant of clinical history), or information
   * about what activity was performed (e.g. informant witness).
   */
  contributor?: Maybe<Array<Maybe<AdverseEventContributor>>>;
  /** The date (and perhaps time) when the adverse event occurred. */
  date?: Maybe<Scalars['code']>;
  /**
   * Estimated or actual date the AdverseEvent began, in the opinion of the
   * reporter.
   */
  detected?: Maybe<Scalars['code']>;
  /**
   * The Encounter during which AdverseEvent was created or to which the creation
   * of this record is tightly associated.
   */
  encounter?: Maybe<Encounter>;
  /**
   * This element defines the specific type of event that occurred or that was
   * prevented from occurring.
   */
  event?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Business identifiers assigned to this adverse event by the performer or other
   * systems which remain constant as the resource is updated and propagates from
   * server to server.
   */
  identifier?: Maybe<Identifier>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** The information about where the adverse event occurred. */
  location?: Maybe<Location>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Describes the type of outcome from the adverse event. */
  outcome?: Maybe<CodeableConcept>;
  /** The date on which the existence of the AdverseEvent was first recorded. */
  recordedDate?: Maybe<Scalars['code']>;
  /**
   * Information on who recorded the adverse event.  May be the patient or a
   * practitioner.
   */
  recorder?: Maybe<AdverseEventRecorder>;
  /** AdverseEvent.referenceDocument. */
  referenceDocument?: Maybe<Array<Maybe<DocumentReference>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * Includes information about the reaction that occurred as a result of exposure
   * to a substance (for example, a drug or a chemical).
   */
  resultingCondition?: Maybe<Array<Maybe<Condition>>>;
  /** Assessment whether this event was of real importance. */
  seriousness?: Maybe<CodeableConcept>;
  /**
   * Describes the severity of the adverse event, in relation to the subject.
   * Contrast to AdverseEvent.seriousness - a severe rash might not be serious, but
   * a mild heart problem is.
   */
  severity?: Maybe<CodeableConcept>;
  /** AdverseEvent.study. */
  study?: Maybe<Array<Maybe<ResearchStudy>>>;
  /** This subject or group impacted by the event. */
  subject: AdverseEventSubject;
  /** AdverseEvent.subjectMedicalHistory. */
  subjectMedicalHistory?: Maybe<Array<Maybe<AdverseEventSubjectMedicalHistory>>>;
  /** Describes the entity that is suspected to have caused the adverse event. */
  suspectEntity?: Maybe<Array<Maybe<AdverseEventSuspectEntity>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * AdverseEvent.Causality
 *     Actual or  potential/avoided event causing unintended physical injury
 *     resulting from or contributed to by medical care, a research study or other
 *     healthcare setting factors that requires additional monitoring, treatment, or
 *     hospitalization, or that results in death.
 */
export type AdverseEventCausality = {
  __typename?: 'AdverseEventCausality';
  /** Assessment of if the entity caused the event. */
  assessment?: Maybe<CodeableConcept>;
  /** AdverseEvent.suspectEntity.causalityAuthor. */
  author?: Maybe<AdverseEventCausalityAuthor>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** ProbabilityScale | Bayesian | Checklist. */
  method?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** AdverseEvent.suspectEntity.causalityProductRelatedness. */
  productRelatedness?: Maybe<Scalars['code']>;
};

export type AdverseEventCausalityAuthor = Practitioner | PractitionerRole;

export type AdverseEventContributor = Device | Practitioner | PractitionerRole;

export type AdverseEventRecorder = Patient | Practitioner | PractitionerRole | RelatedPerson;

export type AdverseEventSubject = Group | Patient | Practitioner | RelatedPerson;

export type AdverseEventSubjectMedicalHistory = AllergyIntolerance | Condition | DocumentReference | FamilyMemberHistory | Immunization | Media | Observation | Procedure;

/**
 * AdverseEvent.SuspectEntity
 *     Actual or  potential/avoided event causing unintended physical injury
 *     resulting from or contributed to by medical care, a research study or other
 *     healthcare setting factors that requires additional monitoring, treatment, or
 *     hospitalization, or that results in death.
 */
export type AdverseEventSuspectEntity = {
  __typename?: 'AdverseEventSuspectEntity';
  /** Information on the possible cause of the event. */
  causality?: Maybe<Array<Maybe<AdverseEventCausality>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifies the actual instance of what caused the adverse event.  May be a
   * substance, medication, medication administration, medication statement or a
   * device.
   */
  instance: AdverseEventSuspectEntityInstance;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type AdverseEventSuspectEntityInstance = Device | Immunization | Medication | MedicationAdministration | MedicationStatement | Procedure | Substance;

/**
 * Age
 *     A duration of time during which an organism (or a process) has existed.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Age = {
  __typename?: 'Age';
  /** A computer processable form of the unit in some unit representation system. */
  code?: Maybe<Scalars['code']>;
  /**
   * How the value should be understood and represented - whether the actual value
   * is greater or less than the stated value due to measurement issues; e.g. if
   * the comparator is "<" , then the real value is < stated value.
   */
  comparator?: Maybe<Scalars['code']>;
  /** The identification of the system that provides the coded form of the unit. */
  system?: Maybe<Scalars['code']>;
  /** A human-readable form of the unit. */
  unit?: Maybe<Scalars['code']>;
  /**
   * The value of the measured amount. The value includes an implicit precision in
   * the presentation of the value.
   */
  value?: Maybe<Scalars['code']>;
};

/**
 * AllergyIntolerance
 *     Risk of harmful or undesirable, physiological response which is unique to an
 *     individual and associated with exposure to a substance.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type AllergyIntolerance = {
  __typename?: 'AllergyIntolerance';
  /** The source of the information about the allergy that is recorded. */
  asserter?: Maybe<AllergyIntoleranceAsserter>;
  /** Category of the identified substance. */
  category?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The clinical status of the allergy or intolerance. */
  clinicalStatus?: Maybe<CodeableConcept>;
  /**
   * Code for an allergy or intolerance statement (either a positive or a
   * negated/excluded statement).  This may be a code for a substance or
   * pharmaceutical product that is considered to be responsible for the adverse
   * reaction risk (e.g., "Latex"), an allergy or intolerance condition (e.g.,
   * "Latex allergy"), or a negated/excluded code for a specific substance or class
   * (e.g., "No latex allergy") or a general or categorical negated statement
   * (e.g.,  "No known allergy", "No known drug allergies").  Note: the substance
   * for a specific reaction may be different from the substance identified as the
   * cause of the risk, but it must be consistent with it. For instance, it may be
   * a more specific substance (e.g. a brand medication) or a composite product
   * that includes the identified substance. It must be clinically safe to only
   * process the 'code' and ignore the 'reaction.substance'.  If a receiving system
   * is unable to confirm that AllergyIntolerance.reaction.substance falls within
   * the semantic scope of AllergyIntolerance.code, then the receiving system
   * should ignore AllergyIntolerance.reaction.substance.
   */
  code?: Maybe<CodeableConcept>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Estimate of the potential clinical harm, or seriousness, of the reaction to
   * the identified substance.
   */
  criticality?: Maybe<Scalars['code']>;
  /** The encounter when the allergy or intolerance was asserted. */
  encounter?: Maybe<Encounter>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Business identifiers assigned to this AllergyIntolerance by the performer or
   * other systems which remain constant as the resource is updated and propagates
   * from server to server.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * Represents the date and/or time of the last known occurrence of a reaction
   * event.
   */
  lastOccurrence?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Additional narrative about the propensity for the Adverse Reaction, not
   * captured in other fields.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** None */
  onsetAge?: Maybe<Quantity>;
  /** None */
  onsetDateTime?: Maybe<Scalars['code']>;
  /** None */
  onsetPeriod?: Maybe<Period>;
  /** None */
  onsetRange?: Maybe<Range>;
  /** None */
  onsetString?: Maybe<Scalars['code']>;
  /** The patient who has the allergy or intolerance. */
  patient: Patient;
  /**
   * Details about each adverse reaction event linked to exposure to the identified
   * substance.
   */
  reaction?: Maybe<Array<Maybe<AllergyIntoleranceReaction>>>;
  /**
   * The recordedDate represents when this particular AllergyIntolerance record was
   * created in the system, which is often a system-generated date.
   */
  recordedDate?: Maybe<Scalars['code']>;
  /** Individual who recorded the record and takes responsibility for its content. */
  recorder?: Maybe<AllergyIntoleranceRecorder>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * Identification of the underlying physiological mechanism for the reaction
   * risk.
   */
  type?: Maybe<Scalars['code']>;
  /**
   * Assertion about certainty associated with the propensity, or potential risk,
   * of a reaction to the identified substance (including pharmaceutical product).
   */
  verificationStatus?: Maybe<CodeableConcept>;
};

export type AllergyIntoleranceAsserter = Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * AllergyIntolerance.Reaction
 *     Risk of harmful or undesirable, physiological response which is unique to an
 *     individual and associated with exposure to a substance.
 */
export type AllergyIntoleranceReaction = {
  __typename?: 'AllergyIntoleranceReaction';
  /**
   * Text description about the reaction as a whole, including details of the
   * manifestation if required.
   */
  description?: Maybe<Scalars['code']>;
  /** Identification of the route by which the subject was exposed to the substance. */
  exposureRoute?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Clinical symptoms and/or signs that are observed or associated with the
   * adverse reaction event.
   */
  manifestation: Array<Maybe<CodeableConcept>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Additional text about the adverse reaction event not captured in other fields. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** Record of the date and/or time of the onset of the Reaction. */
  onset?: Maybe<Scalars['code']>;
  /**
   * Clinical assessment of the severity of the reaction event as a whole,
   * potentially considering multiple different manifestations.
   */
  severity?: Maybe<Scalars['code']>;
  /**
   * Identification of the specific substance (or pharmaceutical product)
   * considered to be responsible for the Adverse Reaction event. Note: the
   * substance for a specific reaction may be different from the substance
   * identified as the cause of the risk, but it must be consistent with it. For
   * instance, it may be a more specific substance (e.g. a brand medication) or a
   * composite product that includes the identified substance. It must be
   * clinically safe to only process the 'code' and ignore the
   * 'reaction.substance'.  If a receiving system is unable to confirm that
   * AllergyIntolerance.reaction.substance falls within the semantic scope of
   * AllergyIntolerance.code, then the receiving system should ignore
   * AllergyIntolerance.reaction.substance.
   */
  substance?: Maybe<CodeableConcept>;
};

export type AllergyIntoleranceRecorder = Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Annotation
 *     A  text note which also  contains information about who made the statement and
 *     when.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Annotation = {
  __typename?: 'Annotation';
  /** None */
  authorReference?: Maybe<AnnotationAuthorReference>;
  /** None */
  authorString?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The text of the annotation in markdown format. */
  text: Scalars['code'];
  /** Indicates when this particular annotation was made. */
  time?: Maybe<Scalars['code']>;
};

export type AnnotationAuthorReference = Organization | Patient | Practitioner | RelatedPerson;

/**
 * Appointment
 *     A booking of a healthcare event among patient(s), practitioner(s), related
 *     person(s) and/or device(s) for a specific date/time. This may result in one or
 *     more Encounter(s).
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Appointment = {
  __typename?: 'Appointment';
  /**
   * The style of appointment or patient that has been booked in the slot (not
   * service type).
   */
  appointmentType?: Maybe<CodeableConcept>;
  /**
   * The service request this appointment is allocated to assess (e.g. incoming
   * referral or procedure request).
   */
  basedOn?: Maybe<Array<Maybe<ServiceRequest>>>;
  /**
   * The coded reason for the appointment being cancelled. This is often used in
   * reporting/billing/futher processing to determine if further actions are
   * required, or specific fees apply.
   */
  cancelationReason?: Maybe<CodeableConcept>;
  /** Additional comments about the appointment. */
  comment?: Maybe<Scalars['code']>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The date that this appointment was initially created. This could be different
   * to the meta.lastModified value on the initial entry, as this could have been
   * before the resource was created on the FHIR server, and should remain
   * unchanged over the lifespan of the appointment.
   */
  created?: Maybe<Scalars['code']>;
  /**
   * The brief description of the appointment as would be shown on a subject line
   * in a meeting request, or appointment list. Detailed or expanded information
   * should be put in the comment field.
   */
  description?: Maybe<Scalars['code']>;
  document?: Maybe<Array<Maybe<DocumentReference>>>;
  encounter?: Maybe<Array<Maybe<Encounter>>>;
  /** Date/Time that the appointment is to conclude. */
  end?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * This records identifiers associated with this appointment concern that are
   * defined by business processes and/or used to refer to it when a direct URL
   * reference to the resource itself is not appropriate (e.g. in CDA documents, or
   * in written / printed documentation).
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * Number of minutes that the appointment is to take. This can be less than the
   * duration between the start and end times.  For example, where the actual time
   * of appointment is only an estimate or if a 30 minute appointment is being
   * requested, but any time would work.  Also, if there is, for example, a planned
   * 15 minute break in the middle of a long appointment, the duration may be 15
   * minutes less than the difference between the start and end.
   */
  minutesDuration?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  officeBasedReferral?: Maybe<Scalars['Boolean']>;
  ogAppointmentId?: Maybe<Scalars['String']>;
  ogStage?: Maybe<Scalars['String']>;
  /** List of participants involved in the appointment. */
  participant: Array<Maybe<AppointmentParticipant>>;
  /**
   * While Appointment.comment contains information for internal use,
   * Appointment.patientInstructions is used to capture patient facing information
   * about the Appointment (e.g. please bring your referral or fast from 8pm night
   * before).
   */
  patientInstruction?: Maybe<Scalars['code']>;
  /**
   * The priority of the appointment. Can be used to make informed decisions if
   * needing to re-prioritize appointments. (The iCal Standard specifies 0 as
   * undefined, 1 as highest, 9 as lowest priority).
   */
  priority?: Maybe<Scalars['code']>;
  providerName?: Maybe<Array<Maybe<HumanName>>>;
  /**
   * The coded reason that this appointment is being scheduled. This is more
   * clinical than administrative.
   */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Reason the appointment has been scheduled to take place, as specified using
   * information from another resource. When the patient arrives and the encounter
   * begins it may be used as the admission diagnosis. The indication will
   * typically be a Condition (with other resources referenced in the
   * evidence.detail), or a Procedure.
   */
  reasonReference?: Maybe<Array<Maybe<AppointmentReasonReference>>>;
  /**
   * A set of date ranges (potentially including times) that the appointment is
   * preferred to be scheduled within.
   *
   * The duration (usually in minutes) could also be provided to indicate the
   * length of the appointment to fill and populate the start/end times for the
   * actual allocated time. However, in other situations the duration may be
   * calculated by the scheduling system.
   */
  requestedPeriod?: Maybe<Array<Maybe<Period>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A broad categorization of the service that is to be performed during this
   * appointment.
   */
  serviceCategory?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The specific service that is to be performed during this appointment. */
  serviceType?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The slots from the participants' schedules that will be filled by the
   * appointment.
   */
  slot?: Maybe<Array<Maybe<Slot>>>;
  /**
   * The specialty of a practitioner that would be required to perform the service
   * requested in this appointment.
   */
  specialty?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Date/Time that the appointment is to take place. */
  start?: Maybe<Scalars['code']>;
  /**
   * The overall status of the Appointment. Each of the participants has their own
   * participation status which indicates their involvement in the process, however
   * this status indicates the shared status.
   */
  status: Scalars['code'];
  /**
   * Additional information to support the appointment provided when making the
   * appointment.
   */
  supportingInformation?: Maybe<Array<Maybe<Resource>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * Appointment.Participant
 *     A booking of a healthcare event among patient(s), practitioner(s), related
 *     person(s) and/or device(s) for a specific date/time. This may result in one or
 *     more Encounter(s).
 */
export type AppointmentParticipant = {
  __typename?: 'AppointmentParticipant';
  /**
   * A Person, Location/HealthcareService or Device that is participating in the
   * appointment.
   */
  actor?: Maybe<AppointmentParticipantActor>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Participation period of the actor. */
  period?: Maybe<Period>;
  /**
   * Whether this participant is required to be present at the meeting. This covers
   * a use-case where two doctors need to meet to discuss the results for a
   * specific patient, and the patient is not required to be present.
   */
  required?: Maybe<Scalars['code']>;
  /** Participation status of the actor. */
  status: Scalars['code'];
  /** Role of participant in the appointment. */
  type?: Maybe<Array<Maybe<CodeableConcept>>>;
};

export type AppointmentParticipantActor = Device | HealthcareService | Location | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type AppointmentReasonReference = Condition | ImmunizationRecommendation | Observation | Procedure;

/**
 * AppointmentResponse
 *     A reply to an appointment request for a patient and/or practitioner(s), such
 *     as a confirmation or rejection.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type AppointmentResponse = {
  __typename?: 'AppointmentResponse';
  /**
   * A Person, Location, HealthcareService, or Device that is participating in the
   * appointment.
   */
  actor?: Maybe<AppointmentResponseActor>;
  /** Appointment that this response is replying to. */
  appointment: Appointment;
  /** Additional comments about the appointment. */
  comment?: Maybe<Scalars['code']>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * This may be either the same as the appointment request to confirm the details
   * of the appointment, or alternately a new time to request a re-negotiation of
   * the end time.
   */
  end?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * This records identifiers associated with this appointment response concern
   * that are defined by business processes and/ or used to refer to it when a
   * direct URL reference to the resource itself is not appropriate.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Participation status of the participant. When the status is declined or
   * tentative if the start/end times are different to the appointment, then these
   * times should be interpreted as a requested time change. When the status is
   * accepted, the times can either be the time of the appointment (as a
   * confirmation of the time) or can be empty.
   */
  participantStatus: Scalars['code'];
  /** Role of participant in the appointment. */
  participantType?: Maybe<Array<Maybe<CodeableConcept>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** Date/Time that the appointment is to take place, or requested new start time. */
  start?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type AppointmentResponseActor = Device | HealthcareService | Location | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Attachment
 *     For referring to data content defined in other formats.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Attachment = {
  __typename?: 'Attachment';
  /**
   * Identifies the type of the data in the attachment and allows a method to be
   * chosen to interpret or render the data. Includes mime type parameters such as
   * charset where appropriate.
   */
  contentType?: Maybe<Scalars['code']>;
  /** The date that the attachment was first created. */
  creation?: Maybe<Scalars['code']>;
  /** The actual data of the attachment - a sequence of bytes, base64 encoded. */
  data?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** The calculated hash of the data using SHA-1. Represented using base64. */
  hash?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The human language of the content. The value can be any valid value according
   * to BCP 47.
   */
  language?: Maybe<Scalars['code']>;
  /**
   * The number of bytes of data that make up this attachment (before base64
   * encoding, if that is done).
   */
  size?: Maybe<Scalars['code']>;
  /** A label or set of text to display in place of the data. */
  title?: Maybe<Scalars['code']>;
  /** A location where the data can be accessed. */
  url?: Maybe<Scalars['code']>;
};

/**
 * AuditEvent
 *     A record of an event made for purposes of maintaining a security log. Typical
 *     uses include detection of intrusion attempts and monitoring for inappropriate
 *     usage.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type AuditEvent = {
  __typename?: 'AuditEvent';
  /**
   * Indicator for type of action performed during the event that generated the
   * audit.
   */
  action?: Maybe<Scalars['code']>;
  /** An actor taking an active role in the event or activity that is logged. */
  agent: Array<Maybe<AuditEventAgent>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Specific instances of data or objects that have been accessed. */
  entity?: Maybe<Array<Maybe<AuditEventEntity>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Indicates whether the event succeeded or failed. */
  outcome?: Maybe<Scalars['code']>;
  /** A free text description of the outcome of the event. */
  outcomeDesc?: Maybe<Scalars['code']>;
  /** The period during which the activity occurred. */
  period?: Maybe<Period>;
  /** The purposeOfUse (reason) that was used during the event being recorded. */
  purposeOfEvent?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The time when the event was recorded. */
  recorded: Scalars['code'];
  resourceType?: Maybe<Scalars['String']>;
  /** The system that is reporting the event. */
  source: AuditEventSource;
  /** Identifier for the category of event. */
  subtype?: Maybe<Array<Maybe<Coding>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * Identifier for a family of the event.  For example, a menu item, program,
   * rule, policy, function code, application name or URL. It identifies the
   * performed function.
   */
  type: Coding;
};

/**
 * AuditEvent.Agent
 *     A record of an event made for purposes of maintaining a security log. Typical
 *     uses include detection of intrusion attempts and monitoring for inappropriate
 *     usage.
 */
export type AuditEventAgent = {
  __typename?: 'AuditEventAgent';
  /**
   * Alternative agent Identifier. For a human, this should be a user identifier
   * text string from authentication system. This identifier would be one known to
   * a common authentication system (e.g. single sign-on), if available.
   */
  altId?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Where the event occurred. */
  location?: Maybe<Location>;
  /**
   * Type of media involved. Used when the event is about exporting/importing onto
   * media.
   */
  media?: Maybe<Coding>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Human-meaningful name for the agent. */
  name?: Maybe<Scalars['code']>;
  /**
   * Logical network location for application activity, if the activity has a
   * network location.
   */
  network?: Maybe<AuditEventNetwork>;
  /**
   * The policy or plan that authorized the activity being recorded. Typically, a
   * single activity may have multiple applicable policies, such as patient
   * consent, guarantor funding, etc. The policy would also indicate the security
   * token used.
   */
  policy?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The reason (purpose of use), specific to this agent, that was used during the
   * event being recorded.
   */
  purposeOfUse?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Indicator that the user is or is not the requestor, or initiator, for the
   * event being audited.
   */
  requestor: Scalars['code'];
  /**
   * The security role that the user was acting under, that come from local codes
   * defined by the access control security system (e.g. RBAC, ABAC) used in the
   * local context.
   */
  role?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Specification of the participation type the user plays when performing the
   * event.
   */
  type?: Maybe<CodeableConcept>;
  /** Reference to who this agent is that was involved in the event. */
  who?: Maybe<AuditEventAgentWho>;
};

export type AuditEventAgentWho = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * AuditEvent.Detail
 *     A record of an event made for purposes of maintaining a security log. Typical
 *     uses include detection of intrusion attempts and monitoring for inappropriate
 *     usage.
 */
export type AuditEventDetail = {
  __typename?: 'AuditEventDetail';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The type of extra detail provided in the value. */
  type: Scalars['code'];
  /** None */
  valueBase64Binary?: Maybe<Scalars['code']>;
  /** None */
  valueString?: Maybe<Scalars['code']>;
};

/**
 * AuditEvent.Entity
 *     A record of an event made for purposes of maintaining a security log. Typical
 *     uses include detection of intrusion attempts and monitoring for inappropriate
 *     usage.
 */
export type AuditEventEntity = {
  __typename?: 'AuditEventEntity';
  /** Text that describes the entity in more detail. */
  description?: Maybe<Scalars['code']>;
  /** Tagged value pairs for conveying additional information about the entity. */
  detail?: Maybe<Array<Maybe<AuditEventDetail>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Identifier for the data life-cycle stage for the entity. */
  lifecycle?: Maybe<Coding>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A name of the entity in the audit event. */
  name?: Maybe<Scalars['code']>;
  /** The query parameters for a query-type entities. */
  query?: Maybe<Scalars['code']>;
  /** Code representing the role the entity played in the event being audited. */
  role?: Maybe<Coding>;
  /** Security labels for the identified entity. */
  securityLabel?: Maybe<Array<Maybe<Coding>>>;
  /** The type of the object that was involved in this audit event. */
  type?: Maybe<Coding>;
  /**
   * Identifies a specific instance of the entity. The reference should be version
   * specific.
   */
  what?: Maybe<Resource>;
};

/**
 * AuditEvent.Network
 *     A record of an event made for purposes of maintaining a security log. Typical
 *     uses include detection of intrusion attempts and monitoring for inappropriate
 *     usage.
 */
export type AuditEventNetwork = {
  __typename?: 'AuditEventNetwork';
  /**
   * An identifier for the network access point of the user device for the audit
   * event.
   */
  address?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * An identifier for the type of network access point that originated the audit
   * event.
   */
  type?: Maybe<Scalars['code']>;
};

/**
 * AuditEvent.Source
 *     A record of an event made for purposes of maintaining a security log. Typical
 *     uses include detection of intrusion attempts and monitoring for inappropriate
 *     usage.
 */
export type AuditEventSource = {
  __typename?: 'AuditEventSource';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Identifier of the source where the event was detected. */
  observer: AuditEventSourceObserver;
  /**
   * Logical source location within the healthcare enterprise network.  For
   * example, a hospital or other provider location within a multi-entity provider
   * group.
   */
  site?: Maybe<Scalars['code']>;
  /** Code specifying the type of source where event originated. */
  type?: Maybe<Array<Maybe<Coding>>>;
};

export type AuditEventSourceObserver = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * BackboneElement
 *     Base definition for all elements that are defined inside a resource - but not
 *     those in a data type.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type BackboneElement = {
  __typename?: 'BackboneElement';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * Basic
 *     Basic is used for handling concepts not yet defined in FHIR, narrative-only
 *     resources that don't map to an existing resource, and custom resources not
 *     appropriate for inclusion in the FHIR specification.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Basic = {
  __typename?: 'Basic';
  /** Indicates who was responsible for creating the resource instance. */
  author?: Maybe<BasicAuthor>;
  /**
   * Identifies the 'type' of resource - equivalent to the resource name for other
   * resources.
   */
  code: CodeableConcept;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Identifies when the resource was first created. */
  created?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifier assigned to the resource for business purposes, outside the context
   * of FHIR.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * Identifies the patient, practitioner, device or any other resource that is the
   * "focus" of this resource.
   */
  subject?: Maybe<Resource>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type BasicAuthor = Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Binary
 *     A resource that represents the data of a single raw artifact as digital
 *     content accessible in its native format.  A Binary resource can contain any
 *     content, whether text, image, pdf, zip archive, etc.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Binary = {
  __typename?: 'Binary';
  /** MimeType of the binary content represented as a standard MimeType (BCP 13). */
  contentType: Scalars['code'];
  /** The actual content, base64 encoded. */
  data?: Maybe<Scalars['code']>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * This element identifies another resource that can be used as a proxy of the
   * security sensitivity to use when deciding and enforcing access control rules
   * for the Binary resource. Given that the Binary resource contains very few
   * elements that can be used to determine the sensitivity of the data and
   * relationships to individuals, the referenced resource stands in as a proxy
   * equivalent for this purpose. This referenced resource may be related to the
   * Binary (e.g. Media, DocumentReference), or may be some non-related Resource
   * purely as a security proxy. E.g. to identify that the binary resource relates
   * to a patient, and access should only be granted to applications that have
   * access to the patient.
   */
  securityContext?: Maybe<Resource>;
};

/**
 * BiologicallyDerivedProduct
 *     A material substance originating from a biological entity intended to be
 *     transplanted or infused
 *     into another (possibly the same) biological entity.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type BiologicallyDerivedProduct = {
  __typename?: 'BiologicallyDerivedProduct';
  /** How this product was collected. */
  collection?: Maybe<BiologicallyDerivedProductCollection>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * This records identifiers associated with this biologically derived product
   * instance that are defined by business processes and/or used to refer to it
   * when a direct URL reference to the resource itself is not appropriate (e.g. in
   * CDA documents, or in written / printed documentation).
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * Any manipulation of product post-collection that is intended to alter the
   * product.  For example a buffy-coat enrichment or CD8 reduction of Peripheral
   * Blood Stem Cells to make it more suitable for infusion.
   */
  manipulation?: Maybe<BiologicallyDerivedProductManipulation>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Parent product (if any). */
  parent?: Maybe<Array<Maybe<BiologicallyDerivedProduct>>>;
  /**
   * Any processing of the product during collection that does not change the
   * fundamental nature of the product. For example adding anti-coagulants during
   * the collection of Peripheral Blood Stem Cells.
   */
  processing?: Maybe<Array<Maybe<BiologicallyDerivedProductProcessing>>>;
  /** Broad category of this product. */
  productCategory?: Maybe<Scalars['code']>;
  /**
   * A code that identifies the kind of this biologically derived product (SNOMED
   * Ctcode).
   */
  productCode?: Maybe<CodeableConcept>;
  /** Number of discrete units within this product. */
  quantity?: Maybe<Scalars['code']>;
  /** Procedure request to obtain this biologically derived product. */
  request?: Maybe<Array<Maybe<ServiceRequest>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** Whether the product is currently available. */
  status?: Maybe<Scalars['code']>;
  /** Product storage. */
  storage?: Maybe<Array<Maybe<BiologicallyDerivedProductStorage>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * BiologicallyDerivedProduct.Collection
 *     A material substance originating from a biological entity intended to be
 *     transplanted or infused
 *     into another (possibly the same) biological entity.
 */
export type BiologicallyDerivedProductCollection = {
  __typename?: 'BiologicallyDerivedProductCollection';
  /** None */
  collectedDateTime?: Maybe<Scalars['code']>;
  /** None */
  collectedPeriod?: Maybe<Period>;
  /** Healthcare professional who is performing the collection. */
  collector?: Maybe<BiologicallyDerivedProductCollectionCollector>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The patient or entity, such as a hospital or vendor in the case of a
   * processed/manipulated/manufactured product, providing the product.
   */
  source?: Maybe<BiologicallyDerivedProductCollectionSource>;
};

export type BiologicallyDerivedProductCollectionCollector = Practitioner | PractitionerRole;

export type BiologicallyDerivedProductCollectionSource = Organization | Patient;

/**
 * BiologicallyDerivedProduct.Manipulation
 *     A material substance originating from a biological entity intended to be
 *     transplanted or infused
 *     into another (possibly the same) biological entity.
 */
export type BiologicallyDerivedProductManipulation = {
  __typename?: 'BiologicallyDerivedProductManipulation';
  /** Description of manipulation. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  timeDateTime?: Maybe<Scalars['code']>;
  /** None */
  timePeriod?: Maybe<Period>;
};

/**
 * BiologicallyDerivedProduct.Processing
 *     A material substance originating from a biological entity intended to be
 *     transplanted or infused
 *     into another (possibly the same) biological entity.
 */
export type BiologicallyDerivedProductProcessing = {
  __typename?: 'BiologicallyDerivedProductProcessing';
  /** Substance added during processing. */
  additive?: Maybe<Substance>;
  /** Description of of processing. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Procesing code. */
  procedure?: Maybe<CodeableConcept>;
  /** None */
  timeDateTime?: Maybe<Scalars['code']>;
  /** None */
  timePeriod?: Maybe<Period>;
};

/**
 * BiologicallyDerivedProduct.Storage
 *     A material substance originating from a biological entity intended to be
 *     transplanted or infused
 *     into another (possibly the same) biological entity.
 */
export type BiologicallyDerivedProductStorage = {
  __typename?: 'BiologicallyDerivedProductStorage';
  /** Description of storage. */
  description?: Maybe<Scalars['code']>;
  /** Storage timeperiod. */
  duration?: Maybe<Period>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Temperature scale used. */
  scale?: Maybe<Scalars['code']>;
  /** Storage temperature. */
  temperature?: Maybe<Scalars['code']>;
};

/**
 * BodyStructure
 *     Record details about an anatomical structure.  This resource may be used when
 *     a coded concept does not provide the necessary detail needed for the use case.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type BodyStructure = {
  __typename?: 'BodyStructure';
  /** Whether this body site is in active use. */
  active?: Maybe<Scalars['code']>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** A summary, characterization or explanation of the body structure. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Identifier for this instance of the anatomical structure. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /** Image or images used to identify a location. */
  image?: Maybe<Array<Maybe<Attachment>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** The anatomical location or region of the specimen, lesion, or body structure. */
  location?: Maybe<CodeableConcept>;
  /**
   * Qualifier to refine the anatomical location.  These include qualifiers for
   * laterality, relative location, directionality, number, and plane.
   */
  locationQualifier?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The kind of structure being represented by the body structure at
   * `BodyStructure.location`.  This can define both normal and abnormal
   * morphologies.
   */
  morphology?: Maybe<CodeableConcept>;
  /** The person to which the body site belongs. */
  patient: Patient;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * Bundle
 *     A container for a collection of resources.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Bundle = {
  __typename?: 'Bundle';
  /**
   * An entry in a bundle resource - will either contain a resource or information
   * about a resource (transactions and history only).
   */
  entry?: Maybe<Array<Maybe<BundleEntry>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A persistent identifier for the bundle that won't change as a bundle is copied
   * from server to server.
   */
  identifier?: Maybe<Identifier>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** A series of links that provide context to this bundle. */
  link?: Maybe<Array<Maybe<BundleLink>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  resourceType?: Maybe<Scalars['String']>;
  /** Digital Signature - base64 encoded. XML-DSig or a JWT. */
  signature?: Maybe<Signature>;
  /**
   * The date/time that the bundle was assembled - i.e. when the resources were
   * placed in the bundle.
   */
  timestamp?: Maybe<Scalars['code']>;
  /**
   * If a set of search matches, this is the total number of entries of type
   * 'match' across all pages in the search.  It does not include search.mode =
   * 'include' or 'outcome' entries and it does not provide a count of the number
   * of entries in the Bundle.
   */
  total?: Maybe<Scalars['code']>;
  /** Indicates the purpose of this bundle - how it is intended to be used. */
  type: Scalars['code'];
};

/**
 * Bundle.Entry
 *     A container for a collection of resources.
 */
export type BundleEntry = {
  __typename?: 'BundleEntry';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The Absolute URL for the resource.  The fullUrl SHALL NOT disagree with the id
   * in the resource - i.e. if the fullUrl is not a urn:uuid, the URL shall be
   * version-independent URL consistent with the Resource.id. The fullUrl is a
   * version independent reference to the resource. The fullUrl element SHALL have
   * a value except that:
   * * fullUrl can be empty on a POST (although it does not need to when specifying
   * a temporary id for reference in the bundle)
   * * Results from operations might involve resources that are not identified.
   */
  fullUrl?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** A series of links that provide context to this entry. */
  link?: Maybe<Array<Maybe<BundleLink>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Additional information about how this entry should be processed as part of a
   * transaction or batch.  For history, it shows how the entry was processed to
   * create the version contained in the entry.
   */
  request?: Maybe<BundleRequest>;
  /**
   * The Resource for the entry. The purpose/meaning of the resource is determined
   * by the Bundle.type.
   */
  resource?: Maybe<Scalars['code']>;
  /**
   * Indicates the results of processing the corresponding 'request' entry in the
   * batch or transaction being responded to or what the results of an operation
   * where when returning history.
   */
  response?: Maybe<BundleResponse>;
  /** Information about the search process that lead to the creation of this entry. */
  search?: Maybe<BundleSearch>;
};

/**
 * Bundle.Link
 *     A container for a collection of resources.
 */
export type BundleLink = {
  __typename?: 'BundleLink';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A name which details the functional use for this link - see
   * [http://www.iana.org/assignments/link-relations/link-relations.xhtml#link-
   * relations-1](http://www.iana.org/assignments/link-relations/link-
   * relations.xhtml#link-relations-1).
   */
  relation: Scalars['code'];
  /** The reference details for the link. */
  url: Scalars['code'];
};

/**
 * Bundle.Request
 *     A container for a collection of resources.
 */
export type BundleRequest = {
  __typename?: 'BundleRequest';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Only perform the operation if the Etag value matches. For more information,
   * see the API section ["Managing Resource Contention"](http.html#concurrency).
   */
  ifMatch?: Maybe<Scalars['code']>;
  /**
   * Only perform the operation if the last updated date matches. See the API
   * documentation for ["Conditional Read"](http.html#cread).
   */
  ifModifiedSince?: Maybe<Scalars['code']>;
  /**
   * Instruct the server not to perform the create if a specified resource already
   * exists. For further information, see the API documentation for ["Conditional
   * Create"](http.html#ccreate). This is just the query portion of the URL - what
   * follows the "?" (not including the "?").
   */
  ifNoneExist?: Maybe<Scalars['code']>;
  /**
   * If the ETag values match, return a 304 Not Modified status. See the API
   * documentation for ["Conditional Read"](http.html#cread).
   */
  ifNoneMatch?: Maybe<Scalars['code']>;
  /**
   * In a transaction or batch, this is the HTTP action to be executed for this
   * entry. In a history bundle, this indicates the HTTP action that occurred.
   */
  method: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The URL for this entry, relative to the root (the address to which the request
   * is posted).
   */
  url: Scalars['code'];
};

/**
 * Bundle.Response
 *     A container for a collection of resources.
 */
export type BundleResponse = {
  __typename?: 'BundleResponse';
  /**
   * The Etag for the resource, if the operation for the entry produced a versioned
   * resource (see [Resource Metadata and Versioning](http.html#versioning) and
   * [Managing Resource Contention](http.html#concurrency)).
   */
  etag?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The date/time that the resource was modified on the server. */
  lastModified?: Maybe<Scalars['code']>;
  /**
   * The location header created by processing this operation, populated if the
   * operation returns a location.
   */
  location?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * An OperationOutcome containing hints and warnings produced as part of
   * processing this entry in a batch or transaction.
   */
  outcome?: Maybe<Scalars['code']>;
  /**
   * The status code returned by processing this entry. The status SHALL start with
   * a 3 digit HTTP code (e.g. 404) and may contain the standard HTTP description
   * associated with the status code.
   */
  status: Scalars['code'];
};

/**
 * Bundle.Search
 *     A container for a collection of resources.
 */
export type BundleSearch = {
  __typename?: 'BundleSearch';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Why this entry is in the result set - whether it's included as a match or
   * because of an _include requirement, or to convey information or warning
   * information about the search process.
   */
  mode?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** When searching, the server's search ranking score for the entry. */
  score?: Maybe<Scalars['code']>;
};

/**
 * CapabilityStatement
 *     A Capability Statement documents a set of capabilities (behaviors) of a FHIR
 *     Server for a particular version of FHIR that may be used as a statement of
 *     actual server functionality or a statement of required or desired server
 *     implementation.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type CapabilityStatement = {
  __typename?: 'CapabilityStatement';
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the capability statement and/or its
   * contents. Copyright statements are generally legal restrictions on the use and
   * publishing of the capability statement.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the capability statement was published.
   * The date must change when the business version changes and it must change if
   * the status code changes. In addition, it should change when the substantive
   * content of the capability statement changes.
   */
  date: Scalars['code'];
  /**
   * A free text natural language description of the capability statement from a
   * consumer's perspective. Typically, this is used when the capability statement
   * describes a desired rather than an actual solution, for example as a formal
   * expression of requirements as part of an RFP.
   */
  description?: Maybe<Scalars['code']>;
  /** A document definition. */
  document?: Maybe<Array<Maybe<CapabilityStatementDocument>>>;
  /**
   * A Boolean value to indicate that this capability statement is authored for
   * testing purposes (or education/evaluation/marketing) and is not intended to be
   * used for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The version of the FHIR specification that this CapabilityStatement describes
   * (which SHALL be the same as the FHIR version of the CapabilityStatement
   * itself). There is no default value.
   */
  fhirVersion: Scalars['code'];
  /**
   * A list of the formats supported by this implementation using their content
   * types.
   */
  format: Array<Maybe<Scalars['code']>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifies a specific implementation instance that is described by the
   * capability statement - i.e. a particular installation, rather than the
   * capabilities of a software program.
   */
  implementation?: Maybe<CapabilityStatementImplementation>;
  /**
   * A list of implementation guides that the server does (or should) support in
   * their entirety.
   */
  implementationGuide?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * Reference to a canonical URL of another CapabilityStatement that this software
   * adds to. The capability statement automatically includes everything in the
   * other statement, and it is not duplicated, though the server may repeat the
   * same resources, interactions and operations to add additional details to them.
   */
  imports?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Reference to a canonical URL of another CapabilityStatement that this software
   * implements. This capability statement is a published API description that
   * corresponds to a business service. The server may actually implement a subset
   * of the capability statement it claims to implement, so the capability
   * statement must specify the full capability details.
   */
  instantiates?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A legal or geographic region in which the capability statement is intended to
   * be used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The way that this statement is intended to be used, to describe an actual
   * running instance of software, a particular product (kind, not instance of
   * software) or a class of implementation (e.g. a desired purchase).
   */
  kind: Scalars['code'];
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** A description of the messaging capabilities of the solution. */
  messaging?: Maybe<Array<Maybe<CapabilityStatementMessaging>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the capability statement. This name should
   * be usable as an identifier for the module by machine processing applications
   * such as code generation.
   */
  name?: Maybe<Scalars['code']>;
  /**
   * A list of the patch formats supported by this implementation using their
   * content types.
   */
  patchFormat?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The name of the organization or individual that published the capability
   * statement.
   */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this capability statement is needed and why it has been
   * designed as it has.
   */
  purpose?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /** A definition of the restful capabilities of the solution, if any. */
  rest?: Maybe<Array<Maybe<CapabilityStatementRest>>>;
  /**
   * Software that is covered by this capability statement.  It is used when the
   * capability statement describes the capabilities of a particular software
   * version, independent of an installation.
   */
  software?: Maybe<CapabilityStatementSoftware>;
  /**
   * The status of this capability statement. Enables tracking the life-cycle of
   * the content.
   */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the capability statement. */
  title?: Maybe<Scalars['code']>;
  /**
   * An absolute URI that is used to identify this capability statement when it is
   * referenced in a specification, model, design or an instance; also called its
   * canonical identifier. This SHOULD be globally unique and SHOULD be a literal
   * address at which at which an authoritative instance of this capability
   * statement is (or will be) published. This URL can be the target of a canonical
   * reference. It SHALL remain the same when the capability statement is stored on
   * different servers.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate capability
   * statement instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the capability
   * statement when it is referenced in a specification, model, design or instance.
   * This is an arbitrary value managed by the capability statement author and is
   * not expected to be globally unique. For example, it might be a timestamp (e.g.
   * yyyymmdd) if a managed version is not available. There is also no expectation
   * that versions can be placed in a lexicographical sequence.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * CapabilityStatement.Document
 *     A Capability Statement documents a set of capabilities (behaviors) of a FHIR
 *     Server for a particular version of FHIR that may be used as a statement of
 *     actual server functionality or a statement of required or desired server
 *     implementation.
 */
export type CapabilityStatementDocument = {
  __typename?: 'CapabilityStatementDocument';
  /**
   * A description of how the application supports or uses the specified document
   * profile.  For example, when documents are created, what action is taken with
   * consumed documents, etc.
   */
  documentation?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Mode of this document declaration - whether an application is a producer or
   * consumer.
   */
  mode: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A profile on the document Bundle that constrains which resources are present,
   * and their contents.
   */
  profile: Scalars['code'];
};

/**
 * CapabilityStatement.Endpoint
 *     A Capability Statement documents a set of capabilities (behaviors) of a FHIR
 *     Server for a particular version of FHIR that may be used as a statement of
 *     actual server functionality or a statement of required or desired server
 *     implementation.
 */
export type CapabilityStatementEndpoint = {
  __typename?: 'CapabilityStatementEndpoint';
  /**
   * The network address of the endpoint. For solutions that do not use network
   * addresses for routing, it can be just an identifier.
   */
  address: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A list of the messaging transport protocol(s) identifiers, supported by this
   * endpoint.
   */
  protocol: Coding;
};

/**
 * CapabilityStatement.Implementation
 *     A Capability Statement documents a set of capabilities (behaviors) of a FHIR
 *     Server for a particular version of FHIR that may be used as a statement of
 *     actual server functionality or a statement of required or desired server
 *     implementation.
 */
export type CapabilityStatementImplementation = {
  __typename?: 'CapabilityStatementImplementation';
  /**
   * The organization responsible for the management of the instance and oversight
   * of the data on the server at the specified URL.
   */
  custodian?: Maybe<Organization>;
  /**
   * Information about the specific installation that this capability statement
   * relates to.
   */
  description: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * An absolute base URL for the implementation.  This forms the base for REST
   * interfaces as well as the mailbox and document interfaces.
   */
  url?: Maybe<Scalars['code']>;
};

/**
 * CapabilityStatement.Interaction
 *     A Capability Statement documents a set of capabilities (behaviors) of a FHIR
 *     Server for a particular version of FHIR that may be used as a statement of
 *     actual server functionality or a statement of required or desired server
 *     implementation.
 */
export type CapabilityStatementInteraction = {
  __typename?: 'CapabilityStatementInteraction';
  /** Coded identifier of the operation, supported by the system resource. */
  code: Scalars['code'];
  /**
   * Guidance specific to the implementation of this operation, such as 'delete is
   * a logical delete' or 'updates are only allowed with version id' or 'creates
   * permitted from pre-authorized certificates only'.
   */
  documentation?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * CapabilityStatement.Interaction1
 *     A Capability Statement documents a set of capabilities (behaviors) of a FHIR
 *     Server for a particular version of FHIR that may be used as a statement of
 *     actual server functionality or a statement of required or desired server
 *     implementation.
 */
export type CapabilityStatementInteraction1 = {
  __typename?: 'CapabilityStatementInteraction1';
  /** A coded identifier of the operation, supported by the system. */
  code: Scalars['code'];
  /**
   * Guidance specific to the implementation of this operation, such as limitations
   * on the kind of transactions allowed, or information about system wide search
   * is implemented.
   */
  documentation?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * CapabilityStatement.Messaging
 *     A Capability Statement documents a set of capabilities (behaviors) of a FHIR
 *     Server for a particular version of FHIR that may be used as a statement of
 *     actual server functionality or a statement of required or desired server
 *     implementation.
 */
export type CapabilityStatementMessaging = {
  __typename?: 'CapabilityStatementMessaging';
  /**
   * Documentation about the system's messaging capabilities for this endpoint not
   * otherwise documented by the capability statement.  For example, the process
   * for becoming an authorized messaging exchange partner.
   */
  documentation?: Maybe<Scalars['code']>;
  /**
   * An endpoint (network accessible address) to which messages and/or replies are
   * to be sent.
   */
  endpoint?: Maybe<Array<Maybe<CapabilityStatementEndpoint>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Length if the receiver's reliable messaging cache in minutes (if a receiver)
   * or how long the cache length on the receiver should be (if a sender).
   */
  reliableCache?: Maybe<Scalars['code']>;
  /**
   * References to message definitions for messages this system can send or
   * receive.
   */
  supportedMessage?: Maybe<Array<Maybe<CapabilityStatementSupportedMessage>>>;
};

/**
 * CapabilityStatement.Operation
 *     A Capability Statement documents a set of capabilities (behaviors) of a FHIR
 *     Server for a particular version of FHIR that may be used as a statement of
 *     actual server functionality or a statement of required or desired server
 *     implementation.
 */
export type CapabilityStatementOperation = {
  __typename?: 'CapabilityStatementOperation';
  /**
   * Where the formal definition can be found. If a server references the base
   * definition of an Operation (i.e. from the specification itself such as
   * ```http://hl7.org/fhir/OperationDefinition/ValueSet-expand```), that means it
   * supports the full capabilities of the operation - e.g. both GET and POST
   * invocation.  If it only supports a subset, it must define its own custom
   * [[[OperationDefinition]]] with a 'base' of the original OperationDefinition.
   * The custom definition would describe the specific subset of functionality
   * supported.
   */
  definition: Scalars['code'];
  /**
   * Documentation that describes anything special about the operation behavior,
   * possibly detailing different behavior for system, type and instance-level
   * invocation of the operation.
   */
  documentation?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The name of the operation or query. For an operation, this is the name
   * prefixed with $ and used in the URL. For a query, this is the name used in the
   * _query parameter when the query is called.
   */
  name: Scalars['code'];
};

/**
 * CapabilityStatement.Resource
 *     A Capability Statement documents a set of capabilities (behaviors) of a FHIR
 *     Server for a particular version of FHIR that may be used as a statement of
 *     actual server functionality or a statement of required or desired server
 *     implementation.
 */
export type CapabilityStatementResource = {
  __typename?: 'CapabilityStatementResource';
  /** A flag that indicates that the server supports conditional create. */
  conditionalCreate?: Maybe<Scalars['code']>;
  /** A code that indicates how the server supports conditional delete. */
  conditionalDelete?: Maybe<Scalars['code']>;
  /** A code that indicates how the server supports conditional read. */
  conditionalRead?: Maybe<Scalars['code']>;
  /** A flag that indicates that the server supports conditional update. */
  conditionalUpdate?: Maybe<Scalars['code']>;
  /** Additional information about the resource type used by the system. */
  documentation?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Identifies a restful operation supported by the solution. */
  interaction?: Maybe<Array<Maybe<CapabilityStatementInteraction>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Definition of an operation or a named query together with its parameters and
   * their meaning and type. Consult the definition of the operation for details
   * about how to invoke the operation, and the parameters.
   */
  operation?: Maybe<Array<Maybe<CapabilityStatementOperation>>>;
  /**
   * A specification of the profile that describes the solution's overall support
   * for the resource, including any constraints on cardinality, bindings, lengths
   * or other limitations. See further discussion in [Using
   * Profiles](profiling.html#profile-uses).
   */
  profile?: Maybe<Scalars['code']>;
  /**
   * A flag for whether the server is able to return past versions as part of the
   * vRead operation.
   */
  readHistory?: Maybe<Scalars['code']>;
  /** A set of flags that defines how references are supported. */
  referencePolicy?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** A list of _include values supported by the server. */
  searchInclude?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Search parameters for implementations to support and/or make use of - either
   * references to ones defined in the specification, or additional ones defined
   * for/by the implementation.
   */
  searchParam?: Maybe<Array<Maybe<CapabilityStatementSearchParam>>>;
  /** A list of _revinclude (reverse include) values supported by the server. */
  searchRevInclude?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A list of profiles that represent different use cases supported by the system.
   * For a server, "supported by the system" means the system hosts/produces a set
   * of resources that are conformant to a particular profile, and allows clients
   * that use its services to search using this profile and to find appropriate
   * data. For a client, it means the system will search by this profile and
   * process data according to the guidance implicit in the profile. See further
   * discussion in [Using Profiles](profiling.html#profile-uses).
   */
  supportedProfile?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** A type of resource exposed via the restful interface. */
  type: Scalars['code'];
  /**
   * A flag to indicate that the server allows or needs to allow the client to
   * create new identities on the server (that is, the client PUTs to a location
   * where there is no existing resource). Allowing this operation means that the
   * server allows the client to create new identities on the server.
   */
  updateCreate?: Maybe<Scalars['code']>;
  /**
   * This field is set to no-version to specify that the system does not support
   * (server) or use (client) versioning for this resource type. If this has some
   * other value, the server must at least correctly track and populate the
   * versionId meta-property on resources. If the value is 'versioned-update', then
   * the server supports all the versioning features, including using e-tags for
   * version integrity in the API.
   */
  versioning?: Maybe<Scalars['code']>;
};

/**
 * CapabilityStatement.Rest
 *     A Capability Statement documents a set of capabilities (behaviors) of a FHIR
 *     Server for a particular version of FHIR that may be used as a statement of
 *     actual server functionality or a statement of required or desired server
 *     implementation.
 */
export type CapabilityStatementRest = {
  __typename?: 'CapabilityStatementRest';
  /**
   * An absolute URI which is a reference to the definition of a compartment that
   * the system supports. The reference is to a CompartmentDefinition resource by
   * its canonical URL .
   */
  compartment?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Information about the system's restful capabilities that apply across all
   * applications, such as security.
   */
  documentation?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** A specification of restful operations supported by the system. */
  interaction?: Maybe<Array<Maybe<CapabilityStatementInteraction1>>>;
  /**
   * Identifies whether this portion of the statement is describing the ability to
   * initiate or receive restful operations.
   */
  mode: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Definition of an operation or a named query together with its parameters and
   * their meaning and type.
   */
  operation?: Maybe<Array<Maybe<CapabilityStatementOperation>>>;
  /**
   * A specification of the restful capabilities of the solution for a specific
   * resource type.
   */
  resource?: Maybe<Array<Maybe<CapabilityStatementResource>>>;
  /**
   * Search parameters that are supported for searching all resources for
   * implementations to support and/or make use of - either references to ones
   * defined in the specification, or additional ones defined for/by the
   * implementation.
   */
  searchParam?: Maybe<Array<Maybe<CapabilityStatementSearchParam>>>;
  /**
   * Information about security implementation from an interface perspective - what
   * a client needs to know.
   */
  security?: Maybe<CapabilityStatementSecurity>;
};

/**
 * CapabilityStatement.SearchParam
 *     A Capability Statement documents a set of capabilities (behaviors) of a FHIR
 *     Server for a particular version of FHIR that may be used as a statement of
 *     actual server functionality or a statement of required or desired server
 *     implementation.
 */
export type CapabilityStatementSearchParam = {
  __typename?: 'CapabilityStatementSearchParam';
  /**
   * An absolute URI that is a formal reference to where this parameter was first
   * defined, so that a client can be confident of the meaning of the search
   * parameter (a reference to [[[SearchParameter.url]]]). This element SHALL be
   * populated if the search parameter refers to a SearchParameter defined by the
   * FHIR core specification or externally defined IGs.
   */
  definition?: Maybe<Scalars['code']>;
  /**
   * This allows documentation of any distinct behaviors about how the search
   * parameter is used.  For example, text matching algorithms.
   */
  documentation?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The name of the search parameter used in the interface. */
  name: Scalars['code'];
  /**
   * The type of value a search parameter refers to, and how the content is
   * interpreted.
   */
  type: Scalars['code'];
};

/**
 * CapabilityStatement.Security
 *     A Capability Statement documents a set of capabilities (behaviors) of a FHIR
 *     Server for a particular version of FHIR that may be used as a statement of
 *     actual server functionality or a statement of required or desired server
 *     implementation.
 */
export type CapabilityStatementSecurity = {
  __typename?: 'CapabilityStatementSecurity';
  /**
   * Server adds CORS headers when responding to requests - this enables Javascript
   * applications to use the server.
   */
  cors?: Maybe<Scalars['code']>;
  /** General description of how security works. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Types of security services that are supported/required by the system. */
  service?: Maybe<Array<Maybe<CodeableConcept>>>;
};

/**
 * CapabilityStatement.Software
 *     A Capability Statement documents a set of capabilities (behaviors) of a FHIR
 *     Server for a particular version of FHIR that may be used as a statement of
 *     actual server functionality or a statement of required or desired server
 *     implementation.
 */
export type CapabilityStatementSoftware = {
  __typename?: 'CapabilityStatementSoftware';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Name the software is known by. */
  name: Scalars['code'];
  /** Date this version of the software was released. */
  releaseDate?: Maybe<Scalars['code']>;
  /** The version identifier for the software covered by this statement. */
  version?: Maybe<Scalars['code']>;
};

/**
 * CapabilityStatement.SupportedMessage
 *     A Capability Statement documents a set of capabilities (behaviors) of a FHIR
 *     Server for a particular version of FHIR that may be used as a statement of
 *     actual server functionality or a statement of required or desired server
 *     implementation.
 */
export type CapabilityStatementSupportedMessage = {
  __typename?: 'CapabilityStatementSupportedMessage';
  /**
   * Points to a message definition that identifies the messaging event, message
   * structure, allowed responses, etc.
   */
  definition: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The mode of this event declaration - whether application is sender or
   * receiver.
   */
  mode: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * CarePlan
 *     Describes the intention of how one or more practitioners intend to deliver
 *     care for a particular patient, group or community for a period of time,
 *     possibly limited to care for a specific condition or set of conditions.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type CarePlan = {
  __typename?: 'CarePlan';
  /**
   * Identifies a planned action to occur as part of the plan.  For example, a
   * medication to be used, lab tests to perform, self-monitoring, education, etc.
   */
  activity?: Maybe<Array<Maybe<CarePlanActivity>>>;
  /**
   * Identifies the conditions/problems/concerns/diagnoses/etc. whose management
   * and/or mitigation are handled by this plan.
   */
  addresses?: Maybe<Array<Maybe<Condition>>>;
  /**
   * When populated, the author is responsible for the care plan.  The care plan is
   * attributed to the author.
   */
  author?: Maybe<CarePlanAuthor>;
  /** A care plan that is fulfilled in whole or in part by this care plan. */
  basedOn?: Maybe<Array<Maybe<CarePlan>>>;
  /**
   * Identifies all people and organizations who are expected to be involved in the
   * care envisioned by this plan.
   */
  careTeam?: Maybe<Array<Maybe<CareTeam>>>;
  /**
   * Identifies what "kind" of plan this is to support differentiation between
   * multiple co-existing plans; e.g. "Home health", "psychiatric", "asthma",
   * "disease management", "wellness plan", etc.
   */
  category?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Identifies the individual(s) or organization who provided the contents of the
   * care plan.
   */
  contributor?: Maybe<Array<Maybe<CarePlanContributor>>>;
  /**
   * Represents when this particular CarePlan record was created in the system,
   * which is often a system-generated date.
   */
  created?: Maybe<Scalars['code']>;
  /** A description of the scope and nature of the plan. */
  description?: Maybe<Scalars['code']>;
  /**
   * The Encounter during which this CarePlan was created or to which the creation
   * of this record is tightly associated.
   */
  encounter?: Maybe<Encounter>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Describes the intended objective(s) of carrying out the care plan. */
  goal?: Maybe<Array<Maybe<Goal>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Business identifiers assigned to this care plan by the performer or other
   * systems which remain constant as the resource is updated and propagates from
   * server to server.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * The URL pointing to a FHIR-defined protocol, guideline, questionnaire or other
   * definition that is adhered to in whole or in part by this CarePlan.
   */
  instantiatesCanonical?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The URL pointing to an externally maintained protocol, guideline,
   * questionnaire or other definition that is adhered to in whole or in part by
   * this CarePlan.
   */
  instantiatesUri?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Indicates the level of authority/intentionality associated with the care plan
   * and where the care plan fits into the workflow chain.
   */
  intent: Scalars['code'];
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** General notes about the care plan not covered elsewhere. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** A larger care plan of which this particular care plan is a component or step. */
  partOf?: Maybe<Array<Maybe<CarePlan>>>;
  /** Indicates when the plan did (or is intended to) come into effect and end. */
  period?: Maybe<Period>;
  /**
   * Completed or terminated care plan whose function is taken by this new care
   * plan.
   */
  replaces?: Maybe<Array<Maybe<CarePlan>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * Indicates whether the plan is currently being acted upon, represents future
   * intentions or is now a historical record.
   */
  status: Scalars['code'];
  /** Identifies the patient or group whose intended care is described by the plan. */
  subject: CarePlanSubject;
  /**
   * Identifies portions of the patient's record that specifically influenced the
   * formation of the plan.  These might include comorbidities, recent procedures,
   * limitations, recent assessments, etc.
   */
  supportingInfo?: Maybe<Array<Maybe<Resource>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** Human-friendly name for the care plan. */
  title?: Maybe<Scalars['code']>;
};

/**
 * CarePlan.Activity
 *     Describes the intention of how one or more practitioners intend to deliver
 *     care for a particular patient, group or community for a period of time,
 *     possibly limited to care for a specific condition or set of conditions.
 */
export type CarePlanActivity = {
  __typename?: 'CarePlanActivity';
  /**
   * A simple summary of a planned activity suitable for a general care plan system
   * (e.g. form driven) that doesn't know about specific resources such as
   * procedure etc.
   */
  detail?: Maybe<CarePlanDetail>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Identifies the outcome at the point when the status of the activity is
   * assessed.  For example, the outcome of an education activity could be patient
   * understands (or not).
   */
  outcomeCodeableConcept?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Details of the outcome or action resulting from the activity.  The reference
   * to an "event" resource, such as Procedure or Encounter or Observation, is the
   * result/outcome of the activity itself.  The activity can be conveyed using
   * CarePlan.activity.detail OR using the CarePlan.activity.reference (a reference
   * to a “request” resource).
   */
  outcomeReference?: Maybe<Array<Maybe<Resource>>>;
  /** Notes about the adherence/status/progress of the activity. */
  progress?: Maybe<Array<Maybe<Annotation>>>;
  /** The details of the proposed activity represented in a specific resource. */
  reference?: Maybe<CarePlanActivityReference>;
};

export type CarePlanActivityReference = Appointment | CommunicationRequest | DeviceRequest | MedicationRequest | NutritionOrder | RequestGroup | ServiceRequest | Task | VisionPrescription;

export type CarePlanAuthor = CareTeam | Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type CarePlanContributor = CareTeam | Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * CarePlan.Detail
 *     Describes the intention of how one or more practitioners intend to deliver
 *     care for a particular patient, group or community for a period of time,
 *     possibly limited to care for a specific condition or set of conditions.
 */
export type CarePlanDetail = {
  __typename?: 'CarePlanDetail';
  /**
   * Detailed description of the type of planned activity; e.g. what lab test, what
   * procedure, what kind of encounter.
   */
  code?: Maybe<CodeableConcept>;
  /** Identifies the quantity expected to be consumed in a given day. */
  dailyAmount?: Maybe<Quantity>;
  /**
   * This provides a textual description of constraints on the intended activity
   * occurrence, including relation to other activities.  It may also include
   * objectives, pre-conditions and end-conditions.  Finally, it may convey
   * specifics about the activity such as body site, method, route, etc.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * If true, indicates that the described activity is one that must NOT be engaged
   * in when following the plan.  If false, or missing, indicates that the
   * described activity is one that should be engaged in when following the plan.
   */
  doNotPerform?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Internal reference that identifies the goals that this activity is intended to
   * contribute towards meeting.
   */
  goal?: Maybe<Array<Maybe<Goal>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The URL pointing to a FHIR-defined protocol, guideline, questionnaire or other
   * definition that is adhered to in whole or in part by this CarePlan activity.
   */
  instantiatesCanonical?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The URL pointing to an externally maintained protocol, guideline,
   * questionnaire or other definition that is adhered to in whole or in part by
   * this CarePlan activity.
   */
  instantiatesUri?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A description of the kind of resource the in-line definition of a care plan
   * activity is representing.  The CarePlan.activity.detail is an in-line
   * definition when a resource is not referenced using
   * CarePlan.activity.reference.  For example, a MedicationRequest, a
   * ServiceRequest, or a CommunicationRequest.
   */
  kind?: Maybe<Scalars['code']>;
  /**
   * Identifies the facility where the activity will occur; e.g. home, hospital,
   * specific clinic, etc.
   */
  location?: Maybe<Location>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Identifies who's expected to be involved in the activity. */
  performer?: Maybe<Array<Maybe<CarePlanDetailPerformer>>>;
  /** None */
  productCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  productReference?: Maybe<CarePlanDetailProductReference>;
  /**
   * Identifies the quantity expected to be supplied, administered or consumed by
   * the subject.
   */
  quantity?: Maybe<Quantity>;
  /**
   * Provides the rationale that drove the inclusion of this particular activity as
   * part of the plan or the reason why the activity was prohibited.
   */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Indicates another resource, such as the health condition(s), whose existence
   * justifies this request and drove the inclusion of this particular activity as
   * part of the plan.
   */
  reasonReference?: Maybe<Array<Maybe<CarePlanDetailReasonReference>>>;
  /** None */
  scheduledPeriod?: Maybe<Period>;
  /** None */
  scheduledString?: Maybe<Scalars['code']>;
  /** None */
  scheduledTiming?: Maybe<Timing>;
  /** Identifies what progress is being made for the specific activity. */
  status: Scalars['code'];
  /**
   * Provides reason why the activity isn't yet started, is on hold, was cancelled,
   * etc.
   */
  statusReason?: Maybe<CodeableConcept>;
};

export type CarePlanDetailPerformer = CareTeam | Device | HealthcareService | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type CarePlanDetailProductReference = Medication | Substance;

export type CarePlanDetailReasonReference = Condition | DiagnosticReport | DocumentReference | Observation;

export type CarePlanSubject = Group | Patient;

/**
 * CareTeam
 *     The Care Team includes all the people and organizations who plan to
 *     participate in the coordination and delivery of care for a patient.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type CareTeam = {
  __typename?: 'CareTeam';
  /**
   * Identifies what kind of team.  This is to support differentiation between
   * multiple co-existing teams, such as care plan team, episode of care team,
   * longitudinal care team.
   */
  category?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The Encounter during which this CareTeam was created or to which the creation
   * of this record is tightly associated.
   */
  encounter?: Maybe<Encounter>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Business identifiers assigned to this care team by the performer or other
   * systems which remain constant as the resource is updated and propagates from
   * server to server.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** The organization responsible for the care team. */
  managingOrganization?: Maybe<Array<Maybe<Organization>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A label for human use intended to distinguish like teams.  E.g. the "red" vs.
   * "green" trauma teams.
   */
  name?: Maybe<Scalars['code']>;
  /** Comments made about the CareTeam. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /**
   * Identifies all people and organizations who are expected to be involved in the
   * care team.
   */
  participant?: Maybe<Array<Maybe<CareTeamParticipant>>>;
  /** Indicates when the team did (or is intended to) come into effect and end. */
  period?: Maybe<Period>;
  /** Describes why the care team exists. */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Condition(s) that this care team addresses. */
  reasonReference?: Maybe<Array<Maybe<Condition>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** Indicates the current state of the care team. */
  status?: Maybe<Scalars['code']>;
  /** Identifies the patient or group whose intended care is handled by the team. */
  subject?: Maybe<CareTeamSubject>;
  /** A central contact detail for the care team (that applies to all members). */
  telecom?: Maybe<Array<Maybe<ContactPoint>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * CareTeam.Participant
 *     The Care Team includes all the people and organizations who plan to
 *     participate in the coordination and delivery of care for a patient.
 */
export type CareTeamParticipant = {
  __typename?: 'CareTeamParticipant';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The specific person or organization who is participating/expected to
   * participate in the care team.
   */
  member?: Maybe<CareTeamParticipantMember>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The organization of the practitioner. */
  onBehalfOf?: Maybe<Organization>;
  /**
   * Indicates when the specific member or organization did (or is intended to)
   * come into effect and end.
   */
  period?: Maybe<Period>;
  /**
   * Indicates specific responsibility of an individual within the care team, such
   * as "Primary care physician", "Trained social worker counselor", "Caregiver",
   * etc.
   */
  role?: Maybe<Array<Maybe<CodeableConcept>>>;
};

export type CareTeamParticipantMember = CareTeam | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type CareTeamSubject = Group | Patient;

/**
 * CatalogEntry
 *     Catalog entries are wrappers that contextualize items included in a catalog.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type CatalogEntry = {
  __typename?: 'CatalogEntry';
  /** Used for examplefor Out of Formulary, or any specifics. */
  additionalCharacteristic?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** User for example for ATC classification, or. */
  additionalClassification?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Used in supporting related concepts, e.g. NDC to RxNorm. */
  additionalIdentifier?: Maybe<Array<Maybe<Identifier>>>;
  /** Classes of devices, or ATC for medication. */
  classification?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Used in supporting different identifiers for the same product, e.g.
   * manufacturer code and retailer code.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * Typically date of issue is different from the beginning of the validity. This
   * can be used to see when an item was last updated.
   */
  lastUpdated?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Whether the entry represents an orderable item. */
  orderable: Scalars['code'];
  /** The item in a catalog or definition. */
  referencedItem: CatalogEntryReferencedItem;
  /**
   * Used for example, to point to a substance, or to a device used to administer a
   * medication.
   */
  relatedEntry?: Maybe<Array<Maybe<CatalogEntryRelatedEntry>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * Used to support catalog exchange even for unsupported products, e.g. getting
   * list of medications even if not prescribable.
   */
  status?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** The type of item - medication, device, service, protocol or other. */
  type?: Maybe<CodeableConcept>;
  /** The date until which this catalog entry is expected to be active. */
  validTo?: Maybe<Scalars['code']>;
  /** The time period in which this catalog entry is expected to be active. */
  validityPeriod?: Maybe<Period>;
};

export type CatalogEntryReferencedItem = ActivityDefinition | Binary | Device | HealthcareService | Medication | ObservationDefinition | Organization | PlanDefinition | Practitioner | PractitionerRole | SpecimenDefinition;

/**
 * CatalogEntry.RelatedEntry
 *     Catalog entries are wrappers that contextualize items included in a catalog.
 */
export type CatalogEntryRelatedEntry = {
  __typename?: 'CatalogEntryRelatedEntry';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The reference to the related item. */
  item: CatalogEntry;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The type of relation to the related item: child, parent, packageContent,
   * containerPackage, usedIn, uses, requires, etc.
   */
  relationtype: Scalars['code'];
};

/**
 * ChargeItem
 *     The resource ChargeItem describes the provision of healthcare provider
 *     products for a certain patient, therefore referring not only to the product,
 *     but containing in addition details of the provision, like date, time, amounts
 *     and participating organizations and persons. Main Usage of the ChargeItem is
 *     to enable the billing process and internal cost allocation.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ChargeItem = {
  __typename?: 'ChargeItem';
  /** Account into which this ChargeItems belongs. */
  account?: Maybe<Array<Maybe<Account>>>;
  /** The anatomical location where the related service has been applied. */
  bodysite?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** A code that identifies the charge, like a billing code. */
  code: CodeableConcept;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The encounter or episode of care that establishes the context for this event. */
  context?: Maybe<ChargeItemContext>;
  /** The financial cost center permits the tracking of charge attribution. */
  costCenter?: Maybe<Organization>;
  /**
   * References the source of pricing information, rules of application for the
   * code this ChargeItem uses.
   */
  definitionCanonical?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * References the (external) source of pricing information, rules of application
   * for the code this ChargeItem uses.
   */
  definitionUri?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Date the charge item was entered. */
  enteredDate?: Maybe<Scalars['code']>;
  /** The device, practitioner, etc. who entered the charge item. */
  enterer?: Maybe<ChargeItemEnterer>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Factor overriding the factor determined by the rules associated with the code. */
  factorOverride?: Maybe<Scalars['code']>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Identifiers assigned to this event performer or other systems. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Comments made about the event by the performer, subject or other participants. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** None */
  occurrenceDateTime?: Maybe<Scalars['code']>;
  /** None */
  occurrencePeriod?: Maybe<Period>;
  /** None */
  occurrenceTiming?: Maybe<Timing>;
  /**
   * If the list price or the rule-based factor associated with the code is
   * overridden, this attribute can capture a text to indicate the  reason for this
   * action.
   */
  overrideReason?: Maybe<Scalars['code']>;
  /** ChargeItems can be grouped to larger ChargeItems covering the whole set. */
  partOf?: Maybe<Array<Maybe<ChargeItem>>>;
  /** Indicates who or what performed or participated in the charged service. */
  performer?: Maybe<Array<Maybe<ChargeItemPerformer>>>;
  /** The organization requesting the service. */
  performingOrganization?: Maybe<Organization>;
  /** Total price of the charge overriding the list price associated with the code. */
  priceOverride?: Maybe<Money>;
  /** None */
  productCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  productReference?: Maybe<ChargeItemProductReference>;
  /** Quantity of which the charge item has been serviced. */
  quantity?: Maybe<Quantity>;
  /** Describes why the event occurred in coded or textual form. */
  reason?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The organization performing the service. */
  requestingOrganization?: Maybe<Organization>;
  resourceType?: Maybe<Scalars['String']>;
  /** Indicated the rendered service that caused this charge. */
  service?: Maybe<Array<Maybe<ChargeItemService>>>;
  /** The current state of the ChargeItem. */
  status: Scalars['code'];
  /** The individual or set of individuals the action is being or was performed on. */
  subject: ChargeItemSubject;
  /** Further information supporting this charge. */
  supportingInformation?: Maybe<Array<Maybe<Resource>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type ChargeItemContext = Encounter | EpisodeOfCare;

/**
 * ChargeItemDefinition
 *     The ChargeItemDefinition resource provides the properties that apply to the
 *     (billing) codes necessary to calculate costs and prices. The properties may
 *     differ largely depending on type and realm, therefore this resource gives only
 *     a rough structure and requires profiling for each type of billing code system.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ChargeItemDefinition = {
  __typename?: 'ChargeItemDefinition';
  /** Expressions that describe applicability criteria for the billing code. */
  applicability?: Maybe<Array<Maybe<ChargeItemDefinitionApplicability>>>;
  /**
   * The date on which the resource content was approved by the publisher. Approval
   * happens once when the content is officially approved for usage.
   */
  approvalDate?: Maybe<Scalars['code']>;
  /**
   * The defined billing details in this resource pertain to the given billing
   * code.
   */
  code?: Maybe<CodeableConcept>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the charge item definition and/or its
   * contents. Copyright statements are generally legal restrictions on the use and
   * publishing of the charge item definition.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the charge item definition was published.
   * The date must change when the business version changes and it must change if
   * the status code changes. In addition, it should change when the substantive
   * content of the charge item definition changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * The URL pointing to an externally-defined charge item definition that is
   * adhered to in whole or in part by this definition.
   */
  derivedFromUri?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A free text natural language description of the charge item definition from a
   * consumer's perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * The period during which the charge item definition content was or is planned
   * to be in active use.
   */
  effectivePeriod?: Maybe<Period>;
  /**
   * A Boolean value to indicate that this charge item definition is authored for
   * testing purposes (or education/evaluation/marketing) and is not intended to be
   * used for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this charge item definition when
   * it is represented in other formats, or referenced in a specification, model,
   * design or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * The defined billing details in this resource pertain to the given product
   * instance(s).
   */
  instance?: Maybe<Array<Maybe<ChargeItemDefinitionInstance>>>;
  /**
   * A legal or geographic region in which the charge item definition is intended
   * to be used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The date on which the resource content was last reviewed. Review happens
   * periodically after approval but does not change the original approval date.
   */
  lastReviewDate?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A larger definition of which this particular definition is a component or
   * step.
   */
  partOf?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Group of properties which are applicable under the same conditions. If no
   * applicability rules are established for the group, then all properties always
   * apply.
   */
  propertyGroup?: Maybe<Array<Maybe<ChargeItemDefinitionPropertyGroup>>>;
  /**
   * The name of the organization or individual that published the charge item
   * definition.
   */
  publisher?: Maybe<Scalars['code']>;
  /**
   * As new versions of a protocol or guideline are defined, allows identification
   * of what versions are replaced by a new instance.
   */
  replaces?: Maybe<Array<Maybe<Scalars['code']>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** The current state of the ChargeItemDefinition. */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the charge item definition. */
  title?: Maybe<Scalars['code']>;
  /**
   * An absolute URI that is used to identify this charge item definition when it
   * is referenced in a specification, model, design or an instance; also called
   * its canonical identifier. This SHOULD be globally unique and SHOULD be a
   * literal address at which at which an authoritative instance of this charge
   * item definition is (or will be) published. This URL can be the target of a
   * canonical reference. It SHALL remain the same when the charge item definition
   * is stored on different servers.
   */
  url: Scalars['code'];
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate charge item
   * definition instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the charge item
   * definition when it is referenced in a specification, model, design or
   * instance. This is an arbitrary value managed by the charge item definition
   * author and is not expected to be globally unique. For example, it might be a
   * timestamp (e.g. yyyymmdd) if a managed version is not available. There is also
   * no expectation that versions can be placed in a lexicographical sequence. To
   * provide a version consistent with the Decision Support Service specification,
   * use the format Major.Minor.Revision (e.g. 1.0.0). For more information on
   * versioning knowledge assets, refer to the Decision Support Service
   * specification. Note that a version is required for non-experimental active
   * assets.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * ChargeItemDefinition.Applicability
 *     The ChargeItemDefinition resource provides the properties that apply to the
 *     (billing) codes necessary to calculate costs and prices. The properties may
 *     differ largely depending on type and realm, therefore this resource gives only
 *     a rough structure and requires profiling for each type of billing code system.
 */
export type ChargeItemDefinitionApplicability = {
  __typename?: 'ChargeItemDefinitionApplicability';
  /**
   * A brief, natural language description of the condition that effectively
   * communicates the intended semantics.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * An expression that returns true or false, indicating whether the condition is
   * satisfied. When using FHIRPath expressions, the %context environment variable
   * must be replaced at runtime with the ChargeItem resource to which this
   * definition is applied.
   */
  expression?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The media type of the language for the expression, e.g. "text/cql" for
   * Clinical Query Language expressions or "text/fhirpath" for FHIRPath
   * expressions.
   */
  language?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type ChargeItemDefinitionInstance = Device | Medication | Substance;

/**
 * ChargeItemDefinition.PriceComponent
 *     The ChargeItemDefinition resource provides the properties that apply to the
 *     (billing) codes necessary to calculate costs and prices. The properties may
 *     differ largely depending on type and realm, therefore this resource gives only
 *     a rough structure and requires profiling for each type of billing code system.
 */
export type ChargeItemDefinitionPriceComponent = {
  __typename?: 'ChargeItemDefinitionPriceComponent';
  /** The amount calculated for this component. */
  amount?: Maybe<Money>;
  /**
   * A code that identifies the component. Codes may be used to differentiate
   * between kinds of taxes, surcharges, discounts etc.
   */
  code?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The factor that has been applied on the base price for calculating this
   * component.
   */
  factor?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** This code identifies the type of the component. */
  type: Scalars['code'];
};

/**
 * ChargeItemDefinition.PropertyGroup
 *     The ChargeItemDefinition resource provides the properties that apply to the
 *     (billing) codes necessary to calculate costs and prices. The properties may
 *     differ largely depending on type and realm, therefore this resource gives only
 *     a rough structure and requires profiling for each type of billing code system.
 */
export type ChargeItemDefinitionPropertyGroup = {
  __typename?: 'ChargeItemDefinitionPropertyGroup';
  /** Expressions that describe applicability criteria for the priceComponent. */
  applicability?: Maybe<Array<Maybe<ChargeItemDefinitionApplicability>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The price for a ChargeItem may be calculated as a base price with
   * surcharges/deductions that apply in certain conditions. A ChargeItemDefinition
   * resource that defines the prices, factors and conditions that apply to a
   * billing code is currently under development. The priceComponent element can be
   * used to offer transparency to the recipient of the Invoice of how the prices
   * have been calculated.
   */
  priceComponent?: Maybe<Array<Maybe<ChargeItemDefinitionPriceComponent>>>;
};

export type ChargeItemEnterer = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * ChargeItem.Performer
 *     The resource ChargeItem describes the provision of healthcare provider
 *     products for a certain patient, therefore referring not only to the product,
 *     but containing in addition details of the provision, like date, time, amounts
 *     and participating organizations and persons. Main Usage of the ChargeItem is
 *     to enable the billing process and internal cost allocation.
 */
export type ChargeItemPerformer = {
  __typename?: 'ChargeItemPerformer';
  /** The device, practitioner, etc. who performed or participated in the service. */
  actor: ChargeItemPerformerActor;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Describes the type of performance or participation(e.g. primary surgeon,
   * anesthesiologiest, etc.).
   */
  function?: Maybe<CodeableConcept>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type ChargeItemPerformerActor = CareTeam | Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type ChargeItemProductReference = Device | Medication | Substance;

export type ChargeItemService = DiagnosticReport | ImagingStudy | Immunization | MedicationAdministration | MedicationDispense | Observation | Procedure | SupplyDelivery;

export type ChargeItemSubject = Group | Patient;

/**
 * Claim
 *     A provider issued list of professional services and products which have been
 *     provided, or are to be provided, to a patient which is sent to an insurer for
 *     reimbursement.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Claim = {
  __typename?: 'Claim';
  /**
   * Details of an accident which resulted in injuries which required the products
   * and services listed in the claim.
   */
  accident?: Maybe<ClaimAccident>;
  /** The period for which charges are being submitted. */
  billablePeriod?: Maybe<Period>;
  /** The members of the team who provided the products and services. */
  careTeam?: Maybe<Array<Maybe<ClaimCareTeam>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The date this resource was created. */
  created: Scalars['code'];
  /** Information about diagnoses relevant to the claim items. */
  diagnosis?: Maybe<Array<Maybe<ClaimDiagnosis>>>;
  /** Individual who created the claim, predetermination or preauthorization. */
  enterer?: Maybe<ClaimEnterer>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Facility where the services were provided. */
  facility?: Maybe<Location>;
  /**
   * A code to indicate whether and for whom funds are to be reserved for future
   * claims.
   */
  fundsReserve?: Maybe<CodeableConcept>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** A unique identifier assigned to this claim. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * Financial instruments for reimbursement for the health care products and
   * services specified on the claim.
   */
  insurance: Array<Maybe<ClaimInsurance>>;
  /** The Insurer who is target of the request. */
  insurer?: Maybe<Organization>;
  /**
   * A claim line. Either a simple  product or service or a 'group' of details
   * which can each be a simple items or groups of sub-details.
   */
  item?: Maybe<Array<Maybe<ClaimItem>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Original prescription which has been superseded by this prescription to
   * support the dispensing of pharmacy services, medications or products.
   */
  originalPrescription?: Maybe<ClaimOriginalPrescription>;
  /**
   * The party to whom the professional services and/or products have been supplied
   * or are being considered and for whom actual or forecast reimbursement is
   * sought.
   */
  patient: Patient;
  /**
   * The party to be reimbursed for cost of the products and services according to
   * the terms of the policy.
   */
  payee?: Maybe<ClaimPayee>;
  /** Prescription to support the dispensing of pharmacy, device or vision products. */
  prescription?: Maybe<ClaimPrescription>;
  /**
   * The provider-required urgency of processing the request. Typical values
   * include: stat, routine deferred.
   */
  priority: CodeableConcept;
  /**
   * Procedures performed on the patient relevant to the billing items with the
   * claim.
   */
  procedure?: Maybe<Array<Maybe<ClaimProcedure>>>;
  /**
   * The provider which is responsible for the claim, predetermination or
   * preauthorization.
   */
  provider: ClaimProvider;
  /** A reference to a referral resource. */
  referral?: Maybe<ServiceRequest>;
  /**
   * Other claims which are related to this claim such as prior submissions or
   * claims for related services or for the same event.
   */
  related?: Maybe<Array<Maybe<ClaimRelated>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** The status of the resource instance. */
  status: Scalars['code'];
  /**
   * A finer grained suite of claim type codes which may convey additional
   * information such as Inpatient vs Outpatient and/or a specialty service.
   */
  subType?: Maybe<CodeableConcept>;
  /**
   * Additional information codes regarding exceptions, special considerations, the
   * condition, situation, prior or concurrent issues.
   */
  supportingInfo?: Maybe<Array<Maybe<ClaimSupportingInfo>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** The total value of the all the items in the claim. */
  total?: Maybe<Money>;
  /**
   * The category of claim, e.g. oral, pharmacy, vision, institutional,
   * professional.
   */
  type: CodeableConcept;
  /**
   * A code to indicate whether the nature of the request is: to request
   * adjudication of products and services previously rendered; or requesting
   * authorization and adjudication for provision in the future; or requesting the
   * non-binding adjudication of the listed products and services which could be
   * provided in the future.
   */
  use: Scalars['code'];
};

/**
 * Claim.Accident
 *     A provider issued list of professional services and products which have been
 *     provided, or are to be provided, to a patient which is sent to an insurer for
 *     reimbursement.
 */
export type ClaimAccident = {
  __typename?: 'ClaimAccident';
  /**
   * Date of an accident event  related to the products and services contained in
   * the claim.
   */
  date: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** None */
  locationAddress?: Maybe<Address>;
  /** None */
  locationReference?: Maybe<Location>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The type or context of the accident event for the purposes of selection of
   * potential insurance coverages and determination of coordination between
   * insurers.
   */
  type?: Maybe<CodeableConcept>;
};

/**
 * Claim.CareTeam
 *     A provider issued list of professional services and products which have been
 *     provided, or are to be provided, to a patient which is sent to an insurer for
 *     reimbursement.
 */
export type ClaimCareTeam = {
  __typename?: 'ClaimCareTeam';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Member of the team who provided the product or service. */
  provider: ClaimCareTeamProvider;
  /** The qualification of the practitioner which is applicable for this service. */
  qualification?: Maybe<CodeableConcept>;
  /**
   * The party who is billing and/or responsible for the claimed products or
   * services.
   */
  responsible?: Maybe<Scalars['code']>;
  /**
   * The lead, assisting or supervising practitioner and their discipline if a
   * multidisciplinary team.
   */
  role?: Maybe<CodeableConcept>;
  /** A number to uniquely identify care team entries. */
  sequence: Scalars['code'];
};

export type ClaimCareTeamProvider = Organization | Practitioner | PractitionerRole;

/**
 * Claim.Detail
 *     A provider issued list of professional services and products which have been
 *     provided, or are to be provided, to a patient which is sent to an insurer for
 *     reimbursement.
 */
export type ClaimDetail = {
  __typename?: 'ClaimDetail';
  /**
   * Code to identify the general type of benefits under which products and
   * services are provided.
   */
  category?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A real number that represents a multiplier used in determining the overall
   * value of services delivered and/or goods received. The concept of a Factor
   * allows for a discount or surcharge multiplier to be applied to a monetary
   * amount.
   */
  factor?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Item typification or modifiers codes to convey additional context for the
   * product or service.
   */
  modifier?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The quantity times the unit price for an additional service or product or
   * charge.
   */
  net?: Maybe<Money>;
  /**
   * When the value is a group code then this item collects a set of related claim
   * details, otherwise this contains the product, service, drug or other billing
   * code for the item.
   */
  productOrService: CodeableConcept;
  /** Identifies the program under which this may be recovered. */
  programCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The number of repetitions of a service or product. */
  quantity?: Maybe<Quantity>;
  /** The type of revenue or cost center providing the product and/or service. */
  revenue?: Maybe<CodeableConcept>;
  /** A number to uniquely identify item entries. */
  sequence: Scalars['code'];
  /**
   * A claim detail line. Either a simple (a product or service) or a 'group' of
   * sub-details which are simple items.
   */
  subDetail?: Maybe<Array<Maybe<ClaimSubDetail>>>;
  /** Unique Device Identifiers associated with this line item. */
  udi?: Maybe<Array<Maybe<Device>>>;
  /**
   * If the item is not a group then this is the fee for the product or service,
   * otherwise this is the total of the fees for the details of the group.
   */
  unitPrice?: Maybe<Money>;
};

/**
 * Claim.Diagnosis
 *     A provider issued list of professional services and products which have been
 *     provided, or are to be provided, to a patient which is sent to an insurer for
 *     reimbursement.
 */
export type ClaimDiagnosis = {
  __typename?: 'ClaimDiagnosis';
  /** None */
  diagnosisCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  diagnosisReference?: Maybe<Condition>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Indication of whether the diagnosis was present on admission to a facility. */
  onAdmission?: Maybe<CodeableConcept>;
  /**
   * A package billing code or bundle code used to group products and services to a
   * particular health condition (such as heart attack) which is based on a
   * predetermined grouping code system.
   */
  packageCode?: Maybe<CodeableConcept>;
  /** A number to uniquely identify diagnosis entries. */
  sequence: Scalars['code'];
  /** When the condition was observed or the relative ranking. */
  type?: Maybe<Array<Maybe<CodeableConcept>>>;
};

export type ClaimEnterer = Practitioner | PractitionerRole;

/**
 * Claim.Insurance
 *     A provider issued list of professional services and products which have been
 *     provided, or are to be provided, to a patient which is sent to an insurer for
 *     reimbursement.
 */
export type ClaimInsurance = {
  __typename?: 'ClaimInsurance';
  /**
   * A business agreement number established between the provider and the insurer
   * for special business processing purposes.
   */
  businessArrangement?: Maybe<Scalars['code']>;
  /**
   * The result of the adjudication of the line items for the Coverage specified in
   * this insurance.
   */
  claimResponse?: Maybe<ClaimResponse>;
  /**
   * Reference to the insurance card level information contained in the Coverage
   * resource. The coverage issuing insurer will use these details to locate the
   * patient's actual coverage within the insurer's information system.
   */
  coverage: Coverage;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A flag to indicate that this Coverage is to be used for adjudication of this
   * claim when set to true.
   */
  focal: Scalars['code'];
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The business identifier to be used when the claim is sent for adjudication
   * against this insurance policy.
   */
  identifier?: Maybe<Identifier>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Reference numbers previously provided by the insurer to the provider to be
   * quoted on subsequent claims containing services or products related to the
   * prior authorization.
   */
  preAuthRef?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A number to uniquely identify insurance entries and provide a sequence of
   * coverages to convey coordination of benefit order.
   */
  sequence: Scalars['code'];
};

/**
 * Claim.Item
 *     A provider issued list of professional services and products which have been
 *     provided, or are to be provided, to a patient which is sent to an insurer for
 *     reimbursement.
 */
export type ClaimItem = {
  __typename?: 'ClaimItem';
  /** Physical service site on the patient (limb, tooth, etc.). */
  bodySite?: Maybe<CodeableConcept>;
  /** CareTeam members related to this service or product. */
  careTeamSequence?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Code to identify the general type of benefits under which products and
   * services are provided.
   */
  category?: Maybe<CodeableConcept>;
  /**
   * A claim detail line. Either a simple (a product or service) or a 'group' of
   * sub-details which are simple items.
   */
  detail?: Maybe<Array<Maybe<ClaimDetail>>>;
  /** Diagnosis applicable for this service or product. */
  diagnosisSequence?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The Encounters during which this Claim was created or to which the creation of
   * this record is tightly associated.
   */
  encounter?: Maybe<Array<Maybe<Encounter>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A real number that represents a multiplier used in determining the overall
   * value of services delivered and/or goods received. The concept of a Factor
   * allows for a discount or surcharge multiplier to be applied to a monetary
   * amount.
   */
  factor?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Exceptions, special conditions and supporting information applicable for this
   * service or product.
   */
  informationSequence?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** None */
  locationAddress?: Maybe<Address>;
  /** None */
  locationCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  locationReference?: Maybe<Location>;
  /**
   * Item typification or modifiers codes to convey additional context for the
   * product or service.
   */
  modifier?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The quantity times the unit price for an additional service or product or
   * charge.
   */
  net?: Maybe<Money>;
  /** Procedures applicable for this service or product. */
  procedureSequence?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * When the value is a group code then this item collects a set of related claim
   * details, otherwise this contains the product, service, drug or other billing
   * code for the item.
   */
  productOrService: CodeableConcept;
  /** Identifies the program under which this may be recovered. */
  programCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The number of repetitions of a service or product. */
  quantity?: Maybe<Quantity>;
  /** The type of revenue or cost center providing the product and/or service. */
  revenue?: Maybe<CodeableConcept>;
  /** A number to uniquely identify item entries. */
  sequence: Scalars['code'];
  /** None */
  servicedDate?: Maybe<Scalars['code']>;
  /** None */
  servicedPeriod?: Maybe<Period>;
  /** A region or surface of the bodySite, e.g. limb region or tooth surface(s). */
  subSite?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Unique Device Identifiers associated with this line item. */
  udi?: Maybe<Array<Maybe<Device>>>;
  /**
   * If the item is not a group then this is the fee for the product or service,
   * otherwise this is the total of the fees for the details of the group.
   */
  unitPrice?: Maybe<Money>;
};

export type ClaimOriginalPrescription = DeviceRequest | MedicationRequest | VisionPrescription;

/**
 * Claim.Payee
 *     A provider issued list of professional services and products which have been
 *     provided, or are to be provided, to a patient which is sent to an insurer for
 *     reimbursement.
 */
export type ClaimPayee = {
  __typename?: 'ClaimPayee';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Reference to the individual or organization to whom any payment will be made. */
  party?: Maybe<ClaimPayeeParty>;
  /** Type of Party to be reimbursed: subscriber, provider, other. */
  type: CodeableConcept;
};

export type ClaimPayeeParty = Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type ClaimPrescription = DeviceRequest | MedicationRequest | VisionPrescription;

/**
 * Claim.Procedure
 *     A provider issued list of professional services and products which have been
 *     provided, or are to be provided, to a patient which is sent to an insurer for
 *     reimbursement.
 */
export type ClaimProcedure = {
  __typename?: 'ClaimProcedure';
  /** Date and optionally time the procedure was performed. */
  date?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  procedureCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  procedureReference?: Maybe<Procedure>;
  /** A number to uniquely identify procedure entries. */
  sequence: Scalars['code'];
  /** When the condition was observed or the relative ranking. */
  type?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Unique Device Identifiers associated with this line item. */
  udi?: Maybe<Array<Maybe<Device>>>;
};

export type ClaimProvider = Organization | Practitioner | PractitionerRole;

/**
 * Claim.Related
 *     A provider issued list of professional services and products which have been
 *     provided, or are to be provided, to a patient which is sent to an insurer for
 *     reimbursement.
 */
export type ClaimRelated = {
  __typename?: 'ClaimRelated';
  /** Reference to a related claim. */
  claim?: Maybe<Claim>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * An alternate organizational reference to the case or file to which this
   * particular claim pertains.
   */
  reference?: Maybe<Identifier>;
  /** A code to convey how the claims are related. */
  relationship?: Maybe<CodeableConcept>;
};

/**
 * ClaimResponse
 *     This resource provides the adjudication details from the processing of a Claim
 *     resource.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ClaimResponse = {
  __typename?: 'ClaimResponse';
  /** The first-tier service adjudications for payor added product or service lines. */
  addItem?: Maybe<Array<Maybe<ClaimResponseAddItem>>>;
  /**
   * The adjudication results which are presented at the header level rather than
   * at the line-item or add-item levels.
   */
  adjudication?: Maybe<Array<Maybe<ClaimResponseAdjudication>>>;
  /** Request for additional supporting or authorizing information. */
  communicationRequest?: Maybe<Array<Maybe<CommunicationRequest>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The date this resource was created. */
  created: Scalars['code'];
  /** A human readable description of the status of the adjudication. */
  disposition?: Maybe<Scalars['code']>;
  /** Errors encountered during the processing of the adjudication. */
  error?: Maybe<Array<Maybe<ClaimResponseError>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The actual form, by reference or inclusion, for printing the content or an
   * EOB.
   */
  form?: Maybe<Attachment>;
  /** A code for the form to be used for printing the content. */
  formCode?: Maybe<CodeableConcept>;
  /**
   * A code, used only on a response to a preauthorization, to indicate whether the
   * benefits payable have been reserved and for whom.
   */
  fundsReserve?: Maybe<CodeableConcept>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** A unique identifier assigned to this claim response. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * Financial instruments for reimbursement for the health care products and
   * services specified on the claim.
   */
  insurance?: Maybe<Array<Maybe<ClaimResponseInsurance>>>;
  /** The party responsible for authorization, adjudication and reimbursement. */
  insurer: Organization;
  /**
   * A claim line. Either a simple (a product or service) or a 'group' of details
   * which can also be a simple items or groups of sub-details.
   */
  item?: Maybe<Array<Maybe<ClaimResponseItem>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The outcome of the claim, predetermination, or preauthorization processing. */
  outcome: Scalars['code'];
  /**
   * The party to whom the professional services and/or products have been supplied
   * or are being considered and for whom actual for facast reimbursement is
   * sought.
   */
  patient: Patient;
  /** Type of Party to be reimbursed: subscriber, provider, other. */
  payeeType?: Maybe<CodeableConcept>;
  /** Payment details for the adjudication of the claim. */
  payment?: Maybe<ClaimResponsePayment>;
  /** The time frame during which this authorization is effective. */
  preAuthPeriod?: Maybe<Period>;
  /**
   * Reference from the Insurer which is used in later communications which refers
   * to this adjudication.
   */
  preAuthRef?: Maybe<Scalars['code']>;
  /**
   * A note that describes or explains adjudication results in a human readable
   * form.
   */
  processNote?: Maybe<Array<Maybe<ClaimResponseProcessNote>>>;
  /** Original request resource reference. */
  request?: Maybe<Claim>;
  /**
   * The provider which is responsible for the claim, predetermination or
   * preauthorization.
   */
  requestor?: Maybe<ClaimResponseRequestor>;
  resourceType?: Maybe<Scalars['String']>;
  /** The status of the resource instance. */
  status: Scalars['code'];
  /**
   * A finer grained suite of claim type codes which may convey additional
   * information such as Inpatient vs Outpatient and/or a specialty service.
   */
  subType?: Maybe<CodeableConcept>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** Categorized monetary totals for the adjudication. */
  total?: Maybe<Array<Maybe<ClaimResponseTotal>>>;
  /**
   * A finer grained suite of claim type codes which may convey additional
   * information such as Inpatient vs Outpatient and/or a specialty service.
   */
  type: CodeableConcept;
  /**
   * A code to indicate whether the nature of the request is: to request
   * adjudication of products and services previously rendered; or requesting
   * authorization and adjudication for provision in the future; or requesting the
   * non-binding adjudication of the listed products and services which could be
   * provided in the future.
   */
  use: Scalars['code'];
};

/**
 * ClaimResponse.AddItem
 *     This resource provides the adjudication details from the processing of a Claim
 *     resource.
 */
export type ClaimResponseAddItem = {
  __typename?: 'ClaimResponseAddItem';
  /** The adjudication results. */
  adjudication: Array<Maybe<ClaimResponseAdjudication>>;
  /** Physical service site on the patient (limb, tooth, etc.). */
  bodySite?: Maybe<CodeableConcept>;
  /** The second-tier service adjudications for payor added services. */
  detail?: Maybe<Array<Maybe<ClaimResponseDetail1>>>;
  /**
   * The sequence number of the details within the claim item which this line is
   * intended to replace.
   */
  detailSequence?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A real number that represents a multiplier used in determining the overall
   * value of services delivered and/or goods received. The concept of a Factor
   * allows for a discount or surcharge multiplier to be applied to a monetary
   * amount.
   */
  factor?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Claim items which this service line is intended to replace. */
  itemSequence?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** None */
  locationAddress?: Maybe<Address>;
  /** None */
  locationCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  locationReference?: Maybe<Location>;
  /**
   * Item typification or modifiers codes to convey additional context for the
   * product or service.
   */
  modifier?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The quantity times the unit price for an additional service or product or
   * charge.
   */
  net?: Maybe<Money>;
  /**
   * The numbers associated with notes below which apply to the adjudication of
   * this item.
   */
  noteNumber?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * When the value is a group code then this item collects a set of related claim
   * details, otherwise this contains the product, service, drug or other billing
   * code for the item.
   */
  productOrService: CodeableConcept;
  /** Identifies the program under which this may be recovered. */
  programCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The providers who are authorized for the services rendered to the patient. */
  provider?: Maybe<Array<Maybe<ClaimResponseAddItemProvider>>>;
  /** The number of repetitions of a service or product. */
  quantity?: Maybe<Quantity>;
  /** None */
  servicedDate?: Maybe<Scalars['code']>;
  /** None */
  servicedPeriod?: Maybe<Period>;
  /** A region or surface of the bodySite, e.g. limb region or tooth surface(s). */
  subSite?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The sequence number of the sub-details within the details within the claim
   * item which this line is intended to replace.
   */
  subdetailSequence?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * If the item is not a group then this is the fee for the product or service,
   * otherwise this is the total of the fees for the details of the group.
   */
  unitPrice?: Maybe<Money>;
};

export type ClaimResponseAddItemProvider = Organization | Practitioner | PractitionerRole;

/**
 * ClaimResponse.Adjudication
 *     This resource provides the adjudication details from the processing of a Claim
 *     resource.
 */
export type ClaimResponseAdjudication = {
  __typename?: 'ClaimResponseAdjudication';
  /** Monetary amount associated with the category. */
  amount?: Maybe<Money>;
  /**
   * A code to indicate the information type of this adjudication record.
   * Information types may include the value submitted, maximum values or
   * percentages allowed or payable under the plan, amounts that: the patient is
   * responsible for in aggregate or pertaining to this item; amounts paid by other
   * coverages; and, the benefit payable for this item.
   */
  category: CodeableConcept;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A code supporting the understanding of the adjudication result and explaining
   * variance from expected amount.
   */
  reason?: Maybe<CodeableConcept>;
  /**
   * A non-monetary value associated with the category. Mutually exclusive to the
   * amount element above.
   */
  value?: Maybe<Scalars['code']>;
};

/**
 * ClaimResponse.Detail
 *     This resource provides the adjudication details from the processing of a Claim
 *     resource.
 */
export type ClaimResponseDetail = {
  __typename?: 'ClaimResponseDetail';
  /** The adjudication results. */
  adjudication: Array<Maybe<ClaimResponseAdjudication>>;
  /** A number to uniquely reference the claim detail entry. */
  detailSequence: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The numbers associated with notes below which apply to the adjudication of
   * this item.
   */
  noteNumber?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** A sub-detail adjudication of a simple product or service. */
  subDetail?: Maybe<Array<Maybe<ClaimResponseSubDetail>>>;
};

/**
 * ClaimResponse.Detail1
 *     This resource provides the adjudication details from the processing of a Claim
 *     resource.
 */
export type ClaimResponseDetail1 = {
  __typename?: 'ClaimResponseDetail1';
  /** The adjudication results. */
  adjudication: Array<Maybe<ClaimResponseAdjudication>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A real number that represents a multiplier used in determining the overall
   * value of services delivered and/or goods received. The concept of a Factor
   * allows for a discount or surcharge multiplier to be applied to a monetary
   * amount.
   */
  factor?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Item typification or modifiers codes to convey additional context for the
   * product or service.
   */
  modifier?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The quantity times the unit price for an additional service or product or
   * charge.
   */
  net?: Maybe<Money>;
  /**
   * The numbers associated with notes below which apply to the adjudication of
   * this item.
   */
  noteNumber?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * When the value is a group code then this item collects a set of related claim
   * details, otherwise this contains the product, service, drug or other billing
   * code for the item.
   */
  productOrService: CodeableConcept;
  /** The number of repetitions of a service or product. */
  quantity?: Maybe<Quantity>;
  /** The third-tier service adjudications for payor added services. */
  subDetail?: Maybe<Array<Maybe<ClaimResponseSubDetail1>>>;
  /**
   * If the item is not a group then this is the fee for the product or service,
   * otherwise this is the total of the fees for the details of the group.
   */
  unitPrice?: Maybe<Money>;
};

/**
 * ClaimResponse.Error
 *     This resource provides the adjudication details from the processing of a Claim
 *     resource.
 */
export type ClaimResponseError = {
  __typename?: 'ClaimResponseError';
  /**
   * An error code, from a specified code system, which details why the claim could
   * not be adjudicated.
   */
  code: CodeableConcept;
  /**
   * The sequence number of the detail within the line item submitted which
   * contains the error. This value is omitted when the error occurs outside of the
   * item structure.
   */
  detailSequence?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The sequence number of the line item submitted which contains the error. This
   * value is omitted when the error occurs outside of the item structure.
   */
  itemSequence?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The sequence number of the sub-detail within the detail within the line item
   * submitted which contains the error. This value is omitted when the error
   * occurs outside of the item structure.
   */
  subDetailSequence?: Maybe<Scalars['code']>;
};

/**
 * ClaimResponse.Insurance
 *     This resource provides the adjudication details from the processing of a Claim
 *     resource.
 */
export type ClaimResponseInsurance = {
  __typename?: 'ClaimResponseInsurance';
  /**
   * A business agreement number established between the provider and the insurer
   * for special business processing purposes.
   */
  businessArrangement?: Maybe<Scalars['code']>;
  /**
   * The result of the adjudication of the line items for the Coverage specified in
   * this insurance.
   */
  claimResponse?: Maybe<ClaimResponse>;
  /**
   * Reference to the insurance card level information contained in the Coverage
   * resource. The coverage issuing insurer will use these details to locate the
   * patient's actual coverage within the insurer's information system.
   */
  coverage: Coverage;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A flag to indicate that this Coverage is to be used for adjudication of this
   * claim when set to true.
   */
  focal: Scalars['code'];
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A number to uniquely identify insurance entries and provide a sequence of
   * coverages to convey coordination of benefit order.
   */
  sequence: Scalars['code'];
};

/**
 * ClaimResponse.Item
 *     This resource provides the adjudication details from the processing of a Claim
 *     resource.
 */
export type ClaimResponseItem = {
  __typename?: 'ClaimResponseItem';
  /**
   * If this item is a group then the values here are a summary of the adjudication
   * of the detail items. If this item is a simple product or service then this is
   * the result of the adjudication of this item.
   */
  adjudication: Array<Maybe<ClaimResponseAdjudication>>;
  /**
   * A claim detail. Either a simple (a product or service) or a 'group' of sub-
   * details which are simple items.
   */
  detail?: Maybe<Array<Maybe<ClaimResponseDetail>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** A number to uniquely reference the claim item entries. */
  itemSequence: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The numbers associated with notes below which apply to the adjudication of
   * this item.
   */
  noteNumber?: Maybe<Array<Maybe<Scalars['code']>>>;
};

/**
 * ClaimResponse.Payment
 *     This resource provides the adjudication details from the processing of a Claim
 *     resource.
 */
export type ClaimResponsePayment = {
  __typename?: 'ClaimResponsePayment';
  /**
   * Total amount of all adjustments to this payment included in this transaction
   * which are not related to this claim's adjudication.
   */
  adjustment?: Maybe<Money>;
  /** Reason for the payment adjustment. */
  adjustmentReason?: Maybe<CodeableConcept>;
  /** Benefits payable less any payment adjustment. */
  amount: Money;
  /** Estimated date the payment will be issued or the actual issue date of payment. */
  date?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Issuer's unique identifier for the payment instrument. */
  identifier?: Maybe<Identifier>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Whether this represents partial or complete payment of the benefits payable. */
  type: CodeableConcept;
};

/**
 * ClaimResponse.ProcessNote
 *     This resource provides the adjudication details from the processing of a Claim
 *     resource.
 */
export type ClaimResponseProcessNote = {
  __typename?: 'ClaimResponseProcessNote';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** A code to define the language used in the text of the note. */
  language?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A number to uniquely identify a note entry. */
  number?: Maybe<Scalars['code']>;
  /** The explanation or description associated with the processing. */
  text: Scalars['code'];
  /** The business purpose of the note text. */
  type?: Maybe<Scalars['code']>;
};

export type ClaimResponseRequestor = Organization | Practitioner | PractitionerRole;

/**
 * ClaimResponse.SubDetail
 *     This resource provides the adjudication details from the processing of a Claim
 *     resource.
 */
export type ClaimResponseSubDetail = {
  __typename?: 'ClaimResponseSubDetail';
  /** The adjudication results. */
  adjudication?: Maybe<Array<Maybe<ClaimResponseAdjudication>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The numbers associated with notes below which apply to the adjudication of
   * this item.
   */
  noteNumber?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** A number to uniquely reference the claim sub-detail entry. */
  subDetailSequence: Scalars['code'];
};

/**
 * ClaimResponse.SubDetail1
 *     This resource provides the adjudication details from the processing of a Claim
 *     resource.
 */
export type ClaimResponseSubDetail1 = {
  __typename?: 'ClaimResponseSubDetail1';
  /** The adjudication results. */
  adjudication: Array<Maybe<ClaimResponseAdjudication>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A real number that represents a multiplier used in determining the overall
   * value of services delivered and/or goods received. The concept of a Factor
   * allows for a discount or surcharge multiplier to be applied to a monetary
   * amount.
   */
  factor?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Item typification or modifiers codes to convey additional context for the
   * product or service.
   */
  modifier?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The quantity times the unit price for an additional service or product or
   * charge.
   */
  net?: Maybe<Money>;
  /**
   * The numbers associated with notes below which apply to the adjudication of
   * this item.
   */
  noteNumber?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * When the value is a group code then this item collects a set of related claim
   * details, otherwise this contains the product, service, drug or other billing
   * code for the item.
   */
  productOrService: CodeableConcept;
  /** The number of repetitions of a service or product. */
  quantity?: Maybe<Quantity>;
  /**
   * If the item is not a group then this is the fee for the product or service,
   * otherwise this is the total of the fees for the details of the group.
   */
  unitPrice?: Maybe<Money>;
};

/**
 * ClaimResponse.Total
 *     This resource provides the adjudication details from the processing of a Claim
 *     resource.
 */
export type ClaimResponseTotal = {
  __typename?: 'ClaimResponseTotal';
  /** Monetary total amount associated with the category. */
  amount: Money;
  /**
   * A code to indicate the information type of this adjudication record.
   * Information types may include: the value submitted, maximum values or
   * percentages allowed or payable under the plan, amounts that the patient is
   * responsible for in aggregate or pertaining to this item, amounts paid by other
   * coverages, and the benefit payable for this item.
   */
  category: CodeableConcept;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * Claim.SubDetail
 *     A provider issued list of professional services and products which have been
 *     provided, or are to be provided, to a patient which is sent to an insurer for
 *     reimbursement.
 */
export type ClaimSubDetail = {
  __typename?: 'ClaimSubDetail';
  /**
   * Code to identify the general type of benefits under which products and
   * services are provided.
   */
  category?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A real number that represents a multiplier used in determining the overall
   * value of services delivered and/or goods received. The concept of a Factor
   * allows for a discount or surcharge multiplier to be applied to a monetary
   * amount.
   */
  factor?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Item typification or modifiers codes to convey additional context for the
   * product or service.
   */
  modifier?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The quantity times the unit price for an additional service or product or
   * charge.
   */
  net?: Maybe<Money>;
  /**
   * When the value is a group code then this item collects a set of related claim
   * details, otherwise this contains the product, service, drug or other billing
   * code for the item.
   */
  productOrService: CodeableConcept;
  /** Identifies the program under which this may be recovered. */
  programCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The number of repetitions of a service or product. */
  quantity?: Maybe<Quantity>;
  /** The type of revenue or cost center providing the product and/or service. */
  revenue?: Maybe<CodeableConcept>;
  /** A number to uniquely identify item entries. */
  sequence: Scalars['code'];
  /** Unique Device Identifiers associated with this line item. */
  udi?: Maybe<Array<Maybe<Device>>>;
  /**
   * If the item is not a group then this is the fee for the product or service,
   * otherwise this is the total of the fees for the details of the group.
   */
  unitPrice?: Maybe<Money>;
};

/**
 * Claim.SupportingInfo
 *     A provider issued list of professional services and products which have been
 *     provided, or are to be provided, to a patient which is sent to an insurer for
 *     reimbursement.
 */
export type ClaimSupportingInfo = {
  __typename?: 'ClaimSupportingInfo';
  /**
   * The general class of the information supplied: information; exception;
   * accident, employment; onset, etc.
   */
  category: CodeableConcept;
  /**
   * System and code pertaining to the specific information regarding special
   * conditions relating to the setting, treatment or patient  for which care is
   * sought.
   */
  code?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Provides the reason in the situation where a reason code is required in
   * addition to the content.
   */
  reason?: Maybe<CodeableConcept>;
  /** A number to uniquely identify supporting information entries. */
  sequence: Scalars['code'];
  /** None */
  timingDate?: Maybe<Scalars['code']>;
  /** None */
  timingPeriod?: Maybe<Period>;
  /** None */
  valueAttachment?: Maybe<Attachment>;
  /** None */
  valueBoolean?: Maybe<Scalars['code']>;
  /** None */
  valueQuantity?: Maybe<Quantity>;
  /** None */
  valueReference?: Maybe<Resource>;
  /** None */
  valueString?: Maybe<Scalars['code']>;
};

/**
 * ClinicalImpression
 *     A record of a clinical assessment performed to determine what problem(s) may
 *     affect the patient and before planning the treatments or management strategies
 *     that are best to manage a patient's condition. Assessments are often 1:1 with
 *     a clinical consultation / encounter,  but this varies greatly depending on the
 *     clinical workflow. This resource is called "ClinicalImpression" rather than
 *     "ClinicalAssessment" to avoid confusion with the recording of assessment tools
 *     such as Apgar score.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ClinicalImpression = {
  __typename?: 'ClinicalImpression';
  /** The clinician performing the assessment. */
  assessor?: Maybe<ClinicalImpressionAssessor>;
  /** Categorizes the type of clinical assessment performed. */
  code?: Maybe<CodeableConcept>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Indicates when the documentation of the assessment was complete. */
  date?: Maybe<Scalars['code']>;
  /**
   * A summary of the context and/or cause of the assessment - why / where it was
   * performed, and what patient events/status prompted it.
   */
  description?: Maybe<Scalars['code']>;
  /** None */
  effectiveDateTime?: Maybe<Scalars['code']>;
  /** None */
  effectivePeriod?: Maybe<Period>;
  /**
   * The Encounter during which this ClinicalImpression was created or to which the
   * creation of this record is tightly associated.
   */
  encounter?: Maybe<Encounter>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Specific findings or diagnoses that were considered likely or relevant to
   * ongoing treatment.
   */
  finding?: Maybe<Array<Maybe<ClinicalImpressionFinding>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Business identifiers assigned to this clinical impression by the performer or
   * other systems which remain constant as the resource is updated and propagates
   * from server to server.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * One or more sets of investigations (signs, symptoms, etc.). The actual
   * grouping of investigations varies greatly depending on the type and context of
   * the assessment. These investigations may include data generated during the
   * assessment process, or data previously generated and recorded that is
   * pertinent to the outcomes.
   */
  investigation?: Maybe<Array<Maybe<ClinicalImpressionInvestigation>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Commentary about the impression, typically recorded after the impression
   * itself was made, though supplemental notes by the original author could also
   * appear.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /**
   * A reference to the last assessment that was conducted on this patient.
   * Assessments are often/usually ongoing in nature; a care provider (practitioner
   * or team) will make new assessments on an ongoing basis as new data arises or
   * the patient's conditions changes.
   */
  previous?: Maybe<ClinicalImpression>;
  /** A list of the relevant problems/conditions for a patient. */
  problem?: Maybe<Array<Maybe<ClinicalImpressionProblem>>>;
  /** Estimate of likely outcome. */
  prognosisCodeableConcept?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** RiskAssessment expressing likely outcome. */
  prognosisReference?: Maybe<Array<Maybe<RiskAssessment>>>;
  /**
   * Reference to a specific published clinical protocol that was followed during
   * this assessment, and/or that provides evidence in support of the diagnosis.
   */
  protocol?: Maybe<Array<Maybe<Scalars['code']>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** Identifies the workflow status of the assessment. */
  status: Scalars['code'];
  /** Captures the reason for the current state of the ClinicalImpression. */
  statusReason?: Maybe<CodeableConcept>;
  /** The patient or group of individuals assessed as part of this record. */
  subject: ClinicalImpressionSubject;
  /** A text summary of the investigations and the diagnosis. */
  summary?: Maybe<Scalars['code']>;
  /** Information supporting the clinical impression. */
  supportingInfo?: Maybe<Array<Maybe<Resource>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type ClinicalImpressionAssessor = Practitioner | PractitionerRole;

/**
 * ClinicalImpression.Finding
 *     A record of a clinical assessment performed to determine what problem(s) may
 *     affect the patient and before planning the treatments or management strategies
 *     that are best to manage a patient's condition. Assessments are often 1:1 with
 *     a clinical consultation / encounter,  but this varies greatly depending on the
 *     clinical workflow. This resource is called "ClinicalImpression" rather than
 *     "ClinicalAssessment" to avoid confusion with the recording of assessment tools
 *     such as Apgar score.
 */
export type ClinicalImpressionFinding = {
  __typename?: 'ClinicalImpressionFinding';
  /** Which investigations support finding or diagnosis. */
  basis?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Specific text or code for finding or diagnosis, which may include ruled-out or
   * resolved conditions.
   */
  itemCodeableConcept?: Maybe<CodeableConcept>;
  /**
   * Specific reference for finding or diagnosis, which may include ruled-out or
   * resolved conditions.
   */
  itemReference?: Maybe<ClinicalImpressionFindingItemReference>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type ClinicalImpressionFindingItemReference = Condition | Media | Observation;

/**
 * ClinicalImpression.Investigation
 *     A record of a clinical assessment performed to determine what problem(s) may
 *     affect the patient and before planning the treatments or management strategies
 *     that are best to manage a patient's condition. Assessments are often 1:1 with
 *     a clinical consultation / encounter,  but this varies greatly depending on the
 *     clinical workflow. This resource is called "ClinicalImpression" rather than
 *     "ClinicalAssessment" to avoid confusion with the recording of assessment tools
 *     such as Apgar score.
 */
export type ClinicalImpressionInvestigation = {
  __typename?: 'ClinicalImpressionInvestigation';
  /**
   * A name/code for the group ("set") of investigations. Typically, this will be
   * something like "signs", "symptoms", "clinical", "diagnostic", but the list is
   * not constrained, and others such groups such as
   * (exposure|family|travel|nutritional) history may be used.
   */
  code: CodeableConcept;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** A record of a specific investigation that was undertaken. */
  item?: Maybe<Array<Maybe<ClinicalImpressionInvestigationItem>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type ClinicalImpressionInvestigationItem = DiagnosticReport | FamilyMemberHistory | ImagingStudy | Media | Observation | QuestionnaireResponse | RiskAssessment;

export type ClinicalImpressionProblem = AllergyIntolerance | Condition;

export type ClinicalImpressionSubject = Group | Patient;

/**
 * CodeSystem
 *     The CodeSystem resource is used to declare the existence of and describe a
 *     code system or code system supplement and its key properties, and optionally
 *     define a part or all of its content.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type CodeSystem = {
  __typename?: 'CodeSystem';
  /**
   * If code comparison is case sensitive when codes within this system are
   * compared to each other.
   */
  caseSensitive?: Maybe<Scalars['code']>;
  /** The code system defines a compositional (post-coordination) grammar. */
  compositional?: Maybe<Scalars['code']>;
  /**
   * Concepts that are in the code system. The concept definitions are inherently
   * hierarchical, but the definitions must be consulted to determine what the
   * meanings of the hierarchical relationships are.
   */
  concept?: Maybe<Array<Maybe<CodeSystemConcept>>>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The extent of the content of the code system (the concepts and codes it
   * defines) are represented in this resource instance.
   */
  content: Scalars['code'];
  /**
   * A copyright statement relating to the code system and/or its contents.
   * Copyright statements are generally legal restrictions on the use and
   * publishing of the code system.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The total number of concepts defined by the code system. Where the code system
   * has a compositional grammar, the basis of this count is defined by the system
   * steward.
   */
  count?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the code system was published. The date
   * must change when the business version changes and it must change if the status
   * code changes. In addition, it should change when the substantive content of
   * the code system changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the code system from a consumer's
   * perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * A Boolean value to indicate that this code system is authored for testing
   * purposes (or education/evaluation/marketing) and is not intended to be used
   * for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A filter that can be used in a value set compose statement when selecting
   * concepts using a filter.
   */
  filter?: Maybe<Array<Maybe<CodeSystemFilter>>>;
  /** The meaning of the hierarchy of concepts as represented in this resource. */
  hierarchyMeaning?: Maybe<Scalars['code']>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this code system when it is
   * represented in other formats, or referenced in a specification, model, design
   * or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** A legal or geographic region in which the code system is intended to be used. */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the code system. This name should be
   * usable as an identifier for the module by machine processing applications such
   * as code generation.
   */
  name?: Maybe<Scalars['code']>;
  /**
   * A property defines an additional slot through which additional information can
   * be provided about a concept.
   */
  property?: Maybe<Array<Maybe<CodeSystemProperty>>>;
  /** The name of the organization or individual that published the code system. */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this code system is needed and why it has been designed as
   * it has.
   */
  purpose?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The date (and optionally time) when the code system resource was created or
   * revised.
   */
  status: Scalars['code'];
  /**
   * The canonical URL of the code system that this code system supplement is
   * adding designations and properties to.
   */
  supplements?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the code system. */
  title?: Maybe<Scalars['code']>;
  /**
   * An absolute URI that is used to identify this code system when it is
   * referenced in a specification, model, design or an instance; also called its
   * canonical identifier. This SHOULD be globally unique and SHOULD be a literal
   * address at which at which an authoritative instance of this code system is (or
   * will be) published. This URL can be the target of a canonical reference. It
   * SHALL remain the same when the code system is stored on different servers.
   * This is used in [Coding](datatypes.html#Coding).system.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate code system
   * instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /** Canonical reference to the value set that contains the entire code system. */
  valueSet?: Maybe<Scalars['code']>;
  /**
   * The identifier that is used to identify this version of the code system when
   * it is referenced in a specification, model, design or instance. This is an
   * arbitrary value managed by the code system author and is not expected to be
   * globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a
   * managed version is not available. There is also no expectation that versions
   * can be placed in a lexicographical sequence. This is used in
   * [Coding](datatypes.html#Coding).version.
   */
  version?: Maybe<Scalars['code']>;
  /**
   * This flag is used to signify that the code system does not commit to concept
   * permanence across versions. If true, a version must be specified when
   * referencing this code system.
   */
  versionNeeded?: Maybe<Scalars['code']>;
};

/**
 * CodeSystem.Concept
 *     The CodeSystem resource is used to declare the existence of and describe a
 *     code system or code system supplement and its key properties, and optionally
 *     define a part or all of its content.
 */
export type CodeSystemConcept = {
  __typename?: 'CodeSystemConcept';
  /**
   * A code - a text symbol - that uniquely identifies the concept within the code
   * system.
   */
  code: Scalars['code'];
  /**
   * Defines children of a concept to produce a hierarchy of concepts. The nature
   * of the relationships is variable (is-a/contains/categorizes) - see
   * hierarchyMeaning.
   */
  concept?: Maybe<Array<Maybe<CodeSystemConcept>>>;
  /**
   * The formal definition of the concept. The code system resource does not make
   * formal definitions required, because of the prevalence of legacy systems.
   * However, they are highly recommended, as without them there is no formal
   * meaning associated with the concept.
   */
  definition?: Maybe<Scalars['code']>;
  /**
   * Additional representations for the concept - other languages, aliases,
   * specialized purposes, used for particular purposes, etc.
   */
  designation?: Maybe<Array<Maybe<CodeSystemDesignation>>>;
  /**
   * A human readable string that is the recommended default way to present this
   * concept to a user.
   */
  display?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A property value for this concept. */
  property?: Maybe<Array<Maybe<CodeSystemProperty1>>>;
};

/**
 * CodeSystem.Designation
 *     The CodeSystem resource is used to declare the existence of and describe a
 *     code system or code system supplement and its key properties, and optionally
 *     define a part or all of its content.
 */
export type CodeSystemDesignation = {
  __typename?: 'CodeSystemDesignation';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The language this designation is defined for. */
  language?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A code that details how this designation would be used. */
  use?: Maybe<Coding>;
  /** The text value for this designation. */
  value: Scalars['code'];
};

/**
 * CodeSystem.Filter
 *     The CodeSystem resource is used to declare the existence of and describe a
 *     code system or code system supplement and its key properties, and optionally
 *     define a part or all of its content.
 */
export type CodeSystemFilter = {
  __typename?: 'CodeSystemFilter';
  /**
   * The code that identifies this filter when it is used as a filter in
   * [[[ValueSet]]].compose.include.filter.
   */
  code: Scalars['code'];
  /** A description of how or why the filter is used. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A list of operators that can be used with the filter. */
  operator: Array<Maybe<Scalars['code']>>;
  /** A description of what the value for the filter should be. */
  value: Scalars['code'];
};

/**
 * CodeSystem.Property
 *     The CodeSystem resource is used to declare the existence of and describe a
 *     code system or code system supplement and its key properties, and optionally
 *     define a part or all of its content.
 */
export type CodeSystemProperty = {
  __typename?: 'CodeSystemProperty';
  /**
   * A code that is used to identify the property. The code is used internally (in
   * CodeSystem.concept.property.code) and also externally, such as in property
   * filters.
   */
  code: Scalars['code'];
  /**
   * A description of the property- why it is defined, and how its value might be
   * used.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The type of the property value. Properties of type "code" contain a code
   * defined by the code system (e.g. a reference to another defined concept).
   */
  type: Scalars['code'];
  /**
   * Reference to the formal meaning of the property. One possible source of
   * meaning is the [Concept Properties](codesystem-concept-properties.html) code
   * system.
   */
  uri?: Maybe<Scalars['code']>;
};

/**
 * CodeSystem.Property1
 *     The CodeSystem resource is used to declare the existence of and describe a
 *     code system or code system supplement and its key properties, and optionally
 *     define a part or all of its content.
 */
export type CodeSystemProperty1 = {
  __typename?: 'CodeSystemProperty1';
  /** A code that is a reference to CodeSystem.property.code. */
  code: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  valueBoolean?: Maybe<Scalars['code']>;
  /** None */
  valueCode?: Maybe<Scalars['code']>;
  /** None */
  valueCoding?: Maybe<Coding>;
  /** None */
  valueDateTime?: Maybe<Scalars['code']>;
  /** None */
  valueDecimal?: Maybe<Scalars['code']>;
  /** None */
  valueInteger?: Maybe<Scalars['code']>;
  /** None */
  valueString?: Maybe<Scalars['code']>;
};

/**
 * CodeableConcept
 *     A concept that may be defined by a formal reference to a terminology or
 *     ontology or may be provided by text.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type CodeableConcept = {
  __typename?: 'CodeableConcept';
  /** A reference to a code defined by a terminology system. */
  coding?: Maybe<Array<Maybe<Coding>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * A human language representation of the concept as seen/selected/uttered by the
   * user who entered the data and/or which represents the intended meaning of the
   * user.
   */
  text?: Maybe<Scalars['code']>;
};

/**
 * Coding
 *     A reference to a code defined by a terminology system.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Coding = {
  __typename?: 'Coding';
  /**
   * A symbol in syntax defined by the system. The symbol may be a predefined code
   * or an expression in a syntax defined by the coding system (e.g. post-
   * coordination).
   */
  code?: Maybe<Scalars['code']>;
  /**
   * A representation of the meaning of the code in the system, following the rules
   * of the system.
   */
  display?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The identification of the code system that defines the meaning of the symbol
   * in the code.
   */
  system?: Maybe<Scalars['code']>;
  /**
   * Indicates that this coding was chosen by a user directly - e.g. off a pick
   * list of available items (codes or displays).
   */
  userSelected?: Maybe<Scalars['code']>;
  /**
   * The version of the code system which was used when choosing this code. Note
   * that a well-maintained code system does not need the version reported, because
   * the meaning of codes is consistent across versions. However this cannot
   * consistently be assured, and when the meaning is not guaranteed to be
   * consistent, the version SHOULD be exchanged.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * Communication
 *     An occurrence of information being transmitted; e.g. an alert that was sent to
 *     a responsible provider, a public health agency that was notified about a
 *     reportable condition.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Communication = {
  __typename?: 'Communication';
  /**
   * Other resources that pertain to this communication and to which this
   * communication should be associated.
   */
  about?: Maybe<Array<Maybe<Resource>>>;
  /**
   * An order, proposal or plan fulfilled in whole or in part by this
   * Communication.
   */
  basedOn?: Maybe<Array<Maybe<Resource>>>;
  /**
   * The type of message conveyed such as alert, notification, reminder,
   * instruction, etc.
   */
  category?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The Encounter during which this Communication was created or to which the
   * creation of this record is tightly associated.
   */
  encounter?: Maybe<Encounter>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Business identifiers assigned to this communication by the performer or other
   * systems which remain constant as the resource is updated and propagates from
   * server to server.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** Prior communication that this communication is in response to. */
  inResponseTo?: Maybe<Array<Maybe<Communication>>>;
  /**
   * The URL pointing to a FHIR-defined protocol, guideline, orderset or other
   * definition that is adhered to in whole or in part by this Communication.
   */
  instantiatesCanonical?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The URL pointing to an externally maintained protocol, guideline, orderset or
   * other definition that is adhered to in whole or in part by this Communication.
   */
  instantiatesUri?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** A channel that was used for this communication (e.g. email, fax). */
  medium?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Additional notes or commentary about the communication by the sender, receiver
   * or other interested parties.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** Part of this action. */
  partOf?: Maybe<Array<Maybe<Resource>>>;
  /** Text, attachment(s), or resource(s) that was communicated to the recipient. */
  payload?: Maybe<Array<Maybe<CommunicationPayload>>>;
  /**
   * Characterizes how quickly the planned or in progress communication must be
   * addressed. Includes concepts such as stat, urgent, routine.
   */
  priority?: Maybe<Scalars['code']>;
  /** The reason or justification for the communication. */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Indicates another resource whose existence justifies this communication. */
  reasonReference?: Maybe<Array<Maybe<CommunicationReasonReference>>>;
  /** The time when this communication arrived at the destination. */
  received?: Maybe<Scalars['code']>;
  /**
   * The entity (e.g. person, organization, clinical information system, care team
   * or device) which was the target of the communication. If receipts need to be
   * tracked by an individual, a separate resource instance will need to be created
   * for each recipient.  Multiple recipient communications are intended where
   * either receipts are not tracked (e.g. a mass mail-out) or a receipt is
   * captured in aggregate (all emails confirmed received by a particular time).
   */
  recipient?: Maybe<Array<Maybe<CommunicationRecipient>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The entity (e.g. person, organization, clinical information system, or device)
   * which was the source of the communication.
   */
  sender?: Maybe<CommunicationSender>;
  /** The time when this communication was sent. */
  sent?: Maybe<Scalars['code']>;
  /** The status of the transmission. */
  status: Scalars['code'];
  /** Captures the reason for the current state of the Communication. */
  statusReason?: Maybe<CodeableConcept>;
  /** The patient or group that was the focus of this communication. */
  subject?: Maybe<CommunicationSubject>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** Description of the purpose/content, similar to a subject line in an email. */
  topic?: Maybe<CodeableConcept>;
};

/**
 * Communication.Payload
 *     An occurrence of information being transmitted; e.g. an alert that was sent to
 *     a responsible provider, a public health agency that was notified about a
 *     reportable condition.
 */
export type CommunicationPayload = {
  __typename?: 'CommunicationPayload';
  /** None */
  contentAttachment?: Maybe<Attachment>;
  /** None */
  contentReference?: Maybe<Resource>;
  /** None */
  contentString?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type CommunicationReasonReference = Condition | DiagnosticReport | DocumentReference | Observation;

export type CommunicationRecipient = CareTeam | Device | Group | HealthcareService | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * CommunicationRequest
 *     A request to convey information; e.g. the CDS system proposes that an alert be
 *     sent to a responsible provider, the CDS system proposes that the public health
 *     agency be notified about a reportable condition.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type CommunicationRequest = {
  __typename?: 'CommunicationRequest';
  /**
   * Other resources that pertain to this communication request and to which this
   * communication request should be associated.
   */
  about?: Maybe<Array<Maybe<Resource>>>;
  /**
   * For draft requests, indicates the date of initial creation.  For requests with
   * other statuses, indicates the date of activation.
   */
  authoredOn?: Maybe<Scalars['code']>;
  /** A plan or proposal that is fulfilled in whole or in part by this request. */
  basedOn?: Maybe<Array<Maybe<Resource>>>;
  /**
   * The type of message to be sent such as alert, notification, reminder,
   * instruction, etc.
   */
  category?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * If true indicates that the CommunicationRequest is asking for the specified
   * action to *not* occur.
   */
  doNotPerform?: Maybe<Scalars['code']>;
  /**
   * The Encounter during which this CommunicationRequest was created or to which
   * the creation of this record is tightly associated.
   */
  encounter?: Maybe<Encounter>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A shared identifier common to all requests that were authorized more or less
   * simultaneously by a single author, representing the identifier of the
   * requisition, prescription or similar form.
   */
  groupIdentifier?: Maybe<Identifier>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Business identifiers assigned to this communication request by the performer
   * or other systems which remain constant as the resource is updated and
   * propagates from server to server.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** A channel that was used for this communication (e.g. email, fax). */
  medium?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Comments made about the request by the requester, sender, recipient, subject
   * or other participants.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** None */
  occurrenceDateTime?: Maybe<Scalars['code']>;
  /** None */
  occurrencePeriod?: Maybe<Period>;
  /** Text, attachment(s), or resource(s) to be communicated to the recipient. */
  payload?: Maybe<Array<Maybe<CommunicationRequestPayload>>>;
  /**
   * Characterizes how quickly the proposed act must be initiated. Includes
   * concepts such as stat, urgent, routine.
   */
  priority?: Maybe<Scalars['code']>;
  /** Describes why the request is being made in coded or textual form. */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Indicates another resource whose existence justifies this request. */
  reasonReference?: Maybe<Array<Maybe<CommunicationRequestReasonReference>>>;
  /**
   * The entity (e.g. person, organization, clinical information system, device,
   * group, or care team) which is the intended target of the communication.
   */
  recipient?: Maybe<Array<Maybe<CommunicationRequestRecipient>>>;
  /**
   * Completed or terminated request(s) whose function is taken by this new
   * request.
   */
  replaces?: Maybe<Array<Maybe<CommunicationRequest>>>;
  /**
   * The device, individual, or organization who initiated the request and has
   * responsibility for its activation.
   */
  requester?: Maybe<CommunicationRequestRequester>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The entity (e.g. person, organization, clinical information system, or device)
   * which is to be the source of the communication.
   */
  sender?: Maybe<CommunicationRequestSender>;
  /** The status of the proposal or order. */
  status: Scalars['code'];
  /** Captures the reason for the current state of the CommunicationRequest. */
  statusReason?: Maybe<CodeableConcept>;
  /** The patient or group that is the focus of this communication request. */
  subject?: Maybe<CommunicationRequestSubject>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * CommunicationRequest.Payload
 *     A request to convey information; e.g. the CDS system proposes that an alert be
 *     sent to a responsible provider, the CDS system proposes that the public health
 *     agency be notified about a reportable condition.
 */
export type CommunicationRequestPayload = {
  __typename?: 'CommunicationRequestPayload';
  /** None */
  contentAttachment?: Maybe<Attachment>;
  /** None */
  contentReference?: Maybe<Resource>;
  /** None */
  contentString?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type CommunicationRequestReasonReference = Condition | DiagnosticReport | DocumentReference | Observation;

export type CommunicationRequestRecipient = CareTeam | Device | Group | HealthcareService | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type CommunicationRequestRequester = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type CommunicationRequestSender = Device | HealthcareService | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type CommunicationRequestSubject = Group | Patient;

export type CommunicationSender = Device | HealthcareService | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type CommunicationSubject = Group | Patient;

/**
 * CompartmentDefinition
 *     A compartment definition that defines how resources are accessed on a server.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type CompartmentDefinition = {
  __typename?: 'CompartmentDefinition';
  /** Which compartment this definition describes. */
  code: Scalars['code'];
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The date  (and optionally time) when the compartment definition was published.
   * The date must change when the business version changes and it must change if
   * the status code changes. In addition, it should change when the substantive
   * content of the compartment definition changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the compartment definition from a
   * consumer's perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * A Boolean value to indicate that this compartment definition is authored for
   * testing purposes (or education/evaluation/marketing) and is not intended to be
   * used for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the compartment definition. This name
   * should be usable as an identifier for the module by machine processing
   * applications such as code generation.
   */
  name: Scalars['code'];
  /**
   * The name of the organization or individual that published the compartment
   * definition.
   */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this compartment definition is needed and why it has been
   * designed as it has.
   */
  purpose?: Maybe<Scalars['code']>;
  /** Information about how a resource is related to the compartment. */
  resource?: Maybe<Array<Maybe<CompartmentDefinitionResource>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** Whether the search syntax is supported,. */
  search: Scalars['code'];
  /**
   * The status of this compartment definition. Enables tracking the life-cycle of
   * the content.
   */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * An absolute URI that is used to identify this compartment definition when it
   * is referenced in a specification, model, design or an instance; also called
   * its canonical identifier. This SHOULD be globally unique and SHOULD be a
   * literal address at which at which an authoritative instance of this
   * compartment definition is (or will be) published. This URL can be the target
   * of a canonical reference. It SHALL remain the same when the compartment
   * definition is stored on different servers.
   */
  url: Scalars['code'];
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate compartment
   * definition instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the compartment
   * definition when it is referenced in a specification, model, design or
   * instance. This is an arbitrary value managed by the compartment definition
   * author and is not expected to be globally unique. For example, it might be a
   * timestamp (e.g. yyyymmdd) if a managed version is not available. There is also
   * no expectation that versions can be placed in a lexicographical sequence.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * CompartmentDefinition.Resource
 *     A compartment definition that defines how resources are accessed on a server.
 */
export type CompartmentDefinitionResource = {
  __typename?: 'CompartmentDefinitionResource';
  /** The name of a resource supported by the server. */
  code: Scalars['code'];
  /** Additional documentation about the resource and compartment. */
  documentation?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The name of a search parameter that represents the link to the compartment.
   * More than one may be listed because a resource may be linked to a compartment
   * in more than one way,.
   */
  param?: Maybe<Array<Maybe<Scalars['code']>>>;
};

/**
 * Composition
 *     A set of healthcare-related information that is assembled together into a
 *     single logical package that provides a single coherent statement of meaning,
 *     establishes its own context and that has clinical attestation with regard to
 *     who is making the statement. A Composition defines the structure and narrative
 *     content necessary for a document. However, a Composition alone does not
 *     constitute a document. Rather, the Composition must be the first entry in a
 *     Bundle where Bundle.type=document, and any other resources referenced from
 *     Composition must be included as subsequent entries in the Bundle (for example
 *     Patient, Practitioner, Encounter, etc.).
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Composition = {
  __typename?: 'Composition';
  /** A participant who has attested to the accuracy of the composition/document. */
  attester?: Maybe<Array<Maybe<CompositionAttester>>>;
  /**
   * Identifies who is responsible for the information in the composition, not
   * necessarily who typed it in.
   */
  author: Array<Maybe<CompositionAuthor>>;
  /**
   * A categorization for the type of the composition - helps for indexing and
   * searching. This may be implied by or derived from the code specified in the
   * Composition Type.
   */
  category?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The code specifying the level of confidentiality of the Composition. */
  confidentiality?: Maybe<Scalars['code']>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Identifies the organization or group who is responsible for ongoing
   * maintenance of and access to the composition/document information.
   */
  custodian?: Maybe<Organization>;
  /**
   * The composition editing time, when the composition was last logically changed
   * by the author.
   */
  date: Scalars['code'];
  /**
   * Describes the clinical encounter or type of care this documentation is
   * associated with.
   */
  encounter?: Maybe<Encounter>;
  /**
   * The clinical service, such as a colonoscopy or an appendectomy, being
   * documented.
   */
  event?: Maybe<Array<Maybe<CompositionEvent>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A version-independent identifier for the Composition. This identifier stays
   * constant as the composition is changed over time.
   */
  identifier?: Maybe<Identifier>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Relationships that this composition has with other compositions or documents
   * that already exist.
   */
  relatesTo?: Maybe<Array<Maybe<CompositionRelatesTo>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** The root of the sections that make up the composition. */
  section?: Maybe<Array<Maybe<CompositionSection>>>;
  /**
   * The workflow/clinical status of this composition. The status is a marker for
   * the clinical standing of the document.
   */
  status: Scalars['code'];
  /**
   * Who or what the composition is about. The composition can be about a person,
   * (patient or healthcare practitioner), a device (e.g. a machine) or even a
   * group of subjects (such as a document about a herd of livestock, or a set of
   * patients that share a common exposure).
   */
  subject?: Maybe<Resource>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** Official human-readable label for the composition. */
  title: Scalars['code'];
  /**
   * Specifies the particular kind of composition (e.g. History and Physical,
   * Discharge Summary, Progress Note). This usually equates to the purpose of
   * making the composition.
   */
  type: CodeableConcept;
};

/**
 * Composition.Attester
 *     A set of healthcare-related information that is assembled together into a
 *     single logical package that provides a single coherent statement of meaning,
 *     establishes its own context and that has clinical attestation with regard to
 *     who is making the statement. A Composition defines the structure and narrative
 *     content necessary for a document. However, a Composition alone does not
 *     constitute a document. Rather, the Composition must be the first entry in a
 *     Bundle where Bundle.type=document, and any other resources referenced from
 *     Composition must be included as subsequent entries in the Bundle (for example
 *     Patient, Practitioner, Encounter, etc.).
 */
export type CompositionAttester = {
  __typename?: 'CompositionAttester';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The type of attestation the authenticator offers. */
  mode: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Who attested the composition in the specified way. */
  party?: Maybe<CompositionAttesterParty>;
  /** When the composition was attested by the party. */
  time?: Maybe<Scalars['code']>;
};

export type CompositionAttesterParty = Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type CompositionAuthor = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Composition.Event
 *     A set of healthcare-related information that is assembled together into a
 *     single logical package that provides a single coherent statement of meaning,
 *     establishes its own context and that has clinical attestation with regard to
 *     who is making the statement. A Composition defines the structure and narrative
 *     content necessary for a document. However, a Composition alone does not
 *     constitute a document. Rather, the Composition must be the first entry in a
 *     Bundle where Bundle.type=document, and any other resources referenced from
 *     Composition must be included as subsequent entries in the Bundle (for example
 *     Patient, Practitioner, Encounter, etc.).
 */
export type CompositionEvent = {
  __typename?: 'CompositionEvent';
  /**
   * This list of codes represents the main clinical acts, such as a colonoscopy or
   * an appendectomy, being documented. In some cases, the event is inherent in the
   * typeCode, such as a "History and Physical Report" in which the procedure being
   * documented is necessarily a "History and Physical" act.
   */
  code?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The description and/or reference of the event(s) being documented. For
   * example, this could be used to document such a colonoscopy or an appendectomy.
   */
  detail?: Maybe<Array<Maybe<Resource>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The period of time covered by the documentation. There is no assertion that
   * the documentation is a complete representation for this period, only that it
   * documents events during this time.
   */
  period?: Maybe<Period>;
};

/**
 * Composition.RelatesTo
 *     A set of healthcare-related information that is assembled together into a
 *     single logical package that provides a single coherent statement of meaning,
 *     establishes its own context and that has clinical attestation with regard to
 *     who is making the statement. A Composition defines the structure and narrative
 *     content necessary for a document. However, a Composition alone does not
 *     constitute a document. Rather, the Composition must be the first entry in a
 *     Bundle where Bundle.type=document, and any other resources referenced from
 *     Composition must be included as subsequent entries in the Bundle (for example
 *     Patient, Practitioner, Encounter, etc.).
 */
export type CompositionRelatesTo = {
  __typename?: 'CompositionRelatesTo';
  /**
   * The type of relationship that this composition has with anther composition or
   * document.
   */
  code: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  targetIdentifier?: Maybe<Identifier>;
  /** None */
  targetReference?: Maybe<Composition>;
};

/**
 * Composition.Section
 *     A set of healthcare-related information that is assembled together into a
 *     single logical package that provides a single coherent statement of meaning,
 *     establishes its own context and that has clinical attestation with regard to
 *     who is making the statement. A Composition defines the structure and narrative
 *     content necessary for a document. However, a Composition alone does not
 *     constitute a document. Rather, the Composition must be the first entry in a
 *     Bundle where Bundle.type=document, and any other resources referenced from
 *     Composition must be included as subsequent entries in the Bundle (for example
 *     Patient, Practitioner, Encounter, etc.).
 */
export type CompositionSection = {
  __typename?: 'CompositionSection';
  /**
   * Identifies who is responsible for the information in this section, not
   * necessarily who typed it in.
   */
  author?: Maybe<Array<Maybe<CompositionSectionAuthor>>>;
  /**
   * A code identifying the kind of content contained within the section. This must
   * be consistent with the section title.
   */
  code?: Maybe<CodeableConcept>;
  /**
   * If the section is empty, why the list is empty. An empty section typically has
   * some text explaining the empty reason.
   */
  emptyReason?: Maybe<CodeableConcept>;
  /**
   * A reference to the actual resource from which the narrative in the section is
   * derived.
   */
  entry?: Maybe<Array<Maybe<Resource>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The actual focus of the section when it is not the subject of the composition,
   * but instead represents something or someone associated with the subject such
   * as (for a patient subject) a spouse, parent, fetus, or donor. If not focus is
   * specified, the focus is assumed to be focus of the parent section, or, for a
   * section in the Composition itself, the subject of the composition. Sections
   * with a focus SHALL only include resources where the logical subject (patient,
   * subject, focus, etc.) matches the section focus, or the resources have no
   * logical subject (few resources).
   */
  focus?: Maybe<Resource>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * How the entry list was prepared - whether it is a working list that is
   * suitable for being maintained on an ongoing basis, or if it represents a
   * snapshot of a list of items from another source, or whether it is a prepared
   * list where items may be marked as added, modified or deleted.
   */
  mode?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Specifies the order applied to the items in the section entries. */
  orderedBy?: Maybe<CodeableConcept>;
  /** A nested sub-section within this section. */
  section?: Maybe<Array<Maybe<CompositionSection>>>;
  /**
   * A human-readable narrative that contains the attested content of the section,
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   */
  text?: Maybe<Narrative>;
  /**
   * The label for this particular section.  This will be part of the rendered
   * content for the document, and is often used to build a table of contents.
   */
  title?: Maybe<Scalars['code']>;
};

export type CompositionSectionAuthor = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * ConceptMap
 *     A statement of relationships from one set of concepts to one or more other
 *     concepts - either concepts in code systems, or data element/data element
 *     concepts, or classes in class models.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ConceptMap = {
  __typename?: 'ConceptMap';
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the concept map and/or its contents.
   * Copyright statements are generally legal restrictions on the use and
   * publishing of the concept map.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the concept map was published. The date
   * must change when the business version changes and it must change if the status
   * code changes. In addition, it should change when the substantive content of
   * the concept map changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the concept map from a consumer's
   * perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * A Boolean value to indicate that this concept map is authored for testing
   * purposes (or education/evaluation/marketing) and is not intended to be used
   * for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** A group of mappings that all have the same source and target system. */
  group?: Maybe<Array<Maybe<ConceptMapGroup>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this concept map when it is
   * represented in other formats, or referenced in a specification, model, design
   * or an instance.
   */
  identifier?: Maybe<Identifier>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** A legal or geographic region in which the concept map is intended to be used. */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the concept map. This name should be
   * usable as an identifier for the module by machine processing applications such
   * as code generation.
   */
  name?: Maybe<Scalars['code']>;
  /** The name of the organization or individual that published the concept map. */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this concept map is needed and why it has been designed as
   * it has.
   */
  purpose?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /** None */
  sourceCanonical?: Maybe<Scalars['code']>;
  /** None */
  sourceUri?: Maybe<Scalars['code']>;
  /**
   * The status of this concept map. Enables tracking the life-cycle of the
   * content.
   */
  status: Scalars['code'];
  /** None */
  targetCanonical?: Maybe<Scalars['code']>;
  /** None */
  targetUri?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the concept map. */
  title?: Maybe<Scalars['code']>;
  /**
   * An absolute URI that is used to identify this concept map when it is
   * referenced in a specification, model, design or an instance; also called its
   * canonical identifier. This SHOULD be globally unique and SHOULD be a literal
   * address at which at which an authoritative instance of this concept map is (or
   * will be) published. This URL can be the target of a canonical reference. It
   * SHALL remain the same when the concept map is stored on different servers.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate concept map
   * instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the concept map when
   * it is referenced in a specification, model, design or instance. This is an
   * arbitrary value managed by the concept map author and is not expected to be
   * globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a
   * managed version is not available. There is also no expectation that versions
   * can be placed in a lexicographical sequence.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * ConceptMap.DependsOn
 *     A statement of relationships from one set of concepts to one or more other
 *     concepts - either concepts in code systems, or data element/data element
 *     concepts, or classes in class models.
 */
export type ConceptMapDependsOn = {
  __typename?: 'ConceptMapDependsOn';
  /**
   * The display for the code. The display is only provided to help editors when
   * editing the concept map.
   */
  display?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A reference to an element that holds a coded value that corresponds to a code
   * system property. The idea is that the information model carries an element
   * somewhere that is labeled to correspond with a code system property.
   */
  property: Scalars['code'];
  /**
   * An absolute URI that identifies the code system of the dependency code (if the
   * source/dependency is a value set that crosses code systems).
   */
  system?: Maybe<Scalars['code']>;
  /**
   * Identity (code or path) or the element/item/ValueSet/text that the map depends
   * on / refers to.
   */
  value: Scalars['code'];
};

/**
 * ConceptMap.Element
 *     A statement of relationships from one set of concepts to one or more other
 *     concepts - either concepts in code systems, or data element/data element
 *     concepts, or classes in class models.
 */
export type ConceptMapElement = {
  __typename?: 'ConceptMapElement';
  /** Identity (code or path) or the element/item being mapped. */
  code?: Maybe<Scalars['code']>;
  /**
   * The display for the code. The display is only provided to help editors when
   * editing the concept map.
   */
  display?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A concept from the target value set that this concept maps to. */
  target?: Maybe<Array<Maybe<ConceptMapTarget>>>;
};

/**
 * ConceptMap.Group
 *     A statement of relationships from one set of concepts to one or more other
 *     concepts - either concepts in code systems, or data element/data element
 *     concepts, or classes in class models.
 */
export type ConceptMapGroup = {
  __typename?: 'ConceptMapGroup';
  /**
   * Mappings for an individual concept in the source to one or more concepts in
   * the target.
   */
  element: Array<Maybe<ConceptMapElement>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * An absolute URI that identifies the source system where the concepts to be
   * mapped are defined.
   */
  source?: Maybe<Scalars['code']>;
  /**
   * The specific version of the code system, as determined by the code system
   * authority.
   */
  sourceVersion?: Maybe<Scalars['code']>;
  /**
   * An absolute URI that identifies the target system that the concepts will be
   * mapped to.
   */
  target?: Maybe<Scalars['code']>;
  /**
   * The specific version of the code system, as determined by the code system
   * authority.
   */
  targetVersion?: Maybe<Scalars['code']>;
  /**
   * What to do when there is no mapping for the source concept. "Unmapped" does
   * not include codes that are unmatched, and the unmapped element is ignored in a
   * code is specified to have equivalence = unmatched.
   */
  unmapped?: Maybe<ConceptMapUnmapped>;
};

/**
 * ConceptMap.Target
 *     A statement of relationships from one set of concepts to one or more other
 *     concepts - either concepts in code systems, or data element/data element
 *     concepts, or classes in class models.
 */
export type ConceptMapTarget = {
  __typename?: 'ConceptMapTarget';
  /** Identity (code or path) or the element/item that the map refers to. */
  code?: Maybe<Scalars['code']>;
  /**
   * A description of status/issues in mapping that conveys additional information
   * not represented in  the structured data.
   */
  comment?: Maybe<Scalars['code']>;
  /**
   * A set of additional dependencies for this mapping to hold. This mapping is
   * only applicable if the specified element can be resolved, and it has the
   * specified value.
   */
  dependsOn?: Maybe<Array<Maybe<ConceptMapDependsOn>>>;
  /**
   * The display for the code. The display is only provided to help editors when
   * editing the concept map.
   */
  display?: Maybe<Scalars['code']>;
  /**
   * The equivalence between the source and target concepts (counting for the
   * dependencies and products). The equivalence is read from target to source
   * (e.g. the target is 'wider' than the source).
   */
  equivalence: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A set of additional outcomes from this mapping to other elements. To properly
   * execute this mapping, the specified element must be mapped to some data
   * element or source that is in context. The mapping may still be useful without
   * a place for the additional data elements, but the equivalence cannot be relied
   * on.
   */
  product?: Maybe<Array<Maybe<ConceptMapDependsOn>>>;
};

/**
 * ConceptMap.Unmapped
 *     A statement of relationships from one set of concepts to one or more other
 *     concepts - either concepts in code systems, or data element/data element
 *     concepts, or classes in class models.
 */
export type ConceptMapUnmapped = {
  __typename?: 'ConceptMapUnmapped';
  /**
   * The fixed code to use when the mode = 'fixed'  - all unmapped codes are mapped
   * to a single fixed code.
   */
  code?: Maybe<Scalars['code']>;
  /**
   * The display for the code. The display is only provided to help editors when
   * editing the concept map.
   */
  display?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Defines which action to take if there is no match for the source concept in
   * the target system designated for the group. One of 3 actions are possible: use
   * the unmapped code (this is useful when doing a mapping between versions, and
   * only a few codes have changed), use a fixed code (a default code), or
   * alternatively, a reference to a different concept map can be provided (by
   * canonical URL).
   */
  mode: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The canonical reference to an additional ConceptMap resource instance to use
   * for mapping if this ConceptMap resource contains no matching mapping for the
   * source concept.
   */
  url?: Maybe<Scalars['code']>;
};

/**
 * Condition
 *     A clinical condition, problem, diagnosis, or other event, situation, issue, or
 *     clinical concept that has risen to a level of concern.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Condition = {
  __typename?: 'Condition';
  /** None */
  abatementAge?: Maybe<Quantity>;
  /** None */
  abatementDateTime?: Maybe<Scalars['code']>;
  /** None */
  abatementPeriod?: Maybe<Period>;
  /** None */
  abatementRange?: Maybe<Range>;
  /** None */
  abatementString?: Maybe<Scalars['code']>;
  /** Individual who is making the condition statement. */
  asserter?: Maybe<ConditionAsserter>;
  /** The anatomical location where this condition manifests itself. */
  bodySite?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** A category assigned to the condition. */
  category?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The clinical status of the condition. */
  clinicalStatus?: Maybe<CodeableConcept>;
  /** Identification of the condition, problem or diagnosis. */
  code?: Maybe<CodeableConcept>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The Encounter during which this Condition was created or to which the creation
   * of this record is tightly associated.
   */
  encounter?: Maybe<Encounter>;
  /**
   * Supporting evidence / manifestations that are the basis of the Condition's
   * verification status, such as evidence that confirmed or refuted the condition.
   */
  evidence?: Maybe<Array<Maybe<ConditionEvidence>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Business identifiers assigned to this condition by the performer or other
   * systems which remain constant as the resource is updated and propagates from
   * server to server.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Additional information about the Condition. This is a general notes/comments
   * entry  for description of the Condition, its diagnosis and prognosis.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** None */
  onsetAge?: Maybe<Quantity>;
  /** None */
  onsetDateTime?: Maybe<Scalars['code']>;
  /** None */
  onsetPeriod?: Maybe<Period>;
  /** None */
  onsetRange?: Maybe<Range>;
  /** None */
  onsetString?: Maybe<Scalars['code']>;
  /**
   * The recordedDate represents when this particular Condition record was created
   * in the system, which is often a system-generated date.
   */
  recordedDate?: Maybe<Scalars['code']>;
  /** Individual who recorded the record and takes responsibility for its content. */
  recorder?: Maybe<ConditionRecorder>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A subjective assessment of the severity of the condition as evaluated by the
   * clinician.
   */
  severity?: Maybe<CodeableConcept>;
  /**
   * Clinical stage or grade of a condition. May include formal severity
   * assessments.
   */
  stage?: Maybe<Array<Maybe<ConditionStage>>>;
  /** Indicates the patient or group who the condition record is associated with. */
  subject: ConditionSubject;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** The verification status to support the clinical status of the condition. */
  verificationStatus?: Maybe<CodeableConcept>;
};

export type ConditionAsserter = Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Condition.Evidence
 *     A clinical condition, problem, diagnosis, or other event, situation, issue, or
 *     clinical concept that has risen to a level of concern.
 */
export type ConditionEvidence = {
  __typename?: 'ConditionEvidence';
  /** A manifestation or symptom that led to the recording of this condition. */
  code?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Links to other relevant information, including pathology reports. */
  detail?: Maybe<Array<Maybe<Resource>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type ConditionRecorder = Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Condition.Stage
 *     A clinical condition, problem, diagnosis, or other event, situation, issue, or
 *     clinical concept that has risen to a level of concern.
 */
export type ConditionStage = {
  __typename?: 'ConditionStage';
  /**
   * Reference to a formal record of the evidence on which the staging assessment
   * is based.
   */
  assessment?: Maybe<Array<Maybe<ConditionStageAssessment>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A simple summary of the stage such as "Stage 3". The determination of the
   * stage is disease-specific.
   */
  summary?: Maybe<CodeableConcept>;
  /** The kind of staging, such as pathological or clinical staging. */
  type?: Maybe<CodeableConcept>;
};

export type ConditionStageAssessment = ClinicalImpression | DiagnosticReport | Observation;

export type ConditionSubject = Group | Patient;

/**
 * Consent
 *     A record of a healthcare consumer’s  choices, which permits or denies
 *     identified recipient(s) or recipient role(s) to perform one or more actions
 *     within a given policy context, for specific purposes and periods of time.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Consent = {
  __typename?: 'Consent';
  /**
   * A classification of the type of consents found in the statement. This element
   * supports indexing and retrieval of consent statements.
   */
  category: Array<Maybe<CodeableConcept>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** When this  Consent was issued / created / indexed. */
  dateTime?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Unique identifier for this copy of the Consent Statement. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The organization that manages the consent, and the framework within which it
   * is executed.
   */
  organization?: Maybe<Array<Maybe<Organization>>>;
  /** The patient/healthcare consumer to whom this consent applies. */
  patient?: Maybe<Patient>;
  /**
   * Either the Grantor, which is the entity responsible for granting the rights
   * listed in a Consent Directive or the Grantee, which is the entity responsible
   * for complying with the Consent Directive, including any obligations or
   * limitations on authorizations and enforcement of prohibitions.
   */
  performer?: Maybe<Array<Maybe<ConsentPerformer>>>;
  /**
   * The references to the policies that are included in this consent scope.
   * Policies may be organizational, but are often defined jurisdictionally, or in
   * law.
   */
  policy?: Maybe<Array<Maybe<ConsentPolicy>>>;
  /** A reference to the specific base computable regulation or policy. */
  policyRule?: Maybe<CodeableConcept>;
  /**
   * An exception to the base policy of this consent. An exception can be an
   * addition or removal of access permissions.
   */
  provision?: Maybe<ConsentProvision>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A selector of the type of consent being presented: ADR, Privacy, Treatment,
   * Research.  This list is now extensible.
   */
  scope: CodeableConcept;
  /** None */
  sourceAttachment?: Maybe<Attachment>;
  /** None */
  sourceReference?: Maybe<ConsentSourceReference>;
  /** Indicates the current state of this consent. */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * Whether a treatment instruction (e.g. artificial respiration yes or no) was
   * verified with the patient, his/her family or another authorized person.
   */
  verification?: Maybe<Array<Maybe<ConsentVerification>>>;
};

/**
 * Consent.Actor
 *     A record of a healthcare consumer’s  choices, which permits or denies
 *     identified recipient(s) or recipient role(s) to perform one or more actions
 *     within a given policy context, for specific purposes and periods of time.
 */
export type ConsentActor = {
  __typename?: 'ConsentActor';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The resource that identifies the actor. To identify actors by type, use group
   * to identify a set of actors by some property they share (e.g. 'admitting
   * officers').
   */
  reference: ConsentActorReference;
  /**
   * How the individual is involved in the resources content that is described in
   * the exception.
   */
  role: CodeableConcept;
};

export type ConsentActorReference = CareTeam | Device | Group | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Consent.Data
 *     A record of a healthcare consumer’s  choices, which permits or denies
 *     identified recipient(s) or recipient role(s) to perform one or more actions
 *     within a given policy context, for specific purposes and periods of time.
 */
export type ConsentData = {
  __typename?: 'ConsentData';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** How the resource reference is interpreted when testing consent restrictions. */
  meaning: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A reference to a specific resource that defines which resources are covered by
   * this consent.
   */
  reference: Resource;
};

export type ConsentPerformer = Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Consent.Policy
 *     A record of a healthcare consumer’s  choices, which permits or denies
 *     identified recipient(s) or recipient role(s) to perform one or more actions
 *     within a given policy context, for specific purposes and periods of time.
 */
export type ConsentPolicy = {
  __typename?: 'ConsentPolicy';
  /**
   * Entity or Organization having regulatory jurisdiction or accountability for
   * enforcing policies pertaining to Consent Directives.
   */
  authority?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The references to the policies that are included in this consent scope.
   * Policies may be organizational, but are often defined jurisdictionally, or in
   * law.
   */
  uri?: Maybe<Scalars['code']>;
};

/**
 * Consent.Provision
 *     A record of a healthcare consumer’s  choices, which permits or denies
 *     identified recipient(s) or recipient role(s) to perform one or more actions
 *     within a given policy context, for specific purposes and periods of time.
 */
export type ConsentProvision = {
  __typename?: 'ConsentProvision';
  /** Actions controlled by this Rule. */
  action?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Who or what is controlled by this rule. Use group to identify a set of actors
   * by some property they share (e.g. 'admitting officers').
   */
  actor?: Maybe<Array<Maybe<ConsentActor>>>;
  /**
   * The class of information covered by this rule. The type can be a FHIR resource
   * type, a profile on a type, or a CDA document, or some other type that
   * indicates what sort of information the consent relates to.
   */
  class?: Maybe<Array<Maybe<Coding>>>;
  /** If this code is found in an instance, then the rule applies. */
  code?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The resources controlled by this rule if specific resources are referenced. */
  data?: Maybe<Array<Maybe<ConsentData>>>;
  /**
   * Clinical or Operational Relevant period of time that bounds the data
   * controlled by this rule.
   */
  dataPeriod?: Maybe<Period>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The timeframe in this rule is valid. */
  period?: Maybe<Period>;
  /** Rules which provide exceptions to the base rule or subrules. */
  provision?: Maybe<Array<Maybe<ConsentProvision>>>;
  /**
   * The context of the activities a user is taking - why the user is accessing the
   * data - that are controlled by this rule.
   */
  purpose?: Maybe<Array<Maybe<Coding>>>;
  /**
   * A security label, comprised of 0..* security label fields (Privacy tags),
   * which define which resources are controlled by this exception.
   */
  securityLabel?: Maybe<Array<Maybe<Coding>>>;
  /**
   * Action  to take - permit or deny - when the rule conditions are met.  Not
   * permitted in root rule, required in all nested rules.
   */
  type?: Maybe<Scalars['code']>;
};

export type ConsentSourceReference = Consent | Contract | DocumentReference | QuestionnaireResponse;

/**
 * Consent.Verification
 *     A record of a healthcare consumer’s  choices, which permits or denies
 *     identified recipient(s) or recipient role(s) to perform one or more actions
 *     within a given policy context, for specific purposes and periods of time.
 */
export type ConsentVerification = {
  __typename?: 'ConsentVerification';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Date verification was collected. */
  verificationDate?: Maybe<Scalars['code']>;
  /** Has the instruction been verified. */
  verified: Scalars['code'];
  /** Who verified the instruction (Patient, Relative or other Authorized Person). */
  verifiedWith?: Maybe<ConsentVerificationVerifiedWith>;
};

export type ConsentVerificationVerifiedWith = Patient | RelatedPerson;

/**
 * ContactDetail
 *     Specifies contact information for a person or organization.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type ContactDetail = {
  __typename?: 'ContactDetail';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The name of an individual to contact. */
  name?: Maybe<Scalars['code']>;
  /**
   * The contact details for the individual (if a name was provided) or the
   * organization.
   */
  telecom?: Maybe<Array<Maybe<ContactPoint>>>;
};

/**
 * ContactPoint
 *     Details for all kinds of technology mediated contact points for a person or
 *     organization, including telephone, email, etc.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type ContactPoint = {
  __typename?: 'ContactPoint';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Time period when the contact point was/is in use. */
  period?: Maybe<Period>;
  /**
   * Specifies a preferred order in which to use a set of contacts. ContactPoints
   * with lower rank values are more preferred than those with higher rank values.
   */
  rank?: Maybe<Scalars['code']>;
  /**
   * Telecommunications form for contact point - what communications system is
   * required to make use of the contact.
   */
  system?: Maybe<Scalars['code']>;
  /** Identifies the purpose for the contact point. */
  use?: Maybe<Scalars['code']>;
  /**
   * The actual contact point details, in a form that is meaningful to the
   * designated communication system (i.e. phone number or email address).
   */
  value?: Maybe<Scalars['code']>;
};

/**
 * Contract
 *     Legally enforceable, formally recorded unilateral or bilateral directive i.e.,
 *     a policy or agreement.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Contract = {
  __typename?: 'Contract';
  /**
   * Alternative representation of the title for this Contract definition,
   * derivative, or instance in any legal state., e.g., a domain specific contract
   * number related to legislation.
   */
  alias?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Relevant time or time-period when this Contract is applicable. */
  applies?: Maybe<Period>;
  /**
   * The individual or organization that authored the Contract definition,
   * derivative, or instance in any legal state.
   */
  author?: Maybe<ContractAuthor>;
  /**
   * A formally or informally recognized grouping of people, principals,
   * organizations, or jurisdictions formed for the purpose of achieving some form
   * of collective action such as the promulgation, administration and enforcement
   * of contracts and policies.
   */
  authority?: Maybe<Array<Maybe<Organization>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Precusory content developed with a focus and intent of supporting the
   * formation a Contract instance, which may be associated with and transformable
   * into a Contract.
   */
  contentDefinition?: Maybe<ContractContentDefinition>;
  /**
   * The minimal content derived from the basal information source at a specific
   * stage in its lifecycle.
   */
  contentDerivative?: Maybe<CodeableConcept>;
  /**
   * Recognized governance framework or system operating with a circumscribed scope
   * in accordance with specified principles, policies, processes or procedures for
   * managing rights, actions, or behaviors of parties or principals relative to
   * resources.
   */
  domain?: Maybe<Array<Maybe<Location>>>;
  /**
   * Event resulting in discontinuation or termination of this Contract instance by
   * one or more parties to the contract.
   */
  expirationType?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The "patient friendly language" versionof the Contract in whole or in parts.
   * "Patient friendly language" means the representation of the Contract and
   * Contract Provisions in a manner that is readily accessible and understandable
   * by a layperson in accordance with best practices for communication styles that
   * ensure that those agreeing to or signing the Contract understand the roles,
   * actions, obligations, responsibilities, and implication of the agreement.
   */
  friendly?: Maybe<Array<Maybe<ContractFriendly>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Unique identifier for this Contract or a derivative that references a Source
   * Contract.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * The URL pointing to a FHIR-defined Contract Definition that is adhered to in
   * whole or part by this Contract.
   */
  instantiatesCanonical?: Maybe<Contract>;
  /**
   * The URL pointing to an externally maintained definition that is adhered to in
   * whole or in part by this Contract.
   */
  instantiatesUri?: Maybe<Scalars['code']>;
  /** When this  Contract was issued. */
  issued?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** List of Legal expressions or representations of this Contract. */
  legal?: Maybe<Array<Maybe<ContractLegal>>>;
  /**
   * Legal states of the formation of a legal instrument, which is a formally
   * executed written document that can be formally attributed to its author,
   * records and formally expresses a legally enforceable act, process, or
   * contractual duty, obligation, or right, and therefore evidences that act,
   * process, or agreement.
   */
  legalState?: Maybe<CodeableConcept>;
  /** None */
  legallyBindingAttachment?: Maybe<Attachment>;
  /** None */
  legallyBindingReference?: Maybe<ContractLegallyBindingReference>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying this Contract definition, derivative, or
   * instance in any legal state. Provides additional information about its
   * content. This name should be usable as an identifier for the module by machine
   * processing applications such as code generation.
   */
  name?: Maybe<Scalars['code']>;
  /**
   * Links to Provenance records for past versions of this Contract definition,
   * derivative, or instance, which identify key state transitions or updates that
   * are likely to be relevant to a user looking at the current version of the
   * Contract.  The Provence.entity indicates the target that was changed in the
   * update. http://build.fhir.org/provenance-definitions.html#Provenance.entity.
   */
  relevantHistory?: Maybe<Array<Maybe<Provenance>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** List of Computable Policy Rule Language Representations of this Contract. */
  rule?: Maybe<Array<Maybe<ContractRule>>>;
  /**
   * A selector of legal concerns for this Contract definition, derivative, or
   * instance in any legal state.
   */
  scope?: Maybe<CodeableConcept>;
  /**
   * Parties with legal standing in the Contract, including the principal parties,
   * the grantor(s) and grantee(s), which are any person or organization bound by
   * the contract, and any ancillary parties, which facilitate the execution of the
   * contract such as a notary or witness.
   */
  signer?: Maybe<Array<Maybe<ContractSigner>>>;
  /** Sites in which the contract is complied with,  exercised, or in force. */
  site?: Maybe<Array<Maybe<Location>>>;
  /** The status of the resource instance. */
  status?: Maybe<Scalars['code']>;
  /**
   * Sub-category for the Contract that distinguishes the kinds of systems that
   * would be interested in the Contract within the context of the Contract's
   * scope.
   */
  subType?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The target entity impacted by or of interest to parties to the agreement. */
  subject?: Maybe<Array<Maybe<Resource>>>;
  /**
   * An explanatory or alternate user-friendly title for this Contract definition,
   * derivative, or instance in any legal state.t giving additional information
   * about its content.
   */
  subtitle?: Maybe<Scalars['code']>;
  /**
   * Information that may be needed by/relevant to the performer in their execution
   * of this term action.
   */
  supportingInfo?: Maybe<Array<Maybe<Resource>>>;
  /**
   * One or more Contract Provisions, which may be related and conveyed as a group,
   * and may contain nested groups.
   */
  term?: Maybe<Array<Maybe<ContractTerm>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * A short, descriptive, user-friendly title for this Contract definition,
   * derivative, or instance in any legal state.t giving additional information
   * about its content.
   */
  title?: Maybe<Scalars['code']>;
  /** None */
  topicCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  topicReference?: Maybe<Resource>;
  /**
   * A high-level category for the legal instrument, whether constructed as a
   * Contract definition, derivative, or instance in any legal state.  Provides
   * additional information about its content within the context of the Contract's
   * scope to distinguish the kinds of systems that would be interested in the
   * contract.
   */
  type?: Maybe<CodeableConcept>;
  /**
   * Canonical identifier for this contract, represented as a URI (globally
   * unique).
   */
  url?: Maybe<Scalars['code']>;
  /**
   * An edition identifier used for business purposes to label business significant
   * variants.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * Contract.Action
 *     Legally enforceable, formally recorded unilateral or bilateral directive i.e.,
 *     a policy or agreement.
 */
export type ContractAction = {
  __typename?: 'ContractAction';
  /** Encounter or Episode with primary association to specified term activity. */
  context?: Maybe<ContractActionContext>;
  /**
   * Id [identifier??] of the clause or question text related to the requester of
   * this action in the referenced form or QuestionnaireResponse.
   */
  contextLinkId?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** True if the term prohibits the  action. */
  doNotPerform?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Reason or purpose for the action stipulated by this Contract Provision. */
  intent: CodeableConcept;
  /**
   * Id [identifier??] of the clause or question text related to this action in the
   * referenced form or QuestionnaireResponse.
   */
  linkId?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Comments made about the term action made by the requester, performer, subject
   * or other participants.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** None */
  occurrenceDateTime?: Maybe<Scalars['code']>;
  /** None */
  occurrencePeriod?: Maybe<Period>;
  /** None */
  occurrenceTiming?: Maybe<Timing>;
  /** Indicates who or what is being asked to perform (or not perform) the ction. */
  performer?: Maybe<ContractActionPerformer>;
  /**
   * Id [identifier??] of the clause or question text related to the reason type or
   * reference of this  action in the referenced form or QuestionnaireResponse.
   */
  performerLinkId?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The type of role or competency of an individual desired or required to perform
   * or not perform the action.
   */
  performerRole?: Maybe<CodeableConcept>;
  /**
   * The type of individual that is desired or required to perform or not perform
   * the action.
   */
  performerType?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Describes why the action is to be performed or not performed in textual form. */
  reason?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Rationale for the action to be performed or not performed. Describes why the
   * action is permitted or prohibited.
   */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Id [identifier??] of the clause or question text related to the reason type or
   * reference of this  action in the referenced form or QuestionnaireResponse.
   */
  reasonLinkId?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Indicates another resource whose existence justifies permitting or not
   * permitting this action.
   */
  reasonReference?: Maybe<Array<Maybe<ContractActionReasonReference>>>;
  /** Who or what initiated the action and has responsibility for its activation. */
  requester?: Maybe<Array<Maybe<ContractActionRequester>>>;
  /**
   * Id [identifier??] of the clause or question text related to the requester of
   * this action in the referenced form or QuestionnaireResponse.
   */
  requesterLinkId?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Security labels that protects the action. */
  securityLabelNumber?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Current state of the term action. */
  status: CodeableConcept;
  /** Entity of the action. */
  subject?: Maybe<Array<Maybe<ContractSubject>>>;
  /**
   * Activity or service obligation to be done or not done, performed or not
   * performed, effectuated or not by this Contract term.
   */
  type: CodeableConcept;
};

export type ContractActionContext = Encounter | EpisodeOfCare;

export type ContractActionPerformer = CareTeam | Device | Location | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson | Substance;

export type ContractActionReasonReference = Condition | DiagnosticReport | DocumentReference | Observation | Questionnaire | QuestionnaireResponse;

export type ContractActionRequester = Device | Group | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Contract.Answer
 *     Legally enforceable, formally recorded unilateral or bilateral directive i.e.,
 *     a policy or agreement.
 */
export type ContractAnswer = {
  __typename?: 'ContractAnswer';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  valueAttachment?: Maybe<Attachment>;
  /** None */
  valueBoolean?: Maybe<Scalars['code']>;
  /** None */
  valueCoding?: Maybe<Coding>;
  /** None */
  valueDate?: Maybe<Scalars['code']>;
  /** None */
  valueDateTime?: Maybe<Scalars['code']>;
  /** None */
  valueDecimal?: Maybe<Scalars['code']>;
  /** None */
  valueInteger?: Maybe<Scalars['code']>;
  /** None */
  valueQuantity?: Maybe<Quantity>;
  /** None */
  valueReference?: Maybe<Resource>;
  /** None */
  valueString?: Maybe<Scalars['code']>;
  /** None */
  valueTime?: Maybe<Scalars['code']>;
  /** None */
  valueUri?: Maybe<Scalars['code']>;
};

/**
 * Contract.Asset
 *     Legally enforceable, formally recorded unilateral or bilateral directive i.e.,
 *     a policy or agreement.
 */
export type ContractAsset = {
  __typename?: 'ContractAsset';
  /** Response to assets. */
  answer?: Maybe<Array<Maybe<ContractAnswer>>>;
  /**
   * Description of the quality and completeness of the asset that imay be a factor
   * in its valuation.
   */
  condition?: Maybe<Scalars['code']>;
  /** Circumstance of the asset. */
  context?: Maybe<Array<Maybe<ContractContext>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Id [identifier??] of the clause or question text about the asset in the
   * referenced form or QuestionnaireResponse.
   */
  linkId?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Asset relevant contractual time period. */
  period?: Maybe<Array<Maybe<Period>>>;
  /** Type of Asset availability for use or ownership. */
  periodType?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Specifies the applicability of the term to an asset resource instance, and
   * instances it refers to orinstances that refer to it, and/or are owned by the
   * offeree.
   */
  relationship?: Maybe<Coding>;
  /** Differentiates the kind of the asset . */
  scope?: Maybe<CodeableConcept>;
  /** Security labels that protects the asset. */
  securityLabelNumber?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** May be a subtype or part of an offered asset. */
  subtype?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Clause or question text (Prose Object) concerning the asset in a linked form,
   * such as a QuestionnaireResponse used in the formation of the contract.
   */
  text?: Maybe<Scalars['code']>;
  /** Target entity type about which the term may be concerned. */
  type?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Associated entities. */
  typeReference?: Maybe<Array<Maybe<Resource>>>;
  /** Time period of asset use. */
  usePeriod?: Maybe<Array<Maybe<Period>>>;
  /** Contract Valued Item List. */
  valuedItem?: Maybe<Array<Maybe<ContractValuedItem>>>;
};

export type ContractAuthor = Organization | Patient | Practitioner | PractitionerRole;

/**
 * Contract.ContentDefinition
 *     Legally enforceable, formally recorded unilateral or bilateral directive i.e.,
 *     a policy or agreement.
 */
export type ContractContentDefinition = {
  __typename?: 'ContractContentDefinition';
  /**
   * A copyright statement relating to Contract precursor content. Copyright
   * statements are generally legal restrictions on the use and publishing of the
   * Contract precursor content.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The date (and optionally time) when the contract was published. The date must
   * change when the business version changes and it must change if the status code
   * changes. In addition, it should change when the substantive content of the
   * contract changes.
   */
  publicationDate?: Maybe<Scalars['code']>;
  /**
   * amended | appended | cancelled | disputed | entered-in-error | executable |
   * executed | negotiable | offered | policy | rejected | renewed | revoked |
   * resolved | terminated.
   */
  publicationStatus: Scalars['code'];
  /** The  individual or organization that published the Contract precursor content. */
  publisher?: Maybe<ContractContentDefinitionPublisher>;
  /** Detailed Precusory content type. */
  subType?: Maybe<CodeableConcept>;
  /**
   * Precusory content structure and use, i.e., a boilerplate, template,
   * application for a contract such as an insurance policy or benefits under a
   * program, e.g., workers compensation.
   */
  type: CodeableConcept;
};

export type ContractContentDefinitionPublisher = Organization | Practitioner | PractitionerRole;

/**
 * Contract.Context
 *     Legally enforceable, formally recorded unilateral or bilateral directive i.e.,
 *     a policy or agreement.
 */
export type ContractContext = {
  __typename?: 'ContractContext';
  /**
   * Coded representation of the context generally or of the Referenced entity,
   * such as the asset holder type or location.
   */
  code?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Asset context reference may include the creator, custodian, or owning Person
   * or Organization (e.g., bank, repository),  location held, e.g., building,
   * jurisdiction.
   */
  reference?: Maybe<Resource>;
  /** Context description. */
  text?: Maybe<Scalars['code']>;
};

/**
 * Contract.Friendly
 *     Legally enforceable, formally recorded unilateral or bilateral directive i.e.,
 *     a policy or agreement.
 */
export type ContractFriendly = {
  __typename?: 'ContractFriendly';
  /** None */
  contentAttachment?: Maybe<Attachment>;
  /** None */
  contentReference?: Maybe<ContractFriendlyContentReference>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type ContractFriendlyContentReference = Composition | DocumentReference | QuestionnaireResponse;

/**
 * Contract.Legal
 *     Legally enforceable, formally recorded unilateral or bilateral directive i.e.,
 *     a policy or agreement.
 */
export type ContractLegal = {
  __typename?: 'ContractLegal';
  /** None */
  contentAttachment?: Maybe<Attachment>;
  /** None */
  contentReference?: Maybe<ContractLegalContentReference>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type ContractLegalContentReference = Composition | DocumentReference | QuestionnaireResponse;

export type ContractLegallyBindingReference = Composition | Contract | DocumentReference | QuestionnaireResponse;

/**
 * Contract.Offer
 *     Legally enforceable, formally recorded unilateral or bilateral directive i.e.,
 *     a policy or agreement.
 */
export type ContractOffer = {
  __typename?: 'ContractOffer';
  /** Response to offer text. */
  answer?: Maybe<Array<Maybe<ContractAnswer>>>;
  /**
   * Type of choice made by accepting party with respect to an offer made by an
   * offeror/ grantee.
   */
  decision?: Maybe<CodeableConcept>;
  /** How the decision about a Contract was conveyed. */
  decisionMode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Unique identifier for this particular Contract Provision. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * The id of the clause or question text of the offer in the referenced
   * questionnaire/response.
   */
  linkId?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Offer Recipient. */
  party?: Maybe<Array<Maybe<ContractParty>>>;
  /** Security labels that protects the offer. */
  securityLabelNumber?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Human readable form of this Contract Offer. */
  text?: Maybe<Scalars['code']>;
  /**
   * The owner of an asset has the residual control rights over the asset: the
   * right to decide all usages of the asset in any way not inconsistent with a
   * prior contract, custom, or law (Hart, 1995, p. 30).
   */
  topic?: Maybe<Resource>;
  /**
   * Type of Contract Provision such as specific requirements, purposes for
   * actions, obligations, prohibitions, e.g. life time maximum benefit.
   */
  type?: Maybe<CodeableConcept>;
};

/**
 * Contract.Party
 *     Legally enforceable, formally recorded unilateral or bilateral directive i.e.,
 *     a policy or agreement.
 */
export type ContractParty = {
  __typename?: 'ContractParty';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Participant in the offer. */
  reference: Array<Maybe<ContractPartyReference>>;
  /** How the party participates in the offer. */
  role: CodeableConcept;
};

export type ContractPartyReference = Device | Group | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Contract.Rule
 *     Legally enforceable, formally recorded unilateral or bilateral directive i.e.,
 *     a policy or agreement.
 */
export type ContractRule = {
  __typename?: 'ContractRule';
  /** None */
  contentAttachment?: Maybe<Attachment>;
  /** None */
  contentReference?: Maybe<DocumentReference>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * Contract.SecurityLabel
 *     Legally enforceable, formally recorded unilateral or bilateral directive i.e.,
 *     a policy or agreement.
 */
export type ContractSecurityLabel = {
  __typename?: 'ContractSecurityLabel';
  /**
   * Security label privacy tag that species the applicable privacy and security
   * policies governing this term and/or term elements.
   */
  category?: Maybe<Array<Maybe<Coding>>>;
  /**
   * Security label privacy tag that species the level of confidentiality
   * protection required for this term and/or term elements.
   */
  classification: Coding;
  /**
   * Security label privacy tag that species the manner in which term and/or term
   * elements are to be protected.
   */
  control?: Maybe<Array<Maybe<Coding>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Number used to link this term or term element to the applicable Security
   * Label.
   */
  number?: Maybe<Array<Maybe<Scalars['code']>>>;
};

/**
 * Contract.Signer
 *     Legally enforceable, formally recorded unilateral or bilateral directive i.e.,
 *     a policy or agreement.
 */
export type ContractSigner = {
  __typename?: 'ContractSigner';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Party which is a signator to this Contract. */
  party: ContractSignerParty;
  /** Legally binding Contract DSIG signature contents in Base64. */
  signature: Array<Maybe<Signature>>;
  /** Role of this Contract signer, e.g. notary, grantee. */
  type: Coding;
};

export type ContractSignerParty = Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Contract.Subject
 *     Legally enforceable, formally recorded unilateral or bilateral directive i.e.,
 *     a policy or agreement.
 */
export type ContractSubject = {
  __typename?: 'ContractSubject';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The entity the action is performed or not performed on or for. */
  reference: Array<Maybe<ContractSubjectReference>>;
  /** Role type of agent assigned roles in this Contract. */
  role?: Maybe<CodeableConcept>;
};

export type ContractSubjectReference = Device | Group | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Contract.Term
 *     Legally enforceable, formally recorded unilateral or bilateral directive i.e.,
 *     a policy or agreement.
 */
export type ContractTerm = {
  __typename?: 'ContractTerm';
  /**
   * An actor taking a role in an activity for which it can be assigned some degree
   * of responsibility for the activity taking place.
   */
  action?: Maybe<Array<Maybe<ContractAction>>>;
  /** Relevant time or time-period when this Contract Provision is applicable. */
  applies?: Maybe<Period>;
  /** Contract Term Asset List. */
  asset?: Maybe<Array<Maybe<ContractAsset>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Nested group of Contract Provisions. */
  group?: Maybe<Array<Maybe<ContractTerm>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Unique identifier for this particular Contract Provision. */
  identifier?: Maybe<Identifier>;
  /** When this Contract Provision was issued. */
  issued?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The matter of concern in the context of this provision of the agrement. */
  offer: ContractOffer;
  /**
   * Security labels that protect the handling of information about the term and
   * its elements, which may be specifically identified..
   */
  securityLabel?: Maybe<Array<Maybe<ContractSecurityLabel>>>;
  /** A specialized legal clause or condition based on overarching contract type. */
  subType?: Maybe<CodeableConcept>;
  /** Statement of a provision in a policy or a contract. */
  text?: Maybe<Scalars['code']>;
  /** None */
  topicCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  topicReference?: Maybe<Resource>;
  /**
   * A legal clause or condition contained within a contract that requires one or
   * both parties to perform a particular requirement by some specified time or
   * prevents one or both parties from performing a particular requirement by some
   * specified time.
   */
  type?: Maybe<CodeableConcept>;
};

/**
 * Contract.ValuedItem
 *     Legally enforceable, formally recorded unilateral or bilateral directive i.e.,
 *     a policy or agreement.
 */
export type ContractValuedItem = {
  __typename?: 'ContractValuedItem';
  /**
   * Indicates the time during which this Contract ValuedItem information is
   * effective.
   */
  effectiveTime?: Maybe<Scalars['code']>;
  /** None */
  entityCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  entityReference?: Maybe<Resource>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A real number that represents a multiplier used in determining the overall
   * value of the Contract Valued Item delivered. The concept of a Factor allows
   * for a discount or surcharge multiplier to be applied to a monetary amount.
   */
  factor?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Identifies a Contract Valued Item instance. */
  identifier?: Maybe<Identifier>;
  /**
   * Id  of the clause or question text related to the context of this valuedItem
   * in the referenced form or QuestionnaireResponse.
   */
  linkId?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Expresses the product of the Contract Valued Item unitQuantity and the
   * unitPriceAmt. For example, the formula: unit Quantity * unit Price (Cost per
   * Point) * factor Number  * points = net Amount. Quantity, factor and points are
   * assumed to be 1 if not supplied.
   */
  net?: Maybe<Money>;
  /** Terms of valuation. */
  payment?: Maybe<Scalars['code']>;
  /** When payment is due. */
  paymentDate?: Maybe<Scalars['code']>;
  /**
   * An amount that expresses the weighting (based on difficulty, cost and/or
   * resource intensiveness) associated with the Contract Valued Item delivered.
   * The concept of Points allows for assignment of point values for a Contract
   * Valued Item, such that a monetary amount can be assigned to each point.
   */
  points?: Maybe<Scalars['code']>;
  /**
   * Specifies the units by which the Contract Valued Item is measured or counted,
   * and quantifies the countable or measurable Contract Valued Item instances.
   */
  quantity?: Maybe<Quantity>;
  /** Who will receive payment. */
  recipient?: Maybe<ContractValuedItemRecipient>;
  /** Who will make payment. */
  responsible?: Maybe<ContractValuedItemResponsible>;
  /**
   * A set of security labels that define which terms are controlled by this
   * condition.
   */
  securityLabelNumber?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** A Contract Valued Item unit valuation measure. */
  unitPrice?: Maybe<Money>;
};

export type ContractValuedItemRecipient = Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type ContractValuedItemResponsible = Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Contributor
 *     A contributor to the content of a knowledge asset, including authors, editors,
 *     reviewers, and endorsers.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Contributor = {
  __typename?: 'Contributor';
  /**
   * Contact details to assist a user in finding and communicating with the
   * contributor.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The name of the individual or organization responsible for the contribution. */
  name: Scalars['code'];
  /** The type of contributor. */
  type: Scalars['code'];
};

/**
 * Count
 *     A measured amount (or an amount that can potentially be measured). Note that
 *     measured amounts include amounts that are not precisely quantified, including
 *     amounts involving arbitrary units and floating currencies.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Count = {
  __typename?: 'Count';
  /** A computer processable form of the unit in some unit representation system. */
  code?: Maybe<Scalars['code']>;
  /**
   * How the value should be understood and represented - whether the actual value
   * is greater or less than the stated value due to measurement issues; e.g. if
   * the comparator is "<" , then the real value is < stated value.
   */
  comparator?: Maybe<Scalars['code']>;
  /** The identification of the system that provides the coded form of the unit. */
  system?: Maybe<Scalars['code']>;
  /** A human-readable form of the unit. */
  unit?: Maybe<Scalars['code']>;
  /**
   * The value of the measured amount. The value includes an implicit precision in
   * the presentation of the value.
   */
  value?: Maybe<Scalars['code']>;
};

/**
 * Coverage
 *     Financial instrument which may be used to reimburse or pay for health care
 *     products and services. Includes both insurance and self-payment.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Coverage = {
  __typename?: 'Coverage';
  /**
   * The party who benefits from the insurance coverage; the patient when products
   * and/or services are provided.
   */
  beneficiary: Patient;
  /** A suite of underwriter specific classifiers. */
  class?: Maybe<Array<Maybe<CoverageClass>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The policy(s) which constitute this insurance coverage. */
  contract?: Maybe<Array<Maybe<Contract>>>;
  /**
   * A suite of codes indicating the cost category and associated amount which have
   * been detailed in the policy and may have been  included on the health card.
   */
  costToBeneficiary?: Maybe<Array<Maybe<CoverageCostToBeneficiary>>>;
  /** A unique identifier for a dependent under the coverage. */
  dependent?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** A unique identifier assigned to this coverage. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The insurer-specific identifier for the insurer-defined network of providers
   * to which the beneficiary may seek treatment which will be covered at the 'in-
   * network' rate, otherwise 'out of network' terms and conditions apply.
   */
  network?: Maybe<Scalars['code']>;
  /**
   * The order of applicability of this coverage relative to other coverages which
   * are currently in force. Note, there may be gaps in the numbering and this does
   * not imply primary, secondary etc. as the specific positioning of coverages
   * depends upon the episode of care.
   */
  order?: Maybe<Scalars['code']>;
  /**
   * The program or plan underwriter or payor including both insurance and non-
   * insurance agreements, such as patient-pay agreements.
   */
  payor: Array<Maybe<CoveragePayor>>;
  /**
   * Time period during which the coverage is in force. A missing start date
   * indicates the start date isn't known, a missing end date means the coverage is
   * continuing to be in force.
   */
  period?: Maybe<Period>;
  /** The party who 'owns' the insurance policy. */
  policyHolder?: Maybe<CoveragePolicyHolder>;
  /** The relationship of beneficiary (patient) to the subscriber. */
  relationship?: Maybe<CodeableConcept>;
  resourceType?: Maybe<Scalars['String']>;
  /** The status of the resource instance. */
  status: Scalars['code'];
  /**
   * When 'subrogation=true' this insurance instance has been included not for
   * adjudication but to provide insurers with the details to recover costs.
   */
  subrogation?: Maybe<Scalars['code']>;
  /**
   * The party who has signed-up for or 'owns' the contractual relationship to the
   * policy or to whom the benefit of the policy for services rendered to them or
   * their family is due.
   */
  subscriber?: Maybe<CoverageSubscriber>;
  /** The insurer assigned ID for the Subscriber. */
  subscriberId?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * The type of coverage: social program, medical plan, accident coverage (workers
   * compensation, auto), group health or payment by an individual or organization.
   */
  type?: Maybe<CodeableConcept>;
};

/**
 * Coverage.Class
 *     Financial instrument which may be used to reimburse or pay for health care
 *     products and services. Includes both insurance and self-payment.
 */
export type CoverageClass = {
  __typename?: 'CoverageClass';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A short description for the class. */
  name?: Maybe<Scalars['code']>;
  /**
   * The type of classification for which an insurer-specific class label or number
   * and optional name is provided, for example may be used to identify a class of
   * coverage or employer group, Policy, Plan.
   */
  type: CodeableConcept;
  /** The alphanumeric string value associated with the insurer issued label. */
  value: Scalars['code'];
};

/**
 * Coverage.CostToBeneficiary
 *     Financial instrument which may be used to reimburse or pay for health care
 *     products and services. Includes both insurance and self-payment.
 */
export type CoverageCostToBeneficiary = {
  __typename?: 'CoverageCostToBeneficiary';
  /**
   * A suite of codes indicating exceptions or reductions to patient costs and
   * their effective periods.
   */
  exception?: Maybe<Array<Maybe<CoverageException>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The category of patient centric costs associated with treatment. */
  type?: Maybe<CodeableConcept>;
  /** None */
  valueMoney?: Maybe<Money>;
  /** None */
  valueQuantity?: Maybe<Quantity>;
};

/**
 * CoverageEligibilityRequest
 *     The CoverageEligibilityRequest provides patient and insurance coverage
 *     information to an insurer for them to respond, in the form of an
 *     CoverageEligibilityResponse, with information regarding whether the stated
 *     coverage is valid and in-force and optionally to provide the insurance details
 *     of the policy.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type CoverageEligibilityRequest = {
  __typename?: 'CoverageEligibilityRequest';
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The date when this resource was created. */
  created: Scalars['code'];
  /** Person who created the request. */
  enterer?: Maybe<CoverageEligibilityRequestEnterer>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Facility where the services are intended to be provided. */
  facility?: Maybe<Location>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** A unique identifier assigned to this coverage eligiblity request. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * Financial instruments for reimbursement for the health care products and
   * services.
   */
  insurance?: Maybe<Array<Maybe<CoverageEligibilityRequestInsurance>>>;
  /**
   * The Insurer who issued the coverage in question and is the recipient of the
   * request.
   */
  insurer: Organization;
  /**
   * Service categories or billable services for which benefit details and/or an
   * authorization prior to service delivery may be required by the payor.
   */
  item?: Maybe<Array<Maybe<CoverageEligibilityRequestItem>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The party who is the beneficiary of the supplied coverage and for whom
   * eligibility is sought.
   */
  patient: Patient;
  /** When the requestor expects the processor to complete processing. */
  priority?: Maybe<CodeableConcept>;
  /** The provider which is responsible for the request. */
  provider?: Maybe<CoverageEligibilityRequestProvider>;
  /**
   * Code to specify whether requesting: prior authorization requirements for some
   * service categories or billing codes; benefits for coverages specified or
   * discovered; discovery and return of coverages for the patient; and/or
   * validation that the specified coverage is in-force at the date/period
   * specified or 'now' if not specified.
   */
  purpose: Array<Maybe<Scalars['code']>>;
  resourceType?: Maybe<Scalars['String']>;
  /** None */
  servicedDate?: Maybe<Scalars['code']>;
  /** None */
  servicedPeriod?: Maybe<Period>;
  /** The status of the resource instance. */
  status: Scalars['code'];
  /**
   * Additional information codes regarding exceptions, special considerations, the
   * condition, situation, prior or concurrent issues.
   */
  supportingInfo?: Maybe<Array<Maybe<CoverageEligibilityRequestSupportingInfo>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * CoverageEligibilityRequest.Diagnosis
 *     The CoverageEligibilityRequest provides patient and insurance coverage
 *     information to an insurer for them to respond, in the form of an
 *     CoverageEligibilityResponse, with information regarding whether the stated
 *     coverage is valid and in-force and optionally to provide the insurance details
 *     of the policy.
 */
export type CoverageEligibilityRequestDiagnosis = {
  __typename?: 'CoverageEligibilityRequestDiagnosis';
  /** None */
  diagnosisCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  diagnosisReference?: Maybe<Condition>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type CoverageEligibilityRequestEnterer = Practitioner | PractitionerRole;

/**
 * CoverageEligibilityRequest.Insurance
 *     The CoverageEligibilityRequest provides patient and insurance coverage
 *     information to an insurer for them to respond, in the form of an
 *     CoverageEligibilityResponse, with information regarding whether the stated
 *     coverage is valid and in-force and optionally to provide the insurance details
 *     of the policy.
 */
export type CoverageEligibilityRequestInsurance = {
  __typename?: 'CoverageEligibilityRequestInsurance';
  /**
   * A business agreement number established between the provider and the insurer
   * for special business processing purposes.
   */
  businessArrangement?: Maybe<Scalars['code']>;
  /**
   * Reference to the insurance card level information contained in the Coverage
   * resource. The coverage issuing insurer will use these details to locate the
   * patient's actual coverage within the insurer's information system.
   */
  coverage: Coverage;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A flag to indicate that this Coverage is to be used for evaluation of this
   * request when set to true.
   */
  focal?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * CoverageEligibilityRequest.Item
 *     The CoverageEligibilityRequest provides patient and insurance coverage
 *     information to an insurer for them to respond, in the form of an
 *     CoverageEligibilityResponse, with information regarding whether the stated
 *     coverage is valid and in-force and optionally to provide the insurance details
 *     of the policy.
 */
export type CoverageEligibilityRequestItem = {
  __typename?: 'CoverageEligibilityRequestItem';
  /**
   * Code to identify the general type of benefits under which products and
   * services are provided.
   */
  category?: Maybe<CodeableConcept>;
  /** The plan/proposal/order describing the proposed service in detail. */
  detail?: Maybe<Array<Maybe<Resource>>>;
  /** Patient diagnosis for which care is sought. */
  diagnosis?: Maybe<Array<Maybe<CoverageEligibilityRequestDiagnosis>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Facility where the services will be provided. */
  facility?: Maybe<CoverageEligibilityRequestItemFacility>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Item typification or modifiers codes to convey additional context for the
   * product or service.
   */
  modifier?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** This contains the product, service, drug or other billing code for the item. */
  productOrService?: Maybe<CodeableConcept>;
  /**
   * The practitioner who is responsible for the product or service to be rendered
   * to the patient.
   */
  provider?: Maybe<CoverageEligibilityRequestItemProvider>;
  /** The number of repetitions of a service or product. */
  quantity?: Maybe<Quantity>;
  /**
   * Exceptions, special conditions and supporting information applicable for this
   * service or product line.
   */
  supportingInfoSequence?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The amount charged to the patient by the provider for a single unit. */
  unitPrice?: Maybe<Money>;
};

export type CoverageEligibilityRequestItemFacility = Location | Organization;

export type CoverageEligibilityRequestItemProvider = Practitioner | PractitionerRole;

export type CoverageEligibilityRequestProvider = Organization | Practitioner | PractitionerRole;

/**
 * CoverageEligibilityRequest.SupportingInfo
 *     The CoverageEligibilityRequest provides patient and insurance coverage
 *     information to an insurer for them to respond, in the form of an
 *     CoverageEligibilityResponse, with information regarding whether the stated
 *     coverage is valid and in-force and optionally to provide the insurance details
 *     of the policy.
 */
export type CoverageEligibilityRequestSupportingInfo = {
  __typename?: 'CoverageEligibilityRequestSupportingInfo';
  /**
   * The supporting materials are applicable for all detail items, product/servce
   * categories and specific billing codes.
   */
  appliesToAll?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Additional data or information such as resources, documents, images etc.
   * including references to the data or the actual inclusion of the data.
   */
  information: Resource;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A number to uniquely identify supporting information entries. */
  sequence: Scalars['code'];
};

/**
 * CoverageEligibilityResponse
 *     This resource provides eligibility and plan details from the processing of an
 *     CoverageEligibilityRequest resource.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type CoverageEligibilityResponse = {
  __typename?: 'CoverageEligibilityResponse';
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The date this resource was created. */
  created: Scalars['code'];
  /** A human readable description of the status of the adjudication. */
  disposition?: Maybe<Scalars['code']>;
  /** Errors encountered during the processing of the request. */
  error?: Maybe<Array<Maybe<CoverageEligibilityResponseError>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** A code for the form to be used for printing the content. */
  form?: Maybe<CodeableConcept>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** A unique identifier assigned to this coverage eligiblity request. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * Financial instruments for reimbursement for the health care products and
   * services.
   */
  insurance?: Maybe<Array<Maybe<CoverageEligibilityResponseInsurance>>>;
  /**
   * The Insurer who issued the coverage in question and is the author of the
   * response.
   */
  insurer: Organization;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The outcome of the request processing. */
  outcome: Scalars['code'];
  /**
   * The party who is the beneficiary of the supplied coverage and for whom
   * eligibility is sought.
   */
  patient: Patient;
  /**
   * A reference from the Insurer to which these services pertain to be used on
   * further communication and as proof that the request occurred.
   */
  preAuthRef?: Maybe<Scalars['code']>;
  /**
   * Code to specify whether requesting: prior authorization requirements for some
   * service categories or billing codes; benefits for coverages specified or
   * discovered; discovery and return of coverages for the patient; and/or
   * validation that the specified coverage is in-force at the date/period
   * specified or 'now' if not specified.
   */
  purpose: Array<Maybe<Scalars['code']>>;
  /** Reference to the original request resource. */
  request: CoverageEligibilityRequest;
  /** The provider which is responsible for the request. */
  requestor?: Maybe<CoverageEligibilityResponseRequestor>;
  resourceType?: Maybe<Scalars['String']>;
  /** None */
  servicedDate?: Maybe<Scalars['code']>;
  /** None */
  servicedPeriod?: Maybe<Period>;
  /** The status of the resource instance. */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * CoverageEligibilityResponse.Benefit
 *     This resource provides eligibility and plan details from the processing of an
 *     CoverageEligibilityRequest resource.
 */
export type CoverageEligibilityResponseBenefit = {
  __typename?: 'CoverageEligibilityResponseBenefit';
  /** None */
  allowedMoney?: Maybe<Money>;
  /** None */
  allowedString?: Maybe<Scalars['code']>;
  /** None */
  allowedUnsignedInt?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Classification of benefit being provided. */
  type: CodeableConcept;
  /** None */
  usedMoney?: Maybe<Money>;
  /** None */
  usedString?: Maybe<Scalars['code']>;
  /** None */
  usedUnsignedInt?: Maybe<Scalars['code']>;
};

/**
 * CoverageEligibilityResponse.Error
 *     This resource provides eligibility and plan details from the processing of an
 *     CoverageEligibilityRequest resource.
 */
export type CoverageEligibilityResponseError = {
  __typename?: 'CoverageEligibilityResponseError';
  /**
   * An error code,from a specified code system, which details why the eligibility
   * check could not be performed.
   */
  code: CodeableConcept;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * CoverageEligibilityResponse.Insurance
 *     This resource provides eligibility and plan details from the processing of an
 *     CoverageEligibilityRequest resource.
 */
export type CoverageEligibilityResponseInsurance = {
  __typename?: 'CoverageEligibilityResponseInsurance';
  /** The term of the benefits documented in this response. */
  benefitPeriod?: Maybe<Period>;
  /**
   * Reference to the insurance card level information contained in the Coverage
   * resource. The coverage issuing insurer will use these details to locate the
   * patient's actual coverage within the insurer's information system.
   */
  coverage: Coverage;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Flag indicating if the coverage provided is inforce currently if no service
   * date(s) specified or for the whole duration of the service dates.
   */
  inforce?: Maybe<Scalars['code']>;
  /**
   * Benefits and optionally current balances, and authorization details by
   * category or service.
   */
  item?: Maybe<Array<Maybe<CoverageEligibilityResponseItem>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * CoverageEligibilityResponse.Item
 *     This resource provides eligibility and plan details from the processing of an
 *     CoverageEligibilityRequest resource.
 */
export type CoverageEligibilityResponseItem = {
  __typename?: 'CoverageEligibilityResponseItem';
  /**
   * A boolean flag indicating whether a preauthorization is required prior to
   * actual service delivery.
   */
  authorizationRequired?: Maybe<Scalars['code']>;
  /**
   * Codes or comments regarding information or actions associated with the
   * preauthorization.
   */
  authorizationSupporting?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * A web location for obtaining requirements or descriptive information regarding
   * the preauthorization.
   */
  authorizationUrl?: Maybe<Scalars['code']>;
  /** Benefits used to date. */
  benefit?: Maybe<Array<Maybe<CoverageEligibilityResponseBenefit>>>;
  /**
   * Code to identify the general type of benefits under which products and
   * services are provided.
   */
  category?: Maybe<CodeableConcept>;
  /** A richer description of the benefit or services covered. */
  description?: Maybe<Scalars['code']>;
  /**
   * True if the indicated class of service is excluded from the plan, missing or
   * False indicates the product or service is included in the coverage.
   */
  excluded?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Item typification or modifiers codes to convey additional context for the
   * product or service.
   */
  modifier?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A short name or tag for the benefit. */
  name?: Maybe<Scalars['code']>;
  /**
   * Is a flag to indicate whether the benefits refer to in-network providers or
   * out-of-network providers.
   */
  network?: Maybe<CodeableConcept>;
  /** This contains the product, service, drug or other billing code for the item. */
  productOrService?: Maybe<CodeableConcept>;
  /** The practitioner who is eligible for the provision of the product or service. */
  provider?: Maybe<CoverageEligibilityResponseItemProvider>;
  /**
   * The term or period of the values such as 'maximum lifetime benefit' or
   * 'maximum annual visits'.
   */
  term?: Maybe<CodeableConcept>;
  /** Indicates if the benefits apply to an individual or to the family. */
  unit?: Maybe<CodeableConcept>;
};

export type CoverageEligibilityResponseItemProvider = Practitioner | PractitionerRole;

export type CoverageEligibilityResponseRequestor = Organization | Practitioner | PractitionerRole;

/**
 * Coverage.Exception
 *     Financial instrument which may be used to reimburse or pay for health care
 *     products and services. Includes both insurance and self-payment.
 */
export type CoverageException = {
  __typename?: 'CoverageException';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The timeframe during when the exception is in force. */
  period?: Maybe<Period>;
  /** The code for the specific exception. */
  type: CodeableConcept;
};

export type CoveragePayor = Organization | Patient | RelatedPerson;

export type CoveragePolicyHolder = Organization | Patient | RelatedPerson;

export type CoverageSubscriber = Patient | RelatedPerson;

/**
 * DataRequirement
 *     Describes a required data item for evaluation in terms of the type of data,
 *     and optional code or date-based filters of the data.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type DataRequirement = {
  __typename?: 'DataRequirement';
  /**
   * Code filters specify additional constraints on the data, specifying the value
   * set of interest for a particular element of the data. Each code filter defines
   * an additional constraint on the data, i.e. code filters are AND'ed, not OR'ed.
   */
  codeFilter?: Maybe<Array<Maybe<DataRequirementCodeFilter>>>;
  /**
   * Date filters specify additional constraints on the data in terms of the
   * applicable date range for specific elements. Each date filter specifies an
   * additional constraint on the data, i.e. date filters are AND'ed, not OR'ed.
   */
  dateFilter?: Maybe<Array<Maybe<DataRequirementDateFilter>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Specifies a maximum number of results that are required (uses the _count
   * search parameter).
   */
  limit?: Maybe<Scalars['code']>;
  /**
   * Indicates that specific elements of the type are referenced by the knowledge
   * module and must be supported by the consumer in order to obtain an effective
   * evaluation. This does not mean that a value is required for this element, only
   * that the consuming system must understand the element and be able to provide
   * values for it if they are available.
   *
   * The value of mustSupport SHALL be a FHIRPath resolveable on the type of the
   * DataRequirement. The path SHALL consist only of identifiers, constant
   * indexers, and .resolve() (see the [Simple FHIRPath
   * Profile](fhirpath.html#simple) for full details).
   */
  mustSupport?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The profile of the required data, specified as the uri of the profile
   * definition.
   */
  profile?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Specifies the order of the results to be returned. */
  sort?: Maybe<Array<Maybe<DataRequirementSort>>>;
  /** None */
  subjectCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  subjectReference?: Maybe<Group>;
  /**
   * The type of the required data, specified as the type name of a resource. For
   * profiles, this value is set to the type of the base resource of the profile.
   */
  type: Scalars['code'];
};

/**
 * DataRequirement.CodeFilter
 *     Describes a required data item for evaluation in terms of the type of data,
 *     and optional code or date-based filters of the data.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type DataRequirementCodeFilter = {
  __typename?: 'DataRequirementCodeFilter';
  /**
   * The codes for the code filter. If values are given, the filter will return
   * only those data items for which the code-valued attribute specified by the
   * path has a value that is one of the specified codes. If codes are specified in
   * addition to a value set, the filter returns items matching a code in the value
   * set or one of the specified codes.
   */
  code?: Maybe<Array<Maybe<Coding>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The code-valued attribute of the filter. The specified path SHALL be a
   * FHIRPath resolveable on the specified type of the DataRequirement, and SHALL
   * consist only of identifiers, constant indexers, and .resolve(). The path is
   * allowed to contain qualifiers (.) to traverse sub-elements, as well as
   * indexers ([x]) to traverse multiple-cardinality sub-elements (see the [Simple
   * FHIRPath Profile](fhirpath.html#simple) for full details). Note that the index
   * must be an integer constant. The path must resolve to an element of type code,
   * Coding, or CodeableConcept.
   */
  path?: Maybe<Scalars['code']>;
  /**
   * A token parameter that refers to a search parameter defined on the specified
   * type of the DataRequirement, and which searches on elements of type code,
   * Coding, or CodeableConcept.
   */
  searchParam?: Maybe<Scalars['code']>;
  /**
   * The valueset for the code filter. The valueSet and code elements are additive.
   * If valueSet is specified, the filter will return only those data items for
   * which the value of the code-valued element specified in the path is a member
   * of the specified valueset.
   */
  valueSet?: Maybe<Scalars['code']>;
};

/**
 * DataRequirement.DateFilter
 *     Describes a required data item for evaluation in terms of the type of data,
 *     and optional code or date-based filters of the data.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type DataRequirementDateFilter = {
  __typename?: 'DataRequirementDateFilter';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The date-valued attribute of the filter. The specified path SHALL be a
   * FHIRPath resolveable on the specified type of the DataRequirement, and SHALL
   * consist only of identifiers, constant indexers, and .resolve(). The path is
   * allowed to contain qualifiers (.) to traverse sub-elements, as well as
   * indexers ([x]) to traverse multiple-cardinality sub-elements (see the [Simple
   * FHIRPath Profile](fhirpath.html#simple) for full details). Note that the index
   * must be an integer constant. The path must resolve to an element of type date,
   * dateTime, Period, Schedule, or Timing.
   */
  path?: Maybe<Scalars['code']>;
  /**
   * A date parameter that refers to a search parameter defined on the specified
   * type of the DataRequirement, and which searches on elements of type date,
   * dateTime, Period, Schedule, or Timing.
   */
  searchParam?: Maybe<Scalars['code']>;
  /** None */
  valueDateTime?: Maybe<Scalars['code']>;
  /** None */
  valueDuration?: Maybe<Quantity>;
  /** None */
  valuePeriod?: Maybe<Period>;
};

/**
 * DataRequirement.Sort
 *     Describes a required data item for evaluation in terms of the type of data,
 *     and optional code or date-based filters of the data.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type DataRequirementSort = {
  __typename?: 'DataRequirementSort';
  /** The direction of the sort, ascending or descending. */
  direction: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The attribute of the sort. The specified path must be resolvable from the type
   * of the required data. The path is allowed to contain qualifiers (.) to
   * traverse sub-elements, as well as indexers ([x]) to traverse multiple-
   * cardinality sub-elements. Note that the index must be an integer constant.
   */
  path: Scalars['code'];
};

/**
 * DetectedIssue
 *     Indicates an actual or potential clinical issue with or between one or more
 *     active or proposed clinical actions for a patient; e.g. Drug-drug interaction,
 *     Ineffective treatment frequency, Procedure-condition conflict, etc.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type DetectedIssue = {
  __typename?: 'DetectedIssue';
  /**
   * Individual or device responsible for the issue being raised.  For example, a
   * decision support application or a pharmacist conducting a medication review.
   */
  author?: Maybe<DetectedIssueAuthor>;
  /** Identifies the general type of issue identified. */
  code?: Maybe<CodeableConcept>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** A textual explanation of the detected issue. */
  detail?: Maybe<Scalars['code']>;
  /**
   * Supporting evidence or manifestations that provide the basis for identifying
   * the detected issue such as a GuidanceResponse or MeasureReport.
   */
  evidence?: Maybe<Array<Maybe<DetectedIssueEvidence>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** None */
  identifiedDateTime?: Maybe<Scalars['code']>;
  /** None */
  identifiedPeriod?: Maybe<Period>;
  /** Business identifier associated with the detected issue record. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * Indicates the resource representing the current activity or proposed activity
   * that is potentially problematic.
   */
  implicated?: Maybe<Array<Maybe<Resource>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * Indicates an action that has been taken or is committed to reduce or eliminate
   * the likelihood of the risk identified by the detected issue from manifesting.
   * Can also reflect an observation of known mitigating factors that may
   * reduce/eliminate the need for any action.
   */
  mitigation?: Maybe<Array<Maybe<DetectedIssueMitigation>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Indicates the patient whose record the detected issue is associated with. */
  patient?: Maybe<Patient>;
  /**
   * The literature, knowledge-base or similar reference that describes the
   * propensity for the detected issue identified.
   */
  reference?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * Indicates the degree of importance associated with the identified issue based
   * on the potential impact on the patient.
   */
  severity?: Maybe<Scalars['code']>;
  /** Indicates the status of the detected issue. */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type DetectedIssueAuthor = Device | Practitioner | PractitionerRole;

/**
 * DetectedIssue.Evidence
 *     Indicates an actual or potential clinical issue with or between one or more
 *     active or proposed clinical actions for a patient; e.g. Drug-drug interaction,
 *     Ineffective treatment frequency, Procedure-condition conflict, etc.
 */
export type DetectedIssueEvidence = {
  __typename?: 'DetectedIssueEvidence';
  /** A manifestation that led to the recording of this detected issue. */
  code?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Links to resources that constitute evidence for the detected issue such as a
   * GuidanceResponse or MeasureReport.
   */
  detail?: Maybe<Array<Maybe<Resource>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * DetectedIssue.Mitigation
 *     Indicates an actual or potential clinical issue with or between one or more
 *     active or proposed clinical actions for a patient; e.g. Drug-drug interaction,
 *     Ineffective treatment frequency, Procedure-condition conflict, etc.
 */
export type DetectedIssueMitigation = {
  __typename?: 'DetectedIssueMitigation';
  /**
   * Describes the action that was taken or the observation that was made that
   * reduces/eliminates the risk associated with the identified issue.
   */
  action: CodeableConcept;
  /**
   * Identifies the practitioner who determined the mitigation and takes
   * responsibility for the mitigation step occurring.
   */
  author?: Maybe<DetectedIssueMitigationAuthor>;
  /** Indicates when the mitigating action was documented. */
  date?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type DetectedIssueMitigationAuthor = Practitioner | PractitionerRole;

/**
 * Device
 *     A type of a manufactured item that is used in the provision of healthcare
 *     without being substantially changed through that activity. The device may be a
 *     medical or non-medical device.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Device = {
  __typename?: 'Device';
  /**
   * Contact details for an organization or a particular human that is responsible
   * for the device.
   */
  contact?: Maybe<Array<Maybe<ContactPoint>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The reference to the definition for the device. */
  definition?: Maybe<DeviceDefinition>;
  /**
   * This represents the manufacturer's name of the device as provided by the
   * device, from a UDI label, or by a person describing the Device.  This
   * typically would be used when a person provides the name(s) or when the device
   * represents one of the names available from DeviceDefinition.
   */
  deviceName?: Maybe<Array<Maybe<DeviceDeviceName>>>;
  /**
   * The distinct identification string as required by regulation for a human cell,
   * tissue, or cellular and tissue-based product.
   */
  distinctIdentifier?: Maybe<Scalars['code']>;
  /**
   * The date and time beyond which this device is no longer valid or should not be
   * used (if applicable).
   */
  expirationDate?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Unique instance identifiers assigned to a device by manufacturers other
   * organizations or owners.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** The place where the device can be found. */
  location?: Maybe<Location>;
  /** Lot number assigned by the manufacturer. */
  lotNumber?: Maybe<Scalars['code']>;
  /** The date and time when the device was manufactured. */
  manufactureDate?: Maybe<Scalars['code']>;
  /** A name of the manufacturer. */
  manufacturer?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /** The model number for the device. */
  modelNumber?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Descriptive information, usage information or implantation information that is
   * not captured in an existing element.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /**
   * An organization that is responsible for the provision and ongoing maintenance
   * of the device.
   */
  owner?: Maybe<Organization>;
  /** The parent device. */
  parent?: Maybe<Device>;
  /** The part number of the device. */
  partNumber?: Maybe<Scalars['code']>;
  /** Patient information, If the device is affixed to a person. */
  patient?: Maybe<Patient>;
  /**
   * The actual configuration settings of a device as it actually operates, e.g.,
   * regulation status, time properties.
   */
  property?: Maybe<Array<Maybe<DeviceProperty>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * Provides additional safety characteristics about a medical device.  For
   * example devices containing latex.
   */
  safety?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The serial number assigned by the organization when the device was
   * manufactured.
   */
  serialNumber?: Maybe<Scalars['code']>;
  /**
   * The capabilities supported on a  device, the standards to which the device
   * conforms for a particular purpose, and used for the communication.
   */
  specialization?: Maybe<Array<Maybe<DeviceSpecialization>>>;
  /** Status of the Device availability. */
  status?: Maybe<Scalars['code']>;
  /** Reason for the dtatus of the Device availability. */
  statusReason?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** The kind or type of device. */
  type?: Maybe<CodeableConcept>;
  /**
   * Unique device identifier (UDI) assigned to device label or package.  Note that
   * the Device may include multiple udiCarriers as it either may include just the
   * udiCarrier for the jurisdiction it is sold, or for multiple jurisdictions it
   * could have been sold.
   */
  udiCarrier?: Maybe<Array<Maybe<DeviceUdiCarrier>>>;
  /** A network address on which the device may be contacted directly. */
  url?: Maybe<Scalars['code']>;
  /** The actual design of the device or software version running on the device. */
  version?: Maybe<Array<Maybe<DeviceVersion>>>;
};

/**
 * DeviceDefinition
 *     The characteristics, operational status and capabilities of a medical-related
 *     component of a medical device.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type DeviceDefinition = {
  __typename?: 'DeviceDefinition';
  /** Device capabilities. */
  capability?: Maybe<Array<Maybe<DeviceDefinitionCapability>>>;
  /**
   * Contact details for an organization or a particular human that is responsible
   * for the device.
   */
  contact?: Maybe<Array<Maybe<ContactPoint>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** A name given to the device to identify it. */
  deviceName?: Maybe<Array<Maybe<DeviceDefinitionDeviceName>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Unique instance identifiers assigned to a device by the software,
   * manufacturers, other organizations or owners. For example: handle ID.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * Language code for the human-readable text strings produced by the device (all
   * supported).
   */
  languageCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** None */
  manufacturerReference?: Maybe<Organization>;
  /** None */
  manufacturerString?: Maybe<Scalars['code']>;
  /** A substance used to create the material(s) of which the device is made. */
  material?: Maybe<Array<Maybe<DeviceDefinitionMaterial>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /** The model number for the device. */
  modelNumber?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Descriptive information, usage information or implantation information that is
   * not captured in an existing element.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** Access to on-line information about the device. */
  onlineInformation?: Maybe<Scalars['code']>;
  /**
   * An organization that is responsible for the provision and ongoing maintenance
   * of the device.
   */
  owner?: Maybe<Organization>;
  /** The parent device it can be part of. */
  parentDevice?: Maybe<DeviceDefinition>;
  /** Dimensions, color etc. */
  physicalCharacteristics?: Maybe<ProdCharacteristic>;
  /**
   * The actual configuration settings of a device as it actually operates, e.g.,
   * regulation status, time properties.
   */
  property?: Maybe<Array<Maybe<DeviceDefinitionProperty>>>;
  /**
   * The quantity of the device present in the packaging (e.g. the number of
   * devices present in a pack, or the number of devices in the same package of the
   * medicinal product).
   */
  quantity?: Maybe<Quantity>;
  resourceType?: Maybe<Scalars['String']>;
  /** Safety characteristics of the device. */
  safety?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Shelf Life and storage information. */
  shelfLifeStorage?: Maybe<Array<Maybe<ProductShelfLife>>>;
  /**
   * The capabilities supported on a  device, the standards to which the device
   * conforms for a particular purpose, and used for the communication.
   */
  specialization?: Maybe<Array<Maybe<DeviceDefinitionSpecialization>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** What kind of device or device system this is. */
  type?: Maybe<CodeableConcept>;
  /**
   * Unique device identifier (UDI) assigned to device label or package.  Note that
   * the Device may include multiple udiCarriers as it either may include just the
   * udiCarrier for the jurisdiction it is sold, or for multiple jurisdictions it
   * could have been sold.
   */
  udiDeviceIdentifier?: Maybe<Array<Maybe<DeviceDefinitionUdiDeviceIdentifier>>>;
  /** A network address on which the device may be contacted directly. */
  url?: Maybe<Scalars['code']>;
  /** The available versions of the device, e.g., software versions. */
  version?: Maybe<Array<Maybe<Scalars['code']>>>;
};

/**
 * DeviceDefinition.Capability
 *     The characteristics, operational status and capabilities of a medical-related
 *     component of a medical device.
 */
export type DeviceDefinitionCapability = {
  __typename?: 'DeviceDefinitionCapability';
  /** Description of capability. */
  description?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Type of capability. */
  type: CodeableConcept;
};

/**
 * DeviceDefinition.DeviceName
 *     The characteristics, operational status and capabilities of a medical-related
 *     component of a medical device.
 */
export type DeviceDefinitionDeviceName = {
  __typename?: 'DeviceDefinitionDeviceName';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The name of the device. */
  name: Scalars['code'];
  /**
   * The type of deviceName.
   * UDILabelName | UserFriendlyName | PatientReportedName | ManufactureDeviceName
   * | ModelName.
   */
  type: Scalars['code'];
};

/**
 * DeviceDefinition.Material
 *     The characteristics, operational status and capabilities of a medical-related
 *     component of a medical device.
 */
export type DeviceDefinitionMaterial = {
  __typename?: 'DeviceDefinitionMaterial';
  /** Whether the substance is a known or suspected allergen. */
  allergenicIndicator?: Maybe<Scalars['code']>;
  /** Indicates an alternative material of the device. */
  alternate?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The substance. */
  substance: CodeableConcept;
};

/**
 * DeviceDefinition.Property
 *     The characteristics, operational status and capabilities of a medical-related
 *     component of a medical device.
 */
export type DeviceDefinitionProperty = {
  __typename?: 'DeviceDefinitionProperty';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Code that specifies the property DeviceDefinitionPropetyCode (Extensible). */
  type: CodeableConcept;
  /** Property value as a code, e.g., NTP4 (synced to NTP). */
  valueCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Property value as a quantity. */
  valueQuantity?: Maybe<Array<Maybe<Quantity>>>;
};

/**
 * DeviceDefinition.Specialization
 *     The characteristics, operational status and capabilities of a medical-related
 *     component of a medical device.
 */
export type DeviceDefinitionSpecialization = {
  __typename?: 'DeviceDefinitionSpecialization';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The standard that is used to operate and communicate. */
  systemType: Scalars['code'];
  /** The version of the standard that is used to operate and communicate. */
  version?: Maybe<Scalars['code']>;
};

/**
 * DeviceDefinition.UdiDeviceIdentifier
 *     The characteristics, operational status and capabilities of a medical-related
 *     component of a medical device.
 */
export type DeviceDefinitionUdiDeviceIdentifier = {
  __typename?: 'DeviceDefinitionUdiDeviceIdentifier';
  /**
   * The identifier that is to be associated with every Device that references this
   * DeviceDefintiion for the issuer and jurisdication porvided in the
   * DeviceDefinition.udiDeviceIdentifier.
   */
  deviceIdentifier: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The organization that assigns the identifier algorithm. */
  issuer: Scalars['code'];
  /** The jurisdiction to which the deviceIdentifier applies. */
  jurisdiction: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * Device.DeviceName
 *     A type of a manufactured item that is used in the provision of healthcare
 *     without being substantially changed through that activity. The device may be a
 *     medical or non-medical device.
 */
export type DeviceDeviceName = {
  __typename?: 'DeviceDeviceName';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The name of the device. */
  name: Scalars['code'];
  /**
   * The type of deviceName.
   * UDILabelName | UserFriendlyName | PatientReportedName | ManufactureDeviceName
   * | ModelName.
   */
  type: Scalars['code'];
};

/**
 * DeviceMetric
 *     Describes a measurement, calculation or setting capability of a medical
 *     device.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type DeviceMetric = {
  __typename?: 'DeviceMetric';
  /**
   * Describes the calibrations that have been performed or that are required to be
   * performed.
   */
  calibration?: Maybe<Array<Maybe<DeviceMetricCalibration>>>;
  /**
   * Indicates the category of the observation generation process. A DeviceMetric
   * can be for example a setting, measurement, or calculation.
   */
  category: Scalars['code'];
  /**
   * Describes the color representation for the metric. This is often used to aid
   * clinicians to track and identify parameter types by color. In practice,
   * consider a Patient Monitor that has ECG/HR and Pleth for example; the
   * parameters are displayed in different characteristic colors, such as HR-blue,
   * BP-green, and PR and SpO2- magenta.
   */
  color?: Maybe<Scalars['code']>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Unique instance identifiers assigned to a device by the device or gateway
   * software, manufacturers, other organizations or owners. For example: handle
   * ID.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * Describes the measurement repetition time. This is not necessarily the same as
   * the update period. The measurement repetition time can range from milliseconds
   * up to hours. An example for a measurement repetition time in the range of
   * milliseconds is the sampling rate of an ECG. An example for a measurement
   * repetition time in the range of hours is a NIBP that is triggered
   * automatically every hour. The update period may be different than the
   * measurement repetition time, if the device does not update the published
   * observed value with the same frequency as it was measured.
   */
  measurementPeriod?: Maybe<Timing>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Indicates current operational state of the device. For example: On, Off,
   * Standby, etc.
   */
  operationalStatus?: Maybe<Scalars['code']>;
  /**
   * Describes the link to the  Device that this DeviceMetric belongs to and that
   * provide information about the location of this DeviceMetric in the containment
   * structure of the parent Device. An example would be a Device that represents a
   * Channel. This reference can be used by a client application to distinguish
   * DeviceMetrics that have the same type, but should be interpreted based on
   * their containment location.
   */
  parent?: Maybe<Device>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * Describes the link to the  Device that this DeviceMetric belongs to and that
   * contains administrative device information such as manufacturer, serial
   * number, etc.
   */
  source?: Maybe<Device>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** Describes the type of the metric. For example: Heart Rate, PEEP Setting, etc. */
  type: CodeableConcept;
  /**
   * Describes the unit that an observed value determined for this metric will
   * have. For example: Percent, Seconds, etc.
   */
  unit?: Maybe<CodeableConcept>;
};

/**
 * DeviceMetric.Calibration
 *     Describes a measurement, calculation or setting capability of a medical
 *     device.
 */
export type DeviceMetricCalibration = {
  __typename?: 'DeviceMetricCalibration';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Describes the state of the calibration. */
  state?: Maybe<Scalars['code']>;
  /** Describes the time last calibration has been performed. */
  time?: Maybe<Scalars['code']>;
  /** Describes the type of the calibration method. */
  type?: Maybe<Scalars['code']>;
};

/**
 * Device.Property
 *     A type of a manufactured item that is used in the provision of healthcare
 *     without being substantially changed through that activity. The device may be a
 *     medical or non-medical device.
 */
export type DeviceProperty = {
  __typename?: 'DeviceProperty';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Code that specifies the property DeviceDefinitionPropetyCode (Extensible). */
  type: CodeableConcept;
  /** Property value as a code, e.g., NTP4 (synced to NTP). */
  valueCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Property value as a quantity. */
  valueQuantity?: Maybe<Array<Maybe<Quantity>>>;
};

/**
 * DeviceRequest
 *     Represents a request for a patient to employ a medical device. The device may
 *     be an implantable device, or an external assistive device, such as a walker.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type DeviceRequest = {
  __typename?: 'DeviceRequest';
  /** When the request transitioned to being actionable. */
  authoredOn?: Maybe<Scalars['code']>;
  /** Plan/proposal/order fulfilled by this request. */
  basedOn?: Maybe<Array<Maybe<Resource>>>;
  /** None */
  codeCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  codeReference?: Maybe<Device>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** An encounter that provides additional context in which this request is made. */
  encounter?: Maybe<Encounter>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Composite request this is part of. */
  groupIdentifier?: Maybe<Identifier>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Identifiers assigned to this order by the orderer or by the receiver. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * The URL pointing to a FHIR-defined protocol, guideline, orderset or other
   * definition that is adhered to in whole or in part by this DeviceRequest.
   */
  instantiatesCanonical?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The URL pointing to an externally maintained protocol, guideline, orderset or
   * other definition that is adhered to in whole or in part by this DeviceRequest.
   */
  instantiatesUri?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Insurance plans, coverage extensions, pre-authorizations and/or pre-
   * determinations that may be required for delivering the requested service.
   */
  insurance?: Maybe<Array<Maybe<DeviceRequestInsurance>>>;
  /** Whether the request is a proposal, plan, an original order or a reflex order. */
  intent: Scalars['code'];
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Details about this request that were not represented at all or sufficiently in
   * one of the attributes provided in a class. These may include for example a
   * comment, an instruction, or a note associated with the statement.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** None */
  occurrenceDateTime?: Maybe<Scalars['code']>;
  /** None */
  occurrencePeriod?: Maybe<Period>;
  /** None */
  occurrenceTiming?: Maybe<Timing>;
  /**
   * Specific parameters for the ordered item.  For example, the prism value for
   * lenses.
   */
  parameter?: Maybe<Array<Maybe<DeviceRequestParameter>>>;
  /** The desired performer for doing the diagnostic testing. */
  performer?: Maybe<DeviceRequestPerformer>;
  /** Desired type of performer for doing the diagnostic testing. */
  performerType?: Maybe<CodeableConcept>;
  /**
   * The request takes the place of the referenced completed or terminated
   * request(s).
   */
  priorRequest?: Maybe<Array<Maybe<Resource>>>;
  /**
   * Indicates how quickly the {{title}} should be addressed with respect to other
   * requests.
   */
  priority?: Maybe<Scalars['code']>;
  /** Reason or justification for the use of this device. */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Reason or justification for the use of this device. */
  reasonReference?: Maybe<Array<Maybe<DeviceRequestReasonReference>>>;
  /** Key events in the history of the request. */
  relevantHistory?: Maybe<Array<Maybe<Provenance>>>;
  /**
   * The individual who initiated the request and has responsibility for its
   * activation.
   */
  requester?: Maybe<DeviceRequestRequester>;
  resourceType?: Maybe<Scalars['String']>;
  /** The status of the request. */
  status?: Maybe<Scalars['code']>;
  /** The patient who will use the device. */
  subject: DeviceRequestSubject;
  /**
   * Additional clinical information about the patient that may influence the
   * request fulfilment.  For example, this may include where on the subject's body
   * the device will be used (i.e. the target site).
   */
  supportingInfo?: Maybe<Array<Maybe<Resource>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type DeviceRequestInsurance = ClaimResponse | Coverage;

/**
 * DeviceRequest.Parameter
 *     Represents a request for a patient to employ a medical device. The device may
 *     be an implantable device, or an external assistive device, such as a walker.
 */
export type DeviceRequestParameter = {
  __typename?: 'DeviceRequestParameter';
  /** A code or string that identifies the device detail being asserted. */
  code?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  valueBoolean?: Maybe<Scalars['code']>;
  /** None */
  valueCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  valueQuantity?: Maybe<Quantity>;
  /** None */
  valueRange?: Maybe<Range>;
};

export type DeviceRequestPerformer = CareTeam | Device | HealthcareService | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type DeviceRequestReasonReference = Condition | DiagnosticReport | DocumentReference | Observation;

export type DeviceRequestRequester = Device | Organization | Practitioner | PractitionerRole;

export type DeviceRequestSubject = Device | Group | Location | Patient;

/**
 * Device.Specialization
 *     A type of a manufactured item that is used in the provision of healthcare
 *     without being substantially changed through that activity. The device may be a
 *     medical or non-medical device.
 */
export type DeviceSpecialization = {
  __typename?: 'DeviceSpecialization';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The standard that is used to operate and communicate. */
  systemType: CodeableConcept;
  /** The version of the standard that is used to operate and communicate. */
  version?: Maybe<Scalars['code']>;
};

/**
 * Device.UdiCarrier
 *     A type of a manufactured item that is used in the provision of healthcare
 *     without being substantially changed through that activity. The device may be a
 *     medical or non-medical device.
 */
export type DeviceUdiCarrier = {
  __typename?: 'DeviceUdiCarrier';
  /**
   * The full UDI carrier of the Automatic Identification and Data Capture (AIDC)
   * technology representation of the barcode string as printed on the packaging of
   * the device - e.g., a barcode or RFID.   Because of limitations on character
   * sets in XML and the need to round-trip JSON data through XML, AIDC Formats
   * *SHALL* be base64 encoded.
   */
  carrierAIDC?: Maybe<Scalars['code']>;
  /**
   * The full UDI carrier as the human readable form (HRF) representation of the
   * barcode string as printed on the packaging of the device.
   */
  carrierHRF?: Maybe<Scalars['code']>;
  /**
   * The device identifier (DI) is a mandatory, fixed portion of a UDI that
   * identifies the labeler and the specific version or model of a device.
   */
  deviceIdentifier?: Maybe<Scalars['code']>;
  /** A coded entry to indicate how the data was entered. */
  entryType?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Organization that is charged with issuing UDIs for devices.  For example, the
   * US FDA issuers include :
   * 1) GS1:
   * http://hl7.org/fhir/NamingSystem/gs1-di,
   * 2) HIBCC:
   * http://hl7.org/fhir/NamingSystem/hibcc-dI,
   * 3) ICCBBA for blood containers:
   * http://hl7.org/fhir/NamingSystem/iccbba-blood-di,
   * 4) ICCBA for other devices:
   * http://hl7.org/fhir/NamingSystem/iccbba-other-di.
   */
  issuer?: Maybe<Scalars['code']>;
  /**
   * The identity of the authoritative source for UDI generation within a
   * jurisdiction.  All UDIs are globally unique within a single namespace with the
   * appropriate repository uri as the system.  For example,  UDIs of devices
   * managed in the U.S. by the FDA, the value is
   * http://hl7.org/fhir/NamingSystem/fda-udi.
   */
  jurisdiction?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * DeviceUseStatement
 *     A record of a device being used by a patient where the record is the result of
 *     a report from the patient or another clinician.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type DeviceUseStatement = {
  __typename?: 'DeviceUseStatement';
  /**
   * A plan, proposal or order that is fulfilled in whole or in part by this
   * DeviceUseStatement.
   */
  basedOn?: Maybe<Array<Maybe<ServiceRequest>>>;
  /**
   * Indicates the anotomic location on the subject's body where the device was
   * used ( i.e. the target).
   */
  bodySite?: Maybe<CodeableConcept>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Allows linking the DeviceUseStatement to the underlying Request, or to other
   * information that supports or is used to derive the DeviceUseStatement.
   */
  derivedFrom?: Maybe<Array<Maybe<DeviceUseStatementDerivedFrom>>>;
  /** The details of the device used. */
  device: Device;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** An external identifier for this statement such as an IRI. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Details about the device statement that were not represented at all or
   * sufficiently in one of the attributes provided in a class. These may include
   * for example a comment, an instruction, or a note associated with the
   * statement.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** Reason or justification for the use of the device. */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Indicates another resource whose existence justifies this DeviceUseStatement. */
  reasonReference?: Maybe<Array<Maybe<DeviceUseStatementReasonReference>>>;
  /** The time at which the statement was made/recorded. */
  recordedOn?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /** Who reported the device was being used by the patient. */
  source?: Maybe<DeviceUseStatementSource>;
  /**
   * A code representing the patient or other source's judgment about the state of
   * the device used that this statement is about.  Generally this will be active
   * or completed.
   */
  status: Scalars['code'];
  /** The patient who used the device. */
  subject: DeviceUseStatementSubject;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** None */
  timingDateTime?: Maybe<Scalars['code']>;
  /** None */
  timingPeriod?: Maybe<Period>;
  /** None */
  timingTiming?: Maybe<Timing>;
};

export type DeviceUseStatementDerivedFrom = Claim | DocumentReference | Observation | Procedure | QuestionnaireResponse | ServiceRequest;

export type DeviceUseStatementReasonReference = Condition | DiagnosticReport | DocumentReference | Media | Observation;

export type DeviceUseStatementSource = Patient | Practitioner | PractitionerRole | RelatedPerson;

export type DeviceUseStatementSubject = Group | Patient;

/**
 * Device.Version
 *     A type of a manufactured item that is used in the provision of healthcare
 *     without being substantially changed through that activity. The device may be a
 *     medical or non-medical device.
 */
export type DeviceVersion = {
  __typename?: 'DeviceVersion';
  /** A single component of the device version. */
  component?: Maybe<Identifier>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The type of the device version. */
  type?: Maybe<CodeableConcept>;
  /** The version text. */
  value: Scalars['code'];
};

/**
 * DiagnosticReport
 *     The findings and interpretation of diagnostic  tests performed on patients,
 *     groups of patients, devices, and locations, and/or specimens derived from
 *     these. The report includes clinical context such as requesting and provider
 *     information, and some mix of atomic results, images, textual and coded
 *     interpretations, and formatted representation of diagnostic reports.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type DiagnosticReport = {
  __typename?: 'DiagnosticReport';
  /** Details concerning a service requested. */
  basedOn?: Maybe<Array<Maybe<DiagnosticReportBasedOn>>>;
  /**
   * A code that classifies the clinical discipline, department or diagnostic
   * service that created the report (e.g. cardiology, biochemistry, hematology,
   * MRI). This is used for searching, sorting and display purposes.
   */
  category?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** A code or name that describes this diagnostic report. */
  code: CodeableConcept;
  /**
   * Concise and clinically contextualized summary conclusion
   * (interpretation/impression) of the diagnostic report.
   */
  conclusion?: Maybe<Scalars['code']>;
  /**
   * One or more codes that represent the summary conclusion
   * (interpretation/impression) of the diagnostic report.
   */
  conclusionCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** None */
  effectiveDateTime?: Maybe<Scalars['code']>;
  /** None */
  effectivePeriod?: Maybe<Period>;
  /**
   * The healthcare event  (e.g. a patient and healthcare provider interaction)
   * which this DiagnosticReport is about.
   */
  encounter?: Maybe<Encounter>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Identifiers assigned to this report by the performer or other systems. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * One or more links to full details of any imaging performed during the
   * diagnostic investigation. Typically, this is imaging performed by DICOM
   * enabled modalities, but this is not required. A fully enabled PACS viewer can
   * use this information to provide views of the source images.
   */
  imagingStudy?: Maybe<Array<Maybe<ImagingStudy>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * The date and time that this version of the report was made available to
   * providers, typically after the report was reviewed and verified.
   */
  issued?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * A list of key images associated with this report. The images are generally
   * created during the diagnostic process, and may be directly of the patient, or
   * of treated specimens (i.e. slides of interest).
   */
  media?: Maybe<Array<Maybe<DiagnosticReportMedia>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The diagnostic service that is responsible for issuing the report. */
  performer?: Maybe<Array<Maybe<DiagnosticReportPerformer>>>;
  /**
   * Rich text representation of the entire result as issued by the diagnostic
   * service. Multiple formats are allowed but they SHALL be semantically
   * equivalent.
   */
  presentedForm?: Maybe<Array<Maybe<Attachment>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** [Observations](observation.html)  that are part of this diagnostic report. */
  result?: Maybe<Array<Maybe<Observation>>>;
  /**
   * The practitioner or organization that is responsible for the report's
   * conclusions and interpretations.
   */
  resultsInterpreter?: Maybe<Array<Maybe<DiagnosticReportResultsInterpreter>>>;
  /** Details about the specimens on which this diagnostic report is based. */
  specimen?: Maybe<Array<Maybe<Specimen>>>;
  /** The status of the diagnostic report. */
  status: Scalars['code'];
  /**
   * The subject of the report. Usually, but not always, this is a patient.
   * However, diagnostic services also perform analyses on specimens collected from
   * a variety of other sources.
   */
  subject?: Maybe<DiagnosticReportSubject>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type DiagnosticReportBasedOn = CarePlan | ImmunizationRecommendation | MedicationRequest | NutritionOrder | ServiceRequest;

/**
 * DiagnosticReport.Media
 *     The findings and interpretation of diagnostic  tests performed on patients,
 *     groups of patients, devices, and locations, and/or specimens derived from
 *     these. The report includes clinical context such as requesting and provider
 *     information, and some mix of atomic results, images, textual and coded
 *     interpretations, and formatted representation of diagnostic reports.
 */
export type DiagnosticReportMedia = {
  __typename?: 'DiagnosticReportMedia';
  /**
   * A comment about the image. Typically, this is used to provide an explanation
   * for why the image is included, or to draw the viewer's attention to important
   * features.
   */
  comment?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Reference to the image source. */
  link: Media;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type DiagnosticReportPerformer = CareTeam | Organization | Practitioner | PractitionerRole;

export type DiagnosticReportResultsInterpreter = CareTeam | Organization | Practitioner | PractitionerRole;

export type DiagnosticReportSubject = Device | Group | Location | Patient;

/**
 * Distance
 *     A length - a value with a unit that is a physical distance.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Distance = {
  __typename?: 'Distance';
  /** A computer processable form of the unit in some unit representation system. */
  code?: Maybe<Scalars['code']>;
  /**
   * How the value should be understood and represented - whether the actual value
   * is greater or less than the stated value due to measurement issues; e.g. if
   * the comparator is "<" , then the real value is < stated value.
   */
  comparator?: Maybe<Scalars['code']>;
  /** The identification of the system that provides the coded form of the unit. */
  system?: Maybe<Scalars['code']>;
  /** A human-readable form of the unit. */
  unit?: Maybe<Scalars['code']>;
  /**
   * The value of the measured amount. The value includes an implicit precision in
   * the presentation of the value.
   */
  value?: Maybe<Scalars['code']>;
};

export type Document = {
  __typename?: 'Document';
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  meta?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**
 * DocumentManifest
 *     A collection of documents compiled for a purpose together with metadata that
 *     applies to the collection.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type DocumentManifest = {
  __typename?: 'DocumentManifest';
  /**
   * Identifies who is the author of the manifest. Manifest author is not
   * necessarly the author of the references included.
   */
  author?: Maybe<Array<Maybe<DocumentManifestAuthor>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The list of Resources that consist of the parts of this manifest. */
  content: Array<Maybe<Resource>>;
  /**
   * When the document manifest was created for submission to the server (not
   * necessarily the same thing as the actual resource last modified time, since it
   * may be modified, replicated, etc.).
   */
  created?: Maybe<Scalars['code']>;
  /**
   * Human-readable description of the source document. This is sometimes known as
   * the "title".
   */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Other identifiers associated with the document manifest, including version
   * independent  identifiers.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * A single identifier that uniquely identifies this manifest. Principally used
   * to refer to the manifest in non-FHIR contexts.
   */
  masterIdentifier?: Maybe<Identifier>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A patient, practitioner, or organization for which this set of documents is
   * intended.
   */
  recipient?: Maybe<Array<Maybe<DocumentManifestRecipient>>>;
  /** Related identifiers or resources associated with the DocumentManifest. */
  related?: Maybe<Array<Maybe<DocumentManifestRelated>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * Identifies the source system, application, or software that produced the
   * document manifest.
   */
  source?: Maybe<Scalars['code']>;
  /** The status of this document manifest. */
  status: Scalars['code'];
  /**
   * Who or what the set of documents is about. The documents can be about a
   * person, (patient or healthcare practitioner), a device (i.e. machine) or even
   * a group of subjects (such as a document about a herd of farm animals, or a set
   * of patients that share a common exposure). If the documents cross more than
   * one subject, then more than one subject is allowed here (unusual use case).
   */
  subject?: Maybe<DocumentManifestSubject>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * The code specifying the type of clinical activity that resulted in placing the
   * associated content into the DocumentManifest.
   */
  type?: Maybe<CodeableConcept>;
};

export type DocumentManifestAuthor = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type DocumentManifestRecipient = Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * DocumentManifest.Related
 *     A collection of documents compiled for a purpose together with metadata that
 *     applies to the collection.
 */
export type DocumentManifestRelated = {
  __typename?: 'DocumentManifestRelated';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Related identifier to this DocumentManifest.  For example, Order numbers,
   * accession numbers, XDW workflow numbers.
   */
  identifier?: Maybe<Identifier>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Related Resource to this DocumentManifest. For example, Order, ServiceRequest,
   * Procedure, EligibilityRequest, etc.
   */
  ref?: Maybe<Resource>;
};

export type DocumentManifestSubject = Device | Group | Patient | Practitioner;

/**
 * DocumentReference
 *     A reference to a document of any kind for any purpose. Provides metadata about
 *     the document so that the document can be discovered and managed. The scope of
 *     a document is any seralized object with a mime-type, so includes formal
 *     patient centric documents (CDA), cliical notes, scanned paper, and non-patient
 *     specific documents like policy text.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type DocumentReference = {
  __typename?: 'DocumentReference';
  /** Which person or organization authenticates that this document is valid. */
  authenticator?: Maybe<DocumentReferenceAuthenticator>;
  /** Identifies who is responsible for adding the information to the document. */
  author?: Maybe<Array<Maybe<DocumentReferenceAuthor>>>;
  /**
   * A categorization for the type of document referenced - helps for indexing and
   * searching. This may be implied by or derived from the code specified in the
   * DocumentReference.type.
   */
  category?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The document and format referenced. There may be multiple content element
   * repetitions, each with a different format.
   */
  content: Array<Maybe<DocumentReferenceContent>>;
  /** The clinical context in which the document was prepared. */
  context?: Maybe<DocumentReferenceContext>;
  /**
   * Identifies the organization or group who is responsible for ongoing
   * maintenance of and access to the document.
   */
  custodian?: Maybe<Organization>;
  /** When the document reference was created. */
  date?: Maybe<Scalars['code']>;
  /** Human-readable description of the source document. */
  description?: Maybe<Scalars['code']>;
  /** The status of the underlying document. */
  docStatus?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Other identifiers associated with the document, including version independent
   * identifiers.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * Document identifier as assigned by the source of the document. This identifier
   * is specific to this version of the document. This unique identifier may be
   * used elsewhere to identify this version of the document.
   */
  masterIdentifier?: Maybe<Identifier>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Relationships that this document has with other document references that
   * already exist.
   */
  relatesTo?: Maybe<Array<Maybe<DocumentReferenceRelatesTo>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A set of Security-Tag codes specifying the level of privacy/security of the
   * Document. Note that DocumentReference.meta.security contains the security
   * labels of the "reference" to the document, while
   * DocumentReference.securityLabel contains a snapshot of the security labels on
   * the document the reference refers to.
   */
  securityLabel?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The status of this document reference. */
  status: Scalars['code'];
  /**
   * Who or what the document is about. The document can be about a person,
   * (patient or healthcare practitioner), a device (e.g. a machine) or even a
   * group of subjects (such as a document about a herd of farm animals, or a set
   * of patients that share a common exposure).
   */
  subject?: Maybe<DocumentReferenceSubject>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * Specifies the particular kind of document referenced  (e.g. History and
   * Physical, Discharge Summary, Progress Note). This usually equates to the
   * purpose of making the document referenced.
   */
  type?: Maybe<CodeableConcept>;
};

export type DocumentReferenceAuthenticator = Organization | Practitioner | PractitionerRole;

export type DocumentReferenceAuthor = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * DocumentReference.Content
 *     A reference to a document of any kind for any purpose. Provides metadata about
 *     the document so that the document can be discovered and managed. The scope of
 *     a document is any seralized object with a mime-type, so includes formal
 *     patient centric documents (CDA), cliical notes, scanned paper, and non-patient
 *     specific documents like policy text.
 */
export type DocumentReferenceContent = {
  __typename?: 'DocumentReferenceContent';
  /**
   * The document or URL of the document along with critical metadata to prove
   * content has integrity.
   */
  attachment: Attachment;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * An identifier of the document encoding, structure, and template that the
   * document conforms to beyond the base format indicated in the mimeType.
   */
  format?: Maybe<Coding>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * DocumentReference.Context
 *     A reference to a document of any kind for any purpose. Provides metadata about
 *     the document so that the document can be discovered and managed. The scope of
 *     a document is any seralized object with a mime-type, so includes formal
 *     patient centric documents (CDA), cliical notes, scanned paper, and non-patient
 *     specific documents like policy text.
 */
export type DocumentReferenceContext = {
  __typename?: 'DocumentReferenceContext';
  /**
   * Describes the clinical encounter or type of care that the document content is
   * associated with.
   */
  encounter?: Maybe<Array<Maybe<DocumentReferenceContextEncounter>>>;
  /**
   * This list of codes represents the main clinical acts, such as a colonoscopy or
   * an appendectomy, being documented. In some cases, the event is inherent in the
   * type Code, such as a "History and Physical Report" in which the procedure
   * being documented is necessarily a "History and Physical" act.
   */
  event?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** The kind of facility where the patient was seen. */
  facilityType?: Maybe<CodeableConcept>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The time period over which the service that is described by the document was
   * provided.
   */
  period?: Maybe<Period>;
  /**
   * This property may convey specifics about the practice setting where the
   * content was created, often reflecting the clinical specialty.
   */
  practiceSetting?: Maybe<CodeableConcept>;
  /** Related identifiers or resources associated with the DocumentReference. */
  related?: Maybe<Array<Maybe<Resource>>>;
  /**
   * The Patient Information as known when the document was published. May be a
   * reference to a version specific, or contained.
   */
  sourcePatientInfo?: Maybe<Patient>;
};

export type DocumentReferenceContextEncounter = Encounter | EpisodeOfCare;

/**
 * DocumentReference.RelatesTo
 *     A reference to a document of any kind for any purpose. Provides metadata about
 *     the document so that the document can be discovered and managed. The scope of
 *     a document is any seralized object with a mime-type, so includes formal
 *     patient centric documents (CDA), cliical notes, scanned paper, and non-patient
 *     specific documents like policy text.
 */
export type DocumentReferenceRelatesTo = {
  __typename?: 'DocumentReferenceRelatesTo';
  /** The type of relationship that this document has with anther document. */
  code: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The target document of this relationship. */
  target: DocumentReference;
};

export type DocumentReferenceSubject = Device | Group | Patient | Practitioner;

/**
 * DomainResource
 *     A resource that includes narrative, extensions, and contained resources.
 */
export type DomainResource = {
  __typename?: 'DomainResource';
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * Dosage
 *     Indicates how the medication is/was taken or should be taken by the patient.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Dosage = {
  __typename?: 'Dosage';
  /**
   * Supplemental instructions to the patient on how to take the medication  (e.g.
   * "with meals" or"take half to one hour before food") or warnings for the
   * patient about the medication (e.g. "may cause drowsiness" or "avoid exposure
   * of skin to direct sunlight or sunlamps").
   */
  additionalInstruction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** None */
  asNeededBoolean?: Maybe<Scalars['code']>;
  /** None */
  asNeededCodeableConcept?: Maybe<CodeableConcept>;
  /** The amount of medication administered. */
  doseAndRate?: Maybe<Array<Maybe<DosageDoseAndRate>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Upper limit on medication per administration. */
  maxDosePerAdministration?: Maybe<Quantity>;
  /** Upper limit on medication per lifetime of the patient. */
  maxDosePerLifetime?: Maybe<Quantity>;
  /** Upper limit on medication per unit of time. */
  maxDosePerPeriod?: Maybe<Ratio>;
  /** Technique for administering medication. */
  method?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Instructions in terms that are understood by the patient or consumer. */
  patientInstruction?: Maybe<Scalars['code']>;
  /** How drug should enter body. */
  route?: Maybe<CodeableConcept>;
  /**
   * Indicates the order in which the dosage instructions should be applied or
   * interpreted.
   */
  sequence?: Maybe<Scalars['code']>;
  /** Body site to administer to. */
  site?: Maybe<CodeableConcept>;
  /** Free text dosage instructions e.g. SIG. */
  text?: Maybe<Scalars['code']>;
  /** When medication should be administered. */
  timing?: Maybe<Timing>;
};

/**
 * Dosage.DoseAndRate
 *     Indicates how the medication is/was taken or should be taken by the patient.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type DosageDoseAndRate = {
  __typename?: 'DosageDoseAndRate';
  /** None */
  doseQuantity?: Maybe<Quantity>;
  /** None */
  doseRange?: Maybe<Range>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  rateQuantity?: Maybe<Quantity>;
  /** None */
  rateRange?: Maybe<Range>;
  /** None */
  rateRatio?: Maybe<Ratio>;
  /** The kind of dose or rate specified, for example, ordered or calculated. */
  type?: Maybe<CodeableConcept>;
};

/**
 * Duration
 *     A length of time.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Duration = {
  __typename?: 'Duration';
  /** A computer processable form of the unit in some unit representation system. */
  code?: Maybe<Scalars['code']>;
  /**
   * How the value should be understood and represented - whether the actual value
   * is greater or less than the stated value due to measurement issues; e.g. if
   * the comparator is "<" , then the real value is < stated value.
   */
  comparator?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The identification of the system that provides the coded form of the unit. */
  system?: Maybe<Scalars['code']>;
  /** A human-readable form of the unit. */
  unit?: Maybe<Scalars['code']>;
  /**
   * The value of the measured amount. The value includes an implicit precision in
   * the presentation of the value.
   */
  value?: Maybe<Scalars['code']>;
};

/**
 * EffectEvidenceSynthesis
 *     The EffectEvidenceSynthesis resource describes the difference in an outcome
 *     between exposures states in a population where the effect estimate is derived
 *     from a combination of research studies.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type EffectEvidenceSynthesis = {
  __typename?: 'EffectEvidenceSynthesis';
  /**
   * The date on which the resource content was approved by the publisher. Approval
   * happens once when the content is officially approved for usage.
   */
  approvalDate?: Maybe<Scalars['code']>;
  /**
   * An individiual or organization primarily involved in the creation and
   * maintenance of the content.
   */
  author?: Maybe<Array<Maybe<ContactDetail>>>;
  /** A description of the certainty of the effect estimate. */
  certainty?: Maybe<Array<Maybe<EffectEvidenceSynthesisCertainty>>>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the effect evidence synthesis and/or its
   * contents. Copyright statements are generally legal restrictions on the use and
   * publishing of the effect evidence synthesis.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the effect evidence synthesis was
   * published. The date must change when the business version changes and it must
   * change if the status code changes. In addition, it should change when the
   * substantive content of the effect evidence synthesis changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the effect evidence synthesis from
   * a consumer's perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * An individual or organization primarily responsible for internal coherence of
   * the content.
   */
  editor?: Maybe<Array<Maybe<ContactDetail>>>;
  /** The estimated effect of the exposure variant. */
  effectEstimate?: Maybe<Array<Maybe<EffectEvidenceSynthesisEffectEstimate>>>;
  /**
   * The period during which the effect evidence synthesis content was or is
   * planned to be in active use.
   */
  effectivePeriod?: Maybe<Period>;
  /**
   * An individual or organization responsible for officially endorsing the content
   * for use in some setting.
   */
  endorser?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * A reference to a EvidenceVariable resource that defines the exposure for the
   * research.
   */
  exposure: EvidenceVariable;
  /**
   * A reference to a EvidenceVariable resource that defines the comparison
   * exposure for the research.
   */
  exposureAlternative: EvidenceVariable;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this effect evidence synthesis
   * when it is represented in other formats, or referenced in a specification,
   * model, design or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A legal or geographic region in which the effect evidence synthesis is
   * intended to be used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The date on which the resource content was last reviewed. Review happens
   * periodically after approval but does not change the original approval date.
   */
  lastReviewDate?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the effect evidence synthesis. This name
   * should be usable as an identifier for the module by machine processing
   * applications such as code generation.
   */
  name?: Maybe<Scalars['code']>;
  /** A human-readable string to clarify or explain concepts about the resource. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /**
   * A reference to a EvidenceVariable resomece that defines the outcome for the
   * research.
   */
  outcome: EvidenceVariable;
  /**
   * A reference to a EvidenceVariable resource that defines the population for the
   * research.
   */
  population: EvidenceVariable;
  /**
   * The name of the organization or individual that published the effect evidence
   * synthesis.
   */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Related artifacts such as additional documentation, justification, or
   * bibliographic references.
   */
  relatedArtifact?: Maybe<Array<Maybe<RelatedArtifact>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A description of the results for each exposure considered in the effect
   * estimate.
   */
  resultsByExposure?: Maybe<Array<Maybe<EffectEvidenceSynthesisResultsByExposure>>>;
  /**
   * An individual or organization primarily responsible for review of some aspect
   * of the content.
   */
  reviewer?: Maybe<Array<Maybe<ContactDetail>>>;
  /** A description of the size of the sample involved in the synthesis. */
  sampleSize?: Maybe<EffectEvidenceSynthesisSampleSize>;
  /**
   * The status of this effect evidence synthesis. Enables tracking the life-cycle
   * of the content.
   */
  status: Scalars['code'];
  /** Type of study eg randomized trial. */
  studyType?: Maybe<CodeableConcept>;
  /** Type of synthesis eg meta-analysis. */
  synthesisType?: Maybe<CodeableConcept>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the effect evidence synthesis. */
  title?: Maybe<Scalars['code']>;
  /**
   * Descriptive topics related to the content of the EffectEvidenceSynthesis.
   * Topics provide a high-level categorization grouping types of
   * EffectEvidenceSynthesiss that can be useful for filtering and searching.
   */
  topic?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * An absolute URI that is used to identify this effect evidence synthesis when
   * it is referenced in a specification, model, design or an instance; also called
   * its canonical identifier. This SHOULD be globally unique and SHOULD be a
   * literal address at which at which an authoritative instance of this effect
   * evidence synthesis is (or will be) published. This URL can be the target of a
   * canonical reference. It SHALL remain the same when the effect evidence
   * synthesis is stored on different servers.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate effect
   * evidence synthesis instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the effect evidence
   * synthesis when it is referenced in a specification, model, design or instance.
   * This is an arbitrary value managed by the effect evidence synthesis author and
   * is not expected to be globally unique. For example, it might be a timestamp
   * (e.g. yyyymmdd) if a managed version is not available. There is also no
   * expectation that versions can be placed in a lexicographical sequence.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * EffectEvidenceSynthesis.Certainty
 *     The EffectEvidenceSynthesis resource describes the difference in an outcome
 *     between exposures states in a population where the effect estimate is derived
 *     from a combination of research studies.
 */
export type EffectEvidenceSynthesisCertainty = {
  __typename?: 'EffectEvidenceSynthesisCertainty';
  /** A description of a component of the overall certainty. */
  certaintySubcomponent?: Maybe<Array<Maybe<EffectEvidenceSynthesisCertaintySubcomponent>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A human-readable string to clarify or explain concepts about the resource. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** A rating of the certainty of the effect estimate. */
  rating?: Maybe<Array<Maybe<CodeableConcept>>>;
};

/**
 * EffectEvidenceSynthesis.CertaintySubcomponent
 *     The EffectEvidenceSynthesis resource describes the difference in an outcome
 *     between exposures states in a population where the effect estimate is derived
 *     from a combination of research studies.
 */
export type EffectEvidenceSynthesisCertaintySubcomponent = {
  __typename?: 'EffectEvidenceSynthesisCertaintySubcomponent';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A human-readable string to clarify or explain concepts about the resource. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** A rating of a subcomponent of rating certainty. */
  rating?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Type of subcomponent of certainty rating. */
  type?: Maybe<CodeableConcept>;
};

/**
 * EffectEvidenceSynthesis.EffectEstimate
 *     The EffectEvidenceSynthesis resource describes the difference in an outcome
 *     between exposures states in a population where the effect estimate is derived
 *     from a combination of research studies.
 */
export type EffectEvidenceSynthesisEffectEstimate = {
  __typename?: 'EffectEvidenceSynthesisEffectEstimate';
  /** Human-readable summary of effect estimate. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A description of the precision of the estimate for the effect. */
  precisionEstimate?: Maybe<Array<Maybe<EffectEvidenceSynthesisPrecisionEstimate>>>;
  /** Examples include relative risk and mean difference. */
  type?: Maybe<CodeableConcept>;
  /** Specifies the UCUM unit for the outcome. */
  unitOfMeasure?: Maybe<CodeableConcept>;
  /** The point estimate of the effect estimate. */
  value?: Maybe<Scalars['code']>;
  /** Used to define variant exposure states such as low-risk state. */
  variantState?: Maybe<CodeableConcept>;
};

/**
 * EffectEvidenceSynthesis.PrecisionEstimate
 *     The EffectEvidenceSynthesis resource describes the difference in an outcome
 *     between exposures states in a population where the effect estimate is derived
 *     from a combination of research studies.
 */
export type EffectEvidenceSynthesisPrecisionEstimate = {
  __typename?: 'EffectEvidenceSynthesisPrecisionEstimate';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Lower bound of confidence interval. */
  from?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Use 95 for a 95% confidence interval. */
  level?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Upper bound of confidence interval. */
  to?: Maybe<Scalars['code']>;
  /** Examples include confidence interval and interquartile range. */
  type?: Maybe<CodeableConcept>;
};

/**
 * EffectEvidenceSynthesis.ResultsByExposure
 *     The EffectEvidenceSynthesis resource describes the difference in an outcome
 *     between exposures states in a population where the effect estimate is derived
 *     from a combination of research studies.
 */
export type EffectEvidenceSynthesisResultsByExposure = {
  __typename?: 'EffectEvidenceSynthesisResultsByExposure';
  /** Human-readable summary of results by exposure state. */
  description?: Maybe<Scalars['code']>;
  /**
   * Whether these results are for the exposure state or alternative exposure
   * state.
   */
  exposureState?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Reference to a RiskEvidenceSynthesis resource. */
  riskEvidenceSynthesis: RiskEvidenceSynthesis;
  /** Used to define variant exposure states such as low-risk state. */
  variantState?: Maybe<CodeableConcept>;
};

/**
 * EffectEvidenceSynthesis.SampleSize
 *     The EffectEvidenceSynthesis resource describes the difference in an outcome
 *     between exposures states in a population where the effect estimate is derived
 *     from a combination of research studies.
 */
export type EffectEvidenceSynthesisSampleSize = {
  __typename?: 'EffectEvidenceSynthesisSampleSize';
  /** Human-readable summary of sample size. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Number of participants included in this evidence synthesis. */
  numberOfParticipants?: Maybe<Scalars['code']>;
  /** Number of studies included in this evidence synthesis. */
  numberOfStudies?: Maybe<Scalars['code']>;
};

/**
 * Element
 *     Base definition for all elements in a resource.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Element = {
  __typename?: 'Element';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
};

/**
 * ElementDefinition
 *     Captures constraints on each element within the resource, profile, or
 *     extension.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type ElementDefinition = {
  __typename?: 'ElementDefinition';
  /** Identifies additional names by which this element might also be known. */
  alias?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Information about the base definition of the element, provided to make it
   * unnecessary for tools to trace the deviation of the element through the
   * derived and related profiles. When the element definition is not the original
   * definition of an element - i.g. either in a constraint on another type, or for
   * elements from a super type in a snap shot - then the information in provided
   * in the element definition may be different to the base definition. On the
   * original definition of the element, it will be same.
   */
  base?: Maybe<ElementDefinitionBase>;
  /**
   * Binds to a value set if this element is coded (code, Coding, CodeableConcept,
   * Quantity), or the data types (string, uri).
   */
  binding?: Maybe<ElementDefinitionBinding>;
  /** A code that has the same meaning as the element in a particular terminology. */
  code?: Maybe<Array<Maybe<Coding>>>;
  /**
   * Explanatory notes and implementation guidance about the data element,
   * including notes about how to use the data properly, exceptions to proper use,
   * etc. (Note: The text you are reading is specified in
   * ElementDefinition.comment).
   */
  comment?: Maybe<Scalars['code']>;
  /**
   * A reference to an invariant that may make additional statements about the
   * cardinality or value in the instance.
   */
  condition?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Formal constraints such as co-occurrence and other constraints that can be
   * computationally evaluated within the context of the instance.
   */
  constraint?: Maybe<Array<Maybe<ElementDefinitionConstraint>>>;
  /**
   * Identifies an element defined elsewhere in the definition whose content rules
   * should be applied to the current element. ContentReferences bring across all
   * the rules that are in the ElementDefinition for the element, including
   * definitions, cardinality constraints, bindings, invariants etc.
   */
  contentReference?: Maybe<Scalars['code']>;
  /** None */
  defaultValueAddress?: Maybe<Address>;
  /** None */
  defaultValueAge?: Maybe<Quantity>;
  /** None */
  defaultValueAnnotation?: Maybe<Annotation>;
  /** None */
  defaultValueAttachment?: Maybe<Attachment>;
  /** None */
  defaultValueBase64Binary?: Maybe<Scalars['code']>;
  /** None */
  defaultValueBoolean?: Maybe<Scalars['code']>;
  /** None */
  defaultValueCanonical?: Maybe<Scalars['code']>;
  /** None */
  defaultValueCode?: Maybe<Scalars['code']>;
  /** None */
  defaultValueCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  defaultValueCoding?: Maybe<Coding>;
  /** None */
  defaultValueContactDetail?: Maybe<ContactDetail>;
  /** None */
  defaultValueContactPoint?: Maybe<ContactPoint>;
  /** None */
  defaultValueContributor?: Maybe<Contributor>;
  /** None */
  defaultValueCount?: Maybe<Quantity>;
  /** None */
  defaultValueDataRequirement?: Maybe<DataRequirement>;
  /** None */
  defaultValueDate?: Maybe<Scalars['code']>;
  /** None */
  defaultValueDateTime?: Maybe<Scalars['code']>;
  /** None */
  defaultValueDecimal?: Maybe<Scalars['code']>;
  /** None */
  defaultValueDistance?: Maybe<Quantity>;
  /** None */
  defaultValueDosage?: Maybe<Dosage>;
  /** None */
  defaultValueDuration?: Maybe<Quantity>;
  /** None */
  defaultValueExpression?: Maybe<Expression>;
  /** None */
  defaultValueHumanName?: Maybe<HumanName>;
  /** None */
  defaultValueId?: Maybe<Scalars['code']>;
  /** None */
  defaultValueIdentifier?: Maybe<Identifier>;
  /** None */
  defaultValueInstant?: Maybe<Scalars['code']>;
  /** None */
  defaultValueInteger?: Maybe<Scalars['code']>;
  /** None */
  defaultValueMarkdown?: Maybe<Scalars['code']>;
  /** None */
  defaultValueMeta?: Maybe<Meta>;
  /** None */
  defaultValueMoney?: Maybe<Money>;
  /** None */
  defaultValueOid?: Maybe<Scalars['code']>;
  /** None */
  defaultValueParameterDefinition?: Maybe<ParameterDefinition>;
  /** None */
  defaultValuePeriod?: Maybe<Period>;
  /** None */
  defaultValuePositiveInt?: Maybe<Scalars['code']>;
  /** None */
  defaultValueQuantity?: Maybe<Quantity>;
  /** None */
  defaultValueRange?: Maybe<Range>;
  /** None */
  defaultValueRatio?: Maybe<Ratio>;
  /** None */
  defaultValueReference?: Maybe<Resource>;
  /** None */
  defaultValueRelatedArtifact?: Maybe<RelatedArtifact>;
  /** None */
  defaultValueSampledData?: Maybe<SampledData>;
  /** None */
  defaultValueSignature?: Maybe<Signature>;
  /** None */
  defaultValueString?: Maybe<Scalars['code']>;
  /** None */
  defaultValueTime?: Maybe<Scalars['code']>;
  /** None */
  defaultValueTiming?: Maybe<Timing>;
  /** None */
  defaultValueTriggerDefinition?: Maybe<TriggerDefinition>;
  /** None */
  defaultValueUnsignedInt?: Maybe<Scalars['code']>;
  /** None */
  defaultValueUri?: Maybe<Scalars['code']>;
  /** None */
  defaultValueUrl?: Maybe<Scalars['code']>;
  /** None */
  defaultValueUsageContext?: Maybe<UsageContext>;
  /** None */
  defaultValueUuid?: Maybe<Scalars['code']>;
  /**
   * Provides a complete explanation of the meaning of the data element for human
   * readability.  For the case of elements derived from existing elements (e.g.
   * constraints), the definition SHALL be consistent with the base definition, but
   * convey the meaning of the element in the particular context of use of the
   * resource. (Note: The text you are reading is specified in
   * ElementDefinition.definition).
   */
  definition?: Maybe<Scalars['code']>;
  /**
   * A sample value for this element demonstrating the type of information that
   * would typically be found in the element.
   */
  example?: Maybe<Array<Maybe<ElementDefinitionExample>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  fixedAddress?: Maybe<Address>;
  /** None */
  fixedAge?: Maybe<Quantity>;
  /** None */
  fixedAnnotation?: Maybe<Annotation>;
  /** None */
  fixedAttachment?: Maybe<Attachment>;
  /** None */
  fixedBase64Binary?: Maybe<Scalars['code']>;
  /** None */
  fixedBoolean?: Maybe<Scalars['code']>;
  /** None */
  fixedCanonical?: Maybe<Scalars['code']>;
  /** None */
  fixedCode?: Maybe<Scalars['code']>;
  /** None */
  fixedCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  fixedCoding?: Maybe<Coding>;
  /** None */
  fixedContactDetail?: Maybe<ContactDetail>;
  /** None */
  fixedContactPoint?: Maybe<ContactPoint>;
  /** None */
  fixedContributor?: Maybe<Contributor>;
  /** None */
  fixedCount?: Maybe<Quantity>;
  /** None */
  fixedDataRequirement?: Maybe<DataRequirement>;
  /** None */
  fixedDate?: Maybe<Scalars['code']>;
  /** None */
  fixedDateTime?: Maybe<Scalars['code']>;
  /** None */
  fixedDecimal?: Maybe<Scalars['code']>;
  /** None */
  fixedDistance?: Maybe<Quantity>;
  /** None */
  fixedDosage?: Maybe<Dosage>;
  /** None */
  fixedDuration?: Maybe<Quantity>;
  /** None */
  fixedExpression?: Maybe<Expression>;
  /** None */
  fixedHumanName?: Maybe<HumanName>;
  /** None */
  fixedId?: Maybe<Scalars['code']>;
  /** None */
  fixedIdentifier?: Maybe<Identifier>;
  /** None */
  fixedInstant?: Maybe<Scalars['code']>;
  /** None */
  fixedInteger?: Maybe<Scalars['code']>;
  /** None */
  fixedMarkdown?: Maybe<Scalars['code']>;
  /** None */
  fixedMeta?: Maybe<Meta>;
  /** None */
  fixedMoney?: Maybe<Money>;
  /** None */
  fixedOid?: Maybe<Scalars['code']>;
  /** None */
  fixedParameterDefinition?: Maybe<ParameterDefinition>;
  /** None */
  fixedPeriod?: Maybe<Period>;
  /** None */
  fixedPositiveInt?: Maybe<Scalars['code']>;
  /** None */
  fixedQuantity?: Maybe<Quantity>;
  /** None */
  fixedRange?: Maybe<Range>;
  /** None */
  fixedRatio?: Maybe<Ratio>;
  /** None */
  fixedReference?: Maybe<Resource>;
  /** None */
  fixedRelatedArtifact?: Maybe<RelatedArtifact>;
  /** None */
  fixedSampledData?: Maybe<SampledData>;
  /** None */
  fixedSignature?: Maybe<Signature>;
  /** None */
  fixedString?: Maybe<Scalars['code']>;
  /** None */
  fixedTime?: Maybe<Scalars['code']>;
  /** None */
  fixedTiming?: Maybe<Timing>;
  /** None */
  fixedTriggerDefinition?: Maybe<TriggerDefinition>;
  /** None */
  fixedUnsignedInt?: Maybe<Scalars['code']>;
  /** None */
  fixedUri?: Maybe<Scalars['code']>;
  /** None */
  fixedUrl?: Maybe<Scalars['code']>;
  /** None */
  fixedUsageContext?: Maybe<UsageContext>;
  /** None */
  fixedUuid?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * If true, the value of this element affects the interpretation of the element
   * or resource that contains it, and the value of the element cannot be ignored.
   * Typically, this is used for status, negation and qualification codes. The
   * effect of this is that the element cannot be ignored by systems: they SHALL
   * either recognize the element and process it, and/or a pre-determination has
   * been made that it is not relevant to their particular system.
   */
  isModifier?: Maybe<Scalars['code']>;
  /**
   * Explains how that element affects the interpretation of the resource or
   * element that contains it.
   */
  isModifierReason?: Maybe<Scalars['code']>;
  /**
   * Whether the element should be included if a client requests a search with the
   * parameter _summary=true.
   */
  isSummary?: Maybe<Scalars['code']>;
  /**
   * A single preferred label which is the text to display beside the element
   * indicating its meaning or to use to prompt for the element in a user display
   * or form.
   */
  label?: Maybe<Scalars['code']>;
  /**
   * Identifies a concept from an external specification that roughly corresponds
   * to this element.
   */
  mapping?: Maybe<Array<Maybe<ElementDefinitionMapping>>>;
  /**
   * The maximum number of times this element is permitted to appear in the
   * instance.
   */
  max?: Maybe<Scalars['code']>;
  /**
   * Indicates the maximum length in characters that is permitted to be present in
   * conformant instances and which is expected to be supported by conformant
   * consumers that support the element.
   */
  maxLength?: Maybe<Scalars['code']>;
  /** None */
  maxValueDate?: Maybe<Scalars['code']>;
  /** None */
  maxValueDateTime?: Maybe<Scalars['code']>;
  /** None */
  maxValueDecimal?: Maybe<Scalars['code']>;
  /** None */
  maxValueInstant?: Maybe<Scalars['code']>;
  /** None */
  maxValueInteger?: Maybe<Scalars['code']>;
  /** None */
  maxValuePositiveInt?: Maybe<Scalars['code']>;
  /** None */
  maxValueQuantity?: Maybe<Quantity>;
  /** None */
  maxValueTime?: Maybe<Scalars['code']>;
  /** None */
  maxValueUnsignedInt?: Maybe<Scalars['code']>;
  /**
   * The Implicit meaning that is to be understood when this element is missing
   * (e.g. 'when this element is missing, the period is ongoing').
   */
  meaningWhenMissing?: Maybe<Scalars['code']>;
  /** The minimum number of times this element SHALL appear in the instance. */
  min?: Maybe<Scalars['code']>;
  /** None */
  minValueDate?: Maybe<Scalars['code']>;
  /** None */
  minValueDateTime?: Maybe<Scalars['code']>;
  /** None */
  minValueDecimal?: Maybe<Scalars['code']>;
  /** None */
  minValueInstant?: Maybe<Scalars['code']>;
  /** None */
  minValueInteger?: Maybe<Scalars['code']>;
  /** None */
  minValuePositiveInt?: Maybe<Scalars['code']>;
  /** None */
  minValueQuantity?: Maybe<Quantity>;
  /** None */
  minValueTime?: Maybe<Scalars['code']>;
  /** None */
  minValueUnsignedInt?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * If true, implementations that produce or consume resources SHALL provide
   * "support" for the element in some meaningful way.  If false, the element may
   * be ignored and not supported. If false, whether to populate or use the data
   * element in any way is at the discretion of the implementation.
   */
  mustSupport?: Maybe<Scalars['code']>;
  /**
   * If present, indicates that the order of the repeating element has meaning and
   * describes what that meaning is.  If absent, it means that the order of the
   * element has no meaning.
   */
  orderMeaning?: Maybe<Scalars['code']>;
  /**
   * The path identifies the element and is expressed as a "."-separated list of
   * ancestor elements, beginning with the name of the resource or extension.
   */
  path: Scalars['code'];
  /** None */
  patternAddress?: Maybe<Address>;
  /** None */
  patternAge?: Maybe<Quantity>;
  /** None */
  patternAnnotation?: Maybe<Annotation>;
  /** None */
  patternAttachment?: Maybe<Attachment>;
  /** None */
  patternBase64Binary?: Maybe<Scalars['code']>;
  /** None */
  patternBoolean?: Maybe<Scalars['code']>;
  /** None */
  patternCanonical?: Maybe<Scalars['code']>;
  /** None */
  patternCode?: Maybe<Scalars['code']>;
  /** None */
  patternCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  patternCoding?: Maybe<Coding>;
  /** None */
  patternContactDetail?: Maybe<ContactDetail>;
  /** None */
  patternContactPoint?: Maybe<ContactPoint>;
  /** None */
  patternContributor?: Maybe<Contributor>;
  /** None */
  patternCount?: Maybe<Quantity>;
  /** None */
  patternDataRequirement?: Maybe<DataRequirement>;
  /** None */
  patternDate?: Maybe<Scalars['code']>;
  /** None */
  patternDateTime?: Maybe<Scalars['code']>;
  /** None */
  patternDecimal?: Maybe<Scalars['code']>;
  /** None */
  patternDistance?: Maybe<Quantity>;
  /** None */
  patternDosage?: Maybe<Dosage>;
  /** None */
  patternDuration?: Maybe<Quantity>;
  /** None */
  patternExpression?: Maybe<Expression>;
  /** None */
  patternHumanName?: Maybe<HumanName>;
  /** None */
  patternId?: Maybe<Scalars['code']>;
  /** None */
  patternIdentifier?: Maybe<Identifier>;
  /** None */
  patternInstant?: Maybe<Scalars['code']>;
  /** None */
  patternInteger?: Maybe<Scalars['code']>;
  /** None */
  patternMarkdown?: Maybe<Scalars['code']>;
  /** None */
  patternMeta?: Maybe<Meta>;
  /** None */
  patternMoney?: Maybe<Money>;
  /** None */
  patternOid?: Maybe<Scalars['code']>;
  /** None */
  patternParameterDefinition?: Maybe<ParameterDefinition>;
  /** None */
  patternPeriod?: Maybe<Period>;
  /** None */
  patternPositiveInt?: Maybe<Scalars['code']>;
  /** None */
  patternQuantity?: Maybe<Quantity>;
  /** None */
  patternRange?: Maybe<Range>;
  /** None */
  patternRatio?: Maybe<Ratio>;
  /** None */
  patternReference?: Maybe<Resource>;
  /** None */
  patternRelatedArtifact?: Maybe<RelatedArtifact>;
  /** None */
  patternSampledData?: Maybe<SampledData>;
  /** None */
  patternSignature?: Maybe<Signature>;
  /** None */
  patternString?: Maybe<Scalars['code']>;
  /** None */
  patternTime?: Maybe<Scalars['code']>;
  /** None */
  patternTiming?: Maybe<Timing>;
  /** None */
  patternTriggerDefinition?: Maybe<TriggerDefinition>;
  /** None */
  patternUnsignedInt?: Maybe<Scalars['code']>;
  /** None */
  patternUri?: Maybe<Scalars['code']>;
  /** None */
  patternUrl?: Maybe<Scalars['code']>;
  /** None */
  patternUsageContext?: Maybe<UsageContext>;
  /** None */
  patternUuid?: Maybe<Scalars['code']>;
  /**
   * Codes that define how this element is represented in instances, when the
   * deviation varies from the normal case.
   */
  representation?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * This element is for traceability of why the element was created and why the
   * constraints exist as they do. This may be used to point to source materials or
   * specifications that drove the structure of this element.
   */
  requirements?: Maybe<Scalars['code']>;
  /**
   * A concise description of what this element means (e.g. for use in
   * autogenerated summaries).
   */
  short?: Maybe<Scalars['code']>;
  /**
   * If true, indicates that this slice definition is constraining a slice
   * definition with the same name in an inherited profile. If false, the slice is
   * not overriding any slice in an inherited profile. If missing, the slice might
   * or might not be overriding a slice in an inherited profile, depending on the
   * sliceName.
   */
  sliceIsConstraining?: Maybe<Scalars['code']>;
  /**
   * The name of this element definition slice, when slicing is working. The name
   * must be a token with no dots or spaces. This is a unique name referring to a
   * specific set of constraints applied to this element, used to provide a name to
   * different slices of the same element.
   */
  sliceName?: Maybe<Scalars['code']>;
  /**
   * Indicates that the element is sliced into a set of alternative definitions
   * (i.e. in a structure definition, there are multiple different constraints on a
   * single element in the base resource). Slicing can be used in any resource that
   * has cardinality ..* on the base resource, or any resource with a choice of
   * types. The set of slices is any elements that come after this in the element
   * sequence that have the same path, until a shorter path occurs (the shorter
   * path terminates the set).
   */
  slicing?: Maybe<ElementDefinitionSlicing>;
  /** The data type or resource that the value of this element is permitted to be. */
  type?: Maybe<Array<Maybe<ElementDefinitionType>>>;
};

/**
 * ElementDefinition.Base
 *     Captures constraints on each element within the resource, profile, or
 *     extension.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type ElementDefinitionBase = {
  __typename?: 'ElementDefinitionBase';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Maximum cardinality of the base element identified by the path. */
  max: Scalars['code'];
  /** Minimum cardinality of the base element identified by the path. */
  min: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The Path that identifies the base element - this matches the
   * ElementDefinition.path for that element. Across FHIR, there is only one base
   * definition of any element - that is, an element definition on a
   * [[[StructureDefinition]]] without a StructureDefinition.base.
   */
  path: Scalars['code'];
};

/**
 * ElementDefinition.Binding
 *     Captures constraints on each element within the resource, profile, or
 *     extension.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type ElementDefinitionBinding = {
  __typename?: 'ElementDefinitionBinding';
  /** Describes the intended use of this particular set of codes. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Indicates the degree of conformance expectations associated with this binding
   * - that is, the degree to which the provided value set must be adhered to in
   * the instances.
   */
  strength: Scalars['code'];
  /**
   * Refers to the value set that identifies the set of codes the binding refers
   * to.
   */
  valueSet?: Maybe<Scalars['code']>;
};

/**
 * ElementDefinition.Constraint
 *     Captures constraints on each element within the resource, profile, or
 *     extension.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type ElementDefinitionConstraint = {
  __typename?: 'ElementDefinitionConstraint';
  /**
   * A [FHIRPath](fhirpath.html) expression of constraint that can be executed to
   * see if this constraint is met.
   */
  expression?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Text that can be used to describe the constraint in messages identifying that
   * the constraint has been violated.
   */
  human: Scalars['code'];
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Allows identification of which elements have their cardinalities impacted by
   * the constraint.  Will not be referenced for constraints that do not affect
   * cardinality.
   */
  key: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Description of why this constraint is necessary or appropriate. */
  requirements?: Maybe<Scalars['code']>;
  /**
   * Identifies the impact constraint violation has on the conformance of the
   * instance.
   */
  severity: Scalars['code'];
  /**
   * A reference to the original source of the constraint, for traceability
   * purposes.
   */
  source?: Maybe<Scalars['code']>;
  /**
   * An XPath expression of constraint that can be executed to see if this
   * constraint is met.
   */
  xpath?: Maybe<Scalars['code']>;
};

/**
 * ElementDefinition.Discriminator
 *     Captures constraints on each element within the resource, profile, or
 *     extension.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type ElementDefinitionDiscriminator = {
  __typename?: 'ElementDefinitionDiscriminator';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A FHIRPath expression, using [the simple subset of
   * FHIRPath](fhirpath.html#simple), that is used to identify the element on which
   * discrimination is based.
   */
  path: Scalars['code'];
  /** How the element value is interpreted when discrimination is evaluated. */
  type: Scalars['code'];
};

/**
 * ElementDefinition.Example
 *     Captures constraints on each element within the resource, profile, or
 *     extension.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type ElementDefinitionExample = {
  __typename?: 'ElementDefinitionExample';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Describes the purpose of this example amoung the set of examples. */
  label: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  valueAddress?: Maybe<Address>;
  /** None */
  valueAge?: Maybe<Quantity>;
  /** None */
  valueAnnotation?: Maybe<Annotation>;
  /** None */
  valueAttachment?: Maybe<Attachment>;
  /** None */
  valueBase64Binary?: Maybe<Scalars['code']>;
  /** None */
  valueBoolean?: Maybe<Scalars['code']>;
  /** None */
  valueCanonical?: Maybe<Scalars['code']>;
  /** None */
  valueCode?: Maybe<Scalars['code']>;
  /** None */
  valueCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  valueCoding?: Maybe<Coding>;
  /** None */
  valueContactDetail?: Maybe<ContactDetail>;
  /** None */
  valueContactPoint?: Maybe<ContactPoint>;
  /** None */
  valueContributor?: Maybe<Contributor>;
  /** None */
  valueCount?: Maybe<Quantity>;
  /** None */
  valueDataRequirement?: Maybe<DataRequirement>;
  /** None */
  valueDate?: Maybe<Scalars['code']>;
  /** None */
  valueDateTime?: Maybe<Scalars['code']>;
  /** None */
  valueDecimal?: Maybe<Scalars['code']>;
  /** None */
  valueDistance?: Maybe<Quantity>;
  /** None */
  valueDosage?: Maybe<Dosage>;
  /** None */
  valueDuration?: Maybe<Quantity>;
  /** None */
  valueExpression?: Maybe<Expression>;
  /** None */
  valueHumanName?: Maybe<HumanName>;
  /** None */
  valueId?: Maybe<Scalars['code']>;
  /** None */
  valueIdentifier?: Maybe<Identifier>;
  /** None */
  valueInstant?: Maybe<Scalars['code']>;
  /** None */
  valueInteger?: Maybe<Scalars['code']>;
  /** None */
  valueMarkdown?: Maybe<Scalars['code']>;
  /** None */
  valueMeta?: Maybe<Meta>;
  /** None */
  valueMoney?: Maybe<Money>;
  /** None */
  valueOid?: Maybe<Scalars['code']>;
  /** None */
  valueParameterDefinition?: Maybe<ParameterDefinition>;
  /** None */
  valuePeriod?: Maybe<Period>;
  /** None */
  valuePositiveInt?: Maybe<Scalars['code']>;
  /** None */
  valueQuantity?: Maybe<Quantity>;
  /** None */
  valueRange?: Maybe<Range>;
  /** None */
  valueRatio?: Maybe<Ratio>;
  /** None */
  valueReference?: Maybe<Resource>;
  /** None */
  valueRelatedArtifact?: Maybe<RelatedArtifact>;
  /** None */
  valueSampledData?: Maybe<SampledData>;
  /** None */
  valueSignature?: Maybe<Signature>;
  /** None */
  valueString?: Maybe<Scalars['code']>;
  /** None */
  valueTime?: Maybe<Scalars['code']>;
  /** None */
  valueTiming?: Maybe<Timing>;
  /** None */
  valueTriggerDefinition?: Maybe<TriggerDefinition>;
  /** None */
  valueUnsignedInt?: Maybe<Scalars['code']>;
  /** None */
  valueUri?: Maybe<Scalars['code']>;
  /** None */
  valueUrl?: Maybe<Scalars['code']>;
  /** None */
  valueUsageContext?: Maybe<UsageContext>;
  /** None */
  valueUuid?: Maybe<Scalars['code']>;
};

/**
 * ElementDefinition.Mapping
 *     Captures constraints on each element within the resource, profile, or
 *     extension.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type ElementDefinitionMapping = {
  __typename?: 'ElementDefinitionMapping';
  /** Comments that provide information about the mapping or its use. */
  comment?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** An internal reference to the definition of a mapping. */
  identity: Scalars['code'];
  /** Identifies the computable language in which mapping.map is expressed. */
  language?: Maybe<Scalars['code']>;
  /** Expresses what part of the target specification corresponds to this element. */
  map: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * ElementDefinition.Slicing
 *     Captures constraints on each element within the resource, profile, or
 *     extension.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type ElementDefinitionSlicing = {
  __typename?: 'ElementDefinitionSlicing';
  /**
   * A human-readable text description of how the slicing works. If there is no
   * discriminator, this is required to be present to provide whatever information
   * is possible about how the slices can be differentiated.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * Designates which child elements are used to discriminate between the slices
   * when processing an instance. If one or more discriminators are provided, the
   * value of the child elements in the instance data SHALL completely distinguish
   * which slice the element in the resource matches based on the allowed values
   * for those elements in each of the slices.
   */
  discriminator?: Maybe<Array<Maybe<ElementDefinitionDiscriminator>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * If the matching elements have to occur in the same order as defined in the
   * profile.
   */
  ordered?: Maybe<Scalars['code']>;
  /**
   * Whether additional slices are allowed or not. When the slices are ordered,
   * profile authors can also say that additional slices are only allowed at the
   * end.
   */
  rules: Scalars['code'];
};

/**
 * ElementDefinition.Type
 *     Captures constraints on each element within the resource, profile, or
 *     extension.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type ElementDefinitionType = {
  __typename?: 'ElementDefinitionType';
  /**
   * If the type is a reference to another resource, how the resource is or can be
   * aggregated - is it a contained resource, or a reference, and if the context is
   * a bundle, is it included in the bundle.
   */
  aggregation?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * URL of Data type or Resource that is a(or the) type used for this element.
   * References are URLs that are relative to
   * http://hl7.org/fhir/StructureDefinition e.g. "string" is a reference to
   * http://hl7.org/fhir/StructureDefinition/string. Absolute URLs are only allowed
   * in logical models.
   */
  code: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Identifies a profile structure or implementation Guide that applies to the
   * datatype this element refers to. If any profiles are specified, then the
   * content must conform to at least one of them. The URL can be a local reference
   * - to a contained StructureDefinition, or a reference to another
   * StructureDefinition or Implementation Guide by a canonical URL. When an
   * implementation guide is specified, the type SHALL conform to at least one
   * profile defined in the implementation guide.
   */
  profile?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Used when the type is "Reference" or "canonical", and identifies a profile
   * structure or implementation Guide that applies to the target of the reference
   * this element refers to. If any profiles are specified, then the content must
   * conform to at least one of them. The URL can be a local reference - to a
   * contained StructureDefinition, or a reference to another StructureDefinition
   * or Implementation Guide by a canonical URL. When an implementation guide is
   * specified, the target resource SHALL conform to at least one profile defined
   * in the implementation guide.
   */
  targetProfile?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Whether this reference needs to be version specific or version independent, or
   * whether either can be used.
   */
  versioning?: Maybe<Scalars['code']>;
};

/**
 * Encounter
 *     An interaction between a patient and healthcare provider(s) for the purpose of
 *     providing healthcare service(s) or assessing the health status of a patient.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Encounter = {
  __typename?: 'Encounter';
  /** The set of accounts that may be used for billing for this Encounter. */
  account?: Maybe<Array<Maybe<Account>>>;
  /** The appointment that scheduled this encounter. */
  appointment?: Maybe<Array<Maybe<Appointment>>>;
  /**
   * The request this encounter satisfies (e.g. incoming referral or procedure
   * request).
   */
  basedOn?: Maybe<Array<Maybe<ServiceRequest>>>;
  /**
   * Concepts representing classification of patient encounter such as ambulatory
   * (outpatient), inpatient, emergency, home health or others due to local
   * variations.
   */
  class: Coding;
  /**
   * The class history permits the tracking of the encounters transitions without
   * needing to go  through the resource history.  This would be used for a case
   * where an admission starts of as an emergency encounter, then transitions into
   * an inpatient scenario. Doing this and not restarting a new encounter ensures
   * that any lab/diagnostic results can more easily follow the patient and not
   * require re-processing and not get lost or cancelled during a kind of discharge
   * from emergency to inpatient.
   */
  classHistory?: Maybe<Array<Maybe<EncounterClassHistory>>>;
  composition?: Maybe<Array<Maybe<Composition>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The list of diagnosis relevant to this encounter. */
  diagnosis?: Maybe<Array<Maybe<EncounterDiagnosis>>>;
  /**
   * Where a specific encounter should be classified as a part of a specific
   * episode(s) of care this field should be used. This association can facilitate
   * grouping of related encounters together for a specific purpose, such as
   * government reporting, issue tracking, association via a common problem.  The
   * association is recorded on the encounter as these are typically created after
   * the episode of care and grouped on entry rather than editing the episode of
   * care to append another encounter to it (the episode of care could span years).
   */
  episodeOfCare?: Maybe<Array<Maybe<EpisodeOfCare>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Details about the admission to a healthcare service. */
  hospitalization?: Maybe<EncounterHospitalization>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Identifier(s) by which this encounter is known. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * Quantity of time the encounter lasted. This excludes the time during leaves of
   * absence.
   */
  length?: Maybe<Quantity>;
  /** List of locations where  the patient has been during this encounter. */
  location?: Maybe<Array<Maybe<EncounterLocation>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Another Encounter of which this encounter is a part of (administratively or in
   * time).
   */
  partOf?: Maybe<Encounter>;
  /** The list of people responsible for providing the service. */
  participant?: Maybe<Array<Maybe<EncounterParticipant>>>;
  /** The start and end time of the encounter. */
  period?: Maybe<Period>;
  /** Indicates the urgency of the encounter. */
  priority?: Maybe<CodeableConcept>;
  procedure?: Maybe<Array<Maybe<Procedure>>>;
  /**
   * Reason the encounter takes place, expressed as a code. For admissions, this
   * can be used for a coded admission diagnosis.
   */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Reason the encounter takes place, expressed as a code. For admissions, this
   * can be used for a coded admission diagnosis.
   */
  reasonReference?: Maybe<Array<Maybe<EncounterReasonReference>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The organization that is primarily responsible for this Encounter's services.
   * This MAY be the same as the organization on the Patient record, however it
   * could be different, such as if the actor performing the services was from an
   * external organization (which may be billed seperately) for an external
   * consultation.  Refer to the example bundle showing an abbreviated set of
   * Encounters for a colonoscopy.
   */
  serviceProvider?: Maybe<Organization>;
  /** Broad categorization of the service that is to be provided (e.g. cardiology). */
  serviceType?: Maybe<CodeableConcept>;
  /** planned | arrived | triaged | in-progress | onleave | finished | cancelled +. */
  status: Scalars['code'];
  /**
   * The status history permits the encounter resource to contain the status
   * history without needing to read through the historical versions of the
   * resource, or even have the server store them.
   */
  statusHistory?: Maybe<Array<Maybe<EncounterStatusHistory>>>;
  /** The patient or group present at the encounter. */
  subject?: Maybe<EncounterSubject>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * Specific type of encounter (e.g. e-mail consultation, surgical day-care,
   * skilled nursing, rehabilitation).
   */
  type?: Maybe<Array<Maybe<CodeableConcept>>>;
};

/**
 * Encounter.ClassHistory
 *     An interaction between a patient and healthcare provider(s) for the purpose of
 *     providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterClassHistory = {
  __typename?: 'EncounterClassHistory';
  /** inpatient | outpatient | ambulatory | emergency +. */
  class: Coding;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The time that the episode was in the specified class. */
  period: Period;
};

/**
 * Encounter.Diagnosis
 *     An interaction between a patient and healthcare provider(s) for the purpose of
 *     providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterDiagnosis = {
  __typename?: 'EncounterDiagnosis';
  /**
   * Reason the encounter takes place, as specified using information from another
   * resource. For admissions, this is the admission diagnosis. The indication will
   * typically be a Condition (with other resources referenced in the
   * evidence.detail), or a Procedure.
   */
  condition: EncounterDiagnosisCondition;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Ranking of the diagnosis (for each role type). */
  rank?: Maybe<Scalars['code']>;
  /**
   * Role that this diagnosis has within the encounter (e.g. admission, billing,
   * discharge …).
   */
  use?: Maybe<CodeableConcept>;
};

export type EncounterDiagnosisCondition = Condition | Procedure;

/**
 * Encounter.Hospitalization
 *     An interaction between a patient and healthcare provider(s) for the purpose of
 *     providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterHospitalization = {
  __typename?: 'EncounterHospitalization';
  /** From where patient was admitted (physician referral, transfer). */
  admitSource?: Maybe<CodeableConcept>;
  /** Location/organization to which the patient is discharged. */
  destination?: Maybe<EncounterHospitalizationDestination>;
  /** Diet preferences reported by the patient. */
  dietPreference?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Category or kind of location after discharge. */
  dischargeDisposition?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The location/organization from which the patient came before admission. */
  origin?: Maybe<EncounterHospitalizationOrigin>;
  /** Pre-admission identifier. */
  preAdmissionIdentifier?: Maybe<Identifier>;
  /** Whether this hospitalization is a readmission and why if known. */
  reAdmission?: Maybe<CodeableConcept>;
  /**
   * Any special requests that have been made for this hospitalization encounter,
   * such as the provision of specific equipment or other things.
   */
  specialArrangement?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Special courtesies (VIP, board member). */
  specialCourtesy?: Maybe<Array<Maybe<CodeableConcept>>>;
};

export type EncounterHospitalizationDestination = Location | Organization;

export type EncounterHospitalizationOrigin = Location | Organization;

/**
 * Encounter.Location
 *     An interaction between a patient and healthcare provider(s) for the purpose of
 *     providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterLocation = {
  __typename?: 'EncounterLocation';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The location where the encounter takes place. */
  location: Location;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Time period during which the patient was present at the location. */
  period?: Maybe<Period>;
  /**
   * This will be used to specify the required levels (bed/ward/room/etc.) desired
   * to be recorded to simplify either messaging or query.
   */
  physicalType?: Maybe<CodeableConcept>;
  /**
   * The status of the participants' presence at the specified location during the
   * period specified. If the participant is no longer at the location, then the
   * period will have an end date/time.
   */
  status?: Maybe<Scalars['code']>;
};

/**
 * Encounter.Participant
 *     An interaction between a patient and healthcare provider(s) for the purpose of
 *     providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterParticipant = {
  __typename?: 'EncounterParticipant';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Persons involved in the encounter other than the patient. */
  individual?: Maybe<EncounterParticipantIndividual>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The period of time that the specified participant participated in the
   * encounter. These can overlap or be sub-sets of the overall encounter's period.
   */
  period?: Maybe<Period>;
  /** Role of participant in encounter. */
  type?: Maybe<Array<Maybe<CodeableConcept>>>;
};

export type EncounterParticipantIndividual = Practitioner | PractitionerRole | RelatedPerson;

export type EncounterReasonReference = Condition | ImmunizationRecommendation | Observation | Procedure;

/**
 * Encounter.StatusHistory
 *     An interaction between a patient and healthcare provider(s) for the purpose of
 *     providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterStatusHistory = {
  __typename?: 'EncounterStatusHistory';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The time that the episode was in the specified status. */
  period: Period;
  /** planned | arrived | triaged | in-progress | onleave | finished | cancelled +. */
  status: Scalars['code'];
};

export type EncounterSubject = Group | Patient;

/**
 * Endpoint
 *     The technical details of an endpoint that can be used for electronic services,
 *     such as for web services providing XDS.b or a REST endpoint for another FHIR
 *     server. This may include any security context information.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Endpoint = {
  __typename?: 'Endpoint';
  /** The uri that describes the actual end-point to connect to. */
  address: Scalars['code'];
  /**
   * A coded value that represents the technical details of the usage of this
   * endpoint, such as what WSDLs should be used in what way. (e.g.
   * XDS.b/DICOM/cds-hook).
   */
  connectionType: Coding;
  /**
   * Contact details for a human to contact about the subscription. The primary use
   * of this for system administrator troubleshooting.
   */
  contact?: Maybe<Array<Maybe<ContactPoint>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Additional headers / information to send as part of the notification. */
  header?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifier for the organization that is used to identify the endpoint across
   * multiple disparate systems.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The organization that manages this endpoint (even if technically another
   * organization is hosting this in the cloud, it is the organization associated
   * with the data).
   */
  managingOrganization?: Maybe<Organization>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A friendly name that this endpoint can be referred to with. */
  name?: Maybe<Scalars['code']>;
  /**
   * The mime type to send the payload in - e.g. application/fhir+xml,
   * application/fhir+json. If the mime type is not specified, then the sender
   * could send any content (including no content depending on the connectionType).
   */
  payloadMimeType?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The payload type describes the acceptable content that can be communicated on
   * the endpoint.
   */
  payloadType: Array<Maybe<CodeableConcept>>;
  /** The interval during which the endpoint is expected to be operational. */
  period?: Maybe<Period>;
  resourceType?: Maybe<Scalars['String']>;
  /** active | suspended | error | off | test. */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * EnrollmentRequest
 *     This resource provides the insurance enrollment details to the insurer
 *     regarding a specified coverage.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type EnrollmentRequest = {
  __typename?: 'EnrollmentRequest';
  /** Patient Resource. */
  candidate?: Maybe<Patient>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Reference to the program or plan identification, underwriter or payor. */
  coverage?: Maybe<Coverage>;
  /** The date when this resource was created. */
  created?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** The Response business identifier. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The Insurer who is target  of the request. */
  insurer?: Maybe<Organization>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The practitioner who is responsible for the services rendered to the patient. */
  provider?: Maybe<EnrollmentRequestProvider>;
  resourceType?: Maybe<Scalars['String']>;
  /** The status of the resource instance. */
  status?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type EnrollmentRequestProvider = Organization | Practitioner | PractitionerRole;

/**
 * EnrollmentResponse
 *     This resource provides enrollment and plan details from the processing of an
 *     EnrollmentRequest resource.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type EnrollmentResponse = {
  __typename?: 'EnrollmentResponse';
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The date when the enclosed suite of services were performed or completed. */
  created?: Maybe<Scalars['code']>;
  /** A description of the status of the adjudication. */
  disposition?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** The Response business identifier. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The Insurer who produced this adjudicated response. */
  organization?: Maybe<Organization>;
  /** Processing status: error, complete. */
  outcome?: Maybe<Scalars['code']>;
  /** Original request resource reference. */
  request?: Maybe<EnrollmentRequest>;
  /** The practitioner who is responsible for the services rendered to the patient. */
  requestProvider?: Maybe<EnrollmentResponseRequestProvider>;
  resourceType?: Maybe<Scalars['String']>;
  /** The status of the resource instance. */
  status?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type EnrollmentResponseRequestProvider = Organization | Practitioner | PractitionerRole;

/**
 * EpisodeOfCare
 *     An association between a patient and an organization / healthcare provider(s)
 *     during which time encounters may occur. The managing organization assumes a
 *     level of responsibility for the patient during this time.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type EpisodeOfCare = {
  __typename?: 'EpisodeOfCare';
  /** The set of accounts that may be used for billing for this EpisodeOfCare. */
  account?: Maybe<Array<Maybe<Account>>>;
  /** The practitioner that is the care manager/care coordinator for this patient. */
  careManager?: Maybe<EpisodeOfCareCareManager>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The list of diagnosis relevant to this episode of care. */
  diagnosis?: Maybe<Array<Maybe<EpisodeOfCareDiagnosis>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * The EpisodeOfCare may be known by different identifiers for different contexts
   * of use, such as when an external agency is tracking the Episode for funding
   * purposes.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The organization that has assumed the specific responsibilities for the
   * specified duration.
   */
  managingOrganization?: Maybe<Organization>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The patient who is the focus of this episode of care. */
  patient: Patient;
  /**
   * The interval during which the managing organization assumes the defined
   * responsibility.
   */
  period?: Maybe<Period>;
  /**
   * Referral Request(s) that are fulfilled by this EpisodeOfCare, incoming
   * referrals.
   */
  referralRequest?: Maybe<Array<Maybe<ServiceRequest>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** planned | waitlist | active | onhold | finished | cancelled. */
  status: Scalars['code'];
  /**
   * The history of statuses that the EpisodeOfCare has been through (without
   * requiring processing the history of the resource).
   */
  statusHistory?: Maybe<Array<Maybe<EpisodeOfCareStatusHistory>>>;
  /**
   * The list of practitioners that may be facilitating this episode of care for
   * specific purposes.
   */
  team?: Maybe<Array<Maybe<CareTeam>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * A classification of the type of episode of care; e.g. specialist referral,
   * disease management, type of funded care.
   */
  type?: Maybe<Array<Maybe<CodeableConcept>>>;
};

export type EpisodeOfCareCareManager = Practitioner | PractitionerRole;

/**
 * EpisodeOfCare.Diagnosis
 *     An association between a patient and an organization / healthcare provider(s)
 *     during which time encounters may occur. The managing organization assumes a
 *     level of responsibility for the patient during this time.
 */
export type EpisodeOfCareDiagnosis = {
  __typename?: 'EpisodeOfCareDiagnosis';
  /**
   * A list of conditions/problems/diagnoses that this episode of care is intended
   * to be providing care for.
   */
  condition: Condition;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Ranking of the diagnosis (for each role type). */
  rank?: Maybe<Scalars['code']>;
  /**
   * Role that this diagnosis has within the episode of care (e.g. admission,
   * billing, discharge …).
   */
  role?: Maybe<CodeableConcept>;
};

/**
 * EpisodeOfCare.StatusHistory
 *     An association between a patient and an organization / healthcare provider(s)
 *     during which time encounters may occur. The managing organization assumes a
 *     level of responsibility for the patient during this time.
 */
export type EpisodeOfCareStatusHistory = {
  __typename?: 'EpisodeOfCareStatusHistory';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The period during this EpisodeOfCare that the specific status applied. */
  period: Period;
  /** planned | waitlist | active | onhold | finished | cancelled. */
  status: Scalars['code'];
};

/**
 * EventDefinition
 *     The EventDefinition resource provides a reusable description of when a
 *     particular event can occur.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type EventDefinition = {
  __typename?: 'EventDefinition';
  /**
   * The date on which the resource content was approved by the publisher. Approval
   * happens once when the content is officially approved for usage.
   */
  approvalDate?: Maybe<Scalars['code']>;
  /**
   * An individiual or organization primarily involved in the creation and
   * maintenance of the content.
   */
  author?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the event definition and/or its contents.
   * Copyright statements are generally legal restrictions on the use and
   * publishing of the event definition.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the event definition was published. The
   * date must change when the business version changes and it must change if the
   * status code changes. In addition, it should change when the substantive
   * content of the event definition changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the event definition from a
   * consumer's perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * An individual or organization primarily responsible for internal coherence of
   * the content.
   */
  editor?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * The period during which the event definition content was or is planned to be
   * in active use.
   */
  effectivePeriod?: Maybe<Period>;
  /**
   * An individual or organization responsible for officially endorsing the content
   * for use in some setting.
   */
  endorser?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * A Boolean value to indicate that this event definition is authored for testing
   * purposes (or education/evaluation/marketing) and is not intended to be used
   * for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this event definition when it is
   * represented in other formats, or referenced in a specification, model, design
   * or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A legal or geographic region in which the event definition is intended to be
   * used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The date on which the resource content was last reviewed. Review happens
   * periodically after approval but does not change the original approval date.
   */
  lastReviewDate?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the event definition. This name should be
   * usable as an identifier for the module by machine processing applications such
   * as code generation.
   */
  name?: Maybe<Scalars['code']>;
  /**
   * The name of the organization or individual that published the event
   * definition.
   */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this event definition is needed and why it has been
   * designed as it has.
   */
  purpose?: Maybe<Scalars['code']>;
  /**
   * Related resources such as additional documentation, justification, or
   * bibliographic references.
   */
  relatedArtifact?: Maybe<Array<Maybe<RelatedArtifact>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * An individual or organization primarily responsible for review of some aspect
   * of the content.
   */
  reviewer?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * The status of this event definition. Enables tracking the life-cycle of the
   * content.
   */
  status: Scalars['code'];
  /** None */
  subjectCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  subjectReference?: Maybe<Group>;
  /**
   * An explanatory or alternate title for the event definition giving additional
   * information about its content.
   */
  subtitle?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the event definition. */
  title?: Maybe<Scalars['code']>;
  /**
   * Descriptive topics related to the module. Topics provide a high-level
   * categorization of the module that can be useful for filtering and searching.
   */
  topic?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The trigger element defines when the event occurs. If more than one trigger
   * condition is specified, the event fires whenever any one of the trigger
   * conditions is met.
   */
  trigger: Array<Maybe<TriggerDefinition>>;
  /**
   * An absolute URI that is used to identify this event definition when it is
   * referenced in a specification, model, design or an instance; also called its
   * canonical identifier. This SHOULD be globally unique and SHOULD be a literal
   * address at which at which an authoritative instance of this event definition
   * is (or will be) published. This URL can be the target of a canonical
   * reference. It SHALL remain the same when the event definition is stored on
   * different servers.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * A detailed description of how the event definition is used from a clinical
   * perspective.
   */
  usage?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate event
   * definition instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the event definition
   * when it is referenced in a specification, model, design or instance. This is
   * an arbitrary value managed by the event definition author and is not expected
   * to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if
   * a managed version is not available. There is also no expectation that versions
   * can be placed in a lexicographical sequence.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * Evidence
 *     The Evidence resource describes the conditional state (population and any
 *     exposures being compared within the population) and outcome (if specified)
 *     that the knowledge (evidence, assertion, recommendation) is about.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Evidence = {
  __typename?: 'Evidence';
  /**
   * The date on which the resource content was approved by the publisher. Approval
   * happens once when the content is officially approved for usage.
   */
  approvalDate?: Maybe<Scalars['code']>;
  /**
   * An individiual or organization primarily involved in the creation and
   * maintenance of the content.
   */
  author?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the evidence and/or its contents. Copyright
   * statements are generally legal restrictions on the use and publishing of the
   * evidence.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the evidence was published. The date must
   * change when the business version changes and it must change if the status code
   * changes. In addition, it should change when the substantive content of the
   * evidence changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the evidence from a consumer's
   * perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * An individual or organization primarily responsible for internal coherence of
   * the content.
   */
  editor?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * The period during which the evidence content was or is planned to be in active
   * use.
   */
  effectivePeriod?: Maybe<Period>;
  /**
   * An individual or organization responsible for officially endorsing the content
   * for use in some setting.
   */
  endorser?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * A reference to a EvidenceVariable resource that defines the population for the
   * research.
   */
  exposureBackground: EvidenceVariable;
  /**
   * A reference to a EvidenceVariable resource that defines the exposure for the
   * research.
   */
  exposureVariant?: Maybe<Array<Maybe<EvidenceVariable>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this evidence when it is
   * represented in other formats, or referenced in a specification, model, design
   * or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** A legal or geographic region in which the evidence is intended to be used. */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The date on which the resource content was last reviewed. Review happens
   * periodically after approval but does not change the original approval date.
   */
  lastReviewDate?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the evidence. This name should be usable
   * as an identifier for the module by machine processing applications such as
   * code generation.
   */
  name?: Maybe<Scalars['code']>;
  /** A human-readable string to clarify or explain concepts about the resource. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /**
   * A reference to a EvidenceVariable resomece that defines the outcome for the
   * research.
   */
  outcome?: Maybe<Array<Maybe<EvidenceVariable>>>;
  /** The name of the organization or individual that published the evidence. */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Related artifacts such as additional documentation, justification, or
   * bibliographic references.
   */
  relatedArtifact?: Maybe<Array<Maybe<RelatedArtifact>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * An individual or organization primarily responsible for review of some aspect
   * of the content.
   */
  reviewer?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * The short title provides an alternate title for use in informal descriptive
   * contexts where the full, formal title is not necessary.
   */
  shortTitle?: Maybe<Scalars['code']>;
  /** The status of this evidence. Enables tracking the life-cycle of the content. */
  status: Scalars['code'];
  /**
   * An explanatory or alternate title for the Evidence giving additional
   * information about its content.
   */
  subtitle?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the evidence. */
  title?: Maybe<Scalars['code']>;
  /**
   * Descriptive topics related to the content of the Evidence. Topics provide a
   * high-level categorization grouping types of Evidences that can be useful for
   * filtering and searching.
   */
  topic?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * An absolute URI that is used to identify this evidence when it is referenced
   * in a specification, model, design or an instance; also called its canonical
   * identifier. This SHOULD be globally unique and SHOULD be a literal address at
   * which at which an authoritative instance of this evidence is (or will be)
   * published. This URL can be the target of a canonical reference. It SHALL
   * remain the same when the evidence is stored on different servers.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate evidence
   * instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the evidence when it
   * is referenced in a specification, model, design or instance. This is an
   * arbitrary value managed by the evidence author and is not expected to be
   * globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a
   * managed version is not available. There is also no expectation that versions
   * can be placed in a lexicographical sequence. To provide a version consistent
   * with the Decision Support Service specification, use the format
   * Major.Minor.Revision (e.g. 1.0.0). For more information on versioning
   * knowledge assets, refer to the Decision Support Service specification. Note
   * that a version is required for non-experimental active artifacts.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * EvidenceVariable
 *     The EvidenceVariable resource describes a "PICO" element that knowledge
 *     (evidence, assertion, recommendation) is about.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type EvidenceVariable = {
  __typename?: 'EvidenceVariable';
  /**
   * The date on which the resource content was approved by the publisher. Approval
   * happens once when the content is officially approved for usage.
   */
  approvalDate?: Maybe<Scalars['code']>;
  /**
   * An individiual or organization primarily involved in the creation and
   * maintenance of the content.
   */
  author?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * A characteristic that defines the members of the evidence element. Multiple
   * characteristics are applied with "and" semantics.
   */
  characteristic: Array<Maybe<EvidenceVariableCharacteristic>>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the evidence variable and/or its contents.
   * Copyright statements are generally legal restrictions on the use and
   * publishing of the evidence variable.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the evidence variable was published. The
   * date must change when the business version changes and it must change if the
   * status code changes. In addition, it should change when the substantive
   * content of the evidence variable changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the evidence variable from a
   * consumer's perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * An individual or organization primarily responsible for internal coherence of
   * the content.
   */
  editor?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * The period during which the evidence variable content was or is planned to be
   * in active use.
   */
  effectivePeriod?: Maybe<Period>;
  /**
   * An individual or organization responsible for officially endorsing the content
   * for use in some setting.
   */
  endorser?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this evidence variable when it is
   * represented in other formats, or referenced in a specification, model, design
   * or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A legal or geographic region in which the evidence variable is intended to be
   * used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The date on which the resource content was last reviewed. Review happens
   * periodically after approval but does not change the original approval date.
   */
  lastReviewDate?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the evidence variable. This name should be
   * usable as an identifier for the module by machine processing applications such
   * as code generation.
   */
  name?: Maybe<Scalars['code']>;
  /** A human-readable string to clarify or explain concepts about the resource. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /**
   * The name of the organization or individual that published the evidence
   * variable.
   */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Related artifacts such as additional documentation, justification, or
   * bibliographic references.
   */
  relatedArtifact?: Maybe<Array<Maybe<RelatedArtifact>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * An individual or organization primarily responsible for review of some aspect
   * of the content.
   */
  reviewer?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * The short title provides an alternate title for use in informal descriptive
   * contexts where the full, formal title is not necessary.
   */
  shortTitle?: Maybe<Scalars['code']>;
  /**
   * The status of this evidence variable. Enables tracking the life-cycle of the
   * content.
   */
  status: Scalars['code'];
  /**
   * An explanatory or alternate title for the EvidenceVariable giving additional
   * information about its content.
   */
  subtitle?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the evidence variable. */
  title?: Maybe<Scalars['code']>;
  /**
   * Descriptive topics related to the content of the EvidenceVariable. Topics
   * provide a high-level categorization grouping types of EvidenceVariables that
   * can be useful for filtering and searching.
   */
  topic?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The type of evidence element, a population, an exposure, or an outcome. */
  type?: Maybe<Scalars['code']>;
  /**
   * An absolute URI that is used to identify this evidence variable when it is
   * referenced in a specification, model, design or an instance; also called its
   * canonical identifier. This SHOULD be globally unique and SHOULD be a literal
   * address at which at which an authoritative instance of this evidence variable
   * is (or will be) published. This URL can be the target of a canonical
   * reference. It SHALL remain the same when the evidence variable is stored on
   * different servers.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate evidence
   * variable instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the evidence variable
   * when it is referenced in a specification, model, design or instance. This is
   * an arbitrary value managed by the evidence variable author and is not expected
   * to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if
   * a managed version is not available. There is also no expectation that versions
   * can be placed in a lexicographical sequence. To provide a version consistent
   * with the Decision Support Service specification, use the format
   * Major.Minor.Revision (e.g. 1.0.0). For more information on versioning
   * knowledge assets, refer to the Decision Support Service specification. Note
   * that a version is required for non-experimental active artifacts.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * EvidenceVariable.Characteristic
 *     The EvidenceVariable resource describes a "PICO" element that knowledge
 *     (evidence, assertion, recommendation) is about.
 */
export type EvidenceVariableCharacteristic = {
  __typename?: 'EvidenceVariableCharacteristic';
  /** None */
  definitionCanonical?: Maybe<Scalars['code']>;
  /** None */
  definitionCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  definitionDataRequirement?: Maybe<DataRequirement>;
  /** None */
  definitionExpression?: Maybe<Expression>;
  /** None */
  definitionReference?: Maybe<Group>;
  /** None */
  definitionTriggerDefinition?: Maybe<TriggerDefinition>;
  /**
   * A short, natural language description of the characteristic that could be used
   * to communicate the criteria to an end-user.
   */
  description?: Maybe<Scalars['code']>;
  /** When true, members with this characteristic are excluded from the element. */
  exclude?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Indicates how elements are aggregated within the study effective period. */
  groupMeasure?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  participantEffectiveDateTime?: Maybe<Scalars['code']>;
  /** None */
  participantEffectiveDuration?: Maybe<Quantity>;
  /** None */
  participantEffectivePeriod?: Maybe<Period>;
  /** None */
  participantEffectiveTiming?: Maybe<Timing>;
  /** Indicates duration from the participant's study entry. */
  timeFromStart?: Maybe<Quantity>;
  /**
   * Use UsageContext to define the members of the population, such as Age Ranges,
   * Genders, Settings.
   */
  usageContext?: Maybe<Array<Maybe<UsageContext>>>;
};

/**
 * ExampleScenario
 *     Example of workflow instance.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ExampleScenario = {
  __typename?: 'ExampleScenario';
  /** Actor participating in the resource. */
  actor?: Maybe<Array<Maybe<ExampleScenarioActor>>>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the example scenario and/or its contents.
   * Copyright statements are generally legal restrictions on the use and
   * publishing of the example scenario.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the example scenario was published. The
   * date must change when the business version changes and it must change if the
   * status code changes. In addition, it should change when the substantive
   * content of the example scenario changes. (e.g. the 'content logical
   * definition').
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A Boolean value to indicate that this example scenario is authored for testing
   * purposes (or education/evaluation/marketing) and is not intended to be used
   * for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this example scenario when it is
   * represented in other formats, or referenced in a specification, model, design
   * or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** Each resource and each version that is present in the workflow. */
  instance?: Maybe<Array<Maybe<ExampleScenarioInstance>>>;
  /**
   * A legal or geographic region in which the example scenario is intended to be
   * used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the example scenario. This name should be
   * usable as an identifier for the module by machine processing applications such
   * as code generation.
   */
  name?: Maybe<Scalars['code']>;
  /** Each major process - a group of operations. */
  process?: Maybe<Array<Maybe<ExampleScenarioProcess>>>;
  /**
   * The name of the organization or individual that published the example
   * scenario.
   */
  publisher?: Maybe<Scalars['code']>;
  /**
   * What the example scenario resource is created for. This should not be used to
   * show the business purpose of the scenario itself, but the purpose of
   * documenting a scenario.
   */
  purpose?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The status of this example scenario. Enables tracking the life-cycle of the
   * content.
   */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * An absolute URI that is used to identify this example scenario when it is
   * referenced in a specification, model, design or an instance; also called its
   * canonical identifier. This SHOULD be globally unique and SHOULD be a literal
   * address at which at which an authoritative instance of this example scenario
   * is (or will be) published. This URL can be the target of a canonical
   * reference. It SHALL remain the same when the example scenario is stored on
   * different servers.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate example
   * scenario instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the example scenario
   * when it is referenced in a specification, model, design or instance. This is
   * an arbitrary value managed by the example scenario author and is not expected
   * to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if
   * a managed version is not available. There is also no expectation that versions
   * can be placed in a lexicographical sequence.
   */
  version?: Maybe<Scalars['code']>;
  /** Another nested workflow. */
  workflow?: Maybe<Array<Maybe<Scalars['code']>>>;
};

/**
 * ExampleScenario.Actor
 *     Example of workflow instance.
 */
export type ExampleScenarioActor = {
  __typename?: 'ExampleScenarioActor';
  /** ID or acronym of actor. */
  actorId: Scalars['code'];
  /** The description of the actor. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The name of the actor as shown in the page. */
  name?: Maybe<Scalars['code']>;
  /** The type of actor - person or system. */
  type: Scalars['code'];
};

/**
 * ExampleScenario.Alternative
 *     Example of workflow instance.
 */
export type ExampleScenarioAlternative = {
  __typename?: 'ExampleScenarioAlternative';
  /**
   * A human-readable description of the alternative explaining when the
   * alternative should occur rather than the base step.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** What happens in each alternative option. */
  step?: Maybe<Array<Maybe<ExampleScenarioStep>>>;
  /**
   * The label to display for the alternative that gives a sense of the
   * circumstance in which the alternative should be invoked.
   */
  title: Scalars['code'];
};

/**
 * ExampleScenario.ContainedInstance
 *     Example of workflow instance.
 */
export type ExampleScenarioContainedInstance = {
  __typename?: 'ExampleScenarioContainedInstance';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Each resource contained in the instance. */
  resourceId: Scalars['code'];
  /** A specific version of a resource contained in the instance. */
  versionId?: Maybe<Scalars['code']>;
};

/**
 * ExampleScenario.Instance
 *     Example of workflow instance.
 */
export type ExampleScenarioInstance = {
  __typename?: 'ExampleScenarioInstance';
  /**
   * Resources contained in the instance (e.g. the observations contained in a
   * bundle).
   */
  containedInstance?: Maybe<Array<Maybe<ExampleScenarioContainedInstance>>>;
  /** Human-friendly description of the resource instance. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A short name for the resource instance. */
  name?: Maybe<Scalars['code']>;
  /** The id of the resource for referencing. */
  resourceId: Scalars['code'];
  /** The type of the resource. */
  resourceType: Scalars['code'];
  /** A specific version of the resource. */
  version?: Maybe<Array<Maybe<ExampleScenarioVersion>>>;
};

/**
 * ExampleScenario.Operation
 *     Example of workflow instance.
 */
export type ExampleScenarioOperation = {
  __typename?: 'ExampleScenarioOperation';
  /** A comment to be inserted in the diagram. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Who starts the transaction. */
  initiator?: Maybe<Scalars['code']>;
  /** Whether the initiator is deactivated right after the transaction. */
  initiatorActive?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The human-friendly name of the interaction. */
  name?: Maybe<Scalars['code']>;
  /** The sequential number of the interaction, e.g. 1.2.5. */
  number: Scalars['code'];
  /** Who receives the transaction. */
  receiver?: Maybe<Scalars['code']>;
  /** Whether the receiver is deactivated right after the transaction. */
  receiverActive?: Maybe<Scalars['code']>;
  /** Each resource instance used by the initiator. */
  request?: Maybe<ExampleScenarioContainedInstance>;
  /** Each resource instance used by the responder. */
  response?: Maybe<ExampleScenarioContainedInstance>;
  /** The type of operation - CRUD. */
  type?: Maybe<Scalars['code']>;
};

/**
 * ExampleScenario.Process
 *     Example of workflow instance.
 */
export type ExampleScenarioProcess = {
  __typename?: 'ExampleScenarioProcess';
  /** A longer description of the group of operations. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Description of final status after the process ends. */
  postConditions?: Maybe<Scalars['code']>;
  /** Description of initial status before the process starts. */
  preConditions?: Maybe<Scalars['code']>;
  /** Each step of the process. */
  step?: Maybe<Array<Maybe<ExampleScenarioStep>>>;
  /** The diagram title of the group of operations. */
  title: Scalars['code'];
};

/**
 * ExampleScenario.Step
 *     Example of workflow instance.
 */
export type ExampleScenarioStep = {
  __typename?: 'ExampleScenarioStep';
  /**
   * Indicates an alternative step that can be taken instead of the operations on
   * the base step in exceptional/atypical circumstances.
   */
  alternative?: Maybe<Array<Maybe<ExampleScenarioAlternative>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Each interaction or action. */
  operation?: Maybe<ExampleScenarioOperation>;
  /** If there is a pause in the flow. */
  pause?: Maybe<Scalars['code']>;
  /** Nested process. */
  process?: Maybe<Array<Maybe<ExampleScenarioProcess>>>;
};

/**
 * ExampleScenario.Version
 *     Example of workflow instance.
 */
export type ExampleScenarioVersion = {
  __typename?: 'ExampleScenarioVersion';
  /** The description of the resource version. */
  description: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The identifier of a specific version of a resource. */
  versionId: Scalars['code'];
};

/**
 * ExplanationOfBenefit
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ExplanationOfBenefit = {
  __typename?: 'ExplanationOfBenefit';
  /**
   * Details of a accident which resulted in injuries which required the products
   * and services listed in the claim.
   */
  accident?: Maybe<ExplanationOfBenefitAccident>;
  /** The first-tier service adjudications for payor added product or service lines. */
  addItem?: Maybe<Array<Maybe<ExplanationOfBenefitAddItem>>>;
  /**
   * The adjudication results which are presented at the header level rather than
   * at the line-item or add-item levels.
   */
  adjudication?: Maybe<Array<Maybe<ExplanationOfBenefitAdjudication>>>;
  /** Balance by Benefit Category. */
  benefitBalance?: Maybe<Array<Maybe<ExplanationOfBenefitBenefitBalance>>>;
  /** The term of the benefits documented in this response. */
  benefitPeriod?: Maybe<Period>;
  /** The period for which charges are being submitted. */
  billablePeriod?: Maybe<Period>;
  /** The members of the team who provided the products and services. */
  careTeam?: Maybe<Array<Maybe<ExplanationOfBenefitCareTeam>>>;
  /**
   * The business identifier for the instance of the adjudication request: claim
   * predetermination or preauthorization.
   */
  claim?: Maybe<Claim>;
  /**
   * The business identifier for the instance of the adjudication response: claim,
   * predetermination or preauthorization response.
   */
  claimResponse?: Maybe<ClaimResponse>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The date this resource was created. */
  created: Scalars['code'];
  /** Information about diagnoses relevant to the claim items. */
  diagnosis?: Maybe<Array<Maybe<ExplanationOfBenefitDiagnosis>>>;
  /** A human readable description of the status of the adjudication. */
  disposition?: Maybe<Scalars['code']>;
  /** Individual who created the claim, predetermination or preauthorization. */
  enterer?: Maybe<ExplanationOfBenefitEnterer>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Facility where the services were provided. */
  facility?: Maybe<Location>;
  /**
   * The actual form, by reference or inclusion, for printing the content or an
   * EOB.
   */
  form?: Maybe<Attachment>;
  /** A code for the form to be used for printing the content. */
  formCode?: Maybe<CodeableConcept>;
  /**
   * A code, used only on a response to a preauthorization, to indicate whether the
   * benefits payable have been reserved and for whom.
   */
  fundsReserve?: Maybe<CodeableConcept>;
  /**
   * A code to indicate whether and for whom funds are to be reserved for future
   * claims.
   */
  fundsReserveRequested?: Maybe<CodeableConcept>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** A unique identifier assigned to this explanation of benefit. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * Financial instruments for reimbursement for the health care products and
   * services specified on the claim.
   */
  insurance: Array<Maybe<ExplanationOfBenefitInsurance>>;
  /** The party responsible for authorization, adjudication and reimbursement. */
  insurer: Organization;
  /**
   * A claim line. Either a simple (a product or service) or a 'group' of details
   * which can also be a simple items or groups of sub-details.
   */
  item?: Maybe<Array<Maybe<ExplanationOfBenefitItem>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Original prescription which has been superseded by this prescription to
   * support the dispensing of pharmacy services, medications or products.
   */
  originalPrescription?: Maybe<MedicationRequest>;
  /** The outcome of the claim, predetermination, or preauthorization processing. */
  outcome: Scalars['code'];
  /**
   * The party to whom the professional services and/or products have been supplied
   * or are being considered and for whom actual for forecast reimbursement is
   * sought.
   */
  patient: Patient;
  /**
   * The party to be reimbursed for cost of the products and services according to
   * the terms of the policy.
   */
  payee?: Maybe<ExplanationOfBenefitPayee>;
  /** Payment details for the adjudication of the claim. */
  payment?: Maybe<ExplanationOfBenefitPayment>;
  /**
   * Reference from the Insurer which is used in later communications which refers
   * to this adjudication.
   */
  preAuthRef?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The timeframe during which the supplied preauthorization reference may be
   * quoted on claims to obtain the adjudication as provided.
   */
  preAuthRefPeriod?: Maybe<Array<Maybe<Period>>>;
  /**
   * This indicates the relative order of a series of EOBs related to different
   * coverages for the same suite of services.
   */
  precedence?: Maybe<Scalars['code']>;
  /** Prescription to support the dispensing of pharmacy, device or vision products. */
  prescription?: Maybe<ExplanationOfBenefitPrescription>;
  /**
   * The provider-required urgency of processing the request. Typical values
   * include: stat, routine deferred.
   */
  priority?: Maybe<CodeableConcept>;
  /**
   * Procedures performed on the patient relevant to the billing items with the
   * claim.
   */
  procedure?: Maybe<Array<Maybe<ExplanationOfBenefitProcedure>>>;
  /**
   * A note that describes or explains adjudication results in a human readable
   * form.
   */
  processNote?: Maybe<Array<Maybe<ExplanationOfBenefitProcessNote>>>;
  /**
   * The provider which is responsible for the claim, predetermination or
   * preauthorization.
   */
  provider: ExplanationOfBenefitProvider;
  /** A reference to a referral resource. */
  referral?: Maybe<ServiceRequest>;
  /**
   * Other claims which are related to this claim such as prior submissions or
   * claims for related services or for the same event.
   */
  related?: Maybe<Array<Maybe<ExplanationOfBenefitRelated>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** The status of the resource instance. */
  status: Scalars['code'];
  /**
   * A finer grained suite of claim type codes which may convey additional
   * information such as Inpatient vs Outpatient and/or a specialty service.
   */
  subType?: Maybe<CodeableConcept>;
  /**
   * Additional information codes regarding exceptions, special considerations, the
   * condition, situation, prior or concurrent issues.
   */
  supportingInfo?: Maybe<Array<Maybe<ExplanationOfBenefitSupportingInfo>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** Categorized monetary totals for the adjudication. */
  total?: Maybe<Array<Maybe<ExplanationOfBenefitTotal>>>;
  /**
   * The category of claim, e.g. oral, pharmacy, vision, institutional,
   * professional.
   */
  type: CodeableConcept;
  /**
   * A code to indicate whether the nature of the request is: to request
   * adjudication of products and services previously rendered; or requesting
   * authorization and adjudication for provision in the future; or requesting the
   * non-binding adjudication of the listed products and services which could be
   * provided in the future.
   */
  use: Scalars['code'];
};

/**
 * ExplanationOfBenefit.Accident
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitAccident = {
  __typename?: 'ExplanationOfBenefitAccident';
  /**
   * Date of an accident event  related to the products and services contained in
   * the claim.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** None */
  locationAddress?: Maybe<Address>;
  /** None */
  locationReference?: Maybe<Location>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The type or context of the accident event for the purposes of selection of
   * potential insurance coverages and determination of coordination between
   * insurers.
   */
  type?: Maybe<CodeableConcept>;
};

/**
 * ExplanationOfBenefit.AddItem
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitAddItem = {
  __typename?: 'ExplanationOfBenefitAddItem';
  /** The adjudication results. */
  adjudication?: Maybe<Array<Maybe<ExplanationOfBenefitAdjudication>>>;
  /** Physical service site on the patient (limb, tooth, etc.). */
  bodySite?: Maybe<CodeableConcept>;
  /** The second-tier service adjudications for payor added services. */
  detail?: Maybe<Array<Maybe<ExplanationOfBenefitDetail1>>>;
  /**
   * The sequence number of the details within the claim item which this line is
   * intended to replace.
   */
  detailSequence?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A real number that represents a multiplier used in determining the overall
   * value of services delivered and/or goods received. The concept of a Factor
   * allows for a discount or surcharge multiplier to be applied to a monetary
   * amount.
   */
  factor?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Claim items which this service line is intended to replace. */
  itemSequence?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** None */
  locationAddress?: Maybe<Address>;
  /** None */
  locationCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  locationReference?: Maybe<Location>;
  /**
   * Item typification or modifiers codes to convey additional context for the
   * product or service.
   */
  modifier?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The quantity times the unit price for an additional service or product or
   * charge.
   */
  net?: Maybe<Money>;
  /**
   * The numbers associated with notes below which apply to the adjudication of
   * this item.
   */
  noteNumber?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * When the value is a group code then this item collects a set of related claim
   * details, otherwise this contains the product, service, drug or other billing
   * code for the item.
   */
  productOrService: CodeableConcept;
  /** Identifies the program under which this may be recovered. */
  programCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The providers who are authorized for the services rendered to the patient. */
  provider?: Maybe<Array<Maybe<ExplanationOfBenefitAddItemProvider>>>;
  /** The number of repetitions of a service or product. */
  quantity?: Maybe<Quantity>;
  /** None */
  servicedDate?: Maybe<Scalars['code']>;
  /** None */
  servicedPeriod?: Maybe<Period>;
  /**
   * The sequence number of the sub-details woithin the details within the claim
   * item which this line is intended to replace.
   */
  subDetailSequence?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** A region or surface of the bodySite, e.g. limb region or tooth surface(s). */
  subSite?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * If the item is not a group then this is the fee for the product or service,
   * otherwise this is the total of the fees for the details of the group.
   */
  unitPrice?: Maybe<Money>;
};

export type ExplanationOfBenefitAddItemProvider = Organization | Practitioner | PractitionerRole;

/**
 * ExplanationOfBenefit.Adjudication
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitAdjudication = {
  __typename?: 'ExplanationOfBenefitAdjudication';
  /** Monetary amount associated with the category. */
  amount?: Maybe<Money>;
  /**
   * A code to indicate the information type of this adjudication record.
   * Information types may include: the value submitted, maximum values or
   * percentages allowed or payable under the plan, amounts that the patient is
   * responsible for in-aggregate or pertaining to this item, amounts paid by other
   * coverages, and the benefit payable for this item.
   */
  category: CodeableConcept;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A code supporting the understanding of the adjudication result and explaining
   * variance from expected amount.
   */
  reason?: Maybe<CodeableConcept>;
  /**
   * A non-monetary value associated with the category. Mutually exclusive to the
   * amount element above.
   */
  value?: Maybe<Scalars['code']>;
};

/**
 * ExplanationOfBenefit.BenefitBalance
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitBenefitBalance = {
  __typename?: 'ExplanationOfBenefitBenefitBalance';
  /**
   * Code to identify the general type of benefits under which products and
   * services are provided.
   */
  category: CodeableConcept;
  /** A richer description of the benefit or services covered. */
  description?: Maybe<Scalars['code']>;
  /**
   * True if the indicated class of service is excluded from the plan, missing or
   * False indicates the product or service is included in the coverage.
   */
  excluded?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Benefits Used to date. */
  financial?: Maybe<Array<Maybe<ExplanationOfBenefitFinancial>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A short name or tag for the benefit. */
  name?: Maybe<Scalars['code']>;
  /**
   * Is a flag to indicate whether the benefits refer to in-network providers or
   * out-of-network providers.
   */
  network?: Maybe<CodeableConcept>;
  /**
   * The term or period of the values such as 'maximum lifetime benefit' or
   * 'maximum annual visits'.
   */
  term?: Maybe<CodeableConcept>;
  /** Indicates if the benefits apply to an individual or to the family. */
  unit?: Maybe<CodeableConcept>;
};

/**
 * ExplanationOfBenefit.CareTeam
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitCareTeam = {
  __typename?: 'ExplanationOfBenefitCareTeam';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Member of the team who provided the product or service. */
  provider: ExplanationOfBenefitCareTeamProvider;
  /** The qualification of the practitioner which is applicable for this service. */
  qualification?: Maybe<CodeableConcept>;
  /**
   * The party who is billing and/or responsible for the claimed products or
   * services.
   */
  responsible?: Maybe<Scalars['code']>;
  /**
   * The lead, assisting or supervising practitioner and their discipline if a
   * multidisciplinary team.
   */
  role?: Maybe<CodeableConcept>;
  /** A number to uniquely identify care team entries. */
  sequence: Scalars['code'];
};

export type ExplanationOfBenefitCareTeamProvider = Organization | Practitioner | PractitionerRole;

/**
 * ExplanationOfBenefit.Detail
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitDetail = {
  __typename?: 'ExplanationOfBenefitDetail';
  /** The adjudication results. */
  adjudication?: Maybe<Array<Maybe<ExplanationOfBenefitAdjudication>>>;
  /**
   * Code to identify the general type of benefits under which products and
   * services are provided.
   */
  category?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A real number that represents a multiplier used in determining the overall
   * value of services delivered and/or goods received. The concept of a Factor
   * allows for a discount or surcharge multiplier to be applied to a monetary
   * amount.
   */
  factor?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Item typification or modifiers codes to convey additional context for the
   * product or service.
   */
  modifier?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The quantity times the unit price for an additional service or product or
   * charge.
   */
  net?: Maybe<Money>;
  /**
   * The numbers associated with notes below which apply to the adjudication of
   * this item.
   */
  noteNumber?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * When the value is a group code then this item collects a set of related claim
   * details, otherwise this contains the product, service, drug or other billing
   * code for the item.
   */
  productOrService: CodeableConcept;
  /** Identifies the program under which this may be recovered. */
  programCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The number of repetitions of a service or product. */
  quantity?: Maybe<Quantity>;
  /** The type of revenue or cost center providing the product and/or service. */
  revenue?: Maybe<CodeableConcept>;
  /**
   * A claim detail line. Either a simple (a product or service) or a 'group' of
   * sub-details which are simple items.
   */
  sequence: Scalars['code'];
  /** Third-tier of goods and services. */
  subDetail?: Maybe<Array<Maybe<ExplanationOfBenefitSubDetail>>>;
  /** Unique Device Identifiers associated with this line item. */
  udi?: Maybe<Array<Maybe<Device>>>;
  /**
   * If the item is not a group then this is the fee for the product or service,
   * otherwise this is the total of the fees for the details of the group.
   */
  unitPrice?: Maybe<Money>;
};

/**
 * ExplanationOfBenefit.Detail1
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitDetail1 = {
  __typename?: 'ExplanationOfBenefitDetail1';
  /** The adjudication results. */
  adjudication?: Maybe<Array<Maybe<ExplanationOfBenefitAdjudication>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A real number that represents a multiplier used in determining the overall
   * value of services delivered and/or goods received. The concept of a Factor
   * allows for a discount or surcharge multiplier to be applied to a monetary
   * amount.
   */
  factor?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Item typification or modifiers codes to convey additional context for the
   * product or service.
   */
  modifier?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The quantity times the unit price for an additional service or product or
   * charge.
   */
  net?: Maybe<Money>;
  /**
   * The numbers associated with notes below which apply to the adjudication of
   * this item.
   */
  noteNumber?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * When the value is a group code then this item collects a set of related claim
   * details, otherwise this contains the product, service, drug or other billing
   * code for the item.
   */
  productOrService: CodeableConcept;
  /** The number of repetitions of a service or product. */
  quantity?: Maybe<Quantity>;
  /** The third-tier service adjudications for payor added services. */
  subDetail?: Maybe<Array<Maybe<ExplanationOfBenefitSubDetail1>>>;
  /**
   * If the item is not a group then this is the fee for the product or service,
   * otherwise this is the total of the fees for the details of the group.
   */
  unitPrice?: Maybe<Money>;
};

/**
 * ExplanationOfBenefit.Diagnosis
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitDiagnosis = {
  __typename?: 'ExplanationOfBenefitDiagnosis';
  /** None */
  diagnosisCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  diagnosisReference?: Maybe<Condition>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Indication of whether the diagnosis was present on admission to a facility. */
  onAdmission?: Maybe<CodeableConcept>;
  /**
   * A package billing code or bundle code used to group products and services to a
   * particular health condition (such as heart attack) which is based on a
   * predetermined grouping code system.
   */
  packageCode?: Maybe<CodeableConcept>;
  /** A number to uniquely identify diagnosis entries. */
  sequence: Scalars['code'];
  /** When the condition was observed or the relative ranking. */
  type?: Maybe<Array<Maybe<CodeableConcept>>>;
};

export type ExplanationOfBenefitEnterer = Practitioner | PractitionerRole;

/**
 * ExplanationOfBenefit.Financial
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitFinancial = {
  __typename?: 'ExplanationOfBenefitFinancial';
  /** None */
  allowedMoney?: Maybe<Money>;
  /** None */
  allowedString?: Maybe<Scalars['code']>;
  /** None */
  allowedUnsignedInt?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Classification of benefit being provided. */
  type: CodeableConcept;
  /** None */
  usedMoney?: Maybe<Money>;
  /** None */
  usedUnsignedInt?: Maybe<Scalars['code']>;
};

/**
 * ExplanationOfBenefit.Insurance
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitInsurance = {
  __typename?: 'ExplanationOfBenefitInsurance';
  /**
   * Reference to the insurance card level information contained in the Coverage
   * resource. The coverage issuing insurer will use these details to locate the
   * patient's actual coverage within the insurer's information system.
   */
  coverage: Coverage;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A flag to indicate that this Coverage is to be used for adjudication of this
   * claim when set to true.
   */
  focal: Scalars['code'];
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Reference numbers previously provided by the insurer to the provider to be
   * quoted on subsequent claims containing services or products related to the
   * prior authorization.
   */
  preAuthRef?: Maybe<Array<Maybe<Scalars['code']>>>;
};

/**
 * ExplanationOfBenefit.Item
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitItem = {
  __typename?: 'ExplanationOfBenefitItem';
  /**
   * If this item is a group then the values here are a summary of the adjudication
   * of the detail items. If this item is a simple product or service then this is
   * the result of the adjudication of this item.
   */
  adjudication?: Maybe<Array<Maybe<ExplanationOfBenefitAdjudication>>>;
  /** Physical service site on the patient (limb, tooth, etc.). */
  bodySite?: Maybe<CodeableConcept>;
  /** Care team members related to this service or product. */
  careTeamSequence?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Code to identify the general type of benefits under which products and
   * services are provided.
   */
  category?: Maybe<CodeableConcept>;
  /** Second-tier of goods and services. */
  detail?: Maybe<Array<Maybe<ExplanationOfBenefitDetail>>>;
  /** Diagnoses applicable for this service or product. */
  diagnosisSequence?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** A billed item may include goods or services provided in multiple encounters. */
  encounter?: Maybe<Array<Maybe<Encounter>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A real number that represents a multiplier used in determining the overall
   * value of services delivered and/or goods received. The concept of a Factor
   * allows for a discount or surcharge multiplier to be applied to a monetary
   * amount.
   */
  factor?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Exceptions, special conditions and supporting information applicable for this
   * service or product.
   */
  informationSequence?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** None */
  locationAddress?: Maybe<Address>;
  /** None */
  locationCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  locationReference?: Maybe<Location>;
  /**
   * Item typification or modifiers codes to convey additional context for the
   * product or service.
   */
  modifier?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The quantity times the unit price for an additional service or product or
   * charge.
   */
  net?: Maybe<Money>;
  /**
   * The numbers associated with notes below which apply to the adjudication of
   * this item.
   */
  noteNumber?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Procedures applicable for this service or product. */
  procedureSequence?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * When the value is a group code then this item collects a set of related claim
   * details, otherwise this contains the product, service, drug or other billing
   * code for the item.
   */
  productOrService: CodeableConcept;
  /** Identifies the program under which this may be recovered. */
  programCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The number of repetitions of a service or product. */
  quantity?: Maybe<Quantity>;
  /** The type of revenue or cost center providing the product and/or service. */
  revenue?: Maybe<CodeableConcept>;
  /** A number to uniquely identify item entries. */
  sequence: Scalars['code'];
  /** None */
  servicedDate?: Maybe<Scalars['code']>;
  /** None */
  servicedPeriod?: Maybe<Period>;
  /** A region or surface of the bodySite, e.g. limb region or tooth surface(s). */
  subSite?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Unique Device Identifiers associated with this line item. */
  udi?: Maybe<Array<Maybe<Device>>>;
  /**
   * If the item is not a group then this is the fee for the product or service,
   * otherwise this is the total of the fees for the details of the group.
   */
  unitPrice?: Maybe<Money>;
};

/**
 * ExplanationOfBenefit.Payee
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitPayee = {
  __typename?: 'ExplanationOfBenefitPayee';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Reference to the individual or organization to whom any payment will be made. */
  party?: Maybe<ExplanationOfBenefitPayeeParty>;
  /** Type of Party to be reimbursed: Subscriber, provider, other. */
  type?: Maybe<CodeableConcept>;
};

export type ExplanationOfBenefitPayeeParty = Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * ExplanationOfBenefit.Payment
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitPayment = {
  __typename?: 'ExplanationOfBenefitPayment';
  /**
   * Total amount of all adjustments to this payment included in this transaction
   * which are not related to this claim's adjudication.
   */
  adjustment?: Maybe<Money>;
  /** Reason for the payment adjustment. */
  adjustmentReason?: Maybe<CodeableConcept>;
  /** Benefits payable less any payment adjustment. */
  amount?: Maybe<Money>;
  /** Estimated date the payment will be issued or the actual issue date of payment. */
  date?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Issuer's unique identifier for the payment instrument. */
  identifier?: Maybe<Identifier>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Whether this represents partial or complete payment of the benefits payable. */
  type?: Maybe<CodeableConcept>;
};

export type ExplanationOfBenefitPrescription = MedicationRequest | VisionPrescription;

/**
 * ExplanationOfBenefit.Procedure
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitProcedure = {
  __typename?: 'ExplanationOfBenefitProcedure';
  /** Date and optionally time the procedure was performed. */
  date?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  procedureCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  procedureReference?: Maybe<Procedure>;
  /** A number to uniquely identify procedure entries. */
  sequence: Scalars['code'];
  /** When the condition was observed or the relative ranking. */
  type?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Unique Device Identifiers associated with this line item. */
  udi?: Maybe<Array<Maybe<Device>>>;
};

/**
 * ExplanationOfBenefit.ProcessNote
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitProcessNote = {
  __typename?: 'ExplanationOfBenefitProcessNote';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** A code to define the language used in the text of the note. */
  language?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A number to uniquely identify a note entry. */
  number?: Maybe<Scalars['code']>;
  /** The explanation or description associated with the processing. */
  text?: Maybe<Scalars['code']>;
  /** The business purpose of the note text. */
  type?: Maybe<Scalars['code']>;
};

export type ExplanationOfBenefitProvider = Organization | Practitioner | PractitionerRole;

/**
 * ExplanationOfBenefit.Related
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitRelated = {
  __typename?: 'ExplanationOfBenefitRelated';
  /** Reference to a related claim. */
  claim?: Maybe<Claim>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * An alternate organizational reference to the case or file to which this
   * particular claim pertains.
   */
  reference?: Maybe<Identifier>;
  /** A code to convey how the claims are related. */
  relationship?: Maybe<CodeableConcept>;
};

/**
 * ExplanationOfBenefit.SubDetail
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitSubDetail = {
  __typename?: 'ExplanationOfBenefitSubDetail';
  /** The adjudication results. */
  adjudication?: Maybe<Array<Maybe<ExplanationOfBenefitAdjudication>>>;
  /**
   * Code to identify the general type of benefits under which products and
   * services are provided.
   */
  category?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A real number that represents a multiplier used in determining the overall
   * value of services delivered and/or goods received. The concept of a Factor
   * allows for a discount or surcharge multiplier to be applied to a monetary
   * amount.
   */
  factor?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Item typification or modifiers codes to convey additional context for the
   * product or service.
   */
  modifier?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The quantity times the unit price for an additional service or product or
   * charge.
   */
  net?: Maybe<Money>;
  /**
   * The numbers associated with notes below which apply to the adjudication of
   * this item.
   */
  noteNumber?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * When the value is a group code then this item collects a set of related claim
   * details, otherwise this contains the product, service, drug or other billing
   * code for the item.
   */
  productOrService: CodeableConcept;
  /** Identifies the program under which this may be recovered. */
  programCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The number of repetitions of a service or product. */
  quantity?: Maybe<Quantity>;
  /** The type of revenue or cost center providing the product and/or service. */
  revenue?: Maybe<CodeableConcept>;
  /**
   * A claim detail line. Either a simple (a product or service) or a 'group' of
   * sub-details which are simple items.
   */
  sequence: Scalars['code'];
  /** Unique Device Identifiers associated with this line item. */
  udi?: Maybe<Array<Maybe<Device>>>;
  /**
   * If the item is not a group then this is the fee for the product or service,
   * otherwise this is the total of the fees for the details of the group.
   */
  unitPrice?: Maybe<Money>;
};

/**
 * ExplanationOfBenefit.SubDetail1
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitSubDetail1 = {
  __typename?: 'ExplanationOfBenefitSubDetail1';
  /** The adjudication results. */
  adjudication?: Maybe<Array<Maybe<ExplanationOfBenefitAdjudication>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A real number that represents a multiplier used in determining the overall
   * value of services delivered and/or goods received. The concept of a Factor
   * allows for a discount or surcharge multiplier to be applied to a monetary
   * amount.
   */
  factor?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Item typification or modifiers codes to convey additional context for the
   * product or service.
   */
  modifier?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The quantity times the unit price for an additional service or product or
   * charge.
   */
  net?: Maybe<Money>;
  /**
   * The numbers associated with notes below which apply to the adjudication of
   * this item.
   */
  noteNumber?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * When the value is a group code then this item collects a set of related claim
   * details, otherwise this contains the product, service, drug or other billing
   * code for the item.
   */
  productOrService: CodeableConcept;
  /** The number of repetitions of a service or product. */
  quantity?: Maybe<Quantity>;
  /**
   * If the item is not a group then this is the fee for the product or service,
   * otherwise this is the total of the fees for the details of the group.
   */
  unitPrice?: Maybe<Money>;
};

/**
 * ExplanationOfBenefit.SupportingInfo
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitSupportingInfo = {
  __typename?: 'ExplanationOfBenefitSupportingInfo';
  /**
   * The general class of the information supplied: information; exception;
   * accident, employment; onset, etc.
   */
  category: CodeableConcept;
  /**
   * System and code pertaining to the specific information regarding special
   * conditions relating to the setting, treatment or patient  for which care is
   * sought.
   */
  code?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Provides the reason in the situation where a reason code is required in
   * addition to the content.
   */
  reason?: Maybe<Coding>;
  /** A number to uniquely identify supporting information entries. */
  sequence: Scalars['code'];
  /** None */
  timingDate?: Maybe<Scalars['code']>;
  /** None */
  timingPeriod?: Maybe<Period>;
  /** None */
  valueAttachment?: Maybe<Attachment>;
  /** None */
  valueBoolean?: Maybe<Scalars['code']>;
  /** None */
  valueQuantity?: Maybe<Quantity>;
  /** None */
  valueReference?: Maybe<Resource>;
  /** None */
  valueString?: Maybe<Scalars['code']>;
};

/**
 * ExplanationOfBenefit.Total
 *     This resource provides: the claim details; adjudication details from the
 *     processing of a Claim; and optionally account balance information, for
 *     informing the subscriber of the benefits provided.
 */
export type ExplanationOfBenefitTotal = {
  __typename?: 'ExplanationOfBenefitTotal';
  /** Monetary total amount associated with the category. */
  amount: Money;
  /**
   * A code to indicate the information type of this adjudication record.
   * Information types may include: the value submitted, maximum values or
   * percentages allowed or payable under the plan, amounts that the patient is
   * responsible for in aggregate or pertaining to this item, amounts paid by other
   * coverages, and the benefit payable for this item.
   */
  category: CodeableConcept;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * Expression
 *     A expression that is evaluated in a specified context and returns a value. The
 *     context of use of the expression must specify the context in which the
 *     expression is evaluated, and how the result of the expression is used.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Expression = {
  __typename?: 'Expression';
  /**
   * A brief, natural language description of the condition that effectively
   * communicates the intended semantics.
   */
  description?: Maybe<Scalars['code']>;
  /** An expression in the specified language that returns a value. */
  expression?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The media type of the language for the expression. */
  language: Scalars['code'];
  /**
   * A short name assigned to the expression to allow for multiple reuse of the
   * expression in the context where it is defined.
   */
  name?: Maybe<Scalars['code']>;
  /** A URI that defines where the expression is found. */
  reference?: Maybe<Scalars['code']>;
};

/**
 * Extension
 *     Optional Extension Element - found in all resources.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Extension = {
  __typename?: 'Extension';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** None */
  url?: Maybe<Scalars['code']>;
  /** None */
  valueAddress?: Maybe<Address>;
  /** None */
  valueAge?: Maybe<Quantity>;
  /** None */
  valueAnnotation?: Maybe<Annotation>;
  /** None */
  valueAttachment?: Maybe<Attachment>;
  /** None */
  valueBase64Binary?: Maybe<Scalars['code']>;
  /** None */
  valueBoolean?: Maybe<Scalars['code']>;
  /** None */
  valueCanonical?: Maybe<Scalars['code']>;
  /** None */
  valueCode?: Maybe<Scalars['code']>;
  /** None */
  valueCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  valueCoding?: Maybe<Coding>;
  /** None */
  valueContactDetail?: Maybe<ContactDetail>;
  /** None */
  valueContactPoint?: Maybe<ContactPoint>;
  /** None */
  valueContributor?: Maybe<Contributor>;
  /** None */
  valueCount?: Maybe<Quantity>;
  /** None */
  valueDataRequirement?: Maybe<DataRequirement>;
  /** None */
  valueDate?: Maybe<Scalars['code']>;
  /** None */
  valueDateTime?: Maybe<Scalars['code']>;
  /** None */
  valueDecimal?: Maybe<Scalars['code']>;
  /** None */
  valueDistance?: Maybe<Quantity>;
  /** None */
  valueDosage?: Maybe<Dosage>;
  /** None */
  valueDuration?: Maybe<Quantity>;
  /** None */
  valueExpression?: Maybe<Expression>;
  /** None */
  valueHumanName?: Maybe<HumanName>;
  /** None */
  valueId?: Maybe<Scalars['code']>;
  /** None */
  valueIdentifier?: Maybe<Identifier>;
  /** None */
  valueInstant?: Maybe<Scalars['code']>;
  /** None */
  valueInteger?: Maybe<Scalars['code']>;
  /** None */
  valueMarkdown?: Maybe<Scalars['code']>;
  /** None */
  valueMeta?: Maybe<Meta>;
  /** None */
  valueMoney?: Maybe<Money>;
  /** None */
  valueOid?: Maybe<Scalars['code']>;
  /** None */
  valueParameterDefinition?: Maybe<ParameterDefinition>;
  /** None */
  valuePeriod?: Maybe<Period>;
  /** None */
  valuePositiveInt?: Maybe<Scalars['code']>;
  /** None */
  valueQuantity?: Maybe<Quantity>;
  /** None */
  valueRange?: Maybe<Range>;
  /** None */
  valueRatio?: Maybe<Ratio>;
  /** None */
  valueReference?: Maybe<Resource>;
  /** None */
  valueRelatedArtifact?: Maybe<RelatedArtifact>;
  /** None */
  valueSampledData?: Maybe<SampledData>;
  /** None */
  valueSignature?: Maybe<Signature>;
  /** None */
  valueString?: Maybe<Scalars['code']>;
  /** None */
  valueTime?: Maybe<Scalars['code']>;
  /** None */
  valueTiming?: Maybe<Timing>;
  /** None */
  valueTriggerDefinition?: Maybe<TriggerDefinition>;
  /** None */
  valueUnsignedInt?: Maybe<Scalars['code']>;
  /** None */
  valueUri?: Maybe<Scalars['code']>;
  /** None */
  valueUrl?: Maybe<Scalars['code']>;
  /** None */
  valueUsageContext?: Maybe<UsageContext>;
  /** None */
  valueUuid?: Maybe<Scalars['code']>;
};

/**
 * FamilyMemberHistory
 *     Significant health conditions for a person related to the patient relevant in
 *     the context of care for the patient.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type FamilyMemberHistory = {
  __typename?: 'FamilyMemberHistory';
  /** None */
  ageAge?: Maybe<Quantity>;
  /** None */
  ageRange?: Maybe<Range>;
  /** None */
  ageString?: Maybe<Scalars['code']>;
  /** None */
  bornDate?: Maybe<Scalars['code']>;
  /** None */
  bornPeriod?: Maybe<Period>;
  /** None */
  bornString?: Maybe<Scalars['code']>;
  /**
   * The significant Conditions (or condition) that the family member had. This is
   * a repeating section to allow a system to represent more than one condition per
   * resource, though there is nothing stopping multiple resources - one per
   * condition.
   */
  condition?: Maybe<Array<Maybe<FamilyMemberHistoryCondition>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Describes why the family member's history is not available. */
  dataAbsentReason?: Maybe<CodeableConcept>;
  /**
   * The date (and possibly time) when the family member history was recorded or
   * last updated.
   */
  date?: Maybe<Scalars['code']>;
  /** None */
  deceasedAge?: Maybe<Quantity>;
  /** None */
  deceasedBoolean?: Maybe<Scalars['code']>;
  /** None */
  deceasedDate?: Maybe<Scalars['code']>;
  /** None */
  deceasedRange?: Maybe<Range>;
  /** None */
  deceasedString?: Maybe<Scalars['code']>;
  /** If true, indicates that the age value specified is an estimated value. */
  estimatedAge?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Business identifiers assigned to this family member history by the performer
   * or other systems which remain constant as the resource is updated and
   * propagates from server to server.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * The URL pointing to a FHIR-defined protocol, guideline, orderset or other
   * definition that is adhered to in whole or in part by this FamilyMemberHistory.
   */
  instantiatesCanonical?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The URL pointing to an externally maintained protocol, guideline, orderset or
   * other definition that is adhered to in whole or in part by this
   * FamilyMemberHistory.
   */
  instantiatesUri?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * This will either be a name or a description; e.g. "Aunt Susan", "my cousin
   * with the red hair".
   */
  name?: Maybe<Scalars['code']>;
  /**
   * This property allows a non condition-specific note to the made about the
   * related person. Ideally, the note would be in the condition property, but this
   * is not always possible.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** The person who this history concerns. */
  patient: Patient;
  /** Describes why the family member history occurred in coded or textual form. */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Indicates a Condition, Observation, AllergyIntolerance, or
   * QuestionnaireResponse that justifies this family member history event.
   */
  reasonReference?: Maybe<Array<Maybe<FamilyMemberHistoryReasonReference>>>;
  /**
   * The type of relationship this person has to the patient (father, mother,
   * brother etc.).
   */
  relationship: CodeableConcept;
  resourceType?: Maybe<Scalars['String']>;
  /** The birth sex of the family member. */
  sex?: Maybe<CodeableConcept>;
  /**
   * A code specifying the status of the record of the family history of a specific
   * family member.
   */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * FamilyMemberHistory.Condition
 *     Significant health conditions for a person related to the patient relevant in
 *     the context of care for the patient.
 */
export type FamilyMemberHistoryCondition = {
  __typename?: 'FamilyMemberHistoryCondition';
  /**
   * The actual condition specified. Could be a coded condition (like MI or
   * Diabetes) or a less specific string like 'cancer' depending on how much is
   * known about the condition and the capabilities of the creating system.
   */
  code: CodeableConcept;
  /**
   * This condition contributed to the cause of death of the related person. If
   * contributedToDeath is not populated, then it is unknown.
   */
  contributedToDeath?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** An area where general notes can be placed about this specific condition. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** None */
  onsetAge?: Maybe<Quantity>;
  /** None */
  onsetPeriod?: Maybe<Period>;
  /** None */
  onsetRange?: Maybe<Range>;
  /** None */
  onsetString?: Maybe<Scalars['code']>;
  /**
   * Indicates what happened following the condition.  If the condition resulted in
   * death, deceased date is captured on the relation.
   */
  outcome?: Maybe<CodeableConcept>;
};

export type FamilyMemberHistoryReasonReference = AllergyIntolerance | Condition | DiagnosticReport | DocumentReference | Observation | QuestionnaireResponse;

/**
 * Flag
 *     Prospective warnings of potential issues when providing care to the patient.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Flag = {
  __typename?: 'Flag';
  /** The person, organization or device that created the flag. */
  author?: Maybe<FlagAuthor>;
  /**
   * Allows a flag to be divided into different categories like clinical,
   * administrative etc. Intended to be used as a means of filtering which flags
   * are displayed to particular user or in a given context.
   */
  category?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The coded value or textual component of the flag to display to the user. */
  code: CodeableConcept;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** This alert is only relevant during the encounter. */
  encounter?: Maybe<Encounter>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Business identifiers assigned to this flag by the performer or other systems
   * which remain constant as the resource is updated and propagates from server to
   * server.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The period of time from the activation of the flag to inactivation of the
   * flag. If the flag is active, the end of the period should be unspecified.
   */
  period?: Maybe<Period>;
  resourceType?: Maybe<Scalars['String']>;
  /** Supports basic workflow. */
  status: Scalars['code'];
  /**
   * The patient, location, group, organization, or practitioner etc. this is about
   * record this flag is associated with.
   */
  subject: FlagSubject;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type FlagAuthor = Device | Organization | Patient | Practitioner | PractitionerRole;

export type FlagSubject = Group | Location | Medication | Organization | Patient | PlanDefinition | Practitioner | Procedure;

/**
 * Goal
 *     Describes the intended objective(s) for a patient, group or organization care,
 *     for example, weight loss, restoring an activity of daily living, obtaining
 *     herd immunity via immunization, meeting a process improvement objective, etc.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Goal = {
  __typename?: 'Goal';
  /**
   * Describes the progression, or lack thereof, towards the goal against the
   * target.
   */
  achievementStatus?: Maybe<CodeableConcept>;
  /**
   * The identified conditions and other health record elements that are intended
   * to be addressed by the goal.
   */
  addresses?: Maybe<Array<Maybe<GoalAddresses>>>;
  /** Indicates a category the goal falls within. */
  category?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Human-readable and/or coded description of a specific desired objective of
   * care, such as "control blood pressure" or "negotiate an obstacle course" or
   * "dance with child at wedding".
   */
  description: CodeableConcept;
  /** Indicates whose goal this is - patient goal, practitioner goal, etc. */
  expressedBy?: Maybe<GoalExpressedBy>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Business identifiers assigned to this goal by the performer or other systems
   * which remain constant as the resource is updated and propagates from server to
   * server.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** The state of the goal throughout its lifecycle. */
  lifecycleStatus: Scalars['code'];
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Any comments related to the goal. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /**
   * Identifies the change (or lack of change) at the point when the status of the
   * goal is assessed.
   */
  outcomeCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Details of what's changed (or not changed). */
  outcomeReference?: Maybe<Array<Maybe<Observation>>>;
  /**
   * Identifies the mutually agreed level of importance associated with
   * reaching/sustaining the goal.
   */
  priority?: Maybe<CodeableConcept>;
  resourceType?: Maybe<Scalars['String']>;
  /** None */
  startCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  startDate?: Maybe<Scalars['code']>;
  /**
   * Identifies when the current status.  I.e. When initially created, when
   * achieved, when cancelled, etc.
   */
  statusDate?: Maybe<Scalars['code']>;
  /** Captures the reason for the current status. */
  statusReason?: Maybe<Scalars['code']>;
  /**
   * Identifies the patient, group or organization for whom the goal is being
   * established.
   */
  subject: GoalSubject;
  /** Indicates what should be done by when. */
  target?: Maybe<Array<Maybe<GoalTarget>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type GoalAddresses = Condition | MedicationStatement | NutritionOrder | Observation | RiskAssessment | ServiceRequest;

export type GoalExpressedBy = Patient | Practitioner | PractitionerRole | RelatedPerson;

export type GoalSubject = Group | Organization | Patient;

/**
 * Goal.Target
 *     Describes the intended objective(s) for a patient, group or organization care,
 *     for example, weight loss, restoring an activity of daily living, obtaining
 *     herd immunity via immunization, meeting a process improvement objective, etc.
 */
export type GoalTarget = {
  __typename?: 'GoalTarget';
  /** None */
  detailBoolean?: Maybe<Scalars['code']>;
  /** None */
  detailCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  detailInteger?: Maybe<Scalars['code']>;
  /** None */
  detailQuantity?: Maybe<Quantity>;
  /** None */
  detailRange?: Maybe<Range>;
  /** None */
  detailRatio?: Maybe<Ratio>;
  /** None */
  detailString?: Maybe<Scalars['code']>;
  /** None */
  dueDate?: Maybe<Scalars['code']>;
  /** None */
  dueDuration?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The parameter whose value is being tracked, e.g. body weight, blood pressure,
   * or hemoglobin A1c level.
   */
  measure?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * GraphDefinition
 *     A formal computable definition of a graph of resources - that is, a coherent
 *     set of resources that form a graph by following references. The Graph
 *     Definition resource defines a set and makes rules about the set.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type GraphDefinition = {
  __typename?: 'GraphDefinition';
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The date  (and optionally time) when the graph definition was published. The
   * date must change when the business version changes and it must change if the
   * status code changes. In addition, it should change when the substantive
   * content of the graph definition changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the graph definition from a
   * consumer's perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * A Boolean value to indicate that this graph definition is authored for testing
   * purposes (or education/evaluation/marketing) and is not intended to be used
   * for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A legal or geographic region in which the graph definition is intended to be
   * used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** Links this graph makes rules about. */
  link?: Maybe<Array<Maybe<GraphDefinitionLink>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the graph definition. This name should be
   * usable as an identifier for the module by machine processing applications such
   * as code generation.
   */
  name: Scalars['code'];
  /** The profile that describes the use of the base resource. */
  profile?: Maybe<Scalars['code']>;
  /**
   * The name of the organization or individual that published the graph
   * definition.
   */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this graph definition is needed and why it has been
   * designed as it has.
   */
  purpose?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /** The type of FHIR resource at which instances of this graph start. */
  start: Scalars['code'];
  /**
   * The status of this graph definition. Enables tracking the life-cycle of the
   * content.
   */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * An absolute URI that is used to identify this graph definition when it is
   * referenced in a specification, model, design or an instance; also called its
   * canonical identifier. This SHOULD be globally unique and SHOULD be a literal
   * address at which at which an authoritative instance of this graph definition
   * is (or will be) published. This URL can be the target of a canonical
   * reference. It SHALL remain the same when the graph definition is stored on
   * different servers.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate graph
   * definition instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the graph definition
   * when it is referenced in a specification, model, design or instance. This is
   * an arbitrary value managed by the graph definition author and is not expected
   * to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if
   * a managed version is not available. There is also no expectation that versions
   * can be placed in a lexicographical sequence.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * GraphDefinition.Compartment
 *     A formal computable definition of a graph of resources - that is, a coherent
 *     set of resources that form a graph by following references. The Graph
 *     Definition resource defines a set and makes rules about the set.
 */
export type GraphDefinitionCompartment = {
  __typename?: 'GraphDefinitionCompartment';
  /** Identifies the compartment. */
  code: Scalars['code'];
  /** Documentation for FHIRPath expression. */
  description?: Maybe<Scalars['code']>;
  /** Custom rule, as a FHIRPath expression. */
  expression?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** identical | matching | different | no-rule | custom. */
  rule: Scalars['code'];
  /**
   * Defines how the compartment rule is used - whether it it is used to test
   * whether resources are subject to the rule, or whether it is a rule that must
   * be followed.
   */
  use: Scalars['code'];
};

/**
 * GraphDefinition.Link
 *     A formal computable definition of a graph of resources - that is, a coherent
 *     set of resources that form a graph by following references. The Graph
 *     Definition resource defines a set and makes rules about the set.
 */
export type GraphDefinitionLink = {
  __typename?: 'GraphDefinitionLink';
  /** Information about why this link is of interest in this graph definition. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Maximum occurrences for this link. */
  max?: Maybe<Scalars['code']>;
  /** Minimum occurrences for this link. */
  min?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A FHIR expression that identifies one of FHIR References to other resources. */
  path?: Maybe<Scalars['code']>;
  /** Which slice (if profiled). */
  sliceName?: Maybe<Scalars['code']>;
  /** Potential target for the link. */
  target?: Maybe<Array<Maybe<GraphDefinitionTarget>>>;
};

/**
 * GraphDefinition.Target
 *     A formal computable definition of a graph of resources - that is, a coherent
 *     set of resources that form a graph by following references. The Graph
 *     Definition resource defines a set and makes rules about the set.
 */
export type GraphDefinitionTarget = {
  __typename?: 'GraphDefinitionTarget';
  /** Compartment Consistency Rules. */
  compartment?: Maybe<Array<Maybe<GraphDefinitionCompartment>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Additional links from target resource. */
  link?: Maybe<Array<Maybe<GraphDefinitionLink>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A set of parameters to look up. */
  params?: Maybe<Scalars['code']>;
  /** Profile for the target resource. */
  profile?: Maybe<Scalars['code']>;
  /** Type of resource this link refers to. */
  type: Scalars['code'];
};

/**
 * Group
 *     Represents a defined collection of entities that may be discussed or acted
 *     upon collectively but which are not expected to act collectively, and are not
 *     formally or legally recognized; i.e. a collection of entities that isn't an
 *     Organization.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Group = {
  __typename?: 'Group';
  /**
   * Indicates whether the record for the group is available for use or is merely
   * being retained for historical purposes.
   */
  active?: Maybe<Scalars['code']>;
  /**
   * If true, indicates that the resource refers to a specific group of real
   * individuals.  If false, the group defines a set of intended individuals.
   */
  actual: Scalars['code'];
  /** Identifies traits whose presence r absence is shared by members of the group. */
  characteristic?: Maybe<Array<Maybe<GroupCharacteristic>>>;
  /**
   * Provides a specific type of resource the group includes; e.g. "cow",
   * "syringe", etc.
   */
  code?: Maybe<CodeableConcept>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** A unique business identifier for this group. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * Entity responsible for defining and maintaining Group characteristics and/or
   * registered members.
   */
  managingEntity?: Maybe<GroupManagingEntity>;
  /** Identifies the resource instances that are members of the group. */
  member?: Maybe<Array<Maybe<GroupMember>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A label assigned to the group for human identification and communication. */
  name?: Maybe<Scalars['code']>;
  /** A count of the number of resource instances that are part of the group. */
  quantity?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * Identifies the broad classification of the kind of resources the group
   * includes.
   */
  type: Scalars['code'];
};

/**
 * Group.Characteristic
 *     Represents a defined collection of entities that may be discussed or acted
 *     upon collectively but which are not expected to act collectively, and are not
 *     formally or legally recognized; i.e. a collection of entities that isn't an
 *     Organization.
 */
export type GroupCharacteristic = {
  __typename?: 'GroupCharacteristic';
  /** A code that identifies the kind of trait being asserted. */
  code: CodeableConcept;
  /**
   * If true, indicates the characteristic is one that is NOT held by members of
   * the group.
   */
  exclude: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The period over which the characteristic is tested; e.g. the patient had an
   * operation during the month of June.
   */
  period?: Maybe<Period>;
  /** None */
  valueBoolean?: Maybe<Scalars['code']>;
  /** None */
  valueCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  valueQuantity?: Maybe<Quantity>;
  /** None */
  valueRange?: Maybe<Range>;
  /** None */
  valueReference?: Maybe<Resource>;
};

export type GroupManagingEntity = Organization | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Group.Member
 *     Represents a defined collection of entities that may be discussed or acted
 *     upon collectively but which are not expected to act collectively, and are not
 *     formally or legally recognized; i.e. a collection of entities that isn't an
 *     Organization.
 */
export type GroupMember = {
  __typename?: 'GroupMember';
  /**
   * A reference to the entity that is a member of the group. Must be consistent
   * with Group.type. If the entity is another group, then the type must be the
   * same.
   */
  entity: GroupMemberEntity;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * A flag to indicate that the member is no longer in the group, but previously
   * may have been a member.
   */
  inactive?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The period that the member was in the group, if known. */
  period?: Maybe<Period>;
};

export type GroupMemberEntity = Device | Group | Medication | Patient | Practitioner | PractitionerRole | Substance;

/**
 * GuidanceResponse
 *     A guidance response is the formal response to a guidance request, including
 *     any output parameters returned by the evaluation, as well as the description
 *     of any proposed actions to be taken.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type GuidanceResponse = {
  __typename?: 'GuidanceResponse';
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * If the evaluation could not be completed due to lack of information, or
   * additional information would potentially result in a more accurate response,
   * this element will a description of the data required in order to proceed with
   * the evaluation. A subsequent request to the service should include this data.
   */
  dataRequirement?: Maybe<Array<Maybe<DataRequirement>>>;
  /**
   * The encounter during which this response was created or to which the creation
   * of this record is tightly associated.
   */
  encounter?: Maybe<Encounter>;
  /**
   * Messages resulting from the evaluation of the artifact or artifacts. As part
   * of evaluating the request, the engine may produce informational or warning
   * messages. These messages will be provided by this element.
   */
  evaluationMessage?: Maybe<Array<Maybe<OperationOutcome>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Allows a service to provide  unique, business identifiers for the response. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  moduleCanonical?: Maybe<Scalars['code']>;
  /** None */
  moduleCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  moduleUri?: Maybe<Scalars['code']>;
  /** Provides a mechanism to communicate additional information about the response. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** Indicates when the guidance response was processed. */
  occurrenceDateTime?: Maybe<Scalars['code']>;
  /**
   * The output parameters of the evaluation, if any. Many modules will result in
   * the return of specific resources such as procedure or communication requests
   * that are returned as part of the operation result. However, modules may define
   * specific outputs that would be returned as the result of the evaluation, and
   * these would be returned in this element.
   */
  outputParameters?: Maybe<Parameters>;
  /** Provides a reference to the device that performed the guidance. */
  performer?: Maybe<Device>;
  /** Describes the reason for the guidance response in coded or textual form. */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Indicates the reason the request was initiated. This is typically provided as
   * a parameter to the evaluation and echoed by the service, although for some use
   * cases, such as subscription- or event-based scenarios, it may provide an
   * indication of the cause for the response.
   */
  reasonReference?: Maybe<Array<Maybe<GuidanceResponseReasonReference>>>;
  /**
   * The identifier of the request associated with this response. If an identifier
   * was given as part of the request, it will be reproduced here to enable the
   * requester to more easily identify the response in a multi-request scenario.
   */
  requestIdentifier?: Maybe<Identifier>;
  resourceType?: Maybe<Scalars['String']>;
  /** The actions, if any, produced by the evaluation of the artifact. */
  result?: Maybe<GuidanceResponseResult>;
  /**
   * The status of the response. If the evaluation is completed successfully, the
   * status will indicate success. However, in order to complete the evaluation,
   * the engine may require more information. In this case, the status will be
   * data-required, and the response will contain a description of the additional
   * required information. If the evaluation completed successfully, but the engine
   * determines that a potentially more accurate response could be provided if more
   * data was available, the status will be data-requested, and the response will
   * contain a description of the additional requested information.
   */
  status: Scalars['code'];
  /** The patient for which the request was processed. */
  subject?: Maybe<GuidanceResponseSubject>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type GuidanceResponseReasonReference = Condition | DiagnosticReport | DocumentReference | Observation;

export type GuidanceResponseResult = CarePlan | RequestGroup;

export type GuidanceResponseSubject = Group | Patient;

/**
 * HealthcareService
 *     The details of a healthcare service available at a location.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type HealthcareService = {
  __typename?: 'HealthcareService';
  /**
   * This flag is used to mark the record to not be used. This is not used when a
   * center is closed for maintenance, or for holidays, the notAvailable period is
   * to be used for this.
   */
  active?: Maybe<Scalars['code']>;
  /**
   * Indicates whether or not a prospective consumer will require an appointment
   * for a particular service at a site to be provided by the Organization.
   * Indicates if an appointment is required for access to this service.
   */
  appointmentRequired?: Maybe<Scalars['code']>;
  /**
   * A description of site availability exceptions, e.g. public holiday
   * availability. Succinctly describing all possible exceptions to normal site
   * availability as details in the available Times and not available Times.
   */
  availabilityExceptions?: Maybe<Scalars['code']>;
  /** A collection of times that the Service Site is available. */
  availableTime?: Maybe<Array<Maybe<HealthcareServiceAvailableTime>>>;
  /** Identifies the broad category of service being performed or delivered. */
  category?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Collection of characteristics (attributes). */
  characteristic?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Any additional description of the service and/or any specific issues not
   * covered by the other attributes, which can be displayed as further detail
   * under the serviceName.
   */
  comment?: Maybe<Scalars['code']>;
  /**
   * Some services are specifically made available in multiple languages, this
   * property permits a directory to declare the languages this is offered in.
   * Typically this is only provided where a service operates in communities with
   * mixed languages used.
   */
  communication?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The location(s) that this service is available to (not where the service is
   * provided).
   */
  coverageArea?: Maybe<Array<Maybe<Location>>>;
  /**
   * Does this service have specific eligibility requirements that need to be met
   * in order to use the service?
   */
  eligibility?: Maybe<Array<Maybe<HealthcareServiceEligibility>>>;
  /**
   * Technical endpoints providing access to services operated for the specific
   * healthcare services defined at this resource.
   */
  endpoint?: Maybe<Array<Maybe<Endpoint>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Extra details about the service that can't be placed in the other fields. */
  extraDetails?: Maybe<Scalars['code']>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** External identifiers for this item. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** The location(s) where this healthcare service may be provided. */
  location?: Maybe<Array<Maybe<Location>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Further description of the service as it would be presented to a consumer
   * while searching.
   */
  name?: Maybe<Scalars['code']>;
  /**
   * The HealthcareService is not available during this period of time due to the
   * provided reason.
   */
  notAvailable?: Maybe<Array<Maybe<HealthcareServiceNotAvailable>>>;
  /**
   * If there is a photo/symbol associated with this HealthcareService, it may be
   * included here to facilitate quick identification of the service in a list.
   */
  photo?: Maybe<Attachment>;
  /** Programs that this service is applicable to. */
  program?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The organization that provides this healthcare service. */
  providedBy?: Maybe<Organization>;
  /**
   * Ways that the service accepts referrals, if this is not provided then it is
   * implied that no referral is required.
   */
  referralMethod?: Maybe<Array<Maybe<CodeableConcept>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The code(s) that detail the conditions under which the healthcare service is
   * available/offered.
   */
  serviceProvisionCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Collection of specialties handled by the service site. This is more of a
   * medical term.
   */
  specialty?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** List of contacts related to this specific healthcare service. */
  telecom?: Maybe<Array<Maybe<ContactPoint>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** The specific type of service that may be delivered or performed. */
  type?: Maybe<Array<Maybe<CodeableConcept>>>;
};

/**
 * HealthcareService.AvailableTime
 *     The details of a healthcare service available at a location.
 */
export type HealthcareServiceAvailableTime = {
  __typename?: 'HealthcareServiceAvailableTime';
  /** Is this always available? (hence times are irrelevant) e.g. 24 hour service. */
  allDay?: Maybe<Scalars['code']>;
  /**
   * The closing time of day. Note: If the AllDay flag is set, then this time is
   * ignored.
   */
  availableEndTime?: Maybe<Scalars['code']>;
  /**
   * The opening time of day. Note: If the AllDay flag is set, then this time is
   * ignored.
   */
  availableStartTime?: Maybe<Scalars['code']>;
  /**
   * Indicates which days of the week are available between the start and end
   * Times.
   */
  daysOfWeek?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * HealthcareService.Eligibility
 *     The details of a healthcare service available at a location.
 */
export type HealthcareServiceEligibility = {
  __typename?: 'HealthcareServiceEligibility';
  /** Coded value for the eligibility. */
  code?: Maybe<CodeableConcept>;
  /** Describes the eligibility conditions for the service. */
  comment?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * HealthcareService.NotAvailable
 *     The details of a healthcare service available at a location.
 */
export type HealthcareServiceNotAvailable = {
  __typename?: 'HealthcareServiceNotAvailable';
  /**
   * The reason that can be presented to the user as to why this time is not
   * available.
   */
  description: Scalars['code'];
  /** Service is not available (seasonally or for a public holiday) from this date. */
  during?: Maybe<Period>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * HumanName
 *     A human's name with the ability to identify parts and usage.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type HumanName = {
  __typename?: 'HumanName';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The part of a name that links to the genealogy. In some cultures (e.g.
   * Eritrea) the family name of a son is the first name of his father.
   */
  family?: Maybe<Scalars['code']>;
  /** Given name. */
  given?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Indicates the period of time when this name was valid for the named person. */
  period?: Maybe<Period>;
  /**
   * Part of the name that is acquired as a title due to academic, legal,
   * employment or nobility status, etc. and that appears at the start of the name.
   */
  prefix?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Part of the name that is acquired as a title due to academic, legal,
   * employment or nobility status, etc. and that appears at the end of the name.
   */
  suffix?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Specifies the entire name as it should be displayed e.g. on an application UI.
   * This may be provided instead of or as well as the specific parts.
   */
  text?: Maybe<Scalars['code']>;
  /** Identifies the purpose for this name. */
  use?: Maybe<Scalars['code']>;
};

/**
 * Identifier
 *     An identifier - identifies some entity uniquely and unambiguously. Typically
 *     this is used for business identifiers.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Identifier = {
  __typename?: 'Identifier';
  /** Organization that issued/manages the identifier. */
  assigner?: Maybe<Organization>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Time period during which identifier is/was valid for use. */
  period?: Maybe<Period>;
  /**
   * Establishes the namespace for the value - that is, a URL that describes a set
   * values that are unique.
   */
  system?: Maybe<Scalars['code']>;
  /**
   * A coded type for the identifier that can be used to determine which identifier
   * to use for a specific purpose.
   */
  type?: Maybe<CodeableConcept>;
  /** The purpose of this identifier. */
  use?: Maybe<Scalars['code']>;
  /**
   * The portion of the identifier typically relevant to the user and which is
   * unique within the context of the system.
   */
  value?: Maybe<Scalars['code']>;
};

/**
 * ImagingStudy
 *     Representation of the content produced in a DICOM imaging study. A study
 *     comprises a set of series, each of which includes a set of Service-Object Pair
 *     Instances (SOP Instances - images or other data) acquired or produced in a
 *     common context.  A series is of only one modality (e.g. X-ray, CT, MR,
 *     ultrasound), but a study may have multiple series of different modalities.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ImagingStudy = {
  __typename?: 'ImagingStudy';
  /**
   * A list of the diagnostic requests that resulted in this imaging study being
   * performed.
   */
  basedOn?: Maybe<Array<Maybe<ImagingStudyBasedOn>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The Imaging Manager description of the study. Institution-generated
   * description or classification of the Study (component) performed.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * The healthcare event (e.g. a patient and healthcare provider interaction)
   * during which this ImagingStudy is made.
   */
  encounter?: Maybe<Encounter>;
  /**
   * The network service providing access (e.g., query, view, or retrieval) for the
   * study. See implementation notes for information about using DICOM endpoints. A
   * study-level endpoint applies to each series in the study, unless overridden by
   * a series-level endpoint with the same Endpoint.connectionType.
   */
  endpoint?: Maybe<Array<Maybe<Endpoint>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifiers for the ImagingStudy such as DICOM Study Instance UID, and
   * Accession Number.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** Who read the study and interpreted the images or other content. */
  interpreter?: Maybe<Array<Maybe<ImagingStudyInterpreter>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** The principal physical location where the ImagingStudy was performed. */
  location?: Maybe<Location>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * A list of all the series.modality values that are actual acquisition
   * modalities, i.e. those in the DICOM Context Group 29 (value set OID
   * 1.2.840.10008.6.1.19).
   */
  modality?: Maybe<Array<Maybe<Coding>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Per the recommended DICOM mapping, this element is derived from the Study
   * Description attribute (0008,1030). Observations or findings about the imaging
   * study should be recorded in another resource, e.g. Observation, and not in
   * this element.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /**
   * Number of SOP Instances in Study. This value given may be larger than the
   * number of instance elements this resource contains due to resource
   * availability, security, or other factors. This element should be present if
   * any instance elements are present.
   */
  numberOfInstances?: Maybe<Scalars['code']>;
  /**
   * Number of Series in the Study. This value given may be larger than the number
   * of series elements this Resource contains due to resource availability,
   * security, or other factors. This element should be present if any series
   * elements are present.
   */
  numberOfSeries?: Maybe<Scalars['code']>;
  /** The code for the performed procedure type. */
  procedureCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The procedure which this ImagingStudy was part of. */
  procedureReference?: Maybe<Procedure>;
  /**
   * Description of clinical condition indicating why the ImagingStudy was
   * requested.
   */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Indicates another resource whose existence justifies this Study. */
  reasonReference?: Maybe<Array<Maybe<ImagingStudyReasonReference>>>;
  /** The requesting/referring physician. */
  referrer?: Maybe<ImagingStudyReferrer>;
  resourceType?: Maybe<Scalars['String']>;
  /** Each study has one or more series of images or other content. */
  series?: Maybe<Array<Maybe<ImagingStudySeries>>>;
  /** Date and time the study started. */
  started?: Maybe<Scalars['code']>;
  /** The current state of the ImagingStudy. */
  status: Scalars['code'];
  /** The subject, typically a patient, of the imaging study. */
  subject: ImagingStudySubject;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type ImagingStudyBasedOn = Appointment | AppointmentResponse | CarePlan | ServiceRequest | Task;

/**
 * ImagingStudy.Instance
 *     Representation of the content produced in a DICOM imaging study. A study
 *     comprises a set of series, each of which includes a set of Service-Object Pair
 *     Instances (SOP Instances - images or other data) acquired or produced in a
 *     common context.  A series is of only one modality (e.g. X-ray, CT, MR,
 *     ultrasound), but a study may have multiple series of different modalities.
 */
export type ImagingStudyInstance = {
  __typename?: 'ImagingStudyInstance';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The number of instance in the series. */
  number?: Maybe<Scalars['code']>;
  /** DICOM instance  type. */
  sopClass: Coding;
  /** The description of the instance. */
  title?: Maybe<Scalars['code']>;
  /** The DICOM SOP Instance UID for this image or other DICOM content. */
  uid: Scalars['code'];
};

export type ImagingStudyInterpreter = Practitioner | PractitionerRole;

/**
 * ImagingStudy.Performer
 *     Representation of the content produced in a DICOM imaging study. A study
 *     comprises a set of series, each of which includes a set of Service-Object Pair
 *     Instances (SOP Instances - images or other data) acquired or produced in a
 *     common context.  A series is of only one modality (e.g. X-ray, CT, MR,
 *     ultrasound), but a study may have multiple series of different modalities.
 */
export type ImagingStudyPerformer = {
  __typename?: 'ImagingStudyPerformer';
  /** Indicates who or what performed the series. */
  actor: ImagingStudyPerformerActor;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Distinguishes the type of involvement of the performer in the series. */
  function?: Maybe<CodeableConcept>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type ImagingStudyPerformerActor = CareTeam | Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type ImagingStudyReasonReference = Condition | DiagnosticReport | DocumentReference | Media | Observation;

export type ImagingStudyReferrer = Practitioner | PractitionerRole;

/**
 * ImagingStudy.Series
 *     Representation of the content produced in a DICOM imaging study. A study
 *     comprises a set of series, each of which includes a set of Service-Object Pair
 *     Instances (SOP Instances - images or other data) acquired or produced in a
 *     common context.  A series is of only one modality (e.g. X-ray, CT, MR,
 *     ultrasound), but a study may have multiple series of different modalities.
 */
export type ImagingStudySeries = {
  __typename?: 'ImagingStudySeries';
  /**
   * The anatomic structures examined. See DICOM Part 16 Annex L (http://dicom.nema
   * .org/medical/dicom/current/output/chtml/part16/chapter_L.html) for DICOM to
   * SNOMED-CT mappings. The bodySite may indicate the laterality of body part
   * imaged; if so, it shall be consistent with any content of
   * ImagingStudy.series.laterality.
   */
  bodySite?: Maybe<Coding>;
  /** A description of the series. */
  description?: Maybe<Scalars['code']>;
  /**
   * The network service providing access (e.g., query, view, or retrieval) for
   * this series. See implementation notes for information about using DICOM
   * endpoints. A series-level endpoint, if present, has precedence over a study-
   * level endpoint with the same Endpoint.connectionType.
   */
  endpoint?: Maybe<Array<Maybe<Endpoint>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** A single SOP instance within the series, e.g. an image, or presentation state. */
  instance?: Maybe<Array<Maybe<ImagingStudyInstance>>>;
  /**
   * The laterality of the (possibly paired) anatomic structures examined. E.g.,
   * the left knee, both lungs, or unpaired abdomen. If present, shall be
   * consistent with any laterality information indicated in
   * ImagingStudy.series.bodySite.
   */
  laterality?: Maybe<Coding>;
  /** The modality of this series sequence. */
  modality: Coding;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The numeric identifier of this series in the study. */
  number?: Maybe<Scalars['code']>;
  /**
   * Number of SOP Instances in the Study. The value given may be larger than the
   * number of instance elements this resource contains due to resource
   * availability, security, or other factors. This element should be present if
   * any instance elements are present.
   */
  numberOfInstances?: Maybe<Scalars['code']>;
  /** Indicates who or what performed the series and how they were involved. */
  performer?: Maybe<Array<Maybe<ImagingStudyPerformer>>>;
  /** The specimen imaged, e.g., for whole slide imaging of a biopsy. */
  specimen?: Maybe<Array<Maybe<Specimen>>>;
  /** The date and time the series was started. */
  started?: Maybe<Scalars['code']>;
  /** The DICOM Series Instance UID for the series. */
  uid: Scalars['code'];
};

export type ImagingStudySubject = Device | Group | Patient;

/**
 * Immunization
 *     Describes the event of a patient being administered a vaccine or a record of
 *     an immunization as reported by a patient, a clinician or another party.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Immunization = {
  __typename?: 'Immunization';
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The quantity of vaccine product that was administered. */
  doseQuantity?: Maybe<Quantity>;
  /**
   * Educational material presented to the patient (or guardian) at the time of
   * vaccine administration.
   */
  education?: Maybe<Array<Maybe<ImmunizationEducation>>>;
  /**
   * The visit or admission or other contact between patient and health care
   * provider the immunization was performed as part of.
   */
  encounter?: Maybe<Encounter>;
  /** Date vaccine batch expires. */
  expirationDate?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Indicates the source of the vaccine actually administered. This may be
   * different than the patient eligibility (e.g. the patient may be eligible for a
   * publically purchased vaccine but due to inventory issues, vaccine purchased
   * with private funds was actually administered).
   */
  fundingSource?: Maybe<CodeableConcept>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** A unique identifier assigned to this immunization record. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * Indication if a dose is considered to be subpotent. By default, a dose should
   * be considered to be potent.
   */
  isSubpotent?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** The service delivery location where the vaccine administration occurred. */
  location?: Maybe<Location>;
  /** Lot number of the  vaccine product. */
  lotNumber?: Maybe<Scalars['code']>;
  /** Name of vaccine manufacturer. */
  manufacturer?: Maybe<Organization>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Extra information about the immunization that is not conveyed by the other
   * attributes.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** None */
  occurrenceDateTime?: Maybe<Scalars['code']>;
  /** None */
  occurrenceString?: Maybe<Scalars['code']>;
  /** The patient who either received or did not receive the immunization. */
  patient: Patient;
  /** Indicates who performed the immunization event. */
  performer?: Maybe<Array<Maybe<ImmunizationPerformer>>>;
  /**
   * An indication that the content of the record is based on information from the
   * person who administered the vaccine. This reflects the context under which the
   * data was originally recorded.
   */
  primarySource?: Maybe<Scalars['code']>;
  /** Indicates a patient's eligibility for a funding program. */
  programEligibility?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The protocol (set of recommendations) being followed by the provider who
   * administered the dose.
   */
  protocolApplied?: Maybe<Array<Maybe<ImmunizationProtocolApplied>>>;
  /**
   * Categorical data indicating that an adverse event is associated in time to an
   * immunization.
   */
  reaction?: Maybe<Array<Maybe<ImmunizationReaction>>>;
  /** Reasons why the vaccine was administered. */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Condition, Observation or DiagnosticReport that supports why the immunization
   * was administered.
   */
  reasonReference?: Maybe<Array<Maybe<ImmunizationReasonReference>>>;
  /**
   * The date the occurrence of the immunization was first captured in the record -
   * potentially significantly after the occurrence of the event.
   */
  recorded?: Maybe<Scalars['code']>;
  /**
   * The source of the data when the report of the immunization event is not based
   * on information from the person who administered the vaccine.
   */
  reportOrigin?: Maybe<CodeableConcept>;
  resourceType?: Maybe<Scalars['String']>;
  /** The path by which the vaccine product is taken into the body. */
  route?: Maybe<CodeableConcept>;
  /** Body site where vaccine was administered. */
  site?: Maybe<CodeableConcept>;
  /** Indicates the current status of the immunization event. */
  status: Scalars['code'];
  /** Indicates the reason the immunization event was not performed. */
  statusReason?: Maybe<CodeableConcept>;
  /** Reason why a dose is considered to be subpotent. */
  subpotentReason?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** Vaccine that was administered or was to be administered. */
  vaccineCode: CodeableConcept;
};

/**
 * Immunization.Education
 *     Describes the event of a patient being administered a vaccine or a record of
 *     an immunization as reported by a patient, a clinician or another party.
 */
export type ImmunizationEducation = {
  __typename?: 'ImmunizationEducation';
  /** Identifier of the material presented to the patient. */
  documentType?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Date the educational material was given to the patient. */
  presentationDate?: Maybe<Scalars['code']>;
  /** Date the educational material was published. */
  publicationDate?: Maybe<Scalars['code']>;
  /**
   * Reference pointer to the educational material given to the patient if the
   * information was on line.
   */
  reference?: Maybe<Scalars['code']>;
};

/**
 * ImmunizationEvaluation
 *     Describes a comparison of an immunization event against published
 *     recommendations to determine if the administration is "valid" in relation to
 *     those  recommendations.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ImmunizationEvaluation = {
  __typename?: 'ImmunizationEvaluation';
  /** Indicates the authority who published the protocol (e.g. ACIP). */
  authority?: Maybe<Organization>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The date the evaluation of the vaccine administration event was performed. */
  date?: Maybe<Scalars['code']>;
  /** Additional information about the evaluation. */
  description?: Maybe<Scalars['code']>;
  /** None */
  doseNumberPositiveInt?: Maybe<Scalars['code']>;
  /** None */
  doseNumberString?: Maybe<Scalars['code']>;
  /**
   * Indicates if the dose is valid or not valid with respect to the published
   * recommendations.
   */
  doseStatus: CodeableConcept;
  /**
   * Provides an explanation as to why the vaccine administration event is valid or
   * not relative to the published recommendations.
   */
  doseStatusReason?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** A unique identifier assigned to this immunization evaluation record. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /** The vaccine administration event being evaluated. */
  immunizationEvent: Immunization;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The individual for whom the evaluation is being done. */
  patient: Patient;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * One possible path to achieve presumed immunity against a disease - within the
   * context of an authority.
   */
  series?: Maybe<Scalars['code']>;
  /** None */
  seriesDosesPositiveInt?: Maybe<Scalars['code']>;
  /** None */
  seriesDosesString?: Maybe<Scalars['code']>;
  /**
   * Indicates the current status of the evaluation of the vaccination
   * administration event.
   */
  status: Scalars['code'];
  /** The vaccine preventable disease the dose is being evaluated against. */
  targetDisease: CodeableConcept;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * Immunization.Performer
 *     Describes the event of a patient being administered a vaccine or a record of
 *     an immunization as reported by a patient, a clinician or another party.
 */
export type ImmunizationPerformer = {
  __typename?: 'ImmunizationPerformer';
  /** The practitioner or organization who performed the action. */
  actor: ImmunizationPerformerActor;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Describes the type of performance (e.g. ordering provider, administering
   * provider, etc.).
   */
  function?: Maybe<CodeableConcept>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type ImmunizationPerformerActor = Organization | Practitioner | PractitionerRole;

/**
 * Immunization.ProtocolApplied
 *     Describes the event of a patient being administered a vaccine or a record of
 *     an immunization as reported by a patient, a clinician or another party.
 */
export type ImmunizationProtocolApplied = {
  __typename?: 'ImmunizationProtocolApplied';
  /**
   * Indicates the authority who published the protocol (e.g. ACIP) that is being
   * followed.
   */
  authority?: Maybe<Organization>;
  /** None */
  doseNumberPositiveInt?: Maybe<Scalars['code']>;
  /** None */
  doseNumberString?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * One possible path to achieve presumed immunity against a disease - within the
   * context of an authority.
   */
  series?: Maybe<Scalars['code']>;
  /** None */
  seriesDosesPositiveInt?: Maybe<Scalars['code']>;
  /** None */
  seriesDosesString?: Maybe<Scalars['code']>;
  /** The vaccine preventable disease the dose is being administered against. */
  targetDisease?: Maybe<Array<Maybe<CodeableConcept>>>;
};

/**
 * Immunization.Reaction
 *     Describes the event of a patient being administered a vaccine or a record of
 *     an immunization as reported by a patient, a clinician or another party.
 */
export type ImmunizationReaction = {
  __typename?: 'ImmunizationReaction';
  /** Date of reaction to the immunization. */
  date?: Maybe<Scalars['code']>;
  /** Details of the reaction. */
  detail?: Maybe<Observation>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Self-reported indicator. */
  reported?: Maybe<Scalars['code']>;
};

export type ImmunizationReasonReference = Condition | DiagnosticReport | Observation;

/**
 * ImmunizationRecommendation
 *     A patient's point-in-time set of recommendations (i.e. forecasting) according
 *     to a published schedule with optional supporting justification.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ImmunizationRecommendation = {
  __typename?: 'ImmunizationRecommendation';
  /** Indicates the authority who published the protocol (e.g. ACIP). */
  authority?: Maybe<Organization>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The date the immunization recommendation(s) were created. */
  date: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** A unique identifier assigned to this particular recommendation record. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The patient the recommendation(s) are for. */
  patient: Patient;
  /** Vaccine administration recommendations. */
  recommendation: Array<Maybe<ImmunizationRecommendationRecommendation>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * ImmunizationRecommendation.DateCriterion
 *     A patient's point-in-time set of recommendations (i.e. forecasting) according
 *     to a published schedule with optional supporting justification.
 */
export type ImmunizationRecommendationDateCriterion = {
  __typename?: 'ImmunizationRecommendationDateCriterion';
  /**
   * Date classification of recommendation.  For example, earliest date to give,
   * latest date to give, etc.
   */
  code: CodeableConcept;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The date whose meaning is specified by dateCriterion.code. */
  value: Scalars['code'];
};

/**
 * ImmunizationRecommendation.Recommendation
 *     A patient's point-in-time set of recommendations (i.e. forecasting) according
 *     to a published schedule with optional supporting justification.
 */
export type ImmunizationRecommendationRecommendation = {
  __typename?: 'ImmunizationRecommendationRecommendation';
  /** Vaccine(s) which should not be used to fulfill the recommendation. */
  contraindicatedVaccineCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Vaccine date recommendations.  For example, earliest date to administer,
   * latest date to administer, etc.
   */
  dateCriterion?: Maybe<Array<Maybe<ImmunizationRecommendationDateCriterion>>>;
  /**
   * Contains the description about the protocol under which the vaccine was
   * administered.
   */
  description?: Maybe<Scalars['code']>;
  /** None */
  doseNumberPositiveInt?: Maybe<Scalars['code']>;
  /** None */
  doseNumberString?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** The reason for the assigned forecast status. */
  forecastReason?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Indicates the patient status with respect to the path to immunity for the
   * target disease.
   */
  forecastStatus: CodeableConcept;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * One possible path to achieve presumed immunity against a disease - within the
   * context of an authority.
   */
  series?: Maybe<Scalars['code']>;
  /** None */
  seriesDosesPositiveInt?: Maybe<Scalars['code']>;
  /** None */
  seriesDosesString?: Maybe<Scalars['code']>;
  /**
   * Immunization event history and/or evaluation that supports the status and
   * recommendation.
   */
  supportingImmunization?: Maybe<Array<Maybe<ImmunizationRecommendationRecommendationSupportingImmunization>>>;
  /**
   * Patient Information that supports the status and recommendation.  This
   * includes patient observations, adverse reactions and allergy/intolerance
   * information.
   */
  supportingPatientInformation?: Maybe<Array<Maybe<Resource>>>;
  /** The targeted disease for the recommendation. */
  targetDisease?: Maybe<CodeableConcept>;
  /** Vaccine(s) or vaccine group that pertain to the recommendation. */
  vaccineCode?: Maybe<Array<Maybe<CodeableConcept>>>;
};

export type ImmunizationRecommendationRecommendationSupportingImmunization = Immunization | ImmunizationEvaluation;

/**
 * ImplementationGuide
 *     A set of rules of how a particular interoperability or standards problem is
 *     solved - typically through the use of FHIR resources. This resource is used to
 *     gather all the parts of an implementation guide into a logical whole and to
 *     publish a computable definition of all the parts.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ImplementationGuide = {
  __typename?: 'ImplementationGuide';
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the implementation guide and/or its
   * contents. Copyright statements are generally legal restrictions on the use and
   * publishing of the implementation guide.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the implementation guide was published.
   * The date must change when the business version changes and it must change if
   * the status code changes. In addition, it should change when the substantive
   * content of the implementation guide changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * The information needed by an IG publisher tool to publish the whole
   * implementation guide.
   */
  definition?: Maybe<ImplementationGuideDefinition>;
  /**
   * Another implementation guide that this implementation depends on. Typically,
   * an implementation guide uses value sets, profiles etc.defined in other
   * implementation guides.
   */
  dependsOn?: Maybe<Array<Maybe<ImplementationGuideDependsOn>>>;
  /**
   * A free text natural language description of the implementation guide from a
   * consumer's perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * A Boolean value to indicate that this implementation guide is authored for
   * testing purposes (or education/evaluation/marketing) and is not intended to be
   * used for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The version(s) of the FHIR specification that this ImplementationGuide targets
   * - e.g. describes how to use. The value of this element is the formal version
   * of the specification, without the revision number, e.g.
   * [publication].[major].[minor], which is 4.0.1. for this version.
   */
  fhirVersion: Array<Maybe<Scalars['code']>>;
  /**
   * A set of profiles that all resources covered by this implementation guide must
   * conform to.
   */
  global?: Maybe<Array<Maybe<ImplementationGuideGlobal>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A legal or geographic region in which the implementation guide is intended to
   * be used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The license that applies to this Implementation Guide, using an SPDX license
   * code, or 'not-open-source'.
   */
  license?: Maybe<Scalars['code']>;
  /**
   * Information about an assembled implementation guide, created by the
   * publication tooling.
   */
  manifest?: Maybe<ImplementationGuideManifest>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the implementation guide. This name should
   * be usable as an identifier for the module by machine processing applications
   * such as code generation.
   */
  name: Scalars['code'];
  /**
   * The NPM package name for this Implementation Guide, used in the NPM package
   * distribution, which is the primary mechanism by which FHIR based tooling
   * manages IG dependencies. This value must be globally unique, and should be
   * assigned with care.
   */
  packageId: Scalars['code'];
  /**
   * The name of the organization or individual that published the implementation
   * guide.
   */
  publisher?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The status of this implementation guide. Enables tracking the life-cycle of
   * the content.
   */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the implementation guide. */
  title?: Maybe<Scalars['code']>;
  /**
   * An absolute URI that is used to identify this implementation guide when it is
   * referenced in a specification, model, design or an instance; also called its
   * canonical identifier. This SHOULD be globally unique and SHOULD be a literal
   * address at which at which an authoritative instance of this implementation
   * guide is (or will be) published. This URL can be the target of a canonical
   * reference. It SHALL remain the same when the implementation guide is stored on
   * different servers.
   */
  url: Scalars['code'];
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate
   * implementation guide instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the implementation
   * guide when it is referenced in a specification, model, design or instance.
   * This is an arbitrary value managed by the implementation guide author and is
   * not expected to be globally unique. For example, it might be a timestamp (e.g.
   * yyyymmdd) if a managed version is not available. There is also no expectation
   * that versions can be placed in a lexicographical sequence.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * ImplementationGuide.Definition
 *     A set of rules of how a particular interoperability or standards problem is
 *     solved - typically through the use of FHIR resources. This resource is used to
 *     gather all the parts of an implementation guide into a logical whole and to
 *     publish a computable definition of all the parts.
 */
export type ImplementationGuideDefinition = {
  __typename?: 'ImplementationGuideDefinition';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** A logical group of resources. Logical groups can be used when building pages. */
  grouping?: Maybe<Array<Maybe<ImplementationGuideGrouping>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A page / section in the implementation guide. The root page is the
   * implementation guide home page.
   */
  page?: Maybe<ImplementationGuidePage>;
  /** Defines how IG is built by tools. */
  parameter?: Maybe<Array<Maybe<ImplementationGuideParameter>>>;
  /**
   * A resource that is part of the implementation guide. Conformance resources
   * (value set, structure definition, capability statements etc.) are obvious
   * candidates for inclusion, but any kind of resource can be included as an
   * example resource.
   */
  resource: Array<Maybe<ImplementationGuideResource>>;
  /** A template for building resources. */
  template?: Maybe<Array<Maybe<ImplementationGuideTemplate>>>;
};

/**
 * ImplementationGuide.DependsOn
 *     A set of rules of how a particular interoperability or standards problem is
 *     solved - typically through the use of FHIR resources. This resource is used to
 *     gather all the parts of an implementation guide into a logical whole and to
 *     publish a computable definition of all the parts.
 */
export type ImplementationGuideDependsOn = {
  __typename?: 'ImplementationGuideDependsOn';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The NPM package name for the Implementation Guide that this IG depends on. */
  packageId?: Maybe<Scalars['code']>;
  /** A canonical reference to the Implementation guide for the dependency. */
  uri: Scalars['code'];
  /**
   * The version of the IG that is depended on, when the correct version is
   * required to understand the IG correctly.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * ImplementationGuide.Global
 *     A set of rules of how a particular interoperability or standards problem is
 *     solved - typically through the use of FHIR resources. This resource is used to
 *     gather all the parts of an implementation guide into a logical whole and to
 *     publish a computable definition of all the parts.
 */
export type ImplementationGuideGlobal = {
  __typename?: 'ImplementationGuideGlobal';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A reference to the profile that all instances must conform to. */
  profile: Scalars['code'];
  /** The type of resource that all instances must conform to. */
  type: Scalars['code'];
};

/**
 * ImplementationGuide.Grouping
 *     A set of rules of how a particular interoperability or standards problem is
 *     solved - typically through the use of FHIR resources. This resource is used to
 *     gather all the parts of an implementation guide into a logical whole and to
 *     publish a computable definition of all the parts.
 */
export type ImplementationGuideGrouping = {
  __typename?: 'ImplementationGuideGrouping';
  /** Human readable text describing the package. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The human-readable title to display for the package of resources when
   * rendering the implementation guide.
   */
  name: Scalars['code'];
};

/**
 * ImplementationGuide.Manifest
 *     A set of rules of how a particular interoperability or standards problem is
 *     solved - typically through the use of FHIR resources. This resource is used to
 *     gather all the parts of an implementation guide into a logical whole and to
 *     publish a computable definition of all the parts.
 */
export type ImplementationGuideManifest = {
  __typename?: 'ImplementationGuideManifest';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Indicates a relative path to an image that exists within the IG. */
  image?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Indicates the relative path of an additional non-page, non-image file that is
   * part of the IG - e.g. zip, jar and similar files that could be the target of a
   * hyperlink in a derived IG.
   */
  other?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Information about a page within the IG. */
  page?: Maybe<Array<Maybe<ImplementationGuidePage1>>>;
  /**
   * A pointer to official web page, PDF or other rendering of the implementation
   * guide.
   */
  rendering?: Maybe<Scalars['code']>;
  /**
   * A resource that is part of the implementation guide. Conformance resources
   * (value set, structure definition, capability statements etc.) are obvious
   * candidates for inclusion, but any kind of resource can be included as an
   * example resource.
   */
  resource: Array<Maybe<ImplementationGuideResource1>>;
};

/**
 * ImplementationGuide.Page
 *     A set of rules of how a particular interoperability or standards problem is
 *     solved - typically through the use of FHIR resources. This resource is used to
 *     gather all the parts of an implementation guide into a logical whole and to
 *     publish a computable definition of all the parts.
 */
export type ImplementationGuidePage = {
  __typename?: 'ImplementationGuidePage';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** A code that indicates how the page is generated. */
  generation: Scalars['code'];
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  nameReference?: Maybe<Binary>;
  /** None */
  nameUrl?: Maybe<Scalars['code']>;
  /** Nested Pages/Sections under this page. */
  page?: Maybe<Array<Maybe<ImplementationGuidePage>>>;
  /**
   * A short title used to represent this page in navigational structures such as
   * table of contents, bread crumbs, etc.
   */
  title: Scalars['code'];
};

/**
 * ImplementationGuide.Page1
 *     A set of rules of how a particular interoperability or standards problem is
 *     solved - typically through the use of FHIR resources. This resource is used to
 *     gather all the parts of an implementation guide into a logical whole and to
 *     publish a computable definition of all the parts.
 */
export type ImplementationGuidePage1 = {
  __typename?: 'ImplementationGuidePage1';
  /** The name of an anchor available on the page. */
  anchor?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Relative path to the page. */
  name: Scalars['code'];
  /** Label for the page intended for human display. */
  title?: Maybe<Scalars['code']>;
};

/**
 * ImplementationGuide.Parameter
 *     A set of rules of how a particular interoperability or standards problem is
 *     solved - typically through the use of FHIR resources. This resource is used to
 *     gather all the parts of an implementation guide into a logical whole and to
 *     publish a computable definition of all the parts.
 */
export type ImplementationGuideParameter = {
  __typename?: 'ImplementationGuideParameter';
  /**
   * apply | path-resource | path-pages | path-tx-cache | expansion-parameter |
   * rule-broken-links | generate-xml | generate-json | generate-turtle | html-
   * template.
   */
  code: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Value for named type. */
  value: Scalars['code'];
};

/**
 * ImplementationGuide.Resource
 *     A set of rules of how a particular interoperability or standards problem is
 *     solved - typically through the use of FHIR resources. This resource is used to
 *     gather all the parts of an implementation guide into a logical whole and to
 *     publish a computable definition of all the parts.
 */
export type ImplementationGuideResource = {
  __typename?: 'ImplementationGuideResource';
  /**
   * A description of the reason that a resource has been included in the
   * implementation guide.
   */
  description?: Maybe<Scalars['code']>;
  /** None */
  exampleBoolean?: Maybe<Scalars['code']>;
  /** None */
  exampleCanonical?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Indicates the FHIR Version(s) this artifact is intended to apply to. If no
   * versions are specified, the resource is assumed to apply to all the versions
   * stated in ImplementationGuide.fhirVersion.
   */
  fhirVersion?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Reference to the id of the grouping this resource appears in. */
  groupingId?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A human assigned name for the resource. All resources SHOULD have a name, but
   * the name may be extracted from the resource (e.g. ValueSet.name).
   */
  name?: Maybe<Scalars['code']>;
  /** Where this resource is found. */
  reference: Resource;
};

/**
 * ImplementationGuide.Resource1
 *     A set of rules of how a particular interoperability or standards problem is
 *     solved - typically through the use of FHIR resources. This resource is used to
 *     gather all the parts of an implementation guide into a logical whole and to
 *     publish a computable definition of all the parts.
 */
export type ImplementationGuideResource1 = {
  __typename?: 'ImplementationGuideResource1';
  /** None */
  exampleBoolean?: Maybe<Scalars['code']>;
  /** None */
  exampleCanonical?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Where this resource is found. */
  reference: Resource;
  /** The relative path for primary page for this resource within the IG. */
  relativePath?: Maybe<Scalars['code']>;
};

/**
 * ImplementationGuide.Template
 *     A set of rules of how a particular interoperability or standards problem is
 *     solved - typically through the use of FHIR resources. This resource is used to
 *     gather all the parts of an implementation guide into a logical whole and to
 *     publish a computable definition of all the parts.
 */
export type ImplementationGuideTemplate = {
  __typename?: 'ImplementationGuideTemplate';
  /** Type of template specified. */
  code: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The scope in which the template applies. */
  scope?: Maybe<Scalars['code']>;
  /** The source location for the template. */
  source: Scalars['code'];
};

/**
 * InsurancePlan
 *     Details of a Health Insurance product/plan provided by an organization.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type InsurancePlan = {
  __typename?: 'InsurancePlan';
  /**
   * An organization which administer other services such as underwriting, customer
   * service and/or claims processing on behalf of the health insurance product
   * owner.
   */
  administeredBy?: Maybe<Organization>;
  /**
   * A list of alternate names that the product is known as, or was known as in the
   * past.
   */
  alias?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The contact for the health insurance product for a certain purpose. */
  contact?: Maybe<Array<Maybe<InsurancePlanContact>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Details about the coverage offered by the insurance product. */
  coverage?: Maybe<Array<Maybe<InsurancePlanCoverage>>>;
  /** The geographic region in which a health insurance product's benefits apply. */
  coverageArea?: Maybe<Array<Maybe<Location>>>;
  /**
   * The technical endpoints providing access to services operated for the health
   * insurance product.
   */
  endpoint?: Maybe<Array<Maybe<Endpoint>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Business identifiers assigned to this health insurance product which remain
   * constant as the resource is updated and propagates from server to server.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Official name of the health insurance product as designated by the owner. */
  name?: Maybe<Scalars['code']>;
  /** Reference to the network included in the health insurance product. */
  network?: Maybe<Array<Maybe<Organization>>>;
  /**
   * The entity that is providing  the health insurance product and underwriting
   * the risk.  This is typically an insurance carriers, other third-party payers,
   * or health plan sponsors comonly referred to as 'payers'.
   */
  ownedBy?: Maybe<Organization>;
  /** The period of time that the health insurance product is available. */
  period?: Maybe<Period>;
  /** Details about an insurance plan. */
  plan?: Maybe<Array<Maybe<InsurancePlanPlan>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** The current state of the health insurance product. */
  status?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** The kind of health insurance product. */
  type?: Maybe<Array<Maybe<CodeableConcept>>>;
};

/**
 * InsurancePlan.Benefit
 *     Details of a Health Insurance product/plan provided by an organization.
 */
export type InsurancePlanBenefit = {
  __typename?: 'InsurancePlanBenefit';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The specific limits on the benefit. */
  limit?: Maybe<Array<Maybe<InsurancePlanLimit>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The referral requirements to have access/coverage for this benefit. */
  requirement?: Maybe<Scalars['code']>;
  /** Type of benefit (primary care; speciality care; inpatient; outpatient). */
  type: CodeableConcept;
};

/**
 * InsurancePlan.Benefit1
 *     Details of a Health Insurance product/plan provided by an organization.
 */
export type InsurancePlanBenefit1 = {
  __typename?: 'InsurancePlanBenefit1';
  /** List of the costs associated with a specific benefit. */
  cost?: Maybe<Array<Maybe<InsurancePlanCost>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Type of specific benefit (preventative; primary care office visit; speciality
   * office visit; hospitalization; emergency room; urgent care).
   */
  type: CodeableConcept;
};

/**
 * InsurancePlan.Contact
 *     Details of a Health Insurance product/plan provided by an organization.
 */
export type InsurancePlanContact = {
  __typename?: 'InsurancePlanContact';
  /** Visiting or postal addresses for the contact. */
  address?: Maybe<Address>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A name associated with the contact. */
  name?: Maybe<HumanName>;
  /** Indicates a purpose for which the contact can be reached. */
  purpose?: Maybe<CodeableConcept>;
  /**
   * A contact detail (e.g. a telephone number or an email address) by which the
   * party may be contacted.
   */
  telecom?: Maybe<Array<Maybe<ContactPoint>>>;
};

/**
 * InsurancePlan.Cost
 *     Details of a Health Insurance product/plan provided by an organization.
 */
export type InsurancePlanCost = {
  __typename?: 'InsurancePlanCost';
  /**
   * Whether the cost applies to in-network or out-of-network providers (in-
   * network; out-of-network; other).
   */
  applicability?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Additional information about the cost, such as information about funding
   * sources (e.g. HSA, HRA, FSA, RRA).
   */
  qualifiers?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Type of cost (copay; individual cap; family cap; coinsurance; deductible). */
  type: CodeableConcept;
  /**
   * The actual cost value. (some of the costs may be represented as percentages
   * rather than currency, e.g. 10% coinsurance).
   */
  value?: Maybe<Quantity>;
};

/**
 * InsurancePlan.Coverage
 *     Details of a Health Insurance product/plan provided by an organization.
 */
export type InsurancePlanCoverage = {
  __typename?: 'InsurancePlanCoverage';
  /** Specific benefits under this type of coverage. */
  benefit: Array<Maybe<InsurancePlanBenefit>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Reference to the network that providing the type of coverage. */
  network?: Maybe<Array<Maybe<Organization>>>;
  /**
   * Type of coverage  (Medical; Dental; Mental Health; Substance Abuse; Vision;
   * Drug; Short Term; Long Term Care; Hospice; Home Health).
   */
  type: CodeableConcept;
};

/**
 * InsurancePlan.GeneralCost
 *     Details of a Health Insurance product/plan provided by an organization.
 */
export type InsurancePlanGeneralCost = {
  __typename?: 'InsurancePlanGeneralCost';
  /** Additional information about the general costs associated with this plan. */
  comment?: Maybe<Scalars['code']>;
  /** Value of the cost. */
  cost?: Maybe<Money>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Number of participants enrolled in the plan. */
  groupSize?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Type of cost. */
  type?: Maybe<CodeableConcept>;
};

/**
 * InsurancePlan.Limit
 *     Details of a Health Insurance product/plan provided by an organization.
 */
export type InsurancePlanLimit = {
  __typename?: 'InsurancePlanLimit';
  /** The specific limit on the benefit. */
  code?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The maximum amount of a service item a plan will pay for a covered benefit.
   * For examples. wellness visits, or eyeglasses.
   */
  value?: Maybe<Quantity>;
};

/**
 * InsurancePlan.Plan
 *     Details of a Health Insurance product/plan provided by an organization.
 */
export type InsurancePlanPlan = {
  __typename?: 'InsurancePlanPlan';
  /** The geographic region in which a health insurance plan's benefits apply. */
  coverageArea?: Maybe<Array<Maybe<Location>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Overall costs associated with the plan. */
  generalCost?: Maybe<Array<Maybe<InsurancePlanGeneralCost>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Business identifiers assigned to this health insurance plan which remain
   * constant as the resource is updated and propagates from server to server.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Reference to the network that providing the type of coverage. */
  network?: Maybe<Array<Maybe<Organization>>>;
  /** Costs associated with the coverage provided by the product. */
  specificCost?: Maybe<Array<Maybe<InsurancePlanSpecificCost>>>;
  /** Type of plan. For example, "Platinum" or "High Deductable". */
  type?: Maybe<CodeableConcept>;
};

/**
 * InsurancePlan.SpecificCost
 *     Details of a Health Insurance product/plan provided by an organization.
 */
export type InsurancePlanSpecificCost = {
  __typename?: 'InsurancePlanSpecificCost';
  /** List of the specific benefits under this category of benefit. */
  benefit?: Maybe<Array<Maybe<InsurancePlanBenefit1>>>;
  /**
   * General category of benefit (Medical; Dental; Vision; Drug; Mental Health;
   * Substance Abuse; Hospice, Home Health).
   */
  category: CodeableConcept;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * Invoice
 *     Invoice containing collected ChargeItems from an Account with calculated
 *     individual and total price for Billing purpose.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Invoice = {
  __typename?: 'Invoice';
  /** Account which is supposed to be balanced with this Invoice. */
  account?: Maybe<Account>;
  /**
   * In case of Invoice cancellation a reason must be given (entered in error,
   * superseded by corrected invoice etc.).
   */
  cancelledReason?: Maybe<Scalars['code']>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Date/time(s) of when this Invoice was posted. */
  date?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifier of this Invoice, often used for reference in correspondence about
   * this invoice or for tracking of payments.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The organizationissuing the Invoice. */
  issuer?: Maybe<Organization>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * Each line item represents one charge for goods and services rendered. Details
   * such as date, code and amount are found in the referenced ChargeItem resource.
   */
  lineItem?: Maybe<Array<Maybe<InvoiceLineItem>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Comments made about the invoice by the issuer, subject, or other participants. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** Indicates who or what performed or participated in the charged service. */
  participant?: Maybe<Array<Maybe<InvoiceParticipant>>>;
  /**
   * Payment details such as banking details, period of payment, deductibles,
   * methods of payment.
   */
  paymentTerms?: Maybe<Scalars['code']>;
  /** The individual or Organization responsible for balancing of this invoice. */
  recipient?: Maybe<InvoiceRecipient>;
  resourceType?: Maybe<Scalars['String']>;
  /** The current state of the Invoice. */
  status: Scalars['code'];
  /**
   * The individual or set of individuals receiving the goods and services billed
   * in this invoice.
   */
  subject?: Maybe<InvoiceSubject>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** Invoice total, tax included. */
  totalGross?: Maybe<Money>;
  /** Invoice total , taxes excluded. */
  totalNet?: Maybe<Money>;
  /**
   * The total amount for the Invoice may be calculated as the sum of the line
   * items with surcharges/deductions that apply in certain conditions.  The
   * priceComponent element can be used to offer transparency to the recipient of
   * the Invoice of how the total price was calculated.
   */
  totalPriceComponent?: Maybe<Array<Maybe<InvoicePriceComponent>>>;
  /**
   * Type of Invoice depending on domain, realm an usage (e.g. internal/external,
   * dental, preliminary).
   */
  type?: Maybe<CodeableConcept>;
};

/**
 * Invoice.LineItem
 *     Invoice containing collected ChargeItems from an Account with calculated
 *     individual and total price for Billing purpose.
 */
export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  /** None */
  chargeItemCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  chargeItemReference?: Maybe<ChargeItem>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The price for a ChargeItem may be calculated as a base price with
   * surcharges/deductions that apply in certain conditions. A ChargeItemDefinition
   * resource that defines the prices, factors and conditions that apply to a
   * billing code is currently under development. The priceComponent element can be
   * used to offer transparency to the recipient of the Invoice as to how the
   * prices have been calculated.
   */
  priceComponent?: Maybe<Array<Maybe<InvoicePriceComponent>>>;
  /** Sequence in which the items appear on the invoice. */
  sequence?: Maybe<Scalars['code']>;
};

/**
 * Invoice.Participant
 *     Invoice containing collected ChargeItems from an Account with calculated
 *     individual and total price for Billing purpose.
 */
export type InvoiceParticipant = {
  __typename?: 'InvoiceParticipant';
  /** The device, practitioner, etc. who performed or participated in the service. */
  actor: InvoiceParticipantActor;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Describes the type of involvement (e.g. transcriptionist, creator etc.). If
   * the invoice has been created automatically, the Participant may be a billing
   * engine or another kind of device.
   */
  role?: Maybe<CodeableConcept>;
};

export type InvoiceParticipantActor = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Invoice.PriceComponent
 *     Invoice containing collected ChargeItems from an Account with calculated
 *     individual and total price for Billing purpose.
 */
export type InvoicePriceComponent = {
  __typename?: 'InvoicePriceComponent';
  /** The amount calculated for this component. */
  amount?: Maybe<Money>;
  /**
   * A code that identifies the component. Codes may be used to differentiate
   * between kinds of taxes, surcharges, discounts etc.
   */
  code?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The factor that has been applied on the base price for calculating this
   * component.
   */
  factor?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** This code identifies the type of the component. */
  type: Scalars['code'];
};

export type InvoiceRecipient = Organization | Patient | RelatedPerson;

export type InvoiceSubject = Group | Patient;

/**
 * Library
 *     The Library resource is a general-purpose container for knowledge asset
 *     definitions. It can be used to describe and expose existing knowledge assets
 *     such as logic libraries and information model descriptions, as well as to
 *     describe a collection of knowledge assets.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Library = {
  __typename?: 'Library';
  /**
   * The date on which the resource content was approved by the publisher. Approval
   * happens once when the content is officially approved for usage.
   */
  approvalDate?: Maybe<Scalars['code']>;
  /**
   * An individiual or organization primarily involved in the creation and
   * maintenance of the content.
   */
  author?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The content of the library as an Attachment. The content may be a reference to
   * a url, or may be directly embedded as a base-64 string. Either way, the
   * contentType of the attachment determines how to interpret the content.
   */
  content?: Maybe<Array<Maybe<Attachment>>>;
  /**
   * A copyright statement relating to the library and/or its contents. Copyright
   * statements are generally legal restrictions on the use and publishing of the
   * library.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * Describes a set of data that must be provided in order to be able to
   * successfully perform the computations defined by the library.
   */
  dataRequirement?: Maybe<Array<Maybe<DataRequirement>>>;
  /**
   * The date  (and optionally time) when the library was published. The date must
   * change when the business version changes and it must change if the status code
   * changes. In addition, it should change when the substantive content of the
   * library changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the library from a consumer's
   * perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * An individual or organization primarily responsible for internal coherence of
   * the content.
   */
  editor?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * The period during which the library content was or is planned to be in active
   * use.
   */
  effectivePeriod?: Maybe<Period>;
  /**
   * An individual or organization responsible for officially endorsing the content
   * for use in some setting.
   */
  endorser?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * A Boolean value to indicate that this library is authored for testing purposes
   * (or education/evaluation/marketing) and is not intended to be used for genuine
   * usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this library when it is
   * represented in other formats, or referenced in a specification, model, design
   * or an instance. e.g. CMS or NQF identifiers for a measure artifact. Note that
   * at least one identifier is required for non-experimental active artifacts.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** A legal or geographic region in which the library is intended to be used. */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The date on which the resource content was last reviewed. Review happens
   * periodically after approval but does not change the original approval date.
   */
  lastReviewDate?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the library. This name should be usable as
   * an identifier for the module by machine processing applications such as code
   * generation.
   */
  name?: Maybe<Scalars['code']>;
  /** The parameter element defines parameters used by the library. */
  parameter?: Maybe<Array<Maybe<ParameterDefinition>>>;
  /** The name of the organization or individual that published the library. */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this library is needed and why it has been designed as it
   * has.
   */
  purpose?: Maybe<Scalars['code']>;
  /**
   * Related artifacts such as additional documentation, justification, or
   * bibliographic references.
   */
  relatedArtifact?: Maybe<Array<Maybe<RelatedArtifact>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * An individual or organization primarily responsible for review of some aspect
   * of the content.
   */
  reviewer?: Maybe<Array<Maybe<ContactDetail>>>;
  /** The status of this library. Enables tracking the life-cycle of the content. */
  status: Scalars['code'];
  /** None */
  subjectCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  subjectReference?: Maybe<Group>;
  /**
   * An explanatory or alternate title for the library giving additional
   * information about its content.
   */
  subtitle?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the library. */
  title?: Maybe<Scalars['code']>;
  /**
   * Descriptive topics related to the content of the library. Topics provide a
   * high-level categorization of the library that can be useful for filtering and
   * searching.
   */
  topic?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Identifies the type of library such as a Logic Library, Model Definition,
   * Asset Collection, or Module Definition.
   */
  type: CodeableConcept;
  /**
   * An absolute URI that is used to identify this library when it is referenced in
   * a specification, model, design or an instance; also called its canonical
   * identifier. This SHOULD be globally unique and SHOULD be a literal address at
   * which at which an authoritative instance of this library is (or will be)
   * published. This URL can be the target of a canonical reference. It SHALL
   * remain the same when the library is stored on different servers.
   */
  url?: Maybe<Scalars['code']>;
  /** A detailed description of how the library is used from a clinical perspective. */
  usage?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate library
   * instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the library when it is
   * referenced in a specification, model, design or instance. This is an arbitrary
   * value managed by the library author and is not expected to be globally unique.
   * For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is
   * not available. There is also no expectation that versions can be placed in a
   * lexicographical sequence. To provide a version consistent with the Decision
   * Support Service specification, use the format Major.Minor.Revision (e.g.
   * 1.0.0). For more information on versioning knowledge assets, refer to the
   * Decision Support Service specification. Note that a version is required for
   * non-experimental active artifacts.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * Linkage
 *     Identifies two or more records (resource instances) that refer to the same
 *     real-world "occurrence".
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Linkage = {
  __typename?: 'Linkage';
  /**
   * Indicates whether the asserted set of linkages are considered to be "in
   * effect".
   */
  active?: Maybe<Scalars['code']>;
  /**
   * Identifies the user or organization responsible for asserting the linkages as
   * well as the user or organization who establishes the context in which the
   * nature of each linkage is evaluated.
   */
  author?: Maybe<LinkageAuthor>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * Identifies which record considered as the reference to the same real-world
   * occurrence as well as how the items should be evaluated within the collection
   * of linked items.
   */
  item: Array<Maybe<LinkageItem>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type LinkageAuthor = Organization | Practitioner | PractitionerRole;

/**
 * Linkage.Item
 *     Identifies two or more records (resource instances) that refer to the same
 *     real-world "occurrence".
 */
export type LinkageItem = {
  __typename?: 'LinkageItem';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The resource instance being linked as part of the group. */
  resource: Resource;
  /**
   * Distinguishes which item is "source of truth" (if any) and which items are no
   * longer considered to be current representations.
   */
  type: Scalars['code'];
};

/**
 * List
 *     A list is a curated collection of resources.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type List = {
  __typename?: 'List';
  /** This code defines the purpose of the list - why it was created. */
  code?: Maybe<CodeableConcept>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The date that the list was prepared. */
  date?: Maybe<Scalars['code']>;
  /** If the list is empty, why the list is empty. */
  emptyReason?: Maybe<CodeableConcept>;
  /** The encounter that is the context in which this list was created. */
  encounter?: Maybe<Encounter>;
  /** Entries in this list. */
  entry?: Maybe<Array<Maybe<ListEntry>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifier for the List assigned for business purposes outside the context of
   * FHIR.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * How this list was prepared - whether it is a working list that is suitable for
   * being maintained on an ongoing basis, or if it represents a snapshot of a list
   * of items from another source, or whether it is a prepared list where items may
   * be marked as added, modified or deleted.
   */
  mode: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Comments that apply to the overall list. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** What order applies to the items in the list. */
  orderedBy?: Maybe<CodeableConcept>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The entity responsible for deciding what the contents of the list were. Where
   * the list was created by a human, this is the same as the author of the list.
   */
  source?: Maybe<ListSource>;
  /** Indicates the current state of this list. */
  status: Scalars['code'];
  /**
   * The common subject (or patient) of the resources that are in the list if there
   * is one.
   */
  subject?: Maybe<ListSubject>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A label for the list assigned by the author. */
  title?: Maybe<Scalars['code']>;
};

/**
 * List.Entry
 *     A list is a curated collection of resources.
 */
export type ListEntry = {
  __typename?: 'ListEntry';
  /** When this item was added to the list. */
  date?: Maybe<Scalars['code']>;
  /** True if this item is marked as deleted in the list. */
  deleted?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The flag allows the system constructing the list to indicate the role and
   * significance of the item in the list.
   */
  flag?: Maybe<CodeableConcept>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** A reference to the actual resource from which data was derived. */
  item: Resource;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type ListSource = Device | Patient | Practitioner | PractitionerRole;

export type ListSubject = Device | Group | Location | Patient;

/**
 * Location
 *     Details and position information for a physical place where services are
 *     provided and resources and participants may be stored, found, contained, or
 *     accommodated.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Location = {
  __typename?: 'Location';
  /** Physical location. */
  address?: Maybe<Address>;
  /**
   * A list of alternate names that the location is known as, or was known as, in
   * the past.
   */
  alias?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A description of when the locations opening ours are different to normal, e.g.
   * public holiday availability. Succinctly describing all possible exceptions to
   * normal site availability as detailed in the opening hours Times.
   */
  availabilityExceptions?: Maybe<Scalars['code']>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Description of the Location, which helps in finding or referencing the place. */
  description?: Maybe<Scalars['code']>;
  email?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Technical endpoints providing access to services operated for the location. */
  endpoint?: Maybe<Array<Maybe<Endpoint>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** What days/times during a week is this location usually open. */
  hoursOfOperation?: Maybe<Array<Maybe<LocationHoursOfOperation>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Unique code or number identifying the location to its users. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** The organization responsible for the provisioning and upkeep of the location. */
  managingOrganization?: Maybe<Organization>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * Indicates whether a resource instance represents a specific location or a
   * class of locations.
   */
  mode?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Name of the location as used by humans. Does not need to be unique. */
  name?: Maybe<Scalars['code']>;
  /**
   * The operational status covers operation values most relevant to beds (but can
   * also apply to rooms/units/chairs/etc. such as an isolation unit/dialysis
   * chair). This typically covers concepts such as contamination, housekeeping,
   * and other activities like maintenance.
   */
  operationalStatus?: Maybe<Coding>;
  /** Another Location of which this Location is physically a part of. */
  partOf?: Maybe<Location>;
  phone?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Physical form of the location, e.g. building, room, vehicle, road. */
  physicalType?: Maybe<CodeableConcept>;
  /**
   * The absolute geographic location of the Location, expressed using the WGS84
   * datum (This is the same co-ordinate system used in KML).
   */
  position?: Maybe<LocationPosition>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The status property covers the general availability of the resource, not the
   * current value which may be covered by the operationStatus, or by a
   * schedule/slots if they are configured for the location.
   */
  status?: Maybe<Scalars['code']>;
  /**
   * The contact details of communication devices available at the location. This
   * can include phone numbers, fax numbers, mobile numbers, email addresses and
   * web sites.
   */
  telecom?: Maybe<Array<Maybe<ContactPoint>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** Indicates the type of function performed at the location. */
  type?: Maybe<Array<Maybe<CodeableConcept>>>;
};

/**
 * Location.HoursOfOperation
 *     Details and position information for a physical place where services are
 *     provided and resources and participants may be stored, found, contained, or
 *     accommodated.
 */
export type LocationHoursOfOperation = {
  __typename?: 'LocationHoursOfOperation';
  /** The Location is open all day. */
  allDay?: Maybe<Scalars['code']>;
  /** Time that the Location closes. */
  closingTime?: Maybe<Scalars['code']>;
  /**
   * Indicates which days of the week are available between the start and end
   * Times.
   */
  daysOfWeek?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Time that the Location opens. */
  openingTime?: Maybe<Scalars['code']>;
};

/**
 * Location.Position
 *     Details and position information for a physical place where services are
 *     provided and resources and participants may be stored, found, contained, or
 *     accommodated.
 */
export type LocationPosition = {
  __typename?: 'LocationPosition';
  /**
   * Altitude. The value domain and the interpretation are the same as for the text
   * of the altitude element in KML (see notes below).
   */
  altitude?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Latitude. The value domain and the interpretation are the same as for the text
   * of the latitude element in KML (see notes below).
   */
  latitude: Scalars['code'];
  /**
   * Longitude. The value domain and the interpretation are the same as for the
   * text of the longitude element in KML (see notes below).
   */
  longitude: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * MarketingStatus
 *     The marketing status describes the date when a medicinal product is actually
 *     put on the market or the date as of which it is no longer available.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type MarketingStatus = {
  __typename?: 'MarketingStatus';
  /**
   * The country in which the marketing authorisation has been granted shall be
   * specified It should be specified using the ISO 3166 ‑ 1 alpha-2 code elements.
   */
  country: CodeableConcept;
  /**
   * The date when the Medicinal Product is placed on the market by the Marketing
   * Authorisation Holder (or where applicable, the manufacturer/distributor) in a
   * country and/or jurisdiction shall be provided A complete date consisting of
   * day, month and year shall be specified using the ISO 8601 date format NOTE
   * “Placed on the market” refers to the release of the Medicinal Product into the
   * distribution chain.
   */
  dateRange: Period;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Where a Medicines Regulatory Agency has granted a marketing authorisation for
   * which specific provisions within a jurisdiction apply, the jurisdiction can be
   * specified using an appropriate controlled terminology The controlled term and
   * the controlled term identifier shall be specified.
   */
  jurisdiction?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The date when the Medicinal Product is placed on the market by the Marketing
   * Authorisation Holder (or where applicable, the manufacturer/distributor) in a
   * country and/or jurisdiction shall be provided A complete date consisting of
   * day, month and year shall be specified using the ISO 8601 date format NOTE
   * “Placed on the market” refers to the release of the Medicinal Product into the
   * distribution chain.
   */
  restoreDate?: Maybe<Scalars['code']>;
  /**
   * This attribute provides information on the status of the marketing of the
   * medicinal product See ISO/TS 20443 for more information and examples.
   */
  status: CodeableConcept;
};

/**
 * Measure
 *     The Measure resource provides the definition of a quality measure.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Measure = {
  __typename?: 'Measure';
  /**
   * The date on which the resource content was approved by the publisher. Approval
   * happens once when the content is officially approved for usage.
   */
  approvalDate?: Maybe<Scalars['code']>;
  /**
   * An individiual or organization primarily involved in the creation and
   * maintenance of the content.
   */
  author?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * Provides a summary of relevant clinical guidelines or other clinical
   * recommendations supporting the measure.
   */
  clinicalRecommendationStatement?: Maybe<Scalars['code']>;
  /**
   * If this is a composite measure, the scoring method used to combine the
   * component measures to determine the composite score.
   */
  compositeScoring?: Maybe<CodeableConcept>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the measure and/or its contents. Copyright
   * statements are generally legal restrictions on the use and publishing of the
   * measure.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the measure was published. The date must
   * change when the business version changes and it must change if the status code
   * changes. In addition, it should change when the substantive content of the
   * measure changes.
   */
  date?: Maybe<Scalars['code']>;
  /** Provides a description of an individual term used within the measure. */
  definition?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A free text natural language description of the measure from a consumer's
   * perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * Notices and disclaimers regarding the use of the measure or related to
   * intellectual property (such as code systems) referenced by the measure.
   */
  disclaimer?: Maybe<Scalars['code']>;
  /**
   * An individual or organization primarily responsible for internal coherence of
   * the content.
   */
  editor?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * The period during which the measure content was or is planned to be in active
   * use.
   */
  effectivePeriod?: Maybe<Period>;
  /**
   * An individual or organization responsible for officially endorsing the content
   * for use in some setting.
   */
  endorser?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * A Boolean value to indicate that this measure is authored for testing purposes
   * (or education/evaluation/marketing) and is not intended to be used for genuine
   * usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** A group of population criteria for the measure. */
  group?: Maybe<Array<Maybe<MeasureGroup>>>;
  /**
   * Additional guidance for the measure including how it can be used in a clinical
   * context, and the intent of the measure.
   */
  guidance?: Maybe<Scalars['code']>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this measure when it is
   * represented in other formats, or referenced in a specification, model, design
   * or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * Information on whether an increase or decrease in score is the preferred
   * result (e.g., a higher score indicates better quality OR a lower score
   * indicates better quality OR quality is within a range).
   */
  improvementNotation?: Maybe<CodeableConcept>;
  /** A legal or geographic region in which the measure is intended to be used. */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The date on which the resource content was last reviewed. Review happens
   * periodically after approval but does not change the original approval date.
   */
  lastReviewDate?: Maybe<Scalars['code']>;
  /**
   * A reference to a Library resource containing the formal logic used by the
   * measure.
   */
  library?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the measure. This name should be usable as
   * an identifier for the module by machine processing applications such as code
   * generation.
   */
  name?: Maybe<Scalars['code']>;
  /** The name of the organization or individual that published the measure. */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this measure is needed and why it has been designed as it
   * has.
   */
  purpose?: Maybe<Scalars['code']>;
  /**
   * Describes how to combine the information calculated, based on logic in each of
   * several populations, into one summarized result.
   */
  rateAggregation?: Maybe<Scalars['code']>;
  /**
   * Provides a succinct statement of the need for the measure. Usually includes
   * statements pertaining to importance criterion: impact, gap in care, and
   * evidence.
   */
  rationale?: Maybe<Scalars['code']>;
  /**
   * Related artifacts such as additional documentation, justification, or
   * bibliographic references.
   */
  relatedArtifact?: Maybe<Array<Maybe<RelatedArtifact>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * An individual or organization primarily responsible for review of some aspect
   * of the content.
   */
  reviewer?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * A description of the risk adjustment factors that may impact the resulting
   * score for the measure and how they may be accounted for when computing and
   * reporting measure results.
   */
  riskAdjustment?: Maybe<Scalars['code']>;
  /**
   * Indicates how the calculation is performed for the measure, including
   * proportion, ratio, continuous-variable, and cohort. The value set is
   * extensible, allowing additional measure scoring types to be represented.
   */
  scoring?: Maybe<CodeableConcept>;
  /** The status of this measure. Enables tracking the life-cycle of the content. */
  status: Scalars['code'];
  /** None */
  subjectCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  subjectReference?: Maybe<Group>;
  /**
   * An explanatory or alternate title for the measure giving additional
   * information about its content.
   */
  subtitle?: Maybe<Scalars['code']>;
  /**
   * The supplemental data criteria for the measure report, specified as either the
   * name of a valid CQL expression within a referenced library, or a valid FHIR
   * Resource Path.
   */
  supplementalData?: Maybe<Array<Maybe<MeasureSupplementalData>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the measure. */
  title?: Maybe<Scalars['code']>;
  /**
   * Descriptive topics related to the content of the measure. Topics provide a
   * high-level categorization grouping types of measures that can be useful for
   * filtering and searching.
   */
  topic?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Indicates whether the measure is used to examine a process, an outcome over
   * time, a patient-reported outcome, or a structure measure such as utilization.
   */
  type?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * An absolute URI that is used to identify this measure when it is referenced in
   * a specification, model, design or an instance; also called its canonical
   * identifier. This SHOULD be globally unique and SHOULD be a literal address at
   * which at which an authoritative instance of this measure is (or will be)
   * published. This URL can be the target of a canonical reference. It SHALL
   * remain the same when the measure is stored on different servers.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * A detailed description, from a clinical perspective, of how the measure is
   * used.
   */
  usage?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate measure
   * instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the measure when it is
   * referenced in a specification, model, design or instance. This is an arbitrary
   * value managed by the measure author and is not expected to be globally unique.
   * For example, it might be a timestamp (e.g. yyyymmdd) if a managed version is
   * not available. There is also no expectation that versions can be placed in a
   * lexicographical sequence. To provide a version consistent with the Decision
   * Support Service specification, use the format Major.Minor.Revision (e.g.
   * 1.0.0). For more information on versioning knowledge assets, refer to the
   * Decision Support Service specification. Note that a version is required for
   * non-experimental active artifacts.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * Measure.Component
 *     The Measure resource provides the definition of a quality measure.
 */
export type MeasureComponent = {
  __typename?: 'MeasureComponent';
  /**
   * Indicates a meaning for the stratifier component. This can be as simple as a
   * unique identifier, or it can establish meaning in a broader context by drawing
   * from a terminology, allowing stratifiers to be correlated across measures.
   */
  code?: Maybe<CodeableConcept>;
  /**
   * An expression that specifies the criteria for this component of the
   * stratifier. This is typically the name of an expression defined within a
   * referenced library, but it may also be a path to a stratifier element.
   */
  criteria: Expression;
  /** The human readable description of this stratifier criteria component. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * Measure.Group
 *     The Measure resource provides the definition of a quality measure.
 */
export type MeasureGroup = {
  __typename?: 'MeasureGroup';
  /**
   * Indicates a meaning for the group. This can be as simple as a unique
   * identifier, or it can establish meaning in a broader context by drawing from a
   * terminology, allowing groups to be correlated across measures.
   */
  code?: Maybe<CodeableConcept>;
  /** The human readable description of this population group. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A population criteria for the measure. */
  population?: Maybe<Array<Maybe<MeasurePopulation>>>;
  /**
   * The stratifier criteria for the measure report, specified as either the name
   * of a valid CQL expression defined within a referenced library or a valid FHIR
   * Resource Path.
   */
  stratifier?: Maybe<Array<Maybe<MeasureStratifier>>>;
};

/**
 * Measure.Population
 *     The Measure resource provides the definition of a quality measure.
 */
export type MeasurePopulation = {
  __typename?: 'MeasurePopulation';
  /** The type of population criteria. */
  code?: Maybe<CodeableConcept>;
  /**
   * An expression that specifies the criteria for the population, typically the
   * name of an expression in a library.
   */
  criteria: Expression;
  /** The human readable description of this population criteria. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * MeasureReport
 *     The MeasureReport resource contains the results of the calculation of a
 *     measure; and optionally a reference to the resources involved in that
 *     calculation.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MeasureReport = {
  __typename?: 'MeasureReport';
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The date this measure report was generated. */
  date?: Maybe<Scalars['code']>;
  /**
   * A reference to a Bundle containing the Resources that were used in the
   * calculation of this measure.
   */
  evaluatedResource?: Maybe<Array<Maybe<Resource>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** The results of the calculation, one for each population group in the measure. */
  group?: Maybe<Array<Maybe<MeasureReportGroup>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this MeasureReport when it is
   * represented in other formats or referenced in a specification, model, design
   * or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * Whether improvement in the measure is noted by an increase or decrease in the
   * measure score.
   */
  improvementNotation?: Maybe<CodeableConcept>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** A reference to the Measure that was calculated to produce this report. */
  measure: Scalars['code'];
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The reporting period for which the report was calculated. */
  period: Period;
  /** The individual, location, or organization that is reporting the data. */
  reporter?: Maybe<MeasureReportReporter>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The MeasureReport status. No data will be available until the MeasureReport
   * status is complete.
   */
  status: Scalars['code'];
  /** Optional subject identifying the individual or individuals the report is for. */
  subject?: Maybe<MeasureReportSubject>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * The type of measure report. This may be an individual report, which provides
   * the score for the measure for an individual member of the population; a
   * subject-listing, which returns the list of members that meet the various
   * criteria in the measure; a summary report, which returns a population count
   * for each of the criteria in the measure; or a data-collection, which enables
   * the MeasureReport to be used to exchange the data-of-interest for a quality
   * measure.
   */
  type: Scalars['code'];
};

/**
 * MeasureReport.Component
 *     The MeasureReport resource contains the results of the calculation of a
 *     measure; and optionally a reference to the resources involved in that
 *     calculation.
 */
export type MeasureReportComponent = {
  __typename?: 'MeasureReportComponent';
  /** The code for the stratum component value. */
  code: CodeableConcept;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The stratum component value. */
  value: CodeableConcept;
};

/**
 * MeasureReport.Group
 *     The MeasureReport resource contains the results of the calculation of a
 *     measure; and optionally a reference to the resources involved in that
 *     calculation.
 */
export type MeasureReportGroup = {
  __typename?: 'MeasureReportGroup';
  /** The meaning of the population group as defined in the measure definition. */
  code?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The measure score for this population group, calculated as appropriate for the
   * measure type and scoring method, and based on the contents of the populations
   * defined in the group.
   */
  measureScore?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The populations that make up the population group, one for each type of
   * population appropriate for the measure.
   */
  population?: Maybe<Array<Maybe<MeasureReportPopulation>>>;
  /**
   * When a measure includes multiple stratifiers, there will be a stratifier group
   * for each stratifier defined by the measure.
   */
  stratifier?: Maybe<Array<Maybe<MeasureReportStratifier>>>;
};

/**
 * MeasureReport.Population
 *     The MeasureReport resource contains the results of the calculation of a
 *     measure; and optionally a reference to the resources involved in that
 *     calculation.
 */
export type MeasureReportPopulation = {
  __typename?: 'MeasureReportPopulation';
  /** The type of the population. */
  code?: Maybe<CodeableConcept>;
  /** The number of members of the population. */
  count?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * This element refers to a List of subject level MeasureReport resources, one
   * for each subject in this population.
   */
  subjectResults?: Maybe<List>;
};

/**
 * MeasureReport.Population1
 *     The MeasureReport resource contains the results of the calculation of a
 *     measure; and optionally a reference to the resources involved in that
 *     calculation.
 */
export type MeasureReportPopulation1 = {
  __typename?: 'MeasureReportPopulation1';
  /** The type of the population. */
  code?: Maybe<CodeableConcept>;
  /** The number of members of the population in this stratum. */
  count?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * This element refers to a List of subject level MeasureReport resources, one
   * for each subject in this population in this stratum.
   */
  subjectResults?: Maybe<List>;
};

export type MeasureReportReporter = Location | Organization | Practitioner | PractitionerRole;

/**
 * MeasureReport.Stratifier
 *     The MeasureReport resource contains the results of the calculation of a
 *     measure; and optionally a reference to the resources involved in that
 *     calculation.
 */
export type MeasureReportStratifier = {
  __typename?: 'MeasureReportStratifier';
  /** The meaning of this stratifier, as defined in the measure definition. */
  code?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * This element contains the results for a single stratum within the stratifier.
   * For example, when stratifying on administrative gender, there will be four
   * strata, one for each possible gender value.
   */
  stratum?: Maybe<Array<Maybe<MeasureReportStratum>>>;
};

/**
 * MeasureReport.Stratum
 *     The MeasureReport resource contains the results of the calculation of a
 *     measure; and optionally a reference to the resources involved in that
 *     calculation.
 */
export type MeasureReportStratum = {
  __typename?: 'MeasureReportStratum';
  /** A stratifier component value. */
  component?: Maybe<Array<Maybe<MeasureReportComponent>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The measure score for this stratum, calculated as appropriate for the measure
   * type and scoring method, and based on only the members of this stratum.
   */
  measureScore?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The populations that make up the stratum, one for each type of population
   * appropriate to the measure.
   */
  population?: Maybe<Array<Maybe<MeasureReportPopulation1>>>;
  /**
   * The value for this stratum, expressed as a CodeableConcept. When defining
   * stratifiers on complex values, the value must be rendered such that the value
   * for each stratum within the stratifier is unique.
   */
  value?: Maybe<CodeableConcept>;
};

export type MeasureReportSubject = Device | Group | Location | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Measure.Stratifier
 *     The Measure resource provides the definition of a quality measure.
 */
export type MeasureStratifier = {
  __typename?: 'MeasureStratifier';
  /**
   * Indicates a meaning for the stratifier. This can be as simple as a unique
   * identifier, or it can establish meaning in a broader context by drawing from a
   * terminology, allowing stratifiers to be correlated across measures.
   */
  code?: Maybe<CodeableConcept>;
  /**
   * A component of the stratifier criteria for the measure report, specified as
   * either the name of a valid CQL expression defined within a referenced library
   * or a valid FHIR Resource Path.
   */
  component?: Maybe<Array<Maybe<MeasureComponent>>>;
  /**
   * An expression that specifies the criteria for the stratifier. This is
   * typically the name of an expression defined within a referenced library, but
   * it may also be a path to a stratifier element.
   */
  criteria?: Maybe<Expression>;
  /** The human readable description of this stratifier criteria. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * Measure.SupplementalData
 *     The Measure resource provides the definition of a quality measure.
 */
export type MeasureSupplementalData = {
  __typename?: 'MeasureSupplementalData';
  /**
   * Indicates a meaning for the supplemental data. This can be as simple as a
   * unique identifier, or it can establish meaning in a broader context by drawing
   * from a terminology, allowing supplemental data to be correlated across
   * measures.
   */
  code?: Maybe<CodeableConcept>;
  /**
   * The criteria for the supplemental data. This is typically the name of a valid
   * expression defined within a referenced library, but it may also be a path to a
   * specific data element. The criteria defines the data to be returned for this
   * element.
   */
  criteria: Expression;
  /** The human readable description of this supplemental data. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * An indicator of the intended usage for the supplemental data element.
   * Supplemental data indicates the data is additional information requested to
   * augment the measure information. Risk adjustment factor indicates the data is
   * additional information used to calculate risk adjustment factors when applying
   * a risk model to the measure calculation.
   */
  usage?: Maybe<Array<Maybe<CodeableConcept>>>;
};

/**
 * Media
 *     A photo, video, or audio recording acquired or used in healthcare. The actual
 *     content may be inline or provided by direct reference.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Media = {
  __typename?: 'Media';
  /**
   * A procedure that is fulfilled in whole or in part by the creation of this
   * media.
   */
  basedOn?: Maybe<Array<Maybe<MediaBasedOn>>>;
  /**
   * Indicates the site on the subject's body where the observation was made (i.e.
   * the target site).
   */
  bodySite?: Maybe<CodeableConcept>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The actual content of the media - inline or by direct reference to the media
   * source file.
   */
  content: Attachment;
  /** None */
  createdDateTime?: Maybe<Scalars['code']>;
  /** None */
  createdPeriod?: Maybe<Period>;
  /** The device used to collect the media. */
  device?: Maybe<MediaDevice>;
  /**
   * The name of the device / manufacturer of the device  that was used to make the
   * recording.
   */
  deviceName?: Maybe<Scalars['code']>;
  /** The duration of the recording in seconds - for audio and video. */
  duration?: Maybe<Scalars['code']>;
  /** The encounter that establishes the context for this media. */
  encounter?: Maybe<Encounter>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The number of frames in a photo. This is used with a multi-page fax, or an
   * imaging acquisition context that takes multiple slices in a single image, or
   * an animated gif. If there is more than one frame, this SHALL have a value in
   * order to alert interface software that a multi-frame capable rendering widget
   * is required.
   */
  frames?: Maybe<Scalars['code']>;
  /** Height of the image in pixels (photo/video). */
  height?: Maybe<Scalars['code']>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifiers associated with the image - these may include identifiers for the
   * image itself, identifiers for the context of its collection (e.g. series ids)
   * and context ids such as accession numbers or other workflow identifiers.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * The date and time this version of the media was made available to providers,
   * typically after having been reviewed.
   */
  issued?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * Details of the type of the media - usually, how it was acquired (what type of
   * device). If images sourced from a DICOM system, are wrapped in a Media
   * resource, then this is the modality.
   */
  modality?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Comments made about the media by the performer, subject or other participants. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** The person who administered the collection of the image. */
  operator?: Maybe<MediaOperator>;
  /** A larger event of which this particular event is a component or step. */
  partOf?: Maybe<Array<Maybe<Resource>>>;
  /** Describes why the event occurred in coded or textual form. */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** The current state of the {{title}}. */
  status: Scalars['code'];
  /** Who/What this Media is a record of. */
  subject?: Maybe<MediaSubject>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * A code that classifies whether the media is an image, video or audio recording
   * or some other media category.
   */
  type?: Maybe<CodeableConcept>;
  /** The name of the imaging view e.g. Lateral or Antero-posterior (AP). */
  view?: Maybe<CodeableConcept>;
  /** Width of the image in pixels (photo/video). */
  width?: Maybe<Scalars['code']>;
};

export type MediaBasedOn = CarePlan | ServiceRequest;

export type MediaDevice = Device | DeviceMetric;

export type MediaOperator = CareTeam | Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type MediaSubject = Device | Group | Location | Patient | Practitioner | PractitionerRole | Specimen;

/**
 * Medication
 *     This resource is primarily used for the identification and definition of a
 *     medication for the purposes of prescribing, dispensing, and administering a
 *     medication as well as for making statements about medication use.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Medication = {
  __typename?: 'Medication';
  /**
   * Specific amount of the drug in the packaged product.  For example, when
   * specifying a product that has the same strength (For example, Insulin glargine
   * 100 unit per mL solution for injection), this attribute provides additional
   * clarification of the package amount (For example, 3 mL, 10mL, etc.).
   */
  amount?: Maybe<Ratio>;
  /** Information that only applies to packages (not products). */
  batch?: Maybe<MedicationBatch>;
  /**
   * A code (or set of codes) that specify this medication, or a textual
   * description if no code is available. Usage note: This could be a standard
   * medication code such as a code from RxNorm, SNOMED CT, IDMP etc. It could also
   * be a national or local formulary code, optionally with translations to other
   * code systems.
   */
  code?: Maybe<CodeableConcept>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Describes the form of the item.  Powder; tablets; capsule. */
  form?: Maybe<CodeableConcept>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Business identifier for this medication. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** Identifies a particular constituent of interest in the product. */
  ingredient?: Maybe<Array<Maybe<MedicationIngredient>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * Describes the details of the manufacturer of the medication product.  This is
   * not intended to represent the distributor of a medication product.
   */
  manufacturer?: Maybe<Organization>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** A code to indicate if the medication is in active use. */
  status?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * MedicationAdministration
 *     Describes the event of a patient consuming or otherwise being administered a
 *     medication.  This may be as simple as swallowing a tablet or it may be a long
 *     running infusion.  Related resources tie this event to the authorizing
 *     prescription, and the specific encounter between patient and health care
 *     practitioner.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MedicationAdministration = {
  __typename?: 'MedicationAdministration';
  /** Indicates where the medication is expected to be consumed or administered. */
  category?: Maybe<CodeableConcept>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The visit, admission, or other contact between patient and health care
   * provider during which the medication administration was performed.
   */
  context?: Maybe<MedicationAdministrationContext>;
  /**
   * The device used in administering the medication to the patient.  For example,
   * a particular infusion pump.
   */
  device?: Maybe<Array<Maybe<Device>>>;
  /**
   * Describes the medication dosage information details e.g. dose, rate, site,
   * route, etc.
   */
  dosage?: Maybe<MedicationAdministrationDosage>;
  /** None */
  effectiveDateTime?: Maybe<Scalars['code']>;
  /** None */
  effectivePeriod?: Maybe<Period>;
  /**
   * A summary of the events of interest that have occurred, such as when the
   * administration was verified.
   */
  eventHistory?: Maybe<Array<Maybe<Provenance>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifiers associated with this Medication Administration that are defined by
   * business processes and/or used to refer to it when a direct URL reference to
   * the resource itself is not appropriate. They are business identifiers assigned
   * to this resource by the performer or other systems and remain constant as the
   * resource is updated and propagates from server to server.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A protocol, guideline, orderset, or other definition that was adhered to in
   * whole or in part by this event.
   */
  instantiates?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** None */
  medicationCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  medicationReference?: Maybe<Medication>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Extra information about the medication administration that is not conveyed by
   * the other attributes.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** A larger event of which this particular event is a component or step. */
  partOf?: Maybe<Array<Maybe<MedicationAdministrationPartOf>>>;
  /**
   * Indicates who or what performed the medication administration and how they
   * were involved.
   */
  performer?: Maybe<Array<Maybe<MedicationAdministrationPerformer>>>;
  /** A code indicating why the medication was given. */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Condition or observation that supports why the medication was administered. */
  reasonReference?: Maybe<Array<Maybe<MedicationAdministrationReasonReference>>>;
  /** The original request, instruction or authority to perform the administration. */
  request?: Maybe<MedicationRequest>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * Will generally be set to show that the administration has been completed.  For
   * some long running administrations such as infusions, it is possible for an
   * administration to be started but not completed or it may be paused while some
   * other process is under way.
   */
  status: Scalars['code'];
  /** A code indicating why the administration was not performed. */
  statusReason?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The person or animal or group receiving the medication. */
  subject: MedicationAdministrationSubject;
  /**
   * Additional information (for example, patient height and weight) that supports
   * the administration of the medication.
   */
  supportingInformation?: Maybe<Array<Maybe<Resource>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type MedicationAdministrationContext = Encounter | EpisodeOfCare;

/**
 * MedicationAdministration.Dosage
 *     Describes the event of a patient consuming or otherwise being administered a
 *     medication.  This may be as simple as swallowing a tablet or it may be a long
 *     running infusion.  Related resources tie this event to the authorizing
 *     prescription, and the specific encounter between patient and health care
 *     practitioner.
 */
export type MedicationAdministrationDosage = {
  __typename?: 'MedicationAdministrationDosage';
  /**
   * The amount of the medication given at one administration event.   Use this
   * value when the administration is essentially an instantaneous event such as a
   * swallowing a tablet or giving an injection.
   */
  dose?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * A coded value indicating the method by which the medication is intended to be
   * or was introduced into or on the body.  This attribute will most often NOT be
   * populated.  It is most commonly used for injections.  For example, Slow Push,
   * Deep IV.
   */
  method?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  rateQuantity?: Maybe<Quantity>;
  /** None */
  rateRatio?: Maybe<Ratio>;
  /**
   * A code specifying the route or physiological path of administration of a
   * therapeutic agent into or onto the patient.  For example, topical,
   * intravenous, etc.
   */
  route?: Maybe<CodeableConcept>;
  /**
   * A coded specification of the anatomic site where the medication first entered
   * the body.  For example, "left arm".
   */
  site?: Maybe<CodeableConcept>;
  /**
   * Free text dosage can be used for cases where the dosage administered is too
   * complex to code. When coded dosage is present, the free text dosage may still
   * be present for display to humans.
   *
   * The dosage instructions should reflect the dosage of the medication that was
   * administered.
   */
  text?: Maybe<Scalars['code']>;
};

export type MedicationAdministrationPartOf = MedicationAdministration | Procedure;

/**
 * MedicationAdministration.Performer
 *     Describes the event of a patient consuming or otherwise being administered a
 *     medication.  This may be as simple as swallowing a tablet or it may be a long
 *     running infusion.  Related resources tie this event to the authorizing
 *     prescription, and the specific encounter between patient and health care
 *     practitioner.
 */
export type MedicationAdministrationPerformer = {
  __typename?: 'MedicationAdministrationPerformer';
  /** Indicates who or what performed the medication administration. */
  actor: MedicationAdministrationPerformerActor;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Distinguishes the type of involvement of the performer in the medication
   * administration.
   */
  function?: Maybe<CodeableConcept>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type MedicationAdministrationPerformerActor = Device | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type MedicationAdministrationReasonReference = Condition | DiagnosticReport | Observation;

export type MedicationAdministrationSubject = Group | Patient;

/**
 * Medication.Batch
 *     This resource is primarily used for the identification and definition of a
 *     medication for the purposes of prescribing, dispensing, and administering a
 *     medication as well as for making statements about medication use.
 */
export type MedicationBatch = {
  __typename?: 'MedicationBatch';
  /** When this specific batch of product will expire. */
  expirationDate?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The assigned lot number of a batch of the specified product. */
  lotNumber?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * MedicationDispense
 *     Indicates that a medication product is to be or has been dispensed for a named
 *     person/patient.  This includes a description of the medication product
 *     (supply) provided and the instructions for administering the medication.  The
 *     medication dispense is the result of a pharmacy system responding to a
 *     medication order.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MedicationDispense = {
  __typename?: 'MedicationDispense';
  /** Indicates the medication order that is being dispensed against. */
  authorizingPrescription?: Maybe<Array<Maybe<MedicationRequest>>>;
  /**
   * Indicates the type of medication dispense (for example, where the medication
   * is expected to be consumed or administered (i.e. inpatient or outpatient)).
   */
  category?: Maybe<CodeableConcept>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The encounter or episode of care that establishes the context for this event. */
  context?: Maybe<MedicationDispenseContext>;
  /** The amount of medication expressed as a timing amount. */
  daysSupply?: Maybe<Quantity>;
  /**
   * Identification of the facility/location where the medication was shipped to,
   * as part of the dispense event.
   */
  destination?: Maybe<Location>;
  /**
   * Indicates an actual or potential clinical issue with or between one or more
   * active or proposed clinical actions for a patient; e.g. drug-drug interaction,
   * duplicate therapy, dosage alert etc.
   */
  detectedIssue?: Maybe<Array<Maybe<DetectedIssue>>>;
  /** Indicates how the medication is to be used by the patient. */
  dosageInstruction?: Maybe<Array<Maybe<Dosage>>>;
  /**
   * A summary of the events of interest that have occurred, such as when the
   * dispense was verified.
   */
  eventHistory?: Maybe<Array<Maybe<Provenance>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifiers associated with this Medication Dispense that are defined by
   * business processes and/or used to refer to it when a direct URL reference to
   * the resource itself is not appropriate. They are business identifiers assigned
   * to this resource by the performer or other systems and remain constant as the
   * resource is updated and propagates from server to server.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** The principal physical location where the dispense was performed. */
  location?: Maybe<Location>;
  /** None */
  medicationCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  medicationReference?: Maybe<Medication>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Extra information about the dispense that could not be conveyed in the other
   * attributes.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** The procedure that trigger the dispense. */
  partOf?: Maybe<Array<Maybe<Procedure>>>;
  /** Indicates who or what performed the event. */
  performer?: Maybe<Array<Maybe<MedicationDispensePerformer>>>;
  /** The amount of medication that has been dispensed. Includes unit of measure. */
  quantity?: Maybe<Quantity>;
  /**
   * Identifies the person who picked up the medication.  This will usually be a
   * patient or their caregiver, but some cases exist where it can be a healthcare
   * professional.
   */
  receiver?: Maybe<Array<Maybe<MedicationDispenseReceiver>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** A code specifying the state of the set of dispense events. */
  status: Scalars['code'];
  /** None */
  statusReasonCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  statusReasonReference?: Maybe<DetectedIssue>;
  /**
   * A link to a resource representing the person or the group to whom the
   * medication will be given.
   */
  subject?: Maybe<MedicationDispenseSubject>;
  /**
   * Indicates whether or not substitution was made as part of the dispense.  In
   * some cases, substitution will be expected but does not happen, in other cases
   * substitution is not expected but does happen.  This block explains what
   * substitution did or did not happen and why.  If nothing is specified,
   * substitution was not done.
   */
  substitution?: Maybe<MedicationDispenseSubstitution>;
  /** Additional information that supports the medication being dispensed. */
  supportingInformation?: Maybe<Array<Maybe<Resource>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * Indicates the type of dispensing event that is performed. For example, Trial
   * Fill, Completion of Trial, Partial Fill, Emergency Fill, Samples, etc.
   */
  type?: Maybe<CodeableConcept>;
  /**
   * The time the dispensed product was provided to the patient or their
   * representative.
   */
  whenHandedOver?: Maybe<Scalars['code']>;
  /** The time when the dispensed product was packaged and reviewed. */
  whenPrepared?: Maybe<Scalars['code']>;
};

export type MedicationDispenseContext = Encounter | EpisodeOfCare;

/**
 * MedicationDispense.Performer
 *     Indicates that a medication product is to be or has been dispensed for a named
 *     person/patient.  This includes a description of the medication product
 *     (supply) provided and the instructions for administering the medication.  The
 *     medication dispense is the result of a pharmacy system responding to a
 *     medication order.
 */
export type MedicationDispensePerformer = {
  __typename?: 'MedicationDispensePerformer';
  /**
   * The device, practitioner, etc. who performed the action.  It should be assumed
   * that the actor is the dispenser of the medication.
   */
  actor: MedicationDispensePerformerActor;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Distinguishes the type of performer in the dispense.  For example, date
   * enterer, packager, final checker.
   */
  function?: Maybe<CodeableConcept>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type MedicationDispensePerformerActor = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type MedicationDispenseReceiver = Patient | Practitioner;

export type MedicationDispenseSubject = Group | Patient;

/**
 * MedicationDispense.Substitution
 *     Indicates that a medication product is to be or has been dispensed for a named
 *     person/patient.  This includes a description of the medication product
 *     (supply) provided and the instructions for administering the medication.  The
 *     medication dispense is the result of a pharmacy system responding to a
 *     medication order.
 */
export type MedicationDispenseSubstitution = {
  __typename?: 'MedicationDispenseSubstitution';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Indicates the reason for the substitution (or lack of substitution) from what
   * was prescribed.
   */
  reason?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The person or organization that has primary responsibility for the
   * substitution.
   */
  responsibleParty?: Maybe<Array<Maybe<MedicationDispenseSubstitutionResponsibleParty>>>;
  /**
   * A code signifying whether a different drug was dispensed from what was
   * prescribed.
   */
  type?: Maybe<CodeableConcept>;
  /**
   * True if the dispenser dispensed a different drug or product from what was
   * prescribed.
   */
  wasSubstituted: Scalars['code'];
};

export type MedicationDispenseSubstitutionResponsibleParty = Practitioner | PractitionerRole;

/**
 * Medication.Ingredient
 *     This resource is primarily used for the identification and definition of a
 *     medication for the purposes of prescribing, dispensing, and administering a
 *     medication as well as for making statements about medication use.
 */
export type MedicationIngredient = {
  __typename?: 'MedicationIngredient';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Indication of whether this ingredient affects the therapeutic action of the
   * drug.
   */
  isActive?: Maybe<Scalars['code']>;
  /** None */
  itemCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  itemReference?: Maybe<MedicationIngredientItemReference>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Specifies how many (or how much) of the items there are in this Medication.
   * For example, 250 mg per tablet.  This is expressed as a ratio where the
   * numerator is 250mg and the denominator is 1 tablet.
   */
  strength?: Maybe<Ratio>;
};

export type MedicationIngredientItemReference = Medication | Substance;

/**
 * MedicationKnowledge
 *     Information about a medication that is used to support knowledge.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MedicationKnowledge = {
  __typename?: 'MedicationKnowledge';
  /** Guidelines for the administration of the medication. */
  administrationGuidelines?: Maybe<Array<Maybe<MedicationKnowledgeAdministrationGuidelines>>>;
  /**
   * Specific amount of the drug in the packaged product.  For example, when
   * specifying a product that has the same strength (For example, Insulin glargine
   * 100 unit per mL solution for injection), this attribute provides additional
   * clarification of the package amount (For example, 3 mL, 10mL, etc.).
   */
  amount?: Maybe<Quantity>;
  /**
   * Associated or related medications.  For example, if the medication is a
   * branded product (e.g. Crestor), this is the Therapeutic Moeity (e.g.
   * Rosuvastatin) or if this is a generic medication (e.g. Rosuvastatin), this
   * would link to a branded product (e.g. Crestor).
   */
  associatedMedication?: Maybe<Array<Maybe<Medication>>>;
  /**
   * A code that specifies this medication, or a textual description if no code is
   * available. Usage note: This could be a standard medication code such as a code
   * from RxNorm, SNOMED CT, IDMP etc. It could also be a national or local
   * formulary code, optionally with translations to other code systems.
   */
  code?: Maybe<CodeableConcept>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Potential clinical issue with or between medication(s) (for example, drug-drug
   * interaction, drug-disease contraindication, drug-allergy interaction, etc.).
   */
  contraindication?: Maybe<Array<Maybe<DetectedIssue>>>;
  /** The price of the medication. */
  cost?: Maybe<Array<Maybe<MedicationKnowledgeCost>>>;
  /** Describes the form of the item.  Powder; tablets; capsule. */
  doseForm?: Maybe<CodeableConcept>;
  /**
   * Specifies descriptive properties of the medicine, such as color, shape,
   * imprints, etc.
   */
  drugCharacteristic?: Maybe<Array<Maybe<MedicationKnowledgeDrugCharacteristic>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** Identifies a particular constituent of interest in the product. */
  ingredient?: Maybe<Array<Maybe<MedicationKnowledgeIngredient>>>;
  /** The intended or approved route of administration. */
  intendedRoute?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The time course of drug absorption, distribution, metabolism and excretion of
   * a medication from the body.
   */
  kinetics?: Maybe<Array<Maybe<MedicationKnowledgeKinetics>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * Describes the details of the manufacturer of the medication product.  This is
   * not intended to represent the distributor of a medication product.
   */
  manufacturer?: Maybe<Organization>;
  /** Categorization of the medication within a formulary or classification system. */
  medicineClassification?: Maybe<Array<Maybe<MedicationKnowledgeMedicineClassification>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The program under which the medication is reviewed. */
  monitoringProgram?: Maybe<Array<Maybe<MedicationKnowledgeMonitoringProgram>>>;
  /** Associated documentation about the medication. */
  monograph?: Maybe<Array<Maybe<MedicationKnowledgeMonograph>>>;
  /** Information that only applies to packages (not products). */
  packaging?: Maybe<MedicationKnowledgePackaging>;
  /** The instructions for preparing the medication. */
  preparationInstruction?: Maybe<Scalars['code']>;
  /**
   * Category of the medication or product (e.g. branded product, therapeutic
   * moeity, generic product, innovator product, etc.).
   */
  productType?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Regulatory information about a medication. */
  regulatory?: Maybe<Array<Maybe<MedicationKnowledgeRegulatory>>>;
  /** Associated or related knowledge about a medication. */
  relatedMedicationKnowledge?: Maybe<Array<Maybe<MedicationKnowledgeRelatedMedicationKnowledge>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A code to indicate if the medication is in active use.  The status refers to
   * the validity about the information of the medication and not to its medicinal
   * properties.
   */
  status?: Maybe<Scalars['code']>;
  /**
   * Additional names for a medication, for example, the name(s) given to a
   * medication in different countries.  For example, acetaminophen and paracetamol
   * or salbutamol and albuterol.
   */
  synonym?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * MedicationKnowledge.AdministrationGuidelines
 *     Information about a medication that is used to support knowledge.
 */
export type MedicationKnowledgeAdministrationGuidelines = {
  __typename?: 'MedicationKnowledgeAdministrationGuidelines';
  /** Dosage for the medication for the specific guidelines. */
  dosage?: Maybe<Array<Maybe<MedicationKnowledgeDosage>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** None */
  indicationCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  indicationReference?: Maybe<ObservationDefinition>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Characteristics of the patient that are relevant to the administration
   * guidelines (for example, height, weight, gender, etc.).
   */
  patientCharacteristics?: Maybe<Array<Maybe<MedicationKnowledgePatientCharacteristics>>>;
};

/**
 * MedicationKnowledge.Cost
 *     Information about a medication that is used to support knowledge.
 */
export type MedicationKnowledgeCost = {
  __typename?: 'MedicationKnowledgeCost';
  /** The price of the medication. */
  cost: Money;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The source or owner that assigns the price to the medication. */
  source?: Maybe<Scalars['code']>;
  /**
   * The category of the cost information.  For example, manufacturers' cost,
   * patient cost, claim reimbursement cost, actual acquisition cost.
   */
  type: CodeableConcept;
};

/**
 * MedicationKnowledge.Dosage
 *     Information about a medication that is used to support knowledge.
 */
export type MedicationKnowledgeDosage = {
  __typename?: 'MedicationKnowledgeDosage';
  /** Dosage for the medication for the specific guidelines. */
  dosage: Array<Maybe<Dosage>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The type of dosage (for example, prophylaxis, maintenance, therapeutic, etc.). */
  type: CodeableConcept;
};

/**
 * MedicationKnowledge.DrugCharacteristic
 *     Information about a medication that is used to support knowledge.
 */
export type MedicationKnowledgeDrugCharacteristic = {
  __typename?: 'MedicationKnowledgeDrugCharacteristic';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A code specifying which characteristic of the medicine is being described (for
   * example, colour, shape, imprint).
   */
  type?: Maybe<CodeableConcept>;
  /** None */
  valueBase64Binary?: Maybe<Scalars['code']>;
  /** None */
  valueCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  valueQuantity?: Maybe<Quantity>;
  /** None */
  valueString?: Maybe<Scalars['code']>;
};

/**
 * MedicationKnowledge.Ingredient
 *     Information about a medication that is used to support knowledge.
 */
export type MedicationKnowledgeIngredient = {
  __typename?: 'MedicationKnowledgeIngredient';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Indication of whether this ingredient affects the therapeutic action of the
   * drug.
   */
  isActive?: Maybe<Scalars['code']>;
  /** None */
  itemCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  itemReference?: Maybe<Substance>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Specifies how many (or how much) of the items there are in this Medication.
   * For example, 250 mg per tablet.  This is expressed as a ratio where the
   * numerator is 250mg and the denominator is 1 tablet.
   */
  strength?: Maybe<Ratio>;
};

/**
 * MedicationKnowledge.Kinetics
 *     Information about a medication that is used to support knowledge.
 */
export type MedicationKnowledgeKinetics = {
  __typename?: 'MedicationKnowledgeKinetics';
  /** The drug concentration measured at certain discrete points in time. */
  areaUnderCurve?: Maybe<Array<Maybe<Quantity>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The time required for any specified property (e.g., the concentration of a
   * substance in the body) to decrease by half.
   */
  halfLifePeriod?: Maybe<Quantity>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The median lethal dose of a drug. */
  lethalDose50?: Maybe<Array<Maybe<Quantity>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * MedicationKnowledge.MaxDispense
 *     Information about a medication that is used to support knowledge.
 */
export type MedicationKnowledgeMaxDispense = {
  __typename?: 'MedicationKnowledgeMaxDispense';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The period that applies to the maximum number of units. */
  period?: Maybe<Quantity>;
  /** The maximum number of units of the medication that can be dispensed. */
  quantity: Quantity;
};

/**
 * MedicationKnowledge.MedicineClassification
 *     Information about a medication that is used to support knowledge.
 */
export type MedicationKnowledgeMedicineClassification = {
  __typename?: 'MedicationKnowledgeMedicineClassification';
  /**
   * Specific category assigned to the medication (e.g. anti-infective, anti-
   * hypertensive, antibiotic, etc.).
   */
  classification?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The type of category for the medication (for example, therapeutic
   * classification, therapeutic sub-classification).
   */
  type: CodeableConcept;
};

/**
 * MedicationKnowledge.MonitoringProgram
 *     Information about a medication that is used to support knowledge.
 */
export type MedicationKnowledgeMonitoringProgram = {
  __typename?: 'MedicationKnowledgeMonitoringProgram';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Name of the reviewing program. */
  name?: Maybe<Scalars['code']>;
  /** Type of program under which the medication is monitored. */
  type?: Maybe<CodeableConcept>;
};

/**
 * MedicationKnowledge.Monograph
 *     Information about a medication that is used to support knowledge.
 */
export type MedicationKnowledgeMonograph = {
  __typename?: 'MedicationKnowledgeMonograph';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Associated documentation about the medication. */
  source?: Maybe<MedicationKnowledgeMonographSource>;
  /**
   * The category of documentation about the medication. (e.g. professional
   * monograph, patient education monograph).
   */
  type?: Maybe<CodeableConcept>;
};

export type MedicationKnowledgeMonographSource = DocumentReference | Media;

/**
 * MedicationKnowledge.Packaging
 *     Information about a medication that is used to support knowledge.
 */
export type MedicationKnowledgePackaging = {
  __typename?: 'MedicationKnowledgePackaging';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The number of product units the package would contain if fully loaded. */
  quantity?: Maybe<Quantity>;
  /**
   * A code that defines the specific type of packaging that the medication can be
   * found in (e.g. blister sleeve, tube, bottle).
   */
  type?: Maybe<CodeableConcept>;
};

/**
 * MedicationKnowledge.PatientCharacteristics
 *     Information about a medication that is used to support knowledge.
 */
export type MedicationKnowledgePatientCharacteristics = {
  __typename?: 'MedicationKnowledgePatientCharacteristics';
  /** None */
  characteristicCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  characteristicQuantity?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The specific characteristic (e.g. height, weight, gender, etc.). */
  value?: Maybe<Array<Maybe<Scalars['code']>>>;
};

/**
 * MedicationKnowledge.Regulatory
 *     Information about a medication that is used to support knowledge.
 */
export type MedicationKnowledgeRegulatory = {
  __typename?: 'MedicationKnowledgeRegulatory';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The maximum number of units of the medication that can be dispensed in a
   * period.
   */
  maxDispense?: Maybe<MedicationKnowledgeMaxDispense>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The authority that is specifying the regulations. */
  regulatoryAuthority: Organization;
  /** Specifies the schedule of a medication in jurisdiction. */
  schedule?: Maybe<Array<Maybe<MedicationKnowledgeSchedule>>>;
  /**
   * Specifies if changes are allowed when dispensing a medication from a
   * regulatory perspective.
   */
  substitution?: Maybe<Array<Maybe<MedicationKnowledgeSubstitution>>>;
};

/**
 * MedicationKnowledge.RelatedMedicationKnowledge
 *     Information about a medication that is used to support knowledge.
 */
export type MedicationKnowledgeRelatedMedicationKnowledge = {
  __typename?: 'MedicationKnowledgeRelatedMedicationKnowledge';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Associated documentation about the associated medication knowledge. */
  reference: Array<Maybe<MedicationKnowledge>>;
  /** The category of the associated medication knowledge reference. */
  type: CodeableConcept;
};

/**
 * MedicationKnowledge.Schedule
 *     Information about a medication that is used to support knowledge.
 */
export type MedicationKnowledgeSchedule = {
  __typename?: 'MedicationKnowledgeSchedule';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Specifies the specific drug schedule. */
  schedule: CodeableConcept;
};

/**
 * MedicationKnowledge.Substitution
 *     Information about a medication that is used to support knowledge.
 */
export type MedicationKnowledgeSubstitution = {
  __typename?: 'MedicationKnowledgeSubstitution';
  /** Specifies if regulation allows for changes in the medication when dispensing. */
  allowed: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Specifies the type of substitution allowed. */
  type: CodeableConcept;
};

/**
 * MedicationRequest
 *     An order or request for both supply of the medication and the instructions for
 *     administration of the medication to a patient. The resource is called
 *     "MedicationRequest" rather than "MedicationPrescription" or "MedicationOrder"
 *     to generalize the use across inpatient and outpatient settings, including care
 *     plans, etc., and to harmonize with workflow patterns.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MedicationRequest = {
  __typename?: 'MedicationRequest';
  /**
   * The date (and perhaps time) when the prescription was initially written or
   * authored on.
   */
  authoredOn?: Maybe<Scalars['code']>;
  /**
   * A plan or request that is fulfilled in whole or in part by this medication
   * request.
   */
  basedOn?: Maybe<Array<Maybe<MedicationRequestBasedOn>>>;
  /**
   * Indicates the type of medication request (for example, where the medication is
   * expected to be consumed or administered (i.e. inpatient or outpatient)).
   */
  category?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The description of the overall patte3rn of the administration of the
   * medication to the patient.
   */
  courseOfTherapyType?: Maybe<CodeableConcept>;
  /**
   * Indicates an actual or potential clinical issue with or between one or more
   * active or proposed clinical actions for a patient; e.g. Drug-drug interaction,
   * duplicate therapy, dosage alert etc.
   */
  detectedIssue?: Maybe<Array<Maybe<DetectedIssue>>>;
  /**
   * Indicates the specific details for the dispense or medication supply part of a
   * medication request (also known as a Medication Prescription or Medication
   * Order).  Note that this information is not always sent with the order.  There
   * may be in some settings (e.g. hospitals) institutional or system support for
   * completing the dispense details in the pharmacy department.
   */
  dispenseRequest?: Maybe<MedicationRequestDispenseRequest>;
  /**
   * If true indicates that the provider is asking for the medication request not
   * to occur.
   */
  doNotPerform?: Maybe<Scalars['code']>;
  /** Indicates how the medication is to be used by the patient. */
  dosageInstruction?: Maybe<Array<Maybe<Dosage>>>;
  /**
   * The Encounter during which this [x] was created or to which the creation of
   * this record is tightly associated.
   */
  encounter?: Maybe<Encounter>;
  /**
   * Links to Provenance records for past versions of this resource or fulfilling
   * request or event resources that identify key state transitions or updates that
   * are likely to be relevant to a user looking at the current version of the
   * resource.
   */
  eventHistory?: Maybe<Array<Maybe<Provenance>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A shared identifier common to all requests that were authorized more or less
   * simultaneously by a single author, representing the identifier of the
   * requisition or prescription.
   */
  groupIdentifier?: Maybe<Identifier>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifiers associated with this medication request that are defined by
   * business processes and/or used to refer to it when a direct URL reference to
   * the resource itself is not appropriate. They are business identifiers assigned
   * to this resource by the performer or other systems and remain constant as the
   * resource is updated and propagates from server to server.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * The URL pointing to a protocol, guideline, orderset, or other definition that
   * is adhered to in whole or in part by this MedicationRequest.
   */
  instantiatesCanonical?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The URL pointing to an externally maintained protocol, guideline, orderset or
   * other definition that is adhered to in whole or in part by this
   * MedicationRequest.
   */
  instantiatesUri?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Insurance plans, coverage extensions, pre-authorizations and/or pre-
   * determinations that may be required for delivering the requested service.
   */
  insurance?: Maybe<Array<Maybe<MedicationRequestInsurance>>>;
  /** Whether the request is a proposal, plan, or an original order. */
  intent: Scalars['code'];
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** None */
  medicationCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  medicationReference?: Maybe<Medication>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Extra information about the prescription that could not be conveyed by the
   * other attributes.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /**
   * The specified desired performer of the medication treatment (e.g. the
   * performer of the medication administration).
   */
  performer?: Maybe<MedicationRequestPerformer>;
  /** Indicates the type of performer of the administration of the medication. */
  performerType?: Maybe<CodeableConcept>;
  /**
   * A link to a resource representing an earlier order related order or
   * prescription.
   */
  priorPrescription?: Maybe<MedicationRequest>;
  /**
   * Indicates how quickly the Medication Request should be addressed with respect
   * to other requests.
   */
  priority?: Maybe<Scalars['code']>;
  /** The reason or the indication for ordering or not ordering the medication. */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Condition or observation that supports why the medication was ordered. */
  reasonReference?: Maybe<Array<Maybe<MedicationRequestReasonReference>>>;
  /**
   * The person who entered the order on behalf of another individual for example
   * in the case of a verbal or a telephone order.
   */
  recorder?: Maybe<MedicationRequestRecorder>;
  /** None */
  reportedBoolean?: Maybe<Scalars['code']>;
  /** None */
  reportedReference?: Maybe<MedicationRequestReportedReference>;
  /**
   * The individual, organization, or device that initiated the request and has
   * responsibility for its activation.
   */
  requester?: Maybe<MedicationRequestRequester>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A code specifying the current state of the order.  Generally, this will be
   * active or completed state.
   */
  status: Scalars['code'];
  /** Captures the reason for the current state of the MedicationRequest. */
  statusReason?: Maybe<CodeableConcept>;
  /**
   * A link to a resource representing the person or set of individuals to whom the
   * medication will be given.
   */
  subject: MedicationRequestSubject;
  /**
   * Indicates whether or not substitution can or should be part of the dispense.
   * In some cases, substitution must happen, in other cases substitution must not
   * happen. This block explains the prescriber's intent. If nothing is specified
   * substitution may be done.
   */
  substitution?: Maybe<MedicationRequestSubstitution>;
  /**
   * Include additional information (for example, patient height and weight) that
   * supports the ordering of the medication.
   */
  supportingInformation?: Maybe<Array<Maybe<Resource>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type MedicationRequestBasedOn = CarePlan | ImmunizationRecommendation | MedicationRequest | ServiceRequest;

/**
 * MedicationRequest.DispenseRequest
 *     An order or request for both supply of the medication and the instructions for
 *     administration of the medication to a patient. The resource is called
 *     "MedicationRequest" rather than "MedicationPrescription" or "MedicationOrder"
 *     to generalize the use across inpatient and outpatient settings, including care
 *     plans, etc., and to harmonize with workflow patterns.
 */
export type MedicationRequestDispenseRequest = {
  __typename?: 'MedicationRequestDispenseRequest';
  /**
   * The minimum period of time that must occur between dispenses of the
   * medication.
   */
  dispenseInterval?: Maybe<Quantity>;
  /**
   * Identifies the period time over which the supplied product is expected to be
   * used, or the length of time the dispense is expected to last.
   */
  expectedSupplyDuration?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Indicates the quantity or duration for the first dispense of the medication. */
  initialFill?: Maybe<MedicationRequestInitialFill>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * An integer indicating the number of times, in addition to the original
   * dispense, (aka refills or repeats) that the patient can receive the prescribed
   * medication. Usage Notes: This integer does not include the original order
   * dispense. This means that if an order indicates dispense 30 tablets plus "3
   * repeats", then the order can be dispensed a total of 4 times and the patient
   * can receive a total of 120 tablets.  A prescriber may explicitly say that zero
   * refills are permitted after the initial dispense.
   */
  numberOfRepeatsAllowed?: Maybe<Scalars['code']>;
  /** Indicates the intended dispensing Organization specified by the prescriber. */
  performer?: Maybe<Organization>;
  /** The amount that is to be dispensed for one fill. */
  quantity?: Maybe<Quantity>;
  /**
   * This indicates the validity period of a prescription (stale dating the
   * Prescription).
   */
  validityPeriod?: Maybe<Period>;
};

/**
 * MedicationRequest.InitialFill
 *     An order or request for both supply of the medication and the instructions for
 *     administration of the medication to a patient. The resource is called
 *     "MedicationRequest" rather than "MedicationPrescription" or "MedicationOrder"
 *     to generalize the use across inpatient and outpatient settings, including care
 *     plans, etc., and to harmonize with workflow patterns.
 */
export type MedicationRequestInitialFill = {
  __typename?: 'MedicationRequestInitialFill';
  /** The length of time that the first dispense is expected to last. */
  duration?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The amount or quantity to provide as part of the first dispense. */
  quantity?: Maybe<Quantity>;
};

export type MedicationRequestInsurance = ClaimResponse | Coverage;

export type MedicationRequestPerformer = CareTeam | Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type MedicationRequestReasonReference = Condition | Observation;

export type MedicationRequestRecorder = Practitioner | PractitionerRole;

export type MedicationRequestReportedReference = Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type MedicationRequestRequester = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type MedicationRequestSubject = Group | Patient;

/**
 * MedicationRequest.Substitution
 *     An order or request for both supply of the medication and the instructions for
 *     administration of the medication to a patient. The resource is called
 *     "MedicationRequest" rather than "MedicationPrescription" or "MedicationOrder"
 *     to generalize the use across inpatient and outpatient settings, including care
 *     plans, etc., and to harmonize with workflow patterns.
 */
export type MedicationRequestSubstitution = {
  __typename?: 'MedicationRequestSubstitution';
  /** None */
  allowedBoolean?: Maybe<Scalars['code']>;
  /** None */
  allowedCodeableConcept?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Indicates the reason for the substitution, or why substitution must or must
   * not be performed.
   */
  reason?: Maybe<CodeableConcept>;
};

/**
 * MedicationStatement
 *     A record of a medication that is being consumed by a patient.   A
 *     MedicationStatement may indicate that the patient may be taking the medication
 *     now or has taken the medication in the past or will be taking the medication
 *     in the future.  The source of this information can be the patient, significant
 *     other (such as a family member or spouse), or a clinician.  A common scenario
 *     where this information is captured is during the history taking process during
 *     a patient visit or stay.   The medication information may come from sources
 *     such as the patient's memory, from a prescription bottle,  or from a list of
 *     medications the patient, clinician or other party maintains.
 *
 *     The primary difference between a medication statement and a medication
 *     administration is that the medication administration has complete
 *     administration information and is based on actual administration information
 *     from the person who administered the medication.  A medication statement is
 *     often, if not always, less specific.  There is no required date/time when the
 *     medication was administered, in fact we only know that a source has reported
 *     the patient is taking this medication, where details such as time, quantity,
 *     or rate or even medication product may be incomplete or missing or less
 *     precise.  As stated earlier, the medication statement information may come
 *     from the patient's memory, from a prescription bottle or from a list of
 *     medications the patient, clinician or other party maintains.  Medication
 *     administration is more formal and is not missing detailed information.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MedicationStatement = {
  __typename?: 'MedicationStatement';
  /** A plan, proposal or order that is fulfilled in whole or in part by this event. */
  basedOn?: Maybe<Array<Maybe<MedicationStatementBasedOn>>>;
  /** Indicates where the medication is expected to be consumed or administered. */
  category?: Maybe<CodeableConcept>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The encounter or episode of care that establishes the context for this
   * MedicationStatement.
   */
  context?: Maybe<MedicationStatementContext>;
  /** The date when the medication statement was asserted by the information source. */
  dateAsserted?: Maybe<Scalars['code']>;
  /**
   * Allows linking the MedicationStatement to the underlying MedicationRequest, or
   * to other information that supports or is used to derive the
   * MedicationStatement.
   */
  derivedFrom?: Maybe<Array<Maybe<Resource>>>;
  /** Indicates how the medication is/was or should be taken by the patient. */
  dosage?: Maybe<Array<Maybe<Dosage>>>;
  /** None */
  effectiveDateTime?: Maybe<Scalars['code']>;
  /** None */
  effectivePeriod?: Maybe<Period>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifiers associated with this Medication Statement that are defined by
   * business processes and/or used to refer to it when a direct URL reference to
   * the resource itself is not appropriate. They are business identifiers assigned
   * to this resource by the performer or other systems and remain constant as the
   * resource is updated and propagates from server to server.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * The person or organization that provided the information about the taking of
   * this medication. Note: Use derivedFrom when a MedicationStatement is derived
   * from other resources, e.g. Claim or MedicationRequest.
   */
  informationSource?: Maybe<MedicationStatementInformationSource>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** None */
  medicationCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  medicationReference?: Maybe<Medication>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Provides extra information about the medication statement that is not conveyed
   * by the other attributes.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** A larger event of which this particular event is a component or step. */
  partOf?: Maybe<Array<Maybe<MedicationStatementPartOf>>>;
  /** A reason for why the medication is being/was taken. */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Condition or observation that supports why the medication is being/was taken. */
  reasonReference?: Maybe<Array<Maybe<MedicationStatementReasonReference>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A code representing the patient or other source's judgment about the state of
   * the medication used that this statement is about.  Generally, this will be
   * active or completed.
   */
  status: Scalars['code'];
  /** Captures the reason for the current state of the MedicationStatement. */
  statusReason?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The person, animal or group who is/was taking the medication. */
  subject: MedicationStatementSubject;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type MedicationStatementBasedOn = CarePlan | MedicationRequest | ServiceRequest;

export type MedicationStatementContext = Encounter | EpisodeOfCare;

export type MedicationStatementInformationSource = Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type MedicationStatementPartOf = MedicationAdministration | MedicationDispense | MedicationStatement | Observation | Procedure;

export type MedicationStatementReasonReference = Condition | DiagnosticReport | Observation;

export type MedicationStatementSubject = Group | Patient;

/**
 * MedicinalProduct
 *     Detailed definition of a medicinal product, typically for uses other than
 *     direct patient care (e.g. regulatory use).
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MedicinalProduct = {
  __typename?: 'MedicinalProduct';
  /**
   * Whether the Medicinal Product is subject to additional monitoring for
   * regulatory reasons.
   */
  additionalMonitoringIndicator?: Maybe<CodeableConcept>;
  /** Supporting documentation, typically for regulatory submission. */
  attachedDocument?: Maybe<Array<Maybe<DocumentReference>>>;
  /** Clinical trials or studies that this product is involved in. */
  clinicalTrial?: Maybe<Array<Maybe<ResearchStudy>>>;
  /**
   * The dose form for a single part product, or combined form of a multiple part
   * product.
   */
  combinedPharmaceuticalDoseForm?: Maybe<CodeableConcept>;
  /** A product specific contact, person (in a role), or an organization. */
  contact?: Maybe<Array<Maybe<MedicinalProductContact>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Reference to another product, e.g. for linking authorised to investigational
   * product.
   */
  crossReference?: Maybe<Array<Maybe<Identifier>>>;
  /** If this medicine applies to human or veterinary uses. */
  domain?: Maybe<Coding>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Business identifier for this product. Could be an MPID. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The legal status of supply of the medicinal product as classified by the
   * regulator.
   */
  legalStatusOfSupply?: Maybe<CodeableConcept>;
  /**
   * An operation applied to the product, for manufacturing or adminsitrative
   * purpose.
   */
  manufacturingBusinessOperation?: Maybe<Array<Maybe<MedicinalProductManufacturingBusinessOperation>>>;
  /**
   * Marketing status of the medicinal product, in contrast to marketing
   * authorizaton.
   */
  marketingStatus?: Maybe<Array<Maybe<MarketingStatus>>>;
  /**
   * A master file for to the medicinal product (e.g. Pharmacovigilance System
   * Master File).
   */
  masterFile?: Maybe<Array<Maybe<DocumentReference>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The product's name, including full name and possibly coded parts. */
  name: Array<Maybe<MedicinalProductName>>;
  /** Package representation for the product. */
  packagedMedicinalProduct?: Maybe<Array<Maybe<MedicinalProductPackaged>>>;
  /** If authorised for use in children. */
  paediatricUseIndicator?: Maybe<CodeableConcept>;
  /** Pharmaceutical aspects of product. */
  pharmaceuticalProduct?: Maybe<Array<Maybe<MedicinalProductPharmaceutical>>>;
  /** Allows the product to be classified by various systems. */
  productClassification?: Maybe<Array<Maybe<CodeableConcept>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * Indicates if the medicinal product has an orphan designation for the treatment
   * of a rare disease.
   */
  specialDesignation?: Maybe<Array<Maybe<MedicinalProductSpecialDesignation>>>;
  /**
   * Whether the Medicinal Product is subject to special measures for regulatory
   * reasons.
   */
  specialMeasures?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** Regulatory type, e.g. Investigational or Authorized. */
  type?: Maybe<CodeableConcept>;
};

/**
 * MedicinalProductAuthorization
 *     The regulatory authorization of a medicinal product.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MedicinalProductAuthorization = {
  __typename?: 'MedicinalProductAuthorization';
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The country in which the marketing authorization has been granted. */
  country?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * A period of time after authorization before generic product applicatiosn can
   * be submitted.
   */
  dataExclusivityPeriod?: Maybe<Period>;
  /**
   * The date when the first authorization was granted by a Medicines Regulatory
   * Agency.
   */
  dateOfFirstAuthorization?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Marketing Authorization Holder. */
  holder?: Maybe<Organization>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Business identifier for the marketing authorization, as assigned by a
   * regulator.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * Date of first marketing authorization for a company's new medicinal product in
   * any country in the World.
   */
  internationalBirthDate?: Maybe<Scalars['code']>;
  /** Jurisdiction within a country. */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Authorization in areas within a country. */
  jurisdictionalAuthorization?: Maybe<Array<Maybe<MedicinalProductAuthorizationJurisdictionalAuthorization>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** The legal framework against which this authorization is granted. */
  legalBasis?: Maybe<CodeableConcept>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The regulatory procedure for granting or amending a marketing authorization. */
  procedure?: Maybe<MedicinalProductAuthorizationProcedure>;
  /** Medicines Regulatory Agency. */
  regulator?: Maybe<Organization>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The date when a suspended the marketing or the marketing authorization of the
   * product is anticipated to be restored.
   */
  restoreDate?: Maybe<Scalars['code']>;
  /** The status of the marketing authorization. */
  status?: Maybe<CodeableConcept>;
  /** The date at which the given status has become applicable. */
  statusDate?: Maybe<Scalars['code']>;
  /** The medicinal product that is being authorized. */
  subject?: Maybe<MedicinalProductAuthorizationSubject>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * The beginning of the time period in which the marketing authorization is in
   * the specific status shall be specified A complete date consisting of day,
   * month and year shall be specified using the ISO 8601 date format.
   */
  validityPeriod?: Maybe<Period>;
};

/**
 * MedicinalProductAuthorization.JurisdictionalAuthorization
 *     The regulatory authorization of a medicinal product.
 */
export type MedicinalProductAuthorizationJurisdictionalAuthorization = {
  __typename?: 'MedicinalProductAuthorizationJurisdictionalAuthorization';
  /** Country of authorization. */
  country?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The assigned number for the marketing authorization. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /** Jurisdiction within a country. */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The legal status of supply in a jurisdiction or region. */
  legalStatusOfSupply?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The start and expected end date of the authorization. */
  validityPeriod?: Maybe<Period>;
};

/**
 * MedicinalProductAuthorization.Procedure
 *     The regulatory authorization of a medicinal product.
 */
export type MedicinalProductAuthorizationProcedure = {
  __typename?: 'MedicinalProductAuthorizationProcedure';
  /** Applcations submitted to obtain a marketing authorization. */
  application?: Maybe<Array<Maybe<MedicinalProductAuthorizationProcedure>>>;
  /** None */
  dateDateTime?: Maybe<Scalars['code']>;
  /** None */
  datePeriod?: Maybe<Period>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Identifier for this procedure. */
  identifier?: Maybe<Identifier>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Type of procedure. */
  type: CodeableConcept;
};

export type MedicinalProductAuthorizationSubject = MedicinalProduct | MedicinalProductPackaged;

export type MedicinalProductContact = Organization | PractitionerRole;

/**
 * MedicinalProductContraindication
 *     The clinical particulars - indications, contraindications etc. of a medicinal
 *     product, including for regulatory purposes.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MedicinalProductContraindication = {
  __typename?: 'MedicinalProductContraindication';
  /** A comorbidity (concurrent condition) or coinfection. */
  comorbidity?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The disease, symptom or procedure for the contraindication. */
  disease?: Maybe<CodeableConcept>;
  /** The status of the disease or symptom for the contraindication. */
  diseaseStatus?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Information about the use of the medicinal product in relation to other
   * therapies described as part of the indication.
   */
  otherTherapy?: Maybe<Array<Maybe<MedicinalProductContraindicationOtherTherapy>>>;
  /** The population group to which this applies. */
  population?: Maybe<Array<Maybe<Population>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** The medication for which this is an indication. */
  subject?: Maybe<Array<Maybe<MedicinalProductContraindicationSubject>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * Information about the use of the medicinal product in relation to other
   * therapies as part of the indication.
   */
  therapeuticIndication?: Maybe<Array<Maybe<MedicinalProductIndication>>>;
};

/**
 * MedicinalProductContraindication.OtherTherapy
 *     The clinical particulars - indications, contraindications etc. of a medicinal
 *     product, including for regulatory purposes.
 */
export type MedicinalProductContraindicationOtherTherapy = {
  __typename?: 'MedicinalProductContraindicationOtherTherapy';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** None */
  medicationCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  medicationReference?: Maybe<MedicinalProductContraindicationOtherTherapyMedicationReference>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The type of relationship between the medicinal product indication or
   * contraindication and another therapy.
   */
  therapyRelationshipType: CodeableConcept;
};

export type MedicinalProductContraindicationOtherTherapyMedicationReference = Medication | MedicinalProduct | Substance | SubstanceSpecification;

export type MedicinalProductContraindicationSubject = Medication | MedicinalProduct;

/**
 * MedicinalProduct.CountryLanguage
 *     Detailed definition of a medicinal product, typically for uses other than
 *     direct patient care (e.g. regulatory use).
 */
export type MedicinalProductCountryLanguage = {
  __typename?: 'MedicinalProductCountryLanguage';
  /** Country code for where this name applies. */
  country: CodeableConcept;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Jurisdiction code for where this name applies. */
  jurisdiction?: Maybe<CodeableConcept>;
  /** Language code for this name. */
  language: CodeableConcept;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * MedicinalProductIndication
 *     Indication for the Medicinal Product.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MedicinalProductIndication = {
  __typename?: 'MedicinalProductIndication';
  /** Comorbidity (concurrent condition) or co-infection as part of the indication. */
  comorbidity?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The status of the disease or symptom for which the indication applies. */
  diseaseStatus?: Maybe<CodeableConcept>;
  /** The disease, symptom or procedure that is the indication for treatment. */
  diseaseSymptomProcedure?: Maybe<CodeableConcept>;
  /** Timing or duration information as part of the indication. */
  duration?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The intended effect, aim or strategy to be achieved by the indication. */
  intendedEffect?: Maybe<CodeableConcept>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Information about the use of the medicinal product in relation to other
   * therapies described as part of the indication.
   */
  otherTherapy?: Maybe<Array<Maybe<MedicinalProductIndicationOtherTherapy>>>;
  /** The population group to which this applies. */
  population?: Maybe<Array<Maybe<Population>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** The medication for which this is an indication. */
  subject?: Maybe<Array<Maybe<MedicinalProductIndicationSubject>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** Describe the undesirable effects of the medicinal product. */
  undesirableEffect?: Maybe<Array<Maybe<MedicinalProductUndesirableEffect>>>;
};

/**
 * MedicinalProductIndication.OtherTherapy
 *     Indication for the Medicinal Product.
 */
export type MedicinalProductIndicationOtherTherapy = {
  __typename?: 'MedicinalProductIndicationOtherTherapy';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** None */
  medicationCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  medicationReference?: Maybe<MedicinalProductIndicationOtherTherapyMedicationReference>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The type of relationship between the medicinal product indication or
   * contraindication and another therapy.
   */
  therapyRelationshipType: CodeableConcept;
};

export type MedicinalProductIndicationOtherTherapyMedicationReference = Medication | MedicinalProduct | Substance | SubstanceSpecification;

export type MedicinalProductIndicationSubject = Medication | MedicinalProduct;

/**
 * MedicinalProductIngredient
 *     An ingredient of a manufactured item or pharmaceutical product.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MedicinalProductIngredient = {
  __typename?: 'MedicinalProductIngredient';
  /** If the ingredient is a known or suspected allergen. */
  allergenicIndicator?: Maybe<Scalars['code']>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * The identifier(s) of this Ingredient that are assigned by business processes
   * and/or used to refer to it when a direct URL reference to the resource itself
   * is not appropriate.
   */
  identifier?: Maybe<Identifier>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** Manufacturer of this Ingredient. */
  manufacturer?: Maybe<Array<Maybe<Organization>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** Ingredient role e.g. Active ingredient, excipient. */
  role: CodeableConcept;
  /** A specified substance that comprises this ingredient. */
  specifiedSubstance?: Maybe<Array<Maybe<MedicinalProductIngredientSpecifiedSubstance>>>;
  /** The ingredient substance. */
  substance?: Maybe<MedicinalProductIngredientSubstance>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * MedicinalProductIngredient.ReferenceStrength
 *     An ingredient of a manufactured item or pharmaceutical product.
 */
export type MedicinalProductIngredientReferenceStrength = {
  __typename?: 'MedicinalProductIngredientReferenceStrength';
  /** The country or countries for which the strength range applies. */
  country?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** For when strength is measured at a particular point or distance. */
  measurementPoint?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Strength expressed in terms of a reference substance. */
  strength: Ratio;
  /** Strength expressed in terms of a reference substance. */
  strengthLowLimit?: Maybe<Ratio>;
  /** Relevant reference substance. */
  substance?: Maybe<CodeableConcept>;
};

/**
 * MedicinalProductIngredient.SpecifiedSubstance
 *     An ingredient of a manufactured item or pharmaceutical product.
 */
export type MedicinalProductIngredientSpecifiedSubstance = {
  __typename?: 'MedicinalProductIngredientSpecifiedSubstance';
  /** The specified substance. */
  code: CodeableConcept;
  /** Confidentiality level of the specified substance as the ingredient. */
  confidentiality?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** The group of specified substance, e.g. group 1 to 4. */
  group: CodeableConcept;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Quantity of the substance or specified substance present in the manufactured
   * item or pharmaceutical product.
   */
  strength?: Maybe<Array<Maybe<MedicinalProductIngredientStrength>>>;
};

/**
 * MedicinalProductIngredient.Strength
 *     An ingredient of a manufactured item or pharmaceutical product.
 */
export type MedicinalProductIngredientStrength = {
  __typename?: 'MedicinalProductIngredientStrength';
  /** The strength per unitary volume (or mass). */
  concentration?: Maybe<Ratio>;
  /**
   * A lower limit for the strength per unitary volume (or mass), for when there is
   * a range. The concentration attribute then becomes the upper limit.
   */
  concentrationLowLimit?: Maybe<Ratio>;
  /** The country or countries for which the strength range applies. */
  country?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** For when strength is measured at a particular point or distance. */
  measurementPoint?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The quantity of substance in the unit of presentation, or in the volume (or
   * mass) of the single pharmaceutical product or manufactured item.
   */
  presentation: Ratio;
  /**
   * A lower limit for the quantity of substance in the unit of presentation. For
   * use when there is a range of strengths, this is the lower limit, with the
   * presentation attribute becoming the upper limit.
   */
  presentationLowLimit?: Maybe<Ratio>;
  /** Strength expressed in terms of a reference substance. */
  referenceStrength?: Maybe<Array<Maybe<MedicinalProductIngredientReferenceStrength>>>;
};

/**
 * MedicinalProductIngredient.Substance
 *     An ingredient of a manufactured item or pharmaceutical product.
 */
export type MedicinalProductIngredientSubstance = {
  __typename?: 'MedicinalProductIngredientSubstance';
  /** The ingredient substance. */
  code: CodeableConcept;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Quantity of the substance or specified substance present in the manufactured
   * item or pharmaceutical product.
   */
  strength?: Maybe<Array<Maybe<MedicinalProductIngredientStrength>>>;
};

/**
 * MedicinalProductInteraction
 *     The interactions of the medicinal product with other medicinal products, or
 *     other forms of interactions.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MedicinalProductInteraction = {
  __typename?: 'MedicinalProductInteraction';
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The interaction described. */
  description?: Maybe<Scalars['code']>;
  /**
   * The effect of the interaction, for example "reduced gastric absorption of
   * primary medication".
   */
  effect?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The incidence of the interaction, e.g. theoretical, observed. */
  incidence?: Maybe<CodeableConcept>;
  /** The specific medication, food or laboratory test that interacts. */
  interactant?: Maybe<Array<Maybe<MedicinalProductInteractionInteractant>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** Actions for managing the interaction. */
  management?: Maybe<CodeableConcept>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** The medication for which this is a described interaction. */
  subject?: Maybe<Array<Maybe<MedicinalProductInteractionSubject>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * The type of the interaction e.g. drug-drug interaction, drug-food interaction,
   * drug-lab test interaction.
   */
  type?: Maybe<CodeableConcept>;
};

/**
 * MedicinalProductInteraction.Interactant
 *     The interactions of the medicinal product with other medicinal products, or
 *     other forms of interactions.
 */
export type MedicinalProductInteractionInteractant = {
  __typename?: 'MedicinalProductInteractionInteractant';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** None */
  itemCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  itemReference?: Maybe<MedicinalProductInteractionInteractantItemReference>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type MedicinalProductInteractionInteractantItemReference = Medication | MedicinalProduct | ObservationDefinition | Substance;

export type MedicinalProductInteractionSubject = Medication | MedicinalProduct | Substance;

/**
 * MedicinalProductManufactured
 *     The manufactured item as contained in the packaged medicinal product.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MedicinalProductManufactured = {
  __typename?: 'MedicinalProductManufactured';
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** Ingredient. */
  ingredient?: Maybe<Array<Maybe<MedicinalProductIngredient>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * Dose form as manufactured and before any transformation into the
   * pharmaceutical product.
   */
  manufacturedDoseForm: CodeableConcept;
  /**
   * Manufacturer of the item (Note that this should be named "manufacturer" but it
   * currently causes technical issues).
   */
  manufacturer?: Maybe<Array<Maybe<Organization>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Other codeable characteristics. */
  otherCharacteristics?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Dimensions, color etc. */
  physicalCharacteristics?: Maybe<ProdCharacteristic>;
  /** The quantity or "count number" of the manufactured item. */
  quantity: Quantity;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * The “real world” units in which the quantity of the manufactured item is
   * described.
   */
  unitOfPresentation?: Maybe<CodeableConcept>;
};

/**
 * MedicinalProduct.ManufacturingBusinessOperation
 *     Detailed definition of a medicinal product, typically for uses other than
 *     direct patient care (e.g. regulatory use).
 */
export type MedicinalProductManufacturingBusinessOperation = {
  __typename?: 'MedicinalProductManufacturingBusinessOperation';
  /** Regulatory authorization reference number. */
  authorisationReferenceNumber?: Maybe<Identifier>;
  /** To indicate if this proces is commercially confidential. */
  confidentialityIndicator?: Maybe<CodeableConcept>;
  /** Regulatory authorization date. */
  effectiveDate?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The manufacturer or establishment associated with the process. */
  manufacturer?: Maybe<Array<Maybe<Organization>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The type of manufacturing operation. */
  operationType?: Maybe<CodeableConcept>;
  /** A regulator which oversees the operation. */
  regulator?: Maybe<Organization>;
};

/**
 * MedicinalProduct.Name
 *     Detailed definition of a medicinal product, typically for uses other than
 *     direct patient care (e.g. regulatory use).
 */
export type MedicinalProductName = {
  __typename?: 'MedicinalProductName';
  /** Country where the name applies. */
  countryLanguage?: Maybe<Array<Maybe<MedicinalProductCountryLanguage>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Coding words or phrases of the name. */
  namePart?: Maybe<Array<Maybe<MedicinalProductNamePart>>>;
  /** The full product name. */
  productName: Scalars['code'];
};

/**
 * MedicinalProduct.NamePart
 *     Detailed definition of a medicinal product, typically for uses other than
 *     direct patient care (e.g. regulatory use).
 */
export type MedicinalProductNamePart = {
  __typename?: 'MedicinalProductNamePart';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A fragment of a product name. */
  part: Scalars['code'];
  /** Idenifying type for this part of the name (e.g. strength part). */
  type: Coding;
};

/**
 * MedicinalProductPackaged
 *     A medicinal product in a container or package.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MedicinalProductPackaged = {
  __typename?: 'MedicinalProductPackaged';
  /** Batch numbering. */
  batchIdentifier?: Maybe<Array<Maybe<MedicinalProductPackagedBatchIdentifier>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Textual description. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Unique identifier. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The legal status of supply of the medicinal product as classified by the
   * regulator.
   */
  legalStatusOfSupply?: Maybe<CodeableConcept>;
  /** Manufacturer of this Package Item. */
  manufacturer?: Maybe<Array<Maybe<Organization>>>;
  /** Manufacturer of this Package Item. */
  marketingAuthorization?: Maybe<MedicinalProductAuthorization>;
  /** Marketing information. */
  marketingStatus?: Maybe<Array<Maybe<MarketingStatus>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A packaging item, as a contained for medicine, possibly with other packaging
   * items within.
   */
  packageItem: Array<Maybe<MedicinalProductPackagedPackageItem>>;
  resourceType?: Maybe<Scalars['String']>;
  /** The product with this is a pack for. */
  subject?: Maybe<Array<Maybe<MedicinalProduct>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * MedicinalProductPackaged.BatchIdentifier
 *     A medicinal product in a container or package.
 */
export type MedicinalProductPackagedBatchIdentifier = {
  __typename?: 'MedicinalProductPackagedBatchIdentifier';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** A number appearing on the immediate packaging (and not the outer packaging). */
  immediatePackaging?: Maybe<Identifier>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A number appearing on the outer packaging of a specific batch. */
  outerPackaging: Identifier;
};

/**
 * MedicinalProductPackaged.PackageItem
 *     A medicinal product in a container or package.
 */
export type MedicinalProductPackagedPackageItem = {
  __typename?: 'MedicinalProductPackagedPackageItem';
  /** A possible alternate material for the packaging. */
  alternateMaterial?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** A device accompanying a medicinal product. */
  device?: Maybe<Array<Maybe<DeviceDefinition>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Including possibly Data Carrier Identifier. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /** The manufactured item as contained in the packaged medicinal product. */
  manufacturedItem?: Maybe<Array<Maybe<MedicinalProductManufactured>>>;
  /** Manufacturer of this Package Item. */
  manufacturer?: Maybe<Array<Maybe<Organization>>>;
  /** Material type of the package item. */
  material?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Other codeable characteristics. */
  otherCharacteristics?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Allows containers within containers. */
  packageItem?: Maybe<Array<Maybe<MedicinalProductPackagedPackageItem>>>;
  /** Dimensions, color etc. */
  physicalCharacteristics?: Maybe<ProdCharacteristic>;
  /**
   * The quantity of this package in the medicinal product, at the current level of
   * packaging. The outermost is always 1.
   */
  quantity: Quantity;
  /** Shelf Life and storage information. */
  shelfLifeStorage?: Maybe<Array<Maybe<ProductShelfLife>>>;
  /** The physical type of the container of the medicine. */
  type: CodeableConcept;
};

/**
 * MedicinalProductPharmaceutical
 *     A pharmaceutical product described in terms of its composition and dose form.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MedicinalProductPharmaceutical = {
  __typename?: 'MedicinalProductPharmaceutical';
  /** The administrable dose form, after necessary reconstitution. */
  administrableDoseForm: CodeableConcept;
  /** Characteristics e.g. a products onset of action. */
  characteristics?: Maybe<Array<Maybe<MedicinalProductPharmaceuticalCharacteristics>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Accompanying device. */
  device?: Maybe<Array<Maybe<DeviceDefinition>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** An identifier for the pharmaceutical medicinal product. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** Ingredient. */
  ingredient?: Maybe<Array<Maybe<MedicinalProductIngredient>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The path by which the pharmaceutical product is taken into or makes contact
   * with the body.
   */
  routeOfAdministration: Array<Maybe<MedicinalProductPharmaceuticalRouteOfAdministration>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** Todo. */
  unitOfPresentation?: Maybe<CodeableConcept>;
};

/**
 * MedicinalProductPharmaceutical.Characteristics
 *     A pharmaceutical product described in terms of its composition and dose form.
 */
export type MedicinalProductPharmaceuticalCharacteristics = {
  __typename?: 'MedicinalProductPharmaceuticalCharacteristics';
  /** A coded characteristic. */
  code: CodeableConcept;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The status of characteristic e.g. assigned or pending. */
  status?: Maybe<CodeableConcept>;
};

/**
 * MedicinalProductPharmaceutical.RouteOfAdministration
 *     A pharmaceutical product described in terms of its composition and dose form.
 */
export type MedicinalProductPharmaceuticalRouteOfAdministration = {
  __typename?: 'MedicinalProductPharmaceuticalRouteOfAdministration';
  /** Coded expression for the route. */
  code: CodeableConcept;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The first dose (dose quantity) administered in humans can be specified, for a
   * product under investigation, using a numerical value and its unit of
   * measurement.
   */
  firstDose?: Maybe<Quantity>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The maximum dose per day (maximum dose quantity to be administered in any one
   * 24-h period) that can be administered as per the protocol referenced in the
   * clinical trial authorisation.
   */
  maxDosePerDay?: Maybe<Quantity>;
  /**
   * The maximum dose per treatment period that can be administered as per the
   * protocol referenced in the clinical trial authorisation.
   */
  maxDosePerTreatmentPeriod?: Maybe<Ratio>;
  /**
   * The maximum single dose that can be administered as per the protocol of a
   * clinical trial can be specified using a numerical value and its unit of
   * measurement.
   */
  maxSingleDose?: Maybe<Quantity>;
  /**
   * The maximum treatment period during which an Investigational Medicinal Product
   * can be administered as per the protocol referenced in the clinical trial
   * authorisation.
   */
  maxTreatmentPeriod?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A species for which this route applies. */
  targetSpecies?: Maybe<Array<Maybe<MedicinalProductPharmaceuticalTargetSpecies>>>;
};

/**
 * MedicinalProductPharmaceutical.TargetSpecies
 *     A pharmaceutical product described in terms of its composition and dose form.
 */
export type MedicinalProductPharmaceuticalTargetSpecies = {
  __typename?: 'MedicinalProductPharmaceuticalTargetSpecies';
  /** Coded expression for the species. */
  code: CodeableConcept;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A species specific time during which consumption of animal product is not
   * appropriate.
   */
  withdrawalPeriod?: Maybe<Array<Maybe<MedicinalProductPharmaceuticalWithdrawalPeriod>>>;
};

/**
 * MedicinalProductPharmaceutical.WithdrawalPeriod
 *     A pharmaceutical product described in terms of its composition and dose form.
 */
export type MedicinalProductPharmaceuticalWithdrawalPeriod = {
  __typename?: 'MedicinalProductPharmaceuticalWithdrawalPeriod';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Extra information about the withdrawal period. */
  supportingInformation?: Maybe<Scalars['code']>;
  /**
   * Coded expression for the type of tissue for which the withdrawal period
   * applues, e.g. meat, milk.
   */
  tissue: CodeableConcept;
  /** A value for the time. */
  value: Quantity;
};

/**
 * MedicinalProduct.SpecialDesignation
 *     Detailed definition of a medicinal product, typically for uses other than
 *     direct patient care (e.g. regulatory use).
 */
export type MedicinalProductSpecialDesignation = {
  __typename?: 'MedicinalProductSpecialDesignation';
  /** Date when the designation was granted. */
  date?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Identifier for the designation, or procedure number. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /** None */
  indicationCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  indicationReference?: Maybe<MedicinalProductIndication>;
  /** The intended use of the product, e.g. prevention, treatment. */
  intendedUse?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Animal species for which this applies. */
  species?: Maybe<CodeableConcept>;
  /** For example granted, pending, expired or withdrawn. */
  status?: Maybe<CodeableConcept>;
  /** The type of special designation, e.g. orphan drug, minor use. */
  type?: Maybe<CodeableConcept>;
};

/**
 * MedicinalProductUndesirableEffect
 *     Describe the undesirable effects of the medicinal product.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MedicinalProductUndesirableEffect = {
  __typename?: 'MedicinalProductUndesirableEffect';
  /** Classification of the effect. */
  classification?: Maybe<CodeableConcept>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** The frequency of occurrence of the effect. */
  frequencyOfOccurrence?: Maybe<CodeableConcept>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The population group to which this applies. */
  population?: Maybe<Array<Maybe<Population>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** The medication for which this is an indication. */
  subject?: Maybe<Array<Maybe<MedicinalProductUndesirableEffectSubject>>>;
  /** The symptom, condition or undesirable effect. */
  symptomConditionEffect?: Maybe<CodeableConcept>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type MedicinalProductUndesirableEffectSubject = Medication | MedicinalProduct;

/**
 * MessageDefinition
 *     Defines the characteristics of a message that can be shared between systems,
 *     including the type of event that initiates the message, the content to be
 *     transmitted and what response(s), if any, are permitted.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MessageDefinition = {
  __typename?: 'MessageDefinition';
  /**
   * Indicates what types of messages may be sent as an application-level response
   * to this message.
   */
  allowedResponse?: Maybe<Array<Maybe<MessageDefinitionAllowedResponse>>>;
  /** The MessageDefinition that is the basis for the contents of this resource. */
  base?: Maybe<Scalars['code']>;
  /** The impact of the content of the message. */
  category?: Maybe<Scalars['code']>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the message definition and/or its contents.
   * Copyright statements are generally legal restrictions on the use and
   * publishing of the message definition.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the message definition was published. The
   * date must change when the business version changes and it must change if the
   * status code changes. In addition, it should change when the substantive
   * content of the message definition changes.
   */
  date: Scalars['code'];
  /**
   * A free text natural language description of the message definition from a
   * consumer's perspective.
   */
  description?: Maybe<Scalars['code']>;
  /** None */
  eventCoding?: Maybe<Coding>;
  /** None */
  eventUri?: Maybe<Scalars['code']>;
  /**
   * A Boolean value to indicate that this message definition is authored for
   * testing purposes (or education/evaluation/marketing) and is not intended to be
   * used for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Identifies the resource (or resources) that are being addressed by the event.
   * For example, the Encounter for an admit message or two Account records for a
   * merge.
   */
  focus?: Maybe<Array<Maybe<MessageDefinitionFocus>>>;
  /**
   * Canonical reference to a GraphDefinition. If a URL is provided, it is the
   * canonical reference to a [[[GraphDefinition]]] that it controls what resources
   * are to be added to the bundle when building the document. The GraphDefinition
   * can also specify profiles that apply to the various resources.
   */
  graph?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this message definition when it
   * is represented in other formats, or referenced in a specification, model,
   * design or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A legal or geographic region in which the message definition is intended to be
   * used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the message definition. This name should
   * be usable as an identifier for the module by machine processing applications
   * such as code generation.
   */
  name?: Maybe<Scalars['code']>;
  /**
   * Identifies a protocol or workflow that this MessageDefinition represents a
   * step in.
   */
  parent?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The name of the organization or individual that published the message
   * definition.
   */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this message definition is needed and why it has been
   * designed as it has.
   */
  purpose?: Maybe<Scalars['code']>;
  /** A MessageDefinition that is superseded by this definition. */
  replaces?: Maybe<Array<Maybe<Scalars['code']>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * Declare at a message definition level whether a response is required or only
   * upon error or success, or never.
   */
  responseRequired?: Maybe<Scalars['code']>;
  /**
   * The status of this message definition. Enables tracking the life-cycle of the
   * content.
   */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the message definition. */
  title?: Maybe<Scalars['code']>;
  /**
   * The business identifier that is used to reference the MessageDefinition and
   * *is* expected to be consistent from server to server.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate message
   * definition instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the message definition
   * when it is referenced in a specification, model, design or instance. This is
   * an arbitrary value managed by the message definition author and is not
   * expected to be globally unique. For example, it might be a timestamp (e.g.
   * yyyymmdd) if a managed version is not available. There is also no expectation
   * that versions can be placed in a lexicographical sequence.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * MessageDefinition.AllowedResponse
 *     Defines the characteristics of a message that can be shared between systems,
 *     including the type of event that initiates the message, the content to be
 *     transmitted and what response(s), if any, are permitted.
 */
export type MessageDefinitionAllowedResponse = {
  __typename?: 'MessageDefinitionAllowedResponse';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to the message definition that must be adhered to by this
   * supported response.
   */
  message: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Provides a description of the circumstances in which this response should be
   * used (as opposed to one of the alternative responses).
   */
  situation?: Maybe<Scalars['code']>;
};

/**
 * MessageDefinition.Focus
 *     Defines the characteristics of a message that can be shared between systems,
 *     including the type of event that initiates the message, the content to be
 *     transmitted and what response(s), if any, are permitted.
 */
export type MessageDefinitionFocus = {
  __typename?: 'MessageDefinitionFocus';
  /** The kind of resource that must be the focus for this message. */
  code: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifies the maximum number of resources of this type that must be pointed
   * to by a message in order for it to be valid against this MessageDefinition.
   */
  max?: Maybe<Scalars['code']>;
  /**
   * Identifies the minimum number of resources of this type that must be pointed
   * to by a message in order for it to be valid against this MessageDefinition.
   */
  min: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A profile that reflects constraints for the focal resource (and potentially
   * for related resources).
   */
  profile?: Maybe<Scalars['code']>;
};

/**
 * MessageHeader
 *     The header for a message exchange that is either requesting or responding to
 *     an action.  The reference(s) that are the subject of the action as well as
 *     other information related to the action are typically transmitted in a bundle
 *     in which the MessageHeader resource instance is the first resource in the
 *     bundle.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MessageHeader = {
  __typename?: 'MessageHeader';
  /**
   * The logical author of the message - the person or device that decided the
   * described event should happen. When there is more than one candidate, pick the
   * most proximal to the MessageHeader. Can provide other authors in extensions.
   */
  author?: Maybe<MessageHeaderAuthor>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Permanent link to the MessageDefinition for this message. */
  definition?: Maybe<Scalars['code']>;
  /** The destination application which the message is intended for. */
  destination?: Maybe<Array<Maybe<MessageHeaderDestination>>>;
  /**
   * The person or device that performed the data entry leading to this message.
   * When there is more than one candidate, pick the most proximal to the message.
   * Can provide other enterers in extensions.
   */
  enterer?: Maybe<MessageHeaderEnterer>;
  /** None */
  eventCoding?: Maybe<Coding>;
  /** None */
  eventUri?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The actual data of the message - a reference to the root/focus class of the
   * event.
   */
  focus?: Maybe<Array<Maybe<Resource>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Coded indication of the cause for the event - indicates  a reason for the
   * occurrence of the event that is a focus of this message.
   */
  reason?: Maybe<CodeableConcept>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * Information about the message that this message is a response to.  Only
   * present if this message is a response.
   */
  response?: Maybe<MessageHeaderResponse>;
  /**
   * The person or organization that accepts overall responsibility for the
   * contents of the message. The implication is that the message event happened
   * under the policies of the responsible party.
   */
  responsible?: Maybe<MessageHeaderResponsible>;
  /** Identifies the sending system to allow the use of a trust relationship. */
  sender?: Maybe<MessageHeaderSender>;
  /** The source application from which this message originated. */
  source: MessageHeaderSource;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type MessageHeaderAuthor = Practitioner | PractitionerRole;

/**
 * MessageHeader.Destination
 *     The header for a message exchange that is either requesting or responding to
 *     an action.  The reference(s) that are the subject of the action as well as
 *     other information related to the action are typically transmitted in a bundle
 *     in which the MessageHeader resource instance is the first resource in the
 *     bundle.
 */
export type MessageHeaderDestination = {
  __typename?: 'MessageHeaderDestination';
  /** Indicates where the message should be routed to. */
  endpoint: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Human-readable name for the target system. */
  name?: Maybe<Scalars['code']>;
  /**
   * Allows data conveyed by a message to be addressed to a particular person or
   * department when routing to a specific application isn't sufficient.
   */
  receiver?: Maybe<MessageHeaderDestinationReceiver>;
  /**
   * Identifies the target end system in situations where the initial message
   * transmission is to an intermediary system.
   */
  target?: Maybe<Device>;
};

export type MessageHeaderDestinationReceiver = Organization | Practitioner | PractitionerRole;

export type MessageHeaderEnterer = Practitioner | PractitionerRole;

/**
 * MessageHeader.Response
 *     The header for a message exchange that is either requesting or responding to
 *     an action.  The reference(s) that are the subject of the action as well as
 *     other information related to the action are typically transmitted in a bundle
 *     in which the MessageHeader resource instance is the first resource in the
 *     bundle.
 */
export type MessageHeaderResponse = {
  __typename?: 'MessageHeaderResponse';
  /**
   * Code that identifies the type of response to the message - whether it was
   * successful or not, and whether it should be resent or not.
   */
  code: Scalars['code'];
  /** Full details of any issues found in the message. */
  details?: Maybe<OperationOutcome>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The MessageHeader.id of the message to which this message is a response. */
  identifier: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type MessageHeaderResponsible = Organization | Practitioner | PractitionerRole;

export type MessageHeaderSender = Organization | Practitioner | PractitionerRole;

/**
 * MessageHeader.Source
 *     The header for a message exchange that is either requesting or responding to
 *     an action.  The reference(s) that are the subject of the action as well as
 *     other information related to the action are typically transmitted in a bundle
 *     in which the MessageHeader resource instance is the first resource in the
 *     bundle.
 */
export type MessageHeaderSource = {
  __typename?: 'MessageHeaderSource';
  /**
   * An e-mail, phone, website or other contact point to use to resolve issues with
   * message communications.
   */
  contact?: Maybe<ContactPoint>;
  /** Identifies the routing target to send acknowledgements to. */
  endpoint: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Human-readable name for the source system. */
  name?: Maybe<Scalars['code']>;
  /** May include configuration or other information useful in debugging. */
  software?: Maybe<Scalars['code']>;
  /**
   * Can convey versions of multiple systems in situations where a message passes
   * through multiple hands.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * Meta
 *     The metadata about a resource. This is content in the resource that is
 *     maintained by the infrastructure. Changes to the content might not always be
 *     associated with version changes to the resource.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Meta = {
  __typename?: 'Meta';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** When the resource last changed - e.g. when the version changed. */
  lastUpdated?: Maybe<Scalars['code']>;
  /**
   * A list of profiles (references to [[[StructureDefinition]]] resources) that
   * this resource claims to conform to. The URL is a reference to
   * [[[StructureDefinition.url]]].
   */
  profile?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Security labels applied to this resource. These tags connect specific
   * resources to the overall security policy and infrastructure.
   */
  security?: Maybe<Array<Maybe<Coding>>>;
  /**
   * A uri that identifies the source system of the resource. This provides a
   * minimal amount of [[[Provenance]]] information that can be used to track or
   * differentiate the source of information in the resource. The source may
   * identify another FHIR server, document, message, database, etc.
   */
  source?: Maybe<Scalars['code']>;
  /**
   * Tags applied to this resource. Tags are intended to be used to identify and
   * relate resources to process and workflow, and applications are not required to
   * consider the tags when interpreting the meaning of a resource.
   */
  tag?: Maybe<Array<Maybe<Coding>>>;
  /**
   * The version specific identifier, as it appears in the version portion of the
   * URL. This value changes when the resource is created, updated, or deleted.
   */
  versionId?: Maybe<Scalars['code']>;
};

/**
 * MolecularSequence
 *     Raw data describing a biological sequence.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type MolecularSequence = {
  __typename?: 'MolecularSequence';
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Whether the sequence is numbered starting at 0 (0-based numbering or
   * coordinates, inclusive start, exclusive end) or starting at 1 (1-based
   * numbering, inclusive start and inclusive end).
   */
  coordinateSystem: Scalars['code'];
  /** The method for sequencing, for example, chip information. */
  device?: Maybe<Device>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A unique identifier for this particular sequence instance. This is a FHIR-
   * defined id.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Sequence that was observed. It is the result marked by referenceSeq along with
   * variant records on referenceSeq. This shall start from
   * referenceSeq.windowStart and end by referenceSeq.windowEnd.
   */
  observedSeq?: Maybe<Scalars['code']>;
  /** The patient whose sequencing results are described by this resource. */
  patient?: Maybe<Patient>;
  /** The organization or lab that should be responsible for this result. */
  performer?: Maybe<Organization>;
  /** Pointer to next atomic sequence which at most contains one variant. */
  pointer?: Maybe<Array<Maybe<MolecularSequence>>>;
  /**
   * An experimental feature attribute that defines the quality of the feature in a
   * quantitative way, such as a phred quality score ([SO:0001686](http://www.seque
   * nceontology.org/browser/current_svn/term/SO:0001686)).
   */
  quality?: Maybe<Array<Maybe<MolecularSequenceQuality>>>;
  /** The number of copies of the sequence of interest. (RNASeq). */
  quantity?: Maybe<Quantity>;
  /**
   * Coverage (read depth or depth) is the average number of reads representing a
   * given nucleotide in the reconstructed sequence.
   */
  readCoverage?: Maybe<Scalars['code']>;
  /**
   * A sequence that is used as a reference to describe variants that are present
   * in a sequence analyzed.
   */
  referenceSeq?: Maybe<MolecularSequenceReferenceSeq>;
  /**
   * Configurations of the external repository. The repository shall store target's
   * observedSeq or records related with target's observedSeq.
   */
  repository?: Maybe<Array<Maybe<MolecularSequenceRepository>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** Specimen used for sequencing. */
  specimen?: Maybe<Specimen>;
  /** Information about chromosome structure variation. */
  structureVariant?: Maybe<Array<Maybe<MolecularSequenceStructureVariant>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** Amino Acid Sequence/ DNA Sequence / RNA Sequence. */
  type?: Maybe<Scalars['code']>;
  /**
   * The definition of variant here originates from Sequence ontology ([variant_of]
   * (http://www.sequenceontology.org/browser/current_svn/term/variant_of)). This
   * element can represent amino acid or nucleic sequence change(including
   * insertion,deletion,SNP,etc.)  It can represent some complex mutation or
   * segment variation with the assist of CIGAR string.
   */
  variant?: Maybe<Array<Maybe<MolecularSequenceVariant>>>;
};

/**
 * MolecularSequence.Inner
 *     Raw data describing a biological sequence.
 */
export type MolecularSequenceInner = {
  __typename?: 'MolecularSequenceInner';
  /**
   * Structural variant inner end. If the coordinate system is 0-based then end is
   * exclusive and does not include the last position. If the coordinate system is
   * 1-base, then end is inclusive and includes the last position.
   */
  end?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Structural variant inner start. If the coordinate system is either 0-based or
   * 1-based, then start position is inclusive.
   */
  start?: Maybe<Scalars['code']>;
};

/**
 * MolecularSequence.Outer
 *     Raw data describing a biological sequence.
 */
export type MolecularSequenceOuter = {
  __typename?: 'MolecularSequenceOuter';
  /**
   * Structural variant outer end. If the coordinate system is 0-based then end is
   * exclusive and does not include the last position. If the coordinate system is
   * 1-base, then end is inclusive and includes the last position.
   */
  end?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Structural variant outer start. If the coordinate system is either 0-based or
   * 1-based, then start position is inclusive.
   */
  start?: Maybe<Scalars['code']>;
};

/**
 * MolecularSequence.Quality
 *     Raw data describing a biological sequence.
 */
export type MolecularSequenceQuality = {
  __typename?: 'MolecularSequenceQuality';
  /**
   * End position of the sequence. If the coordinate system is 0-based then end is
   * exclusive and does not include the last position. If the coordinate system is
   * 1-base, then end is inclusive and includes the last position.
   */
  end?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Harmonic mean of Recall and Precision, computed as: 2 * precision * recall /
   * (precision + recall).
   */
  fScore?: Maybe<Scalars['code']>;
  /**
   * The number of false positives where the non-REF alleles in the Truth and Query
   * Call Sets match (i.e. cases where the truth is 1/1 and the query is 0/1 or
   * similar).
   */
  gtFP?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Which method is used to get sequence quality. */
  method?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** QUERY.TP / (QUERY.TP + QUERY.FP). */
  precision?: Maybe<Scalars['code']>;
  /**
   * False positives, i.e. the number of sites in the Query Call Set for which
   * there is no path through the Truth Call Set that is consistent with this site.
   * Sites with correct variant but incorrect genotype are counted here.
   */
  queryFP?: Maybe<Scalars['code']>;
  /**
   * True positives, from the perspective of the query data, i.e. the number of
   * sites in the Query Call Set for which there are paths through the Truth Call
   * Set that are consistent with all of the alleles at this site, and for which
   * there is an accurate genotype call for the event.
   */
  queryTP?: Maybe<Scalars['code']>;
  /** TRUTH.TP / (TRUTH.TP + TRUTH.FN). */
  recall?: Maybe<Scalars['code']>;
  /**
   * Receiver Operator Characteristic (ROC) Curve  to give sensitivity/specificity
   * tradeoff.
   */
  roc?: Maybe<MolecularSequenceRoc>;
  /**
   * The score of an experimentally derived feature such as a p-value ([SO:0001685]
   * (http://www.sequenceontology.org/browser/current_svn/term/SO:0001685)).
   */
  score?: Maybe<Quantity>;
  /** Gold standard sequence used for comparing against. */
  standardSequence?: Maybe<CodeableConcept>;
  /**
   * Start position of the sequence. If the coordinate system is either 0-based or
   * 1-based, then start position is inclusive.
   */
  start?: Maybe<Scalars['code']>;
  /**
   * False negatives, i.e. the number of sites in the Truth Call Set for which
   * there is no path through the Query Call Set that is consistent with all of the
   * alleles at this site, or sites for which there is an inaccurate genotype call
   * for the event. Sites with correct variant but incorrect genotype are counted
   * here.
   */
  truthFN?: Maybe<Scalars['code']>;
  /**
   * True positives, from the perspective of the truth data, i.e. the number of
   * sites in the Truth Call Set for which there are paths through the Query Call
   * Set that are consistent with all of the alleles at this site, and for which
   * there is an accurate genotype call for the event.
   */
  truthTP?: Maybe<Scalars['code']>;
  /** INDEL / SNP / Undefined variant. */
  type: Scalars['code'];
};

/**
 * MolecularSequence.ReferenceSeq
 *     Raw data describing a biological sequence.
 */
export type MolecularSequenceReferenceSeq = {
  __typename?: 'MolecularSequenceReferenceSeq';
  /**
   * Structural unit composed of a nucleic acid molecule which controls its own
   * replication through the interaction of specific proteins at one or more
   * origins of replication ([SO:0000340](http://www.sequenceontology.org/browser/c
   * urrent_svn/term/SO:0000340)).
   */
  chromosome?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The Genome Build used for reference, following GRCh build versions e.g. 'GRCh
   * 37'.  Version number must be included if a versioned release of a primary
   * build was used.
   */
  genomeBuild?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A relative reference to a DNA strand based on gene orientation. The strand
   * that contains the open reading frame of the gene is the "sense" strand, and
   * the opposite complementary strand is the "antisense" strand.
   */
  orientation?: Maybe<Scalars['code']>;
  /**
   * Reference identifier of reference sequence submitted to NCBI. It must match
   * the type in the MolecularSequence.type field. For example, the prefix, “NG_”
   * identifies reference sequence for genes, “NM_” for messenger RNA transcripts,
   * and “NP_” for amino acid sequences.
   */
  referenceSeqId?: Maybe<CodeableConcept>;
  /** A pointer to another MolecularSequence entity as reference sequence. */
  referenceSeqPointer?: Maybe<MolecularSequence>;
  /** A string like "ACGT". */
  referenceSeqString?: Maybe<Scalars['code']>;
  /**
   * An absolute reference to a strand. The Watson strand is the strand whose
   * 5'-end is on the short arm of the chromosome, and the Crick strand as the one
   * whose 5'-end is on the long arm.
   */
  strand?: Maybe<Scalars['code']>;
  /**
   * End position of the window on the reference sequence. If the coordinate system
   * is 0-based then end is exclusive and does not include the last position. If
   * the coordinate system is 1-base, then end is inclusive and includes the last
   * position.
   */
  windowEnd?: Maybe<Scalars['code']>;
  /**
   * Start position of the window on the reference sequence. If the coordinate
   * system is either 0-based or 1-based, then start position is inclusive.
   */
  windowStart?: Maybe<Scalars['code']>;
};

/**
 * MolecularSequence.Repository
 *     Raw data describing a biological sequence.
 */
export type MolecularSequenceRepository = {
  __typename?: 'MolecularSequenceRepository';
  /**
   * Id of the variant in this external repository. The server will understand how
   * to use this id to call for more info about datasets in external repository.
   */
  datasetId?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * URI of an external repository which contains further details about the
   * genetics data.
   */
  name?: Maybe<Scalars['code']>;
  /** Id of the read in this external repository. */
  readsetId?: Maybe<Scalars['code']>;
  /**
   * Click and see / RESTful API / Need login to see / RESTful API with
   * authentication / Other ways to see resource.
   */
  type: Scalars['code'];
  /**
   * URI of an external repository which contains further details about the
   * genetics data.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * Id of the variantset in this external repository. The server will understand
   * how to use this id to call for more info about variantsets in external
   * repository.
   */
  variantsetId?: Maybe<Scalars['code']>;
};

/**
 * MolecularSequence.Roc
 *     Raw data describing a biological sequence.
 */
export type MolecularSequenceRoc = {
  __typename?: 'MolecularSequenceRoc';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Calculated fScore if the GQ score threshold was set to "score" field value. */
  fMeasure?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The number of false negatives if the GQ score threshold was set to "score"
   * field value.
   */
  numFN?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The number of false positives if the GQ score threshold was set to "score"
   * field value.
   */
  numFP?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The number of true positives if the GQ score threshold was set to "score"
   * field value.
   */
  numTP?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Calculated precision if the GQ score threshold was set to "score" field value. */
  precision?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Invidual data point representing the GQ (genotype quality) score threshold. */
  score?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Calculated sensitivity if the GQ score threshold was set to "score" field
   * value.
   */
  sensitivity?: Maybe<Array<Maybe<Scalars['code']>>>;
};

/**
 * MolecularSequence.StructureVariant
 *     Raw data describing a biological sequence.
 */
export type MolecularSequenceStructureVariant = {
  __typename?: 'MolecularSequenceStructureVariant';
  /**
   * Used to indicate if the outer and inner start-end values have the same
   * meaning.
   */
  exact?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Structural variant inner. */
  inner?: Maybe<MolecularSequenceInner>;
  /** Length of the variant chromosome. */
  length?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Structural variant outer. */
  outer?: Maybe<MolecularSequenceOuter>;
  /** Information about chromosome structure variation DNA change type. */
  variantType?: Maybe<CodeableConcept>;
};

/**
 * MolecularSequence.Variant
 *     Raw data describing a biological sequence.
 */
export type MolecularSequenceVariant = {
  __typename?: 'MolecularSequenceVariant';
  /**
   * Extended CIGAR string for aligning the sequence with reference bases. See
   * detailed documentation [here](http://support.illumina.com/help/SequencingAnaly
   * sisWorkflow/Content/Vault/Informatics/Sequencing_Analysis/CASAVA/swSEQ_mCA_Ext
   * endedCIGARFormat.htm).
   */
  cigar?: Maybe<Scalars['code']>;
  /**
   * End position of the variant on the reference sequence. If the coordinate
   * system is 0-based then end is exclusive and does not include the last
   * position. If the coordinate system is 1-base, then end is inclusive and
   * includes the last position.
   */
  end?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * An allele is one of a set of coexisting sequence variants of a gene ([SO:00010
   * 23](http://www.sequenceontology.org/browser/current_svn/term/SO:0001023)).
   * Nucleotide(s)/amino acids from start position of sequence to stop position of
   * sequence on the positive (+) strand of the observed  sequence. When the
   * sequence  type is DNA, it should be the sequence on the positive (+) strand.
   * This will lay in the range between variant.start and variant.end.
   */
  observedAllele?: Maybe<Scalars['code']>;
  /**
   * An allele is one of a set of coexisting sequence variants of a gene ([SO:00010
   * 23](http://www.sequenceontology.org/browser/current_svn/term/SO:0001023)).
   * Nucleotide(s)/amino acids from start position of sequence to stop position of
   * sequence on the positive (+) strand of the reference sequence. When the
   * sequence  type is DNA, it should be the sequence on the positive (+) strand.
   * This will lay in the range between variant.start and variant.end.
   */
  referenceAllele?: Maybe<Scalars['code']>;
  /**
   * Start position of the variant on the  reference sequence. If the coordinate
   * system is either 0-based or 1-based, then start position is inclusive.
   */
  start?: Maybe<Scalars['code']>;
  /** A pointer to an Observation containing variant information. */
  variantPointer?: Maybe<Observation>;
};

/**
 * Money
 *     An amount of economic utility in some recognized currency.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Money = {
  __typename?: 'Money';
  /** ISO 4217 Currency Code. */
  currency?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Numerical value (with implicit precision). */
  value?: Maybe<Scalars['code']>;
};

/**
 * NamingSystem
 *     A curated namespace that issues unique symbols within that namespace for the
 *     identification of concepts, people, devices, etc.  Represents a "System" used
 *     within the Identifier and Coding data types.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type NamingSystem = {
  __typename?: 'NamingSystem';
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The date  (and optionally time) when the naming system was published. The date
   * must change when the business version changes and it must change if the status
   * code changes. In addition, it should change when the substantive content of
   * the naming system changes.
   */
  date: Scalars['code'];
  /**
   * A free text natural language description of the naming system from a
   * consumer's perspective. Details about what the namespace identifies including
   * scope, granularity, version labeling, etc.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A legal or geographic region in which the naming system is intended to be
   * used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Indicates the purpose for the naming system - what kinds of things does it
   * make unique?
   */
  kind: Scalars['code'];
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the naming system. This name should be
   * usable as an identifier for the module by machine processing applications such
   * as code generation.
   */
  name: Scalars['code'];
  /** The name of the organization or individual that published the naming system. */
  publisher?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The name of the organization that is responsible for issuing identifiers or
   * codes for this namespace and ensuring their non-collision.
   */
  responsible?: Maybe<Scalars['code']>;
  /**
   * The status of this naming system. Enables tracking the life-cycle of the
   * content.
   */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * Categorizes a naming system for easier search by grouping related naming
   * systems.
   */
  type?: Maybe<CodeableConcept>;
  /**
   * Indicates how the system may be identified when referenced in electronic
   * exchange.
   */
  uniqueId: Array<Maybe<NamingSystemUniqueId>>;
  /**
   * Provides guidance on the use of the namespace, including the handling of
   * formatting characters, use of upper vs. lower case, etc.
   */
  usage?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate naming
   * system instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
};

/**
 * NamingSystem.UniqueId
 *     A curated namespace that issues unique symbols within that namespace for the
 *     identification of concepts, people, devices, etc.  Represents a "System" used
 *     within the Identifier and Coding data types.
 */
export type NamingSystemUniqueId = {
  __typename?: 'NamingSystemUniqueId';
  /** Notes about the past or intended usage of this identifier. */
  comment?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Identifies the period of time over which this identifier is considered
   * appropriate to refer to the naming system.  Outside of this window, the
   * identifier might be non-deterministic.
   */
  period?: Maybe<Period>;
  /** Indicates whether this identifier is the "preferred" identifier of this type. */
  preferred?: Maybe<Scalars['code']>;
  /** Identifies the unique identifier scheme used for this particular identifier. */
  type: Scalars['code'];
  /**
   * The string that should be sent over the wire to identify the code system or
   * identifier system.
   */
  value: Scalars['code'];
};

/**
 * Narrative
 *     A human-readable summary of the resource conveying the essential clinical and
 *     business information for the resource.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Narrative = {
  __typename?: 'Narrative';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The status of the narrative - whether it's entirely generated (from just the
   * defined data or the extensions too), or whether a human authored it and it may
   * contain additional data.
   */
  status: Scalars['code'];
};

/**
 * NutritionOrder
 *     A request to supply a diet, formula feeding (enteral) or oral nutritional
 *     supplement to a patient/resident.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type NutritionOrder = {
  __typename?: 'NutritionOrder';
  /**
   * A link to a record of allergies or intolerances  which should be included in
   * the nutrition order.
   */
  allergyIntolerance?: Maybe<Array<Maybe<AllergyIntolerance>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The date and time that this nutrition order was requested. */
  dateTime: Scalars['code'];
  /**
   * An encounter that provides additional information about the healthcare context
   * in which this request is made.
   */
  encounter?: Maybe<Encounter>;
  /**
   * Feeding provided through the gastrointestinal tract via a tube, catheter, or
   * stoma that delivers nutrition distal to the oral cavity.
   */
  enteralFormula?: Maybe<NutritionOrderEnteralFormula>;
  /**
   * This modifier is used to convey Order-specific modifier about the type of oral
   * food or oral fluids that should not be given. These can be derived from
   * patient allergies, intolerances, or preferences such as No Red Meat, No Soy or
   * No Wheat or  Gluten-Free.  While it should not be necessary to repeat allergy
   * or intolerance information captured in the referenced AllergyIntolerance
   * resource in the excludeFoodModifier, this element may be used to convey
   * additional specificity related to foods that should be eliminated from the
   * patient’s diet for any reason.  This modifier applies to the entire nutrition
   * order inclusive of the oral diet, nutritional supplements and enteral formula
   * feedings.
   */
  excludeFoodModifier?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * This modifier is used to convey order-specific modifiers about the type of
   * food that should be given. These can be derived from patient allergies,
   * intolerances, or preferences such as Halal, Vegan or Kosher. This modifier
   * applies to the entire nutrition order inclusive of the oral diet, nutritional
   * supplements and enteral formula feedings.
   */
  foodPreferenceModifier?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifiers assigned to this order by the order sender or by the order
   * receiver.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * The URL pointing to a protocol, guideline, orderset or other definition that
   * is adhered to in whole or in part by this NutritionOrder.
   */
  instantiates?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The URL pointing to a FHIR-defined protocol, guideline, orderset or other
   * definition that is adhered to in whole or in part by this NutritionOrder.
   */
  instantiatesCanonical?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The URL pointing to an externally maintained protocol, guideline, orderset or
   * other definition that is adhered to in whole or in part by this
   * NutritionOrder.
   */
  instantiatesUri?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Indicates the level of authority/intentionality associated with the
   * NutrionOrder and where the request fits into the workflow chain.
   */
  intent: Scalars['code'];
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Comments made about the {{title}} by the requester, performer, subject or
   * other participants.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** Diet given orally in contrast to enteral (tube) feeding. */
  oralDiet?: Maybe<NutritionOrderOralDiet>;
  /**
   * The practitioner that holds legal responsibility for ordering the diet,
   * nutritional supplement, or formula feedings.
   */
  orderer?: Maybe<NutritionOrderOrderer>;
  /**
   * The person (patient) who needs the nutrition order for an oral diet,
   * nutritional supplement and/or enteral or formula feeding.
   */
  patient: Patient;
  resourceType?: Maybe<Scalars['String']>;
  /** The workflow status of the nutrition order/request. */
  status: Scalars['code'];
  /**
   * Oral nutritional products given in order to add further nutritional value to
   * the patient's diet.
   */
  supplement?: Maybe<Array<Maybe<NutritionOrderSupplement>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * NutritionOrder.Administration
 *     A request to supply a diet, formula feeding (enteral) or oral nutritional
 *     supplement to a patient/resident.
 */
export type NutritionOrderAdministration = {
  __typename?: 'NutritionOrderAdministration';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The volume of formula to provide to the patient per the specified
   * administration schedule.
   */
  quantity?: Maybe<Quantity>;
  /** None */
  rateQuantity?: Maybe<Quantity>;
  /** None */
  rateRatio?: Maybe<Ratio>;
  /**
   * The time period and frequency at which the enteral formula should be delivered
   * to the patient.
   */
  schedule?: Maybe<Timing>;
};

/**
 * NutritionOrder.EnteralFormula
 *     A request to supply a diet, formula feeding (enteral) or oral nutritional
 *     supplement to a patient/resident.
 */
export type NutritionOrderEnteralFormula = {
  __typename?: 'NutritionOrderEnteralFormula';
  /**
   * The product or brand name of the type of modular component to be added to the
   * formula.
   */
  additiveProductName?: Maybe<Scalars['code']>;
  /**
   * Indicates the type of modular component such as protein, carbohydrate, fat or
   * fiber to be provided in addition to or mixed with the base formula.
   */
  additiveType?: Maybe<CodeableConcept>;
  /**
   * Formula administration instructions as structured data.  This repeating
   * structure allows for changing the administration rate or volume over time for
   * both bolus and continuous feeding.  An example of this would be an instruction
   * to increase the rate of continuous feeding every 2 hours.
   */
  administration?: Maybe<Array<Maybe<NutritionOrderAdministration>>>;
  /**
   * Free text formula administration, feeding instructions or additional
   * instructions or information.
   */
  administrationInstruction?: Maybe<Scalars['code']>;
  /**
   * The product or brand name of the enteral or infant formula product such as
   * "ACME Adult Standard Formula".
   */
  baseFormulaProductName?: Maybe<Scalars['code']>;
  /**
   * The type of enteral or infant formula such as an adult standard formula with
   * fiber or a soy-based infant formula.
   */
  baseFormulaType?: Maybe<CodeableConcept>;
  /**
   * The amount of energy (calories) that the formula should provide per specified
   * volume, typically per mL or fluid oz.  For example, an infant may require a
   * formula that provides 24 calories per fluid ounce or an adult may require an
   * enteral formula that provides 1.5 calorie/mL.
   */
  caloricDensity?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The maximum total quantity of formula that may be administered to a subject
   * over the period of time, e.g. 1440 mL over 24 hours.
   */
  maxVolumeToDeliver?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The route or physiological path of administration into the patient's
   * gastrointestinal  tract for purposes of providing the formula feeding, e.g.
   * nasogastric tube.
   */
  routeofAdministration?: Maybe<CodeableConcept>;
};

/**
 * NutritionOrder.Nutrient
 *     A request to supply a diet, formula feeding (enteral) or oral nutritional
 *     supplement to a patient/resident.
 */
export type NutritionOrderNutrient = {
  __typename?: 'NutritionOrderNutrient';
  /** The quantity of the specified nutrient to include in diet. */
  amount?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The nutrient that is being modified such as carbohydrate or sodium. */
  modifier?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * NutritionOrder.OralDiet
 *     A request to supply a diet, formula feeding (enteral) or oral nutritional
 *     supplement to a patient/resident.
 */
export type NutritionOrderOralDiet = {
  __typename?: 'NutritionOrderOralDiet';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The required consistency (e.g. honey-thick, nectar-thick, thin, thickened.) of
   * liquids or fluids served to the patient.
   */
  fluidConsistencyType?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Free text or additional instructions or information pertaining to the oral
   * diet.
   */
  instruction?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Class that defines the quantity and type of nutrient modifications (for
   * example carbohydrate, fiber or sodium) required for the oral diet.
   */
  nutrient?: Maybe<Array<Maybe<NutritionOrderNutrient>>>;
  /**
   * The time period and frequency at which the diet should be given.  The diet
   * should be given for the combination of all schedules if more than one schedule
   * is present.
   */
  schedule?: Maybe<Array<Maybe<Timing>>>;
  /**
   * Class that describes any texture modifications required for the patient to
   * safely consume various types of solid foods.
   */
  texture?: Maybe<Array<Maybe<NutritionOrderTexture>>>;
  /**
   * The kind of diet or dietary restriction such as fiber restricted diet or
   * diabetic diet.
   */
  type?: Maybe<Array<Maybe<CodeableConcept>>>;
};

export type NutritionOrderOrderer = Practitioner | PractitionerRole;

/**
 * NutritionOrder.Supplement
 *     A request to supply a diet, formula feeding (enteral) or oral nutritional
 *     supplement to a patient/resident.
 */
export type NutritionOrderSupplement = {
  __typename?: 'NutritionOrderSupplement';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Free text or additional instructions or information pertaining to the oral
   * supplement.
   */
  instruction?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The product or brand name of the nutritional supplement such as "Acme Protein
   * Shake".
   */
  productName?: Maybe<Scalars['code']>;
  /** The amount of the nutritional supplement to be given. */
  quantity?: Maybe<Quantity>;
  /**
   * The time period and frequency at which the supplement(s) should be given.  The
   * supplement should be given for the combination of all schedules if more than
   * one schedule is present.
   */
  schedule?: Maybe<Array<Maybe<Timing>>>;
  /**
   * The kind of nutritional supplement product required such as a high protein or
   * pediatric clear liquid supplement.
   */
  type?: Maybe<CodeableConcept>;
};

/**
 * NutritionOrder.Texture
 *     A request to supply a diet, formula feeding (enteral) or oral nutritional
 *     supplement to a patient/resident.
 */
export type NutritionOrderTexture = {
  __typename?: 'NutritionOrderTexture';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The food type(s) (e.g. meats, all foods)  that the texture modification
   * applies to.  This could be all foods types.
   */
  foodType?: Maybe<CodeableConcept>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Any texture modifications (for solid foods) that should be made, e.g. easy to
   * chew, chopped, ground, and pureed.
   */
  modifier?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * Observation
 *     Measurements and simple assertions made about a patient, device or other
 *     subject.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Observation = {
  __typename?: 'Observation';
  /**
   * A plan, proposal or order that is fulfilled in whole or in part by this event.
   * For example, a MedicationRequest may require a patient to have laboratory test
   * performed before  it is dispensed.
   */
  basedOn?: Maybe<Array<Maybe<ObservationBasedOn>>>;
  /**
   * Indicates the site on the subject's body where the observation was made (i.e.
   * the target site).
   */
  bodySite?: Maybe<CodeableConcept>;
  /** A code that classifies the general type of observation being made. */
  category?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Describes what was observed. Sometimes this is called the observation "name". */
  code: CodeableConcept;
  /**
   * Some observations have multiple component observations.  These component
   * observations are expressed as separate code value pairs that share the same
   * attributes.  Examples include systolic and diastolic component observations
   * for blood pressure measurement and multiple component observations for
   * genetics observations.
   */
  component?: Maybe<Array<Maybe<ObservationComponent>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Provides a reason why the expected value in the element Observation.value[x]
   * is missing.
   */
  dataAbsentReason?: Maybe<CodeableConcept>;
  /**
   * The target resource that represents a measurement from which this observation
   * value is derived. For example, a calculated anion gap or a fetal measurement
   * based on an ultrasound image.
   */
  derivedFrom?: Maybe<Array<Maybe<ObservationDerivedFrom>>>;
  /** The device used to generate the observation data. */
  device?: Maybe<ObservationDevice>;
  /** None */
  effectiveDateTime?: Maybe<Scalars['code']>;
  /** None */
  effectiveInstant?: Maybe<Scalars['code']>;
  /** None */
  effectivePeriod?: Maybe<Period>;
  /** None */
  effectiveTiming?: Maybe<Timing>;
  /**
   * The healthcare event  (e.g. a patient and healthcare provider interaction)
   * during which this observation is made.
   */
  encounter?: Maybe<Encounter>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The actual focus of an observation when it is not the patient of record
   * representing something or someone associated with the patient such as a
   * spouse, parent, fetus, or donor. For example, fetus observations in a mother's
   * record.  The focus of an observation could also be an existing condition,  an
   * intervention, the subject's diet,  another observation of the subject,  or a
   * body structure such as tumor or implanted device.   An example use case would
   * be using the Observation resource to capture whether the mother is trained to
   * change her child's tracheostomy tube. In this example, the child is the
   * patient of record and the mother is the focus.
   */
  focus?: Maybe<Array<Maybe<Resource>>>;
  /**
   * This observation is a group observation (e.g. a battery, a panel of tests, a
   * set of vital sign measurements) that includes the target as a member of the
   * group.
   */
  hasMember?: Maybe<Array<Maybe<ObservationHasMember>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** A unique identifier assigned to this observation. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A categorical assessment of an observation value.  For example, high, low,
   * normal.
   */
  interpretation?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The date and time this version of the observation was made available to
   * providers, typically after the results have been reviewed and verified.
   */
  issued?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /** Indicates the mechanism used to perform the observation. */
  method?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Comments about the observation or the results. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /**
   * A larger event of which this particular Observation is a component or step.
   * For example,  an observation as part of a procedure.
   */
  partOf?: Maybe<Array<Maybe<ObservationPartOf>>>;
  /** Who was responsible for asserting the observed value as "true". */
  performer?: Maybe<Array<Maybe<ObservationPerformer>>>;
  /**
   * Guidance on how to interpret the value by comparison to a normal or
   * recommended range.  Multiple reference ranges are interpreted as an "OR".   In
   * other words, to represent two distinct target populations, two
   * `referenceRange` elements would be used.
   */
  referenceRange?: Maybe<Array<Maybe<ObservationReferenceRange>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** The specimen that was used when this observation was made. */
  specimen?: Maybe<Specimen>;
  /** The status of the result value. */
  status: Scalars['code'];
  /**
   * The patient, or group of patients, location, or device this observation is
   * about and into whose record the observation is placed. If the actual focus of
   * the observation is different from the subject (or a sample of, part, or region
   * of the subject), the `focus` element or the `code` itself specifies the actual
   * focus of the observation.
   */
  subject?: Maybe<ObservationSubject>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** None */
  valueBoolean?: Maybe<Scalars['code']>;
  /** None */
  valueCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  valueDateTime?: Maybe<Scalars['code']>;
  /** None */
  valueInteger?: Maybe<Scalars['code']>;
  /** None */
  valuePeriod?: Maybe<Period>;
  /** None */
  valueQuantity?: Maybe<Quantity>;
  /** None */
  valueRange?: Maybe<Range>;
  /** None */
  valueRatio?: Maybe<Ratio>;
  /** None */
  valueSampledData?: Maybe<SampledData>;
  /** None */
  valueString?: Maybe<Scalars['code']>;
  /** None */
  valueTime?: Maybe<Scalars['code']>;
};

export type ObservationBasedOn = CarePlan | DeviceRequest | ImmunizationRecommendation | MedicationRequest | NutritionOrder | ServiceRequest;

/**
 * Observation.Component
 *     Measurements and simple assertions made about a patient, device or other
 *     subject.
 */
export type ObservationComponent = {
  __typename?: 'ObservationComponent';
  /** Describes what was observed. Sometimes this is called the observation "code". */
  code: CodeableConcept;
  /**
   * Provides a reason why the expected value in the element
   * Observation.component.value[x] is missing.
   */
  dataAbsentReason?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * A categorical assessment of an observation value.  For example, high, low,
   * normal.
   */
  interpretation?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Guidance on how to interpret the value by comparison to a normal or
   * recommended range.
   */
  referenceRange?: Maybe<Array<Maybe<ObservationReferenceRange>>>;
  /** None */
  valueBoolean?: Maybe<Scalars['code']>;
  /** None */
  valueCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  valueDateTime?: Maybe<Scalars['code']>;
  /** None */
  valueInteger?: Maybe<Scalars['code']>;
  /** None */
  valuePeriod?: Maybe<Period>;
  /** None */
  valueQuantity?: Maybe<Quantity>;
  /** None */
  valueRange?: Maybe<Range>;
  /** None */
  valueRatio?: Maybe<Ratio>;
  /** None */
  valueSampledData?: Maybe<SampledData>;
  /** None */
  valueString?: Maybe<Scalars['code']>;
  /** None */
  valueTime?: Maybe<Scalars['code']>;
};

/**
 * ObservationDefinition
 *     Set of definitional characteristics for a kind of observation or measurement
 *     produced or consumed by an orderable health care service.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ObservationDefinition = {
  __typename?: 'ObservationDefinition';
  /**
   * The set of abnormal coded results for the observation conforming to this
   * ObservationDefinition.
   */
  abnormalCodedValueSet?: Maybe<ValueSet>;
  /** A code that classifies the general type of observation. */
  category?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Describes what will be observed. Sometimes this is called the observation
   * "name".
   */
  code: CodeableConcept;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The set of critical coded results for the observation conforming to this
   * ObservationDefinition.
   */
  criticalCodedValueSet?: Maybe<ValueSet>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** A unique identifier assigned to this ObservationDefinition artifact. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /** The method or technique used to perform the observation. */
  method?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Multiple results allowed for observations conforming to this
   * ObservationDefinition.
   */
  multipleResultsAllowed?: Maybe<Scalars['code']>;
  /**
   * The set of normal coded results for the observations conforming to this
   * ObservationDefinition.
   */
  normalCodedValueSet?: Maybe<ValueSet>;
  /**
   * The data types allowed for the value element of the instance observations
   * conforming to this ObservationDefinition.
   */
  permittedDataType?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The preferred name to be used when reporting the results of observations
   * conforming to this ObservationDefinition.
   */
  preferredReportName?: Maybe<Scalars['code']>;
  /**
   * Multiple  ranges of results qualified by different contexts for ordinal or
   * continuous observations conforming to this ObservationDefinition.
   */
  qualifiedInterval?: Maybe<Array<Maybe<ObservationDefinitionQualifiedInterval>>>;
  /** Characteristics for quantitative results of this observation. */
  quantitativeDetails?: Maybe<ObservationDefinitionQuantitativeDetails>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * The set of valid coded results for the observations  conforming to this
   * ObservationDefinition.
   */
  validCodedValueSet?: Maybe<ValueSet>;
};

/**
 * ObservationDefinition.QualifiedInterval
 *     Set of definitional characteristics for a kind of observation or measurement
 *     produced or consumed by an orderable health care service.
 */
export type ObservationDefinitionQualifiedInterval = {
  __typename?: 'ObservationDefinitionQualifiedInterval';
  /**
   * The age at which this reference range is applicable. This is a neonatal age
   * (e.g. number of weeks at term) if the meaning says so.
   */
  age?: Maybe<Range>;
  /** Codes to indicate the target population this reference range applies to. */
  appliesTo?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The category of interval of values for continuous or ordinal observations
   * conforming to this ObservationDefinition.
   */
  category?: Maybe<Scalars['code']>;
  /** Text based condition for which the reference range is valid. */
  condition?: Maybe<Scalars['code']>;
  /**
   * Codes to indicate the health context the range applies to. For example, the
   * normal or therapeutic range.
   */
  context?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Sex of the population the range applies to. */
  gender?: Maybe<Scalars['code']>;
  /**
   * The gestational age to which this reference range is applicable, in the
   * context of pregnancy.
   */
  gestationalAge?: Maybe<Range>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The low and high values determining the interval. There may be only one of the
   * two.
   */
  range?: Maybe<Range>;
};

/**
 * ObservationDefinition.QuantitativeDetails
 *     Set of definitional characteristics for a kind of observation or measurement
 *     produced or consumed by an orderable health care service.
 */
export type ObservationDefinitionQuantitativeDetails = {
  __typename?: 'ObservationDefinitionQuantitativeDetails';
  /**
   * Factor for converting value expressed with SI unit to value expressed with
   * customary unit.
   */
  conversionFactor?: Maybe<Scalars['code']>;
  /**
   * Customary unit used to report quantitative results of observations conforming
   * to this ObservationDefinition.
   */
  customaryUnit?: Maybe<CodeableConcept>;
  /**
   * Number of digits after decimal separator when the results of such observations
   * are of type Quantity.
   */
  decimalPrecision?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * SI unit used to report quantitative results of observations conforming to this
   * ObservationDefinition.
   */
  unit?: Maybe<CodeableConcept>;
};

export type ObservationDerivedFrom = DocumentReference | ImagingStudy | Media | MolecularSequence | Observation | QuestionnaireResponse;

export type ObservationDevice = Device | DeviceMetric;

export type ObservationHasMember = MolecularSequence | Observation | QuestionnaireResponse;

export type ObservationPartOf = ImagingStudy | Immunization | MedicationAdministration | MedicationDispense | MedicationStatement | Procedure;

export type ObservationPerformer = CareTeam | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Observation.ReferenceRange
 *     Measurements and simple assertions made about a patient, device or other
 *     subject.
 */
export type ObservationReferenceRange = {
  __typename?: 'ObservationReferenceRange';
  /**
   * The age at which this reference range is applicable. This is a neonatal age
   * (e.g. number of weeks at term) if the meaning says so.
   */
  age?: Maybe<Range>;
  /**
   * Codes to indicate the target population this reference range applies to.  For
   * example, a reference range may be based on the normal population or a
   * particular sex or race.  Multiple `appliesTo`  are interpreted as an "AND" of
   * the target populations.  For example, to represent a target population of
   * African American females, both a code of female and a code for African
   * American would be used.
   */
  appliesTo?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The value of the high bound of the reference range.  The high bound of the
   * reference range endpoint is inclusive of the value (e.g.  reference range is
   * >=5 - <=9). If the high bound is omitted,  it is assumed to be meaningless
   * (e.g. reference range is >= 2.3).
   */
  high?: Maybe<Quantity>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The value of the low bound of the reference range.  The low bound of the
   * reference range endpoint is inclusive of the value (e.g.  reference range is
   * >=5 - <=9). If the low bound is omitted,  it is assumed to be meaningless
   * (e.g. reference range is <=2.3).
   */
  low?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Text based reference range in an observation which may be used when a
   * quantitative range is not appropriate for an observation.  An example would be
   * a reference value of "Negative" or a list or table of "normals".
   */
  text?: Maybe<Scalars['code']>;
  /**
   * Codes to indicate the what part of the targeted reference population it
   * applies to. For example, the normal or therapeutic range.
   */
  type?: Maybe<CodeableConcept>;
};

export type ObservationSubject = Device | Group | Location | Patient;

/**
 * OperationDefinition
 *     A formal computable definition of an operation (on the RESTful interface) or a
 *     named query (using the search interaction).
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type OperationDefinition = {
  __typename?: 'OperationDefinition';
  /**
   * Whether the operation affects state. Side effects such as producing audit
   * trail entries do not count as 'affecting  state'.
   */
  affectsState?: Maybe<Scalars['code']>;
  /**
   * Indicates that this operation definition is a constraining profile on the
   * base.
   */
  base?: Maybe<Scalars['code']>;
  /** The name used to invoke the operation. */
  code: Scalars['code'];
  /** Additional information about how to use this operation or named query. */
  comment?: Maybe<Scalars['code']>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The date  (and optionally time) when the operation definition was published.
   * The date must change when the business version changes and it must change if
   * the status code changes. In addition, it should change when the substantive
   * content of the operation definition changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the operation definition from a
   * consumer's perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * A Boolean value to indicate that this operation definition is authored for
   * testing purposes (or education/evaluation/marketing) and is not intended to be
   * used for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * Additional validation information for the in parameters - a single profile
   * that covers all the parameters. The profile is a constraint on the parameters
   * resource as a whole.
   */
  inputProfile?: Maybe<Scalars['code']>;
  /**
   * Indicates whether this operation can be invoked on a particular instance of
   * one of the given types.
   */
  instance: Scalars['code'];
  /**
   * A legal or geographic region in which the operation definition is intended to
   * be used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Whether this is an operation or a named query. */
  kind: Scalars['code'];
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the operation definition. This name should
   * be usable as an identifier for the module by machine processing applications
   * such as code generation.
   */
  name: Scalars['code'];
  /**
   * Additional validation information for the out parameters - a single profile
   * that covers all the parameters. The profile is a constraint on the parameters
   * resource.
   */
  outputProfile?: Maybe<Scalars['code']>;
  /**
   * Defines an appropriate combination of parameters to use when invoking this
   * operation, to help code generators when generating overloaded parameter sets
   * for this operation.
   */
  overload?: Maybe<Array<Maybe<OperationDefinitionOverload>>>;
  /** The parameters for the operation/query. */
  parameter?: Maybe<Array<Maybe<OperationDefinitionParameter>>>;
  /**
   * The name of the organization or individual that published the operation
   * definition.
   */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this operation definition is needed and why it has been
   * designed as it has.
   */
  purpose?: Maybe<Scalars['code']>;
  /** The types on which this operation can be executed. */
  resource?: Maybe<Array<Maybe<Scalars['code']>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The status of this operation definition. Enables tracking the life-cycle of
   * the content.
   */
  status: Scalars['code'];
  /**
   * Indicates whether this operation or named query can be invoked at the system
   * level (e.g. without needing to choose a resource type for the context).
   */
  system: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the operation definition. */
  title?: Maybe<Scalars['code']>;
  /**
   * Indicates whether this operation or named query can be invoked at the resource
   * type level for any given resource type level (e.g. without needing to choose a
   * specific resource id for the context).
   */
  type: Scalars['code'];
  /**
   * An absolute URI that is used to identify this operation definition when it is
   * referenced in a specification, model, design or an instance; also called its
   * canonical identifier. This SHOULD be globally unique and SHOULD be a literal
   * address at which at which an authoritative instance of this operation
   * definition is (or will be) published. This URL can be the target of a
   * canonical reference. It SHALL remain the same when the operation definition is
   * stored on different servers.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate operation
   * definition instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the operation
   * definition when it is referenced in a specification, model, design or
   * instance. This is an arbitrary value managed by the operation definition
   * author and is not expected to be globally unique. For example, it might be a
   * timestamp (e.g. yyyymmdd) if a managed version is not available. There is also
   * no expectation that versions can be placed in a lexicographical sequence.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * OperationDefinition.Binding
 *     A formal computable definition of an operation (on the RESTful interface) or a
 *     named query (using the search interaction).
 */
export type OperationDefinitionBinding = {
  __typename?: 'OperationDefinitionBinding';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Indicates the degree of conformance expectations associated with this binding
   * - that is, the degree to which the provided value set must be adhered to in
   * the instances.
   */
  strength: Scalars['code'];
  /**
   * Points to the value set or external definition (e.g. implicit value set) that
   * identifies the set of codes to be used.
   */
  valueSet: Scalars['code'];
};

/**
 * OperationDefinition.Overload
 *     A formal computable definition of an operation (on the RESTful interface) or a
 *     named query (using the search interaction).
 */
export type OperationDefinitionOverload = {
  __typename?: 'OperationDefinitionOverload';
  /** Comments to go on overload. */
  comment?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Name of parameter to include in overload. */
  parameterName?: Maybe<Array<Maybe<Scalars['code']>>>;
};

/**
 * OperationDefinition.Parameter
 *     A formal computable definition of an operation (on the RESTful interface) or a
 *     named query (using the search interaction).
 */
export type OperationDefinitionParameter = {
  __typename?: 'OperationDefinitionParameter';
  /**
   * Binds to a value set if this parameter is coded (code, Coding,
   * CodeableConcept).
   */
  binding?: Maybe<OperationDefinitionBinding>;
  /** Describes the meaning or use of this parameter. */
  documentation?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The maximum number of times this element is permitted to appear in the request
   * or response.
   */
  max: Scalars['code'];
  /**
   * The minimum number of times this parameter SHALL appear in the request or
   * response.
   */
  min: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The name of used to identify the parameter. */
  name: Scalars['code'];
  /** The parts of a nested Parameter. */
  part?: Maybe<Array<Maybe<OperationDefinitionParameter>>>;
  /**
   * Identifies other resource parameters within the operation invocation that are
   * expected to resolve to this resource.
   */
  referencedFrom?: Maybe<Array<Maybe<OperationDefinitionReferencedFrom>>>;
  /**
   * How the parameter is understood as a search parameter. This is only used if
   * the parameter type is 'string'.
   */
  searchType?: Maybe<Scalars['code']>;
  /**
   * Used when the type is "Reference" or "canonical", and identifies a profile
   * structure or implementation Guide that applies to the target of the reference
   * this parameter refers to. If any profiles are specified, then the content must
   * conform to at least one of them. The URL can be a local reference - to a
   * contained StructureDefinition, or a reference to another StructureDefinition
   * or Implementation Guide by a canonical URL. When an implementation guide is
   * specified, the target resource SHALL conform to at least one profile defined
   * in the implementation guide.
   */
  targetProfile?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The type for this parameter. */
  type?: Maybe<Scalars['code']>;
  /** Whether this is an input or an output parameter. */
  use: Scalars['code'];
};

/**
 * OperationDefinition.ReferencedFrom
 *     A formal computable definition of an operation (on the RESTful interface) or a
 *     named query (using the search interaction).
 */
export type OperationDefinitionReferencedFrom = {
  __typename?: 'OperationDefinitionReferencedFrom';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The name of the parameter or dot-separated path of parameter names pointing to
   * the resource parameter that is expected to contain a reference to this
   * resource.
   */
  source: Scalars['code'];
  /**
   * The id of the element in the referencing resource that is expected to resolve
   * to this resource.
   */
  sourceId?: Maybe<Scalars['code']>;
};

/**
 * OperationOutcome
 *     A collection of error, warning, or information messages that result from a
 *     system action.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type OperationOutcome = {
  __typename?: 'OperationOutcome';
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** An error, warning, or information message that results from a system action. */
  issue: Array<Maybe<OperationOutcomeIssue>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * OperationOutcome.Issue
 *     A collection of error, warning, or information messages that result from a
 *     system action.
 */
export type OperationOutcomeIssue = {
  __typename?: 'OperationOutcomeIssue';
  /**
   * Describes the type of the issue. The system that creates an OperationOutcome
   * SHALL choose the most applicable code from the IssueType value set, and may
   * additional provide its own code for the error in the details element.
   */
  code: Scalars['code'];
  /**
   * Additional details about the error. This may be a text description of the
   * error or a system code that identifies the error.
   */
  details?: Maybe<CodeableConcept>;
  /** Additional diagnostic information about the issue. */
  diagnostics?: Maybe<Scalars['code']>;
  /**
   * A [simple subset of FHIRPath](fhirpath.html#simple) limited to element names,
   * repetition indicators and the default child accessor that identifies one of
   * the elements in the resource that caused this issue to be raised.
   */
  expression?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * This element is deprecated because it is XML specific. It is replaced by
   * issue.expression, which is format independent, and simpler to parse.
   *
   * For resource issues, this will be a simple XPath limited to element names,
   * repetition indicators and the default child accessor that identifies one of
   * the elements in the resource that caused this issue to be raised.  For HTTP
   * errors, will be "http." + the parameter name.
   */
  location?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Indicates whether the issue indicates a variation from successful processing. */
  severity: Scalars['code'];
};

/**
 * Organization
 *     A formally or informally recognized grouping of people or organizations formed
 *     for the purpose of achieving some form of collective action.  Includes
 *     companies, institutions, corporations, departments, community groups,
 *     healthcare practice groups, payer/insurer, etc.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Organization = {
  __typename?: 'Organization';
  /** Whether the organization's record is still in active use. */
  active?: Maybe<Scalars['code']>;
  /** An address for the organization. */
  address?: Maybe<Array<Maybe<Address>>>;
  /**
   * A list of alternate names that the organization is known as, or was known as
   * in the past.
   */
  alias?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Contact for the organization for a certain purpose. */
  contact?: Maybe<Array<Maybe<OrganizationContact>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  email?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Technical endpoints providing access to services operated for the
   * organization.
   */
  endpoint?: Maybe<Array<Maybe<Endpoint>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifier for the organization that is used to identify the organization
   * across multiple disparate systems.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A name associated with the organization. */
  name?: Maybe<Scalars['code']>;
  /** The organization of which this organization forms a part. */
  partOf?: Maybe<Organization>;
  phone?: Maybe<Array<Maybe<Scalars['String']>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** A contact detail for the organization. */
  telecom?: Maybe<Array<Maybe<ContactPoint>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** The kind(s) of organization that this is. */
  type?: Maybe<Array<Maybe<CodeableConcept>>>;
};

/**
 * OrganizationAffiliation
 *     Defines an affiliation/assotiation/relationship between 2 distinct
 *     oganizations, that is not a part-of relationship/sub-division relationship.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type OrganizationAffiliation = {
  __typename?: 'OrganizationAffiliation';
  /** Whether this organization affiliation record is in active use. */
  active?: Maybe<Scalars['code']>;
  /** Definition of the role the participatingOrganization plays in the association. */
  code?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  email?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Technical endpoints providing access to services operated for this role. */
  endpoint?: Maybe<Array<Maybe<Endpoint>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Healthcare services provided through the role. */
  healthcareService?: Maybe<Array<Maybe<HealthcareService>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Business identifiers that are specific to this role. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** The location(s) at which the role occurs. */
  location?: Maybe<Array<Maybe<Location>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Health insurance provider network in which the participatingOrganization
   * provides the role's services (if defined) at the indicated locations (if
   * defined).
   */
  network?: Maybe<Array<Maybe<Organization>>>;
  /** Organization where the role is available (primary organization/has members). */
  organization?: Maybe<Organization>;
  /**
   * The Participating Organization provides/performs the role(s) defined by the
   * code to the Primary Organization (e.g. providing services or is a member of).
   */
  participatingOrganization?: Maybe<Organization>;
  /**
   * The period during which the participatingOrganization is affiliated with the
   * primary organization.
   */
  period?: Maybe<Period>;
  phone?: Maybe<Array<Maybe<Scalars['String']>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * Specific specialty of the participatingOrganization in the context of the
   * role.
   */
  specialty?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Contact details at the participatingOrganization relevant to this Affiliation. */
  telecom?: Maybe<Array<Maybe<ContactPoint>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * Organization.Contact
 *     A formally or informally recognized grouping of people or organizations formed
 *     for the purpose of achieving some form of collective action.  Includes
 *     companies, institutions, corporations, departments, community groups,
 *     healthcare practice groups, payer/insurer, etc.
 */
export type OrganizationContact = {
  __typename?: 'OrganizationContact';
  /** Visiting or postal addresses for the contact. */
  address?: Maybe<Address>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A name associated with the contact. */
  name?: Maybe<HumanName>;
  /** Indicates a purpose for which the contact can be reached. */
  purpose?: Maybe<CodeableConcept>;
  /**
   * A contact detail (e.g. a telephone number or an email address) by which the
   * party may be contacted.
   */
  telecom?: Maybe<Array<Maybe<ContactPoint>>>;
};

/**
 * ParameterDefinition
 *     The parameters to the module. This collection specifies both the input and
 *     output parameters. Input parameters are provided by the caller as part of the
 *     $evaluate operation. Output parameters are included in the GuidanceResponse.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type ParameterDefinition = {
  __typename?: 'ParameterDefinition';
  /**
   * A brief discussion of what the parameter is for and how it is used by the
   * module.
   */
  documentation?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The maximum number of times this element is permitted to appear in the request
   * or response.
   */
  max?: Maybe<Scalars['code']>;
  /**
   * The minimum number of times this parameter SHALL appear in the request or
   * response.
   */
  min?: Maybe<Scalars['code']>;
  /**
   * The name of the parameter used to allow access to the value of the parameter
   * in evaluation contexts.
   */
  name?: Maybe<Scalars['code']>;
  /**
   * If specified, this indicates a profile that the input data must conform to, or
   * that the output data will conform to.
   */
  profile?: Maybe<Scalars['code']>;
  /** The type of the parameter. */
  type: Scalars['code'];
  /** Whether the parameter is input or output for the module. */
  use: Scalars['code'];
};

/**
 * Parameters
 *     This resource is a non-persisted resource used to pass information into and
 *     back from an [operation](operations.html). It has no other use, and there is
 *     no RESTful endpoint associated with it.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Parameters = {
  __typename?: 'Parameters';
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /** A parameter passed to or received from the operation. */
  parameter?: Maybe<Array<Maybe<ParametersParameter>>>;
  resourceType?: Maybe<Scalars['String']>;
};

/**
 * Parameters.Parameter
 *     This resource is a non-persisted resource used to pass information into and
 *     back from an [operation](operations.html). It has no other use, and there is
 *     no RESTful endpoint associated with it.
 */
export type ParametersParameter = {
  __typename?: 'ParametersParameter';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The name of the parameter (reference to the operation definition). */
  name: Scalars['code'];
  /** A named part of a multi-part parameter. */
  part?: Maybe<Array<Maybe<ParametersParameter>>>;
  /** If the parameter is a whole resource. */
  resource?: Maybe<Scalars['code']>;
  /** None */
  valueAddress?: Maybe<Address>;
  /** None */
  valueAge?: Maybe<Quantity>;
  /** None */
  valueAnnotation?: Maybe<Annotation>;
  /** None */
  valueAttachment?: Maybe<Attachment>;
  /** None */
  valueBase64Binary?: Maybe<Scalars['code']>;
  /** None */
  valueBoolean?: Maybe<Scalars['code']>;
  /** None */
  valueCanonical?: Maybe<Scalars['code']>;
  /** None */
  valueCode?: Maybe<Scalars['code']>;
  /** None */
  valueCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  valueCoding?: Maybe<Coding>;
  /** None */
  valueContactDetail?: Maybe<ContactDetail>;
  /** None */
  valueContactPoint?: Maybe<ContactPoint>;
  /** None */
  valueContributor?: Maybe<Contributor>;
  /** None */
  valueCount?: Maybe<Quantity>;
  /** None */
  valueDataRequirement?: Maybe<DataRequirement>;
  /** None */
  valueDate?: Maybe<Scalars['code']>;
  /** None */
  valueDateTime?: Maybe<Scalars['code']>;
  /** None */
  valueDecimal?: Maybe<Scalars['code']>;
  /** None */
  valueDistance?: Maybe<Quantity>;
  /** None */
  valueDosage?: Maybe<Dosage>;
  /** None */
  valueDuration?: Maybe<Quantity>;
  /** None */
  valueExpression?: Maybe<Expression>;
  /** None */
  valueHumanName?: Maybe<HumanName>;
  /** None */
  valueId?: Maybe<Scalars['code']>;
  /** None */
  valueIdentifier?: Maybe<Identifier>;
  /** None */
  valueInstant?: Maybe<Scalars['code']>;
  /** None */
  valueInteger?: Maybe<Scalars['code']>;
  /** None */
  valueMarkdown?: Maybe<Scalars['code']>;
  /** None */
  valueMeta?: Maybe<Meta>;
  /** None */
  valueMoney?: Maybe<Money>;
  /** None */
  valueOid?: Maybe<Scalars['code']>;
  /** None */
  valueParameterDefinition?: Maybe<ParameterDefinition>;
  /** None */
  valuePeriod?: Maybe<Period>;
  /** None */
  valuePositiveInt?: Maybe<Scalars['code']>;
  /** None */
  valueQuantity?: Maybe<Quantity>;
  /** None */
  valueRange?: Maybe<Range>;
  /** None */
  valueRatio?: Maybe<Ratio>;
  /** None */
  valueReference?: Maybe<Resource>;
  /** None */
  valueRelatedArtifact?: Maybe<RelatedArtifact>;
  /** None */
  valueSampledData?: Maybe<SampledData>;
  /** None */
  valueSignature?: Maybe<Signature>;
  /** None */
  valueString?: Maybe<Scalars['code']>;
  /** None */
  valueTime?: Maybe<Scalars['code']>;
  /** None */
  valueTiming?: Maybe<Timing>;
  /** None */
  valueTriggerDefinition?: Maybe<TriggerDefinition>;
  /** None */
  valueUnsignedInt?: Maybe<Scalars['code']>;
  /** None */
  valueUri?: Maybe<Scalars['code']>;
  /** None */
  valueUrl?: Maybe<Scalars['code']>;
  /** None */
  valueUsageContext?: Maybe<UsageContext>;
  /** None */
  valueUuid?: Maybe<Scalars['code']>;
};

/**
 * Patient
 *     Demographics and other administrative information about an individual or
 *     animal receiving care or other health-related services.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Patient = {
  __typename?: 'Patient';
  /**
   * Whether this patient record is in active use.
   * Many systems use this property to mark as non-current patients, such as those
   * that have not been seen for a period of time based on an organization's
   * business rules.
   *
   * It is often used to filter patient lists to exclude inactive patients
   *
   * Deceased patients may also be marked as inactive for the same reasons, but may
   * be active for some time after death.
   */
  active?: Maybe<Scalars['code']>;
  /** An address for the individual. */
  address?: Maybe<Array<Maybe<Address>>>;
  appointment?: Maybe<Array<Maybe<Appointment>>>;
  /** The date of birth for the individual. */
  birthDate?: Maybe<Scalars['code']>;
  /**
   * A language which may be used to communicate with the patient about his or her
   * health.
   */
  communication?: Maybe<Array<Maybe<PatientCommunication>>>;
  composition?: Maybe<Array<Maybe<Composition>>>;
  /** A contact party (e.g. guardian, partner, friend) for the patient. */
  contact?: Maybe<Array<Maybe<PatientContact>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** None */
  deceasedBoolean?: Maybe<Scalars['code']>;
  /** None */
  deceasedDateTime?: Maybe<Scalars['code']>;
  document?: Maybe<Array<Maybe<DocumentReference>>>;
  edipi?: Maybe<Scalars['String']>;
  email?: Maybe<Array<Maybe<Scalars['String']>>>;
  encounter?: Maybe<Array<Maybe<Encounter>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  flatName?: Maybe<Scalars['String']>;
  /**
   * Administrative Gender - the gender that the patient is considered to have for
   * administration and record keeping purposes.
   */
  gender?: Maybe<Scalars['code']>;
  /** Patient's nominated care provider. */
  generalPractitioner?: Maybe<Array<Maybe<PatientGeneralPractitioner>>>;
  icn?: Maybe<Scalars['String']>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** An identifier for this patient. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  last4?: Maybe<Scalars['String']>;
  /** Link to another patient resource that concerns the same actual patient. */
  link?: Maybe<Array<Maybe<PatientLink>>>;
  /** Organization that is the custodian of the patient record. */
  managingOrganization?: Maybe<Organization>;
  /** This field contains a patient's most recent marital (civil) status. */
  maritalStatus?: Maybe<CodeableConcept>;
  memberId?: Maybe<Scalars['String']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  multipleBirthBoolean?: Maybe<Scalars['code']>;
  /** None */
  multipleBirthInteger?: Maybe<Scalars['code']>;
  /** A name associated with the individual. */
  name?: Maybe<Array<Maybe<HumanName>>>;
  networkStatus?: Maybe<Scalars['String']>;
  phone?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Image of the patient. */
  photo?: Maybe<Array<Maybe<Attachment>>>;
  procedure?: Maybe<Array<Maybe<Procedure>>>;
  referralNumber?: Maybe<Scalars['String']>;
  removedFromNetwork?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  serviceName?: Maybe<Scalars['String']>;
  serviceRequest?: Maybe<Array<Maybe<ServiceRequest>>>;
  /**
   * A contact detail (e.g. a telephone number or an email address) by which the
   * individual may be contacted.
   */
  telecom?: Maybe<Array<Maybe<ContactPoint>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  zeelId?: Maybe<Scalars['String']>;
};

/**
 * Patient.Communication
 *     Demographics and other administrative information about an individual or
 *     animal receiving care or other health-related services.
 */
export type PatientCommunication = {
  __typename?: 'PatientCommunication';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The ISO-639-1 alpha 2 code in lower case for the language, optionally followed
   * by a hyphen and the ISO-3166-1 alpha 2 code for the region in upper case; e.g.
   * "en" for English, or "en-US" for American English versus "en-EN" for England
   * English.
   */
  language: CodeableConcept;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Indicates whether or not the patient prefers this language (over other
   * languages he masters up a certain level).
   */
  preferred?: Maybe<Scalars['code']>;
};

/**
 * Patient.Contact
 *     Demographics and other administrative information about an individual or
 *     animal receiving care or other health-related services.
 */
export type PatientContact = {
  __typename?: 'PatientContact';
  /** Address for the contact person. */
  address?: Maybe<Address>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Administrative Gender - the gender that the contact person is considered to
   * have for administration and record keeping purposes.
   */
  gender?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A name associated with the contact person. */
  name?: Maybe<HumanName>;
  /**
   * Organization on behalf of which the contact is acting or for which the contact
   * is working.
   */
  organization?: Maybe<Organization>;
  /**
   * The period during which this contact person or organization is valid to be
   * contacted relating to this patient.
   */
  period?: Maybe<Period>;
  /** The nature of the relationship between the patient and the contact person. */
  relationship?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** A contact detail for the person, e.g. a telephone number or an email address. */
  telecom?: Maybe<Array<Maybe<ContactPoint>>>;
};

export type PatientGeneralPractitioner = Organization | Practitioner | PractitionerRole;

/**
 * Patient.Link
 *     Demographics and other administrative information about an individual or
 *     animal receiving care or other health-related services.
 */
export type PatientLink = {
  __typename?: 'PatientLink';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The other patient resource that the link refers to. */
  other: PatientLinkOther;
  /** The type of link between this patient resource and another patient resource. */
  type: Scalars['code'];
};

export type PatientLinkOther = Patient | RelatedPerson;

/**
 * PaymentNotice
 *     This resource provides the status of the payment for goods and services
 *     rendered, and the request and response resource references.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type PaymentNotice = {
  __typename?: 'PaymentNotice';
  /** The amount sent to the payee. */
  amount: Money;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The date when this resource was created. */
  created: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** A unique identifier assigned to this payment notice. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The party who will receive or has received payment that is the subject of this
   * notification.
   */
  payee?: Maybe<PaymentNoticePayee>;
  /** A reference to the payment which is the subject of this notice. */
  payment: PaymentReconciliation;
  /** The date when the above payment action occurred. */
  paymentDate?: Maybe<Scalars['code']>;
  /** A code indicating whether payment has been sent or cleared. */
  paymentStatus?: Maybe<CodeableConcept>;
  /** The practitioner who is responsible for the services rendered to the patient. */
  provider?: Maybe<PaymentNoticeProvider>;
  /** The party who is notified of the payment status. */
  recipient: Organization;
  /** Reference of resource for which payment is being made. */
  request?: Maybe<Resource>;
  resourceType?: Maybe<Scalars['String']>;
  /** Reference of response to resource for which payment is being made. */
  response?: Maybe<Resource>;
  /** The status of the resource instance. */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type PaymentNoticePayee = Organization | Practitioner | PractitionerRole;

export type PaymentNoticeProvider = Organization | Practitioner | PractitionerRole;

/**
 * PaymentReconciliation
 *     This resource provides the details including amount of a payment and allocates
 *     the payment items being paid.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type PaymentReconciliation = {
  __typename?: 'PaymentReconciliation';
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The date when the resource was created. */
  created: Scalars['code'];
  /** Distribution of the payment amount for a previously acknowledged payable. */
  detail?: Maybe<Array<Maybe<PaymentReconciliationDetail>>>;
  /**
   * A human readable description of the status of the request for the
   * reconciliation.
   */
  disposition?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** A code for the form to be used for printing the content. */
  formCode?: Maybe<CodeableConcept>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** A unique identifier assigned to this payment reconciliation. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The outcome of a request for a reconciliation. */
  outcome?: Maybe<Scalars['code']>;
  /** Total payment amount as indicated on the financial instrument. */
  paymentAmount: Money;
  /** The date of payment as indicated on the financial instrument. */
  paymentDate: Scalars['code'];
  /** Issuer's unique identifier for the payment instrument. */
  paymentIdentifier?: Maybe<Identifier>;
  /** The party who generated the payment. */
  paymentIssuer?: Maybe<Organization>;
  /**
   * The period of time for which payments have been gathered into this bulk
   * payment for settlement.
   */
  period?: Maybe<Period>;
  /** A note that describes or explains the processing in a human readable form. */
  processNote?: Maybe<Array<Maybe<PaymentReconciliationProcessNote>>>;
  /** Original request resource reference. */
  request?: Maybe<Task>;
  /** The practitioner who is responsible for the services rendered to the patient. */
  requestor?: Maybe<PaymentReconciliationRequestor>;
  resourceType?: Maybe<Scalars['String']>;
  /** The status of the resource instance. */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * PaymentReconciliation.Detail
 *     This resource provides the details including amount of a payment and allocates
 *     the payment items being paid.
 */
export type PaymentReconciliationDetail = {
  __typename?: 'PaymentReconciliationDetail';
  /** The monetary amount allocated from the total payment to the payable. */
  amount?: Maybe<Money>;
  /** The date from the response resource containing a commitment to pay. */
  date?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Unique identifier for the current payment item for the referenced payable. */
  identifier?: Maybe<Identifier>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The party which is receiving the payment. */
  payee?: Maybe<PaymentReconciliationDetailPayee>;
  /** Unique identifier for the prior payment item for the referenced payable. */
  predecessor?: Maybe<Identifier>;
  /** A resource, such as a Claim, the evaluation of which could lead to payment. */
  request?: Maybe<Resource>;
  /** A resource, such as a ClaimResponse, which contains a commitment to payment. */
  response?: Maybe<Resource>;
  /**
   * A reference to the individual who is responsible for inquiries regarding the
   * response and its payment.
   */
  responsible?: Maybe<PractitionerRole>;
  /** The party which submitted the claim or financial transaction. */
  submitter?: Maybe<PaymentReconciliationDetailSubmitter>;
  /** Code to indicate the nature of the payment. */
  type: CodeableConcept;
};

export type PaymentReconciliationDetailPayee = Organization | Practitioner | PractitionerRole;

export type PaymentReconciliationDetailSubmitter = Organization | Practitioner | PractitionerRole;

/**
 * PaymentReconciliation.ProcessNote
 *     This resource provides the details including amount of a payment and allocates
 *     the payment items being paid.
 */
export type PaymentReconciliationProcessNote = {
  __typename?: 'PaymentReconciliationProcessNote';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The explanation or description associated with the processing. */
  text?: Maybe<Scalars['code']>;
  /** The business purpose of the note text. */
  type?: Maybe<Scalars['code']>;
};

export type PaymentReconciliationRequestor = Organization | Practitioner | PractitionerRole;

/**
 * Period
 *     A time period defined by a start and end date and optionally time.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Period = {
  __typename?: 'Period';
  /**
   * The end of the period. If the end of the period is missing, it means no end
   * was known or planned at the time the instance was created. The start may be in
   * the past, and the end date in the future, which means that period is
   * expected/planned to end at that time.
   */
  end?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The start of the period. The boundary is inclusive. */
  start?: Maybe<Scalars['code']>;
};

/**
 * Person
 *     Demographics and administrative information about a person independent of a
 *     specific health-related context.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Person = {
  __typename?: 'Person';
  /** Whether this person's record is in active use. */
  active?: Maybe<Scalars['code']>;
  /** One or more addresses for the person. */
  address?: Maybe<Array<Maybe<Address>>>;
  /** The birth date for the person. */
  birthDate?: Maybe<Scalars['code']>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  email?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  flatName?: Maybe<Scalars['String']>;
  /** Administrative Gender. */
  gender?: Maybe<Scalars['code']>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Identifier for a person within a particular scope. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** Link to a resource that concerns the same actual person. */
  link?: Maybe<Array<Maybe<PersonLink>>>;
  /** The organization that is the custodian of the person record. */
  managingOrganization?: Maybe<Organization>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A name associated with the person. */
  name?: Maybe<Array<Maybe<HumanName>>>;
  phone?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * An image that can be displayed as a thumbnail of the person to enhance the
   * identification of the individual.
   */
  photo?: Maybe<Attachment>;
  resourceType?: Maybe<Scalars['String']>;
  /** A contact detail for the person, e.g. a telephone number or an email address. */
  telecom?: Maybe<Array<Maybe<ContactPoint>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * Person.Link
 *     Demographics and administrative information about a person independent of a
 *     specific health-related context.
 */
export type PersonLink = {
  __typename?: 'PersonLink';
  /** Level of assurance that this link is associated with the target resource. */
  assurance?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The resource to which this actual person is associated. */
  target: PersonLinkTarget;
};

export type PersonLinkTarget = Patient | Person | Practitioner | RelatedPerson;

/**
 * PlanDefinition
 *     This resource allows for the definition of various types of plans as a
 *     sharable, consumable, and executable artifact. The resource is general enough
 *     to support the description of a broad range of clinical artifacts such as
 *     clinical decision support rules, order sets and protocols.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type PlanDefinition = {
  __typename?: 'PlanDefinition';
  /** An action or group of actions to be taken as part of the plan. */
  action?: Maybe<Array<Maybe<PlanDefinitionAction>>>;
  /**
   * The date on which the resource content was approved by the publisher. Approval
   * happens once when the content is officially approved for usage.
   */
  approvalDate?: Maybe<Scalars['code']>;
  /**
   * An individiual or organization primarily involved in the creation and
   * maintenance of the content.
   */
  author?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the plan definition and/or its contents.
   * Copyright statements are generally legal restrictions on the use and
   * publishing of the plan definition.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the plan definition was published. The
   * date must change when the business version changes and it must change if the
   * status code changes. In addition, it should change when the substantive
   * content of the plan definition changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the plan definition from a
   * consumer's perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * An individual or organization primarily responsible for internal coherence of
   * the content.
   */
  editor?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * The period during which the plan definition content was or is planned to be in
   * active use.
   */
  effectivePeriod?: Maybe<Period>;
  /**
   * An individual or organization responsible for officially endorsing the content
   * for use in some setting.
   */
  endorser?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * A Boolean value to indicate that this plan definition is authored for testing
   * purposes (or education/evaluation/marketing) and is not intended to be used
   * for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Goals that describe what the activities within the plan are intended to
   * achieve. For example, weight loss, restoring an activity of daily living,
   * obtaining herd immunity via immunization, meeting a process improvement
   * objective, etc.
   */
  goal?: Maybe<Array<Maybe<PlanDefinitionGoal>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this plan definition when it is
   * represented in other formats, or referenced in a specification, model, design
   * or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A legal or geographic region in which the plan definition is intended to be
   * used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The date on which the resource content was last reviewed. Review happens
   * periodically after approval but does not change the original approval date.
   */
  lastReviewDate?: Maybe<Scalars['code']>;
  /**
   * A reference to a Library resource containing any formal logic used by the plan
   * definition.
   */
  library?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the plan definition. This name should be
   * usable as an identifier for the module by machine processing applications such
   * as code generation.
   */
  name?: Maybe<Scalars['code']>;
  /** The name of the organization or individual that published the plan definition. */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this plan definition is needed and why it has been designed
   * as it has.
   */
  purpose?: Maybe<Scalars['code']>;
  /**
   * Related artifacts such as additional documentation, justification, or
   * bibliographic references.
   */
  relatedArtifact?: Maybe<Array<Maybe<RelatedArtifact>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * An individual or organization primarily responsible for review of some aspect
   * of the content.
   */
  reviewer?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * The status of this plan definition. Enables tracking the life-cycle of the
   * content.
   */
  status: Scalars['code'];
  /** None */
  subjectCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  subjectReference?: Maybe<Group>;
  /**
   * An explanatory or alternate title for the plan definition giving additional
   * information about its content.
   */
  subtitle?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the plan definition. */
  title?: Maybe<Scalars['code']>;
  /**
   * Descriptive topics related to the content of the plan definition. Topics
   * provide a high-level categorization of the definition that can be useful for
   * filtering and searching.
   */
  topic?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * A high-level category for the plan definition that distinguishes the kinds of
   * systems that would be interested in the plan definition.
   */
  type?: Maybe<CodeableConcept>;
  /**
   * An absolute URI that is used to identify this plan definition when it is
   * referenced in a specification, model, design or an instance; also called its
   * canonical identifier. This SHOULD be globally unique and SHOULD be a literal
   * address at which at which an authoritative instance of this plan definition is
   * (or will be) published. This URL can be the target of a canonical reference.
   * It SHALL remain the same when the plan definition is stored on different
   * servers.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * A detailed description of how the plan definition is used from a clinical
   * perspective.
   */
  usage?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate plan
   * definition instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the plan definition
   * when it is referenced in a specification, model, design or instance. This is
   * an arbitrary value managed by the plan definition author and is not expected
   * to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if
   * a managed version is not available. There is also no expectation that versions
   * can be placed in a lexicographical sequence. To provide a version consistent
   * with the Decision Support Service specification, use the format
   * Major.Minor.Revision (e.g. 1.0.0). For more information on versioning
   * knowledge assets, refer to the Decision Support Service specification. Note
   * that a version is required for non-experimental active artifacts.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * PlanDefinition.Action
 *     This resource allows for the definition of various types of plans as a
 *     sharable, consumable, and executable artifact. The resource is general enough
 *     to support the description of a broad range of clinical artifacts such as
 *     clinical decision support rules, order sets and protocols.
 */
export type PlanDefinitionAction = {
  __typename?: 'PlanDefinitionAction';
  /**
   * Sub actions that are contained within the action. The behavior of this action
   * determines the functionality of the sub-actions. For example, a selection
   * behavior of at-most-one indicates that of the sub-actions, at most one may be
   * chosen as part of realizing the action definition.
   */
  action?: Maybe<Array<Maybe<PlanDefinitionAction>>>;
  /** Defines whether the action can be selected multiple times. */
  cardinalityBehavior?: Maybe<Scalars['code']>;
  /**
   * A code that provides meaning for the action or action group. For example, a
   * section may have a LOINC code for the section of a documentation template.
   */
  code?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * An expression that describes applicability criteria or start/stop conditions
   * for the action.
   */
  condition?: Maybe<Array<Maybe<PlanDefinitionCondition>>>;
  /** None */
  definitionCanonical?: Maybe<Scalars['code']>;
  /** None */
  definitionUri?: Maybe<Scalars['code']>;
  /**
   * A brief description of the action used to provide a summary to display to the
   * user.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * Didactic or other informational resources associated with the action that can
   * be provided to the CDS recipient. Information resources can include inline
   * text commentary and links to web resources.
   */
  documentation?: Maybe<Array<Maybe<RelatedArtifact>>>;
  /**
   * Customizations that should be applied to the statically defined resource. For
   * example, if the dosage of a medication must be computed based on the patient's
   * weight, a customization would be used to specify an expression that calculated
   * the weight, and the path on the resource that would contain the result.
   */
  dynamicValue?: Maybe<Array<Maybe<PlanDefinitionDynamicValue>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Identifies goals that this action supports. The reference must be to a goal
   * element defined within this plan definition.
   */
  goalId?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Defines the grouping behavior for the action and its children. */
  groupingBehavior?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Defines input data requirements for the action. */
  input?: Maybe<Array<Maybe<DataRequirement>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Defines the outputs of the action, if any. */
  output?: Maybe<Array<Maybe<DataRequirement>>>;
  /** Indicates who should participate in performing the action described. */
  participant?: Maybe<Array<Maybe<PlanDefinitionParticipant>>>;
  /** Defines whether the action should usually be preselected. */
  precheckBehavior?: Maybe<Scalars['code']>;
  /** A user-visible prefix for the action. */
  prefix?: Maybe<Scalars['code']>;
  /**
   * Indicates how quickly the action should be addressed with respect to other
   * actions.
   */
  priority?: Maybe<Scalars['code']>;
  /** A description of why this action is necessary or appropriate. */
  reason?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * A relationship to another action such as "before" or "30-60 minutes after
   * start of".
   */
  relatedAction?: Maybe<Array<Maybe<PlanDefinitionRelatedAction>>>;
  /** Defines the required behavior for the action. */
  requiredBehavior?: Maybe<Scalars['code']>;
  /** Defines the selection behavior for the action and its children. */
  selectionBehavior?: Maybe<Scalars['code']>;
  /** None */
  subjectCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  subjectReference?: Maybe<Group>;
  /**
   * A text equivalent of the action to be performed. This provides a human-
   * interpretable description of the action when the definition is consumed by a
   * system that might not be capable of interpreting it dynamically.
   */
  textEquivalent?: Maybe<Scalars['code']>;
  /** None */
  timingAge?: Maybe<Quantity>;
  /** None */
  timingDateTime?: Maybe<Scalars['code']>;
  /** None */
  timingDuration?: Maybe<Quantity>;
  /** None */
  timingPeriod?: Maybe<Period>;
  /** None */
  timingRange?: Maybe<Range>;
  /** None */
  timingTiming?: Maybe<Timing>;
  /** The title of the action displayed to a user. */
  title?: Maybe<Scalars['code']>;
  /**
   * A reference to a StructureMap resource that defines a transform that can be
   * executed to produce the intent resource using the ActivityDefinition instance
   * as the input.
   */
  transform?: Maybe<Scalars['code']>;
  /** A description of when the action should be triggered. */
  trigger?: Maybe<Array<Maybe<TriggerDefinition>>>;
  /** The type of action to perform (create, update, remove). */
  type?: Maybe<CodeableConcept>;
};

/**
 * PlanDefinition.Condition
 *     This resource allows for the definition of various types of plans as a
 *     sharable, consumable, and executable artifact. The resource is general enough
 *     to support the description of a broad range of clinical artifacts such as
 *     clinical decision support rules, order sets and protocols.
 */
export type PlanDefinitionCondition = {
  __typename?: 'PlanDefinitionCondition';
  /**
   * An expression that returns true or false, indicating whether the condition is
   * satisfied.
   */
  expression?: Maybe<Expression>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The kind of condition. */
  kind: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * PlanDefinition.DynamicValue
 *     This resource allows for the definition of various types of plans as a
 *     sharable, consumable, and executable artifact. The resource is general enough
 *     to support the description of a broad range of clinical artifacts such as
 *     clinical decision support rules, order sets and protocols.
 */
export type PlanDefinitionDynamicValue = {
  __typename?: 'PlanDefinitionDynamicValue';
  /** An expression specifying the value of the customized element. */
  expression?: Maybe<Expression>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The path to the element to be customized. This is the path on the resource
   * that will hold the result of the calculation defined by the expression. The
   * specified path SHALL be a FHIRPath resolveable on the specified target type of
   * the ActivityDefinition, and SHALL consist only of identifiers, constant
   * indexers, and a restricted subset of functions. The path is allowed to contain
   * qualifiers (.) to traverse sub-elements, as well as indexers ([x]) to traverse
   * multiple-cardinality sub-elements (see the [Simple FHIRPath
   * Profile](fhirpath.html#simple) for full details).
   */
  path?: Maybe<Scalars['code']>;
};

/**
 * PlanDefinition.Goal
 *     This resource allows for the definition of various types of plans as a
 *     sharable, consumable, and executable artifact. The resource is general enough
 *     to support the description of a broad range of clinical artifacts such as
 *     clinical decision support rules, order sets and protocols.
 */
export type PlanDefinitionGoal = {
  __typename?: 'PlanDefinitionGoal';
  /**
   * Identifies problems, conditions, issues, or concerns the goal is intended to
   * address.
   */
  addresses?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Indicates a category the goal falls within. */
  category?: Maybe<CodeableConcept>;
  /**
   * Human-readable and/or coded description of a specific desired objective of
   * care, such as "control blood pressure" or "negotiate an obstacle course" or
   * "dance with child at wedding".
   */
  description: CodeableConcept;
  /**
   * Didactic or other informational resources associated with the goal that
   * provide further supporting information about the goal. Information resources
   * can include inline text commentary and links to web resources.
   */
  documentation?: Maybe<Array<Maybe<RelatedArtifact>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Identifies the expected level of importance associated with
   * reaching/sustaining the defined goal.
   */
  priority?: Maybe<CodeableConcept>;
  /** The event after which the goal should begin being pursued. */
  start?: Maybe<CodeableConcept>;
  /** Indicates what should be done and within what timeframe. */
  target?: Maybe<Array<Maybe<PlanDefinitionTarget>>>;
};

/**
 * PlanDefinition.Participant
 *     This resource allows for the definition of various types of plans as a
 *     sharable, consumable, and executable artifact. The resource is general enough
 *     to support the description of a broad range of clinical artifacts such as
 *     clinical decision support rules, order sets and protocols.
 */
export type PlanDefinitionParticipant = {
  __typename?: 'PlanDefinitionParticipant';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The role the participant should play in performing the described action. */
  role?: Maybe<CodeableConcept>;
  /** The type of participant in the action. */
  type: Scalars['code'];
};

/**
 * PlanDefinition.RelatedAction
 *     This resource allows for the definition of various types of plans as a
 *     sharable, consumable, and executable artifact. The resource is general enough
 *     to support the description of a broad range of clinical artifacts such as
 *     clinical decision support rules, order sets and protocols.
 */
export type PlanDefinitionRelatedAction = {
  __typename?: 'PlanDefinitionRelatedAction';
  /** The element id of the related action. */
  actionId: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  offsetDuration?: Maybe<Quantity>;
  /** None */
  offsetRange?: Maybe<Range>;
  /** The relationship of this action to the related action. */
  relationship: Scalars['code'];
};

/**
 * PlanDefinition.Target
 *     This resource allows for the definition of various types of plans as a
 *     sharable, consumable, and executable artifact. The resource is general enough
 *     to support the description of a broad range of clinical artifacts such as
 *     clinical decision support rules, order sets and protocols.
 */
export type PlanDefinitionTarget = {
  __typename?: 'PlanDefinitionTarget';
  /** None */
  detailCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  detailQuantity?: Maybe<Quantity>;
  /** None */
  detailRange?: Maybe<Range>;
  /**
   * Indicates the timeframe after the start of the goal in which the goal should
   * be met.
   */
  due?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The parameter whose value is to be tracked, e.g. body weight, blood pressure,
   * or hemoglobin A1c level.
   */
  measure?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * Population
 *     A populatioof people with some set of grouping criteria.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Population = {
  __typename?: 'Population';
  /** None */
  ageCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  ageRange?: Maybe<Range>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** The gender of the specific population. */
  gender?: Maybe<CodeableConcept>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The existing physiological conditions of the specific population to which this
   * applies.
   */
  physiologicalCondition?: Maybe<CodeableConcept>;
  /** Race of the specific population. */
  race?: Maybe<CodeableConcept>;
};

/**
 * Practitioner
 *     A person who is directly or indirectly involved in the provisioning of
 *     healthcare.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Practitioner = {
  __typename?: 'Practitioner';
  /** Whether this practitioner's record is in active use. */
  active?: Maybe<Scalars['code']>;
  /**
   * Address(es) of the practitioner that are not role specific (typically home
   * address).
   * Work addresses are not typically entered in this property as they are usually
   * role dependent.
   */
  address?: Maybe<Array<Maybe<Address>>>;
  appointment?: Maybe<Array<Maybe<Appointment>>>;
  /** The date of birth for the practitioner. */
  birthDate?: Maybe<Scalars['code']>;
  /** A language the practitioner can use in patient communication. */
  communication?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  email?: Maybe<Array<Maybe<Scalars['String']>>>;
  expiration?: Maybe<Scalars['String']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  flatName?: Maybe<Scalars['String']>;
  /**
   * Administrative Gender - the gender that the person is considered to have for
   * administration and record keeping purposes.
   */
  gender?: Maybe<Scalars['code']>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** An identifier that applies to this person in this role. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  memberId?: Maybe<Scalars['String']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The name(s) associated with the practitioner. */
  name?: Maybe<Array<Maybe<HumanName>>>;
  networkStatus?: Maybe<Scalars['String']>;
  npi?: Maybe<Scalars['String']>;
  patient?: Maybe<Array<Maybe<Patient>>>;
  phone?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Image of the person. */
  photo?: Maybe<Array<Maybe<Attachment>>>;
  /**
   * The official certifications, training, and licenses that authorize or
   * otherwise pertain to the provision of care by the practitioner.  For example,
   * a medical license issued by a medical board authorizing the practitioner to
   * practice medicine within a certian locality.
   */
  qualification?: Maybe<Array<Maybe<PractitionerQualification>>>;
  referral?: Maybe<Array<Maybe<ServiceRequest>>>;
  resourceType?: Maybe<Scalars['String']>;
  role?: Maybe<Array<Maybe<PractitionerRole>>>;
  /**
   * A contact detail for the practitioner, e.g. a telephone number or an email
   * address.
   */
  telecom?: Maybe<Array<Maybe<ContactPoint>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  validationFlag?: Maybe<Scalars['Boolean']>;
};

/**
 * Practitioner.Qualification
 *     A person who is directly or indirectly involved in the provisioning of
 *     healthcare.
 */
export type PractitionerQualification = {
  __typename?: 'PractitionerQualification';
  /** Coded representation of the qualification. */
  code: CodeableConcept;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** An identifier that applies to this person's qualification in this role. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /** Organization that regulates and issues the qualification. */
  issuer?: Maybe<Organization>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Period during which the qualification is valid. */
  period?: Maybe<Period>;
};

/**
 * PractitionerRole
 *     A specific set of Roles/Locations/specialties/services that a practitioner may
 *     perform at an organization for a period of time.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type PractitionerRole = {
  __typename?: 'PractitionerRole';
  /** Whether this practitioner role record is in active use. */
  active?: Maybe<Scalars['code']>;
  /**
   * A description of site availability exceptions, e.g. public holiday
   * availability. Succinctly describing all possible exceptions to normal site
   * availability as details in the available Times and not available Times.
   */
  availabilityExceptions?: Maybe<Scalars['code']>;
  /**
   * A collection of times the practitioner is available or performing this role at
   * the location and/or healthcareservice.
   */
  availableTime?: Maybe<Array<Maybe<PractitionerRoleAvailableTime>>>;
  /** Roles which this practitioner is authorized to perform for the organization. */
  code?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  email?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Technical endpoints providing access to services operated for the practitioner
   * with this role.
   */
  endpoint?: Maybe<Array<Maybe<Endpoint>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The list of healthcare services that this worker provides for this role's
   * Organization/Location(s).
   */
  healthcareService?: Maybe<Array<Maybe<HealthcareService>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Business Identifiers that are specific to a role/location. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** The location(s) at which this practitioner provides care. */
  location?: Maybe<Array<Maybe<Location>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The practitioner is not available or performing this role during this period
   * of time due to the provided reason.
   */
  notAvailable?: Maybe<Array<Maybe<PractitionerRoleNotAvailable>>>;
  /** The organization where the Practitioner performs the roles associated. */
  organization?: Maybe<Organization>;
  /**
   * The period during which the person is authorized to act as a practitioner in
   * these role(s) for the organization.
   */
  period?: Maybe<Period>;
  phone?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Practitioner that is able to provide the defined services for the
   * organization.
   */
  practitioner?: Maybe<Practitioner>;
  resourceType?: Maybe<Scalars['String']>;
  /** Specific specialty of the practitioner. */
  specialty?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Contact details that are specific to the role/location/service. */
  telecom?: Maybe<Array<Maybe<ContactPoint>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * PractitionerRole.AvailableTime
 *     A specific set of Roles/Locations/specialties/services that a practitioner may
 *     perform at an organization for a period of time.
 */
export type PractitionerRoleAvailableTime = {
  __typename?: 'PractitionerRoleAvailableTime';
  /** Is this always available? (hence times are irrelevant) e.g. 24 hour service. */
  allDay?: Maybe<Scalars['code']>;
  /**
   * The closing time of day. Note: If the AllDay flag is set, then this time is
   * ignored.
   */
  availableEndTime?: Maybe<Scalars['code']>;
  /**
   * The opening time of day. Note: If the AllDay flag is set, then this time is
   * ignored.
   */
  availableStartTime?: Maybe<Scalars['code']>;
  /**
   * Indicates which days of the week are available between the start and end
   * Times.
   */
  daysOfWeek?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * PractitionerRole.NotAvailable
 *     A specific set of Roles/Locations/specialties/services that a practitioner may
 *     perform at an organization for a period of time.
 */
export type PractitionerRoleNotAvailable = {
  __typename?: 'PractitionerRoleNotAvailable';
  /**
   * The reason that can be presented to the user as to why this time is not
   * available.
   */
  description: Scalars['code'];
  /** Service is not available (seasonally or for a public holiday) from this date. */
  during?: Maybe<Period>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * Procedure
 *     An action that is or was performed on or for a patient. This can be a physical
 *     intervention like an operation, or less invasive like long term services,
 *     counseling, or hypnotherapy.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Procedure = {
  __typename?: 'Procedure';
  /** Individual who is making the procedure statement. */
  asserter?: Maybe<ProcedureAsserter>;
  /**
   * A reference to a resource that contains details of the request for this
   * procedure.
   */
  basedOn?: Maybe<Array<Maybe<ProcedureBasedOn>>>;
  /**
   * Detailed and structured anatomical location information. Multiple locations
   * are allowed - e.g. multiple punch biopsies of a lesion.
   */
  bodySite?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * A code that classifies the procedure for searching, sorting and display
   * purposes (e.g. "Surgical Procedure").
   */
  category?: Maybe<CodeableConcept>;
  /**
   * The specific procedure that is performed. Use text if the exact nature of the
   * procedure cannot be coded (e.g. "Laparoscopic Appendectomy").
   */
  code?: Maybe<CodeableConcept>;
  /**
   * Any complications that occurred during the procedure, or in the immediate
   * post-performance period. These are generally tracked separately from the
   * notes, which will typically describe the procedure itself rather than any
   * 'post procedure' issues.
   */
  complication?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Any complications that occurred during the procedure, or in the immediate
   * post-performance period.
   */
  complicationDetail?: Maybe<Array<Maybe<Condition>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The Encounter during which this Procedure was created or performed or to which
   * the creation of this record is tightly associated.
   */
  encounter?: Maybe<Encounter>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A device that is implanted, removed or otherwise manipulated (calibration,
   * battery replacement, fitting a prosthesis, attaching a wound-vac, etc.) as a
   * focal portion of the Procedure.
   */
  focalDevice?: Maybe<Array<Maybe<ProcedureFocalDevice>>>;
  /**
   * If the procedure required specific follow up - e.g. removal of sutures. The
   * follow up may be represented as a simple note or could potentially be more
   * complex, in which case the CarePlan resource can be used.
   */
  followUp?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Business identifiers assigned to this procedure by the performer or other
   * systems which remain constant as the resource is updated and is propagated
   * from server to server.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * The URL pointing to a FHIR-defined protocol, guideline, order set or other
   * definition that is adhered to in whole or in part by this Procedure.
   */
  instantiatesCanonical?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The URL pointing to an externally maintained protocol, guideline, order set or
   * other definition that is adhered to in whole or in part by this Procedure.
   */
  instantiatesUri?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The location where the procedure actually happened.  E.g. a newborn at home, a
   * tracheostomy at a restaurant.
   */
  location?: Maybe<Location>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Any other notes and comments about the procedure. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /**
   * The outcome of the procedure - did it resolve the reasons for the procedure
   * being performed?
   */
  outcome?: Maybe<CodeableConcept>;
  /** A larger event of which this particular procedure is a component or step. */
  partOf?: Maybe<Array<Maybe<ProcedurePartOf>>>;
  /** None */
  performedAge?: Maybe<Quantity>;
  /** None */
  performedDateTime?: Maybe<Scalars['code']>;
  /** None */
  performedPeriod?: Maybe<Period>;
  /** None */
  performedRange?: Maybe<Range>;
  /** None */
  performedString?: Maybe<Scalars['code']>;
  /** Limited to "real" people rather than equipment. */
  performer?: Maybe<Array<Maybe<ProcedurePerformer>>>;
  /**
   * The coded reason why the procedure was performed. This may be a coded entity
   * of some type, or may simply be present as text.
   */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The justification of why the procedure was performed. */
  reasonReference?: Maybe<Array<Maybe<ProcedureReasonReference>>>;
  /** Individual who recorded the record and takes responsibility for its content. */
  recorder?: Maybe<ProcedureRecorder>;
  /** This could be a histology result, pathology report, surgical report, etc. */
  report?: Maybe<Array<Maybe<ProcedureReport>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A code specifying the state of the procedure. Generally, this will be the in-
   * progress or completed state.
   */
  status: Scalars['code'];
  /** Captures the reason for the current state of the procedure. */
  statusReason?: Maybe<CodeableConcept>;
  /** The person, animal or group on which the procedure was performed. */
  subject: ProcedureSubject;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** Identifies coded items that were used as part of the procedure. */
  usedCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Identifies medications, devices and any other substance used as part of the
   * procedure.
   */
  usedReference?: Maybe<Array<Maybe<ProcedureUsedReference>>>;
};

export type ProcedureAsserter = Patient | Practitioner | PractitionerRole | RelatedPerson;

export type ProcedureBasedOn = CarePlan | ServiceRequest;

/**
 * Procedure.FocalDevice
 *     An action that is or was performed on or for a patient. This can be a physical
 *     intervention like an operation, or less invasive like long term services,
 *     counseling, or hypnotherapy.
 */
export type ProcedureFocalDevice = {
  __typename?: 'ProcedureFocalDevice';
  /** The kind of change that happened to the device during the procedure. */
  action?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The device that was manipulated (changed) during the procedure. */
  manipulated: Device;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type ProcedurePartOf = MedicationAdministration | Observation | Procedure;

/**
 * Procedure.Performer
 *     An action that is or was performed on or for a patient. This can be a physical
 *     intervention like an operation, or less invasive like long term services,
 *     counseling, or hypnotherapy.
 */
export type ProcedurePerformer = {
  __typename?: 'ProcedurePerformer';
  /** The practitioner who was involved in the procedure. */
  actor: ProcedurePerformerActor;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Distinguishes the type of involvement of the performer in the procedure. For
   * example, surgeon, anaesthetist, endoscopist.
   */
  function?: Maybe<CodeableConcept>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The organization the device or practitioner was acting on behalf of. */
  onBehalfOf?: Maybe<Organization>;
};

export type ProcedurePerformerActor = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type ProcedureReasonReference = Condition | DiagnosticReport | DocumentReference | Observation | Procedure;

export type ProcedureRecorder = Patient | Practitioner | PractitionerRole | RelatedPerson;

export type ProcedureReport = Composition | DiagnosticReport | DocumentReference;

export type ProcedureSubject = Group | Patient;

export type ProcedureUsedReference = Device | Medication | Substance;

/**
 * ProdCharacteristic
 *     The marketing status describes the date when a medicinal product is actually
 *     put on the market or the date as of which it is no longer available.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type ProdCharacteristic = {
  __typename?: 'ProdCharacteristic';
  /**
   * Where applicable, the color can be specified An appropriate controlled
   * vocabulary shall be used The term and the term identifier shall be used.
   */
  color?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Where applicable, the depth can be specified using a numerical value and its
   * unit of measurement The unit of measurement shall be specified in accordance
   * with ISO 11240 and the resulting terminology The symbol and the symbol
   * identifier shall be used.
   */
  depth?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Where applicable, the external diameter can be specified using a numerical
   * value and its unit of measurement The unit of measurement shall be specified
   * in accordance with ISO 11240 and the resulting terminology The symbol and the
   * symbol identifier shall be used.
   */
  externalDiameter?: Maybe<Quantity>;
  /**
   * Where applicable, the height can be specified using a numerical value and its
   * unit of measurement The unit of measurement shall be specified in accordance
   * with ISO 11240 and the resulting terminology The symbol and the symbol
   * identifier shall be used.
   */
  height?: Maybe<Quantity>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Where applicable, the image can be provided The format of the image attachment
   * shall be specified by regional implementations.
   */
  image?: Maybe<Array<Maybe<Attachment>>>;
  /** Where applicable, the imprint can be specified as text. */
  imprint?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Where applicable, the nominal volume can be specified using a numerical value
   * and its unit of measurement The unit of measurement shall be specified in
   * accordance with ISO 11240 and the resulting terminology The symbol and the
   * symbol identifier shall be used.
   */
  nominalVolume?: Maybe<Quantity>;
  /**
   * Where applicable, the scoring can be specified An appropriate controlled
   * vocabulary shall be used The term and the term identifier shall be used.
   */
  scoring?: Maybe<CodeableConcept>;
  /**
   * Where applicable, the shape can be specified An appropriate controlled
   * vocabulary shall be used The term and the term identifier shall be used.
   */
  shape?: Maybe<Scalars['code']>;
  /**
   * Where applicable, the weight can be specified using a numerical value and its
   * unit of measurement The unit of measurement shall be specified in accordance
   * with ISO 11240 and the resulting terminology The symbol and the symbol
   * identifier shall be used.
   */
  weight?: Maybe<Quantity>;
  /**
   * Where applicable, the width can be specified using a numerical value and its
   * unit of measurement The unit of measurement shall be specified in accordance
   * with ISO 11240 and the resulting terminology The symbol and the symbol
   * identifier shall be used.
   */
  width?: Maybe<Quantity>;
};

/**
 * ProductShelfLife
 *     The shelf-life and storage information for a medicinal product item or
 *     container can be described using this class.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type ProductShelfLife = {
  __typename?: 'ProductShelfLife';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Unique identifier for the packaged Medicinal Product. */
  identifier?: Maybe<Identifier>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The shelf life time period can be specified using a numerical value for the
   * period of time and its unit of time measurement The unit of measurement shall
   * be specified in accordance with ISO 11240 and the resulting terminology The
   * symbol and the symbol identifier shall be used.
   */
  period: Quantity;
  /**
   * Special precautions for storage, if any, can be specified using an appropriate
   * controlled vocabulary The controlled term and the controlled term identifier
   * shall be specified.
   */
  specialPrecautionsForStorage?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * This describes the shelf life, taking into account various scenarios such as
   * shelf life of the packaged Medicinal Product itself, shelf life after
   * transformation where necessary and shelf life after the first opening of a
   * bottle, etc. The shelf life type shall be specified using an appropriate
   * controlled vocabulary The controlled term and the controlled term identifier
   * shall be specified.
   */
  type: CodeableConcept;
};

/**
 * Provenance
 *     Provenance of a resource is a record that describes entities and processes
 *     involved in producing and delivering or otherwise influencing that resource.
 *     Provenance provides a critical foundation for assessing authenticity, enabling
 *     trust, and allowing reproducibility. Provenance assertions are a form of
 *     contextual metadata and can themselves become important records with their own
 *     provenance. Provenance statement indicates clinical significance in terms of
 *     confidence in authenticity, reliability, and trustworthiness, integrity, and
 *     stage in lifecycle (e.g. Document Completion - has the artifact been legally
 *     authenticated), all of which may impact security, privacy, and trust policies.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Provenance = {
  __typename?: 'Provenance';
  /**
   * An activity is something that occurs over a period of time and acts upon or
   * with entities; it may include consuming, processing, transforming, modifying,
   * relocating, using, or generating entities.
   */
  activity?: Maybe<CodeableConcept>;
  /**
   * An actor taking a role in an activity  for which it can be assigned some
   * degree of responsibility for the activity taking place.
   */
  agent: Array<Maybe<ProvenanceAgent>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** An entity used in this activity. */
  entity?: Maybe<Array<Maybe<ProvenanceEntity>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** Where the activity occurred, if relevant. */
  location?: Maybe<Location>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  occurredDateTime?: Maybe<Scalars['code']>;
  /** None */
  occurredPeriod?: Maybe<Period>;
  /**
   * Policy or plan the activity was defined by. Typically, a single activity may
   * have multiple applicable policy documents, such as patient consent, guarantor
   * funding, etc.
   */
  policy?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The reason that the activity was taking place. */
  reason?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The instant of time at which the activity was recorded. */
  recorded: Scalars['code'];
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A digital signature on the target Reference(s). The signer should match a
   * Provenance.agent. The purpose of the signature is indicated.
   */
  signature?: Maybe<Array<Maybe<Signature>>>;
  /**
   * The Reference(s) that were generated or updated by  the activity described in
   * this resource. A provenance can point to more than one target if multiple
   * resources were created/updated by the same activity.
   */
  target: Array<Maybe<Resource>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * Provenance.Agent
 *     Provenance of a resource is a record that describes entities and processes
 *     involved in producing and delivering or otherwise influencing that resource.
 *     Provenance provides a critical foundation for assessing authenticity, enabling
 *     trust, and allowing reproducibility. Provenance assertions are a form of
 *     contextual metadata and can themselves become important records with their own
 *     provenance. Provenance statement indicates clinical significance in terms of
 *     confidence in authenticity, reliability, and trustworthiness, integrity, and
 *     stage in lifecycle (e.g. Document Completion - has the artifact been legally
 *     authenticated), all of which may impact security, privacy, and trust policies.
 */
export type ProvenanceAgent = {
  __typename?: 'ProvenanceAgent';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The individual, device, or organization for whom the change was made. */
  onBehalfOf?: Maybe<ProvenanceAgentOnBehalfOf>;
  /**
   * The function of the agent with respect to the activity. The security role
   * enabling the agent with respect to the activity.
   */
  role?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The participation the agent had with respect to the activity. */
  type?: Maybe<CodeableConcept>;
  /** The individual, device or organization that participated in the event. */
  who: ProvenanceAgentWho;
};

export type ProvenanceAgentOnBehalfOf = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type ProvenanceAgentWho = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Provenance.Entity
 *     Provenance of a resource is a record that describes entities and processes
 *     involved in producing and delivering or otherwise influencing that resource.
 *     Provenance provides a critical foundation for assessing authenticity, enabling
 *     trust, and allowing reproducibility. Provenance assertions are a form of
 *     contextual metadata and can themselves become important records with their own
 *     provenance. Provenance statement indicates clinical significance in terms of
 *     confidence in authenticity, reliability, and trustworthiness, integrity, and
 *     stage in lifecycle (e.g. Document Completion - has the artifact been legally
 *     authenticated), all of which may impact security, privacy, and trust policies.
 */
export type ProvenanceEntity = {
  __typename?: 'ProvenanceEntity';
  /**
   * The entity is attributed to an agent to express the agent's responsibility for
   * that entity, possibly along with other agents. This description can be
   * understood as shorthand for saying that the agent was responsible for the
   * activity which generated the entity.
   */
  agent?: Maybe<Array<Maybe<ProvenanceAgent>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** How the entity was used during the activity. */
  role: Scalars['code'];
  /**
   * Identity of the  Entity used. May be a logical or physical uri and maybe
   * absolute or relative.
   */
  what: Resource;
};

/**
 * Quantity
 *     A measured amount (or an amount that can potentially be measured). Note that
 *     measured amounts include amounts that are not precisely quantified, including
 *     amounts involving arbitrary units and floating currencies.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Quantity = {
  __typename?: 'Quantity';
  /** A computer processable form of the unit in some unit representation system. */
  code?: Maybe<Scalars['code']>;
  /**
   * How the value should be understood and represented - whether the actual value
   * is greater or less than the stated value due to measurement issues; e.g. if
   * the comparator is "<" , then the real value is < stated value.
   */
  comparator?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The identification of the system that provides the coded form of the unit. */
  system?: Maybe<Scalars['code']>;
  /** A human-readable form of the unit. */
  unit?: Maybe<Scalars['code']>;
  /**
   * The value of the measured amount. The value includes an implicit precision in
   * the presentation of the value.
   */
  value?: Maybe<Scalars['code']>;
};

export type Query = {
  __typename?: 'Query';
  /**
   * Account
   *     A financial tool for tracking value accrued for a particular purpose.  In the
   * healthcare field, used to track charges for a patient, cost centers, etc.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  account?: Maybe<Array<Maybe<Account>>>;
  /**
   * ActivityDefinition
   *     This resource allows for the definition of some activity to be performed,
   * independent of a particular patient, practitioner, or other performance
   * context.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  activityDefinition?: Maybe<Array<Maybe<ActivityDefinition>>>;
  /**
   * AdverseEvent
   *     Actual or  potential/avoided event causing unintended physical injury
   * resulting from or contributed to by medical care, a research study or other
   * healthcare setting factors that requires additional monitoring, treatment, or
   * hospitalization, or that results in death.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  adverseEvent?: Maybe<Array<Maybe<AdverseEvent>>>;
  /**
   * AllergyIntolerance
   *     Risk of harmful or undesirable, physiological response which is unique to an
   * individual and associated with exposure to a substance.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  allergyIntolerance?: Maybe<Array<Maybe<AllergyIntolerance>>>;
  /**
   * Appointment
   *     A booking of a healthcare event among patient(s), practitioner(s), related
   * person(s) and/or device(s) for a specific date/time. This may result in one or
   * more Encounter(s).
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  appointment?: Maybe<Array<Maybe<Appointment>>>;
  /**
   * AppointmentResponse
   *     A reply to an appointment request for a patient and/or practitioner(s), such
   * as a confirmation or rejection.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  appointmentResponse?: Maybe<Array<Maybe<AppointmentResponse>>>;
  /**
   * AuditEvent
   *     A record of an event made for purposes of maintaining a security log. Typical
   * uses include detection of intrusion attempts and monitoring for inappropriate
   * usage.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  auditEvent?: Maybe<Array<Maybe<AuditEvent>>>;
  /**
   * Basic
   *     Basic is used for handling concepts not yet defined in FHIR, narrative-only
   * resources that don't map to an existing resource, and custom resources not
   * appropriate for inclusion in the FHIR specification.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  basic?: Maybe<Array<Maybe<Basic>>>;
  /**
   * Binary
   *     A resource that represents the data of a single raw artifact as digital
   * content accessible in its native format.  A Binary resource can contain any
   * content, whether text, image, pdf, zip archive, etc.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  binary?: Maybe<Array<Maybe<Binary>>>;
  /**
   * BiologicallyDerivedProduct
   *     A material substance originating from a biological entity intended to be
   * transplanted or infused
   * into another (possibly the same) biological entity.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  biologicallyDerivedProduct?: Maybe<Array<Maybe<BiologicallyDerivedProduct>>>;
  /**
   * BodyStructure
   *     Record details about an anatomical structure.  This resource may be used when
   * a coded concept does not provide the necessary detail needed for the use case.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  bodyStructure?: Maybe<Array<Maybe<BodyStructure>>>;
  /**
   * Bundle
   *     A container for a collection of resources.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  bundle?: Maybe<Array<Maybe<Bundle>>>;
  /**
   * CapabilityStatement
   *     A Capability Statement documents a set of capabilities (behaviors) of a FHIR
   * Server for a particular version of FHIR that may be used as a statement of
   * actual server functionality or a statement of required or desired server
   * implementation.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  capabilityStatement?: Maybe<Array<Maybe<CapabilityStatement>>>;
  /**
   * CarePlan
   *     Describes the intention of how one or more practitioners intend to deliver
   * care for a particular patient, group or community for a period of time,
   * possibly limited to care for a specific condition or set of conditions.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  carePlan?: Maybe<Array<Maybe<CarePlan>>>;
  /**
   * CareTeam
   *     The Care Team includes all the people and organizations who plan to
   * participate in the coordination and delivery of care for a patient.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  careTeam?: Maybe<Array<Maybe<CareTeam>>>;
  /**
   * CatalogEntry
   *     Catalog entries are wrappers that contextualize items included in a catalog.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  catalogEntry?: Maybe<Array<Maybe<CatalogEntry>>>;
  /**
   * ChargeItem
   *     The resource ChargeItem describes the provision of healthcare provider
   * products for a certain patient, therefore referring not only to the product,
   * but containing in addition details of the provision, like date, time, amounts
   * and participating organizations and persons. Main Usage of the ChargeItem is
   * to enable the billing process and internal cost allocation.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  chargeItem?: Maybe<Array<Maybe<ChargeItem>>>;
  /**
   * ChargeItemDefinition
   *     The ChargeItemDefinition resource provides the properties that apply to the
   * (billing) codes necessary to calculate costs and prices. The properties may
   * differ largely depending on type and realm, therefore this resource gives only
   * a rough structure and requires profiling for each type of billing code system.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  chargeItemDefinition?: Maybe<Array<Maybe<ChargeItemDefinition>>>;
  /**
   * Claim
   *     A provider issued list of professional services and products which have been
   * provided, or are to be provided, to a patient which is sent to an insurer for
   * reimbursement.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  claim?: Maybe<Array<Maybe<Claim>>>;
  /**
   * ClaimResponse
   *     This resource provides the adjudication details from the processing of a Claim
   * resource.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  claimResponse?: Maybe<Array<Maybe<ClaimResponse>>>;
  /**
   * ClinicalImpression
   *     A record of a clinical assessment performed to determine what problem(s) may
   * affect the patient and before planning the treatments or management strategies
   * that are best to manage a patient's condition. Assessments are often 1:1 with
   * a clinical consultation / encounter,  but this varies greatly depending on the
   * clinical workflow. This resource is called "ClinicalImpression" rather than
   * "ClinicalAssessment" to avoid confusion with the recording of assessment tools
   * such as Apgar score.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  clinicalImpression?: Maybe<Array<Maybe<ClinicalImpression>>>;
  /**
   * CodeSystem
   *     The CodeSystem resource is used to declare the existence of and describe a
   * code system or code system supplement and its key properties, and optionally
   * define a part or all of its content.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  codeSystem?: Maybe<Array<Maybe<CodeSystem>>>;
  /**
   * Communication
   *     An occurrence of information being transmitted; e.g. an alert that was sent to
   * a responsible provider, a public health agency that was notified about a
   * reportable condition.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  communication?: Maybe<Array<Maybe<Communication>>>;
  /**
   * CommunicationRequest
   *     A request to convey information; e.g. the CDS system proposes that an alert be
   * sent to a responsible provider, the CDS system proposes that the public health
   * agency be notified about a reportable condition.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  communicationRequest?: Maybe<Array<Maybe<CommunicationRequest>>>;
  /**
   * CompartmentDefinition
   *     A compartment definition that defines how resources are accessed on a server.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  compartmentDefinition?: Maybe<Array<Maybe<CompartmentDefinition>>>;
  /**
   * Composition
   *     A set of healthcare-related information that is assembled together into a
   * single logical package that provides a single coherent statement of meaning,
   * establishes its own context and that has clinical attestation with regard to
   * who is making the statement. A Composition defines the structure and narrative
   * content necessary for a document. However, a Composition alone does not
   * constitute a document. Rather, the Composition must be the first entry in a
   * Bundle where Bundle.type=document, and any other resources referenced from
   * Composition must be included as subsequent entries in the Bundle (for example
   * Patient, Practitioner, Encounter, etc.).
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  composition?: Maybe<Array<Maybe<Composition>>>;
  /**
   * ConceptMap
   *     A statement of relationships from one set of concepts to one or more other
   * concepts - either concepts in code systems, or data element/data element
   * concepts, or classes in class models.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  conceptMap?: Maybe<Array<Maybe<ConceptMap>>>;
  /**
   * Condition
   *     A clinical condition, problem, diagnosis, or other event, situation, issue, or
   * clinical concept that has risen to a level of concern.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  condition?: Maybe<Array<Maybe<Condition>>>;
  /**
   * Consent
   *     A record of a healthcare consumer’s  choices, which permits or denies
   * identified recipient(s) or recipient role(s) to perform one or more actions
   * within a given policy context, for specific purposes and periods of time.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  consent?: Maybe<Array<Maybe<Consent>>>;
  /**
   * Contract
   *     Legally enforceable, formally recorded unilateral or bilateral directive i.e.,
   * a policy or agreement.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  contract?: Maybe<Array<Maybe<Contract>>>;
  /**
   * Coverage
   *     Financial instrument which may be used to reimburse or pay for health care
   * products and services. Includes both insurance and self-payment.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  coverage?: Maybe<Array<Maybe<Coverage>>>;
  /**
   * CoverageEligibilityRequest
   *     The CoverageEligibilityRequest provides patient and insurance coverage
   * information to an insurer for them to respond, in the form of an
   * CoverageEligibilityResponse, with information regarding whether the stated
   * coverage is valid and in-force and optionally to provide the insurance details
   * of the policy.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  coverageEligibilityRequest?: Maybe<Array<Maybe<CoverageEligibilityRequest>>>;
  /**
   * CoverageEligibilityResponse
   *     This resource provides eligibility and plan details from the processing of an
   * CoverageEligibilityRequest resource.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  coverageEligibilityResponse?: Maybe<Array<Maybe<CoverageEligibilityResponse>>>;
  /**
   * DetectedIssue
   *     Indicates an actual or potential clinical issue with or between one or more
   * active or proposed clinical actions for a patient; e.g. Drug-drug interaction,
   * Ineffective treatment frequency, Procedure-condition conflict, etc.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  detectedIssue?: Maybe<Array<Maybe<DetectedIssue>>>;
  /**
   * Device
   *     A type of a manufactured item that is used in the provision of healthcare
   * without being substantially changed through that activity. The device may be a
   * medical or non-medical device.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  device?: Maybe<Array<Maybe<Device>>>;
  /**
   * DeviceDefinition
   *     The characteristics, operational status and capabilities of a medical-related
   * component of a medical device.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  deviceDefinition?: Maybe<Array<Maybe<DeviceDefinition>>>;
  /**
   * DeviceMetric
   *     Describes a measurement, calculation or setting capability of a medical
   * device.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  deviceMetric?: Maybe<Array<Maybe<DeviceMetric>>>;
  /**
   * DeviceRequest
   *     Represents a request for a patient to employ a medical device. The device may
   * be an implantable device, or an external assistive device, such as a walker.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  deviceRequest?: Maybe<Array<Maybe<DeviceRequest>>>;
  /**
   * DeviceUseStatement
   *     A record of a device being used by a patient where the record is the result of
   * a report from the patient or another clinician.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  deviceUseStatement?: Maybe<Array<Maybe<DeviceUseStatement>>>;
  /**
   * DiagnosticReport
   *     The findings and interpretation of diagnostic  tests performed on patients,
   * groups of patients, devices, and locations, and/or specimens derived from
   * these. The report includes clinical context such as requesting and provider
   * information, and some mix of atomic results, images, textual and coded
   * interpretations, and formatted representation of diagnostic reports.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  diagnosticReport?: Maybe<Array<Maybe<DiagnosticReport>>>;
  /**
   * DocumentManifest
   *     A collection of documents compiled for a purpose together with metadata that
   * applies to the collection.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  documentManifest?: Maybe<Array<Maybe<DocumentManifest>>>;
  /**
   * DocumentReference
   *     A reference to a document of any kind for any purpose. Provides metadata about
   * the document so that the document can be discovered and managed. The scope of
   * a document is any seralized object with a mime-type, so includes formal
   * patient centric documents (CDA), cliical notes, scanned paper, and non-patient
   * specific documents like policy text.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  documentReference?: Maybe<Array<Maybe<DocumentReference>>>;
  /**
   * DomainResource
   *     A resource that includes narrative, extensions, and contained resources.
   */
  domainResource?: Maybe<Array<Maybe<DomainResource>>>;
  /**
   * EffectEvidenceSynthesis
   *     The EffectEvidenceSynthesis resource describes the difference in an outcome
   * between exposures states in a population where the effect estimate is derived
   * from a combination of research studies.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  effectEvidenceSynthesis?: Maybe<Array<Maybe<EffectEvidenceSynthesis>>>;
  /**
   * Encounter
   *     An interaction between a patient and healthcare provider(s) for the purpose of
   * providing healthcare service(s) or assessing the health status of a patient.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  encounter?: Maybe<Array<Maybe<Encounter>>>;
  /**
   * Endpoint
   *     The technical details of an endpoint that can be used for electronic services,
   * such as for web services providing XDS.b or a REST endpoint for another FHIR
   * server. This may include any security context information.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  endpoint?: Maybe<Array<Maybe<Endpoint>>>;
  /**
   * EnrollmentRequest
   *     This resource provides the insurance enrollment details to the insurer
   * regarding a specified coverage.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  enrollmentRequest?: Maybe<Array<Maybe<EnrollmentRequest>>>;
  /**
   * EnrollmentResponse
   *     This resource provides enrollment and plan details from the processing of an
   * EnrollmentRequest resource.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  enrollmentResponse?: Maybe<Array<Maybe<EnrollmentResponse>>>;
  /**
   * EpisodeOfCare
   *     An association between a patient and an organization / healthcare provider(s)
   * during which time encounters may occur. The managing organization assumes a
   * level of responsibility for the patient during this time.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  episodeOfCare?: Maybe<Array<Maybe<EpisodeOfCare>>>;
  /**
   * EventDefinition
   *     The EventDefinition resource provides a reusable description of when a
   * particular event can occur.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  eventDefinition?: Maybe<Array<Maybe<EventDefinition>>>;
  /**
   * Evidence
   *     The Evidence resource describes the conditional state (population and any
   * exposures being compared within the population) and outcome (if specified)
   * that the knowledge (evidence, assertion, recommendation) is about.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  evidence?: Maybe<Array<Maybe<Evidence>>>;
  /**
   * EvidenceVariable
   *     The EvidenceVariable resource describes a "PICO" element that knowledge
   * (evidence, assertion, recommendation) is about.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  evidenceVariable?: Maybe<Array<Maybe<EvidenceVariable>>>;
  /**
   * ExampleScenario
   *     Example of workflow instance.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  exampleScenario?: Maybe<Array<Maybe<ExampleScenario>>>;
  /**
   * ExplanationOfBenefit
   *     This resource provides: the claim details; adjudication details from the
   * processing of a Claim; and optionally account balance information, for
   * informing the subscriber of the benefits provided.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  explanationOfBenefit?: Maybe<Array<Maybe<ExplanationOfBenefit>>>;
  /**
   * FamilyMemberHistory
   *     Significant health conditions for a person related to the patient relevant in
   * the context of care for the patient.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  familyMemberHistory?: Maybe<Array<Maybe<FamilyMemberHistory>>>;
  /**
   * Flag
   *     Prospective warnings of potential issues when providing care to the patient.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  flag?: Maybe<Array<Maybe<Flag>>>;
  /**
   * Goal
   *     Describes the intended objective(s) for a patient, group or organization care,
   * for example, weight loss, restoring an activity of daily living, obtaining
   * herd immunity via immunization, meeting a process improvement objective, etc.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  goal?: Maybe<Array<Maybe<Goal>>>;
  /**
   * GraphDefinition
   *     A formal computable definition of a graph of resources - that is, a coherent
   * set of resources that form a graph by following references. The Graph
   * Definition resource defines a set and makes rules about the set.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  graphDefinition?: Maybe<Array<Maybe<GraphDefinition>>>;
  /**
   * Group
   *     Represents a defined collection of entities that may be discussed or acted
   * upon collectively but which are not expected to act collectively, and are not
   * formally or legally recognized; i.e. a collection of entities that isn't an
   * Organization.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  group?: Maybe<Array<Maybe<Group>>>;
  /**
   * GuidanceResponse
   *     A guidance response is the formal response to a guidance request, including
   * any output parameters returned by the evaluation, as well as the description
   * of any proposed actions to be taken.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  guidanceResponse?: Maybe<Array<Maybe<GuidanceResponse>>>;
  /**
   * HealthcareService
   *     The details of a healthcare service available at a location.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  healthcareService?: Maybe<Array<Maybe<HealthcareService>>>;
  id?: Maybe<Scalars['String']>;
  /**
   * ImagingStudy
   *     Representation of the content produced in a DICOM imaging study. A study
   * comprises a set of series, each of which includes a set of Service-Object Pair
   * Instances (SOP Instances - images or other data) acquired or produced in a
   * common context.  A series is of only one modality (e.g. X-ray, CT, MR,
   * ultrasound), but a study may have multiple series of different modalities.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  imagingStudy?: Maybe<Array<Maybe<ImagingStudy>>>;
  /**
   * Immunization
   *     Describes the event of a patient being administered a vaccine or a record of
   * an immunization as reported by a patient, a clinician or another party.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  immunization?: Maybe<Array<Maybe<Immunization>>>;
  /**
   * ImmunizationEvaluation
   *     Describes a comparison of an immunization event against published
   * recommendations to determine if the administration is "valid" in relation to
   * those  recommendations.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  immunizationEvaluation?: Maybe<Array<Maybe<ImmunizationEvaluation>>>;
  /**
   * ImmunizationRecommendation
   *     A patient's point-in-time set of recommendations (i.e. forecasting) according
   * to a published schedule with optional supporting justification.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  immunizationRecommendation?: Maybe<Array<Maybe<ImmunizationRecommendation>>>;
  /**
   * ImplementationGuide
   *     A set of rules of how a particular interoperability or standards problem is
   * solved - typically through the use of FHIR resources. This resource is used to
   * gather all the parts of an implementation guide into a logical whole and to
   * publish a computable definition of all the parts.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  implementationGuide?: Maybe<Array<Maybe<ImplementationGuide>>>;
  /**
   * InsurancePlan
   *     Details of a Health Insurance product/plan provided by an organization.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  insurancePlan?: Maybe<Array<Maybe<InsurancePlan>>>;
  /**
   * Invoice
   *     Invoice containing collected ChargeItems from an Account with calculated
   * individual and total price for Billing purpose.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  invoice?: Maybe<Array<Maybe<Invoice>>>;
  /**
   * Library
   *     The Library resource is a general-purpose container for knowledge asset
   * definitions. It can be used to describe and expose existing knowledge assets
   * such as logic libraries and information model descriptions, as well as to
   * describe a collection of knowledge assets.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  library?: Maybe<Array<Maybe<Library>>>;
  /**
   * Linkage
   *     Identifies two or more records (resource instances) that refer to the same
   * real-world "occurrence".
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  linkage?: Maybe<Array<Maybe<Linkage>>>;
  /**
   * List
   *     A list is a curated collection of resources.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  list?: Maybe<Array<Maybe<List>>>;
  /**
   * Location
   *     Details and position information for a physical place where services are
   * provided and resources and participants may be stored, found, contained, or
   * accommodated.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  location?: Maybe<Array<Maybe<Location>>>;
  /**
   * Measure
   *     The Measure resource provides the definition of a quality measure.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  measure?: Maybe<Array<Maybe<Measure>>>;
  /**
   * MeasureReport
   *     The MeasureReport resource contains the results of the calculation of a
   * measure; and optionally a reference to the resources involved in that
   * calculation.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  measureReport?: Maybe<Array<Maybe<MeasureReport>>>;
  /**
   * Media
   *     A photo, video, or audio recording acquired or used in healthcare. The actual
   * content may be inline or provided by direct reference.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  media?: Maybe<Array<Maybe<Media>>>;
  /**
   * Medication
   *     This resource is primarily used for the identification and definition of a
   * medication for the purposes of prescribing, dispensing, and administering a
   * medication as well as for making statements about medication use.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  medication?: Maybe<Array<Maybe<Medication>>>;
  /**
   * MedicationAdministration
   *     Describes the event of a patient consuming or otherwise being administered a
   * medication.  This may be as simple as swallowing a tablet or it may be a long
   * running infusion.  Related resources tie this event to the authorizing
   * prescription, and the specific encounter between patient and health care
   * practitioner.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  medicationAdministration?: Maybe<Array<Maybe<MedicationAdministration>>>;
  /**
   * MedicationDispense
   *     Indicates that a medication product is to be or has been dispensed for a named
   * person/patient.  This includes a description of the medication product
   * (supply) provided and the instructions for administering the medication.  The
   * medication dispense is the result of a pharmacy system responding to a
   * medication order.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  medicationDispense?: Maybe<Array<Maybe<MedicationDispense>>>;
  /**
   * MedicationKnowledge
   *     Information about a medication that is used to support knowledge.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  medicationKnowledge?: Maybe<Array<Maybe<MedicationKnowledge>>>;
  /**
   * MedicationRequest
   *     An order or request for both supply of the medication and the instructions for
   * administration of the medication to a patient. The resource is called
   * "MedicationRequest" rather than "MedicationPrescription" or "MedicationOrder"
   * to generalize the use across inpatient and outpatient settings, including care
   * plans, etc., and to harmonize with workflow patterns.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  medicationRequest?: Maybe<Array<Maybe<MedicationRequest>>>;
  /**
   * MedicationStatement
   *     A record of a medication that is being consumed by a patient.   A
   * MedicationStatement may indicate that the patient may be taking the medication
   * now or has taken the medication in the past or will be taking the medication
   * in the future.  The source of this information can be the patient, significant
   * other (such as a family member or spouse), or a clinician.  A common scenario
   * where this information is captured is during the history taking process during
   * a patient visit or stay.   The medication information may come from sources
   * such as the patient's memory, from a prescription bottle,  or from a list of
   * medications the patient, clinician or other party maintains.
   *
   * The primary difference between a medication statement and a medication
   * administration is that the medication administration has complete
   * administration information and is based on actual administration information
   * from the person who administered the medication.  A medication statement is
   * often, if not always, less specific.  There is no required date/time when the
   * medication was administered, in fact we only know that a source has reported
   * the patient is taking this medication, where details such as time, quantity,
   * or rate or even medication product may be incomplete or missing or less
   * precise.  As stated earlier, the medication statement information may come
   * from the patient's memory, from a prescription bottle or from a list of
   * medications the patient, clinician or other party maintains.  Medication
   * administration is more formal and is not missing detailed information.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  medicationStatement?: Maybe<Array<Maybe<MedicationStatement>>>;
  /**
   * MedicinalProduct
   *     Detailed definition of a medicinal product, typically for uses other than
   * direct patient care (e.g. regulatory use).
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  medicinalProduct?: Maybe<Array<Maybe<MedicinalProduct>>>;
  /**
   * MedicinalProductAuthorization
   *     The regulatory authorization of a medicinal product.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  medicinalProductAuthorization?: Maybe<Array<Maybe<MedicinalProductAuthorization>>>;
  /**
   * MedicinalProductContraindication
   *     The clinical particulars - indications, contraindications etc. of a medicinal
   * product, including for regulatory purposes.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  medicinalProductContraindication?: Maybe<Array<Maybe<MedicinalProductContraindication>>>;
  /**
   * MedicinalProductIndication
   *     Indication for the Medicinal Product.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  medicinalProductIndication?: Maybe<Array<Maybe<MedicinalProductIndication>>>;
  /**
   * MedicinalProductIngredient
   *     An ingredient of a manufactured item or pharmaceutical product.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  medicinalProductIngredient?: Maybe<Array<Maybe<MedicinalProductIngredient>>>;
  /**
   * MedicinalProductInteraction
   *     The interactions of the medicinal product with other medicinal products, or
   * other forms of interactions.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  medicinalProductInteraction?: Maybe<Array<Maybe<MedicinalProductInteraction>>>;
  /**
   * MedicinalProductManufactured
   *     The manufactured item as contained in the packaged medicinal product.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  medicinalProductManufactured?: Maybe<Array<Maybe<MedicinalProductManufactured>>>;
  /**
   * MedicinalProductPackaged
   *     A medicinal product in a container or package.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  medicinalProductPackaged?: Maybe<Array<Maybe<MedicinalProductPackaged>>>;
  /**
   * MedicinalProductPharmaceutical
   *     A pharmaceutical product described in terms of its composition and dose form.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  medicinalProductPharmaceutical?: Maybe<Array<Maybe<MedicinalProductPharmaceutical>>>;
  /**
   * MedicinalProductUndesirableEffect
   *     Describe the undesirable effects of the medicinal product.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  medicinalProductUndesirableEffect?: Maybe<Array<Maybe<MedicinalProductUndesirableEffect>>>;
  /**
   * MessageDefinition
   *     Defines the characteristics of a message that can be shared between systems,
   * including the type of event that initiates the message, the content to be
   * transmitted and what response(s), if any, are permitted.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  messageDefinition?: Maybe<Array<Maybe<MessageDefinition>>>;
  /**
   * MessageHeader
   *     The header for a message exchange that is either requesting or responding to
   * an action.  The reference(s) that are the subject of the action as well as
   * other information related to the action are typically transmitted in a bundle
   * in which the MessageHeader resource instance is the first resource in the
   * bundle.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  messageHeader?: Maybe<Array<Maybe<MessageHeader>>>;
  /**
   * MolecularSequence
   *     Raw data describing a biological sequence.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  molecularSequence?: Maybe<Array<Maybe<MolecularSequence>>>;
  /**
   * NamingSystem
   *     A curated namespace that issues unique symbols within that namespace for the
   * identification of concepts, people, devices, etc.  Represents a "System" used
   * within the Identifier and Coding data types.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  namingSystem?: Maybe<Array<Maybe<NamingSystem>>>;
  /**
   * NutritionOrder
   *     A request to supply a diet, formula feeding (enteral) or oral nutritional
   * supplement to a patient/resident.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  nutritionOrder?: Maybe<Array<Maybe<NutritionOrder>>>;
  /**
   * Observation
   *     Measurements and simple assertions made about a patient, device or other
   * subject.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  observation?: Maybe<Array<Maybe<Observation>>>;
  /**
   * ObservationDefinition
   *     Set of definitional characteristics for a kind of observation or measurement
   * produced or consumed by an orderable health care service.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  observationDefinition?: Maybe<Array<Maybe<ObservationDefinition>>>;
  /**
   * OperationDefinition
   *     A formal computable definition of an operation (on the RESTful interface) or a
   * named query (using the search interaction).
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  operationDefinition?: Maybe<Array<Maybe<OperationDefinition>>>;
  /**
   * OperationOutcome
   *     A collection of error, warning, or information messages that result from a
   * system action.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  operationOutcome?: Maybe<Array<Maybe<OperationOutcome>>>;
  /**
   * Organization
   *     A formally or informally recognized grouping of people or organizations formed
   * for the purpose of achieving some form of collective action.  Includes
   * companies, institutions, corporations, departments, community groups,
   * healthcare practice groups, payer/insurer, etc.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  organization?: Maybe<Array<Maybe<Organization>>>;
  /**
   * OrganizationAffiliation
   *     Defines an affiliation/assotiation/relationship between 2 distinct
   * oganizations, that is not a part-of relationship/sub-division relationship.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  organizationAffiliation?: Maybe<Array<Maybe<OrganizationAffiliation>>>;
  /**
   * Parameters
   *     This resource is a non-persisted resource used to pass information into and
   * back from an [operation](operations.html). It has no other use, and there is
   * no RESTful endpoint associated with it.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  parameters?: Maybe<Array<Maybe<Parameters>>>;
  /**
   * Patient
   *     Demographics and other administrative information about an individual or
   * animal receiving care or other health-related services.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  patient?: Maybe<Array<Maybe<Patient>>>;
  /**
   * PaymentNotice
   *     This resource provides the status of the payment for goods and services
   * rendered, and the request and response resource references.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  paymentNotice?: Maybe<Array<Maybe<PaymentNotice>>>;
  /**
   * PaymentReconciliation
   *     This resource provides the details including amount of a payment and allocates
   * the payment items being paid.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  paymentReconciliation?: Maybe<Array<Maybe<PaymentReconciliation>>>;
  /**
   * Person
   *     Demographics and administrative information about a person independent of a
   * specific health-related context.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  person?: Maybe<Array<Maybe<Person>>>;
  /**
   * PlanDefinition
   *     This resource allows for the definition of various types of plans as a
   * sharable, consumable, and executable artifact. The resource is general enough
   * to support the description of a broad range of clinical artifacts such as
   * clinical decision support rules, order sets and protocols.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  planDefinition?: Maybe<Array<Maybe<PlanDefinition>>>;
  /**
   * Practitioner
   *     A person who is directly or indirectly involved in the provisioning of
   * healthcare.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  practitioner?: Maybe<Array<Maybe<Practitioner>>>;
  /**
   * PractitionerRole
   *     A specific set of Roles/Locations/specialties/services that a practitioner may
   * perform at an organization for a period of time.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  practitionerRole?: Maybe<Array<Maybe<PractitionerRole>>>;
  /**
   * Procedure
   *     An action that is or was performed on or for a patient. This can be a physical
   * intervention like an operation, or less invasive like long term services,
   * counseling, or hypnotherapy.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  procedure?: Maybe<Array<Maybe<Procedure>>>;
  /**
   * Provenance
   *     Provenance of a resource is a record that describes entities and processes
   * involved in producing and delivering or otherwise influencing that resource.
   * Provenance provides a critical foundation for assessing authenticity, enabling
   * trust, and allowing reproducibility. Provenance assertions are a form of
   * contextual metadata and can themselves become important records with their own
   * provenance. Provenance statement indicates clinical significance in terms of
   * confidence in authenticity, reliability, and trustworthiness, integrity, and
   * stage in lifecycle (e.g. Document Completion - has the artifact been legally
   * authenticated), all of which may impact security, privacy, and trust policies.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  provenance?: Maybe<Array<Maybe<Provenance>>>;
  /**
   * Questionnaire
   *     A structured set of questions intended to guide the collection of answers from
   * end-users. Questionnaires provide detailed control over order, presentation,
   * phraseology and grouping to allow coherent, consistent data collection.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  questionnaire?: Maybe<Array<Maybe<Questionnaire>>>;
  /**
   * QuestionnaireResponse
   *     A structured set of questions and their answers. The questions are ordered and
   * grouped into coherent subsets, corresponding to the structure of the grouping
   * of the questionnaire being responded to.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  questionnaireResponse?: Maybe<Array<Maybe<QuestionnaireResponse>>>;
  /**
   * RelatedPerson
   *     Information about a person that is involved in the care for a patient, but who
   * is not the target of healthcare, nor has a formal responsibility in the care
   * process.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  relatedPerson?: Maybe<Array<Maybe<RelatedPerson>>>;
  /**
   * RequestGroup
   *     A group of related requests that can be used to capture intended activities
   * that have inter-dependencies such as "give this medication after that one".
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  requestGroup?: Maybe<Array<Maybe<RequestGroup>>>;
  /**
   * ResearchDefinition
   *     The ResearchDefinition resource describes the conditional state (population
   * and any exposures being compared within the population) and outcome (if
   * specified) that the knowledge (evidence, assertion, recommendation) is about.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  researchDefinition?: Maybe<Array<Maybe<ResearchDefinition>>>;
  /**
   * ResearchElementDefinition
   *     The ResearchElementDefinition resource describes a "PICO" element that
   * knowledge (evidence, assertion, recommendation) is about.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  researchElementDefinition?: Maybe<Array<Maybe<ResearchElementDefinition>>>;
  /**
   * ResearchStudy
   *     A process where a researcher or organization plans and then executes a series
   * of steps intended to increase the field of healthcare-related knowledge.  This
   * includes studies of safety, efficacy, comparative effectiveness and other
   * information about medications, devices, therapies and other interventional and
   * investigative techniques.  A ResearchStudy involves the gathering of
   * information about human or animal subjects.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  researchStudy?: Maybe<Array<Maybe<ResearchStudy>>>;
  /**
   * ResearchSubject
   *     A physical entity which is the primary unit of operational and/or
   * administrative interest in a study.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  researchSubject?: Maybe<Array<Maybe<ResearchSubject>>>;
  /**
   * Resource
   *     This is the base resource type for everything.
   */
  resource?: Maybe<Array<Maybe<Resource>>>;
  /**
   * RiskAssessment
   *     An assessment of the likely outcome(s) for a patient or other subject as well
   * as the likelihood of each outcome.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  riskAssessment?: Maybe<Array<Maybe<RiskAssessment>>>;
  /**
   * RiskEvidenceSynthesis
   *     The RiskEvidenceSynthesis resource describes the likelihood of an outcome in a
   * population plus exposure state where the risk estimate is derived from a
   * combination of research studies.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  riskEvidenceSynthesis?: Maybe<Array<Maybe<RiskEvidenceSynthesis>>>;
  /**
   * Schedule
   *     A container for slots of time that may be available for booking appointments.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  schedule?: Maybe<Array<Maybe<Schedule>>>;
  /**
   * SearchParameter
   *     A search parameter that defines a named search item that can be used to
   * search/filter on a resource.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  searchParameter?: Maybe<Array<Maybe<SearchParameter>>>;
  /**
   * ServiceRequest
   *     A record of a request for service such as diagnostic investigations,
   * treatments, or operations to be performed.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  serviceRequest?: Maybe<Array<Maybe<ServiceRequest>>>;
  /**
   * Slot
   *     A slot of time on a schedule that may be available for booking appointments.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  slot?: Maybe<Array<Maybe<Slot>>>;
  /**
   * Specimen
   *     A sample to be used for analysis.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  specimen?: Maybe<Array<Maybe<Specimen>>>;
  /**
   * SpecimenDefinition
   *     A kind of specimen with associated set of requirements.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  specimenDefinition?: Maybe<Array<Maybe<SpecimenDefinition>>>;
  /**
   * StructureDefinition
   *     A definition of a FHIR structure. This resource is used to describe the
   * underlying resources, data types defined in FHIR, and also for describing
   * extensions and constraints on resources and data types.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  structureDefinition?: Maybe<Array<Maybe<StructureDefinition>>>;
  /**
   * StructureMap
   *     A Map of relationships between 2 structures that can be used to transform
   * data.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  structureMap?: Maybe<Array<Maybe<StructureMap>>>;
  /**
   * Subscription
   *     The subscription resource is used to define a push-based subscription from a
   * server to another system. Once a subscription is registered with the server,
   * the server checks every resource that is created or updated, and if the
   * resource matches the given criteria, it sends a message on the defined
   * "channel" so that another system can take an appropriate action.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  subscription?: Maybe<Array<Maybe<Subscription>>>;
  /**
   * Substance
   *     A homogeneous material with a definite composition.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  substance?: Maybe<Array<Maybe<Substance>>>;
  /**
   * SubstanceNucleicAcid
   *     Nucleic acids are defined by three distinct elements: the base, sugar and
   * linkage. Individual substance/moiety IDs will be created for each of these
   * elements. The nucleotide sequence will be always entered in the 5’-3’
   * direction.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  substanceNucleicAcid?: Maybe<Array<Maybe<SubstanceNucleicAcid>>>;
  /**
   * SubstancePolymer
   *     Todo.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  substancePolymer?: Maybe<Array<Maybe<SubstancePolymer>>>;
  /**
   * SubstanceProtein
   *     A SubstanceProtein is defined as a single unit of a linear amino acid
   * sequence, or a combination of subunits that are either covalently linked or
   * have a defined invariant stoichiometric relationship. This includes all
   * synthetic, recombinant and purified SubstanceProteins of defined sequence,
   * whether the use is therapeutic or prophylactic. This set of elements will be
   * used to describe albumins, coagulation factors, cytokines, growth factors,
   * peptide/SubstanceProtein hormones, enzymes, toxins, toxoids, recombinant
   * vaccines, and immunomodulators.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  substanceProtein?: Maybe<Array<Maybe<SubstanceProtein>>>;
  /**
   * SubstanceReferenceInformation
   *     Todo.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  substanceReferenceInformation?: Maybe<Array<Maybe<SubstanceReferenceInformation>>>;
  /**
   * SubstanceSourceMaterial
   *     Source material shall capture information on the taxonomic and anatomical
   * origins as well as the fraction of a material that can result in or can be
   * modified to form a substance. This set of data elements shall be used to
   * define polymer substances isolated from biological matrices. Taxonomic and
   * anatomical origins shall be described using a controlled vocabulary as
   * required. This information is captured for naturally derived polymers ( .
   * starch) and structurally diverse substances. For Organisms belonging to the
   * Kingdom Plantae the Substance level defines the fresh material of a single
   * species or infraspecies, the Herbal Drug and the Herbal preparation. For
   * Herbal preparations, the fraction information will be captured at the
   * Substance information level and additional information for herbal extracts
   * will be captured at the Specified Substance Group 1 information level. See for
   * further explanation the Substance Class: Structurally Diverse and the herbal
   * annex.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  substanceSourceMaterial?: Maybe<Array<Maybe<SubstanceSourceMaterial>>>;
  /**
   * SubstanceSpecification
   *     The detailed description of a substance, typically at a level beyond what is
   * used for prescribing.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  substanceSpecification?: Maybe<Array<Maybe<SubstanceSpecification>>>;
  /**
   * SupplyDelivery
   *     Record of delivery of what is supplied.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  supplyDelivery?: Maybe<Array<Maybe<SupplyDelivery>>>;
  /**
   * SupplyRequest
   *     A record of a request for a medication, substance or device used in the
   * healthcare setting.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  supplyRequest?: Maybe<Array<Maybe<SupplyRequest>>>;
  /**
   * Task
   *     A task to be performed.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  task?: Maybe<Array<Maybe<Task>>>;
  /**
   * TerminologyCapabilities
   *     A TerminologyCapabilities resource documents a set of capabilities (behaviors)
   * of a FHIR Terminology Server that may be used as a statement of actual server
   * functionality or a statement of required or desired server implementation.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  terminologyCapabilities?: Maybe<Array<Maybe<TerminologyCapabilities>>>;
  /**
   * TestReport
   *     A summary of information based on the results of executing a TestScript.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  testReport?: Maybe<Array<Maybe<TestReport>>>;
  /**
   * TestScript
   *     A structured set of tests against a FHIR server or client implementation to
   * determine compliance against the FHIR specification.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  testScript?: Maybe<Array<Maybe<TestScript>>>;
  /**
   * ValueSet
   *     A ValueSet resource instance specifies a set of codes drawn from one or more
   * code systems, intended for use in a particular context. Value sets link
   * between [[[CodeSystem]]] definitions and their use in [coded
   * elements](terminologies.html).
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  valueSet?: Maybe<Array<Maybe<ValueSet>>>;
  /**
   * VerificationResult
   *     Describes validation requirements, source(s), status and dates for one or more
   * elements.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  verificationResult?: Maybe<Array<Maybe<VerificationResult>>>;
  /**
   * VisionPrescription
   *     An authorization for the provision of glasses and/or contact lenses to a
   * patient.
   *     If the element is present, it must have either a @value, an @id, or extensions
   */
  visionPrescription?: Maybe<Array<Maybe<VisionPrescription>>>;
};


export type QueryAccountArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryActivityDefinitionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryAdverseEventArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryAllergyIntoleranceArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryAppointmentArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryAppointmentResponseArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryAuditEventArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryBasicArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryBinaryArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryBiologicallyDerivedProductArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryBodyStructureArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryBundleArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryCapabilityStatementArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryCarePlanArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryCareTeamArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryCatalogEntryArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryChargeItemArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryChargeItemDefinitionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryClaimArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryClaimResponseArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryClinicalImpressionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryCodeSystemArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryCommunicationArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryCommunicationRequestArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryCompartmentDefinitionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryCompositionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryConceptMapArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryConditionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryConsentArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryContractArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryCoverageArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryCoverageEligibilityRequestArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryCoverageEligibilityResponseArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryDetectedIssueArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryDeviceArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryDeviceDefinitionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryDeviceMetricArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryDeviceRequestArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryDeviceUseStatementArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryDiagnosticReportArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryDocumentManifestArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryDocumentReferenceArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryDomainResourceArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryEffectEvidenceSynthesisArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryEncounterArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryEndpointArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryEnrollmentRequestArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryEnrollmentResponseArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryEpisodeOfCareArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryEventDefinitionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryEvidenceArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryEvidenceVariableArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryExampleScenarioArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryExplanationOfBenefitArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryFamilyMemberHistoryArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryFlagArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryGoalArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryGraphDefinitionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryGroupArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryGuidanceResponseArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryHealthcareServiceArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryImagingStudyArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryImmunizationArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryImmunizationEvaluationArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryImmunizationRecommendationArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryImplementationGuideArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryInsurancePlanArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryInvoiceArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryLibraryArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryLinkageArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryListArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryLocationArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMeasureArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMeasureReportArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMediaArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMedicationArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMedicationAdministrationArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMedicationDispenseArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMedicationKnowledgeArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMedicationRequestArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMedicationStatementArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMedicinalProductArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMedicinalProductAuthorizationArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMedicinalProductContraindicationArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMedicinalProductIndicationArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMedicinalProductIngredientArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMedicinalProductInteractionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMedicinalProductManufacturedArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMedicinalProductPackagedArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMedicinalProductPharmaceuticalArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMedicinalProductUndesirableEffectArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMessageDefinitionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMessageHeaderArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryMolecularSequenceArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryNamingSystemArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryNutritionOrderArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryObservationArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryObservationDefinitionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryOperationDefinitionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryOperationOutcomeArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryOrganizationArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryOrganizationAffiliationArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryParametersArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryPatientArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryPaymentNoticeArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryPaymentReconciliationArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryPersonArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryPlanDefinitionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryPractitionerArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryPractitionerRoleArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryProcedureArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryProvenanceArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryQuestionnaireArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryQuestionnaireResponseArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryRelatedPersonArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryRequestGroupArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryResearchDefinitionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryResearchElementDefinitionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryResearchStudyArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryResearchSubjectArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryResourceArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryRiskAssessmentArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryRiskEvidenceSynthesisArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryScheduleArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QuerySearchParameterArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryServiceRequestArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QuerySlotArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QuerySpecimenArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QuerySpecimenDefinitionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryStructureDefinitionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryStructureMapArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QuerySubscriptionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QuerySubstanceArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QuerySubstanceNucleicAcidArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QuerySubstancePolymerArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QuerySubstanceProteinArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QuerySubstanceReferenceInformationArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QuerySubstanceSourceMaterialArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QuerySubstanceSpecificationArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QuerySupplyDeliveryArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QuerySupplyRequestArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryTaskArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryTerminologyCapabilitiesArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryTestReportArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryTestScriptArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryValueSetArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryVerificationResultArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};


export type QueryVisionPrescriptionArgs = {
  _lastUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _security?: InputMaybe<Scalars['String']>;
  _tag?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  healthcareService?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  identifier?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Scalars['String']>;
  practitioner?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  versionId?: InputMaybe<Scalars['String']>;
};

/**
 * Questionnaire
 *     A structured set of questions intended to guide the collection of answers from
 *     end-users. Questionnaires provide detailed control over order, presentation,
 *     phraseology and grouping to allow coherent, consistent data collection.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Questionnaire = {
  __typename?: 'Questionnaire';
  /**
   * The date on which the resource content was approved by the publisher. Approval
   * happens once when the content is officially approved for usage.
   */
  approvalDate?: Maybe<Scalars['code']>;
  /**
   * An identifier for this question or group of questions in a particular
   * terminology such as LOINC.
   */
  code?: Maybe<Array<Maybe<Coding>>>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the questionnaire and/or its contents.
   * Copyright statements are generally legal restrictions on the use and
   * publishing of the questionnaire.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the questionnaire was published. The date
   * must change when the business version changes and it must change if the status
   * code changes. In addition, it should change when the substantive content of
   * the questionnaire changes.
   */
  date?: Maybe<Scalars['code']>;
  /** The URL of a Questionnaire that this Questionnaire is based on. */
  derivedFrom?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A free text natural language description of the questionnaire from a
   * consumer's perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * The period during which the questionnaire content was or is planned to be in
   * active use.
   */
  effectivePeriod?: Maybe<Period>;
  /**
   * A Boolean value to indicate that this questionnaire is authored for testing
   * purposes (or education/evaluation/marketing) and is not intended to be used
   * for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this questionnaire when it is
   * represented in other formats, or referenced in a specification, model, design
   * or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A particular question, question grouping or display text that is part of the
   * questionnaire.
   */
  item?: Maybe<Array<Maybe<QuestionnaireItem>>>;
  /**
   * A legal or geographic region in which the questionnaire is intended to be
   * used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The date on which the resource content was last reviewed. Review happens
   * periodically after approval but does not change the original approval date.
   */
  lastReviewDate?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the questionnaire. This name should be
   * usable as an identifier for the module by machine processing applications such
   * as code generation.
   */
  name?: Maybe<Scalars['code']>;
  /** The name of the organization or individual that published the questionnaire. */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this questionnaire is needed and why it has been designed
   * as it has.
   */
  purpose?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The status of this questionnaire. Enables tracking the life-cycle of the
   * content.
   */
  status: Scalars['code'];
  /**
   * The types of subjects that can be the subject of responses created for the
   * questionnaire.
   */
  subjectType?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the questionnaire. */
  title?: Maybe<Scalars['code']>;
  /**
   * An absolute URI that is used to identify this questionnaire when it is
   * referenced in a specification, model, design or an instance; also called its
   * canonical identifier. This SHOULD be globally unique and SHOULD be a literal
   * address at which at which an authoritative instance of this questionnaire is
   * (or will be) published. This URL can be the target of a canonical reference.
   * It SHALL remain the same when the questionnaire is stored on different
   * servers.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate
   * questionnaire instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the questionnaire when
   * it is referenced in a specification, model, design or instance. This is an
   * arbitrary value managed by the questionnaire author and is not expected to be
   * globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a
   * managed version is not available. There is also no expectation that versions
   * can be placed in a lexicographical sequence.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * Questionnaire.AnswerOption
 *     A structured set of questions intended to guide the collection of answers from
 *     end-users. Questionnaires provide detailed control over order, presentation,
 *     phraseology and grouping to allow coherent, consistent data collection.
 */
export type QuestionnaireAnswerOption = {
  __typename?: 'QuestionnaireAnswerOption';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Indicates whether the answer value is selected when the list of possible
   * answers is initially shown.
   */
  initialSelected?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  valueCoding?: Maybe<Coding>;
  /** None */
  valueDate?: Maybe<Scalars['code']>;
  /** None */
  valueInteger?: Maybe<Scalars['code']>;
  /** None */
  valueReference?: Maybe<Resource>;
  /** None */
  valueString?: Maybe<Scalars['code']>;
  /** None */
  valueTime?: Maybe<Scalars['code']>;
};

/**
 * Questionnaire.EnableWhen
 *     A structured set of questions intended to guide the collection of answers from
 *     end-users. Questionnaires provide detailed control over order, presentation,
 *     phraseology and grouping to allow coherent, consistent data collection.
 */
export type QuestionnaireEnableWhen = {
  __typename?: 'QuestionnaireEnableWhen';
  /** None */
  answerBoolean?: Maybe<Scalars['code']>;
  /** None */
  answerCoding?: Maybe<Coding>;
  /** None */
  answerDate?: Maybe<Scalars['code']>;
  /** None */
  answerDateTime?: Maybe<Scalars['code']>;
  /** None */
  answerDecimal?: Maybe<Scalars['code']>;
  /** None */
  answerInteger?: Maybe<Scalars['code']>;
  /** None */
  answerQuantity?: Maybe<Quantity>;
  /** None */
  answerReference?: Maybe<Resource>;
  /** None */
  answerString?: Maybe<Scalars['code']>;
  /** None */
  answerTime?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Specifies the criteria by which the question is enabled. */
  operator: Scalars['code'];
  /**
   * The linkId for the question whose answer (or lack of answer) governs whether
   * this item is enabled.
   */
  question: Scalars['code'];
};

/**
 * Questionnaire.Initial
 *     A structured set of questions intended to guide the collection of answers from
 *     end-users. Questionnaires provide detailed control over order, presentation,
 *     phraseology and grouping to allow coherent, consistent data collection.
 */
export type QuestionnaireInitial = {
  __typename?: 'QuestionnaireInitial';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  valueAttachment?: Maybe<Attachment>;
  /** None */
  valueBoolean?: Maybe<Scalars['code']>;
  /** None */
  valueCoding?: Maybe<Coding>;
  /** None */
  valueDate?: Maybe<Scalars['code']>;
  /** None */
  valueDateTime?: Maybe<Scalars['code']>;
  /** None */
  valueDecimal?: Maybe<Scalars['code']>;
  /** None */
  valueInteger?: Maybe<Scalars['code']>;
  /** None */
  valueQuantity?: Maybe<Quantity>;
  /** None */
  valueReference?: Maybe<Resource>;
  /** None */
  valueString?: Maybe<Scalars['code']>;
  /** None */
  valueTime?: Maybe<Scalars['code']>;
  /** None */
  valueUri?: Maybe<Scalars['code']>;
};

/**
 * Questionnaire.Item
 *     A structured set of questions intended to guide the collection of answers from
 *     end-users. Questionnaires provide detailed control over order, presentation,
 *     phraseology and grouping to allow coherent, consistent data collection.
 */
export type QuestionnaireItem = {
  __typename?: 'QuestionnaireItem';
  /** One of the permitted answers for a "choice" or "open-choice" question. */
  answerOption?: Maybe<Array<Maybe<QuestionnaireAnswerOption>>>;
  /**
   * A reference to a value set containing a list of codes representing permitted
   * answers for a "choice" or "open-choice" question.
   */
  answerValueSet?: Maybe<Scalars['code']>;
  /**
   * A terminology code that corresponds to this group or question (e.g. a code
   * from LOINC, which defines many questions and answers).
   */
  code?: Maybe<Array<Maybe<Coding>>>;
  /**
   * This element is a URI that refers to an [[[ElementDefinition]]] that provides
   * information about this item, including information that might otherwise be
   * included in the instance of the Questionnaire resource. A detailed description
   * of the construction of the URI is shown in Comments, below. If this element is
   * present then the following element values MAY be derived from the Element
   * Definition if the corresponding elements of this Questionnaire resource
   * instance have no value:
   *
   * * code (ElementDefinition.code)
   * * type (ElementDefinition.type)
   * * required (ElementDefinition.min)
   * * repeats (ElementDefinition.max)
   * * maxLength (ElementDefinition.maxLength)
   * * answerValueSet (ElementDefinition.binding)
   * * options (ElementDefinition.binding).
   */
  definition?: Maybe<Scalars['code']>;
  /**
   * Controls how multiple enableWhen values are interpreted -  whether all or any
   * must be true.
   */
  enableBehavior?: Maybe<Scalars['code']>;
  /**
   * A constraint indicating that this item should only be enabled (displayed/allow
   * answers to be captured) when the specified condition is true.
   */
  enableWhen?: Maybe<Array<Maybe<QuestionnaireEnableWhen>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * One or more values that should be pre-populated in the answer when initially
   * rendering the questionnaire for user input.
   */
  initial?: Maybe<Array<Maybe<QuestionnaireInitial>>>;
  /** Text, questions and other groups to be nested beneath a question or group. */
  item?: Maybe<Array<Maybe<QuestionnaireItem>>>;
  /**
   * An identifier that is unique within the Questionnaire allowing linkage to the
   * equivalent item in a QuestionnaireResponse resource.
   */
  linkId: Scalars['code'];
  /**
   * The maximum number of characters that are permitted in the answer to be
   * considered a "valid" QuestionnaireResponse.
   */
  maxLength?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A short label for a particular group, question or set of display text within
   * the questionnaire used for reference by the individual completing the
   * questionnaire.
   */
  prefix?: Maybe<Scalars['code']>;
  /**
   * An indication, when true, that the value cannot be changed by a human
   * respondent to the Questionnaire.
   */
  readOnly?: Maybe<Scalars['code']>;
  /**
   * An indication, if true, that the item may occur multiple times in the
   * response, collecting multiple answers for questions or multiple sets of
   * answers for groups.
   */
  repeats?: Maybe<Scalars['code']>;
  /**
   * An indication, if true, that the item must be present in a "completed"
   * QuestionnaireResponse.  If false, the item may be skipped when answering the
   * questionnaire.
   */
  required?: Maybe<Scalars['code']>;
  /**
   * The name of a section, the text of a question or text content for a display
   * item.
   */
  text?: Maybe<Scalars['code']>;
  /**
   * The type of questionnaire item this is - whether text for display, a grouping
   * of other items or a particular type of data to be captured (string, integer,
   * coded choice, etc.).
   */
  type: Scalars['code'];
};

/**
 * QuestionnaireResponse
 *     A structured set of questions and their answers. The questions are ordered and
 *     grouped into coherent subsets, corresponding to the structure of the grouping
 *     of the questionnaire being responded to.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type QuestionnaireResponse = {
  __typename?: 'QuestionnaireResponse';
  /**
   * Person who received the answers to the questions in the QuestionnaireResponse
   * and recorded them in the system.
   */
  author?: Maybe<QuestionnaireResponseAuthor>;
  /** The date and/or time that this set of answers were last changed. */
  authored?: Maybe<Scalars['code']>;
  /**
   * The order, proposal or plan that is fulfilled in whole or in part by this
   * QuestionnaireResponse.  For example, a ServiceRequest seeking an intake
   * assessment or a decision support recommendation to assess for post-partum
   * depression.
   */
  basedOn?: Maybe<Array<Maybe<QuestionnaireResponseBasedOn>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The Encounter during which this questionnaire response was created or to which
   * the creation of this record is tightly associated.
   */
  encounter?: Maybe<Encounter>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A business identifier assigned to a particular completed (or partially
   * completed) questionnaire.
   */
  identifier?: Maybe<Identifier>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A group or question item from the original questionnaire for which answers are
   * provided.
   */
  item?: Maybe<Array<Maybe<QuestionnaireResponseItem>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A procedure or observation that this questionnaire was performed as part of
   * the execution of.  For example, the surgery a checklist was executed as part
   * of.
   */
  partOf?: Maybe<Array<Maybe<QuestionnaireResponsePartOf>>>;
  /**
   * The Questionnaire that defines and organizes the questions for which answers
   * are being provided.
   */
  questionnaire?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /** The person who answered the questions about the subject. */
  source?: Maybe<QuestionnaireResponseSource>;
  /** The position of the questionnaire response within its overall lifecycle. */
  status: Scalars['code'];
  /**
   * The subject of the questionnaire response.  This could be a patient,
   * organization, practitioner, device, etc.  This is who/what the answers apply
   * to, but is not necessarily the source of information.
   */
  subject?: Maybe<Resource>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * QuestionnaireResponse.Answer
 *     A structured set of questions and their answers. The questions are ordered and
 *     grouped into coherent subsets, corresponding to the structure of the grouping
 *     of the questionnaire being responded to.
 */
export type QuestionnaireResponseAnswer = {
  __typename?: 'QuestionnaireResponseAnswer';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Nested groups and/or questions found within this particular answer. */
  item?: Maybe<Array<Maybe<QuestionnaireResponseItem>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  valueAttachment?: Maybe<Attachment>;
  /** None */
  valueBoolean?: Maybe<Scalars['code']>;
  /** None */
  valueCoding?: Maybe<Coding>;
  /** None */
  valueDate?: Maybe<Scalars['code']>;
  /** None */
  valueDateTime?: Maybe<Scalars['code']>;
  /** None */
  valueDecimal?: Maybe<Scalars['code']>;
  /** None */
  valueInteger?: Maybe<Scalars['code']>;
  /** None */
  valueQuantity?: Maybe<Quantity>;
  /** None */
  valueReference?: Maybe<Resource>;
  /** None */
  valueString?: Maybe<Scalars['code']>;
  /** None */
  valueTime?: Maybe<Scalars['code']>;
  /** None */
  valueUri?: Maybe<Scalars['code']>;
};

export type QuestionnaireResponseAuthor = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type QuestionnaireResponseBasedOn = CarePlan | ServiceRequest;

/**
 * QuestionnaireResponse.Item
 *     A structured set of questions and their answers. The questions are ordered and
 *     grouped into coherent subsets, corresponding to the structure of the grouping
 *     of the questionnaire being responded to.
 */
export type QuestionnaireResponseItem = {
  __typename?: 'QuestionnaireResponseItem';
  /** The respondent's answer(s) to the question. */
  answer?: Maybe<Array<Maybe<QuestionnaireResponseAnswer>>>;
  /**
   * A reference to an [[[ElementDefinition]]] that provides the details for the
   * item.
   */
  definition?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Questions or sub-groups nested beneath a question or group. */
  item?: Maybe<Array<Maybe<QuestionnaireResponseItem>>>;
  /**
   * The item from the Questionnaire that corresponds to this item in the
   * QuestionnaireResponse resource.
   */
  linkId: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Text that is displayed above the contents of the group or as the text of the
   * question being answered.
   */
  text?: Maybe<Scalars['code']>;
};

export type QuestionnaireResponsePartOf = Observation | Procedure;

export type QuestionnaireResponseSource = Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Range
 *     A set of ordered Quantities defined by a low and high limit.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Range = {
  __typename?: 'Range';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** The high limit. The boundary is inclusive. */
  high?: Maybe<Quantity>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The low limit. The boundary is inclusive. */
  low?: Maybe<Quantity>;
};

/**
 * Ratio
 *     A relationship of two Quantity values - expressed as a numerator and a
 *     denominator.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Ratio = {
  __typename?: 'Ratio';
  /** The value of the denominator. */
  denominator?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The value of the numerator. */
  numerator?: Maybe<Quantity>;
};

/**
 * Reference
 *     A reference from one resource to another.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Reference = {
  __typename?: 'Reference';
  /**
   * Plain text narrative that identifies the resource in addition to the resource
   * reference.
   */
  display?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * An identifier for the target resource. This is used when there is no way to
   * reference the other resource directly, either because the entity it represents
   * is not available through a FHIR server, or because there is no way for the
   * author of the resource to convert a known identifier to an actual location.
   * There is no requirement that a Reference.identifier point to something that is
   * actually exposed as a FHIR instance, but it SHALL point to a business concept
   * that would be expected to be exposed as a FHIR instance, and that instance
   * would need to be of a FHIR resource type allowed by the reference.
   */
  identifier?: Maybe<Identifier>;
  /**
   * A reference to a location at which the other resource is found. The reference
   * may be a relative reference, in which case it is relative to the service base
   * URL, or an absolute URL that resolves to the location where the resource is
   * found. The reference may be version specific or not. If the reference is not
   * to a FHIR RESTful server, then it should be assumed to be version specific.
   * Internal fragment references (start with '#') refer to contained resources.
   */
  reference?: Maybe<Scalars['code']>;
  /**
   * The expected type of the target of the reference. If both Reference.type and
   * Reference.reference are populated and Reference.reference is a FHIR URL, both
   * SHALL be consistent.
   *
   * The type is the Canonical URL of Resource Definition that is the type this
   * reference refers to. References are URLs that are relative to
   * http://hl7.org/fhir/StructureDefinition/ e.g. "Patient" is a reference to
   * http://hl7.org/fhir/StructureDefinition/Patient. Absolute URLs are only
   * allowed for logical models (and can only be used in references in logical
   * models, not resources).
   */
  type?: Maybe<Scalars['code']>;
};

/**
 * RelatedArtifact
 *     Related artifacts such as additional documentation, justification, or
 *     bibliographic references.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type RelatedArtifact = {
  __typename?: 'RelatedArtifact';
  /**
   * A bibliographic citation for the related artifact. This text SHOULD be
   * formatted according to an accepted citation format.
   */
  citation?: Maybe<Scalars['code']>;
  /**
   * A brief description of the document or knowledge resource being referenced,
   * suitable for display to a consumer.
   */
  display?: Maybe<Scalars['code']>;
  /**
   * The document being referenced, represented as an attachment. This is exclusive
   * with the resource element.
   */
  document?: Maybe<Attachment>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * A short label that can be used to reference the citation from elsewhere in the
   * containing artifact, such as a footnote index.
   */
  label?: Maybe<Scalars['code']>;
  /**
   * The related resource, such as a library, value set, profile, or other
   * knowledge resource.
   */
  resource?: Maybe<Scalars['code']>;
  /** The type of relationship to the related artifact. */
  type: Scalars['code'];
  /** A url for the artifact that can be followed to access the actual content. */
  url?: Maybe<Scalars['code']>;
};

/**
 * RelatedPerson
 *     Information about a person that is involved in the care for a patient, but who
 *     is not the target of healthcare, nor has a formal responsibility in the care
 *     process.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type RelatedPerson = {
  __typename?: 'RelatedPerson';
  /** Whether this related person record is in active use. */
  active?: Maybe<Scalars['code']>;
  /** Address where the related person can be contacted or visited. */
  address?: Maybe<Array<Maybe<Address>>>;
  /** The date on which the related person was born. */
  birthDate?: Maybe<Scalars['code']>;
  /** A language which may be used to communicate with about the patient's health. */
  communication?: Maybe<Array<Maybe<RelatedPersonCommunication>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  email?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  flatName?: Maybe<Scalars['String']>;
  /**
   * Administrative Gender - the gender that the person is considered to have for
   * administration and record keeping purposes.
   */
  gender?: Maybe<Scalars['code']>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Identifier for a person within a particular scope. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A name associated with the person. */
  name?: Maybe<Array<Maybe<HumanName>>>;
  /** The patient this person is related to. */
  patient: Patient;
  /**
   * The period of time during which this relationship is or was active. If there
   * are no dates defined, then the interval is unknown.
   */
  period?: Maybe<Period>;
  phone?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Image of the person. */
  photo?: Maybe<Array<Maybe<Attachment>>>;
  /** The nature of the relationship between a patient and the related person. */
  relationship?: Maybe<Array<Maybe<CodeableConcept>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** A contact detail for the person, e.g. a telephone number or an email address. */
  telecom?: Maybe<Array<Maybe<ContactPoint>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * RelatedPerson.Communication
 *     Information about a person that is involved in the care for a patient, but who
 *     is not the target of healthcare, nor has a formal responsibility in the care
 *     process.
 */
export type RelatedPersonCommunication = {
  __typename?: 'RelatedPersonCommunication';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The ISO-639-1 alpha 2 code in lower case for the language, optionally followed
   * by a hyphen and the ISO-3166-1 alpha 2 code for the region in upper case; e.g.
   * "en" for English, or "en-US" for American English versus "en-EN" for England
   * English.
   */
  language: CodeableConcept;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Indicates whether or not the patient prefers this language (over other
   * languages he masters up a certain level).
   */
  preferred?: Maybe<Scalars['code']>;
};

/**
 * RequestGroup
 *     A group of related requests that can be used to capture intended activities
 *     that have inter-dependencies such as "give this medication after that one".
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type RequestGroup = {
  __typename?: 'RequestGroup';
  /** The actions, if any, produced by the evaluation of the artifact. */
  action?: Maybe<Array<Maybe<RequestGroupAction>>>;
  /** Provides a reference to the author of the request group. */
  author?: Maybe<RequestGroupAuthor>;
  /** Indicates when the request group was created. */
  authoredOn?: Maybe<Scalars['code']>;
  /**
   * A plan, proposal or order that is fulfilled in whole or in part by this
   * request.
   */
  basedOn?: Maybe<Array<Maybe<Resource>>>;
  /** A code that identifies what the overall request group is. */
  code?: Maybe<CodeableConcept>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Describes the context of the request group, if any. */
  encounter?: Maybe<Encounter>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A shared identifier common to all requests that were authorized more or less
   * simultaneously by a single author, representing the identifier of the
   * requisition, prescription or similar form.
   */
  groupIdentifier?: Maybe<Identifier>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Allows a service to provide a unique, business identifier for the request. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A canonical URL referencing a FHIR-defined protocol, guideline, orderset or
   * other definition that is adhered to in whole or in part by this request.
   */
  instantiatesCanonical?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A URL referencing an externally defined protocol, guideline, orderset or other
   * definition that is adhered to in whole or in part by this request.
   */
  instantiatesUri?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Indicates the level of authority/intentionality associated with the request
   * and where the request fits into the workflow chain.
   */
  intent: Scalars['code'];
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Provides a mechanism to communicate additional information about the response. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /**
   * Indicates how quickly the request should be addressed with respect to other
   * requests.
   */
  priority?: Maybe<Scalars['code']>;
  /** Describes the reason for the request group in coded or textual form. */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Indicates another resource whose existence justifies this request group. */
  reasonReference?: Maybe<Array<Maybe<RequestGroupReasonReference>>>;
  /**
   * Completed or terminated request(s) whose function is taken by this new
   * request.
   */
  replaces?: Maybe<Array<Maybe<Resource>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The current state of the request. For request groups, the status reflects the
   * status of all the requests in the group.
   */
  status: Scalars['code'];
  /** The subject for which the request group was created. */
  subject?: Maybe<RequestGroupSubject>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * RequestGroup.Action
 *     A group of related requests that can be used to capture intended activities
 *     that have inter-dependencies such as "give this medication after that one".
 */
export type RequestGroupAction = {
  __typename?: 'RequestGroupAction';
  /** Sub actions. */
  action?: Maybe<Array<Maybe<RequestGroupAction>>>;
  /** Defines whether the action can be selected multiple times. */
  cardinalityBehavior?: Maybe<Scalars['code']>;
  /**
   * A code that provides meaning for the action or action group. For example, a
   * section may have a LOINC code for a section of a documentation template.
   */
  code?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * An expression that describes applicability criteria, or start/stop conditions
   * for the action.
   */
  condition?: Maybe<Array<Maybe<RequestGroupCondition>>>;
  /**
   * A short description of the action used to provide a summary to display to the
   * user.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * Didactic or other informational resources associated with the action that can
   * be provided to the CDS recipient. Information resources can include inline
   * text commentary and links to web resources.
   */
  documentation?: Maybe<Array<Maybe<RelatedArtifact>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Defines the grouping behavior for the action and its children. */
  groupingBehavior?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The participant that should perform or be responsible for this action. */
  participant?: Maybe<Array<Maybe<RequestGroupActionParticipant>>>;
  /** Defines whether the action should usually be preselected. */
  precheckBehavior?: Maybe<Scalars['code']>;
  /** A user-visible prefix for the action. */
  prefix?: Maybe<Scalars['code']>;
  /**
   * Indicates how quickly the action should be addressed with respect to other
   * actions.
   */
  priority?: Maybe<Scalars['code']>;
  /**
   * A relationship to another action such as "before" or "30-60 minutes after
   * start of".
   */
  relatedAction?: Maybe<Array<Maybe<RequestGroupRelatedAction>>>;
  /** Defines expectations around whether an action is required. */
  requiredBehavior?: Maybe<Scalars['code']>;
  /** The resource that is the target of the action (e.g. CommunicationRequest). */
  resource?: Maybe<Resource>;
  /** Defines the selection behavior for the action and its children. */
  selectionBehavior?: Maybe<Scalars['code']>;
  /**
   * A text equivalent of the action to be performed. This provides a human-
   * interpretable description of the action when the definition is consumed by a
   * system that might not be capable of interpreting it dynamically.
   */
  textEquivalent?: Maybe<Scalars['code']>;
  /** None */
  timingAge?: Maybe<Quantity>;
  /** None */
  timingDateTime?: Maybe<Scalars['code']>;
  /** None */
  timingDuration?: Maybe<Quantity>;
  /** None */
  timingPeriod?: Maybe<Period>;
  /** None */
  timingRange?: Maybe<Range>;
  /** None */
  timingTiming?: Maybe<Timing>;
  /** The title of the action displayed to a user. */
  title?: Maybe<Scalars['code']>;
  /** The type of action to perform (create, update, remove). */
  type?: Maybe<CodeableConcept>;
};

export type RequestGroupActionParticipant = Device | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type RequestGroupAuthor = Device | Practitioner | PractitionerRole;

/**
 * RequestGroup.Condition
 *     A group of related requests that can be used to capture intended activities
 *     that have inter-dependencies such as "give this medication after that one".
 */
export type RequestGroupCondition = {
  __typename?: 'RequestGroupCondition';
  /**
   * An expression that returns true or false, indicating whether or not the
   * condition is satisfied.
   */
  expression?: Maybe<Expression>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The kind of condition. */
  kind: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type RequestGroupReasonReference = Condition | DiagnosticReport | DocumentReference | Observation;

/**
 * RequestGroup.RelatedAction
 *     A group of related requests that can be used to capture intended activities
 *     that have inter-dependencies such as "give this medication after that one".
 */
export type RequestGroupRelatedAction = {
  __typename?: 'RequestGroupRelatedAction';
  /** The element id of the action this is related to. */
  actionId: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  offsetDuration?: Maybe<Quantity>;
  /** None */
  offsetRange?: Maybe<Range>;
  /** The relationship of this action to the related action. */
  relationship: Scalars['code'];
};

export type RequestGroupSubject = Group | Patient;

/**
 * ResearchDefinition
 *     The ResearchDefinition resource describes the conditional state (population
 *     and any exposures being compared within the population) and outcome (if
 *     specified) that the knowledge (evidence, assertion, recommendation) is about.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ResearchDefinition = {
  __typename?: 'ResearchDefinition';
  /**
   * The date on which the resource content was approved by the publisher. Approval
   * happens once when the content is officially approved for usage.
   */
  approvalDate?: Maybe<Scalars['code']>;
  /**
   * An individiual or organization primarily involved in the creation and
   * maintenance of the content.
   */
  author?: Maybe<Array<Maybe<ContactDetail>>>;
  /** A human-readable string to clarify or explain concepts about the resource. */
  comment?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the research definition and/or its contents.
   * Copyright statements are generally legal restrictions on the use and
   * publishing of the research definition.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the research definition was published.
   * The date must change when the business version changes and it must change if
   * the status code changes. In addition, it should change when the substantive
   * content of the research definition changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the research definition from a
   * consumer's perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * An individual or organization primarily responsible for internal coherence of
   * the content.
   */
  editor?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * The period during which the research definition content was or is planned to
   * be in active use.
   */
  effectivePeriod?: Maybe<Period>;
  /**
   * An individual or organization responsible for officially endorsing the content
   * for use in some setting.
   */
  endorser?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * A Boolean value to indicate that this research definition is authored for
   * testing purposes (or education/evaluation/marketing) and is not intended to be
   * used for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * A reference to a ResearchElementDefinition resource that defines the exposure
   * for the research.
   */
  exposure?: Maybe<ResearchElementDefinition>;
  /**
   * A reference to a ResearchElementDefinition resource that defines the
   * exposureAlternative for the research.
   */
  exposureAlternative?: Maybe<ResearchElementDefinition>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this research definition when it
   * is represented in other formats, or referenced in a specification, model,
   * design or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A legal or geographic region in which the research definition is intended to
   * be used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The date on which the resource content was last reviewed. Review happens
   * periodically after approval but does not change the original approval date.
   */
  lastReviewDate?: Maybe<Scalars['code']>;
  /**
   * A reference to a Library resource containing the formal logic used by the
   * ResearchDefinition.
   */
  library?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the research definition. This name should
   * be usable as an identifier for the module by machine processing applications
   * such as code generation.
   */
  name?: Maybe<Scalars['code']>;
  /**
   * A reference to a ResearchElementDefinition resomece that defines the outcome
   * for the research.
   */
  outcome?: Maybe<ResearchElementDefinition>;
  /**
   * A reference to a ResearchElementDefinition resource that defines the
   * population for the research.
   */
  population: ResearchElementDefinition;
  /**
   * The name of the organization or individual that published the research
   * definition.
   */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this research definition is needed and why it has been
   * designed as it has.
   */
  purpose?: Maybe<Scalars['code']>;
  /**
   * Related artifacts such as additional documentation, justification, or
   * bibliographic references.
   */
  relatedArtifact?: Maybe<Array<Maybe<RelatedArtifact>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * An individual or organization primarily responsible for review of some aspect
   * of the content.
   */
  reviewer?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * The short title provides an alternate title for use in informal descriptive
   * contexts where the full, formal title is not necessary.
   */
  shortTitle?: Maybe<Scalars['code']>;
  /**
   * The status of this research definition. Enables tracking the life-cycle of the
   * content.
   */
  status: Scalars['code'];
  /** None */
  subjectCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  subjectReference?: Maybe<Group>;
  /**
   * An explanatory or alternate title for the ResearchDefinition giving additional
   * information about its content.
   */
  subtitle?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the research definition. */
  title?: Maybe<Scalars['code']>;
  /**
   * Descriptive topics related to the content of the ResearchDefinition. Topics
   * provide a high-level categorization grouping types of ResearchDefinitions that
   * can be useful for filtering and searching.
   */
  topic?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * An absolute URI that is used to identify this research definition when it is
   * referenced in a specification, model, design or an instance; also called its
   * canonical identifier. This SHOULD be globally unique and SHOULD be a literal
   * address at which at which an authoritative instance of this research
   * definition is (or will be) published. This URL can be the target of a
   * canonical reference. It SHALL remain the same when the research definition is
   * stored on different servers.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * A detailed description, from a clinical perspective, of how the
   * ResearchDefinition is used.
   */
  usage?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate research
   * definition instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the research
   * definition when it is referenced in a specification, model, design or
   * instance. This is an arbitrary value managed by the research definition author
   * and is not expected to be globally unique. For example, it might be a
   * timestamp (e.g. yyyymmdd) if a managed version is not available. There is also
   * no expectation that versions can be placed in a lexicographical sequence. To
   * provide a version consistent with the Decision Support Service specification,
   * use the format Major.Minor.Revision (e.g. 1.0.0). For more information on
   * versioning knowledge assets, refer to the Decision Support Service
   * specification. Note that a version is required for non-experimental active
   * artifacts.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * ResearchElementDefinition
 *     The ResearchElementDefinition resource describes a "PICO" element that
 *     knowledge (evidence, assertion, recommendation) is about.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ResearchElementDefinition = {
  __typename?: 'ResearchElementDefinition';
  /**
   * The date on which the resource content was approved by the publisher. Approval
   * happens once when the content is officially approved for usage.
   */
  approvalDate?: Maybe<Scalars['code']>;
  /**
   * An individiual or organization primarily involved in the creation and
   * maintenance of the content.
   */
  author?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * A characteristic that defines the members of the research element. Multiple
   * characteristics are applied with "and" semantics.
   */
  characteristic: Array<Maybe<ResearchElementDefinitionCharacteristic>>;
  /** A human-readable string to clarify or explain concepts about the resource. */
  comment?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the research element definition and/or its
   * contents. Copyright statements are generally legal restrictions on the use and
   * publishing of the research element definition.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the research element definition was
   * published. The date must change when the business version changes and it must
   * change if the status code changes. In addition, it should change when the
   * substantive content of the research element definition changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the research element definition
   * from a consumer's perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * An individual or organization primarily responsible for internal coherence of
   * the content.
   */
  editor?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * The period during which the research element definition content was or is
   * planned to be in active use.
   */
  effectivePeriod?: Maybe<Period>;
  /**
   * An individual or organization responsible for officially endorsing the content
   * for use in some setting.
   */
  endorser?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * A Boolean value to indicate that this research element definition is authored
   * for testing purposes (or education/evaluation/marketing) and is not intended
   * to be used for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this research element definition
   * when it is represented in other formats, or referenced in a specification,
   * model, design or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A legal or geographic region in which the research element definition is
   * intended to be used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The date on which the resource content was last reviewed. Review happens
   * periodically after approval but does not change the original approval date.
   */
  lastReviewDate?: Maybe<Scalars['code']>;
  /**
   * A reference to a Library resource containing the formal logic used by the
   * ResearchElementDefinition.
   */
  library?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the research element definition. This name
   * should be usable as an identifier for the module by machine processing
   * applications such as code generation.
   */
  name?: Maybe<Scalars['code']>;
  /**
   * The name of the organization or individual that published the research element
   * definition.
   */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this research element definition is needed and why it has
   * been designed as it has.
   */
  purpose?: Maybe<Scalars['code']>;
  /**
   * Related artifacts such as additional documentation, justification, or
   * bibliographic references.
   */
  relatedArtifact?: Maybe<Array<Maybe<RelatedArtifact>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * An individual or organization primarily responsible for review of some aspect
   * of the content.
   */
  reviewer?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * The short title provides an alternate title for use in informal descriptive
   * contexts where the full, formal title is not necessary.
   */
  shortTitle?: Maybe<Scalars['code']>;
  /**
   * The status of this research element definition. Enables tracking the life-
   * cycle of the content.
   */
  status: Scalars['code'];
  /** None */
  subjectCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  subjectReference?: Maybe<Group>;
  /**
   * An explanatory or alternate title for the ResearchElementDefinition giving
   * additional information about its content.
   */
  subtitle?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the research element definition. */
  title?: Maybe<Scalars['code']>;
  /**
   * Descriptive topics related to the content of the ResearchElementDefinition.
   * Topics provide a high-level categorization grouping types of
   * ResearchElementDefinitions that can be useful for filtering and searching.
   */
  topic?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The type of research element, a population, an exposure, or an outcome. */
  type: Scalars['code'];
  /**
   * An absolute URI that is used to identify this research element definition when
   * it is referenced in a specification, model, design or an instance; also called
   * its canonical identifier. This SHOULD be globally unique and SHOULD be a
   * literal address at which at which an authoritative instance of this research
   * element definition is (or will be) published. This URL can be the target of a
   * canonical reference. It SHALL remain the same when the research element
   * definition is stored on different servers.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * A detailed description, from a clinical perspective, of how the
   * ResearchElementDefinition is used.
   */
  usage?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate research
   * element definition instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /** The type of the outcome (e.g. Dichotomous, Continuous, or Descriptive). */
  variableType?: Maybe<Scalars['code']>;
  /**
   * The identifier that is used to identify this version of the research element
   * definition when it is referenced in a specification, model, design or
   * instance. This is an arbitrary value managed by the research element
   * definition author and is not expected to be globally unique. For example, it
   * might be a timestamp (e.g. yyyymmdd) if a managed version is not available.
   * There is also no expectation that versions can be placed in a lexicographical
   * sequence. To provide a version consistent with the Decision Support Service
   * specification, use the format Major.Minor.Revision (e.g. 1.0.0). For more
   * information on versioning knowledge assets, refer to the Decision Support
   * Service specification. Note that a version is required for non-experimental
   * active artifacts.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * ResearchElementDefinition.Characteristic
 *     The ResearchElementDefinition resource describes a "PICO" element that
 *     knowledge (evidence, assertion, recommendation) is about.
 */
export type ResearchElementDefinitionCharacteristic = {
  __typename?: 'ResearchElementDefinitionCharacteristic';
  /** None */
  definitionCanonical?: Maybe<Scalars['code']>;
  /** None */
  definitionCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  definitionDataRequirement?: Maybe<DataRequirement>;
  /** None */
  definitionExpression?: Maybe<Expression>;
  /** When true, members with this characteristic are excluded from the element. */
  exclude?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  participantEffectiveDateTime?: Maybe<Scalars['code']>;
  /** A narrative description of the time period the study covers. */
  participantEffectiveDescription?: Maybe<Scalars['code']>;
  /** None */
  participantEffectiveDuration?: Maybe<Quantity>;
  /** Indicates how elements are aggregated within the study effective period. */
  participantEffectiveGroupMeasure?: Maybe<Scalars['code']>;
  /** None */
  participantEffectivePeriod?: Maybe<Period>;
  /** Indicates duration from the participant's study entry. */
  participantEffectiveTimeFromStart?: Maybe<Quantity>;
  /** None */
  participantEffectiveTiming?: Maybe<Timing>;
  /** None */
  studyEffectiveDateTime?: Maybe<Scalars['code']>;
  /** A narrative description of the time period the study covers. */
  studyEffectiveDescription?: Maybe<Scalars['code']>;
  /** None */
  studyEffectiveDuration?: Maybe<Quantity>;
  /** Indicates how elements are aggregated within the study effective period. */
  studyEffectiveGroupMeasure?: Maybe<Scalars['code']>;
  /** None */
  studyEffectivePeriod?: Maybe<Period>;
  /** Indicates duration from the study initiation. */
  studyEffectiveTimeFromStart?: Maybe<Quantity>;
  /** None */
  studyEffectiveTiming?: Maybe<Timing>;
  /** Specifies the UCUM unit for the outcome. */
  unitOfMeasure?: Maybe<CodeableConcept>;
  /**
   * Use UsageContext to define the members of the population, such as Age Ranges,
   * Genders, Settings.
   */
  usageContext?: Maybe<Array<Maybe<UsageContext>>>;
};

/**
 * ResearchStudy
 *     A process where a researcher or organization plans and then executes a series
 *     of steps intended to increase the field of healthcare-related knowledge.  This
 *     includes studies of safety, efficacy, comparative effectiveness and other
 *     information about medications, devices, therapies and other interventional and
 *     investigative techniques.  A ResearchStudy involves the gathering of
 *     information about human or animal subjects.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ResearchStudy = {
  __typename?: 'ResearchStudy';
  /**
   * Describes an expected sequence of events for one of the participants of a
   * study.  E.g. Exposure to drug A, wash-out, exposure to drug B, wash-out,
   * follow-up.
   */
  arm?: Maybe<Array<Maybe<ResearchStudyArm>>>;
  /**
   * Codes categorizing the type of study such as investigational vs.
   * observational, type of blinding, type of randomization, safety vs. efficacy,
   * etc.
   */
  category?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The condition that is the focus of the study.  For example, In a study to
   * examine risk factors for Lupus, might have as an inclusion criterion "healthy
   * volunteer", but the target condition code would be a Lupus SNOMED code.
   */
  condition?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Contact details to assist a user in learning more about or engaging with the
   * study.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** A full description of how the study is being conducted. */
  description?: Maybe<Scalars['code']>;
  /**
   * Reference to a Group that defines the criteria for and quantity of subjects
   * participating in the study.  E.g. " 200 female Europeans between the ages of
   * 20 and 45 with early onset diabetes".
   */
  enrollment?: Maybe<Array<Maybe<Group>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The medication(s), food(s), therapy(ies), device(s) or other concerns or
   * interventions that the study is seeking to gain more information about.
   */
  focus?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Identifiers assigned to this research study by the sponsor or other systems. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** Key terms to aid in searching for or filtering the study. */
  keyword?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** Indicates a country, state or other region where the study is taking place. */
  location?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Comments made about the study by the performer, subject or other participants. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /**
   * A goal that the study is aiming to achieve in terms of a scientific question
   * to be answered by the analysis of data collected during the study.
   */
  objective?: Maybe<Array<Maybe<ResearchStudyObjective>>>;
  /** A larger research study of which this particular study is a component or step. */
  partOf?: Maybe<Array<Maybe<ResearchStudy>>>;
  /**
   * Identifies the start date and the expected (or actual, depending on status)
   * end date for the study.
   */
  period?: Maybe<Period>;
  /**
   * The stage in the progression of a therapy from initial experimental use in
   * humans in clinical trials to post-market evaluation.
   */
  phase?: Maybe<CodeableConcept>;
  /**
   * The type of study based upon the intent of the study's activities. A
   * classification of the intent of the study.
   */
  primaryPurposeType?: Maybe<CodeableConcept>;
  /**
   * A researcher in a study who oversees multiple aspects of the study, such as
   * concept development, protocol writing, protocol submission for IRB approval,
   * participant recruitment, informed consent, data collection, analysis,
   * interpretation and presentation.
   */
  principalInvestigator?: Maybe<ResearchStudyPrincipalInvestigator>;
  /**
   * The set of steps expected to be performed as part of the execution of the
   * study.
   */
  protocol?: Maybe<Array<Maybe<PlanDefinition>>>;
  /** A description and/or code explaining the premature termination of the study. */
  reasonStopped?: Maybe<CodeableConcept>;
  /** Citations, references and other related documents. */
  relatedArtifact?: Maybe<Array<Maybe<RelatedArtifact>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** A facility in which study activities are conducted. */
  site?: Maybe<Array<Maybe<Location>>>;
  /**
   * An organization that initiates the investigation and is legally responsible
   * for the study.
   */
  sponsor?: Maybe<Organization>;
  /** The current state of the study. */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive user-friendly label for the study. */
  title?: Maybe<Scalars['code']>;
};

/**
 * ResearchStudy.Arm
 *     A process where a researcher or organization plans and then executes a series
 *     of steps intended to increase the field of healthcare-related knowledge.  This
 *     includes studies of safety, efficacy, comparative effectiveness and other
 *     information about medications, devices, therapies and other interventional and
 *     investigative techniques.  A ResearchStudy involves the gathering of
 *     information about human or animal subjects.
 */
export type ResearchStudyArm = {
  __typename?: 'ResearchStudyArm';
  /**
   * A succinct description of the path through the study that would be followed by
   * a subject adhering to this arm.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Unique, human-readable label for this arm of the study. */
  name: Scalars['code'];
  /**
   * Categorization of study arm, e.g. experimental, active comparator, placebo
   * comparater.
   */
  type?: Maybe<CodeableConcept>;
};

/**
 * ResearchStudy.Objective
 *     A process where a researcher or organization plans and then executes a series
 *     of steps intended to increase the field of healthcare-related knowledge.  This
 *     includes studies of safety, efficacy, comparative effectiveness and other
 *     information about medications, devices, therapies and other interventional and
 *     investigative techniques.  A ResearchStudy involves the gathering of
 *     information about human or animal subjects.
 */
export type ResearchStudyObjective = {
  __typename?: 'ResearchStudyObjective';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Unique, human-readable label for this objective of the study. */
  name?: Maybe<Scalars['code']>;
  /** The kind of study objective. */
  type?: Maybe<CodeableConcept>;
};

export type ResearchStudyPrincipalInvestigator = Practitioner | PractitionerRole;

/**
 * ResearchSubject
 *     A physical entity which is the primary unit of operational and/or
 *     administrative interest in a study.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ResearchSubject = {
  __typename?: 'ResearchSubject';
  /**
   * The name of the arm in the study the subject actually followed as part of this
   * study.
   */
  actualArm?: Maybe<Scalars['code']>;
  /**
   * The name of the arm in the study the subject is expected to follow as part of
   * this study.
   */
  assignedArm?: Maybe<Scalars['code']>;
  /** A record of the patient's informed agreement to participate in the study. */
  consent?: Maybe<Consent>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Identifiers assigned to this research subject for a study. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The record of the person or animal who is involved in the study. */
  individual: Patient;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The dates the subject began and ended their participation in the study. */
  period?: Maybe<Period>;
  resourceType?: Maybe<Scalars['String']>;
  /** The current state of the subject. */
  status: Scalars['code'];
  /** Reference to the study the subject is participating in. */
  study: ResearchStudy;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * Resource
 *     This is the base resource type for everything.
 */
export type Resource = {
  __typename?: 'Resource';
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  resourceType?: Maybe<Scalars['String']>;
};

export type ResourceContainer = {
  __typename?: 'ResourceContainer';
  id_?: Maybe<Scalars['id']>;
  meta?: Maybe<Meta>;
};

/**
 * RiskAssessment
 *     An assessment of the likely outcome(s) for a patient or other subject as well
 *     as the likelihood of each outcome.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type RiskAssessment = {
  __typename?: 'RiskAssessment';
  /** A reference to the request that is fulfilled by this risk assessment. */
  basedOn?: Maybe<Resource>;
  /**
   * Indicates the source data considered as part of the assessment (for example,
   * FamilyHistory, Observations, Procedures, Conditions, etc.).
   */
  basis?: Maybe<Array<Maybe<Resource>>>;
  /** The type of the risk assessment performed. */
  code?: Maybe<CodeableConcept>;
  /**
   * For assessments or prognosis specific to a particular condition, indicates the
   * condition being assessed.
   */
  condition?: Maybe<Condition>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The encounter where the assessment was performed. */
  encounter?: Maybe<Encounter>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Business identifier assigned to the risk assessment. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /** The algorithm, process or mechanism used to evaluate the risk. */
  method?: Maybe<CodeableConcept>;
  /**
   * A description of the steps that might be taken to reduce the identified
   * risk(s).
   */
  mitigation?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Additional comments about the risk assessment. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** None */
  occurrenceDateTime?: Maybe<Scalars['code']>;
  /** None */
  occurrencePeriod?: Maybe<Period>;
  /**
   * A reference to a resource that this risk assessment is part of, such as a
   * Procedure.
   */
  parent?: Maybe<Resource>;
  /** The provider or software application that performed the assessment. */
  performer?: Maybe<RiskAssessmentPerformer>;
  /** Describes the expected outcome for the subject. */
  prediction?: Maybe<Array<Maybe<RiskAssessmentPrediction>>>;
  /** The reason the risk assessment was performed. */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Resources supporting the reason the risk assessment was performed. */
  reasonReference?: Maybe<Array<Maybe<RiskAssessmentReasonReference>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** The status of the RiskAssessment, using the same statuses as an Observation. */
  status: Scalars['code'];
  /** The patient or group the risk assessment applies to. */
  subject: RiskAssessmentSubject;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type RiskAssessmentPerformer = Device | Practitioner | PractitionerRole;

/**
 * RiskAssessment.Prediction
 *     An assessment of the likely outcome(s) for a patient or other subject as well
 *     as the likelihood of each outcome.
 */
export type RiskAssessmentPrediction = {
  __typename?: 'RiskAssessmentPrediction';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * One of the potential outcomes for the patient (e.g. remission, death,  a
   * particular condition).
   */
  outcome?: Maybe<CodeableConcept>;
  /** None */
  probabilityDecimal?: Maybe<Scalars['code']>;
  /** None */
  probabilityRange?: Maybe<Range>;
  /**
   * Indicates how likely the outcome is (in the specified timeframe), expressed as
   * a qualitative value (e.g. low, medium, or high).
   */
  qualitativeRisk?: Maybe<CodeableConcept>;
  /** Additional information explaining the basis for the prediction. */
  rationale?: Maybe<Scalars['code']>;
  /**
   * Indicates the risk for this particular subject (with their specific
   * characteristics) divided by the risk of the population in general.  (Numbers
   * greater than 1 = higher risk than the population, numbers less than 1 = lower
   * risk.).
   */
  relativeRisk?: Maybe<Scalars['code']>;
  /** None */
  whenPeriod?: Maybe<Period>;
  /** None */
  whenRange?: Maybe<Range>;
};

export type RiskAssessmentReasonReference = Condition | DiagnosticReport | DocumentReference | Observation;

export type RiskAssessmentSubject = Group | Patient;

/**
 * RiskEvidenceSynthesis
 *     The RiskEvidenceSynthesis resource describes the likelihood of an outcome in a
 *     population plus exposure state where the risk estimate is derived from a
 *     combination of research studies.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type RiskEvidenceSynthesis = {
  __typename?: 'RiskEvidenceSynthesis';
  /**
   * The date on which the resource content was approved by the publisher. Approval
   * happens once when the content is officially approved for usage.
   */
  approvalDate?: Maybe<Scalars['code']>;
  /**
   * An individiual or organization primarily involved in the creation and
   * maintenance of the content.
   */
  author?: Maybe<Array<Maybe<ContactDetail>>>;
  /** A description of the certainty of the risk estimate. */
  certainty?: Maybe<Array<Maybe<RiskEvidenceSynthesisCertainty>>>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the risk evidence synthesis and/or its
   * contents. Copyright statements are generally legal restrictions on the use and
   * publishing of the risk evidence synthesis.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the risk evidence synthesis was
   * published. The date must change when the business version changes and it must
   * change if the status code changes. In addition, it should change when the
   * substantive content of the risk evidence synthesis changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the risk evidence synthesis from a
   * consumer's perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * An individual or organization primarily responsible for internal coherence of
   * the content.
   */
  editor?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * The period during which the risk evidence synthesis content was or is planned
   * to be in active use.
   */
  effectivePeriod?: Maybe<Period>;
  /**
   * An individual or organization responsible for officially endorsing the content
   * for use in some setting.
   */
  endorser?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * A reference to a EvidenceVariable resource that defines the exposure for the
   * research.
   */
  exposure?: Maybe<EvidenceVariable>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this risk evidence synthesis when
   * it is represented in other formats, or referenced in a specification, model,
   * design or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A legal or geographic region in which the risk evidence synthesis is intended
   * to be used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The date on which the resource content was last reviewed. Review happens
   * periodically after approval but does not change the original approval date.
   */
  lastReviewDate?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the risk evidence synthesis. This name
   * should be usable as an identifier for the module by machine processing
   * applications such as code generation.
   */
  name?: Maybe<Scalars['code']>;
  /** A human-readable string to clarify or explain concepts about the resource. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /**
   * A reference to a EvidenceVariable resomece that defines the outcome for the
   * research.
   */
  outcome: EvidenceVariable;
  /**
   * A reference to a EvidenceVariable resource that defines the population for the
   * research.
   */
  population: EvidenceVariable;
  /**
   * The name of the organization or individual that published the risk evidence
   * synthesis.
   */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Related artifacts such as additional documentation, justification, or
   * bibliographic references.
   */
  relatedArtifact?: Maybe<Array<Maybe<RelatedArtifact>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * An individual or organization primarily responsible for review of some aspect
   * of the content.
   */
  reviewer?: Maybe<Array<Maybe<ContactDetail>>>;
  /** The estimated risk of the outcome. */
  riskEstimate?: Maybe<RiskEvidenceSynthesisRiskEstimate>;
  /** A description of the size of the sample involved in the synthesis. */
  sampleSize?: Maybe<RiskEvidenceSynthesisSampleSize>;
  /**
   * The status of this risk evidence synthesis. Enables tracking the life-cycle of
   * the content.
   */
  status: Scalars['code'];
  /** Type of study eg randomized trial. */
  studyType?: Maybe<CodeableConcept>;
  /** Type of synthesis eg meta-analysis. */
  synthesisType?: Maybe<CodeableConcept>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the risk evidence synthesis. */
  title?: Maybe<Scalars['code']>;
  /**
   * Descriptive topics related to the content of the RiskEvidenceSynthesis. Topics
   * provide a high-level categorization grouping types of EffectEvidenceSynthesiss
   * that can be useful for filtering and searching.
   */
  topic?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * An absolute URI that is used to identify this risk evidence synthesis when it
   * is referenced in a specification, model, design or an instance; also called
   * its canonical identifier. This SHOULD be globally unique and SHOULD be a
   * literal address at which at which an authoritative instance of this risk
   * evidence synthesis is (or will be) published. This URL can be the target of a
   * canonical reference. It SHALL remain the same when the risk evidence synthesis
   * is stored on different servers.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate risk
   * evidence synthesis instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the risk evidence
   * synthesis when it is referenced in a specification, model, design or instance.
   * This is an arbitrary value managed by the risk evidence synthesis author and
   * is not expected to be globally unique. For example, it might be a timestamp
   * (e.g. yyyymmdd) if a managed version is not available. There is also no
   * expectation that versions can be placed in a lexicographical sequence.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * RiskEvidenceSynthesis.Certainty
 *     The RiskEvidenceSynthesis resource describes the likelihood of an outcome in a
 *     population plus exposure state where the risk estimate is derived from a
 *     combination of research studies.
 */
export type RiskEvidenceSynthesisCertainty = {
  __typename?: 'RiskEvidenceSynthesisCertainty';
  /** A description of a component of the overall certainty. */
  certaintySubcomponent?: Maybe<Array<Maybe<RiskEvidenceSynthesisCertaintySubcomponent>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A human-readable string to clarify or explain concepts about the resource. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** A rating of the certainty of the effect estimate. */
  rating?: Maybe<Array<Maybe<CodeableConcept>>>;
};

/**
 * RiskEvidenceSynthesis.CertaintySubcomponent
 *     The RiskEvidenceSynthesis resource describes the likelihood of an outcome in a
 *     population plus exposure state where the risk estimate is derived from a
 *     combination of research studies.
 */
export type RiskEvidenceSynthesisCertaintySubcomponent = {
  __typename?: 'RiskEvidenceSynthesisCertaintySubcomponent';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A human-readable string to clarify or explain concepts about the resource. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** A rating of a subcomponent of rating certainty. */
  rating?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Type of subcomponent of certainty rating. */
  type?: Maybe<CodeableConcept>;
};

/**
 * RiskEvidenceSynthesis.PrecisionEstimate
 *     The RiskEvidenceSynthesis resource describes the likelihood of an outcome in a
 *     population plus exposure state where the risk estimate is derived from a
 *     combination of research studies.
 */
export type RiskEvidenceSynthesisPrecisionEstimate = {
  __typename?: 'RiskEvidenceSynthesisPrecisionEstimate';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Lower bound of confidence interval. */
  from?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Use 95 for a 95% confidence interval. */
  level?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Upper bound of confidence interval. */
  to?: Maybe<Scalars['code']>;
  /** Examples include confidence interval and interquartile range. */
  type?: Maybe<CodeableConcept>;
};

/**
 * RiskEvidenceSynthesis.RiskEstimate
 *     The RiskEvidenceSynthesis resource describes the likelihood of an outcome in a
 *     population plus exposure state where the risk estimate is derived from a
 *     combination of research studies.
 */
export type RiskEvidenceSynthesisRiskEstimate = {
  __typename?: 'RiskEvidenceSynthesisRiskEstimate';
  /** The sample size for the group that was measured for this risk estimate. */
  denominatorCount?: Maybe<Scalars['code']>;
  /** Human-readable summary of risk estimate. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The number of group members with the outcome of interest. */
  numeratorCount?: Maybe<Scalars['code']>;
  /** A description of the precision of the estimate for the effect. */
  precisionEstimate?: Maybe<Array<Maybe<RiskEvidenceSynthesisPrecisionEstimate>>>;
  /** Examples include proportion and mean. */
  type?: Maybe<CodeableConcept>;
  /** Specifies the UCUM unit for the outcome. */
  unitOfMeasure?: Maybe<CodeableConcept>;
  /** The point estimate of the risk estimate. */
  value?: Maybe<Scalars['code']>;
};

/**
 * RiskEvidenceSynthesis.SampleSize
 *     The RiskEvidenceSynthesis resource describes the likelihood of an outcome in a
 *     population plus exposure state where the risk estimate is derived from a
 *     combination of research studies.
 */
export type RiskEvidenceSynthesisSampleSize = {
  __typename?: 'RiskEvidenceSynthesisSampleSize';
  /** Human-readable summary of sample size. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Number of participants included in this evidence synthesis. */
  numberOfParticipants?: Maybe<Scalars['code']>;
  /** Number of studies included in this evidence synthesis. */
  numberOfStudies?: Maybe<Scalars['code']>;
};

/**
 * SampledData
 *     A series of measurements taken by a device, with upper and lower limits. There
 *     may be more than one dimension in the data.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type SampledData = {
  __typename?: 'SampledData';
  /**
   * A series of data points which are decimal values separated by a single space
   * (character u20). The special values "E" (error), "L" (below detection limit)
   * and "U" (above detection limit) can also be used in place of a decimal value.
   */
  data?: Maybe<Scalars['code']>;
  /**
   * The number of sample points at each time point. If this value is greater than
   * one, then the dimensions will be interlaced - all the sample points for a
   * point in time will be recorded at once.
   */
  dimensions: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A correction factor that is applied to the sampled data points before they are
   * added to the origin.
   */
  factor?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The lower limit of detection of the measured points. This is needed if any of
   * the data points have the value "L" (lower than detection limit).
   */
  lowerLimit?: Maybe<Scalars['code']>;
  /**
   * The base quantity that a measured value of zero represents. In addition, this
   * provides the units of the entire measurement series.
   */
  origin: Quantity;
  /** The length of time between sampling times, measured in milliseconds. */
  period: Scalars['code'];
  /**
   * The upper limit of detection of the measured points. This is needed if any of
   * the data points have the value "U" (higher than detection limit).
   */
  upperLimit?: Maybe<Scalars['code']>;
};

/**
 * Schedule
 *     A container for slots of time that may be available for booking appointments.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Schedule = {
  __typename?: 'Schedule';
  /**
   * Whether this schedule record is in active use or should not be used (such as
   * was entered in error).
   */
  active?: Maybe<Scalars['code']>;
  /**
   * Slots that reference this schedule resource provide the availability details
   * to these referenced resource(s).
   */
  actor: Array<Maybe<ScheduleActor>>;
  /**
   * Comments on the availability to describe any extended information. Such as
   * custom constraints on the slots that may be associated.
   */
  comment?: Maybe<Scalars['code']>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** External Ids for this item. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The period of time that the slots that reference this Schedule resource cover
   * (even if none exist). These  cover the amount of time that an organization's
   * planning horizon; the interval for which they are currently accepting
   * appointments. This does not define a "template" for planning outside these
   * dates.
   */
  planningHorizon?: Maybe<Period>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A broad categorization of the service that is to be performed during this
   * appointment.
   */
  serviceCategory?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The specific service that is to be performed during this appointment. */
  serviceType?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The specialty of a practitioner that would be required to perform the service
   * requested in this appointment.
   */
  specialty?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type ScheduleActor = Device | HealthcareService | Location | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * SearchParameter
 *     A search parameter that defines a named search item that can be used to
 *     search/filter on a resource.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type SearchParameter = {
  __typename?: 'SearchParameter';
  /** The base resource type(s) that this search parameter can be used against. */
  base: Array<Maybe<Scalars['code']>>;
  /**
   * Contains the names of any search parameters which may be chained to the
   * containing search parameter. Chained parameters may be added to search
   * parameters of type reference and specify that resources will only be returned
   * if they contain a reference to a resource which matches the chained parameter
   * value. Values for this field should be drawn from SearchParameter.code for a
   * parameter on the target resource type.
   */
  chain?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The code used in the URL or the parameter name in a parameters resource for
   * this search parameter.
   */
  code: Scalars['code'];
  /** Comparators supported for the search parameter. */
  comparator?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Used to define the parts of a composite search parameter. */
  component?: Maybe<Array<Maybe<SearchParameterComponent>>>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The date  (and optionally time) when the search parameter was published. The
   * date must change when the business version changes and it must change if the
   * status code changes. In addition, it should change when the substantive
   * content of the search parameter changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * Where this search parameter is originally defined. If a derivedFrom is
   * provided, then the details in the search parameter must be consistent with the
   * definition from which it is defined. i.e. the parameter should have the same
   * meaning, and (usually) the functionality should be a proper subset of the
   * underlying search parameter.
   */
  derivedFrom?: Maybe<Scalars['code']>;
  /** And how it used. */
  description: Scalars['code'];
  /**
   * A Boolean value to indicate that this search parameter is authored for testing
   * purposes (or education/evaluation/marketing) and is not intended to be used
   * for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /** A FHIRPath expression that returns a set of elements for the search parameter. */
  expression?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A legal or geographic region in which the search parameter is intended to be
   * used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /** A modifier supported for the search parameter. */
  modifier?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Whether multiple parameters are allowed - e.g. more than one parameter with
   * the same name. The search matches if all the parameters match.
   */
  multipleAnd?: Maybe<Scalars['code']>;
  /**
   * Whether multiple values are allowed for each time the parameter exists. Values
   * are separated by commas, and the parameter matches if any of the values match.
   */
  multipleOr?: Maybe<Scalars['code']>;
  /**
   * A natural language name identifying the search parameter. This name should be
   * usable as an identifier for the module by machine processing applications such
   * as code generation.
   */
  name: Scalars['code'];
  /**
   * The name of the organization or individual that published the search
   * parameter.
   */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this search parameter is needed and why it has been
   * designed as it has.
   */
  purpose?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The status of this search parameter. Enables tracking the life-cycle of the
   * content.
   */
  status: Scalars['code'];
  /** Types of resource (if a resource is referenced). */
  target?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * The type of value that a search parameter may contain, and how the content is
   * interpreted.
   */
  type: Scalars['code'];
  /**
   * An absolute URI that is used to identify this search parameter when it is
   * referenced in a specification, model, design or an instance; also called its
   * canonical identifier. This SHOULD be globally unique and SHOULD be a literal
   * address at which at which an authoritative instance of this search parameter
   * is (or will be) published. This URL can be the target of a canonical
   * reference. It SHALL remain the same when the search parameter is stored on
   * different servers.
   */
  url: Scalars['code'];
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate search
   * parameter instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the search parameter
   * when it is referenced in a specification, model, design or instance. This is
   * an arbitrary value managed by the search parameter author and is not expected
   * to be globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if
   * a managed version is not available. There is also no expectation that versions
   * can be placed in a lexicographical sequence.
   */
  version?: Maybe<Scalars['code']>;
  /** An XPath expression that returns a set of elements for the search parameter. */
  xpath?: Maybe<Scalars['code']>;
  /**
   * How the search parameter relates to the set of elements returned by evaluating
   * the xpath query.
   */
  xpathUsage?: Maybe<Scalars['code']>;
};

/**
 * SearchParameter.Component
 *     A search parameter that defines a named search item that can be used to
 *     search/filter on a resource.
 */
export type SearchParameterComponent = {
  __typename?: 'SearchParameterComponent';
  /** The definition of the search parameter that describes this part. */
  definition: Scalars['code'];
  /**
   * A sub-expression that defines how to extract values for this component from
   * the output of the main SearchParameter.expression.
   */
  expression: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * ServiceRequest
 *     A record of a request for service such as diagnostic investigations,
 *     treatments, or operations to be performed.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ServiceRequest = {
  __typename?: 'ServiceRequest';
  appointment?: Maybe<Array<Maybe<Appointment>>>;
  appointmentId?: Maybe<Scalars['String']>;
  /** None */
  asNeededBoolean?: Maybe<Scalars['code']>;
  /** None */
  asNeededCodeableConcept?: Maybe<CodeableConcept>;
  /** When the request transitioned to being actionable. */
  authoredOn?: Maybe<Scalars['code']>;
  /** Plan/proposal/order fulfilled by this request. */
  basedOn?: Maybe<Array<Maybe<ServiceRequestBasedOn>>>;
  /**
   * Anatomic location where the procedure should be performed. This is the target
   * site.
   */
  bodySite?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * A code that classifies the service for searching, sorting and display purposes
   * (e.g. "Surgical Procedure").
   */
  category?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * A code that identifies a particular service (i.e., procedure, diagnostic
   * investigation, or panel of investigations) that have been requested.
   */
  code?: Maybe<CodeableConcept>;
  composition?: Maybe<Array<Maybe<Composition>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Set this to true if the record is saying that the service/procedure should NOT
   * be performed.
   */
  doNotPerform?: Maybe<Scalars['code']>;
  document?: Maybe<Array<Maybe<DocumentReference>>>;
  durationUpdateManualApproved?: Maybe<Scalars['String']>;
  durationUpdatePending?: Maybe<Scalars['String']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifiers assigned to this order instance by the orderer and/or the receiver
   * and/or order fulfiller.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * The URL pointing to a FHIR-defined protocol, guideline, orderset or other
   * definition that is adhered to in whole or in part by this ServiceRequest.
   */
  instantiatesCanonical?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The URL pointing to an externally maintained protocol, guideline, orderset or
   * other definition that is adhered to in whole or in part by this
   * ServiceRequest.
   */
  instantiatesUri?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Insurance plans, coverage extensions, pre-authorizations and/or pre-
   * determinations that may be needed for delivering the requested service.
   */
  insurance?: Maybe<Array<Maybe<ServiceRequestInsurance>>>;
  /** Whether the request is a proposal, plan, an original order or a reflex order. */
  intent: Scalars['code'];
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The preferred location(s) where the procedure should actually happen in coded
   * or free text form. E.g. at home or nursing day care center.
   */
  locationCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * A reference to the the preferred location(s) where the procedure should
   * actually happen. E.g. at home or nursing day care center.
   */
  locationReference?: Maybe<Array<Maybe<Location>>>;
  memberId?: Maybe<Scalars['String']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Any other notes and comments made about the service request. For example,
   * internal billing notes.
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** None */
  occurrenceDateTime?: Maybe<Scalars['code']>;
  /** None */
  occurrencePeriod?: Maybe<Period>;
  /** None */
  occurrenceTiming?: Maybe<Timing>;
  /**
   * Additional details and instructions about the how the services are to be
   * delivered.   For example, and order for a urinary catheter may have an order
   * detail for an external or indwelling catheter, or an order for a bandage may
   * require additional instructions specifying how the bandage should be applied.
   */
  orderDetail?: Maybe<Array<Maybe<CodeableConcept>>>;
  originalDuration?: Maybe<Scalars['String']>;
  patient?: Maybe<Patient>;
  /** Instructions in terms that are understood by the patient or consumer. */
  patientInstruction?: Maybe<Scalars['code']>;
  /**
   * The desired performer for doing the requested service.  For example, the
   * surgeon, dermatopathologist, endoscopist, etc.
   */
  performer?: Maybe<Array<Maybe<ServiceRequestPerformer>>>;
  /** Desired type of performer for doing the requested service. */
  performerType?: Maybe<CodeableConcept>;
  /**
   * Indicates how quickly the ServiceRequest should be addressed with respect to
   * other requests.
   */
  priority?: Maybe<Scalars['code']>;
  /** None */
  quantityQuantity?: Maybe<Quantity>;
  /** None */
  quantityRange?: Maybe<Range>;
  /** None */
  quantityRatio?: Maybe<Ratio>;
  /**
   * An explanation or justification for why this service is being requested in
   * coded or textual form.   This is often for billing purposes.  May relate to
   * the resources referred to in `supportingInfo`.
   */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Indicates another resource that provides a justification for why this service
   * is being requested.   May relate to the resources referred to in
   * `supportingInfo`.
   */
  reasonReference?: Maybe<Array<Maybe<ServiceRequestReasonReference>>>;
  referralNumberOptum?: Maybe<Scalars['String']>;
  referralNumberVa?: Maybe<Scalars['String']>;
  /** Key events in the history of the request. */
  relevantHistory?: Maybe<Array<Maybe<Provenance>>>;
  /**
   * The request takes the place of the referenced completed or terminated
   * request(s).
   */
  replaces?: Maybe<Array<Maybe<ServiceRequest>>>;
  /**
   * The individual who initiated the request and has responsibility for its
   * activation.
   */
  requester?: Maybe<ServiceRequestRequester>;
  /**
   * A shared identifier common to all service requests that were authorized more
   * or less simultaneously by a single author, representing the composite or group
   * identifier.
   */
  requisition?: Maybe<Identifier>;
  resourceType?: Maybe<Scalars['String']>;
  serviceName?: Maybe<Scalars['String']>;
  /** One or more specimens that the laboratory procedure will use. */
  specimen?: Maybe<Array<Maybe<Specimen>>>;
  /** The status of the order. */
  status: Scalars['code'];
  /**
   * On whom or what the service is to be performed. This is usually a human
   * patient, but can also be requested on animals, groups of humans or animals,
   * devices such as dialysis machines, or even locations (typically for
   * environmental scans).
   */
  subject: ServiceRequestSubject;
  /**
   * Additional clinical information about the patient or specimen that may
   * influence the services or their interpretations.     This information includes
   * diagnosis, clinical findings and other observations.  In laboratory ordering
   * these are typically referred to as "ask at order entry questions (AOEs)".
   * This includes observations explicitly requested by the producer (filler) to
   * provide context or supporting information needed to complete the order. For
   * example,  reporting the amount of inspired oxygen for blood gas measurements.
   */
  supportingInfo?: Maybe<Array<Maybe<Resource>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  zeelId?: Maybe<Scalars['String']>;
};

export type ServiceRequestBasedOn = CarePlan | MedicationRequest | ServiceRequest;

export type ServiceRequestInsurance = ClaimResponse | Coverage;

export type ServiceRequestPerformer = CareTeam | Device | HealthcareService | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type ServiceRequestReasonReference = Condition | DiagnosticReport | DocumentReference | Observation;

export type ServiceRequestRequester = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type ServiceRequestSubject = Device | Group | Location | Patient;

/**
 * Signature
 *     A signature along with supporting context. The signature may be a digital
 *     signature that is cryptographic in nature, or some other signature acceptable
 *     to the domain. This other signature may be as simple as a graphical image
 *     representing a hand-written signature, or a signature ceremony Different
 *     signature approaches have different utilities.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Signature = {
  __typename?: 'Signature';
  /**
   * The base64 encoding of the Signature content. When signature is not recorded
   * electronically this element would be empty.
   */
  data?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to an application-usable description of the identity that is
   * represented by the signature.
   */
  onBehalfOf?: Maybe<SignatureOnBehalfOf>;
  /**
   * A mime type that indicates the technical format of the signature. Important
   * mime types are application/signature+xml for X ML DigSig, application/jose for
   * JWS, and image/* for a graphical image of a signature, etc.
   */
  sigFormat?: Maybe<Scalars['code']>;
  /**
   * A mime type that indicates the technical format of the target resources signed
   * by the signature.
   */
  targetFormat?: Maybe<Scalars['code']>;
  /**
   * An indication of the reason that the entity signed this document. This may be
   * explicitly included as part of the signature information and can be used when
   * determining accountability for various actions concerning the document.
   */
  type: Array<Maybe<Coding>>;
  /** When the digital signature was signed. */
  when: Scalars['code'];
  /**
   * A reference to an application-usable description of the identity that signed
   * (e.g. the signature used their private key).
   */
  who: SignatureWho;
};

export type SignatureOnBehalfOf = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type SignatureWho = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Slot
 *     A slot of time on a schedule that may be available for booking appointments.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Slot = {
  __typename?: 'Slot';
  /**
   * The style of appointment or patient that may be booked in the slot (not
   * service type).
   */
  appointmentType?: Maybe<CodeableConcept>;
  /**
   * Comments on the slot to describe any extended information. Such as custom
   * constraints on the slot.
   */
  comment?: Maybe<Scalars['code']>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Date/Time that the slot is to conclude. */
  end: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** External Ids for this item. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * This slot has already been overbooked, appointments are unlikely to be
   * accepted for this time.
   */
  overbooked?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The schedule resource that this slot defines an interval of status
   * information.
   */
  schedule: Schedule;
  /**
   * A broad categorization of the service that is to be performed during this
   * appointment.
   */
  serviceCategory?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The type of appointments that can be booked into this slot (ideally this would
   * be an identifiable service - which is at a location, rather than the location
   * itself). If provided then this overrides the value provided on the
   * availability resource.
   */
  serviceType?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The specialty of a practitioner that would be required to perform the service
   * requested in this appointment.
   */
  specialty?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Date/Time that the slot is to begin. */
  start: Scalars['code'];
  /** busy | free | busy-unavailable | busy-tentative | entered-in-error. */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * Specimen
 *     A sample to be used for analysis.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Specimen = {
  __typename?: 'Specimen';
  /**
   * The identifier assigned by the lab when accessioning specimen(s). This is not
   * necessarily the same as the specimen identifier, depending on local lab
   * procedures.
   */
  accessionIdentifier?: Maybe<Identifier>;
  /** Details concerning the specimen collection. */
  collection?: Maybe<SpecimenCollection>;
  /** A mode or state of being that describes the nature of the specimen. */
  condition?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The container holding the specimen.  The recursive nature of containers; i.e.
   * blood in tube in tray in rack is not addressed here.
   */
  container?: Maybe<Array<Maybe<SpecimenContainer>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Id for specimen. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * To communicate any details or issues about the specimen or during the specimen
   * collection. (for example: broken vial, sent with patient, frozen).
   */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /**
   * Reference to the parent (source) specimen which is used when the specimen was
   * either derived from or a component of another specimen.
   */
  parent?: Maybe<Array<Maybe<Specimen>>>;
  /** Details concerning processing and processing steps for the specimen. */
  processing?: Maybe<Array<Maybe<SpecimenProcessing>>>;
  /** Time when specimen was received for processing or testing. */
  receivedTime?: Maybe<Scalars['code']>;
  /** Details concerning a service request that required a specimen to be collected. */
  request?: Maybe<Array<Maybe<ServiceRequest>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** The availability of the specimen. */
  status?: Maybe<Scalars['code']>;
  /**
   * Where the specimen came from. This may be from patient(s), from a location
   * (e.g., the source of an environmental sample), or a sampling of a substance or
   * a device.
   */
  subject?: Maybe<SpecimenSubject>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** The kind of material that forms the specimen. */
  type?: Maybe<CodeableConcept>;
};

/**
 * Specimen.Collection
 *     A sample to be used for analysis.
 */
export type SpecimenCollection = {
  __typename?: 'SpecimenCollection';
  /**
   * Anatomical location from which the specimen was collected (if subject is a
   * patient). This is the target site.  This element is not used for environmental
   * specimens.
   */
  bodySite?: Maybe<CodeableConcept>;
  /** None */
  collectedDateTime?: Maybe<Scalars['code']>;
  /** None */
  collectedPeriod?: Maybe<Period>;
  /** Person who collected the specimen. */
  collector?: Maybe<SpecimenCollectionCollector>;
  /** The span of time over which the collection of a specimen occurred. */
  duration?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  fastingStatusCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  fastingStatusDuration?: Maybe<Quantity>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** A coded value specifying the technique that is used to perform the procedure. */
  method?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The quantity of specimen collected; for instance the volume of a blood sample,
   * or the physical measurement of an anatomic pathology sample.
   */
  quantity?: Maybe<Quantity>;
};

export type SpecimenCollectionCollector = Practitioner | PractitionerRole;

/**
 * Specimen.Container
 *     A sample to be used for analysis.
 */
export type SpecimenContainer = {
  __typename?: 'SpecimenContainer';
  /** None */
  additiveCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  additiveReference?: Maybe<Substance>;
  /** The capacity (volume or other measure) the container may contain. */
  capacity?: Maybe<Quantity>;
  /** Textual description of the container. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Id for container. There may be multiple; a manufacturer's bar code, lab
   * assigned identifier, etc. The container ID may differ from the specimen id in
   * some circumstances.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The quantity of specimen in the container; may be volume, dimensions, or other
   * appropriate measurements, depending on the specimen type.
   */
  specimenQuantity?: Maybe<Quantity>;
  /**
   * The type of container associated with the specimen (e.g. slide, aliquot,
   * etc.).
   */
  type?: Maybe<CodeableConcept>;
};

/**
 * SpecimenDefinition
 *     A kind of specimen with associated set of requirements.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type SpecimenDefinition = {
  __typename?: 'SpecimenDefinition';
  /** The action to be performed for collecting the specimen. */
  collection?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** A business identifier associated with the kind of specimen. */
  identifier?: Maybe<Identifier>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Preparation of the patient for specimen collection. */
  patientPreparation?: Maybe<Array<Maybe<CodeableConcept>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** Time aspect of specimen collection (duration or offset). */
  timeAspect?: Maybe<Scalars['code']>;
  /** The kind of material to be collected. */
  typeCollected?: Maybe<CodeableConcept>;
  /** Specimen conditioned in a container as expected by the testing laboratory. */
  typeTested?: Maybe<Array<Maybe<SpecimenDefinitionTypeTested>>>;
};

/**
 * SpecimenDefinition.Additive
 *     A kind of specimen with associated set of requirements.
 */
export type SpecimenDefinitionAdditive = {
  __typename?: 'SpecimenDefinitionAdditive';
  /** None */
  additiveCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  additiveReference?: Maybe<Substance>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * SpecimenDefinition.Container
 *     A kind of specimen with associated set of requirements.
 */
export type SpecimenDefinitionContainer = {
  __typename?: 'SpecimenDefinitionContainer';
  /**
   * Substance introduced in the kind of container to preserve, maintain or enhance
   * the specimen. Examples: Formalin, Citrate, EDTA.
   */
  additive?: Maybe<Array<Maybe<SpecimenDefinitionAdditive>>>;
  /** Color of container cap. */
  cap?: Maybe<CodeableConcept>;
  /** The capacity (volume or other measure) of this kind of container. */
  capacity?: Maybe<Quantity>;
  /** The textual description of the kind of container. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The type of material of the container. */
  material?: Maybe<CodeableConcept>;
  /** None */
  minimumVolumeQuantity?: Maybe<Quantity>;
  /** None */
  minimumVolumeString?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Special processing that should be applied to the container for this kind of
   * specimen.
   */
  preparation?: Maybe<Scalars['code']>;
  /** The type of container used to contain this kind of specimen. */
  type?: Maybe<CodeableConcept>;
};

/**
 * SpecimenDefinition.Handling
 *     A kind of specimen with associated set of requirements.
 */
export type SpecimenDefinitionHandling = {
  __typename?: 'SpecimenDefinitionHandling';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Additional textual instructions for the preservation or transport of the
   * specimen. For instance, 'Protect from light exposure'.
   */
  instruction?: Maybe<Scalars['code']>;
  /**
   * The maximum time interval of preservation of the specimen with these
   * conditions.
   */
  maxDuration?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * It qualifies the interval of temperature, which characterizes an occurrence of
   * handling. Conditions that are not related to temperature may be handled in the
   * instruction element.
   */
  temperatureQualifier?: Maybe<CodeableConcept>;
  /** The temperature interval for this set of handling instructions. */
  temperatureRange?: Maybe<Range>;
};

/**
 * SpecimenDefinition.TypeTested
 *     A kind of specimen with associated set of requirements.
 */
export type SpecimenDefinitionTypeTested = {
  __typename?: 'SpecimenDefinitionTypeTested';
  /** The specimen's container. */
  container?: Maybe<SpecimenDefinitionContainer>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Set of instructions for preservation/transport of the specimen at a defined
   * temperature interval, prior the testing process.
   */
  handling?: Maybe<Array<Maybe<SpecimenDefinitionHandling>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Primary of secondary specimen. */
  isDerived?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The preference for this type of conditioned specimen. */
  preference: Scalars['code'];
  /** Criterion for rejection of the specimen in its container by the laboratory. */
  rejectionCriterion?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Requirements for delivery and special handling of this kind of conditioned
   * specimen.
   */
  requirement?: Maybe<Scalars['code']>;
  /**
   * The usual time that a specimen of this kind is retained after the ordered
   * tests are completed, for the purpose of additional testing.
   */
  retentionTime?: Maybe<Quantity>;
  /** The kind of specimen conditioned for testing expected by lab. */
  type?: Maybe<CodeableConcept>;
};

/**
 * Specimen.Processing
 *     A sample to be used for analysis.
 */
export type SpecimenProcessing = {
  __typename?: 'SpecimenProcessing';
  /** Material used in the processing step. */
  additive?: Maybe<Array<Maybe<Substance>>>;
  /** Textual description of procedure. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A coded value specifying the procedure used to process the specimen. */
  procedure?: Maybe<CodeableConcept>;
  /** None */
  timeDateTime?: Maybe<Scalars['code']>;
  /** None */
  timePeriod?: Maybe<Period>;
};

export type SpecimenSubject = Device | Group | Location | Patient | Substance;

/**
 * StructureDefinition
 *     A definition of a FHIR structure. This resource is used to describe the
 *     underlying resources, data types defined in FHIR, and also for describing
 *     extensions and constraints on resources and data types.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type StructureDefinition = {
  __typename?: 'StructureDefinition';
  /**
   * Whether structure this definition describes is abstract or not  - that is,
   * whether the structure is not intended to be instantiated. For Resources and
   * Data types, abstract types will never be exchanged  between systems.
   */
  abstract: Scalars['code'];
  /**
   * An absolute URI that is the base structure from which this type is derived,
   * either by specialization or constraint.
   */
  baseDefinition?: Maybe<Scalars['code']>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Identifies the types of resource or data type elements to which the extension
   * can be applied.
   */
  context?: Maybe<Array<Maybe<StructureDefinitionContext>>>;
  /**
   * A set of rules as FHIRPath Invariants about when the extension can be used
   * (e.g. co-occurrence variants for the extension). All the rules must be true.
   */
  contextInvariant?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the structure definition and/or its
   * contents. Copyright statements are generally legal restrictions on the use and
   * publishing of the structure definition.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the structure definition was published.
   * The date must change when the business version changes and it must change if
   * the status code changes. In addition, it should change when the substantive
   * content of the structure definition changes.
   */
  date?: Maybe<Scalars['code']>;
  /** How the type relates to the baseDefinition. */
  derivation?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the structure definition from a
   * consumer's perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * A differential view is expressed relative to the base StructureDefinition - a
   * statement of differences that it applies.
   */
  differential?: Maybe<StructureDefinitionDifferential>;
  /**
   * A Boolean value to indicate that this structure definition is authored for
   * testing purposes (or education/evaluation/marketing) and is not intended to be
   * used for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The version of the FHIR specification on which this StructureDefinition is
   * based - this is the formal version of the specification, without the revision
   * number, e.g. [publication].[major].[minor], which is 4.0.1. for this version.
   */
  fhirVersion?: Maybe<Scalars['code']>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this structure definition when it
   * is represented in other formats, or referenced in a specification, model,
   * design or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A legal or geographic region in which the structure definition is intended to
   * be used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * A set of key words or terms from external terminologies that may be used to
   * assist with indexing and searching of templates nby describing the use of this
   * structure definition, or the content it describes.
   */
  keyword?: Maybe<Array<Maybe<Coding>>>;
  /** Defines the kind of structure that this definition is describing. */
  kind: Scalars['code'];
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** An external specification that the content is mapped to. */
  mapping?: Maybe<Array<Maybe<StructureDefinitionMapping>>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the structure definition. This name should
   * be usable as an identifier for the module by machine processing applications
   * such as code generation.
   */
  name: Scalars['code'];
  /**
   * The name of the organization or individual that published the structure
   * definition.
   */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this structure definition is needed and why it has been
   * designed as it has.
   */
  purpose?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A snapshot view is expressed in a standalone form that can be used and
   * interpreted without considering the base StructureDefinition.
   */
  snapshot?: Maybe<StructureDefinitionSnapshot>;
  /**
   * The status of this structure definition. Enables tracking the life-cycle of
   * the content.
   */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the structure definition. */
  title?: Maybe<Scalars['code']>;
  /**
   * The type this structure describes. If the derivation kind is 'specialization'
   * then this is the master definition for a type, and there is always one of
   * these (a data type, an extension, a resource, including abstract ones).
   * Otherwise the structure definition is a constraint on the stated type (and in
   * this case, the type cannot be an abstract type).  References are URLs that are
   * relative to http://hl7.org/fhir/StructureDefinition e.g. "string" is a
   * reference to http://hl7.org/fhir/StructureDefinition/string. Absolute URLs are
   * only allowed in logical models.
   */
  type: Scalars['code'];
  /**
   * An absolute URI that is used to identify this structure definition when it is
   * referenced in a specification, model, design or an instance; also called its
   * canonical identifier. This SHOULD be globally unique and SHOULD be a literal
   * address at which at which an authoritative instance of this structure
   * definition is (or will be) published. This URL can be the target of a
   * canonical reference. It SHALL remain the same when the structure definition is
   * stored on different servers.
   */
  url: Scalars['code'];
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate structure
   * definition instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the structure
   * definition when it is referenced in a specification, model, design or
   * instance. This is an arbitrary value managed by the structure definition
   * author and is not expected to be globally unique. For example, it might be a
   * timestamp (e.g. yyyymmdd) if a managed version is not available. There is also
   * no expectation that versions can be placed in a lexicographical sequence.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * StructureDefinition.Context
 *     A definition of a FHIR structure. This resource is used to describe the
 *     underlying resources, data types defined in FHIR, and also for describing
 *     extensions and constraints on resources and data types.
 */
export type StructureDefinitionContext = {
  __typename?: 'StructureDefinitionContext';
  /** An expression that defines where an extension can be used in resources. */
  expression: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Defines how to interpret the expression that defines what the context of the
   * extension is.
   */
  type: Scalars['code'];
};

/**
 * StructureDefinition.Differential
 *     A definition of a FHIR structure. This resource is used to describe the
 *     underlying resources, data types defined in FHIR, and also for describing
 *     extensions and constraints on resources and data types.
 */
export type StructureDefinitionDifferential = {
  __typename?: 'StructureDefinitionDifferential';
  /** Captures constraints on each element within the resource. */
  element: Array<Maybe<ElementDefinition>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * StructureDefinition.Mapping
 *     A definition of a FHIR structure. This resource is used to describe the
 *     underlying resources, data types defined in FHIR, and also for describing
 *     extensions and constraints on resources and data types.
 */
export type StructureDefinitionMapping = {
  __typename?: 'StructureDefinitionMapping';
  /**
   * Comments about this mapping, including version notes, issues, scope
   * limitations, and other important notes for usage.
   */
  comment?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * An Internal id that is used to identify this mapping set when specific
   * mappings are made.
   */
  identity: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A name for the specification that is being mapped to. */
  name?: Maybe<Scalars['code']>;
  /**
   * An absolute URI that identifies the specification that this mapping is
   * expressed to.
   */
  uri?: Maybe<Scalars['code']>;
};

/**
 * StructureDefinition.Snapshot
 *     A definition of a FHIR structure. This resource is used to describe the
 *     underlying resources, data types defined in FHIR, and also for describing
 *     extensions and constraints on resources and data types.
 */
export type StructureDefinitionSnapshot = {
  __typename?: 'StructureDefinitionSnapshot';
  /** Captures constraints on each element within the resource. */
  element: Array<Maybe<ElementDefinition>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * StructureMap
 *     A Map of relationships between 2 structures that can be used to transform
 *     data.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type StructureMap = {
  __typename?: 'StructureMap';
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the structure map and/or its contents.
   * Copyright statements are generally legal restrictions on the use and
   * publishing of the structure map.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the structure map was published. The date
   * must change when the business version changes and it must change if the status
   * code changes. In addition, it should change when the substantive content of
   * the structure map changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the structure map from a
   * consumer's perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * A Boolean value to indicate that this structure map is authored for testing
   * purposes (or education/evaluation/marketing) and is not intended to be used
   * for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Organizes the mapping into manageable chunks for human review/ease of
   * maintenance.
   */
  group: Array<Maybe<StructureMapGroup>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this structure map when it is
   * represented in other formats, or referenced in a specification, model, design
   * or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** Other maps used by this map (canonical URLs). */
  import?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A legal or geographic region in which the structure map is intended to be
   * used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the structure map. This name should be
   * usable as an identifier for the module by machine processing applications such
   * as code generation.
   */
  name: Scalars['code'];
  /** The name of the organization or individual that published the structure map. */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this structure map is needed and why it has been designed
   * as it has.
   */
  purpose?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The status of this structure map. Enables tracking the life-cycle of the
   * content.
   */
  status: Scalars['code'];
  /**
   * A structure definition used by this map. The structure definition may describe
   * instances that are converted, or the instances that are produced.
   */
  structure?: Maybe<Array<Maybe<StructureMapStructure>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the structure map. */
  title?: Maybe<Scalars['code']>;
  /**
   * An absolute URI that is used to identify this structure map when it is
   * referenced in a specification, model, design or an instance; also called its
   * canonical identifier. This SHOULD be globally unique and SHOULD be a literal
   * address at which at which an authoritative instance of this structure map is
   * (or will be) published. This URL can be the target of a canonical reference.
   * It SHALL remain the same when the structure map is stored on different
   * servers.
   */
  url: Scalars['code'];
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate structure
   * map instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the structure map when
   * it is referenced in a specification, model, design or instance. This is an
   * arbitrary value managed by the structure map author and is not expected to be
   * globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a
   * managed version is not available. There is also no expectation that versions
   * can be placed in a lexicographical sequence.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * StructureMap.Dependent
 *     A Map of relationships between 2 structures that can be used to transform
 *     data.
 */
export type StructureMapDependent = {
  __typename?: 'StructureMapDependent';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Name of a rule or group to apply. */
  name: Scalars['code'];
  /** Variable to pass to the rule or group. */
  variable: Array<Maybe<Scalars['code']>>;
};

/**
 * StructureMap.Group
 *     A Map of relationships between 2 structures that can be used to transform
 *     data.
 */
export type StructureMapGroup = {
  __typename?: 'StructureMapGroup';
  /**
   * Additional supporting documentation that explains the purpose of the group and
   * the types of mappings within it.
   */
  documentation?: Maybe<Scalars['code']>;
  /** Another group that this group adds rules to. */
  extends?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * A name assigned to an instance of data. The instance must be provided when the
   * mapping is invoked.
   */
  input: Array<Maybe<StructureMapInput>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A unique name for the group for the convenience of human readers. */
  name: Scalars['code'];
  /** Transform Rule from source to target. */
  rule: Array<Maybe<StructureMapRule>>;
  /**
   * If this is the default rule set to apply for the source type or this
   * combination of types.
   */
  typeMode: Scalars['code'];
};

/**
 * StructureMap.Input
 *     A Map of relationships between 2 structures that can be used to transform
 *     data.
 */
export type StructureMapInput = {
  __typename?: 'StructureMapInput';
  /** Documentation for this instance of data. */
  documentation?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Mode for this instance of data. */
  mode: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Name for this instance of data. */
  name: Scalars['code'];
  /** Type for this instance of data. */
  type?: Maybe<Scalars['code']>;
};

/**
 * StructureMap.Parameter
 *     A Map of relationships between 2 structures that can be used to transform
 *     data.
 */
export type StructureMapParameter = {
  __typename?: 'StructureMapParameter';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  valueBoolean?: Maybe<Scalars['code']>;
  /** None */
  valueDecimal?: Maybe<Scalars['code']>;
  /** None */
  valueId?: Maybe<Scalars['code']>;
  /** None */
  valueInteger?: Maybe<Scalars['code']>;
  /** None */
  valueString?: Maybe<Scalars['code']>;
};

/**
 * StructureMap.Rule
 *     A Map of relationships between 2 structures that can be used to transform
 *     data.
 */
export type StructureMapRule = {
  __typename?: 'StructureMapRule';
  /** Which other rules to apply in the context of this rule. */
  dependent?: Maybe<Array<Maybe<StructureMapDependent>>>;
  /** Documentation for this instance of data. */
  documentation?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Name of the rule for internal references. */
  name: Scalars['code'];
  /** Rules contained in this rule. */
  rule?: Maybe<Array<Maybe<StructureMapRule>>>;
  /** Source inputs to the mapping. */
  source: Array<Maybe<StructureMapSource>>;
  /** Content to create because of this mapping rule. */
  target?: Maybe<Array<Maybe<StructureMapTarget>>>;
};

/**
 * StructureMap.Source
 *     A Map of relationships between 2 structures that can be used to transform
 *     data.
 */
export type StructureMapSource = {
  __typename?: 'StructureMapSource';
  /**
   * FHIRPath expression  - must be true or the mapping engine throws an error
   * instead of completing.
   */
  check?: Maybe<Scalars['code']>;
  /** FHIRPath expression  - must be true or the rule does not apply. */
  condition?: Maybe<Scalars['code']>;
  /** Type or variable this rule applies to. */
  context: Scalars['code'];
  /** None */
  defaultValueAddress?: Maybe<Address>;
  /** None */
  defaultValueAge?: Maybe<Quantity>;
  /** None */
  defaultValueAnnotation?: Maybe<Annotation>;
  /** None */
  defaultValueAttachment?: Maybe<Attachment>;
  /** None */
  defaultValueBase64Binary?: Maybe<Scalars['code']>;
  /** None */
  defaultValueBoolean?: Maybe<Scalars['code']>;
  /** None */
  defaultValueCanonical?: Maybe<Scalars['code']>;
  /** None */
  defaultValueCode?: Maybe<Scalars['code']>;
  /** None */
  defaultValueCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  defaultValueCoding?: Maybe<Coding>;
  /** None */
  defaultValueContactDetail?: Maybe<ContactDetail>;
  /** None */
  defaultValueContactPoint?: Maybe<ContactPoint>;
  /** None */
  defaultValueContributor?: Maybe<Contributor>;
  /** None */
  defaultValueCount?: Maybe<Quantity>;
  /** None */
  defaultValueDataRequirement?: Maybe<DataRequirement>;
  /** None */
  defaultValueDate?: Maybe<Scalars['code']>;
  /** None */
  defaultValueDateTime?: Maybe<Scalars['code']>;
  /** None */
  defaultValueDecimal?: Maybe<Scalars['code']>;
  /** None */
  defaultValueDistance?: Maybe<Quantity>;
  /** None */
  defaultValueDosage?: Maybe<Dosage>;
  /** None */
  defaultValueDuration?: Maybe<Quantity>;
  /** None */
  defaultValueExpression?: Maybe<Expression>;
  /** None */
  defaultValueHumanName?: Maybe<HumanName>;
  /** None */
  defaultValueId?: Maybe<Scalars['code']>;
  /** None */
  defaultValueIdentifier?: Maybe<Identifier>;
  /** None */
  defaultValueInstant?: Maybe<Scalars['code']>;
  /** None */
  defaultValueInteger?: Maybe<Scalars['code']>;
  /** None */
  defaultValueMarkdown?: Maybe<Scalars['code']>;
  /** None */
  defaultValueMeta?: Maybe<Meta>;
  /** None */
  defaultValueMoney?: Maybe<Money>;
  /** None */
  defaultValueOid?: Maybe<Scalars['code']>;
  /** None */
  defaultValueParameterDefinition?: Maybe<ParameterDefinition>;
  /** None */
  defaultValuePeriod?: Maybe<Period>;
  /** None */
  defaultValuePositiveInt?: Maybe<Scalars['code']>;
  /** None */
  defaultValueQuantity?: Maybe<Quantity>;
  /** None */
  defaultValueRange?: Maybe<Range>;
  /** None */
  defaultValueRatio?: Maybe<Ratio>;
  /** None */
  defaultValueReference?: Maybe<Resource>;
  /** None */
  defaultValueRelatedArtifact?: Maybe<RelatedArtifact>;
  /** None */
  defaultValueSampledData?: Maybe<SampledData>;
  /** None */
  defaultValueSignature?: Maybe<Signature>;
  /** None */
  defaultValueString?: Maybe<Scalars['code']>;
  /** None */
  defaultValueTime?: Maybe<Scalars['code']>;
  /** None */
  defaultValueTiming?: Maybe<Timing>;
  /** None */
  defaultValueTriggerDefinition?: Maybe<TriggerDefinition>;
  /** None */
  defaultValueUnsignedInt?: Maybe<Scalars['code']>;
  /** None */
  defaultValueUri?: Maybe<Scalars['code']>;
  /** None */
  defaultValueUrl?: Maybe<Scalars['code']>;
  /** None */
  defaultValueUsageContext?: Maybe<UsageContext>;
  /** None */
  defaultValueUuid?: Maybe<Scalars['code']>;
  /** Optional field for this source. */
  element?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** How to handle the list mode for this element. */
  listMode?: Maybe<Scalars['code']>;
  /**
   * A FHIRPath expression which specifies a message to put in the transform log
   * when content matching the source rule is found.
   */
  logMessage?: Maybe<Scalars['code']>;
  /**
   * Specified maximum cardinality for the element - a number or a "*". This is
   * optional; if present, it acts an implicit check on the input content (* just
   * serves as documentation; it's the default value).
   */
  max?: Maybe<Scalars['code']>;
  /**
   * Specified minimum cardinality for the element. This is optional; if present,
   * it acts an implicit check on the input content.
   */
  min?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Specified type for the element. This works as a condition on the mapping - use
   * for polymorphic elements.
   */
  type?: Maybe<Scalars['code']>;
  /** Named context for field, if a field is specified. */
  variable?: Maybe<Scalars['code']>;
};

/**
 * StructureMap.Structure
 *     A Map of relationships between 2 structures that can be used to transform
 *     data.
 */
export type StructureMapStructure = {
  __typename?: 'StructureMapStructure';
  /** The name used for this type in the map. */
  alias?: Maybe<Scalars['code']>;
  /** Documentation that describes how the structure is used in the mapping. */
  documentation?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** How the referenced structure is used in this mapping. */
  mode: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The canonical reference to the structure. */
  url: Scalars['code'];
};

/**
 * StructureMap.Target
 *     A Map of relationships between 2 structures that can be used to transform
 *     data.
 */
export type StructureMapTarget = {
  __typename?: 'StructureMapTarget';
  /** Type or variable this rule applies to. */
  context?: Maybe<Scalars['code']>;
  /** How to interpret the context. */
  contextType?: Maybe<Scalars['code']>;
  /** Field to create in the context. */
  element?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** If field is a list, how to manage the list. */
  listMode?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Internal rule reference for shared list items. */
  listRuleId?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Parameters to the transform. */
  parameter?: Maybe<Array<Maybe<StructureMapParameter>>>;
  /** How the data is copied / created. */
  transform?: Maybe<Scalars['code']>;
  /** Named context for field, if desired, and a field is specified. */
  variable?: Maybe<Scalars['code']>;
};

/**
 * Subscription
 *     The subscription resource is used to define a push-based subscription from a
 *     server to another system. Once a subscription is registered with the server,
 *     the server checks every resource that is created or updated, and if the
 *     resource matches the given criteria, it sends a message on the defined
 *     "channel" so that another system can take an appropriate action.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Subscription = {
  __typename?: 'Subscription';
  /**
   * Details where to send notifications when resources are received that meet the
   * criteria.
   */
  channel: SubscriptionChannel;
  /**
   * Contact details for a human to contact about the subscription. The primary use
   * of this for system administrator troubleshooting.
   */
  contact?: Maybe<Array<Maybe<ContactPoint>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The rules that the server should use to determine when to generate
   * notifications for this subscription.
   */
  criteria: Scalars['code'];
  /** The time for the server to turn the subscription off. */
  end?: Maybe<Scalars['code']>;
  /**
   * A record of the last error that occurred when the server processed a
   * notification.
   */
  error?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A description of why this subscription is defined. */
  reason: Scalars['code'];
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The status of the subscription, which marks the server state for managing the
   * subscription.
   */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * Subscription.Channel
 *     The subscription resource is used to define a push-based subscription from a
 *     server to another system. Once a subscription is registered with the server,
 *     the server checks every resource that is created or updated, and if the
 *     resource matches the given criteria, it sends a message on the defined
 *     "channel" so that another system can take an appropriate action.
 */
export type SubscriptionChannel = {
  __typename?: 'SubscriptionChannel';
  /** The url that describes the actual end-point to send messages to. */
  endpoint?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Additional headers / information to send as part of the notification. */
  header?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The mime type to send the payload in - either application/fhir+xml, or
   * application/fhir+json. If the payload is not present, then there is no payload
   * in the notification, just a notification. The mime type "text/plain" may also
   * be used for Email and SMS subscriptions.
   */
  payload?: Maybe<Scalars['code']>;
  /** The type of channel to send notifications on. */
  type: Scalars['code'];
};

/**
 * Substance
 *     A homogeneous material with a definite composition.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Substance = {
  __typename?: 'Substance';
  /**
   * A code that classifies the general type of substance.  This is used  for
   * searching, sorting and display purposes.
   */
  category?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** A code (or set of codes) that identify this substance. */
  code: CodeableConcept;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A description of the substance - its appearance, handling requirements, and
   * other usage notes.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Unique identifier for the substance. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** A substance can be composed of other substances. */
  ingredient?: Maybe<Array<Maybe<SubstanceIngredient>>>;
  /**
   * Substance may be used to describe a kind of substance, or a specific
   * package/container of the substance: an instance.
   */
  instance?: Maybe<Array<Maybe<SubstanceInstance>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** A code to indicate if the substance is actively used. */
  status?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * SubstanceAmount
 *     Chemical substances are a single substance type whose primary defining element
 *     is the molecular structure. Chemical substances shall be defined on the basis
 *     of their complete covalent molecular structure; the presence of a salt
 *     (counter-ion) and/or solvates (water, alcohols) is also captured. Purity,
 *     grade, physical form or particle size are not taken into account in the
 *     definition of a chemical substance or in the assignment of a Substance ID.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type SubstanceAmount = {
  __typename?: 'SubstanceAmount';
  /** None */
  amountQuantity?: Maybe<Quantity>;
  /** None */
  amountRange?: Maybe<Range>;
  /** None */
  amountString?: Maybe<Scalars['code']>;
  /** A textual comment on a numeric value. */
  amountText?: Maybe<Scalars['code']>;
  /**
   * Most elements that require a quantitative value will also have a field called
   * amount type. Amount type should always be specified because the actual value
   * of the amount is often dependent on it. EXAMPLE: In capturing the actual
   * relative amounts of substances or molecular fragments it is essential to
   * indicate whether the amount refers to a mole ratio or weight ratio. For any
   * given element an effort should be made to use same the amount type for all
   * related definitional elements.
   */
  amountType?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Reference range of possible or expected values. */
  referenceRange?: Maybe<SubstanceAmountReferenceRange>;
};

/**
 * SubstanceAmount.ReferenceRange
 *     Chemical substances are a single substance type whose primary defining element
 *     is the molecular structure. Chemical substances shall be defined on the basis
 *     of their complete covalent molecular structure; the presence of a salt
 *     (counter-ion) and/or solvates (water, alcohols) is also captured. Purity,
 *     grade, physical form or particle size are not taken into account in the
 *     definition of a chemical substance or in the assignment of a Substance ID.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type SubstanceAmountReferenceRange = {
  __typename?: 'SubstanceAmountReferenceRange';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Upper limit possible or expected. */
  highLimit?: Maybe<Quantity>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Lower limit possible or expected. */
  lowLimit?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * Substance.Ingredient
 *     A homogeneous material with a definite composition.
 */
export type SubstanceIngredient = {
  __typename?: 'SubstanceIngredient';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The amount of the ingredient in the substance - a concentration ratio. */
  quantity?: Maybe<Ratio>;
  /** None */
  substanceCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  substanceReference?: Maybe<Substance>;
};

/**
 * Substance.Instance
 *     A homogeneous material with a definite composition.
 */
export type SubstanceInstance = {
  __typename?: 'SubstanceInstance';
  /**
   * When the substance is no longer valid to use. For some substances, a single
   * arbitrary date is used for expiry.
   */
  expiry?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifier associated with the package/container (usually a label affixed
   * directly).
   */
  identifier?: Maybe<Identifier>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The amount of the substance. */
  quantity?: Maybe<Quantity>;
};

/**
 * SubstanceNucleicAcid
 *     Nucleic acids are defined by three distinct elements: the base, sugar and
 *     linkage. Individual substance/moiety IDs will be created for each of these
 *     elements. The nucleotide sequence will be always entered in the 5’-3’
 *     direction.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type SubstanceNucleicAcid = {
  __typename?: 'SubstanceNucleicAcid';
  /**
   * The area of hybridisation shall be described if applicable for double stranded
   * RNA or DNA. The number associated with the subunit followed by the number
   * associated to the residue shall be specified in increasing order. The
   * underscore “” shall be used as separator as follows: “Subunitnumber Residue”.
   */
  areaOfHybridisation?: Maybe<Scalars['code']>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The number of linear sequences of nucleotides linked through phosphodiester
   * bonds shall be described. Subunits would be strands of nucleic acids that are
   * tightly associated typically through Watson-Crick base pairing. NOTE: If not
   * specified in the reference source, the assumption is that there is 1 subunit.
   */
  numberOfSubunits?: Maybe<Scalars['code']>;
  /** (TBC). */
  oligoNucleotideType?: Maybe<CodeableConcept>;
  resourceType?: Maybe<Scalars['String']>;
  /** The type of the sequence shall be specified based on a controlled vocabulary. */
  sequenceType?: Maybe<CodeableConcept>;
  /**
   * Subunits are listed in order of decreasing length; sequences of the same
   * length will be ordered by molecular weight; subunits that have identical
   * sequences will be repeated multiple times.
   */
  subunit?: Maybe<Array<Maybe<SubstanceNucleicAcidSubunit>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * SubstanceNucleicAcid.Linkage
 *     Nucleic acids are defined by three distinct elements: the base, sugar and
 *     linkage. Individual substance/moiety IDs will be created for each of these
 *     elements. The nucleotide sequence will be always entered in the 5’-3’
 *     direction.
 */
export type SubstanceNucleicAcidLinkage = {
  __typename?: 'SubstanceNucleicAcidLinkage';
  /**
   * The entity that links the sugar residues together should also be captured for
   * nearly all naturally occurring nucleic acid the linkage is a phosphate group.
   * For many synthetic oligonucleotides phosphorothioate linkages are often seen.
   * Linkage connectivity is assumed to be 3’-5’. If the linkage is either 3’-3’ or
   * 5’-5’ this should be specified.
   */
  connectivity?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Each linkage will be registered as a fragment and have an ID. */
  identifier?: Maybe<Identifier>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Each linkage will be registered as a fragment and have at least one name. A
   * single name shall be assigned to each linkage.
   */
  name?: Maybe<Scalars['code']>;
  /** Residues shall be captured as described in 5.3.6.8.3. */
  residueSite?: Maybe<Scalars['code']>;
};

/**
 * SubstanceNucleicAcid.Subunit
 *     Nucleic acids are defined by three distinct elements: the base, sugar and
 *     linkage. Individual substance/moiety IDs will be created for each of these
 *     elements. The nucleotide sequence will be always entered in the 5’-3’
 *     direction.
 */
export type SubstanceNucleicAcidSubunit = {
  __typename?: 'SubstanceNucleicAcidSubunit';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The nucleotide present at the 5’ terminal shall be specified based on a
   * controlled vocabulary. Since the sequence is represented from the 5' to the 3'
   * end, the 5’ prime nucleotide is the letter at the first position in the
   * sequence. A separate representation would be redundant.
   */
  fivePrime?: Maybe<CodeableConcept>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The length of the sequence shall be captured. */
  length?: Maybe<Scalars['code']>;
  /** The linkages between sugar residues will also be captured. */
  linkage?: Maybe<Array<Maybe<SubstanceNucleicAcidLinkage>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Actual nucleotide sequence notation from 5' to 3' end using standard single
   * letter codes. In addition to the base sequence, sugar and type of phosphate or
   * non-phosphate linkage should also be captured.
   */
  sequence?: Maybe<Scalars['code']>;
  /** (TBC). */
  sequenceAttachment?: Maybe<Attachment>;
  /**
   * Index of linear sequences of nucleic acids in order of decreasing length.
   * Sequences of the same length will be ordered by molecular weight. Subunits
   * that have identical sequences will be repeated and have sequential subscripts.
   */
  subunit?: Maybe<Scalars['code']>;
  /** 5.3.6.8.1 Sugar ID (Mandatory). */
  sugar?: Maybe<Array<Maybe<SubstanceNucleicAcidSugar>>>;
  /**
   * The nucleotide present at the 3’ terminal shall be specified based on a
   * controlled vocabulary. Since the sequence is represented from the 5' to the 3'
   * end, the 5’ prime nucleotide is the letter at the last position in the
   * sequence. A separate representation would be redundant.
   */
  threePrime?: Maybe<CodeableConcept>;
};

/**
 * SubstanceNucleicAcid.Sugar
 *     Nucleic acids are defined by three distinct elements: the base, sugar and
 *     linkage. Individual substance/moiety IDs will be created for each of these
 *     elements. The nucleotide sequence will be always entered in the 5’-3’
 *     direction.
 */
export type SubstanceNucleicAcidSugar = {
  __typename?: 'SubstanceNucleicAcidSugar';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The Substance ID of the sugar or sugar-like component that make up the
   * nucleotide.
   */
  identifier?: Maybe<Identifier>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The name of the sugar or sugar-like component that make up the nucleotide. */
  name?: Maybe<Scalars['code']>;
  /**
   * The residues that contain a given sugar will be captured. The order of given
   * residues will be captured in the 5‘-3‘direction consistent with the base
   * sequences listed above.
   */
  residueSite?: Maybe<Scalars['code']>;
};

/**
 * SubstancePolymer
 *     Todo.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type SubstancePolymer = {
  __typename?: 'SubstancePolymer';
  /** Todo. */
  class?: Maybe<CodeableConcept>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Todo. */
  copolymerConnectivity?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Todo. */
  geometry?: Maybe<CodeableConcept>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /** Todo. */
  modification?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Todo. */
  monomerSet?: Maybe<Array<Maybe<SubstancePolymerMonomerSet>>>;
  /** Todo. */
  repeat?: Maybe<Array<Maybe<SubstancePolymerRepeat>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * SubstancePolymer.DegreeOfPolymerisation
 *     Todo.
 */
export type SubstancePolymerDegreeOfPolymerisation = {
  __typename?: 'SubstancePolymerDegreeOfPolymerisation';
  /** Todo. */
  amount?: Maybe<SubstanceAmount>;
  /** Todo. */
  degree?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * SubstancePolymer.MonomerSet
 *     Todo.
 */
export type SubstancePolymerMonomerSet = {
  __typename?: 'SubstancePolymerMonomerSet';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Todo. */
  ratioType?: Maybe<CodeableConcept>;
  /** Todo. */
  startingMaterial?: Maybe<Array<Maybe<SubstancePolymerStartingMaterial>>>;
};

/**
 * SubstancePolymer.Repeat
 *     Todo.
 */
export type SubstancePolymerRepeat = {
  __typename?: 'SubstancePolymerRepeat';
  /** Todo. */
  averageMolecularFormula?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Todo. */
  numberOfUnits?: Maybe<Scalars['code']>;
  /** Todo. */
  repeatUnit?: Maybe<Array<Maybe<SubstancePolymerRepeatUnit>>>;
  /** Todo. */
  repeatUnitAmountType?: Maybe<CodeableConcept>;
};

/**
 * SubstancePolymer.RepeatUnit
 *     Todo.
 */
export type SubstancePolymerRepeatUnit = {
  __typename?: 'SubstancePolymerRepeatUnit';
  /** Todo. */
  amount?: Maybe<SubstanceAmount>;
  /** Todo. */
  degreeOfPolymerisation?: Maybe<Array<Maybe<SubstancePolymerDegreeOfPolymerisation>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Todo. */
  orientationOfPolymerisation?: Maybe<CodeableConcept>;
  /** Todo. */
  repeatUnit?: Maybe<Scalars['code']>;
  /** Todo. */
  structuralRepresentation?: Maybe<Array<Maybe<SubstancePolymerStructuralRepresentation>>>;
};

/**
 * SubstancePolymer.StartingMaterial
 *     Todo.
 */
export type SubstancePolymerStartingMaterial = {
  __typename?: 'SubstancePolymerStartingMaterial';
  /** Todo. */
  amount?: Maybe<SubstanceAmount>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Todo. */
  isDefining?: Maybe<Scalars['code']>;
  /** Todo. */
  material?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Todo. */
  type?: Maybe<CodeableConcept>;
};

/**
 * SubstancePolymer.StructuralRepresentation
 *     Todo.
 */
export type SubstancePolymerStructuralRepresentation = {
  __typename?: 'SubstancePolymerStructuralRepresentation';
  /** Todo. */
  attachment?: Maybe<Attachment>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Todo. */
  representation?: Maybe<Scalars['code']>;
  /** Todo. */
  type?: Maybe<CodeableConcept>;
};

/**
 * SubstanceProtein
 *     A SubstanceProtein is defined as a single unit of a linear amino acid
 *     sequence, or a combination of subunits that are either covalently linked or
 *     have a defined invariant stoichiometric relationship. This includes all
 *     synthetic, recombinant and purified SubstanceProteins of defined sequence,
 *     whether the use is therapeutic or prophylactic. This set of elements will be
 *     used to describe albumins, coagulation factors, cytokines, growth factors,
 *     peptide/SubstanceProtein hormones, enzymes, toxins, toxoids, recombinant
 *     vaccines, and immunomodulators.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type SubstanceProtein = {
  __typename?: 'SubstanceProtein';
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The disulphide bond between two cysteine residues either on the same subunit
   * or on two different subunits shall be described. The position of the disulfide
   * bonds in the SubstanceProtein shall be listed in increasing order of subunit
   * number and position within subunit followed by the abbreviation of the amino
   * acids involved. The disulfide linkage positions shall actually contain the
   * amino acid Cysteine at the respective positions.
   */
  disulfideLinkage?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Number of linear sequences of amino acids linked through peptide bonds. The
   * number of subunits constituting the SubstanceProtein shall be described. It is
   * possible that the number of subunits can be variable.
   */
  numberOfSubunits?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The SubstanceProtein descriptive elements will only be used when a complete or
   * partial amino acid sequence is available or derivable from a nucleic acid
   * sequence.
   */
  sequenceType?: Maybe<CodeableConcept>;
  /**
   * This subclause refers to the description of each subunit constituting the
   * SubstanceProtein. A subunit is a linear sequence of amino acids linked through
   * peptide bonds. The Subunit information shall be provided when the finished
   * SubstanceProtein is a complex of multiple sequences; subunits are not used to
   * delineate domains within a single sequence. Subunits are listed in order of
   * decreasing length; sequences of the same length will be ordered by decreasing
   * molecular weight; subunits that have identical sequences will be repeated
   * multiple times.
   */
  subunit?: Maybe<Array<Maybe<SubstanceProteinSubunit>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * SubstanceProtein.Subunit
 *     A SubstanceProtein is defined as a single unit of a linear amino acid
 *     sequence, or a combination of subunits that are either covalently linked or
 *     have a defined invariant stoichiometric relationship. This includes all
 *     synthetic, recombinant and purified SubstanceProteins of defined sequence,
 *     whether the use is therapeutic or prophylactic. This set of elements will be
 *     used to describe albumins, coagulation factors, cytokines, growth factors,
 *     peptide/SubstanceProtein hormones, enzymes, toxins, toxoids, recombinant
 *     vaccines, and immunomodulators.
 */
export type SubstanceProteinSubunit = {
  __typename?: 'SubstanceProteinSubunit';
  /** The modification at the C-terminal shall be specified. */
  cTerminalModification?: Maybe<Scalars['code']>;
  /**
   * Unique identifier for molecular fragment modification based on the ISO 11238
   * Substance ID.
   */
  cTerminalModificationId?: Maybe<Identifier>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Length of linear sequences of amino acids contained in the subunit. */
  length?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The name of the fragment modified at the N-terminal of the SubstanceProtein
   * shall be specified.
   */
  nTerminalModification?: Maybe<Scalars['code']>;
  /**
   * Unique identifier for molecular fragment modification based on the ISO 11238
   * Substance ID.
   */
  nTerminalModificationId?: Maybe<Identifier>;
  /**
   * The sequence information shall be provided enumerating the amino acids from N-
   * to C-terminal end using standard single-letter amino acid codes. Uppercase
   * shall be used for L-amino acids and lowercase for D-amino acids. Transcribed
   * SubstanceProteins will always be described using the translated sequence; for
   * synthetic peptide containing amino acids that are not represented with a
   * single letter code an X should be used within the sequence. The modified amino
   * acids will be distinguished by their position in the sequence.
   */
  sequence?: Maybe<Scalars['code']>;
  /**
   * The sequence information shall be provided enumerating the amino acids from N-
   * to C-terminal end using standard single-letter amino acid codes. Uppercase
   * shall be used for L-amino acids and lowercase for D-amino acids. Transcribed
   * SubstanceProteins will always be described using the translated sequence; for
   * synthetic peptide containing amino acids that are not represented with a
   * single letter code an X should be used within the sequence. The modified amino
   * acids will be distinguished by their position in the sequence.
   */
  sequenceAttachment?: Maybe<Attachment>;
  /**
   * Index of primary sequences of amino acids linked through peptide bonds in
   * order of decreasing length. Sequences of the same length will be ordered by
   * molecular weight. Subunits that have identical sequences will be repeated and
   * have sequential subscripts.
   */
  subunit?: Maybe<Scalars['code']>;
};

/**
 * SubstanceReferenceInformation
 *     Todo.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type SubstanceReferenceInformation = {
  __typename?: 'SubstanceReferenceInformation';
  /** Todo. */
  classification?: Maybe<Array<Maybe<SubstanceReferenceInformationClassification>>>;
  /** Todo. */
  comment?: Maybe<Scalars['code']>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Todo. */
  gene?: Maybe<Array<Maybe<SubstanceReferenceInformationGene>>>;
  /** Todo. */
  geneElement?: Maybe<Array<Maybe<SubstanceReferenceInformationGeneElement>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** Todo. */
  target?: Maybe<Array<Maybe<SubstanceReferenceInformationTarget>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * SubstanceReferenceInformation.Classification
 *     Todo.
 */
export type SubstanceReferenceInformationClassification = {
  __typename?: 'SubstanceReferenceInformationClassification';
  /** Todo. */
  classification?: Maybe<CodeableConcept>;
  /** Todo. */
  domain?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Todo. */
  source?: Maybe<Array<Maybe<DocumentReference>>>;
  /** Todo. */
  subtype?: Maybe<Array<Maybe<CodeableConcept>>>;
};

/**
 * SubstanceReferenceInformation.Gene
 *     Todo.
 */
export type SubstanceReferenceInformationGene = {
  __typename?: 'SubstanceReferenceInformationGene';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Todo. */
  gene?: Maybe<CodeableConcept>;
  /** Todo. */
  geneSequenceOrigin?: Maybe<CodeableConcept>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Todo. */
  source?: Maybe<Array<Maybe<DocumentReference>>>;
};

/**
 * SubstanceReferenceInformation.GeneElement
 *     Todo.
 */
export type SubstanceReferenceInformationGeneElement = {
  __typename?: 'SubstanceReferenceInformationGeneElement';
  /** Todo. */
  element?: Maybe<Identifier>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Todo. */
  source?: Maybe<Array<Maybe<DocumentReference>>>;
  /** Todo. */
  type?: Maybe<CodeableConcept>;
};

/**
 * SubstanceReferenceInformation.Target
 *     Todo.
 */
export type SubstanceReferenceInformationTarget = {
  __typename?: 'SubstanceReferenceInformationTarget';
  /** None */
  amountQuantity?: Maybe<Quantity>;
  /** None */
  amountRange?: Maybe<Range>;
  /** None */
  amountString?: Maybe<Scalars['code']>;
  /** Todo. */
  amountType?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Todo. */
  interaction?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Todo. */
  organism?: Maybe<CodeableConcept>;
  /** Todo. */
  organismType?: Maybe<CodeableConcept>;
  /** Todo. */
  source?: Maybe<Array<Maybe<DocumentReference>>>;
  /** Todo. */
  target?: Maybe<Identifier>;
  /** Todo. */
  type?: Maybe<CodeableConcept>;
};

/**
 * SubstanceSourceMaterial
 *     Source material shall capture information on the taxonomic and anatomical
 *     origins as well as the fraction of a material that can result in or can be
 *     modified to form a substance. This set of data elements shall be used to
 *     define polymer substances isolated from biological matrices. Taxonomic and
 *     anatomical origins shall be described using a controlled vocabulary as
 *     required. This information is captured for naturally derived polymers ( .
 *     starch) and structurally diverse substances. For Organisms belonging to the
 *     Kingdom Plantae the Substance level defines the fresh material of a single
 *     species or infraspecies, the Herbal Drug and the Herbal preparation. For
 *     Herbal preparations, the fraction information will be captured at the
 *     Substance information level and additional information for herbal extracts
 *     will be captured at the Specified Substance Group 1 information level. See for
 *     further explanation the Substance Class: Structurally Diverse and the herbal
 *     annex.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type SubstanceSourceMaterial = {
  __typename?: 'SubstanceSourceMaterial';
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The country where the plant material is harvested or the countries where the
   * plasma is sourced from as laid down in accordance with the Plasma Master File.
   * For “Plasma-derived substances” the attribute country of origin provides
   * information about the countries used for the manufacturing of the Cryopoor
   * plama or Crioprecipitate.
   */
  countryOfOrigin?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Stage of life for animals, plants, insects and microorganisms. This
   * information shall be provided only when the substance is significantly
   * different in these stages (e.g. foetal bovine serum).
   */
  developmentStage?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Many complex materials are fractions of parts of plants, animals, or minerals.
   * Fraction elements are often necessary to define both Substances and Specified
   * Group 1 Substances. For substances derived from Plants, fraction information
   * will be captured at the Substance information level ( . Oils, Juices and
   * Exudates). Additional information for Extracts, such as extraction solvent
   * composition, will be captured at the Specified Substance Group 1 information
   * level. For plasma-derived products fraction information will be captured at
   * the Substance and the Specified Substance Group 1 levels.
   */
  fractionDescription?: Maybe<Array<Maybe<SubstanceSourceMaterialFractionDescription>>>;
  /**
   * The place/region where the plant is harvested or the places/regions where the
   * animal source material has its habitat.
   */
  geographicalLocation?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * This subclause describes the organism which the substance is derived from. For
   * vaccines, the parent organism shall be specified based on these subclause
   * elements. As an example, full taxonomy will be described for the Substance
   * Name: ., Leaf.
   */
  organism?: Maybe<SubstanceSourceMaterialOrganism>;
  /**
   * The unique identifier associated with the source material parent organism
   * shall be specified.
   */
  organismId?: Maybe<Identifier>;
  /**
   * The organism accepted Scientific name shall be provided based on the organism
   * taxonomy.
   */
  organismName?: Maybe<Scalars['code']>;
  /**
   * The parent of the herbal drug Ginkgo biloba, Leaf is the substance ID of the
   * substance (fresh) of Ginkgo biloba L. or Ginkgo biloba L. (Whole plant).
   */
  parentSubstanceId?: Maybe<Array<Maybe<Identifier>>>;
  /** The parent substance of the Herbal Drug, or Herbal preparation. */
  parentSubstanceName?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** To do. */
  partDescription?: Maybe<Array<Maybe<SubstanceSourceMaterialPartDescription>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * General high level classification of the source material specific to the
   * origin of the material.
   */
  sourceMaterialClass?: Maybe<CodeableConcept>;
  /** The state of the source material when extracted. */
  sourceMaterialState?: Maybe<CodeableConcept>;
  /**
   * The type of the source material shall be specified based on a controlled
   * vocabulary. For vaccines, this subclause refers to the class of infectious
   * agent.
   */
  sourceMaterialType?: Maybe<CodeableConcept>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * SubstanceSourceMaterial.Author
 *     Source material shall capture information on the taxonomic and anatomical
 *     origins as well as the fraction of a material that can result in or can be
 *     modified to form a substance. This set of data elements shall be used to
 *     define polymer substances isolated from biological matrices. Taxonomic and
 *     anatomical origins shall be described using a controlled vocabulary as
 *     required. This information is captured for naturally derived polymers ( .
 *     starch) and structurally diverse substances. For Organisms belonging to the
 *     Kingdom Plantae the Substance level defines the fresh material of a single
 *     species or infraspecies, the Herbal Drug and the Herbal preparation. For
 *     Herbal preparations, the fraction information will be captured at the
 *     Substance information level and additional information for herbal extracts
 *     will be captured at the Specified Substance Group 1 information level. See for
 *     further explanation the Substance Class: Structurally Diverse and the herbal
 *     annex.
 */
export type SubstanceSourceMaterialAuthor = {
  __typename?: 'SubstanceSourceMaterialAuthor';
  /**
   * The author of an organism species shall be specified. The author year of an
   * organism shall also be specified when applicable; refers to the year in which
   * the first author(s) published the infraspecific plant/animal name (of any
   * rank).
   */
  authorDescription?: Maybe<Scalars['code']>;
  /**
   * The type of author of an organism species shall be specified. The
   * parenthetical author of an organism species refers to the first author who
   * published the plant/animal name (of any rank). The primary author of an
   * organism species refers to the first author(s), who validly published the
   * plant/animal name.
   */
  authorType?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * SubstanceSourceMaterial.FractionDescription
 *     Source material shall capture information on the taxonomic and anatomical
 *     origins as well as the fraction of a material that can result in or can be
 *     modified to form a substance. This set of data elements shall be used to
 *     define polymer substances isolated from biological matrices. Taxonomic and
 *     anatomical origins shall be described using a controlled vocabulary as
 *     required. This information is captured for naturally derived polymers ( .
 *     starch) and structurally diverse substances. For Organisms belonging to the
 *     Kingdom Plantae the Substance level defines the fresh material of a single
 *     species or infraspecies, the Herbal Drug and the Herbal preparation. For
 *     Herbal preparations, the fraction information will be captured at the
 *     Substance information level and additional information for herbal extracts
 *     will be captured at the Specified Substance Group 1 information level. See for
 *     further explanation the Substance Class: Structurally Diverse and the herbal
 *     annex.
 */
export type SubstanceSourceMaterialFractionDescription = {
  __typename?: 'SubstanceSourceMaterialFractionDescription';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * This element is capturing information about the fraction of a plant part, or
   * human plasma for fractionation.
   */
  fraction?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The specific type of the material constituting the component. For Herbal
   * preparations the particulars of the extracts (liquid/dry) is described in
   * Specified Substance Group 1.
   */
  materialType?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * SubstanceSourceMaterial.Hybrid
 *     Source material shall capture information on the taxonomic and anatomical
 *     origins as well as the fraction of a material that can result in or can be
 *     modified to form a substance. This set of data elements shall be used to
 *     define polymer substances isolated from biological matrices. Taxonomic and
 *     anatomical origins shall be described using a controlled vocabulary as
 *     required. This information is captured for naturally derived polymers ( .
 *     starch) and structurally diverse substances. For Organisms belonging to the
 *     Kingdom Plantae the Substance level defines the fresh material of a single
 *     species or infraspecies, the Herbal Drug and the Herbal preparation. For
 *     Herbal preparations, the fraction information will be captured at the
 *     Substance information level and additional information for herbal extracts
 *     will be captured at the Specified Substance Group 1 information level. See for
 *     further explanation the Substance Class: Structurally Diverse and the herbal
 *     annex.
 */
export type SubstanceSourceMaterialHybrid = {
  __typename?: 'SubstanceSourceMaterialHybrid';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** The hybrid type of an organism shall be specified. */
  hybridType?: Maybe<CodeableConcept>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The identifier of the maternal species constituting the hybrid organism shall
   * be specified based on a controlled vocabulary. For plants, the parents aren’t
   * always known, and it is unlikely that it will be known which is maternal and
   * which is paternal.
   */
  maternalOrganismId?: Maybe<Scalars['code']>;
  /**
   * The name of the maternal species constituting the hybrid organism shall be
   * specified. For plants, the parents aren’t always known, and it is unlikely
   * that it will be known which is maternal and which is paternal.
   */
  maternalOrganismName?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The identifier of the paternal species constituting the hybrid organism shall
   * be specified based on a controlled vocabulary.
   */
  paternalOrganismId?: Maybe<Scalars['code']>;
  /**
   * The name of the paternal species constituting the hybrid organism shall be
   * specified.
   */
  paternalOrganismName?: Maybe<Scalars['code']>;
};

/**
 * SubstanceSourceMaterial.Organism
 *     Source material shall capture information on the taxonomic and anatomical
 *     origins as well as the fraction of a material that can result in or can be
 *     modified to form a substance. This set of data elements shall be used to
 *     define polymer substances isolated from biological matrices. Taxonomic and
 *     anatomical origins shall be described using a controlled vocabulary as
 *     required. This information is captured for naturally derived polymers ( .
 *     starch) and structurally diverse substances. For Organisms belonging to the
 *     Kingdom Plantae the Substance level defines the fresh material of a single
 *     species or infraspecies, the Herbal Drug and the Herbal preparation. For
 *     Herbal preparations, the fraction information will be captured at the
 *     Substance information level and additional information for herbal extracts
 *     will be captured at the Specified Substance Group 1 information level. See for
 *     further explanation the Substance Class: Structurally Diverse and the herbal
 *     annex.
 */
export type SubstanceSourceMaterialOrganism = {
  __typename?: 'SubstanceSourceMaterialOrganism';
  /** 4.9.13.6.1 Author type (Conditional). */
  author?: Maybe<Array<Maybe<SubstanceSourceMaterialAuthor>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** The family of an organism shall be specified. */
  family?: Maybe<CodeableConcept>;
  /**
   * The genus of an organism shall be specified; refers to the Latin epithet of
   * the genus element of the plant/animal scientific name; it is present in names
   * for genera, species and infraspecies.
   */
  genus?: Maybe<CodeableConcept>;
  /** 4.9.13.8.1 Hybrid species maternal organism ID (Optional). */
  hybrid?: Maybe<SubstanceSourceMaterialHybrid>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * The intraspecific description of an organism shall be specified based on a
   * controlled vocabulary. For Influenza Vaccine, the intraspecific description
   * shall contain the syntax of the antigen in line with the WHO convention.
   */
  intraspecificDescription?: Maybe<Scalars['code']>;
  /** The Intraspecific type of an organism shall be specified. */
  intraspecificType?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** 4.9.13.7.1 Kingdom (Conditional). */
  organismGeneral?: Maybe<SubstanceSourceMaterialOrganismGeneral>;
  /**
   * The species of an organism shall be specified; refers to the Latin epithet of
   * the species of the plant/animal; it is present in names for species and
   * infraspecies.
   */
  species?: Maybe<CodeableConcept>;
};

/**
 * SubstanceSourceMaterial.OrganismGeneral
 *     Source material shall capture information on the taxonomic and anatomical
 *     origins as well as the fraction of a material that can result in or can be
 *     modified to form a substance. This set of data elements shall be used to
 *     define polymer substances isolated from biological matrices. Taxonomic and
 *     anatomical origins shall be described using a controlled vocabulary as
 *     required. This information is captured for naturally derived polymers ( .
 *     starch) and structurally diverse substances. For Organisms belonging to the
 *     Kingdom Plantae the Substance level defines the fresh material of a single
 *     species or infraspecies, the Herbal Drug and the Herbal preparation. For
 *     Herbal preparations, the fraction information will be captured at the
 *     Substance information level and additional information for herbal extracts
 *     will be captured at the Specified Substance Group 1 information level. See for
 *     further explanation the Substance Class: Structurally Diverse and the herbal
 *     annex.
 */
export type SubstanceSourceMaterialOrganismGeneral = {
  __typename?: 'SubstanceSourceMaterialOrganismGeneral';
  /** The class of an organism shall be specified. */
  class?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The kingdom of an organism shall be specified. */
  kingdom?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The order of an organism shall be specified,. */
  order?: Maybe<CodeableConcept>;
  /** The phylum of an organism shall be specified. */
  phylum?: Maybe<CodeableConcept>;
};

/**
 * SubstanceSourceMaterial.PartDescription
 *     Source material shall capture information on the taxonomic and anatomical
 *     origins as well as the fraction of a material that can result in or can be
 *     modified to form a substance. This set of data elements shall be used to
 *     define polymer substances isolated from biological matrices. Taxonomic and
 *     anatomical origins shall be described using a controlled vocabulary as
 *     required. This information is captured for naturally derived polymers ( .
 *     starch) and structurally diverse substances. For Organisms belonging to the
 *     Kingdom Plantae the Substance level defines the fresh material of a single
 *     species or infraspecies, the Herbal Drug and the Herbal preparation. For
 *     Herbal preparations, the fraction information will be captured at the
 *     Substance information level and additional information for herbal extracts
 *     will be captured at the Specified Substance Group 1 information level. See for
 *     further explanation the Substance Class: Structurally Diverse and the herbal
 *     annex.
 */
export type SubstanceSourceMaterialPartDescription = {
  __typename?: 'SubstanceSourceMaterialPartDescription';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Entity of anatomical origin of source material within an organism. */
  part?: Maybe<CodeableConcept>;
  /**
   * The detailed anatomic location when the part can be extracted from different
   * anatomical locations of the organism. Multiple alternative locations may
   * apply.
   */
  partLocation?: Maybe<CodeableConcept>;
};

/**
 * SubstanceSpecification
 *     The detailed description of a substance, typically at a level beyond what is
 *     used for prescribing.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type SubstanceSpecification = {
  __typename?: 'SubstanceSpecification';
  /** Codes associated with the substance. */
  code?: Maybe<Array<Maybe<SubstanceSpecificationCode>>>;
  /** Textual comment about this record of a substance. */
  comment?: Maybe<Scalars['code']>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Textual description of the substance. */
  description?: Maybe<Scalars['code']>;
  /** If the substance applies to only human or veterinary use. */
  domain?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** Identifier by which this substance is known. */
  identifier?: Maybe<Identifier>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Moiety, for structural modifications. */
  moiety?: Maybe<Array<Maybe<SubstanceSpecificationMoiety>>>;
  /**
   * The molecular weight or weight range (for proteins, polymers or nucleic
   * acids).
   */
  molecularWeight?: Maybe<Array<Maybe<SubstanceSpecificationMolecularWeight>>>;
  /** Names applicable to this substance. */
  name?: Maybe<Array<Maybe<SubstanceSpecificationName>>>;
  /** Data items specific to nucleic acids. */
  nucleicAcid?: Maybe<SubstanceNucleicAcid>;
  /** Data items specific to polymers. */
  polymer?: Maybe<SubstancePolymer>;
  /**
   * General specifications for this substance, including how it is related to
   * other substances.
   */
  property?: Maybe<Array<Maybe<SubstanceSpecificationProperty>>>;
  /** Data items specific to proteins. */
  protein?: Maybe<SubstanceProtein>;
  /** General information detailing this substance. */
  referenceInformation?: Maybe<SubstanceReferenceInformation>;
  /** A link between this substance and another, with details of the relationship. */
  relationship?: Maybe<Array<Maybe<SubstanceSpecificationRelationship>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** Supporting literature. */
  source?: Maybe<Array<Maybe<DocumentReference>>>;
  /** Material or taxonomic/anatomical source for the substance. */
  sourceMaterial?: Maybe<SubstanceSourceMaterial>;
  /** Status of substance within the catalogue e.g. approved. */
  status?: Maybe<CodeableConcept>;
  /** Structural information. */
  structure?: Maybe<SubstanceSpecificationStructure>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** High level categorization, e.g. polymer or nucleic acid. */
  type?: Maybe<CodeableConcept>;
};

/**
 * SubstanceSpecification.Code
 *     The detailed description of a substance, typically at a level beyond what is
 *     used for prescribing.
 */
export type SubstanceSpecificationCode = {
  __typename?: 'SubstanceSpecificationCode';
  /** The specific code. */
  code?: Maybe<CodeableConcept>;
  /** Any comment can be provided in this field, if necessary. */
  comment?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Supporting literature. */
  source?: Maybe<Array<Maybe<DocumentReference>>>;
  /** Status of the code assignment. */
  status?: Maybe<CodeableConcept>;
  /**
   * The date at which the code status is changed as part of the terminology
   * maintenance.
   */
  statusDate?: Maybe<Scalars['code']>;
};

/**
 * SubstanceSpecification.Isotope
 *     The detailed description of a substance, typically at a level beyond what is
 *     used for prescribing.
 */
export type SubstanceSpecificationIsotope = {
  __typename?: 'SubstanceSpecificationIsotope';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Half life - for a non-natural nuclide. */
  halfLife?: Maybe<Quantity>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Substance identifier for each non-natural or radioisotope. */
  identifier?: Maybe<Identifier>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The molecular weight or weight range (for proteins, polymers or nucleic
   * acids).
   */
  molecularWeight?: Maybe<SubstanceSpecificationMolecularWeight>;
  /** Substance name for each non-natural or radioisotope. */
  name?: Maybe<CodeableConcept>;
  /** The type of isotopic substitution present in a single substance. */
  substitution?: Maybe<CodeableConcept>;
};

/**
 * SubstanceSpecification.Moiety
 *     The detailed description of a substance, typically at a level beyond what is
 *     used for prescribing.
 */
export type SubstanceSpecificationMoiety = {
  __typename?: 'SubstanceSpecificationMoiety';
  /** None */
  amountQuantity?: Maybe<Quantity>;
  /** None */
  amountString?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Identifier by which this moiety substance is known. */
  identifier?: Maybe<Identifier>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Molecular formula. */
  molecularFormula?: Maybe<Scalars['code']>;
  /** Textual name for this moiety substance. */
  name?: Maybe<Scalars['code']>;
  /** Optical activity type. */
  opticalActivity?: Maybe<CodeableConcept>;
  /** Role that the moiety is playing. */
  role?: Maybe<CodeableConcept>;
  /** Stereochemistry type. */
  stereochemistry?: Maybe<CodeableConcept>;
};

/**
 * SubstanceSpecification.MolecularWeight
 *     The detailed description of a substance, typically at a level beyond what is
 *     used for prescribing.
 */
export type SubstanceSpecificationMolecularWeight = {
  __typename?: 'SubstanceSpecificationMolecularWeight';
  /**
   * Used to capture quantitative values for a variety of elements. If only limits
   * are given, the arithmetic mean would be the average. If only a single definite
   * value for a given element is given, it would be captured in this field.
   */
  amount?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The method by which the molecular weight was determined. */
  method?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Type of molecular weight such as exact, average (also known as. number
   * average), weight average.
   */
  type?: Maybe<CodeableConcept>;
};

/**
 * SubstanceSpecification.Name
 *     The detailed description of a substance, typically at a level beyond what is
 *     used for prescribing.
 */
export type SubstanceSpecificationName = {
  __typename?: 'SubstanceSpecificationName';
  /**
   * The use context of this name for example if there is a different name a drug
   * active ingredient as opposed to a food colour additive.
   */
  domain?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The jurisdiction where this name applies. */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** Language of the name. */
  language?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The actual name. */
  name: Scalars['code'];
  /** Details of the official nature of this name. */
  official?: Maybe<Array<Maybe<SubstanceSpecificationOfficial>>>;
  /** If this is the preferred name for this substance. */
  preferred?: Maybe<Scalars['code']>;
  /** Supporting literature. */
  source?: Maybe<Array<Maybe<DocumentReference>>>;
  /** The status of the name. */
  status?: Maybe<CodeableConcept>;
  /** A synonym of this name. */
  synonym?: Maybe<Array<Maybe<SubstanceSpecificationName>>>;
  /** A translation for this name. */
  translation?: Maybe<Array<Maybe<SubstanceSpecificationName>>>;
  /** Name type. */
  type?: Maybe<CodeableConcept>;
};

/**
 * SubstanceSpecification.Official
 *     The detailed description of a substance, typically at a level beyond what is
 *     used for prescribing.
 */
export type SubstanceSpecificationOfficial = {
  __typename?: 'SubstanceSpecificationOfficial';
  /** Which authority uses this official name. */
  authority?: Maybe<CodeableConcept>;
  /** Date of official name change. */
  date?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The status of the official name. */
  status?: Maybe<CodeableConcept>;
};

/**
 * SubstanceSpecification.Property
 *     The detailed description of a substance, typically at a level beyond what is
 *     used for prescribing.
 */
export type SubstanceSpecificationProperty = {
  __typename?: 'SubstanceSpecificationProperty';
  /** None */
  amountQuantity?: Maybe<Quantity>;
  /** None */
  amountString?: Maybe<Scalars['code']>;
  /** A category for this property, e.g. Physical, Chemical, Enzymatic. */
  category?: Maybe<CodeableConcept>;
  /** Property type e.g. viscosity, pH, isoelectric point. */
  code?: Maybe<CodeableConcept>;
  /** None */
  definingSubstanceCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  definingSubstanceReference?: Maybe<SubstanceSpecificationPropertyDefiningSubstanceReference>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Parameters that were used in the measurement of a property (e.g. for
   * viscosity: measured at 20C with a pH of 7.1).
   */
  parameters?: Maybe<Scalars['code']>;
};

export type SubstanceSpecificationPropertyDefiningSubstanceReference = Substance | SubstanceSpecification;

/**
 * SubstanceSpecification.Relationship
 *     The detailed description of a substance, typically at a level beyond what is
 *     used for prescribing.
 */
export type SubstanceSpecificationRelationship = {
  __typename?: 'SubstanceSpecificationRelationship';
  /** None */
  amountQuantity?: Maybe<Quantity>;
  /** None */
  amountRange?: Maybe<Range>;
  /** None */
  amountRatio?: Maybe<Ratio>;
  /** For use when the numeric. */
  amountRatioLowLimit?: Maybe<Ratio>;
  /** None */
  amountString?: Maybe<Scalars['code']>;
  /**
   * An operator for the amount, for example "average", "approximately", "less
   * than".
   */
  amountType?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * For example where an enzyme strongly bonds with a particular substance, this
   * is a defining relationship for that enzyme, out of several possible substance
   * relationships.
   */
  isDefining?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** For example "salt to parent", "active moiety", "starting material". */
  relationship?: Maybe<CodeableConcept>;
  /** Supporting literature. */
  source?: Maybe<Array<Maybe<DocumentReference>>>;
  /** None */
  substanceCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  substanceReference?: Maybe<SubstanceSpecification>;
};

/**
 * SubstanceSpecification.Representation
 *     The detailed description of a substance, typically at a level beyond what is
 *     used for prescribing.
 */
export type SubstanceSpecificationRepresentation = {
  __typename?: 'SubstanceSpecificationRepresentation';
  /** An attached file with the structural representation. */
  attachment?: Maybe<Attachment>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The structural representation as text string in a format e.g. InChI, SMILES,
   * MOLFILE, CDX.
   */
  representation?: Maybe<Scalars['code']>;
  /** The type of structure (e.g. Full, Partial, Representative). */
  type?: Maybe<CodeableConcept>;
};

/**
 * SubstanceSpecification.Structure
 *     The detailed description of a substance, typically at a level beyond what is
 *     used for prescribing.
 */
export type SubstanceSpecificationStructure = {
  __typename?: 'SubstanceSpecificationStructure';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Applicable for single substances that contain a radionuclide or a non-natural
   * isotopic ratio.
   */
  isotope?: Maybe<Array<Maybe<SubstanceSpecificationIsotope>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Molecular formula. */
  molecularFormula?: Maybe<Scalars['code']>;
  /**
   * Specified per moiety according to the Hill system, i.e. first C, then H, then
   * alphabetical, each moiety separated by a dot.
   */
  molecularFormulaByMoiety?: Maybe<Scalars['code']>;
  /**
   * The molecular weight or weight range (for proteins, polymers or nucleic
   * acids).
   */
  molecularWeight?: Maybe<SubstanceSpecificationMolecularWeight>;
  /** Optical activity type. */
  opticalActivity?: Maybe<CodeableConcept>;
  /** Molecular structural representation. */
  representation?: Maybe<Array<Maybe<SubstanceSpecificationRepresentation>>>;
  /** Supporting literature. */
  source?: Maybe<Array<Maybe<DocumentReference>>>;
  /** Stereochemistry type. */
  stereochemistry?: Maybe<CodeableConcept>;
};

/**
 * SupplyDelivery
 *     Record of delivery of what is supplied.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type SupplyDelivery = {
  __typename?: 'SupplyDelivery';
  /** A plan, proposal or order that is fulfilled in whole or in part by this event. */
  basedOn?: Maybe<Array<Maybe<SupplyRequest>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Identification of the facility/location where the Supply was shipped to, as
   * part of the dispense event.
   */
  destination?: Maybe<Location>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifier for the supply delivery event that is used to identify it across
   * multiple disparate systems.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  occurrenceDateTime?: Maybe<Scalars['code']>;
  /** None */
  occurrencePeriod?: Maybe<Period>;
  /** None */
  occurrenceTiming?: Maybe<Timing>;
  /** A larger event of which this particular event is a component or step. */
  partOf?: Maybe<Array<Maybe<SupplyDeliveryPartOf>>>;
  /** A link to a resource representing the person whom the delivered item is for. */
  patient?: Maybe<Patient>;
  /** Identifies the person who picked up the Supply. */
  receiver?: Maybe<Array<Maybe<SupplyDeliveryReceiver>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** A code specifying the state of the dispense event. */
  status?: Maybe<Scalars['code']>;
  /** The item that is being delivered or has been supplied. */
  suppliedItem?: Maybe<SupplyDeliverySuppliedItem>;
  /** The individual responsible for dispensing the medication, supplier or device. */
  supplier?: Maybe<SupplyDeliverySupplier>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * Indicates the type of dispensing event that is performed. Examples include:
   * Trial Fill, Completion of Trial, Partial Fill, Emergency Fill, Samples, etc.
   */
  type?: Maybe<CodeableConcept>;
};

export type SupplyDeliveryPartOf = Contract | SupplyDelivery;

export type SupplyDeliveryReceiver = Practitioner | PractitionerRole;

/**
 * SupplyDelivery.SuppliedItem
 *     Record of delivery of what is supplied.
 */
export type SupplyDeliverySuppliedItem = {
  __typename?: 'SupplyDeliverySuppliedItem';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** None */
  itemCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  itemReference?: Maybe<SupplyDeliverySuppliedItemItemReference>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The amount of supply that has been dispensed. Includes unit of measure. */
  quantity?: Maybe<Quantity>;
};

export type SupplyDeliverySuppliedItemItemReference = Device | Medication | Substance;

export type SupplyDeliverySupplier = Organization | Practitioner | PractitionerRole;

/**
 * SupplyRequest
 *     A record of a request for a medication, substance or device used in the
 *     healthcare setting.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type SupplyRequest = {
  __typename?: 'SupplyRequest';
  /** When the request was made. */
  authoredOn?: Maybe<Scalars['code']>;
  /**
   * Category of supply, e.g.  central, non-stock, etc. This is used to support
   * work flows associated with the supply process.
   */
  category?: Maybe<CodeableConcept>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** Where the supply is expected to come from. */
  deliverFrom?: Maybe<SupplyRequestDeliverFrom>;
  /** Where the supply is destined to go. */
  deliverTo?: Maybe<SupplyRequestDeliverTo>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Business identifiers assigned to this SupplyRequest by the author and/or other
   * systems. These identifiers remain constant as the resource is updated and
   * propagates from server to server.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** None */
  itemCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  itemReference?: Maybe<SupplyRequestItemReference>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  occurrenceDateTime?: Maybe<Scalars['code']>;
  /** None */
  occurrencePeriod?: Maybe<Period>;
  /** None */
  occurrenceTiming?: Maybe<Timing>;
  /**
   * Specific parameters for the ordered item.  For example, the size of the
   * indicated item.
   */
  parameter?: Maybe<Array<Maybe<SupplyRequestParameter>>>;
  /**
   * Indicates how quickly this SupplyRequest should be addressed with respect to
   * other requests.
   */
  priority?: Maybe<Scalars['code']>;
  /** The amount that is being ordered of the indicated item. */
  quantity: Quantity;
  /** The reason why the supply item was requested. */
  reasonCode?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The reason why the supply item was requested. */
  reasonReference?: Maybe<Array<Maybe<SupplyRequestReasonReference>>>;
  /** The device, practitioner, etc. who initiated the request. */
  requester?: Maybe<SupplyRequestRequester>;
  resourceType?: Maybe<Scalars['String']>;
  /** Status of the supply request. */
  status?: Maybe<Scalars['code']>;
  /** Who is intended to fulfill the request. */
  supplier?: Maybe<Array<Maybe<SupplyRequestSupplier>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

export type SupplyRequestDeliverFrom = Location | Organization;

export type SupplyRequestDeliverTo = Location | Organization | Patient;

export type SupplyRequestItemReference = Device | Medication | Substance;

/**
 * SupplyRequest.Parameter
 *     A record of a request for a medication, substance or device used in the
 *     healthcare setting.
 */
export type SupplyRequestParameter = {
  __typename?: 'SupplyRequestParameter';
  /** A code or string that identifies the device detail being asserted. */
  code?: Maybe<CodeableConcept>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  valueBoolean?: Maybe<Scalars['code']>;
  /** None */
  valueCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  valueQuantity?: Maybe<Quantity>;
  /** None */
  valueRange?: Maybe<Range>;
};

export type SupplyRequestReasonReference = Condition | DiagnosticReport | DocumentReference | Observation;

export type SupplyRequestRequester = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type SupplyRequestSupplier = HealthcareService | Organization;

/**
 * Task
 *     A task to be performed.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type Task = {
  __typename?: 'Task';
  /** The date and time this task was created. */
  authoredOn?: Maybe<Scalars['code']>;
  /**
   * BasedOn refers to a higher-level authorization that triggered the creation of
   * the task.  It references a "request" resource such as a ServiceRequest,
   * MedicationRequest, ServiceRequest, CarePlan, etc. which is distinct from the
   * "request" resource the task is seeking to fulfill.  This latter resource is
   * referenced by FocusOn.  For example, based on a ServiceRequest (= BasedOn), a
   * task is created to fulfill a procedureRequest ( = FocusOn ) to collect a
   * specimen from a patient.
   */
  basedOn?: Maybe<Array<Maybe<Resource>>>;
  /** Contains business-specific nuances of the business state. */
  businessStatus?: Maybe<CodeableConcept>;
  /** A name or code (or both) briefly describing what the task involves. */
  code?: Maybe<CodeableConcept>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** A free-text description of what is to be performed. */
  description?: Maybe<Scalars['code']>;
  /**
   * The healthcare event  (e.g. a patient and healthcare provider interaction)
   * during which this task was created.
   */
  encounter?: Maybe<Encounter>;
  /**
   * Identifies the time action was first taken against the task (start) and/or the
   * time final action was taken against the task prior to marking it as completed
   * (end).
   */
  executionPeriod?: Maybe<Period>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** The request being actioned or the resource being manipulated by this task. */
  focus?: Maybe<Resource>;
  /**
   * The entity who benefits from the performance of the service specified in the
   * task (e.g., the patient).
   */
  for?: Maybe<Resource>;
  /**
   * An identifier that links together multiple tasks and other requests that were
   * created in the same context.
   */
  groupIdentifier?: Maybe<Identifier>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** The business identifier for this task. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** Additional information that may be needed in the execution of the task. */
  input?: Maybe<Array<Maybe<TaskInput>>>;
  /**
   * The URL pointing to a *FHIR*-defined protocol, guideline, orderset or other
   * definition that is adhered to in whole or in part by this Task.
   */
  instantiatesCanonical?: Maybe<Scalars['code']>;
  /**
   * The URL pointing to an *externally* maintained  protocol, guideline, orderset
   * or other definition that is adhered to in whole or in part by this Task.
   */
  instantiatesUri?: Maybe<Scalars['code']>;
  /**
   * Insurance plans, coverage extensions, pre-authorizations and/or pre-
   * determinations that may be relevant to the Task.
   */
  insurance?: Maybe<Array<Maybe<TaskInsurance>>>;
  /**
   * Indicates the "level" of actionability associated with the Task, i.e. i+R[9]Cs
   * this a proposed task, a planned task, an actionable task, etc.
   */
  intent: Scalars['code'];
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** The date and time of last modification to this task. */
  lastModified?: Maybe<Scalars['code']>;
  /** Principal physical location where the this task is performed. */
  location?: Maybe<Location>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Free-text information captured about the task as it progresses. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** Outputs produced by the Task. */
  output?: Maybe<Array<Maybe<TaskOutput>>>;
  /** Individual organization or Device currently responsible for task execution. */
  owner?: Maybe<TaskOwner>;
  /** Task that this particular task is part of. */
  partOf?: Maybe<Array<Maybe<Task>>>;
  /** The kind of participant that should perform the task. */
  performerType?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Indicates how quickly the Task should be addressed with respect to other
   * requests.
   */
  priority?: Maybe<Scalars['code']>;
  /** A description or code indicating why this task needs to be performed. */
  reasonCode?: Maybe<CodeableConcept>;
  /** A resource reference indicating why this task needs to be performed. */
  reasonReference?: Maybe<Resource>;
  /**
   * Links to Provenance records for past versions of this Task that identify key
   * state transitions or updates that are likely to be relevant to a user looking
   * at the current version of the task.
   */
  relevantHistory?: Maybe<Array<Maybe<Provenance>>>;
  /** The creator of the task. */
  requester?: Maybe<TaskRequester>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * If the Task.focus is a request resource and the task is seeking fulfillment
   * (i.e. is asking for the request to be actioned), this element identifies any
   * limitations on what parts of the referenced request should be actioned.
   */
  restriction?: Maybe<TaskRestriction>;
  /** The current status of the task. */
  status: Scalars['code'];
  /** An explanation as to why this task is held, failed, was refused, etc. */
  statusReason?: Maybe<CodeableConcept>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * Task.Input
 *     A task to be performed.
 */
export type TaskInput = {
  __typename?: 'TaskInput';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A code or description indicating how the input is intended to be used as part
   * of the task execution.
   */
  type: CodeableConcept;
  /** None */
  valueAddress?: Maybe<Address>;
  /** None */
  valueAge?: Maybe<Quantity>;
  /** None */
  valueAnnotation?: Maybe<Annotation>;
  /** None */
  valueAttachment?: Maybe<Attachment>;
  /** None */
  valueBase64Binary?: Maybe<Scalars['code']>;
  /** None */
  valueBoolean?: Maybe<Scalars['code']>;
  /** None */
  valueCanonical?: Maybe<Scalars['code']>;
  /** None */
  valueCode?: Maybe<Scalars['code']>;
  /** None */
  valueCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  valueCoding?: Maybe<Coding>;
  /** None */
  valueContactDetail?: Maybe<ContactDetail>;
  /** None */
  valueContactPoint?: Maybe<ContactPoint>;
  /** None */
  valueContributor?: Maybe<Contributor>;
  /** None */
  valueCount?: Maybe<Quantity>;
  /** None */
  valueDataRequirement?: Maybe<DataRequirement>;
  /** None */
  valueDate?: Maybe<Scalars['code']>;
  /** None */
  valueDateTime?: Maybe<Scalars['code']>;
  /** None */
  valueDecimal?: Maybe<Scalars['code']>;
  /** None */
  valueDistance?: Maybe<Quantity>;
  /** None */
  valueDosage?: Maybe<Dosage>;
  /** None */
  valueDuration?: Maybe<Quantity>;
  /** None */
  valueExpression?: Maybe<Expression>;
  /** None */
  valueHumanName?: Maybe<HumanName>;
  /** None */
  valueId?: Maybe<Scalars['code']>;
  /** None */
  valueIdentifier?: Maybe<Identifier>;
  /** None */
  valueInstant?: Maybe<Scalars['code']>;
  /** None */
  valueInteger?: Maybe<Scalars['code']>;
  /** None */
  valueMarkdown?: Maybe<Scalars['code']>;
  /** None */
  valueMeta?: Maybe<Meta>;
  /** None */
  valueMoney?: Maybe<Money>;
  /** None */
  valueOid?: Maybe<Scalars['code']>;
  /** None */
  valueParameterDefinition?: Maybe<ParameterDefinition>;
  /** None */
  valuePeriod?: Maybe<Period>;
  /** None */
  valuePositiveInt?: Maybe<Scalars['code']>;
  /** None */
  valueQuantity?: Maybe<Quantity>;
  /** None */
  valueRange?: Maybe<Range>;
  /** None */
  valueRatio?: Maybe<Ratio>;
  /** None */
  valueReference?: Maybe<Resource>;
  /** None */
  valueRelatedArtifact?: Maybe<RelatedArtifact>;
  /** None */
  valueSampledData?: Maybe<SampledData>;
  /** None */
  valueSignature?: Maybe<Signature>;
  /** None */
  valueString?: Maybe<Scalars['code']>;
  /** None */
  valueTime?: Maybe<Scalars['code']>;
  /** None */
  valueTiming?: Maybe<Timing>;
  /** None */
  valueTriggerDefinition?: Maybe<TriggerDefinition>;
  /** None */
  valueUnsignedInt?: Maybe<Scalars['code']>;
  /** None */
  valueUri?: Maybe<Scalars['code']>;
  /** None */
  valueUrl?: Maybe<Scalars['code']>;
  /** None */
  valueUsageContext?: Maybe<UsageContext>;
  /** None */
  valueUuid?: Maybe<Scalars['code']>;
};

export type TaskInsurance = ClaimResponse | Coverage;

/**
 * Task.Output
 *     A task to be performed.
 */
export type TaskOutput = {
  __typename?: 'TaskOutput';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The name of the Output parameter. */
  type: CodeableConcept;
  /** None */
  valueAddress?: Maybe<Address>;
  /** None */
  valueAge?: Maybe<Quantity>;
  /** None */
  valueAnnotation?: Maybe<Annotation>;
  /** None */
  valueAttachment?: Maybe<Attachment>;
  /** None */
  valueBase64Binary?: Maybe<Scalars['code']>;
  /** None */
  valueBoolean?: Maybe<Scalars['code']>;
  /** None */
  valueCanonical?: Maybe<Scalars['code']>;
  /** None */
  valueCode?: Maybe<Scalars['code']>;
  /** None */
  valueCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  valueCoding?: Maybe<Coding>;
  /** None */
  valueContactDetail?: Maybe<ContactDetail>;
  /** None */
  valueContactPoint?: Maybe<ContactPoint>;
  /** None */
  valueContributor?: Maybe<Contributor>;
  /** None */
  valueCount?: Maybe<Quantity>;
  /** None */
  valueDataRequirement?: Maybe<DataRequirement>;
  /** None */
  valueDate?: Maybe<Scalars['code']>;
  /** None */
  valueDateTime?: Maybe<Scalars['code']>;
  /** None */
  valueDecimal?: Maybe<Scalars['code']>;
  /** None */
  valueDistance?: Maybe<Quantity>;
  /** None */
  valueDosage?: Maybe<Dosage>;
  /** None */
  valueDuration?: Maybe<Quantity>;
  /** None */
  valueExpression?: Maybe<Expression>;
  /** None */
  valueHumanName?: Maybe<HumanName>;
  /** None */
  valueId?: Maybe<Scalars['code']>;
  /** None */
  valueIdentifier?: Maybe<Identifier>;
  /** None */
  valueInstant?: Maybe<Scalars['code']>;
  /** None */
  valueInteger?: Maybe<Scalars['code']>;
  /** None */
  valueMarkdown?: Maybe<Scalars['code']>;
  /** None */
  valueMeta?: Maybe<Meta>;
  /** None */
  valueMoney?: Maybe<Money>;
  /** None */
  valueOid?: Maybe<Scalars['code']>;
  /** None */
  valueParameterDefinition?: Maybe<ParameterDefinition>;
  /** None */
  valuePeriod?: Maybe<Period>;
  /** None */
  valuePositiveInt?: Maybe<Scalars['code']>;
  /** None */
  valueQuantity?: Maybe<Quantity>;
  /** None */
  valueRange?: Maybe<Range>;
  /** None */
  valueRatio?: Maybe<Ratio>;
  /** None */
  valueReference?: Maybe<Resource>;
  /** None */
  valueRelatedArtifact?: Maybe<RelatedArtifact>;
  /** None */
  valueSampledData?: Maybe<SampledData>;
  /** None */
  valueSignature?: Maybe<Signature>;
  /** None */
  valueString?: Maybe<Scalars['code']>;
  /** None */
  valueTime?: Maybe<Scalars['code']>;
  /** None */
  valueTiming?: Maybe<Timing>;
  /** None */
  valueTriggerDefinition?: Maybe<TriggerDefinition>;
  /** None */
  valueUnsignedInt?: Maybe<Scalars['code']>;
  /** None */
  valueUri?: Maybe<Scalars['code']>;
  /** None */
  valueUrl?: Maybe<Scalars['code']>;
  /** None */
  valueUsageContext?: Maybe<UsageContext>;
  /** None */
  valueUuid?: Maybe<Scalars['code']>;
};

export type TaskOwner = CareTeam | Device | HealthcareService | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type TaskRequester = Device | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

/**
 * Task.Restriction
 *     A task to be performed.
 */
export type TaskRestriction = {
  __typename?: 'TaskRestriction';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Over what time-period is fulfillment sought. */
  period?: Maybe<Period>;
  /**
   * For requests that are targeted to more than on potential recipient/target, for
   * whom is fulfillment sought?
   */
  recipient?: Maybe<Array<Maybe<TaskRestrictionRecipient>>>;
  /** Indicates the number of times the requested action should occur. */
  repetitions?: Maybe<Scalars['code']>;
};

export type TaskRestrictionRecipient = Group | Organization | Patient | Practitioner | PractitionerRole | RelatedPerson;

export type Template = {
  __typename?: 'Template';
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  meta?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**
 * TerminologyCapabilities
 *     A TerminologyCapabilities resource documents a set of capabilities (behaviors)
 *     of a FHIR Terminology Server that may be used as a statement of actual server
 *     functionality or a statement of required or desired server implementation.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type TerminologyCapabilities = {
  __typename?: 'TerminologyCapabilities';
  /** Whether the $closure operation is supported. */
  closure?: Maybe<TerminologyCapabilitiesClosure>;
  /**
   * The degree to which the server supports the code search parameter on ValueSet,
   * if it is supported.
   */
  codeSearch?: Maybe<Scalars['code']>;
  /**
   * Identifies a code system that is supported by the server. If there is a no
   * code system URL, then this declares the general assumptions a client can make
   * about support for any CodeSystem resource.
   */
  codeSystem?: Maybe<Array<Maybe<TerminologyCapabilitiesCodeSystem>>>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the terminology capabilities and/or its
   * contents. Copyright statements are generally legal restrictions on the use and
   * publishing of the terminology capabilities.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the terminology capabilities was
   * published. The date must change when the business version changes and it must
   * change if the status code changes. In addition, it should change when the
   * substantive content of the terminology capabilities changes.
   */
  date: Scalars['code'];
  /**
   * A free text natural language description of the terminology capabilities from
   * a consumer's perspective. Typically, this is used when the capability
   * statement describes a desired rather than an actual solution, for example as a
   * formal expression of requirements as part of an RFP.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * Information about the [ValueSet/$expand](valueset-operation-expand.html)
   * operation.
   */
  expansion?: Maybe<TerminologyCapabilitiesExpansion>;
  /**
   * A Boolean value to indicate that this terminology capabilities is authored for
   * testing purposes (or education/evaluation/marketing) and is not intended to be
   * used for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifies a specific implementation instance that is described by the
   * terminology capability statement - i.e. a particular installation, rather than
   * the capabilities of a software program.
   */
  implementation?: Maybe<TerminologyCapabilitiesImplementation>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /**
   * A legal or geographic region in which the terminology capabilities is intended
   * to be used.
   */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * The way that this statement is intended to be used, to describe an actual
   * running instance of software, a particular product (kind, not instance of
   * software) or a class of implementation (e.g. a desired purchase).
   */
  kind: Scalars['code'];
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** Whether the server supports lockedDate. */
  lockedDate?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the terminology capabilities. This name
   * should be usable as an identifier for the module by machine processing
   * applications such as code generation.
   */
  name?: Maybe<Scalars['code']>;
  /**
   * The name of the organization or individual that published the terminology
   * capabilities.
   */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this terminology capabilities is needed and why it has been
   * designed as it has.
   */
  purpose?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * Software that is covered by this terminology capability statement.  It is used
   * when the statement describes the capabilities of a particular software
   * version, independent of an installation.
   */
  software?: Maybe<TerminologyCapabilitiesSoftware>;
  /**
   * The status of this terminology capabilities. Enables tracking the life-cycle
   * of the content.
   */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the terminology capabilities. */
  title?: Maybe<Scalars['code']>;
  /**
   * Information about the [ConceptMap/$translate](conceptmap-operation-
   * translate.html) operation.
   */
  translation?: Maybe<TerminologyCapabilitiesTranslation>;
  /**
   * An absolute URI that is used to identify this terminology capabilities when it
   * is referenced in a specification, model, design or an instance; also called
   * its canonical identifier. This SHOULD be globally unique and SHOULD be a
   * literal address at which at which an authoritative instance of this
   * terminology capabilities is (or will be) published. This URL can be the target
   * of a canonical reference. It SHALL remain the same when the terminology
   * capabilities is stored on different servers.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate terminology
   * capabilities instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * Information about the [ValueSet/$validate-code](valueset-operation-validate-
   * code.html) operation.
   */
  validateCode?: Maybe<TerminologyCapabilitiesValidateCode>;
  /**
   * The identifier that is used to identify this version of the terminology
   * capabilities when it is referenced in a specification, model, design or
   * instance. This is an arbitrary value managed by the terminology capabilities
   * author and is not expected to be globally unique. For example, it might be a
   * timestamp (e.g. yyyymmdd) if a managed version is not available. There is also
   * no expectation that versions can be placed in a lexicographical sequence.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * TerminologyCapabilities.Closure
 *     A TerminologyCapabilities resource documents a set of capabilities (behaviors)
 *     of a FHIR Terminology Server that may be used as a statement of actual server
 *     functionality or a statement of required or desired server implementation.
 */
export type TerminologyCapabilitiesClosure = {
  __typename?: 'TerminologyCapabilitiesClosure';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** If cross-system closure is supported. */
  translation?: Maybe<Scalars['code']>;
};

/**
 * TerminologyCapabilities.CodeSystem
 *     A TerminologyCapabilities resource documents a set of capabilities (behaviors)
 *     of a FHIR Terminology Server that may be used as a statement of actual server
 *     functionality or a statement of required or desired server implementation.
 */
export type TerminologyCapabilitiesCodeSystem = {
  __typename?: 'TerminologyCapabilitiesCodeSystem';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** True if subsumption is supported for this version of the code system. */
  subsumption?: Maybe<Scalars['code']>;
  /** URI for the Code System. */
  uri?: Maybe<Scalars['code']>;
  /** For the code system, a list of versions that are supported by the server. */
  version?: Maybe<Array<Maybe<TerminologyCapabilitiesVersion>>>;
};

/**
 * TerminologyCapabilities.Expansion
 *     A TerminologyCapabilities resource documents a set of capabilities (behaviors)
 *     of a FHIR Terminology Server that may be used as a statement of actual server
 *     functionality or a statement of required or desired server implementation.
 */
export type TerminologyCapabilitiesExpansion = {
  __typename?: 'TerminologyCapabilitiesExpansion';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Whether the server can return nested value sets. */
  hierarchical?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** Allow request for incomplete expansions? */
  incomplete?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Whether the server supports paging on expansion. */
  paging?: Maybe<Scalars['code']>;
  /** Supported expansion parameter. */
  parameter?: Maybe<Array<Maybe<TerminologyCapabilitiesParameter>>>;
  /** Documentation about text searching works. */
  textFilter?: Maybe<Scalars['code']>;
};

/**
 * TerminologyCapabilities.Filter
 *     A TerminologyCapabilities resource documents a set of capabilities (behaviors)
 *     of a FHIR Terminology Server that may be used as a statement of actual server
 *     functionality or a statement of required or desired server implementation.
 */
export type TerminologyCapabilitiesFilter = {
  __typename?: 'TerminologyCapabilitiesFilter';
  /** Code of the property supported. */
  code: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Operations supported for the property. */
  op: Array<Maybe<Scalars['code']>>;
};

/**
 * TerminologyCapabilities.Implementation
 *     A TerminologyCapabilities resource documents a set of capabilities (behaviors)
 *     of a FHIR Terminology Server that may be used as a statement of actual server
 *     functionality or a statement of required or desired server implementation.
 */
export type TerminologyCapabilitiesImplementation = {
  __typename?: 'TerminologyCapabilitiesImplementation';
  /**
   * Information about the specific installation that this terminology capability
   * statement relates to.
   */
  description: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** An absolute base URL for the implementation. */
  url?: Maybe<Scalars['code']>;
};

/**
 * TerminologyCapabilities.Parameter
 *     A TerminologyCapabilities resource documents a set of capabilities (behaviors)
 *     of a FHIR Terminology Server that may be used as a statement of actual server
 *     functionality or a statement of required or desired server implementation.
 */
export type TerminologyCapabilitiesParameter = {
  __typename?: 'TerminologyCapabilitiesParameter';
  /** Description of support for parameter. */
  documentation?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Expansion Parameter name. */
  name: Scalars['code'];
};

/**
 * TerminologyCapabilities.Software
 *     A TerminologyCapabilities resource documents a set of capabilities (behaviors)
 *     of a FHIR Terminology Server that may be used as a statement of actual server
 *     functionality or a statement of required or desired server implementation.
 */
export type TerminologyCapabilitiesSoftware = {
  __typename?: 'TerminologyCapabilitiesSoftware';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Name the software is known by. */
  name: Scalars['code'];
  /** The version identifier for the software covered by this statement. */
  version?: Maybe<Scalars['code']>;
};

/**
 * TerminologyCapabilities.Translation
 *     A TerminologyCapabilities resource documents a set of capabilities (behaviors)
 *     of a FHIR Terminology Server that may be used as a statement of actual server
 *     functionality or a statement of required or desired server implementation.
 */
export type TerminologyCapabilitiesTranslation = {
  __typename?: 'TerminologyCapabilitiesTranslation';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Whether the client must identify the map. */
  needsMap: Scalars['code'];
};

/**
 * TerminologyCapabilities.ValidateCode
 *     A TerminologyCapabilities resource documents a set of capabilities (behaviors)
 *     of a FHIR Terminology Server that may be used as a statement of actual server
 *     functionality or a statement of required or desired server implementation.
 */
export type TerminologyCapabilitiesValidateCode = {
  __typename?: 'TerminologyCapabilitiesValidateCode';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Whether translations are validated. */
  translations: Scalars['code'];
};

/**
 * TerminologyCapabilities.Version
 *     A TerminologyCapabilities resource documents a set of capabilities (behaviors)
 *     of a FHIR Terminology Server that may be used as a statement of actual server
 *     functionality or a statement of required or desired server implementation.
 */
export type TerminologyCapabilitiesVersion = {
  __typename?: 'TerminologyCapabilitiesVersion';
  /**
   * For version-less code systems, there should be a single version with no
   * identifier.
   */
  code?: Maybe<Scalars['code']>;
  /** If the compositional grammar defined by the code system is supported. */
  compositional?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** Filter Properties supported. */
  filter?: Maybe<Array<Maybe<TerminologyCapabilitiesFilter>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** If this is the default version for this code system. */
  isDefault?: Maybe<Scalars['code']>;
  /** Language Displays supported. */
  language?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Properties supported for $lookup. */
  property?: Maybe<Array<Maybe<Scalars['code']>>>;
};

/**
 * TestReport
 *     A summary of information based on the results of executing a TestScript.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type TestReport = {
  __typename?: 'TestReport';
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * Identifier for the TestScript assigned for external purposes outside the
   * context of FHIR.
   */
  identifier?: Maybe<Identifier>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** When the TestScript was executed and this TestReport was generated. */
  issued?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A free text natural language name identifying the executed TestScript. */
  name?: Maybe<Scalars['code']>;
  /**
   * A participant in the test execution, either the execution engine, a client, or
   * a server.
   */
  participant?: Maybe<Array<Maybe<TestReportParticipant>>>;
  resourceType?: Maybe<Scalars['String']>;
  /** The overall result from the execution of the TestScript. */
  result: Scalars['code'];
  /**
   * The final score (percentage of tests passed) resulting from the execution of
   * the TestScript.
   */
  score?: Maybe<Scalars['code']>;
  /**
   * The results of the series of required setup operations before the tests were
   * executed.
   */
  setup?: Maybe<TestReportSetup>;
  /** The current state of this test report. */
  status: Scalars['code'];
  /**
   * The results of the series of operations required to clean up after all the
   * tests were executed (successfully or otherwise).
   */
  teardown?: Maybe<TestReportTeardown>;
  /** A test executed from the test script. */
  test?: Maybe<Array<Maybe<TestReportTest>>>;
  /**
   * Ideally this is an absolute URL that is used to identify the version-specific
   * TestScript that was executed, matching the `TestScript.url`.
   */
  testScript: TestScript;
  /** Name of the tester producing this report (Organization or individual). */
  tester?: Maybe<Scalars['code']>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * TestReport.Action
 *     A summary of information based on the results of executing a TestScript.
 */
export type TestReportAction = {
  __typename?: 'TestReportAction';
  /** The results of the assertion performed on the previous operations. */
  assert?: Maybe<TestReportAssert>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The operation performed. */
  operation?: Maybe<TestReportOperation>;
};

/**
 * TestReport.Action1
 *     A summary of information based on the results of executing a TestScript.
 */
export type TestReportAction1 = {
  __typename?: 'TestReportAction1';
  /** The results of the assertion performed on the previous operations. */
  assert?: Maybe<TestReportAssert>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** An operation would involve a REST request to a server. */
  operation?: Maybe<TestReportOperation>;
};

/**
 * TestReport.Action2
 *     A summary of information based on the results of executing a TestScript.
 */
export type TestReportAction2 = {
  __typename?: 'TestReportAction2';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** An operation would involve a REST request to a server. */
  operation: TestReportOperation;
};

/**
 * TestReport.Assert
 *     A summary of information based on the results of executing a TestScript.
 */
export type TestReportAssert = {
  __typename?: 'TestReportAssert';
  /** A link to further details on the result. */
  detail?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** An explanatory message associated with the result. */
  message?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The result of this assertion. */
  result: Scalars['code'];
};

/**
 * TestReport.Operation
 *     A summary of information based on the results of executing a TestScript.
 */
export type TestReportOperation = {
  __typename?: 'TestReportOperation';
  /** A link to further details on the result. */
  detail?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** An explanatory message associated with the result. */
  message?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The result of this operation. */
  result: Scalars['code'];
};

/**
 * TestReport.Participant
 *     A summary of information based on the results of executing a TestScript.
 */
export type TestReportParticipant = {
  __typename?: 'TestReportParticipant';
  /** The display name of the participant. */
  display?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The type of participant. */
  type: Scalars['code'];
  /** The uri of the participant. An absolute URL is preferred. */
  uri: Scalars['code'];
};

/**
 * TestReport.Setup
 *     A summary of information based on the results of executing a TestScript.
 */
export type TestReportSetup = {
  __typename?: 'TestReportSetup';
  /** Action would contain either an operation or an assertion. */
  action: Array<Maybe<TestReportAction>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * TestReport.Teardown
 *     A summary of information based on the results of executing a TestScript.
 */
export type TestReportTeardown = {
  __typename?: 'TestReportTeardown';
  /** The teardown action will only contain an operation. */
  action: Array<Maybe<TestReportAction2>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * TestReport.Test
 *     A summary of information based on the results of executing a TestScript.
 */
export type TestReportTest = {
  __typename?: 'TestReportTest';
  /** Action would contain either an operation or an assertion. */
  action: Array<Maybe<TestReportAction1>>;
  /**
   * A short description of the test used by test engines for tracking and
   * reporting purposes.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The name of this test used for tracking/logging purposes by test engines. */
  name?: Maybe<Scalars['code']>;
};

/**
 * TestScript
 *     A structured set of tests against a FHIR server or client implementation to
 *     determine compliance against the FHIR specification.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type TestScript = {
  __typename?: 'TestScript';
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the test script and/or its contents.
   * Copyright statements are generally legal restrictions on the use and
   * publishing of the test script.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date  (and optionally time) when the test script was published. The date
   * must change when the business version changes and it must change if the status
   * code changes. In addition, it should change when the substantive content of
   * the test script changes.
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the test script from a consumer's
   * perspective.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * An abstract server used in operations within this test script in the
   * destination element.
   */
  destination?: Maybe<Array<Maybe<TestScriptDestination>>>;
  /**
   * A Boolean value to indicate that this test script is authored for testing
   * purposes (or education/evaluation/marketing) and is not intended to be used
   * for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Fixture in the test script - by reference (uri). All fixtures are required for
   * the test script to execute.
   */
  fixture?: Maybe<Array<Maybe<TestScriptFixture>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this test script when it is
   * represented in other formats, or referenced in a specification, model, design
   * or an instance.
   */
  identifier?: Maybe<Identifier>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** A legal or geographic region in which the test script is intended to be used. */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * The required capability must exist and are assumed to function correctly on
   * the FHIR server being tested.
   */
  metadata?: Maybe<TestScriptMetadata>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the test script. This name should be
   * usable as an identifier for the module by machine processing applications such
   * as code generation.
   */
  name: Scalars['code'];
  /**
   * An abstract server used in operations within this test script in the origin
   * element.
   */
  origin?: Maybe<Array<Maybe<TestScriptOrigin>>>;
  /** Reference to the profile to be used for validation. */
  profile?: Maybe<Array<Maybe<Resource>>>;
  /** The name of the organization or individual that published the test script. */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this test script is needed and why it has been designed as
   * it has.
   */
  purpose?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /** A series of required setup operations before tests are executed. */
  setup?: Maybe<TestScriptSetup>;
  /**
   * The status of this test script. Enables tracking the life-cycle of the
   * content.
   */
  status: Scalars['code'];
  /**
   * A series of operations required to clean up after all the tests are executed
   * (successfully or otherwise).
   */
  teardown?: Maybe<TestScriptTeardown>;
  /** A test in this script. */
  test?: Maybe<Array<Maybe<TestScriptTest>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the test script. */
  title?: Maybe<Scalars['code']>;
  /**
   * An absolute URI that is used to identify this test script when it is
   * referenced in a specification, model, design or an instance; also called its
   * canonical identifier. This SHOULD be globally unique and SHOULD be a literal
   * address at which at which an authoritative instance of this test script is (or
   * will be) published. This URL can be the target of a canonical reference. It
   * SHALL remain the same when the test script is stored on different servers.
   */
  url: Scalars['code'];
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate test script
   * instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * Variable is set based either on element value in response body or on header
   * field value in the response headers.
   */
  variable?: Maybe<Array<Maybe<TestScriptVariable>>>;
  /**
   * The identifier that is used to identify this version of the test script when
   * it is referenced in a specification, model, design or instance. This is an
   * arbitrary value managed by the test script author and is not expected to be
   * globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a
   * managed version is not available. There is also no expectation that versions
   * can be placed in a lexicographical sequence.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * TestScript.Action
 *     A structured set of tests against a FHIR server or client implementation to
 *     determine compliance against the FHIR specification.
 */
export type TestScriptAction = {
  __typename?: 'TestScriptAction';
  /**
   * Evaluates the results of previous operations to determine if the server under
   * test behaves appropriately.
   */
  assert?: Maybe<TestScriptAssert>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The operation to perform. */
  operation?: Maybe<TestScriptOperation>;
};

/**
 * TestScript.Action1
 *     A structured set of tests against a FHIR server or client implementation to
 *     determine compliance against the FHIR specification.
 */
export type TestScriptAction1 = {
  __typename?: 'TestScriptAction1';
  /**
   * Evaluates the results of previous operations to determine if the server under
   * test behaves appropriately.
   */
  assert?: Maybe<TestScriptAssert>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** An operation would involve a REST request to a server. */
  operation?: Maybe<TestScriptOperation>;
};

/**
 * TestScript.Action2
 *     A structured set of tests against a FHIR server or client implementation to
 *     determine compliance against the FHIR specification.
 */
export type TestScriptAction2 = {
  __typename?: 'TestScriptAction2';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** An operation would involve a REST request to a server. */
  operation: TestScriptOperation;
};

/**
 * TestScript.Assert
 *     A structured set of tests against a FHIR server or client implementation to
 *     determine compliance against the FHIR specification.
 */
export type TestScriptAssert = {
  __typename?: 'TestScriptAssert';
  /**
   * The FHIRPath expression to evaluate against the source fixture. When
   * compareToSourceId is defined, either compareToSourceExpression or
   * compareToSourcePath must be defined, but not both.
   */
  compareToSourceExpression?: Maybe<Scalars['code']>;
  /**
   * Id of the source fixture used as the contents to be evaluated by either the
   * "source/expression" or "sourceId/path" definition.
   */
  compareToSourceId?: Maybe<Scalars['code']>;
  /**
   * XPath or JSONPath expression to evaluate against the source fixture. When
   * compareToSourceId is defined, either compareToSourceExpression or
   * compareToSourcePath must be defined, but not both.
   */
  compareToSourcePath?: Maybe<Scalars['code']>;
  /**
   * The mime-type contents to compare against the request or response message
   * 'Content-Type' header.
   */
  contentType?: Maybe<Scalars['code']>;
  /**
   * The description would be used by test engines for tracking and reporting
   * purposes.
   */
  description?: Maybe<Scalars['code']>;
  /** The direction to use for the assertion. */
  direction?: Maybe<Scalars['code']>;
  /**
   * The FHIRPath expression to be evaluated against the request or response
   * message contents - HTTP headers and payload.
   */
  expression?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** The HTTP header field name e.g. 'Location'. */
  headerField?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The label would be used for tracking/logging purposes by test engines. */
  label?: Maybe<Scalars['code']>;
  /**
   * The ID of a fixture.  Asserts that the response contains at a minimum the
   * fixture specified by minimumId.
   */
  minimumId?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Whether or not the test execution performs validation on the bundle navigation
   * links.
   */
  navigationLinks?: Maybe<Scalars['code']>;
  /**
   * The operator type defines the conditional behavior of the assert. If not
   * defined, the default is equals.
   */
  operator?: Maybe<Scalars['code']>;
  /**
   * The XPath or JSONPath expression to be evaluated against the fixture
   * representing the response received from server.
   */
  path?: Maybe<Scalars['code']>;
  /**
   * The request method or HTTP operation code to compare against that used by the
   * client system under test.
   */
  requestMethod?: Maybe<Scalars['code']>;
  /** The value to use in a comparison against the request URL path string. */
  requestURL?: Maybe<Scalars['code']>;
  /** The type of the resource.  See http://build.fhir.org/resourcelist.html. */
  resource?: Maybe<Scalars['code']>;
  /**
   * okay | created | noContent | notModified | bad | forbidden | notFound |
   * methodNotAllowed | conflict | gone | preconditionFailed | unprocessable.
   */
  response?: Maybe<Scalars['code']>;
  /** The value of the HTTP response code to be tested. */
  responseCode?: Maybe<Scalars['code']>;
  /** Fixture to evaluate the XPath/JSONPath expression or the headerField  against. */
  sourceId?: Maybe<Scalars['code']>;
  /** The ID of the Profile to validate against. */
  validateProfileId?: Maybe<Scalars['code']>;
  /** The value to compare to. */
  value?: Maybe<Scalars['code']>;
  /**
   * Whether or not the test execution will produce a warning only on error for
   * this assert.
   */
  warningOnly: Scalars['code'];
};

/**
 * TestScript.Capability
 *     A structured set of tests against a FHIR server or client implementation to
 *     determine compliance against the FHIR specification.
 */
export type TestScriptCapability = {
  __typename?: 'TestScriptCapability';
  /**
   * Minimum capabilities required of server for test script to execute
   * successfully.   If server does not meet at a minimum the referenced capability
   * statement, then all tests in this script are skipped.
   */
  capabilities: Scalars['code'];
  /**
   * Description of the capabilities that this test script is requiring the server
   * to support.
   */
  description?: Maybe<Scalars['code']>;
  /** Which server these requirements apply to. */
  destination?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Links to the FHIR specification that describes this interaction and the
   * resources involved in more detail.
   */
  link?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Which origin server these requirements apply to. */
  origin?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * Whether or not the test execution will require the given capabilities of the
   * server in order for this test script to execute.
   */
  required: Scalars['code'];
  /**
   * Whether or not the test execution will validate the given capabilities of the
   * server in order for this test script to execute.
   */
  validated: Scalars['code'];
};

/**
 * TestScript.Destination
 *     A structured set of tests against a FHIR server or client implementation to
 *     determine compliance against the FHIR specification.
 */
export type TestScriptDestination = {
  __typename?: 'TestScriptDestination';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Abstract name given to a destination server in this test script.  The name is
   * provided as a number starting at 1.
   */
  index: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The type of destination profile the test system supports. */
  profile: Coding;
};

/**
 * TestScript.Fixture
 *     A structured set of tests against a FHIR server or client implementation to
 *     determine compliance against the FHIR specification.
 */
export type TestScriptFixture = {
  __typename?: 'TestScriptFixture';
  /**
   * Whether or not to implicitly create the fixture during setup. If true, the
   * fixture is automatically created on each server being tested during setup,
   * therefore no create operation is required for this fixture in the
   * TestScript.setup section.
   */
  autocreate: Scalars['code'];
  /**
   * Whether or not to implicitly delete the fixture during teardown. If true, the
   * fixture is automatically deleted on each server being tested during teardown,
   * therefore no delete operation is required for this fixture in the
   * TestScript.teardown section.
   */
  autodelete: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Reference to the resource (containing the contents of the resource needed for
   * operations).
   */
  resource?: Maybe<Resource>;
};

/**
 * TestScript.Link
 *     A structured set of tests against a FHIR server or client implementation to
 *     determine compliance against the FHIR specification.
 */
export type TestScriptLink = {
  __typename?: 'TestScriptLink';
  /** Short description of the link. */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** URL to a particular requirement or feature within the FHIR specification. */
  url: Scalars['code'];
};

/**
 * TestScript.Metadata
 *     A structured set of tests against a FHIR server or client implementation to
 *     determine compliance against the FHIR specification.
 */
export type TestScriptMetadata = {
  __typename?: 'TestScriptMetadata';
  /**
   * Capabilities that must exist and are assumed to function correctly on the FHIR
   * server being tested.
   */
  capability: Array<Maybe<TestScriptCapability>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** A link to the FHIR specification that this test is covering. */
  link?: Maybe<Array<Maybe<TestScriptLink>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * TestScript.Operation
 *     A structured set of tests against a FHIR server or client implementation to
 *     determine compliance against the FHIR specification.
 */
export type TestScriptOperation = {
  __typename?: 'TestScriptOperation';
  /** The mime-type to use for RESTful operation in the 'Accept' header. */
  accept?: Maybe<Scalars['code']>;
  /** The mime-type to use for RESTful operation in the 'Content-Type' header. */
  contentType?: Maybe<Scalars['code']>;
  /**
   * The description would be used by test engines for tracking and reporting
   * purposes.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * The server where the request message is destined for.  Must be one of the
   * server numbers listed in TestScript.destination section.
   */
  destination?: Maybe<Scalars['code']>;
  /**
   * Whether or not to implicitly send the request url in encoded format. The
   * default is true to match the standard RESTful client behavior. Set to false
   * when communicating with a server that does not support encoded url paths.
   */
  encodeRequestUrl: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The label would be used for tracking/logging purposes by test engines. */
  label?: Maybe<Scalars['code']>;
  /**
   * The HTTP method the test engine MUST use for this operation regardless of any
   * other operation details.
   */
  method?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The server where the request message originates from.  Must be one of the
   * server numbers listed in TestScript.origin section.
   */
  origin?: Maybe<Scalars['code']>;
  /**
   * Path plus parameters after [type].  Used to set parts of the request URL
   * explicitly.
   */
  params?: Maybe<Scalars['code']>;
  /** Header elements would be used to set HTTP headers. */
  requestHeader?: Maybe<Array<Maybe<TestScriptRequestHeader>>>;
  /** The fixture id (maybe new) to map to the request. */
  requestId?: Maybe<Scalars['code']>;
  /** The type of the resource.  See http://build.fhir.org/resourcelist.html. */
  resource?: Maybe<Scalars['code']>;
  /** The fixture id (maybe new) to map to the response. */
  responseId?: Maybe<Scalars['code']>;
  /** The id of the fixture used as the body of a PUT or POST request. */
  sourceId?: Maybe<Scalars['code']>;
  /**
   * Id of fixture used for extracting the [id],  [type], and [vid] for GET
   * requests.
   */
  targetId?: Maybe<Scalars['code']>;
  /** Server interaction or operation type. */
  type?: Maybe<Coding>;
  /** Complete request URL. */
  url?: Maybe<Scalars['code']>;
};

/**
 * TestScript.Origin
 *     A structured set of tests against a FHIR server or client implementation to
 *     determine compliance against the FHIR specification.
 */
export type TestScriptOrigin = {
  __typename?: 'TestScriptOrigin';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Abstract name given to an origin server in this test script.  The name is
   * provided as a number starting at 1.
   */
  index: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The type of origin profile the test system supports. */
  profile: Coding;
};

/**
 * TestScript.RequestHeader
 *     A structured set of tests against a FHIR server or client implementation to
 *     determine compliance against the FHIR specification.
 */
export type TestScriptRequestHeader = {
  __typename?: 'TestScriptRequestHeader';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** The HTTP header field e.g. "Accept". */
  field: Scalars['code'];
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The value of the header e.g. "application/fhir+xml". */
  value: Scalars['code'];
};

/**
 * TestScript.Setup
 *     A structured set of tests against a FHIR server or client implementation to
 *     determine compliance against the FHIR specification.
 */
export type TestScriptSetup = {
  __typename?: 'TestScriptSetup';
  /** Action would contain either an operation or an assertion. */
  action: Array<Maybe<TestScriptAction>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * TestScript.Teardown
 *     A structured set of tests against a FHIR server or client implementation to
 *     determine compliance against the FHIR specification.
 */
export type TestScriptTeardown = {
  __typename?: 'TestScriptTeardown';
  /** The teardown action will only contain an operation. */
  action: Array<Maybe<TestScriptAction2>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * TestScript.Test
 *     A structured set of tests against a FHIR server or client implementation to
 *     determine compliance against the FHIR specification.
 */
export type TestScriptTest = {
  __typename?: 'TestScriptTest';
  /** Action would contain either an operation or an assertion. */
  action: Array<Maybe<TestScriptAction1>>;
  /**
   * A short description of the test used by test engines for tracking and
   * reporting purposes.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The name of this test used for tracking/logging purposes by test engines. */
  name?: Maybe<Scalars['code']>;
};

/**
 * TestScript.Variable
 *     A structured set of tests against a FHIR server or client implementation to
 *     determine compliance against the FHIR specification.
 */
export type TestScriptVariable = {
  __typename?: 'TestScriptVariable';
  /** A default, hard-coded, or user-defined value for this variable. */
  defaultValue?: Maybe<Scalars['code']>;
  /** A free text natural language description of the variable and its purpose. */
  description?: Maybe<Scalars['code']>;
  /**
   * The FHIRPath expression to evaluate against the fixture body. When variables
   * are defined, only one of either expression, headerField or path must be
   * specified.
   */
  expression?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Will be used to grab the HTTP header field value from the headers that
   * sourceId is pointing to.
   */
  headerField?: Maybe<Scalars['code']>;
  /**
   * Displayable text string with hint help information to the user when entering a
   * default value.
   */
  hint?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Descriptive name for this variable. */
  name: Scalars['code'];
  /**
   * XPath or JSONPath to evaluate against the fixture body.  When variables are
   * defined, only one of either expression, headerField or path must be specified.
   */
  path?: Maybe<Scalars['code']>;
  /**
   * Fixture to evaluate the XPath/JSONPath expression or the headerField  against
   * within this variable.
   */
  sourceId?: Maybe<Scalars['code']>;
};

/**
 * Timing
 *     Specifies an event that may occur multiple times. Timing schedules are used to
 *     record when things are planned, expected or requested to occur. The most
 *     common usage is in dosage instructions for medications. They are also used
 *     when planning care of various kinds, and may be used for reporting the
 *     schedule to which past regular activities were carried out.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type Timing = {
  __typename?: 'Timing';
  /**
   * A code for the timing schedule (or just text in code.text). Some codes such as
   * BID are ubiquitous, but many institutions define their own additional codes.
   * If a code is provided, the code is understood to be a complete statement of
   * whatever is specified in the structured timing data, and either the code or
   * the data may be used to interpret the Timing, with the exception that
   * .repeat.bounds still applies over the code (and is not contained in the code).
   */
  code?: Maybe<CodeableConcept>;
  /** Identifies specific times when the event occurs. */
  event?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A set of rules that describe when the event is scheduled. */
  repeat?: Maybe<TimingRepeat>;
};

/**
 * Timing.Repeat
 *     Specifies an event that may occur multiple times. Timing schedules are used to
 *     record when things are planned, expected or requested to occur. The most
 *     common usage is in dosage instructions for medications. They are also used
 *     when planning care of various kinds, and may be used for reporting the
 *     schedule to which past regular activities were carried out.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type TimingRepeat = {
  __typename?: 'TimingRepeat';
  /** None */
  boundsDuration?: Maybe<Quantity>;
  /** None */
  boundsPeriod?: Maybe<Period>;
  /** None */
  boundsRange?: Maybe<Range>;
  /**
   * A total count of the desired number of repetitions across the duration of the
   * entire timing specification. If countMax is present, this element indicates
   * the lower bound of the allowed range of count values.
   */
  count?: Maybe<Scalars['code']>;
  /**
   * If present, indicates that the count is a range - so to perform the action
   * between [count] and [countMax] times.
   */
  countMax?: Maybe<Scalars['code']>;
  /**
   * If one or more days of week is provided, then the action happens only on the
   * specified day(s).
   */
  dayOfWeek?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * How long this thing happens for when it happens. If durationMax is present,
   * this element indicates the lower bound of the allowed range of the duration.
   */
  duration?: Maybe<Scalars['code']>;
  /**
   * If present, indicates that the duration is a range - so to perform the action
   * between [duration] and [durationMax] time length.
   */
  durationMax?: Maybe<Scalars['code']>;
  /** The units of time for the duration, in UCUM units. */
  durationUnit?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The number of times to repeat the action within the specified period. If
   * frequencyMax is present, this element indicates the lower bound of the allowed
   * range of the frequency.
   */
  frequency?: Maybe<Scalars['code']>;
  /**
   * If present, indicates that the frequency is a range - so to repeat between
   * [frequency] and [frequencyMax] times within the period or period range.
   */
  frequencyMax?: Maybe<Scalars['code']>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The number of minutes from the event. If the event code does not indicate
   * whether the minutes is before or after the event, then the offset is assumed
   * to be after the event.
   */
  offset?: Maybe<Scalars['code']>;
  /**
   * Indicates the duration of time over which repetitions are to occur; e.g. to
   * express "3 times per day", 3 would be the frequency and "1 day" would be the
   * period. If periodMax is present, this element indicates the lower bound of the
   * allowed range of the period length.
   */
  period?: Maybe<Scalars['code']>;
  /**
   * If present, indicates that the period is a range from [period] to [periodMax],
   * allowing expressing concepts such as "do this once every 3-5 days.
   */
  periodMax?: Maybe<Scalars['code']>;
  /** The units of time for the period in UCUM units. */
  periodUnit?: Maybe<Scalars['code']>;
  /** Specified time of day for action to take place. */
  timeOfDay?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * An approximate time period during the day, potentially linked to an event of
   * daily living that indicates when the action should occur.
   */
  when?: Maybe<Array<Maybe<Scalars['code']>>>;
};

export enum TotalType {
  Accurate = 'accurate',
  Estimate = 'estimate'
}

/**
 * TriggerDefinition
 *     A description of a triggering event. Triggering events can be named events,
 *     data events, or periodic, as determined by the type element.
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type TriggerDefinition = {
  __typename?: 'TriggerDefinition';
  /**
   * A boolean-valued expression that is evaluated in the context of the container
   * of the trigger definition and returns whether or not the trigger fires.
   */
  condition?: Maybe<Expression>;
  /**
   * The triggering data of the event (if this is a data trigger). If more than one
   * data is requirement is specified, then all the data requirements must be true.
   */
  data?: Maybe<Array<Maybe<DataRequirement>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal name for the event. This may be an absolute URI that identifies the
   * event formally (e.g. from a trigger registry), or a simple relative URI that
   * identifies the event in a local context.
   */
  name?: Maybe<Scalars['code']>;
  /** None */
  timingDate?: Maybe<Scalars['code']>;
  /** None */
  timingDateTime?: Maybe<Scalars['code']>;
  /** None */
  timingReference?: Maybe<Schedule>;
  /** None */
  timingTiming?: Maybe<Timing>;
  /** The type of triggering event. */
  type: Scalars['code'];
};

/**
 * UsageContext
 *     Specifies clinical/business/etc. metadata that can be used to retrieve, index
 *     and/or categorize an artifact. This metadata can either be specific to the
 *     applicable population (e.g., age category, DRG) or the specific context of
 *     care (e.g., venue, care setting, provider of care).
 *     If the element is present, it must have a value for at least one of the
 *     defined elements, an @id referenced from the Narrative, or extensions
 */
export type UsageContext = {
  __typename?: 'UsageContext';
  /**
   * A code that identifies the type of context being specified by this usage
   * context.
   */
  code: Coding;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** None */
  valueCodeableConcept?: Maybe<CodeableConcept>;
  /** None */
  valueQuantity?: Maybe<Quantity>;
  /** None */
  valueRange?: Maybe<Range>;
  /** None */
  valueReference?: Maybe<UsageContextValueReference>;
};

export type UsageContextValueReference = Group | HealthcareService | InsurancePlan | Location | Organization | PlanDefinition | ResearchStudy;

/**
 * ValueSet
 *     A ValueSet resource instance specifies a set of codes drawn from one or more
 *     code systems, intended for use in a particular context. Value sets link
 *     between [[[CodeSystem]]] definitions and their use in [coded
 *     elements](terminologies.html).
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type ValueSet = {
  __typename?: 'ValueSet';
  /**
   * A set of criteria that define the contents of the value set by including or
   * excluding codes selected from the specified code system(s) that the value set
   * draws from. This is also known as the Content Logical Definition (CLD).
   */
  compose?: Maybe<ValueSetCompose>;
  /**
   * Contact details to assist a user in finding and communicating with the
   * publisher.
   */
  contact?: Maybe<Array<Maybe<ContactDetail>>>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A copyright statement relating to the value set and/or its contents. Copyright
   * statements are generally legal restrictions on the use and publishing of the
   * value set.
   */
  copyright?: Maybe<Scalars['code']>;
  /**
   * The date (and optionally time) when the value set was created or revised (e.g.
   * the 'content logical definition').
   */
  date?: Maybe<Scalars['code']>;
  /**
   * A free text natural language description of the value set from a consumer's
   * perspective. The textual description specifies the span of meanings for
   * concepts to be included within the Value Set Expansion, and also may specify
   * the intended use and limitations of the Value Set.
   */
  description?: Maybe<Scalars['code']>;
  /**
   * A value set can also be "expanded", where the value set is turned into a
   * simple collection of enumerated codes. This element holds the expansion, if it
   * has been performed.
   */
  expansion?: Maybe<ValueSetExpansion>;
  /**
   * A Boolean value to indicate that this value set is authored for testing
   * purposes (or education/evaluation/marketing) and is not intended to be used
   * for genuine usage.
   */
  experimental?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A formal identifier that is used to identify this value set when it is
   * represented in other formats, or referenced in a specification, model, design
   * or an instance.
   */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * If this is set to 'true', then no new versions of the content logical
   * definition can be created.  Note: Other metadata might still change.
   */
  immutable?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** A legal or geographic region in which the value set is intended to be used. */
  jurisdiction?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * A natural language name identifying the value set. This name should be usable
   * as an identifier for the module by machine processing applications such as
   * code generation.
   */
  name?: Maybe<Scalars['code']>;
  /** The name of the organization or individual that published the value set. */
  publisher?: Maybe<Scalars['code']>;
  /**
   * Explanation of why this value set is needed and why it has been designed as it
   * has.
   */
  purpose?: Maybe<Scalars['code']>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The status of this value set. Enables tracking the life-cycle of the content.
   * The status of the value set applies to the value set definition
   * (ValueSet.compose) and the associated ValueSet metadata. Expansions do not
   * have a state.
   */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /** A short, descriptive, user-friendly title for the value set. */
  title?: Maybe<Scalars['code']>;
  /**
   * An absolute URI that is used to identify this value set when it is referenced
   * in a specification, model, design or an instance; also called its canonical
   * identifier. This SHOULD be globally unique and SHOULD be a literal address at
   * which at which an authoritative instance of this value set is (or will be)
   * published. This URL can be the target of a canonical reference. It SHALL
   * remain the same when the value set is stored on different servers.
   */
  url?: Maybe<Scalars['code']>;
  /**
   * The content was developed with a focus and intent of supporting the contexts
   * that are listed. These contexts may be general categories (gender, age, ...)
   * or may be references to specific programs (insurance plans, studies, ...) and
   * may be used to assist with indexing and searching for appropriate value set
   * instances.
   */
  useContext?: Maybe<Array<Maybe<UsageContext>>>;
  /**
   * The identifier that is used to identify this version of the value set when it
   * is referenced in a specification, model, design or instance. This is an
   * arbitrary value managed by the value set author and is not expected to be
   * globally unique. For example, it might be a timestamp (e.g. yyyymmdd) if a
   * managed version is not available. There is also no expectation that versions
   * can be placed in a lexicographical sequence.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * ValueSet.Compose
 *     A ValueSet resource instance specifies a set of codes drawn from one or more
 *     code systems, intended for use in a particular context. Value sets link
 *     between [[[CodeSystem]]] definitions and their use in [coded
 *     elements](terminologies.html).
 */
export type ValueSetCompose = {
  __typename?: 'ValueSetCompose';
  /**
   * Exclude one or more codes from the value set based on code system filters
   * and/or other value sets.
   */
  exclude?: Maybe<Array<Maybe<ValueSetInclude>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * Whether inactive codes - codes that are not approved for current use - are in
   * the value set. If inactive = true, inactive codes are to be included in the
   * expansion, if inactive = false, the inactive codes will not be included in the
   * expansion. If absent, the behavior is determined by the implementation, or by
   * the applicable $expand parameters (but generally, inactive codes would be
   * expected to be included).
   */
  inactive?: Maybe<Scalars['code']>;
  /** Include one or more codes from a code system or other value set(s). */
  include: Array<Maybe<ValueSetInclude>>;
  /**
   * The Locked Date is  the effective date that is used to determine the version
   * of all referenced Code Systems and Value Set Definitions included in the
   * compose that are not already tied to a specific version.
   */
  lockedDate?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * ValueSet.Concept
 *     A ValueSet resource instance specifies a set of codes drawn from one or more
 *     code systems, intended for use in a particular context. Value sets link
 *     between [[[CodeSystem]]] definitions and their use in [coded
 *     elements](terminologies.html).
 */
export type ValueSetConcept = {
  __typename?: 'ValueSetConcept';
  /** Specifies a code for the concept to be included or excluded. */
  code: Scalars['code'];
  /**
   * Additional representations for this concept when used in this value set -
   * other languages, aliases, specialized purposes, used for particular purposes,
   * etc.
   */
  designation?: Maybe<Array<Maybe<ValueSetDesignation>>>;
  /**
   * The text to display to the user for this concept in the context of this
   * valueset. If no display is provided, then applications using the value set use
   * the display specified for the code by the system.
   */
  display?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

/**
 * ValueSet.Contains
 *     A ValueSet resource instance specifies a set of codes drawn from one or more
 *     code systems, intended for use in a particular context. Value sets link
 *     between [[[CodeSystem]]] definitions and their use in [coded
 *     elements](terminologies.html).
 */
export type ValueSetContains = {
  __typename?: 'ValueSetContains';
  /**
   * If true, this entry is included in the expansion for navigational purposes,
   * and the user cannot select the code directly as a proper value.
   */
  abstract?: Maybe<Scalars['code']>;
  /**
   * The code for this item in the expansion hierarchy. If this code is missing the
   * entry in the hierarchy is a place holder (abstract) and does not represent a
   * valid code in the value set.
   */
  code?: Maybe<Scalars['code']>;
  /** Other codes and entries contained under this entry in the hierarchy. */
  contains?: Maybe<Array<Maybe<ValueSetContains>>>;
  /**
   * Additional representations for this item - other languages, aliases,
   * specialized purposes, used for particular purposes, etc. These are relevant
   * when the conditions of the expansion do not fix to a single correct
   * representation.
   */
  designation?: Maybe<Array<Maybe<ValueSetDesignation>>>;
  /** The recommended display for this item in the expansion. */
  display?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * If the concept is inactive in the code system that defines it. Inactive codes
   * are those that are no longer to be used, but are maintained by the code system
   * for understanding legacy data. It might not be known or specified whether an
   * concept is inactive (and it may depend on the context of use).
   */
  inactive?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * An absolute URI which is the code system in which the code for this item in
   * the expansion is defined.
   */
  system?: Maybe<Scalars['code']>;
  /**
   * The version of the code system from this code was taken. Note that a well-
   * maintained code system does not need the version reported, because the meaning
   * of codes is consistent across versions. However this cannot consistently be
   * assured, and when the meaning is not guaranteed to be consistent, the version
   * SHOULD be exchanged.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * ValueSet.Designation
 *     A ValueSet resource instance specifies a set of codes drawn from one or more
 *     code systems, intended for use in a particular context. Value sets link
 *     between [[[CodeSystem]]] definitions and their use in [coded
 *     elements](terminologies.html).
 */
export type ValueSetDesignation = {
  __typename?: 'ValueSetDesignation';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** The language this designation is defined for. */
  language?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A code that represents types of uses of designations. */
  use?: Maybe<Coding>;
  /** The text value for this designation. */
  value: Scalars['code'];
};

/**
 * ValueSet.Expansion
 *     A ValueSet resource instance specifies a set of codes drawn from one or more
 *     code systems, intended for use in a particular context. Value sets link
 *     between [[[CodeSystem]]] definitions and their use in [coded
 *     elements](terminologies.html).
 */
export type ValueSetExpansion = {
  __typename?: 'ValueSetExpansion';
  /** The codes that are contained in the value set expansion. */
  contains?: Maybe<Array<Maybe<ValueSetContains>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * An identifier that uniquely identifies this expansion of the valueset, based
   * on a unique combination of the provided parameters, the system default
   * parameters, and the underlying system code system versions etc. Systems may
   * re-use the same identifier as long as those factors remain the same, and the
   * expansion is the same, but are not required to do so. This is a business
   * identifier.
   */
  identifier?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * If paging is being used, the offset at which this resource starts.  I.e. this
   * resource is a partial view into the expansion. If paging is not being used,
   * this element SHALL NOT be present.
   */
  offset?: Maybe<Scalars['code']>;
  /**
   * A parameter that controlled the expansion process. These parameters may be
   * used by users of expanded value sets to check whether the expansion is
   * suitable for a particular purpose, or to pick the correct expansion.
   */
  parameter?: Maybe<Array<Maybe<ValueSetParameter>>>;
  /** The time at which the expansion was produced by the expanding system. */
  timestamp: Scalars['code'];
  /**
   * The total number of concepts in the expansion. If the number of concept nodes
   * in this resource is less than the stated number, then the server can return
   * more using the offset parameter.
   */
  total?: Maybe<Scalars['code']>;
};

/**
 * ValueSet.Filter
 *     A ValueSet resource instance specifies a set of codes drawn from one or more
 *     code systems, intended for use in a particular context. Value sets link
 *     between [[[CodeSystem]]] definitions and their use in [coded
 *     elements](terminologies.html).
 */
export type ValueSetFilter = {
  __typename?: 'ValueSetFilter';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** The kind of operation to perform as a part of the filter criteria. */
  op: Scalars['code'];
  /** A code that identifies a property or a filter defined in the code system. */
  property: Scalars['code'];
  /**
   * The match value may be either a code defined by the system, or a string value,
   * which is a regex match on the literal string of the property value  (if the
   * filter represents a property defined in CodeSystem) or of the system filter
   * value (if the filter represents a filter defined in CodeSystem) when the
   * operation is 'regex', or one of the values (true and false), when the
   * operation is 'exists'.
   */
  value: Scalars['code'];
};

/**
 * ValueSet.Include
 *     A ValueSet resource instance specifies a set of codes drawn from one or more
 *     code systems, intended for use in a particular context. Value sets link
 *     between [[[CodeSystem]]] definitions and their use in [coded
 *     elements](terminologies.html).
 */
export type ValueSetInclude = {
  __typename?: 'ValueSetInclude';
  /** Specifies a concept to be included or excluded. */
  concept?: Maybe<Array<Maybe<ValueSetConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Select concepts by specify a matching criterion based on the properties
   * (including relationships) defined by the system, or on filters defined by the
   * system. If multiple filters are specified, they SHALL all be true.
   */
  filter?: Maybe<Array<Maybe<ValueSetFilter>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * An absolute URI which is the code system from which the selected codes come
   * from.
   */
  system?: Maybe<Scalars['code']>;
  /**
   * Selects the concepts found in this value set (based on its value set
   * definition). This is an absolute URI that is a reference to ValueSet.url.  If
   * multiple value sets are specified this includes the union of the contents of
   * all of the referenced value sets.
   */
  valueSet?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * The version of the code system that the codes are selected from, or the
   * special version '*' for all versions.
   */
  version?: Maybe<Scalars['code']>;
};

/**
 * ValueSet.Parameter
 *     A ValueSet resource instance specifies a set of codes drawn from one or more
 *     code systems, intended for use in a particular context. Value sets link
 *     between [[[CodeSystem]]] definitions and their use in [coded
 *     elements](terminologies.html).
 */
export type ValueSetParameter = {
  __typename?: 'ValueSetParameter';
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Name of the input parameter to the $expand operation; may be a server-assigned
   * name for additional default or other server-supplied parameters used to
   * control the expansion process.
   */
  name: Scalars['code'];
  /** None */
  valueBoolean?: Maybe<Scalars['code']>;
  /** None */
  valueCode?: Maybe<Scalars['code']>;
  /** None */
  valueDateTime?: Maybe<Scalars['code']>;
  /** None */
  valueDecimal?: Maybe<Scalars['code']>;
  /** None */
  valueInteger?: Maybe<Scalars['code']>;
  /** None */
  valueString?: Maybe<Scalars['code']>;
  /** None */
  valueUri?: Maybe<Scalars['code']>;
};

/**
 * VerificationResult
 *     Describes validation requirements, source(s), status and dates for one or more
 *     elements.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type VerificationResult = {
  __typename?: 'VerificationResult';
  /** Information about the entity attesting to information. */
  attestation?: Maybe<VerificationResultAttestation>;
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** The result if validation fails (fatal; warning; record only; none). */
  failureAction?: Maybe<CodeableConcept>;
  /** Frequency of revalidation. */
  frequency?: Maybe<Timing>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /** The date/time validation was last completed (including failed validations). */
  lastPerformed?: Maybe<Scalars['code']>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The frequency with which the target must be validated (none; initial;
   * periodic).
   */
  need?: Maybe<CodeableConcept>;
  /** The date when target is next validated, if appropriate. */
  nextScheduled?: Maybe<Scalars['code']>;
  /** Information about the primary source(s) involved in validation. */
  primarySource?: Maybe<Array<Maybe<VerificationResultPrimarySource>>>;
  resourceType?: Maybe<Scalars['String']>;
  /**
   * The validation status of the target (attested; validated; in process; requires
   * revalidation; validation failed; revalidation failed).
   */
  status: Scalars['code'];
  /** When the validation status was updated. */
  statusDate?: Maybe<Scalars['code']>;
  /** A resource that was validated. */
  target?: Maybe<Array<Maybe<Resource>>>;
  /** The fhirpath location(s) within the resource that was validated. */
  targetLocation?: Maybe<Array<Maybe<Scalars['code']>>>;
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
  /**
   * The primary process by which the target is validated (edit check; value set;
   * primary source; multiple sources; standalone; in context).
   */
  validationProcess?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * What the target is validated against (nothing; primary source; multiple
   * sources).
   */
  validationType?: Maybe<CodeableConcept>;
  /** Information about the entity validating information. */
  validator?: Maybe<Array<Maybe<VerificationResultValidator>>>;
};

/**
 * VerificationResult.Attestation
 *     Describes validation requirements, source(s), status and dates for one or more
 *     elements.
 */
export type VerificationResultAttestation = {
  __typename?: 'VerificationResultAttestation';
  /**
   * The method by which attested information was submitted/retrieved (manual; API;
   * Push).
   */
  communicationMethod?: Maybe<CodeableConcept>;
  /** The date the information was attested to. */
  date?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** When the who is asserting on behalf of another (organization or individual). */
  onBehalfOf?: Maybe<VerificationResultAttestationOnBehalfOf>;
  /**
   * A digital identity certificate associated with the proxy entity submitting
   * attested information on behalf of the attestation source.
   */
  proxyIdentityCertificate?: Maybe<Scalars['code']>;
  /**
   * Signed assertion by the proxy entity indicating that they have the right to
   * submit attested information on behalf of the attestation source.
   */
  proxySignature?: Maybe<Signature>;
  /** A digital identity certificate associated with the attestation source. */
  sourceIdentityCertificate?: Maybe<Scalars['code']>;
  /**
   * Signed assertion by the attestation source that they have attested to the
   * information.
   */
  sourceSignature?: Maybe<Signature>;
  /** The individual or organization attesting to information. */
  who?: Maybe<VerificationResultAttestationWho>;
};

export type VerificationResultAttestationOnBehalfOf = Organization | Practitioner | PractitionerRole;

export type VerificationResultAttestationWho = Organization | Practitioner | PractitionerRole;

/**
 * VerificationResult.PrimarySource
 *     Describes validation requirements, source(s), status and dates for one or more
 *     elements.
 */
export type VerificationResultPrimarySource = {
  __typename?: 'VerificationResultPrimarySource';
  /** Ability of the primary source to push updates/alerts (yes; no; undetermined). */
  canPushUpdates?: Maybe<CodeableConcept>;
  /** Method for communicating with the primary source (manual; API; Push). */
  communicationMethod?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * Type of alerts/updates the primary source can send (specific requested
   * changes; any changes; as defined by source).
   */
  pushTypeAvailable?: Maybe<Array<Maybe<CodeableConcept>>>;
  /**
   * Type of primary source (License Board; Primary Education; Continuing
   * Education; Postal Service; Relationship owner; Registration Authority; legal
   * source; issuing source; authoritative source).
   */
  type?: Maybe<Array<Maybe<CodeableConcept>>>;
  /** When the target was validated against the primary source. */
  validationDate?: Maybe<Scalars['code']>;
  /**
   * Status of the validation of the target against the primary source (successful;
   * failed; unknown).
   */
  validationStatus?: Maybe<CodeableConcept>;
  /** Reference to the primary source. */
  who?: Maybe<VerificationResultPrimarySourceWho>;
};

export type VerificationResultPrimarySourceWho = Organization | Practitioner | PractitionerRole;

/**
 * VerificationResult.Validator
 *     Describes validation requirements, source(s), status and dates for one or more
 *     elements.
 */
export type VerificationResultValidator = {
  __typename?: 'VerificationResultValidator';
  /** Signed assertion by the validator that they have validated the information. */
  attestationSignature?: Maybe<Signature>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /** A digital identity certificate associated with the validator. */
  identityCertificate?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Reference to the organization validating information. */
  organization: Organization;
};

/**
 * VisionPrescription
 *     An authorization for the provision of glasses and/or contact lenses to a
 *     patient.
 *     If the element is present, it must have either a @value, an @id, or extensions
 */
export type VisionPrescription = {
  __typename?: 'VisionPrescription';
  /**
   * These resources do not have an independent existence apart from the resource
   * that contains them - they cannot be identified independently, and nor can they
   * have their own independent transaction scope.
   */
  contained?: Maybe<Array<Maybe<Scalars['code']>>>;
  /** The date this resource was created. */
  created: Scalars['code'];
  /** The date (and perhaps time) when the prescription was written. */
  dateWritten: Scalars['code'];
  /**
   * A reference to a resource that identifies the particular occurrence of contact
   * between patient and health care provider during which the prescription was
   * issued.
   */
  encounter?: Maybe<Encounter>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /**
   * The logical id of the resource, as used in the URL for the resource. Once
   * assigned, this value never changes.
   */
  id?: Maybe<Scalars['code']>;
  /** A unique identifier assigned to this vision prescription. */
  identifier?: Maybe<Array<Maybe<Identifier>>>;
  /**
   * A reference to a set of rules that were followed when the resource was
   * constructed, and which must be understood when processing the content. Often,
   * this is a reference to an implementation guide that defines the special rules
   * along with other profiles etc.
   */
  implicitRules?: Maybe<Scalars['code']>;
  /** The base language in which the resource is written. */
  language?: Maybe<Scalars['code']>;
  /**
   * Contain the details of  the individual lens specifications and serves as the
   * authorization for the fullfillment by certified professionals.
   */
  lensSpecification: Array<Maybe<VisionPrescriptionLensSpecification>>;
  /**
   * The metadata about the resource. This is content that is maintained by the
   * infrastructure. Changes to the content might not always be associated with
   * version changes to the resource.
   */
  meta?: Maybe<Meta>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the resource and that modifies the understanding of the element
   * that contains it and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer is allowed to define an extension, there is a set of requirements
   * that SHALL be met as part of the definition of the extension. Applications
   * processing a resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** A resource reference to the person to whom the vision prescription applies. */
  patient: Patient;
  /** The healthcare professional responsible for authorizing the prescription. */
  prescriber: VisionPrescriptionPrescriber;
  resourceType?: Maybe<Scalars['String']>;
  /** The status of the resource instance. */
  status: Scalars['code'];
  /**
   * A human-readable narrative that contains a summary of the resource and can be
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   * Resource definitions may define what content should be represented in the
   * narrative to ensure clinical safety.
   */
  text?: Maybe<Narrative>;
};

/**
 * VisionPrescription.LensSpecification
 *     An authorization for the provision of glasses and/or contact lenses to a
 *     patient.
 */
export type VisionPrescriptionLensSpecification = {
  __typename?: 'VisionPrescriptionLensSpecification';
  /** Power adjustment for multifocal lenses measured in dioptres (0.25 units). */
  add?: Maybe<Scalars['code']>;
  /** Adjustment for astigmatism measured in integer degrees. */
  axis?: Maybe<Scalars['code']>;
  /** Back curvature measured in millimetres. */
  backCurve?: Maybe<Scalars['code']>;
  /** Brand recommendations or restrictions. */
  brand?: Maybe<Scalars['code']>;
  /** Special color or pattern. */
  color?: Maybe<Scalars['code']>;
  /** Power adjustment for astigmatism measured in dioptres (0.25 units). */
  cylinder?: Maybe<Scalars['code']>;
  /** Contact lens diameter measured in millimetres. */
  diameter?: Maybe<Scalars['code']>;
  /** The recommended maximum wear period for the lens. */
  duration?: Maybe<Quantity>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** The eye for which the lens specification applies. */
  eye: Scalars['code'];
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
  /** Notes for special requirements such as coatings and lens materials. */
  note?: Maybe<Array<Maybe<Annotation>>>;
  /** Contact lens power measured in dioptres (0.25 units). */
  power?: Maybe<Scalars['code']>;
  /** Allows for adjustment on two axis. */
  prism?: Maybe<Array<Maybe<VisionPrescriptionPrism>>>;
  /**
   * Identifies the type of vision correction product which is required for the
   * patient.
   */
  product: CodeableConcept;
  /** Lens power measured in dioptres (0.25 units). */
  sphere?: Maybe<Scalars['code']>;
};

export type VisionPrescriptionPrescriber = Practitioner | PractitionerRole;

/**
 * VisionPrescription.Prism
 *     An authorization for the provision of glasses and/or contact lenses to a
 *     patient.
 */
export type VisionPrescriptionPrism = {
  __typename?: 'VisionPrescriptionPrism';
  /** Amount of prism to compensate for eye alignment in fractional units. */
  amount: Scalars['code'];
  /** The relative base, or reference lens edge, for the prism. */
  base: Scalars['code'];
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element. To make the use of extensions safe and manageable,
   * there is a strict set of governance  applied to the definition and use of
   * extensions. Though any implementer can define an extension, there is a set of
   * requirements that SHALL be met as part of the definition of the extension.
   */
  extension?: Maybe<Array<Maybe<Extension>>>;
  /** None */
  id?: Maybe<Scalars['code']>;
  /**
   * May be used to represent additional information that is not part of the basic
   * definition of the element and that modifies the understanding of the element
   * in which it is contained and/or the understanding of the containing element's
   * descendants. Usually modifier elements provide negation or qualification. To
   * make the use of extensions safe and manageable, there is a strict set of
   * governance applied to the definition and use of extensions. Though any
   * implementer can define an extension, there is a set of requirements that SHALL
   * be met as part of the definition of the extension. Applications processing a
   * resource are required to check for modifier extensions.
   *
   * Modifier extensions SHALL NOT change the meaning of any elements on Resource
   * or DomainResource (including cannot change the meaning of modifierExtension
   * itself).
   */
  modifierExtension?: Maybe<Array<Maybe<Extension>>>;
};

export type GetPatientsQueryVariables = Exact<{
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;


export type GetPatientsQuery = { __typename?: 'Query', patient?: Array<{ __typename?: 'Patient', id?: any | null, flatName?: string | null, icn?: string | null, edipi?: string | null, phone?: Array<string | null> | null, last4?: string | null, birthDate?: any | null, gender?: any | null, zeelId?: string | null, memberId?: string | null, removedFromNetwork?: string | null, name?: Array<{ __typename?: 'HumanName', given?: Array<any | null> | null, family?: any | null } | null> | null, address?: Array<{ __typename?: 'Address', line?: Array<any | null> | null, city?: any | null, state?: any | null, postalCode?: any | null } | null> | null, telecom?: Array<{ __typename?: 'ContactPoint', system?: any | null, use?: any | null, value?: any | null } | null> | null } | null> | null };

export type GetPatientQueryVariables = Exact<{
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetPatientQuery = { __typename?: 'Query', patient?: Array<{ __typename?: 'Patient', id?: any | null, flatName?: string | null, icn?: string | null, edipi?: string | null, phone?: Array<string | null> | null, last4?: string | null, birthDate?: any | null, gender?: any | null, zeelId?: string | null, memberId?: string | null, removedFromNetwork?: string | null, name?: Array<{ __typename?: 'HumanName', given?: Array<any | null> | null, family?: any | null } | null> | null, address?: Array<{ __typename?: 'Address', line?: Array<any | null> | null, city?: any | null, state?: any | null, postalCode?: any | null } | null> | null, telecom?: Array<{ __typename?: 'ContactPoint', system?: any | null, use?: any | null, value?: any | null } | null> | null } | null> | null };

export type GetPractitionersQueryVariables = Exact<{
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;


export type GetPractitionersQuery = { __typename?: 'Query', patient?: Array<{ __typename?: 'Patient', id?: any | null, email?: Array<string | null> | null, birthDate?: any | null, active?: any | null, phone?: Array<string | null> | null, gender?: any | null, name?: Array<{ __typename?: 'HumanName', given?: Array<any | null> | null, family?: any | null } | null> | null, address?: Array<{ __typename?: 'Address', line?: Array<any | null> | null, city?: any | null, state?: any | null, postalCode?: any | null } | null> | null, photo?: Array<{ __typename?: 'Attachment', url?: any | null } | null> | null, telecom?: Array<{ __typename?: 'ContactPoint', system?: any | null, use?: any | null, value?: any | null } | null> | null } | null> | null };

export type GetPractitionerQueryVariables = Exact<{
  id?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetPractitionerQuery = { __typename?: 'Query', practitioner?: Array<{ __typename?: 'Practitioner', id?: any | null, flatName?: string | null, phone?: Array<string | null> | null, email?: Array<string | null> | null, npi?: string | null, photo?: Array<{ __typename?: 'Attachment', url?: any | null } | null> | null, address?: Array<{ __typename?: 'Address', line?: Array<any | null> | null, city?: any | null, state?: any | null, postalCode?: any | null } | null> | null, patient?: Array<{ __typename?: 'Patient', id?: any | null, flatName?: string | null, appointment?: Array<{ __typename?: 'Appointment', id?: any | null } | null> | null } | null> | null, role?: Array<{ __typename?: 'PractitionerRole', id?: any | null, specialty?: Array<{ __typename?: 'CodeableConcept', text?: any | null } | null> | null, availableTime?: Array<{ __typename?: 'PractitionerRoleAvailableTime', daysOfWeek?: Array<any | null> | null, allDay?: any | null, availableStartTime?: any | null, availableEndTime?: any | null } | null> | null, telecom?: Array<{ __typename?: 'ContactPoint', system?: any | null, use?: any | null, value?: any | null } | null> | null, location?: Array<{ __typename?: 'Location', name?: any | null, address?: { __typename?: 'Address', line?: Array<any | null> | null, city?: any | null, state?: any | null, postalCode?: any | null } | null } | null> | null } | null> | null, appointment?: Array<{ __typename?: 'Appointment', id?: any | null, start?: any | null, status: any, officeBasedReferral?: boolean | null, ogAppointmentId?: string | null, ogStage?: string | null, providerName?: Array<{ __typename?: 'HumanName', family?: any | null, given?: Array<any | null> | null } | null> | null } | null> | null } | null> | null };


export const GetPatientsDocument = gql`
    query GetPatients($id: [String], $name: String, $page: Int, $pageSize: Int) {
  patient(id: $id, name: $name, page: $page, pageSize: $pageSize) {
    id
    name {
      given
      family
    }
    flatName
    icn
    edipi
    phone
    last4
    address {
      line
      city
      state
      postalCode
    }
    birthDate
    gender
    zeelId
    memberId
    telecom {
      system
      use
      value
    }
    removedFromNetwork
  }
}
    `;

/**
 * __useGetPatientsQuery__
 *
 * To run a query within a React component, call `useGetPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetPatientsQuery(baseOptions?: Apollo.QueryHookOptions<GetPatientsQuery, GetPatientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientsQuery, GetPatientsQueryVariables>(GetPatientsDocument, options);
      }
export function useGetPatientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientsQuery, GetPatientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientsQuery, GetPatientsQueryVariables>(GetPatientsDocument, options);
        }
export type GetPatientsQueryHookResult = ReturnType<typeof useGetPatientsQuery>;
export type GetPatientsLazyQueryHookResult = ReturnType<typeof useGetPatientsLazyQuery>;
export type GetPatientsQueryResult = Apollo.QueryResult<GetPatientsQuery, GetPatientsQueryVariables>;
export const GetPatientDocument = gql`
    query GetPatient($id: [String]) {
  patient(id: $id) {
    id
    name {
      given
      family
    }
    flatName
    icn
    edipi
    phone
    last4
    address {
      line
      city
      state
      postalCode
    }
    birthDate
    gender
    zeelId
    memberId
    telecom {
      system
      use
      value
    }
    removedFromNetwork
  }
}
    `;

/**
 * __useGetPatientQuery__
 *
 * To run a query within a React component, call `useGetPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPatientQuery(baseOptions?: Apollo.QueryHookOptions<GetPatientQuery, GetPatientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientQuery, GetPatientQueryVariables>(GetPatientDocument, options);
      }
export function useGetPatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientQuery, GetPatientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientQuery, GetPatientQueryVariables>(GetPatientDocument, options);
        }
export type GetPatientQueryHookResult = ReturnType<typeof useGetPatientQuery>;
export type GetPatientLazyQueryHookResult = ReturnType<typeof useGetPatientLazyQuery>;
export type GetPatientQueryResult = Apollo.QueryResult<GetPatientQuery, GetPatientQueryVariables>;
export const GetPractitionersDocument = gql`
    query GetPractitioners($id: [String], $name: String, $page: Int, $pageSize: Int) {
  patient(id: $id, name: $name, page: $page, pageSize: $pageSize) {
    id
    name {
      given
      family
    }
    email
    birthDate
    active
    address {
      line
      city
      state
      postalCode
    }
    phone
    gender
    photo {
      url
    }
    telecom {
      system
      use
      value
    }
  }
}
    `;

/**
 * __useGetPractitionersQuery__
 *
 * To run a query within a React component, call `useGetPractitionersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPractitionersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPractitionersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetPractitionersQuery(baseOptions?: Apollo.QueryHookOptions<GetPractitionersQuery, GetPractitionersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPractitionersQuery, GetPractitionersQueryVariables>(GetPractitionersDocument, options);
      }
export function useGetPractitionersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPractitionersQuery, GetPractitionersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPractitionersQuery, GetPractitionersQueryVariables>(GetPractitionersDocument, options);
        }
export type GetPractitionersQueryHookResult = ReturnType<typeof useGetPractitionersQuery>;
export type GetPractitionersLazyQueryHookResult = ReturnType<typeof useGetPractitionersLazyQuery>;
export type GetPractitionersQueryResult = Apollo.QueryResult<GetPractitionersQuery, GetPractitionersQueryVariables>;
export const GetPractitionerDocument = gql`
    query GetPractitioner($id: [String]) {
  practitioner(id: $id) {
    id
    flatName
    photo {
      url
    }
    phone
    email
    address {
      line
      city
      state
      postalCode
    }
    npi
    patient {
      id
      flatName
      appointment {
        id
      }
    }
    role {
      id
      specialty {
        text
      }
      availableTime {
        daysOfWeek
        allDay
        availableStartTime
        availableEndTime
      }
      telecom {
        system
        use
        value
      }
      location {
        name
        address {
          line
          city
          state
          postalCode
        }
      }
    }
    appointment {
      id
      start
      status
      officeBasedReferral
      ogAppointmentId
      ogStage
      providerName {
        family
        given
      }
    }
  }
}
    `;

/**
 * __useGetPractitionerQuery__
 *
 * To run a query within a React component, call `useGetPractitionerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPractitionerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPractitionerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPractitionerQuery(baseOptions?: Apollo.QueryHookOptions<GetPractitionerQuery, GetPractitionerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPractitionerQuery, GetPractitionerQueryVariables>(GetPractitionerDocument, options);
      }
export function useGetPractitionerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPractitionerQuery, GetPractitionerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPractitionerQuery, GetPractitionerQueryVariables>(GetPractitionerDocument, options);
        }
export type GetPractitionerQueryHookResult = ReturnType<typeof useGetPractitionerQuery>;
export type GetPractitionerLazyQueryHookResult = ReturnType<typeof useGetPractitionerLazyQuery>;
export type GetPractitionerQueryResult = Apollo.QueryResult<GetPractitionerQuery, GetPractitionerQueryVariables>;