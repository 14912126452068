import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  soapAutoApproveSelect: {
    width: '250px',
  },
  sidePanelInput: {
    width: '250px',
  },
});
