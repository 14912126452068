import React, { useEffect } from 'react';
import qs from 'qs';
import { useState, useUIStore } from 'utils/hooks';
import { history } from 'utils';
import moment from 'moment';
import { MedicalApi } from 'apis';
import { AppointmentType } from 'apis/medical';
import { SectionLayout, TableNew, Button } from 'components';
import styles from './style.module.scss';

export default function TableTest() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [totalRows, setTotalRows] = useState<any>(0);
  const [appointments, setAppointments] = useState<Array<AppointmentType>>([]);

  // hooks for data fetching
  const urlFilters = qs.parse(history?.location?.search, { ignoreQueryPrefix: true });
  const [filters, setFilters] = useState<any>({
    page: 1,
    search: '',
    ...(urlFilters || {}),
  });
  const { openModal } = useUIStore();

  // fetch data on mount
  const fetchData = async () => {
    setLoading(true);
    try {
      // updating url with filters for easier refresh
      history.push({ search: qs.stringify(filters) });

      const { appointments: _appointments, total } = await MedicalApi.fetchOGAppointmentsToCopy({
        page: filters.page,
        search: filters.search,
      });
      setAppointments(_appointments);
      setTotalRows(total);
    } catch (e) {
      setError('An error occured while fetching the appointments');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [filters.page, filters.search]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPageChange = ({ page }: any) => {
    setFilters({
      ...filters,
      page,
    });
  };

  const onSearchChange = (search: any) => {
    setFilters({
      ...filters,
      page: 1,
      search,
    });
  };

  const openCopyModal = (appointmentId: string) => {
    const appointment = appointments?.find((appt) => appt.id === appointmentId);
    if (appointment) {
      openModal({
        id: 'copy-appointment-to-hsrm',
        props: { appointment },
        callback: (response: any) => {
          if (response) {
            fetchData();
          }
        },
      });
    }
  };

  const openMedicalReviewModal = (appointmentId: string) => {
    const appointment = appointments?.find((appt) => appt.id === appointmentId);
    if (appointment && appointment.procedure_id) {
      openModal({
        id: 'appointment-medical-review',
        props: { appointment },
        callback: (response: any) => {
          if (response) {
            fetchData();
          }
        },
      });
    }
  };

  const columns = [
    { id: 'appointmentId', label: 'Appt ID', width: 150, sortable: false },
    { id: 'name', label: 'Patient Name', width: 300, sortable: false },
    { id: 'date', label: 'Date/Time', width: 250, type: 'moment', sortable: false },
    { id: 'stage', label: 'Stage', width: 150, sortable: false },
    { id: 'status', label: 'Status', width: 250, sortable: false },
    {
      id: 'actions',
      label: ' ',
      width: 500,
      sortable: false,
      renderCell: ({ data }: any) => {
        const actionButtons = [];
        const toAdd = data.actions.includes('add');
        const toCopy = data.actions.includes('copy');
        const toReview = data.actions.includes('medical-review');
        if (toAdd || toCopy) {
          actionButtons.push(
            <Button state='secondary' onClick={() => openCopyModal(data.appointmentId)}>
              {toAdd ? 'Add' : 'Copy'} to HSRM
            </Button>
          );
        }
        if (toReview) {
          actionButtons.push(
            <Button state='secondary' onClick={() => openMedicalReviewModal(data.appointmentId)}>
              Review
            </Button>
          );
        }

        return actionButtons;
      },
    },
  ];

  const appointmentRows = (appointments || []).map((appointment: AppointmentType, index: number) => {
    const {
      id,
      fname,
      lname,
      date,
      stage,
      can_be_copied_into_hsrm,
      first_service_request_appointment /*, copied_into_hsrm*/,
      priority_review,
    } = appointment;
    const actions = [];
    let status = '';
    if (can_be_copied_into_hsrm) {
      if (first_service_request_appointment === true && stage === 'confirmed') {
        actions.push('add');
        status = 'Upcoming';
      } else {
        actions.push('copy');
        status = 'Completed';
      }
    }

    if (priority_review) {
      // actions.push('medical-review');
    }

    return {
      id: `${id}-${index}`, // need to add index since multiple same appt ids can be returned here
      appointmentId: id,
      name: `${fname} ${lname}`,
      date: moment(date),
      stage,
      status: status,
      actions: actions,
    };
  });

  return (
    <SectionLayout alert={error}>
      <div className={styles.base}>
        <TableNew
          loading={loading}
          className={styles.serviceRequestsTable}
          name={`${totalRows} Appointments Need Attention`}
          columns={columns}
          rows={appointmentRows}
          onSearchChange={onSearchChange}
          searchPlaceholder='Search by Name'
          pageSize={50}
          onPageChange={onPageChange}
        />
      </div>
    </SectionLayout>
  );
}
