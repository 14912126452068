import { createBrowserHistory } from 'history';
import qs from 'qs';
import get from 'lodash/get';
import set from 'lodash/set';

export function parseUrl(url: string, paramTypes: Record<string, 'boolean' | 'number' | 'string'> = {}, baseKey = '') {
  const params = qs.parse(url, { ignoreQueryPrefix: true });

  Object.entries(paramTypes).forEach(([key, type]) => {
    let paramKey = key;
    if (baseKey) {
      paramKey = `${baseKey}.${key}`;
    }
    if (type === 'boolean') {
      const value = get(params, paramKey);
      if (value) {
        set(params, paramKey, value === 'true' ? true : value === 'false' ? false : value);
      }
    } else if (type === 'number') {
      set(params, paramKey, Number(get(params, paramKey)));
    }
  });

  return params;
}

const browserHistory = createBrowserHistory();
export default browserHistory;
