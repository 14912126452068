import { createUseStyles } from 'react-jss';
import { AccordionProps } from './Expandable';
import { midnight } from '@zeel-dev/ui-styles';

export const useStyles = createUseStyles({
  expandableWrapper: {
    width: '100%',
    height: (props: AccordionProps) => {
      if (props.expanded) {
        return props.expandedHeight || 'auto';
      } else {
        return props.collapsedHeight || 'auto';
      }
    },
    overflowY: (props: AccordionProps) => (props.expanded || props.scrollWhileCollapsed ? 'auto' : undefined),
  },
  expandableFooter: {
    width: '100%',
    bottom: '0',
    backgroundColor: (props: AccordionProps) =>
      props.footerGradient ? 'linear-gradient(#FFFFFF00, #FFFFFFFF)' : 'white',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  expandableFooterClickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  expandableFooterText: {
    color: midnight,
    textDecoration: 'underline',
  },
});
