import React from 'react';
import { MedicalApi } from 'apis';
import { PatientType } from 'apis/medical';
import { useState, useEffect } from 'utils/hooks';
import PatientForm from 'components/forms/Patient';
import { ServiceRequestList } from 'components';
import Modal, { ModalPropType } from '../templates/Modal';
import styles from './style.module.scss';

export type PatientModalProps = ModalPropType & {
  patient: PatientType;
  patientId?: string;
};

export default function Patient({ patientId, patient: patientAsProp, modalProps }: PatientModalProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>();
  const [patient, setPatient] = useState<any>(patientAsProp);

  // fetch data on mnount
  useEffect(() => {
    const fetchPatient = async () => {
      setLoading(true);
      try {
        const p = await MedicalApi.fetchPatient(patientId as string);
        setPatient(p);
      } catch (e) {
        setError('An error occured while fetching the patient');
        console.error(e);
        setPatient(null);
      }
      setLoading(false);
    };
    setError(null);
    if (patientId && !patientAsProp) fetchPatient();
  }, [patientId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal {...modalProps} loading={loading} alert={error} title='Patient' className={styles.base}>
      <PatientForm patient={patient} marginBottom />
      <ServiceRequestList serviceRequests={patient?.service_requests} />
    </Modal>
  );
}
