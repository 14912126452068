import Modal from '../templates/Modal';
import React, { useState, useCallback } from 'react';
import { useForm } from 'utils/hooks';
import { TextField } from 'components';
import { MedicalApi } from 'apis';
import styles from './style.module.scss';
import { RemovePatientHoldProps } from './RemovePatientHold.types';

export default function RemovePatientHold({
  patientId,
  patientName,
  holdType,
  onClose,
  modalProps,
}: RemovePatientHoldProps) {
  const [error, setError] = useState<string>();
  const { bind, form } = useForm();

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        await MedicalApi.updatePatient(patientId, {
          network_status: 'Active in Network',
        });
        onClose?.({ shouldRemove: true, reason: values.reason });
      } catch (e) {
        console.log(e);
        setError('An error occurred while removing patient hold.');
      }
    },
    [patientId, patientName]
  );

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <Modal
      {...modalProps}
      className={styles.base}
      alert={error}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={form.handleSubmit(onSubmit)}
      title={`Remove Hold on ${patientName}`}
      description={`This will remove the current ${holdType} on this patient.`}
      actions={[
        {
          label: 'Remove Patient Hold',
          state: 'primary',
          onClick: form.handleSubmit((values: any) => onSubmit(values)),
        },
      ]}>
      <TextField
        {...bind('reason')}
        className={styles.reason}
        placeholder='Add a reason... (optional)'
        {...fieldProps}
      />
    </Modal>
  );
}
