import React, { useEffect } from 'react';
import { MemberApi } from 'apis';
import { useState, useCopyToClipboard } from 'utils/hooks';
import Modal, { ModalPropType } from '../templates/Modal';
import Grid from '@mui/material/Unstable_Grid2';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import { TextField } from 'components';
import styles from './style.module.scss';
import { DuplicateCopyBoldIcon } from '@zeel-dev/zeel-ui';

export type BookAppointmentForPatientProps = ModalPropType & {
  memberId: string;
  bookingFlowUrl: string | ((OGMember: any) => string);
};

export default function BookAppointmentForPatient({
  memberId,
  bookingFlowUrl,
  modalProps,
}: BookAppointmentForPatientProps) {
  const { copyToClipboard } = useCopyToClipboard();
  // const { bind, form } = useForm();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [member, setMember] = useState<any>();
  const [password, setPassword] = useState<any>();

  useEffect(() => {
    async function init() {
      // fetch notes
      if (memberId) {
        try {
          const m = await MemberApi.fetchMember(memberId);
          setMember(m);
          const temporaryPassword = await MemberApi.generateTemporaryPassword(memberId);
          copyToClipboard(temporaryPassword, false);
          setPassword(temporaryPassword);
        } catch (e) {
          setError('An error occured while fetching the member, generating the password or logging in.');
        }
      }

      setLoading(false);
    }
    init();
  }, [memberId]); // eslint-disable-line react-hooks/exhaustive-deps

  const copy = (value = '') => {
    copyToClipboard(value);
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    disabled: true,
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      style: { pointerEvents: 'none' },
      endAdornment: (
        <InputAdornment position='end'>
          <DuplicateCopyBoldIcon size={24} />
        </InputAdornment>
      ),
    },
  };

  let url = '';
  if (!loading && member) {
    url =
      typeof bookingFlowUrl === 'string'
        ? bookingFlowUrl
        : bookingFlowUrl instanceof Function
        ? bookingFlowUrl(member)
        : `${process.env.REACT_APP_VA_FLOW_BOOK_URL}`; // will default to va flow with no params, but parameter is required
  }

  return (
    <Modal
      {...modalProps}
      loading={loading}
      alert={error}
      title='Book on Behalf of Patient'
      description={
        <>
          This temporary password was generated for {member?.email} and copied to your clipboard. Copy this password and
          use the link below to be redirected to the patient booking flow. Then, paste the password to log into the
          patient&apos;s account and book on their behalf.
        </>
      }
      className={styles.base}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      backdropClose={false}>
      <Grid className={styles.section} container spacing={3}>
        <Grid xs={12} className={styles.copyWrapper} onClick={() => copy(password)}>
          <TextField value={password} label='Temporary Password' {...fieldProps} />
        </Grid>
        <Grid xs={12}>
          <div className={styles.linkContainer}>
            <Link href={url} target='_blank' className={styles.link}>
              Open Booking Flow
            </Link>
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
}
