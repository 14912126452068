import Modal from '../templates/Modal';
import React, { useState, useCallback } from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { TextField } from 'components';
import { ZrefApi } from 'apis';
import styles from './style.module.scss';
import { MarkRFSAsRejectedProps, RFSRejectionCategoryType } from './MarkRFSAsRejected.types';
import { useForm } from 'utils/hooks';
import { FormControl, FormLabel } from '@mui/material';

const RFSRejectionCategoryLabel: Record<RFSRejectionCategoryType, string> = {
  [RFSRejectionCategoryType.Administrative]: 'Administrative (missing information / improperly filled out)',
  [RFSRejectionCategoryType.VAMC_Specific]: 'VAMC specific (submitted too soon, too many in a year, etc.)',
  [RFSRejectionCategoryType.Clinical]: 'Clinical (patient has met goals, no additional improvement, etc.)',
};

export default function MarkRFSAsRejected({ rfsId, onClose, modalProps }: MarkRFSAsRejectedProps) {
  const { bind, form } = useForm({
    rejectionCategory: RFSRejectionCategoryType.Administrative,
    rejectionNote: '',
  });
  const [error, setError] = useState<string>();

  const onSubmit = useCallback(
    async (values: any) => {
      if (!values.rejectionCategory) {
        return;
      }

      try {
        await ZrefApi.updateRequest(rfsId, {
          vamc_rejected: true,
          vamc_rejected_category: values.rejectionCategory,
          vamc_rejected_notes: values.rejectionNote,
        });
        onClose?.(true);
      } catch (e) {
        console.log(e);
        setError('An error occurred while marking the RFS as rejected');
      }
    },
    [rfsId]
  );

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <Modal
      {...modalProps}
      className={styles.base}
      alert={error}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      title={'Mark RFS as Rejected'}
      actions={[
        { label: 'Cancel', action: 'close' },
        {
          label: 'Mark RFS as Rejected',
          state: 'primary',
          onClick: form.handleSubmit((values: any) => onSubmit(values)),
        },
      ]}>
      <FormControl>
        <FormLabel>Rejection Category</FormLabel>
        <RadioGroup {...bind('rejectionCategory')}>
          {Object.values(RFSRejectionCategoryType).map((key) => (
            <FormControlLabel
              key={key}
              value={key}
              control={<Radio checked={form.values.rejectionCategory === key} />}
              label={RFSRejectionCategoryLabel[key]}
            />
          ))}
        </RadioGroup>
        <TextField
          {...bind('rejectionNote')}
          className={styles.rejectionNote}
          placeholder='Add notes'
          {...fieldProps}
        />
      </FormControl>
    </Modal>
  );
}
