import { withStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#ffffff',
    padding: '3px',
    boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.3);',
    borderRadius: '2px',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: '-12px',
      left: '50%',
      width: 0,
      height: 0,
      border: '6px solid transparent',
      borderColor: 'transparent transparent #ffffff #ffffff',
      marginLeft: '-9px',
      transformOrigin: '0 0',
      transform: 'rotate(-45deg)',
      boxShadow: '-2px 2px 2px 0 rgba(0, 0, 0, 0.1)',
    },
  },
}))(Tooltip);

export default HtmlTooltip;
