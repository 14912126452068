import Grid from '@mui/material/Grid';
import moment from 'moment';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import {
  ValidationCheckmarkCircleOutlineIcon,
  DeleteOutlineBoldIcon,
  AutomaticReviewBoldStatusIcon,
} from '@zeel-dev/zeel-ui';
import { SoapNoteHistoryReviewProps } from './ProviderNotesReview.types';
import { ToggleSection } from 'components';
import styles from './style.module.scss';
export default function SoapNoteHistoryReview({ histories, loading }: SoapNoteHistoryReviewProps) {
  const convertDateTime = (historyTime: string) => {
    const index = historyTime.indexOf(': ');
    if (index === -1) {
      return '';
    }
    return moment(historyTime.substring(index + 2)).format('MM/DD/YYYY hh:mm A');
  };
  return (
    <Grid item xs={12} className={styles.section} container spacing={3}>
      {histories?.length === 0 && !loading ? (
        <p className={styles.noHistoryTitle}>No history</p>
      ) : (
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}>
          {histories?.map((history, index) => {
            const isApprovedSoapNote = history.status === 'approved';
            const actionDateTime = convertDateTime(history.action_date_time);
            const isAutoReviewed = history.reviewed_by?.includes('bypass_automation');
            const reviewedBy = isAutoReviewed
              ? 'Automatically Reviewed'
              : history.reviewed_by
              ? `Reviewed by ${history.reviewed_by}`
              : '';

            return (
              <TimelineItem key={history.action_date_time}>
                <TimelineSeparator>
                  {isApprovedSoapNote ? (
                    !isAutoReviewed ? (
                      <ValidationCheckmarkCircleOutlineIcon
                        color='primary'
                        size={'24px'}
                        className={styles.approvedOrRejectedIcon}
                      />
                    ) : (
                      <AutomaticReviewBoldStatusIcon
                        color='primary'
                        size={'24px'}
                        className={styles.approvedOrRejectedIcon}
                      />
                    )
                  ) : (
                    <DeleteOutlineBoldIcon color='warning' size={'24px'} className={styles.approvedOrRejectedIcon} />
                  )}
                  {histories?.length - 1 !== index && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  {actionDateTime && <p className={styles.soapNoteInfo}>{actionDateTime}</p>}
                  {reviewedBy && <p className={styles.soapNoteInfo}>{reviewedBy}</p>}
                  {!isApprovedSoapNote && (
                    <p className={styles.soapNoteInfo}>
                      <ToggleSection title='Review Instruction' defaultValue={true}>
                        {history.rejection_note}
                      </ToggleSection>
                    </p>
                  )}
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      )}
    </Grid>
  );
}
