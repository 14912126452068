import React from 'react';
import { MedicalApi } from 'apis';
import { useState, useUIStore, useForm } from 'utils/hooks';
import Modal, { ModalPropType } from '../templates/Modal';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { TextField, FieldTitle } from 'components';
import styles from './style.module.scss';

export type RevokeReferralProps = ModalPropType & {
  serviceRequestId: string;
};

export default function RevokeReferral({ serviceRequestId, onClose, scrollTop, modalProps }: RevokeReferralProps) {
  const { openAlert } = useUIStore();
  const { bind, form } = useForm();
  const [error, setError] = useState<string>();
  const reasons = [
    { value: 'Patient RFN', description: '(the patient is not allowed to receive service on our platform)' },
    { value: 'Requested by VAMC', description: '(the VAMC has requested that we return the client to them)' },
    { value: 'Requested by Patient', description: '(the patient requested that we return the client to them)' },
    { value: 'Not in service area', description: '(referral is from outside our expected service area)' },
    { value: 'Unable to fulfill gender', description: '(can’t find availability due to gender preference)' },
    { value: 'Unable to fulfill location', description: '(can’t find provider willing to travel to location)' },
    { value: 'Unable to fulfill schedule', description: '(can’t find mutually agreeable treatment time)' },
    { value: 'Unable to contact veteran', description: '(can’t reach the veteran to schedule an appointment)' },
    // { value: 'Other' },
  ];

  const onSubmit = async (values: any) => {
    const { reason, otherReason } = values;

    try {
      await MedicalApi.revokeReferral(serviceRequestId, {
        reason: reason === 'Other' ? otherReason : reason,
      });

      openAlert({ title: `The referral was revoked`, severity: 'info' });
      onClose?.(true);
    } catch (e) {
      setError('An error occured while revoking the referral');
      scrollTop();
      console.error(e);
    }
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <Modal
      {...modalProps}
      alert={error}
      title='Revoke Referral'
      className={styles.base}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={form.handleSubmit(onSubmit)}
      actions={[
        { label: 'Back', action: 'close' },
        { label: 'Submit for Revocation', action: 'submit' },
      ]}>
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}>
          <FieldTitle margin>Enter a reason for this revocation</FieldTitle>
          <TextField {...bind('reason', { required: true })} select label='Reason' {...fieldProps}>
            {reasons.map((reason: { value: string; description?: string }) => (
              <MenuItem key={reason.value} value={reason.value}>
                {reason.value} <span className={styles.reasonDescription}>{reason.description || ''}</span>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {form.getField('reason') === 'Other' && (
          <Grid item xs={12}>
            <TextField
              {...bind('otherReason', { requiredIf: (values: any) => values['reason'] === 'Other' })}
              multiline
              placeholder='Enter a Reason...'
              {...fieldProps}
            />
          </Grid>
        )}
        <Grid className={styles.whatHappensNext} item xs={12}>
          <p>What happens next:</p>
          <ul>
            <li>All upcoming appointments will be automatically canceled.</li>
            <li>Patient and Provider will receive notifications of any canceled appointments.</li>
            <li>Referral will be marked for copying to HSRM and marked as Revoked.</li>
          </ul>
        </Grid>
      </Grid>
    </Modal>
  );
}
