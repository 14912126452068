import React, { useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { useState, useUIStore, useZccService } from 'utils/hooks';
import { ZCCApi } from 'apis';
import { ZccMedicalReviewType, ZCCSortByParameters } from 'apis/zcc';
// import { AppointmentType, CompositionType } from 'apis/medical';
import { SectionLayout, Table, Button, FilterButtonV2 } from 'components';
import AppointmentSidePanel from 'components/sidePanels/Appointment';
import NoteSidePanel from 'components/sidePanels/Note';
import styles from './style.module.scss';
import { CompositionStatusType } from 'apis/medical';

enum MedicalReviewActions {
  SoapNoteReview = 'review_soap',
  RfsReview = 'review_rfs',
  FlaggedAppointmentReview = 'review_medical_flag',
}

enum MedicalReviewTypes {
  SoapNote = 'SOAP REVIEW',
  Appointment = 'MEDICAL REVIEW',
  Request = 'RFS',
}

export default function MedicalReviewV2() {
  const service = useZccService<ZccMedicalReviewType>(ZCCApi.fetchMedicalReviewItems);

  const [open, setOpen] = useState<boolean>(false);
  const [activeRowData, setActiveRowData] = useState<any>(null);
  const [highlightedRowId, setHighlightedRowId] = useState<string | null | undefined>();
  const { openModal } = useUIStore();

  const rowClicked = (row: any) => {
    const { id } = row || {};
    if (open && activeRowData && id === activeRowData?.id) setOpen(false);
    else {
      setOpen(true);
      setActiveRowData(row);
    }
  };

  const sortChangeHandler = (sortModel: any) => {
    const sortBy: ZCCSortByParameters = {};
    sortModel?.forEach(({ field, sort }: any) => {
      sortBy[field] = sort;
    });
    if (!isEqual(service.parameters.sortBy, sortBy)) {
      service.setSortBy(sortBy);
    }
  };

  const highlightRow = (rowId?: string) => {
    if (!rowId) {
      return;
    }
    setHighlightedRowId(rowId);
    setTimeout(() => setHighlightedRowId(null), 5000);
  };

  // Adding appointments and notes
  const rows = useMemo(() => {
    const r = [
      ...(service.data || []).map((item: ZccMedicalReviewType, index: number) => {
        const {
          _id,
          og_appointment_id, // used for appt flagged for review (until moved to NG)
          patient_name,
          submitted_by,
          date_submitted,
          status,
          actions,
          review_type,
          session,
          referral,
          impacted_area,
        } = item;

        return {
          _id,
          id: index.toString(),
          entity_id: _id + '',
          og_appointment_id,
          patient_name,
          submitted_by,
          date_submitted,
          review_type,
          session,
          referral,
          impacted_area,
          status,
          actions,
          data: item,
        };
      }),
    ];
    return r;
  }, [service.data]);

  // Actions
  const openMedicalReviewModal = (appointmentId: string) => {
    if (appointmentId) {
      openModal({
        id: 'appointment-medical-review',
        props: { appointmentId },
        callback: (response: any) => {
          const previousRow = rows?.find((row: any) => row.entity_id === appointmentId);
          highlightRow(previousRow?.id);
          if (response) {
            service.refresh();
          }
        },
      });
    } else {
      alert(`OG Appointment ID is missing for this record, which prevents from opening the review modal.`);
    }
  };

  const openSoapReviewModal = (compositionId: string) => {
    if (compositionId) {
      openModal({
        id: 'provider-notes-review',
        props: { noteId: compositionId },
        callback: (response: any) => {
          const previousRow = rows?.find((row: any) => row.entity_id === compositionId);
          highlightRow(previousRow?.id);
          if (response) {
            service.refresh();
          }
        },
      });
    } else {
      alert(`Composition ID is missing for this record, which prevents from opening the review modal.`);
    }
  };

  const openReadOnlySoapNotesModal = (compositionId: string, status: CompositionStatusType) => {
    if (compositionId) {
      openModal({
        id: 'read-only-soap-notes',
        props: { noteId: compositionId, status },
      });
    } else {
      alert(`Composition ID is missing for this record, which prevents from opening the review modal.`);
    }
  };

  const openRFSReviewModal = (requestId: string) => {
    setOpen(false);
    if (requestId) {
      openModal({
        id: 'rfs-review',
        props: { requestId },
        callback: (response: any) => {
          const previousRow = rows?.find((row: any) => row.entityId === requestId);
          highlightRow(previousRow?.id);
          if (response) {
            service.refresh();
          }
        },
      });
    } else {
      alert(`Request ID is missing for this record, which prevents from opening the review modal.`);
    }
  };

  const onNoteAction = (action: string, data?: any) => {
    if (action === 'review') {
      openSoapReviewModal(data?.id);
    }
  };

  const columns = useMemo(
    () => [
      { id: '_id', label: 'ID', width: 100 },
      { id: 'og_appointment_id', label: 'OG appt ID', width: 150 },
      { id: 'patient_name', label: 'Patient Name', width: 200, sortable: true },
      { id: 'date_submitted', label: 'Date Submitted', width: 210, type: 'moment', sortable: true },
      { id: 'submitted_by', label: 'Submitted By', width: 200, sortable: true },
      { id: 'review_type', label: 'Review Type', width: 160, sortable: true },
      { id: 'session', label: 'Session', width: 125, sortable: true },
      { id: 'referral', label: 'Referral', width: 180, sortable: true },
      { id: 'impacted_area', label: 'Impacted Area', width: 160, sortable: true },
      { id: 'status', label: 'Status', width: 120, sortable: true },
      {
        id: 'action',
        label: 'Next Step',
        width: 500,
        sortable: false,
        renderCell: ({ row }: any) => {
          const actionsList = [];
          if (row?.data?.actions?.includes(MedicalReviewActions.FlaggedAppointmentReview)) {
            actionsList.push(
              <Button
                state='primary'
                variant='outlined'
                onClick={(e: any) => {
                  openMedicalReviewModal(row?.og_appointment_id);
                  e.stopPropagation();
                }}>
                Review Flagged Appointment
              </Button>
            );
          }
          if (row?.data?.actions?.includes(MedicalReviewActions.SoapNoteReview)) {
            actionsList.push(
              <Button
                state='primary'
                variant='outlined'
                onClick={(e: any) => {
                  openSoapReviewModal(row?.entity_id);
                  e.stopPropagation();
                }}>
                Review SOAP Notes
              </Button>
            );
          }
          if (row?.data?.actions?.includes(MedicalReviewActions.RfsReview)) {
            actionsList.push(
              <Button
                state='primary'
                variant='outlined'
                onClick={(e: any) => {
                  openRFSReviewModal(row?.entity_id);
                  e.stopPropagation();
                }}>
                Review Request
              </Button>
            );
          }

          if (
            row?.data?.review_type === 'SOAP REVIEW' &&
            [CompositionStatusType.Rejected, CompositionStatusType.Approved].includes(row?.status.toLowerCase())
          ) {
            actionsList.push(
              <Button
                state='primary'
                variant='filled'
                onClick={(e: any) => {
                  openReadOnlySoapNotesModal(row?.entity_id, row?.status.toLowerCase());
                  e.stopPropagation();
                }}>
                View SOAP
              </Button>
            );
          }
          return <div className={styles.actions}>{actionsList}</div>;
        },
      },
    ],
    []
  );

  let sideContent = null;

  // If active row is a flagged appt
  if (activeRowData?.review_type === MedicalReviewTypes.Appointment) {
    sideContent = (
      <AppointmentSidePanel
        open={open}
        onClose={() => setOpen(false)}
        ogAppointmentId={activeRowData?.og_appointment_id}
        ngAppointmentId={activeRowData?.entity_id}
      />
    );
  }
  // If active row is a soap note
  if (activeRowData?.review_type === MedicalReviewTypes.SoapNote) {
    sideContent = (
      <NoteSidePanel open={open} onClose={() => setOpen(false)} id={activeRowData?.entity_id} onAction={onNoteAction} />
    );
  }
  // We don't have a side-panel for rfs yet

  return (
    <SectionLayout alert={service.error} title='Medical Review Items' sideContent={sideContent}>
      <div className={styles.tableHeader}>
        <FilterButtonV2
          initialValue={service.parameters.filters}
          onChange={(newFilters: any) => {
            service.setFilters(newFilters);
          }}
          filters={[
            { id: '_id', label: 'ID', type: 'text', size: 'half' },
            { id: 'og_appointment_id', label: 'OG Appointment ID', type: 'text', size: 'half' },
            { id: 'patient_name', label: 'Patient Name', type: 'text', size: 'half' },
            { id: 'submitted_by', label: 'Submitted By', type: 'text', size: 'half' },
            { id: 'session', label: 'Session', type: 'text', size: 'half' },
            { id: 'referral', label: 'Referral #', type: 'text', size: 'half' },
            { id: 'impacted_area', label: 'Impacted Area', type: 'text', size: 'half' },
            { id: 'status', label: 'Status', type: 'text', size: 'half' },
            {
              id: 'actions',
              label: 'Review Type',
              type: 'select-multiple',
              options: [
                { label: 'Soap Note review', value: MedicalReviewActions.SoapNoteReview },
                { label: 'Flagged for Medical Review', value: MedicalReviewActions.FlaggedAppointmentReview },
                { label: 'RFS Review', value: MedicalReviewActions.RfsReview },
              ],
              size: 'half',
            },
            // {
            //   id: 'test',
            //   label: 'Include Test Data',
            //   type: 'checkbox',
            // },
          ]}
        />
      </div>
      <Table
        className={styles.table}
        loading={service.loading}
        name={`${rows.length} Items Need Review`}
        columns={columns}
        rows={rows}
        onRowClick={(row: any) => rowClicked(row)}
        activeRowId={open ? activeRowData?.rowId : undefined}
        highlightedRowId={highlightedRowId}
        page={service.parameters.page}
        pageSize={service.parameters.pageSize}
        serverPagination
        serverRowCount={service.total}
        onPageChange={(page: any) => service.setPage(page)}
        onPageSizeChange={(pageSize: any) => service.setPageSize(pageSize)}
        rowsPerPageOptions={[100]}
        sortModel={service.sortModel}
        onSortModelChange={sortChangeHandler}
      />
    </SectionLayout>
  );
}
