import React, { useMemo, useCallback } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { useState, useDebouncedCallback } from 'utils/hooks';
import { Icon } from 'components';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';

import { DataGrid /*, ColDef, CellParams, CellValue*/, GridRowParams, GridSortModel } from '@mui/x-data-grid';
import styles from './style.module.scss';

export type TablePropType = {
  loading?: boolean;
  name?: string;
  columns: Array<ColumnType>;
  rows: Array<any>; // any since structure varies depending on columns
  onRowClick?: (row: any) => void;
  activeRowId?: string;
  allowSearch?: boolean;
  searchPlaceholder?: string;
  // allowSort?: boolean;
  localSearch?: boolean;
  defaultSearch?: string;
  onSearchChange?: (search?: string) => void;
  autoHeight?: boolean;
  rowHeight?: number;
  footer?: boolean;
  className?: string;
  serverPagination?: boolean;
  serverRowCount?: any;
  onPageChange?: any;
  onPageSizeChange?: any;
  page?: any;
  pageSize?: any;
  rowsPerPageOptions?: any;
  onSortModelChange?: any;
  sortModel?: GridSortModel;

  highlightedRowId?: string | null;
  tableCellClassName?: string;
  rowClassName?: string;
};

type ColumnType = {
  id: string;
  label: string;
  width?: number;
  description?: string;
  type?: string;
  dateFormat?: string;
  getter?: (params: any) => any;
  formatter?: (params: any) => any;
  sort?: (value1: any, value2: any, params1?: any, params2?: any) => any;
  sortable?: boolean;
  options?: any;
  renderCell?: (params: any) => any;
  meta?: any;
  cellClassName?: string;
  disableColumnMenu?: boolean;
};

export default function _Table({
  loading,
  name,
  columns,
  rows,
  onRowClick,
  activeRowId,
  allowSearch = false,
  searchPlaceholder = 'Search',
  // allowSort = true,
  localSearch = false,
  defaultSearch = '',
  onSearchChange,
  autoHeight = false,
  rowHeight = 56,
  footer = true,
  serverPagination = false,
  serverRowCount = 0,
  onPageChange,
  pageSize,
  page,
  onPageSizeChange,
  className,
  rowsPerPageOptions,
  onSortModelChange,
  sortModel,
  highlightedRowId,
  tableCellClassName,
  rowClassName,
}: TablePropType) {
  const [searchTerm, setSearchTerm] = useState<string>(defaultSearch);
  const rowClickHandler = useCallback(
    (row: any) => {
      if (onRowClick) {
        onRowClick(row.row);
      }
    },
    [onRowClick]
  );
  const searchHandler = useDebouncedCallback((value) => {
    if (onSearchChange) onSearchChange(value);
  }, 600);

  const sortHandler = useDebouncedCallback((value) => {
    if (onSortModelChange) onSortModelChange(value);
  }, 100);

  const getRowClassName = useCallback(
    (params: GridRowParams) => {
      return cn(rowClassName, {
        [styles.tableRowActive]: params.row.id === activeRowId,
        [styles.tableRowHighlighted]: params.row.id === highlightedRowId,
      });
    },
    [rowClassName]
  );

  const gridColumns = useMemo(
    () =>
      (columns || []).map((column) => {
        const {
          id,
          label,
          width,
          description,
          type,
          dateFormat,
          getter,
          formatter,
          sort,
          sortable = true,
          options,
          renderCell,
          cellClassName,
          disableColumnMenu,
          meta,
        } = column;
        let data: any = {
          field: id,
          headerName: label,
          description,
          headerClassName: styles.tableHeaderCell,
          sortable,
          options,
          meta,
          cellClassName: () => cn(styles.tableCell, tableCellClassName, cellClassName),
          disableColumnMenu,
        };
        if (formatter) data['valueFormatter'] = formatter;
        if (getter) data['valueGetter'] = getter;
        if (sort) data['sortComparator'] = sort;
        if (width) data['width'] = width;
        if (renderCell) data['renderCell'] = renderCell;
        if (type === 'moment') {
          data = {
            ...data,
            valueFormatter: ({ value }: any) => (value ? moment(value).format(dateFormat || 'lll') : ''),
            sortComparator: (v1: any, v2: any) => moment(v1).valueOf() - moment(v2).valueOf(),
          };
        } else if (type === 'status') {
          data = {
            ...data,
            renderCell: ({ value }: any) => {
              if (value === 'alert' || value === false) return <Icon name='exclamation' color='error' size={25} />;
              if (value === 'valid' || value === true) return <Icon name='checkmark-circle' color='lush' size={25} />;
              return null;
            },
          };
        } else if (type) {
          data['type'] = type;
        }
        return data;
      }),
    [columns]
  );
  const _gridRows = useMemo(() => {
    let gridRows: Array<any> = [...rows];

    if (localSearch && searchTerm) {
      if (typeof localSearch === 'function') {
        gridRows = gridRows.filter(localSearch);
      } else {
        gridRows = gridRows.filter((row) => {
          let query = '';
          gridColumns.forEach((gridColumn) => {
            const val = row[gridColumn.field] || '';
            if (val instanceof moment) query += (val as moment.Moment).format('MM/DD/YYYY');
            else if (val.toString) query += val.toString().toLowerCase();
          });
          return query.includes((searchTerm || '').toLowerCase());
        });
      }
    }
    return gridRows;
  }, [rows, localSearch, searchTerm]);

  return (
    <div className={cn(styles.tableWrapper, className)}>
      {(name || allowSearch) && (
        <div className={styles.topContent}>
          <p className={styles.referralAmount}>{name}</p>
          <div className={styles.fieldsWrapper}>
            {allowSearch && (
              <TextField
                placeholder={searchPlaceholder}
                size='small'
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  searchHandler.callback(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Icon color='grey-5' name='search' size={28} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {/* {allowSort && (
              <Select
                value="mostRecent"
                onChange={sortHandler}
              >
                <MenuItem value="mostRecent">Newest First</MenuItem>
              </Select>
            )} */}
          </div>
        </div>
      )}
      <div className={styles.loader}>{loading && <LinearProgress className={styles.linearProgress} />}</div>
      <DataGrid
        className={cn(styles.table, { [styles.loading]: loading })}
        loading={loading}
        pageSize={pageSize}
        page={page}
        onPageSizeChange={onPageSizeChange}
        hideFooter={!footer}
        autoHeight={autoHeight}
        rowHeight={rowHeight}
        disableSelectionOnClick
        rows={_gridRows}
        columns={gridColumns}
        onRowClick={rowClickHandler}
        paginationMode={serverPagination ? 'server' : 'client'}
        sortingMode={serverPagination ? 'server' : 'client'}
        rowCount={serverRowCount}
        onPageChange={onPageChange}
        sortModel={sortModel}
        onSortModelChange={sortHandler.callback}
        rowsPerPageOptions={rowsPerPageOptions}
        getRowClassName={getRowClassName}
      />
    </div>
  );
}
