import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
// import styles from './style.module.scss';

export type TextFieldPropType = TextFieldProps;

export default function _TextField(props: any) {
  // TODO: fix types
  return (
    <TextField
      {...props}
      error={!!props.error}
      value={props.value || ''}
      helperText={props.helperText || props.error}
      variant={props.variant || 'standard'}
    />
  );
}
