import React from 'react';
import Modal, { ModalPropType } from '../templates/Modal';
import styles from './style.module.scss';

export type InfoProps = ModalPropType & {
  className?: string;
  title: any;
  description?: any;
  content?: any;
  showButton?: boolean;
  buttonLabel?: string;
  buttonState?: string;
};

export default function Info({
  title,
  description,
  content,
  showButton,
  buttonLabel,
  buttonState,
  className,
  modalProps,
}: InfoProps) {
  return (
    <Modal
      {...modalProps}
      title={title}
      description={description}
      className={className}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      actions={showButton ? [{ label: buttonLabel || 'OK', action: 'confirm', state: buttonState, main: true }] : null}>
      {content}
    </Modal>
  );
}
