import React, { useState, ChangeEvent } from 'react';
import Modal from '../templates/Modal';
import Grid from '@mui/material/Grid';
import { TextField } from 'components';
import styles from './style.module.scss';
import { ParkingLocationProps } from './ParkingLocation.types';

export default function ParkingLocation({ parkingNote, onClose, modalProps }: ParkingLocationProps) {
  const [note, setNote] = useState(parkingNote);

  const fieldProps = {
    fullWidth: true,
    InputProps: {
      disableUnderline: true,
      multiline: true,
    },
  };

  return (
    <Modal
      {...modalProps}
      title={`${!parkingNote ? 'Add' : 'Edit'} Location Instructions`}
      className={styles.base}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={() => onClose?.(note)}
      onClose={() => onClose?.(note)}
      actions={[{ label: 'Save', action: 'submit', disabled: !note }]}>
      <Grid className={styles.section} container>
        <TextField
          value={note}
          {...fieldProps}
          rows={6}
          placeholder={`Please add notes for your location e.g. \
"Use gate code 123," and provide parking details. \
If free parking is unavailable, we will bill you for the parking charge \
incurred and attach a picture of the parking bill to your Zeel receipt.`}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setNote(e.target.value)}
        />
      </Grid>
    </Modal>
  );
}
