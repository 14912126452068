import { useMemo } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import store from 'store';
import { AuthStore } from 'store';
import { selectToken, isTokenExpired } from 'store/Auth';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API,
});

const authLink = setContext(async (_, { headers }) => {
  let accessToken = '';
  const stateToken = selectToken(store.getState());
  if (stateToken) {
    if (isTokenExpired(store.getState())) {
      await store.dispatch(AuthStore.refreshToken());
      const updatedStateToken = selectToken(store.getState()); // TODO: check if it works
      accessToken = updatedStateToken?.access; // TODO: check if it works
    } else {
      accessToken = stateToken.access;
    }
  }

  return {
    headers: {
      ...headers,
      authorization: accessToken,
    },
  };
});

const Apollo = ({ children }) => {
  const client = useMemo(() => {
    return new ApolloClient({
      link: authLink.concat(httpLink),
      fetch,
      batchMax: 5,
      batchInterval: 20,
      cache: new InMemoryCache({ addTypename: false }),
      connectToDevTools: process.env.NODE_ENV !== 'production',
    });
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Apollo;
