import React, { FC, SyntheticEvent } from 'react';
import Link from '@mui/material/Link';
import { Icon } from 'components';
import cn from 'classnames';
import styles from './style.module.scss';

type FileRowProps = {
  fileName: string;
  checked: boolean;
  onClick: () => void;
  onClickName: (e: SyntheticEvent) => void;
};

const FileRow: FC<FileRowProps> = ({ fileName, checked, onClick, onClickName }) => {
  return (
    <div className={styles.document} onClick={onClick}>
      <Link
        className={cn(styles.filename, { [styles.selected]: checked })}
        component='button'
        variant='body2'
        underline='always'
        onClick={onClickName}>
        {fileName}
      </Link>
      <div className={styles.action}>
        {/* {note?.lastPackaged && 
      <span>Last Packaged {moment(note.lastPackaged).format('YYYY-MM-DD')}</span>}
    */}
        <div className={styles.icon}>
          {checked ? (
            <Icon name='checkmark-circle-filled' color='lush' size={24} />
          ) : (
            <span className={styles.emptyCircle} />
          )}
        </div>
      </div>
    </div>
  );
};

export default FileRow;
