import React, { useEffect } from 'react';
import qs from 'qs';
import { useState, useUIStore } from 'utils/hooks';
import { history, routes } from 'utils';
import { MedicalApi } from 'apis';
import { Button, SectionLayout } from 'components';
import styles from './style.module.scss';

export default function ReviewSoapNotes() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [soapNoteId, setSoapNoteId] = useState<string>();

  const urlFilters = qs.parse(history?.location?.search, { ignoreQueryPrefix: true });

  const { openModal } = useUIStore();

  const openReviewModal = (noteId: string) => {
    openModal({
      id: 'provider-notes-review',
      props: { noteId },
      callback: (response: any) => {
        if (response) {
          history.push(routes.MEDICAL_MEDICAL_REVIEW);
        }
      },
    });
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const soapNote = await MedicalApi.fetchSoapNotesWithOgAppointmentId(urlFilters.og_appointment_id);
      if (!soapNote) {
        setError('No soap notes found');
      } else {
        setSoapNoteId(soapNote.id);
        openReviewModal(soapNote.id);
      }
    } catch (e) {
      setError('An error occurred while fetching the soap notes');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (urlFilters?.og_appointment_id) {
      fetchData();
    } else {
      history.push(routes.MEDICAL_MEDICAL_REVIEW);
    }
  }, [urlFilters?.ogAppointmentId]);

  return (
    <SectionLayout alert={error} loading={loading} className={styles.base}>
      {soapNoteId && (
        <div>
          <Button state='secondary' onClick={() => openReviewModal(soapNoteId)}>
            Review SOAP
          </Button>
        </div>
      )}
    </SectionLayout>
  );
}
