import { PatientType } from 'apis/medical';
import { ApiPatientZrefType, ApiRequestType, RequestType } from './index';

export function transformApiPatientsZref(apiPatientsZref: Array<ApiPatientZrefType> = []): Array<PatientType> {
  let transformed: Array<any> = [];
  try {
    transformed = (apiPatientsZref || [])
      .filter((i) => i)
      .map((apiPatientZref) => {
        const { patient_id, address, edipi, icn, given_name, family_name, email, phone, birth_date, gender, ssn } =
          apiPatientZref;
        const { address_id, country, line_1, line_2, locality, postal, region } = (address as any) || {};

        return {
          id: patient_id,
          active: null,
          address: address
            ? [
                {
                  id: address_id,
                  city: locality,
                  state: region,
                  postal_code: postal,
                  line: [line_1, line_2].filter((a) => a),
                  country,
                },
              ]
            : [],
          birth_date,
          gender: gender,
          referral_number: null,
          edipi,
          icn,
          member_id: null,
          given_names: given_name,
          family_name,
          email,
          phone,
          service_requests: [],
          ssn,
        };
      });
  } catch (error) {
    console.log(error);
  }

  return transformed;
}

export function transformApiRequests(apiRequests: Array<ApiRequestType> = []): Array<RequestType> {
  let transformed: Array<any> = [];
  try {
    transformed = (apiRequests || [])
      .filter((i) => i)
      .map((apiRequest) => {
        const { patient } = apiRequest;
        const clientPatient = transformApiPatientsZref([patient])?.[0];
        return {
          ...apiRequest,
          patient: clientPatient,
        };
      });
  } catch (error) {
    console.log(error);
  }

  return transformed;
}
