import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import store, { persistor } from 'store';
import 'styles/index.css';
import App from './App';
import Apollo from './apollo';
import preset from 'jss-preset-default';
import { jss } from 'react-jss';

jss.setup(preset());

try {
  const version = process.env.REACT_APP_QARC_VERSION;
  if (version) {
    console.log(`QARC version: ${version}`);
    (window as any)['qarc'] = version;
  } else {
    console.log('QARC version not found');
  }
} catch {
  console.log('QARC version not found');
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Apollo>
          <App />
        </Apollo>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
