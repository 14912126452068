import React from 'react';
import { useDispatch } from 'utils/hooks';
import { UIStore } from 'store';
import { PatientFormGraphQL } from 'components/forms/Patient';
import { Button, SidePanel, FieldTitle } from 'components';
import styles from './style.module.scss';
import { useGetPatientQuery, Patient } from 'generated/graphql';

export type PatientSidePanelPropType = {
  open?: boolean;
  onClose?: () => void;
  id: string; // the patient id,
  onChange?: () => void;
};

export default function PatientGraphQL({ open, onClose, id, onChange }: PatientSidePanelPropType) {
  const { data, error, loading, refetch } = useGetPatientQuery({
    skip: !id,
    variables: {
      id,
    },
  });
  const dispatch = useDispatch();
  const patient: any = data?.patient?.[0]; // temporary

  const close = () => {
    if (onClose) {
      onClose();
    }
  };

  const openCreateMemberAccountModal = () => {
    dispatch(
      UIStore.openModal({
        id: 'create-member-account',
        props: {
          email: patient?.telecom?.find((a: any) => a.system === 'email')?.value,
          firstName: (patient?.name?.[0]?.given || []).join(' '),
          lastName: patient?.name?.[0]?.family,
          mobile: patient?.phone,
          zip: patient?.address?.[0]?.postalCode,
          birthdate: patient?.birthDate,
          gender: patient?.gender,
          autoGeneratePassword: true,
        },
        callback: (createdMember: any) => {
          if (createdMember) {
            const fullName =
              createdMember?.fname && createdMember?.lname ? `${createdMember.fname} ${createdMember.lname}` : null;
            openLinkPatientToMember(createdMember?.id, fullName);
          }
        },
      })
    );
  };

  const openLinkPatientToMember = (memberId: any = null, memberName?: string | null) => {
    dispatch(
      UIStore.openModal({
        id: 'link-patient-to-member',
        props: {
          patientId: patient?.id,
          memberId,
          memberName,
        },
        callback: () => {
          refetch({ id });
        },
      })
    );
  };

  // const openServiceRequest = (serviceRequest: any) => {
  //   if (!serviceRequest?.id) return;
  //   history.push(`${routes.MEDICAL_REFERRALS}?id=${serviceRequest.id}`);
  //   // dispatch(UIStore.openModal({
  //   //   id: 'info',
  //   //   props: {
  //   //     title: serviceRequest?.referral_number,
  //   //     content: <>
  //   //       <p>id: {serviceRequest?.id}</p>
  //   //       <p>expires: {serviceRequest?.expiration_date}</p>
  //   //       <p>quantity: {serviceRequest?.quantity?.available}/{serviceRequest?.quantity?.value} remaining</p>
  //   //     </>
  //   //   },
  //   // }));
  // };

  const openBookAppointmentForVeteran = () => {
    if (!patient?.memberId) return;

    dispatch(
      UIStore.openModal({
        id: 'book-appointment-for-patient',
        props: {
          memberId: patient?.memberId,
          bookingFlowUrl: (OGMember: any) => {
            return `${process.env.REACT_APP_VA_FLOW_BOOK_URL}?fromZcc=1&email=${encodeURIComponent(OGMember?.email)}`;
          },
        },
        callback: () => {
          refetch({ id });
        },
      })
    );
  };

  const openUploadConsentModal = () => {
    dispatch(
      UIStore.openModal({
        id: 'upload-consent',
        props: {
          patientId: id,
        },
        callback: (res: any) => {
          if (res) {
            refetch({ id });
          }
        },
      })
    );
  };

  return (
    <SidePanel title='Patient Record' loading={loading} alert={error?.message} open={open} onClose={close}>
      <PatientFormGraphQL patient={patient as Patient} editable onSavePatient={() => onChange?.()} />
      {/* <ServiceRequestList serviceRequests={patient?.service_requests} onClick={openServiceRequest} /> */}

      <FieldTitle margin className={styles.actionTitle}>
        Actions
      </FieldTitle>
      <div className={styles.actions}>
        <Button variant='outlined' color='primary' size='large' onClick={() => openUploadConsentModal()}>
          Upload Consent Forms
        </Button>
        <Button
          variant='outlined'
          color='primary'
          size='large'
          disabled={patient?.memberId}
          onClick={() => openCreateMemberAccountModal()}>
          Create Zeel Member account
        </Button>
        <Button
          variant='outlined'
          color='primary'
          size='large'
          disabled={patient?.memberId}
          onClick={() => openLinkPatientToMember()}>
          Link Patient to Member
        </Button>
        <Button
          variant='outlined'
          color='primary'
          size='large'
          disabled={!patient?.memberId}
          onClick={() => openBookAppointmentForVeteran()}>
          Book VA appointment
        </Button>
        {!loading && !patient?.memberId && (
          <p className={styles.behalfVetWarning}>
            You can book a VA appointment on behalf of a veteran only when he/she has a zeel account
          </p>
        )}
      </div>
    </SidePanel>
  );
}
