import { ModalPropType } from '../templates/Modal';

export type PutPatientOnHoldProps = ModalPropType & {
  patientId: string;
  patientName: string;
};

export enum OnHoldType {
  Scheduling = 'scheduling',
  Medical = 'medical',
  PatientReview = 'trust_and_safety',
}
