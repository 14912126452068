import React from 'react';
import { MemberApi } from 'apis';
import { useForm, useUIStore, useState, useEffect } from 'utils/hooks';
import Modal, { ModalPropType } from '../templates/Modal';
import Grid from '@mui/material/Grid';
import { FieldTitle, AutocompleteField } from 'components';
import styles from './style.module.scss';

export type LinkPatientToMemberProps = ModalPropType & {
  patientId: string;
  memberId?: string;
  memberName?: string;
};

export default function LinkPatientToMember({
  patientId,
  memberId,
  memberName,
  onClose,
  scrollTop,
  modalProps,
}: LinkPatientToMemberProps) {
  const { openAlert } = useUIStore();
  const { form } = useForm({ memberId: memberId || '' });
  const [memberSearch, setMemberSearch] = useState();
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState<any>(null);
  const [touched, setTouched] = useState(false);
  const [first, setFirst] = useState(true);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const m = await MemberApi.searchMembers({ name: memberSearch });
        setMembers(m);
      } catch (error) {
        console.error(error);
      }
    };

    if (first && memberId) {
      setSelectedMember({
        id: memberId,
        name: memberName || 'account created',
      });
    }

    setFirst(false);

    if (memberSearch) fetchMembers();
  }, [memberSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeHandler = (member: any) => {
    setTouched(true);
    setSelectedMember(member);
  };

  const onSubmit = async () => {
    const mId = (selectedMember as any)?.id;
    if (!mId) return;

    try {
      await MemberApi.linkMember({
        patientId,
        memberId: mId,
      });

      openAlert({ title: `Patient was linked to member ${mId}` });
      onClose?.();
    } catch (error) {
      form.setFormError(`An error occured`);
      scrollTop();
    }
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  const options: any = (members || []).map((member: any) => {
    return {
      id: member?.id,
      name: `${member?.fname} ${member?.lname} - ${member?.email}`,
    };
  });
  if (selectedMember) options.push(selectedMember);

  return (
    <Modal
      {...modalProps}
      alert={form.formError}
      title='Link Patient to Member Account'
      description={<>Link a patient to a Zeel Account using the account&apos;s member ID.</>}
      className={styles.base}
      backdropClose={false}
      onSubmit={onSubmit}
      actions={[
        { label: 'Cancel', action: 'close' },
        { label: 'Link Patient', action: 'submit', disabled: !(selectedMember as any)?.id },
      ]}>
      <Grid className={styles.fields} container spacing={3}>
        <Grid item xs={12}>
          <FieldTitle icon='orientation-card'>New Zeel Account Information</FieldTitle>
        </Grid>
        <Grid item xs={12}>
          <AutocompleteField
            filterOptions={(x: any) => x}
            getOptionLabel={(o: any) => `${o?.name} (${o?.id})`}
            onInputChange={(value: any) => setMemberSearch(value)}
            onChange={onChangeHandler}
            options={options}
            ListBoxProps={{ style: { maxHeight: '150px' } }}
            label='Link to existing Zeel Member account'
            value={(selectedMember as any)?.id}
            error={!(selectedMember as any)?.id && touched ? 'Please fill out this field' : null}
            {...fieldProps}
          />
        </Grid>
      </Grid>
    </Modal>
  );
}
