import { FC, useState, useRef } from 'react';
import Modal from '../templates/Modal';
import Button from '@mui/material/Button';
import styles from './style.module.scss';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-mui';
import { AddEditLocationProps } from './AddEditLocation.types';
import Grid from '@mui/material/Grid';
import { Segment, AddPlusOutlineBoldIcon } from '@zeel-dev/zeel-ui';
import { useUIStore } from 'utils/hooks';
import { FormikProps } from 'formik';
import isNil from 'lodash/isNil';
import * as yup from 'yup';
import { joinString } from 'utils/helper';

const validationSchema = yup.object({
  address1: yup.string().required('Please fill out this field.'),
  address2: yup.string().notRequired(),
  city: yup.string().required('Please fill out this field.'),
  state: yup.string().required('Please fill out this field.'),
  zip: yup
    .string()
    .required('Please fill out this field.')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits'),
});

const AddEditLocation: FC<AddEditLocationProps> = ({ location, onClose, modalProps }) => {
  const [table, setTable] = useState(+(location?.table_count ?? 0));
  const [stairs, setStairs] = useState(+(location?.floor ?? 0));
  const [parkingNote, setParkingNote] = useState(location?.parking);
  const formRef = useRef<FormikProps<any>>(null);

  const handleSubmit = async () => {
    formRef.current?.submitForm();
  };

  const { openModal } = useUIStore();

  const handleParkingNote = () => {
    openModal({
      id: 'parking-location',
      props: {
        parkingNote,
      },
      callback: async (newParkingNote: string) => {
        setParkingNote(newParkingNote);
      },
    });
  };

  const fieldProps = {
    variant: 'outlined',
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <Modal
      {...modalProps}
      title={!location ? 'Add New Address' : 'Edit Address'}
      description='Location where treatment will take place.'
      className={styles.base}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      backdropClose={false}
      onSubmit={handleSubmit}
      actions={[
        {
          label: 'Continue',
          action: 'submit',
        },
      ]}>
      <label className={styles.label}>What is the street address?</label>
      <Formik
        innerRef={formRef}
        initialValues={{
          address1: location?.address1 || '',
          address2: location?.address2 || '',
          city: location?.city || '',
          state: location?.state || '',
          zip: location?.zip || '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onClose?.({
            ...values,
            table_count: table,
            floor: stairs,
            parking: parkingNote,
            hastable: table > 0,
            name: joinString([values.city, values.address1, values.address2]),
          });
        }}>
        {() => (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Field component={TextField} type='text' label='Address' name='address1' {...fieldProps} />
            </Grid>
            <Grid item xs={6}>
              <Field component={TextField} type='text' label='Unit Number (Optional)' name='address2' {...fieldProps} />
            </Grid>
            <Grid item xs={6}>
              <Field component={TextField} type='text' label='City' name='city' {...fieldProps} />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                type='text'
                label='State'
                name='state'
                inputProps={{ maxLength: 2 }}
                {...fieldProps}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                type='text'
                label='Zip'
                name='zip'
                inputProps={{ maxLength: 5 }}
                {...fieldProps}
              />
            </Grid>
          </Grid>
        )}
      </Formik>
      <label className={styles.label}>Does the location have a massage table?</label>
      <Segment
        items={[
          {
            id: 0,
            label: 'No',
          },
          {
            id: 1,
            label: '1 Table',
          },
          {
            id: 2,
            label: '2 Tables',
          },
        ]}
        fill
        value={table}
        onChange={(newValue) => !isNil(newValue) && setTable(newValue as number)}
      />
      <label className={styles.label}>Are stairs required for access?</label>
      <Segment
        items={[
          {
            id: 0,
            label: 'No',
          },
          {
            id: 1,
            label: '1 Flight',
          },
          {
            id: 2,
            label: '2+ Flights',
          },
        ]}
        fill
        value={stairs}
        onChange={(newValue) => !isNil(newValue) && setStairs(newValue as number)}
      />
      <Button className={styles.addButton} onClick={handleParkingNote}>
        <AddPlusOutlineBoldIcon size={24} />
        &nbsp;&nbsp;{parkingNote ? 'Edit' : 'Any'} parking and location notes?
      </Button>
    </Modal>
  );
};

export default AddEditLocation;
