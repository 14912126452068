import React, { useState, ChangeEvent } from 'react';
import Modal from '../templates/Modal';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { Icon, TextField } from 'components';
import styles from './style.module.scss';
import { PDFCreatedProps } from './PDFCreated.types';

export default function PDFCreated({ filename: fn, fileCount, onClose, modalProps }: PDFCreatedProps) {
  const [edit, setEdit] = useState(false);
  const [filename, setFilename] = useState(fn);

  const fieldProps = {
    fullWidth: true,
    InputProps: {
      disableUnderline: !edit,
      readOnly: !edit,
      endAdornment: edit && <InputAdornment position='end'>.pdf</InputAdornment>,
    },
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <Modal
      {...modalProps}
      title='PDF Created'
      description={
        <>
          Packaged {fileCount} file{fileCount > 1 ? 's' : ''} successfully.
        </>
      }
      className={styles.base}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={() => onClose?.(filename + '.pdf')}
      onClose={() => onClose?.(null)}
      actions={[
        { label: 'Back', action: 'close', disabled: edit },
        { label: 'Save', action: 'submit', disabled: edit },
      ]}>
      <Grid className={styles.section} container>
        <Icon name='pdf' size={62} />
        <div className={styles.filename}>
          <TextField
            value={filename + (!edit ? '.pdf' : '')}
            {...fieldProps}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFilename(e.target.value)}
          />
          {!edit ? (
            <Icon name='edit-pencil' size={20} onClick={() => setEdit(true)} />
          ) : (
            <Icon
              name='checkmark-filled'
              color='dusty-blue'
              size={20}
              onClick={() => {
                if (!filename) {
                  setFilename(fn);
                }
                setEdit(false);
              }}
            />
          )}
        </div>
      </Grid>
    </Modal>
  );
}
