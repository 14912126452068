import React, { FC } from 'react';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-mui';
import Grid from '@mui/material/Grid';
import { Button, Icon } from 'components';
import { NoteProps } from './Notes.types';
import * as yup from 'yup';

const validationSchema = yup.object({
  note: yup.string().required('Please enter a value in this field'),
});

const Note: FC<NoteProps> = ({ label, note, showActions, saveButtonLabel = 'Save', onClick, onSave, onCancel }) => {
  return (
    <Formik
      initialValues={{
        note: note || '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        onSave(values.note);
        resetForm();
        setSubmitting(false);
      }}>
      {({ handleSubmit, resetForm }) => (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Field
              component={TextField}
              type='text'
              label={label}
              variant='outlined'
              name='note'
              fullWidth
              multiline
              maxRows={5}
              onClick={onClick}
            />
          </Grid>
          {showActions && (
            <>
              <Grid item>
                <Button state='primary' startIcon={<Icon name='checkmark' size={18} />} onClick={handleSubmit}>
                  {saveButtonLabel}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='outlined'
                  state='primary'
                  startIcon={<Icon name='x-circle' size={18} />}
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}>
                  Cancel
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Formik>
  );
};

export default Note;
