import React, { useMemo } from 'react';
import qs from 'qs';
import isEqual from 'lodash/isEqual';
import { useState, useUIStore, useZccService } from 'utils/hooks';
import { history } from 'utils';
import { ZCCApi } from 'apis';
import { ZccReferralType, ZCCSortByParameters } from 'apis/zcc';
import { SectionLayout, Table, Button, FilterButtonV2, IconMenu, Icon } from 'components';
import Tooltip from '@mui/material/Tooltip';
import ServiceRequestSidePanel from 'components/sidePanels/ServiceRequest';
import moment from 'moment';
import { c } from 'utils';
import styles from './style.module.scss';

// enum ReferralActions {
//   RevokeReferral = 'revoke_referral',
//   UpdateExpirationDate = 'update_expiration_date',
// }

enum ReferralColumn {
  VA_REFERRAL_ID = 'va_referral_id',
  PATIENT_ID = 'patient_id',
  PATIENT_NAME = 'patient_name',
  DATE_CREATED = 'date_created',
  EXPIRATION_DATE = 'expiration_date',
  STATUS = 'status',
  OFFICE_BASED_REFERRAL = 'office_based_referral',
  VA_FACILITY_ID = 'va_facility',
  VA_FACILITY_NAME = 'va_facility_name',
  CONDITION_TYPE = 'condition_type',
  DAYS_SINCE_REFERRAL_ACCEPTANCE = 'days_since_referral_acceptance',
  SESSIONS = 'sessions',
  CONSENTS_COMPLETED = 'consents_completed',
  CONSENTS_MISSING = 'consents_missing',
  ACTION = 'action',
}

export default function ReferralsOld() {
  const { id: initialId } = qs.parse(history?.location?.search, { ignoreQueryPrefix: true }) || {};
  const service = useZccService<ZccReferralType>(ZCCApi.fetchReferralItems, {
    initialParameters: {
      filters: {
        _id: initialId,
      },
    },
    paramTypes: { office_based_referral: 'boolean', consents_completed: 'boolean' },
  });

  const [open, setOpen] = useState<boolean>(false);
  const [activeRowData, setActiveRowData] = useState<any>(null);
  // const [highlightedRowId, setHighlightedRowId] = useState<string | null | undefined>();
  const { openModal } = useUIStore();

  const rowClicked = (row: any) => {
    const { id } = row || {};
    if (open && activeRowData && id === activeRowData?.id) setOpen(false);
    else {
      setOpen(true);
      setActiveRowData(row);
    }
  };

  const sortChangeHandler = (sortModel: any) => {
    const sortBy: ZCCSortByParameters = {};
    sortModel?.forEach(({ field, sort }: any) => {
      if (field === ReferralColumn.DAYS_SINCE_REFERRAL_ACCEPTANCE) {
        sortBy['date_created'] = sort === 'asc' ? 'desc' : 'asc';
      } else {
        sortBy[field] = sort;
      }
    });
    if (!isEqual(service.parameters.sortBy, sortBy)) {
      service.setSortBy(sortBy);
    }
  };

  // const highlightRow = (rowId: string) => {
  //   setHighlightedRowId(rowId);
  //   setTimeout(() => setHighlightedRowId(null), 5000);
  // };

  const rows = useMemo(() => {
    const r = [
      ...(service.data || []).map((item: ZccReferralType, index: number) => {
        const {
          _id,
          actions,
          date_created,
          expiration_date,
          icd_10_codes,
          impacted_area,
          patient_id,
          patient_name,
          remaining_sessions,
          status,
          total_sessions,
          condition_type,
          va_facility,
          va_facility_name,
          va_referral_id,
          consents_completed,
          consents_missing = [],
        } = item;

        return {
          id: index,
          entity_id: _id.toString(),
          actions,
          date_created,
          expiration_date,
          icd_10_codes,
          impacted_area,
          patient_id,
          patient_name,
          sessions: `${remaining_sessions}/${total_sessions}`,
          status,
          va_facility,
          va_facility_name,
          va_referral_id,
          condition_type,
          consents_completed: consents_completed ? 'Yes' : 'No',
          consents_missing,
          data: item,
        };
      }),
    ];
    return r;
  }, [service.data]);

  // Actions
  const revokeReferral = (serviceRequestId: string) => {
    openModal({
      id: 'revoke-referral',
      props: {
        serviceRequestId,
      },
      callback: (response: any) => {
        if (response) {
          service.refresh();
        }
      },
    });
  };

  const openUpdateReferralExpirationModal = async (serviceRequestId: string, expirationDate: string) => {
    openModal({
      id: 'update-referral-expiration-date',
      props: {
        serviceRequestId,
        expirationDate,
      },
      callback: (response: any) => {
        if (response) {
          service.refresh();
        }
      },
    });
  };

  const openUploadConsentModal = (patientId: string, serviceRequestId: string) => {
    openModal({
      id: 'upload-consent',
      props: {
        patientId,
        serviceRequestId,
      },
      callback: (response: any) => {
        if (response) {
          service.refresh();
        }
      },
    });
  };

  const openPatientModal = async (patientId: string) => {
    openModal({
      id: 'patient',
      props: {
        patientId,
      },
    });
  };

  const columns = useMemo(
    () => [
      { id: ReferralColumn.VA_REFERRAL_ID, label: 'Referral #', width: 200 },
      { id: ReferralColumn.PATIENT_ID, label: 'Patient ID', width: 140 },
      { id: ReferralColumn.PATIENT_NAME, label: 'Patient Name', width: 250 },
      {
        id: ReferralColumn.DATE_CREATED,
        label: 'Date Created',
        width: 170,
        type: 'moment',
        renderCell: ({ row }: any) => {
          const momentDate = moment(row?.date_created);
          if (!row?.date_created || !momentDate?.isValid()) return '-';
          const isAging = moment().diff(momentDate, 'days') >= 1 && row?.status === 'DRAFT';
          return (
            <div className={styles.dateCreatedCellWrapper}>
              <p>{momentDate.format('ll')}</p>
              {isAging && <p className={styles.agingTag}>More than 24H</p>}
            </div>
          );
        },
      },
      { id: ReferralColumn.EXPIRATION_DATE, label: 'Expiration Date', width: 180, type: 'moment' },
      { id: ReferralColumn.STATUS, label: 'Status', width: 120 },
      {
        id: ReferralColumn.OFFICE_BASED_REFERRAL,
        label: '',
        width: 50,
        renderCell: ({ row }: any) => {
          return (
            <div className={styles.referralCellWrapper}>
              {row?.data?.office_based_referral && <Icon className={styles.inOfficeIcon} name='building' />}
            </div>
          );
        },
      },
      {
        id: ReferralColumn.CONDITION_TYPE,
        label: 'Type',
        width: 110,
      },
      {
        id: ReferralColumn.VA_FACILITY_ID,
        label: 'VA Facility ID',
        width: 150,
      },
      {
        id: ReferralColumn.VA_FACILITY_NAME,
        label: 'VA Facility Name',
        width: 200,
        renderCell: ({ value }: any) => {
          return (
            <Tooltip title={value} arrow placement='top'>
              <span className={styles.ellipsisText}>{value}</span>
            </Tooltip>
          );
        },
      },
      {
        id: ReferralColumn.DAYS_SINCE_REFERRAL_ACCEPTANCE,
        label: 'Days Since Referral Acceptance',
        width: 120,
        renderCell: ({ row }: any) => {
          const momentDate = moment(row?.date_created);
          if (!row?.date_created || !momentDate?.isValid()) return '-';
          const daysSinceReferralAcceptance = moment().diff(row?.date_created, 'days');
          return daysSinceReferralAcceptance;
        },
      },
      { id: ReferralColumn.SESSIONS, label: 'Remaining Sessions (x/n)', width: 120, sortable: false },
      {
        id: ReferralColumn.CONSENTS_COMPLETED,
        label: 'Consents Completed',
        width: 200,
        sortable: false,
      },
      {
        id: ReferralColumn.CONSENTS_MISSING,
        label: 'Consents Missing',
        width: 200,
        sortable: false,
        renderCell: ({ value }: any) => {
          return (
            <div className={styles.ellipsisText}>
              {value.map((consent: string) => (
                <p key={consent} className={styles.ellipsisText}>
                  {c.CONSENT_FORMS[consent] ?? consent}
                </p>
              ))}
            </div>
          );
        },
      },
      {
        id: ReferralColumn.ACTION,
        label: 'Actions',
        width: 100,
        sortable: false,
        renderCell: ({ row }: any) => {
          const actionItems = [
            {
              icon: 'calendar-empty',
              label: 'Update Expiration Date',
              onClick: () => openUpdateReferralExpirationModal(row?.entity_id, row?.expiration_date),
            },
            {
              icon: 'x-circle',
              label: 'Revoke Referral',
              severity: 'danger',
              onClick: () => revokeReferral(row?.entity_id),
            },
            ...(row.consents_completed === 'No'
              ? [
                  {
                    icon: 'upload',
                    label: 'Upload Consent',
                    onClick: () => openUploadConsentModal(row?.patient_id, row?.entity_id),
                  },
                ]
              : []),
            {
              icon: 'user',
              label: 'Open Patient',
              newSection: true,
              onClick: () => openPatientModal(row?.patient_id),
            },
          ];

          return <IconMenu items={actionItems} />;
        },
      },
    ],
    []
  );

  const openAddReferralModal = () => {
    openModal({
      id: 'add-referral',
      callback: (response: any) => {
        if (response) {
          service.refresh();
        }
      },
    });
  };

  const sideContent = (
    <ServiceRequestSidePanel open={open} onClose={() => setOpen(false)} id={activeRowData?.entity_id} />
  );

  return (
    <SectionLayout
      alert={service.error}
      title='Filtered Referrals'
      sideContent={sideContent}
      titleContent={
        <Button variant='outlined' color='primary' size='large' onClick={openAddReferralModal}>
          Add New Referral
        </Button>
      }>
      <div className={styles.tableHeader}>
        <FilterButtonV2
          initialValue={service.parameters.filters}
          onChange={(newFilters: any) => {
            if (newFilters.consents_completed) {
              newFilters.consents_missing = [];
            }
            service.setFilters(newFilters);
          }}
          filters={[
            { id: '_id', label: 'Service Request ID', type: 'text', size: 'half' },
            { id: 'va_referral_id', label: 'Referral #', type: 'text', size: 'half' },
            { id: 'patient_id', label: 'Patient ID', type: 'text', size: 'half' },
            { id: 'patient_name', label: 'Patient Name', type: 'text', size: 'half' },
            {
              id: 'status',
              label: 'Status',
              type: 'select-multiple',
              options: [
                { label: 'DRAFT', value: 'DRAFT' },
                { label: 'ACTIVE', value: 'ACTIVE' },
                { label: 'COMPLETED', value: 'COMPLETED' },
                { label: 'REVOKED', value: 'REVOKED' },
              ],
              size: 'half',
            },
            { id: 'date_created', label: 'Date Created', type: 'date', size: 'half' },
            { id: 'expiration_date', label: 'Expiration Date', type: 'text', size: 'half' },
            {
              id: 'condition_type',
              label: 'Referral Type',
              type: 'select',
              options: [
                { label: 'All', value: '' },
                { label: 'Acute', value: 'Acute' },
                { label: 'Chronic', value: 'Chronic' },
                { label: 'Initial Care', value: 'Initial Care' },
                { label: 'Continuation Care', value: 'Continuation Care' },
              ],
              size: 'half',
            },
            { id: 'va_facility', label: 'VA Facility ID', type: 'text', size: 'half' },
            { id: 'va_facility_name', label: 'VA Facility Name', type: 'text', size: 'half' },
            {
              id: 'office_based_referral',
              label: 'In-Office',
              type: 'select',
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ],
              size: 'half',
            },
            {
              id: 'consents_completed',
              label: 'Consents Completed',
              type: 'select',
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ],
              size: 'half',
            },
            {
              id: 'consents_missing',
              label: 'Consents Missing',
              type: 'select-multiple',
              hidden: service.parameters.filters?.consents_completed !== false,
              options: [
                { label: 'Medical Massage', header: true },
                { label: 'Authorization for the use of phi', value: 'authorization_for_the_use_of_phi_form' },
                { label: 'Informed Consent', value: 'informed_consent_form' },
              ],
              size: 'half',
            },
            // {
            //   id: 'actions',
            //   label: 'Action Type',
            //   type: 'select-multiple',
            //   options: [
            //     { label: 'Revoke Referral', value: ReferralActions.RevokeReferral },
            //     { label: 'Update Expiration Date', value: ReferralActions.UpdateExpirationDate },
            //   ],
            //   size: 'half',
            // },
          ]}
        />
      </div>
      <Table
        className={styles.table}
        loading={service.loading}
        name={`${rows.length} Referrals`}
        columns={columns}
        rows={rows}
        onRowClick={(row: any) => rowClicked(row)}
        activeRowId={open ? activeRowData?.rowId : undefined}
        // highlightedRowId={highlightedRowId}
        page={service.parameters.page}
        pageSize={service.parameters.pageSize}
        serverPagination
        serverRowCount={service.total}
        onPageChange={(page: any) => service.setPage(page)}
        onPageSizeChange={(pageSize: any) => service.setPageSize(pageSize)}
        rowsPerPageOptions={[100]}
        sortModel={service.sortModel}
        onSortModelChange={sortChangeHandler}
      />
    </SectionLayout>
  );
}
