import React from 'react';
import { TextField } from 'components';
import Segments from 'components/Segments';
import Divider from '@mui/material/Divider';
import { ProviderNotesNotesReviewExtensionRequestProps, ExtensionRequestType } from './ProviderNotesReview.types';
import styles from './style.module.scss';

export default function ProviderNotesReviewExtensionRequest({
  extension,
  onExtensionChange,
  bind,
}: ProviderNotesNotesReviewExtensionRequestProps) {
  const reasonFieldProps = {
    fullWidth: true,
    variant: 'filled',
    InputProps: {
      readOnly: false,
    },
    InputLabelProps: {
      shrink: true,
    },
  };
  return (
    <>
      <Divider style={{ marginBottom: 24 }} />
      <p className={styles.title}>Review Extension Request</p>
      <p style={{ marginBottom: 24 }}>
        This provider has indicated that this patient is medically indicated for 60 minute sessions. Please review the
        SOAP notes before approving this request.
      </p>
      <Segments
        name='extension'
        value={extension}
        onChange={onExtensionChange}
        label='Approve Extension to 60 minutes?'
        items={[
          { value: ExtensionRequestType.MANUAL_APPROVED, label: 'Approve Extension to 60 minutes?' },
          { value: ExtensionRequestType.REJECTED, label: 'No, Deny Extension' },
        ]}
        subText={
          extension === ExtensionRequestType.MANUAL_APPROVED
            ? 'All future sessions in this referral will be extended 60 minutes.'
            : 'All future sessions in this referral will remain at 30 minutes.'
        }
      />
      {extension === ExtensionRequestType.REJECTED && (
        <>
          <p className={styles.reasonForDeny}>Reason for Denying Extension to 60 minutes</p>
          <TextField
            {...bind('reasonForDeny', { required: true })}
            hiddenLabel
            placeholder='Type a reason...'
            {...reasonFieldProps}
          />
        </>
      )}
    </>
  );
}
