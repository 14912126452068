import { FC, useState, useMemo } from 'react';
import Modal from '../templates/Modal';
import { useZccSoapNotesQuery } from 'utils/hooks';
import styles from './style.module.scss';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { ReadOnlySoapNotesModalProps } from './ReadOnlySoapNotesModal.types';
import { CompositionStatusType } from 'apis/medical';
import ReadOnlySoapNotesHeader from './ReadOnlySoapNotesHeader';
import ReadOnlySoapNote from './ReadOnlySoapNote';
import { joinString } from 'utils/helper';
import SoapNoteHistoryReview from '../ProviderNotesReview/SoapNoteHistoryReview';
import { ToggleSection } from 'components';
const ReadOnlySoapNotesModal: FC<ReadOnlySoapNotesModalProps> = ({ noteId, status, modalProps }) => {
  const [error, setError] = useState<string>();
  const { loading, soapNotes, compareNotes, serviceRequest, patient, practitioner, reviewedBy, appointment } =
    useZccSoapNotesQuery({
      noteId,
      onError: (err) => setError(err),
    });
  const soapNoteHistories = useMemo(() => {
    return soapNotes?.history?.filter(
      (history) => history.status.includes('approved') || history.status.includes('rejected')
    );
  }, [soapNotes]);
  return (
    <Modal
      {...modalProps}
      loading={loading}
      alert={error}
      title={status === CompositionStatusType.Approved ? 'Approved SOAP Note' : 'Rejected SOAP Note'}
      description={
        <>
          {!loading &&
            soapNotes &&
            joinString([
              soapNotes.status === CompositionStatusType.Approved ? 'Approved' : 'Rejected',
              reviewedBy ? `by ${reviewedBy}` : '',
              soapNotes.meta?.lastUpdated ? `on ${moment(soapNotes.meta?.lastUpdated).format('LL')}` : '',
            ])}
        </>
      }
      className={styles.base}
      backdropClose={false}>
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}>
          <ToggleSection title='History'>
            <SoapNoteHistoryReview histories={soapNoteHistories || []} loading={loading} />
          </ToggleSection>
        </Grid>

        <ReadOnlySoapNotesHeader
          quantity={serviceRequest?.quantity}
          submittedDate={soapNotes?.date_submitted}
          appointment={{ practitioner, date: soapNotes?.appointment_date, sessionNumber: appointment?.sessionNumber }}
          patient={patient}
        />
        <ReadOnlySoapNote
          previousVersion={compareNotes?.previous_version}
          soapNotes={soapNotes}
          reviewedBy={reviewedBy}
          practitioner={practitioner}
        />
      </Grid>
    </Modal>
  );
};

export default ReadOnlySoapNotesModal;
