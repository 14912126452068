import React, { FC, Fragment, useEffect, KeyboardEvent } from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';
import { SegmentsProps } from './Segments.types';

const Segments: FC<SegmentsProps> = ({
  id,
  className,
  disabled,
  label,
  labelEndContent,
  items,
  value,
  error,
  meta = {},
  multiple,
  readOnly,
  required = true,
  subText,
  wrapLabel,
  onChange,
  onError,
}) => {
  useEffect(() => {
    // initial validation (useful for testing required fields that are empty)
    if (!isValid(value) && onError) onError('Please select an option');
  }, []);

  const isValid = (v: any) => {
    let valid = true;
    if (required && (v === null || v === undefined || (Array.isArray(v) && v.length === 0))) {
      valid = false;
    }

    return valid;
  };

  const onItemClick = (item: any) => {
    let newValue = multiple ? value || [] : value;

    if (disabled) return;

    if (multiple) {
      const itemIndex = newValue.indexOf(item.value);
      if (itemIndex > -1) {
        newValue.splice(itemIndex, 1);
      } else {
        newValue.push(item.value);
      }
    } else {
      newValue = item.value;
    }

    if (!item.clickingPreventsCallback) {
      onChange(newValue, !isValid(newValue) ? 'Please select an option' : null);
    }

    if (item.onClick) {
      item.onClick();
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    const { keyCode } = event;
    const valueIndex = items.findIndex((i) => i.value === value);

    if (!multiple) {
      if (keyCode == 37 && valueIndex > 0) {
        onItemClick(items[valueIndex - 1]);
      }
      if (keyCode == 39 && valueIndex < items.length - 1) {
        onItemClick(items[valueIndex + 1]);
      }
    }
  };

  const hasBeenTouched = meta.touched;
  const errorMessage = hasBeenTouched && error;

  return (
    <div
      id={id}
      className={classnames(
        styles.segments,
        {
          [styles.readOnly]: readOnly,
          [styles.invalid]: hasBeenTouched && error,
          [styles.disabled]: disabled,
          [styles.wrapLabel]: wrapLabel,
        },
        className
      )}>
      {label && (
        <label>
          {label}
          {labelEndContent}
        </label>
      )}
      <div
        tabIndex={0}
        aria-label={`${label}. Use left and right arrow to navigate through the options.`}
        onKeyUp={handleKeyPress}
        className={styles.container}>
        {items.map((item: any, i: number) => (
          <Fragment key={i}>
            {i !== 0 && <hr />}
            <div
              className={classnames(styles.item, {
                [styles.active]: multiple ? (value || []).indexOf(item.value) > -1 : value === item.value,
              })}
              onClick={() => onItemClick(item)}>
              {item.label}
            </div>
          </Fragment>
        ))}
      </div>
      {errorMessage && <div className={styles.validationText}>{errorMessage}</div>}
      {subText && <div className={styles.extraText}>{subText}</div>}
    </div>
  );
};

export default Segments;
