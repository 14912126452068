import { useState } from 'react';
import PersonaCard from '../AddReferral/PractitionerSearch/PersonaCard';
import Modal from '../templates/Modal';
import styles from './style.module.scss';
import { SwitchProviderOfficeProps } from './SwitchProviderOffice.types';
import { useUIStore } from 'utils/hooks';
import { MedicalApi } from 'apis';
import { AddressType } from 'apis/medical';

export default function SwitchProviderOffice({
  serviceRequest,
  practitioner,
  scrollTop,
  onClose,
  modalProps,
}: SwitchProviderOfficeProps) {
  const { openAlert } = useUIStore();
  const [matchingAddress, setMatchingAddress] = useState<AddressType>();
  const [error, setError] = useState<string>();

  const handleSubmit = async () => {
    if (!matchingAddress || !serviceRequest.id) {
      return;
    }
    try {
      await MedicalApi.updateServiceRequest(serviceRequest.id, {
        requested_location: {
          address: matchingAddress,
        },
      });
      openAlert({
        title: 'All appointments created from this point forward will be at the selected office location.',
      });
      onClose?.(true);
    } catch (e) {
      setError('An error occurred while updating the referral');
      scrollTop();
      console.error(e);
    }
  };

  return (
    <Modal
      {...modalProps}
      alert={error}
      title='Switch Provider’s Office'
      // eslint-disable-next-line max-len
      description={`Select another office location for provider ${practitioner.flattenName}, effective immediately for new appointments created from this point forward. Any existing appointments will remain as they were.`}
      className={styles.base}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={handleSubmit}
      actions={[
        {
          label: 'Switch Office',
          action: 'submit',
          disabled: !matchingAddress,
        },
      ]}>
      <p className={styles.sectionHeader}>Select an Office</p>
      <PersonaCard
        selectNewLocation
        npi={practitioner.npi}
        name={practitioner.flattenName}
        phone={practitioner.phone}
        email={practitioner.email}
        requestedLocation={serviceRequest.requested_location?.[0]?.address}
        officeLocations={practitioner.office_based_address}
        onMatchAddressChange={setMatchingAddress}
      />
    </Modal>
  );
}
