import { useRef, useReducer, useState } from 'react';
import useMap from './useMap';

/**
 * TODO:
 * - required input option to handle multiple formats
 * - test if bindCustom works properly (for non-event components)
 * - test if html5 validation works for other types of input as well
 * - global error? (not field related, and is cleared on any field change)
 * - ways for component to have a onError callback in case of inner-validations
 *   (the onError prop should be optional as not all components will handle it)
 * - detect object type of "e" (as Event) and extractt target value, else automatically pass it?
 */
function useReferencedCallback() {
  var callbacks = useMap();
  return function (key, current) {
    if (!callbacks.has(key)) {
      var callback = function () {
        return callback.current.apply(callback, arguments);
      };
      callbacks.set(key, callback);
    }

    callbacks.get(key).current = current;
    return callbacks.get(key);
  };
}

function isEqual(value, other) {
  if (Array.isArray(value) && Array.isArray(other)) {
    return (
      value.length === other.length &&
      value.every(function (a) {
        return other.indexOf(a) > -1;
      }) &&
      other.every(function (b) {
        return value.indexOf(b) > -1;
      })
    );
  }

  return value === other;
}

function stateReducer(state, newState) {
  return typeof newState === 'function' ? newState(state) : { ...state, ...newState };
}

function useFormState(_ref) {
  var initialState = _ref.initialState;
  var state = useRef();
  var initialValues = useMap();
  var comparators = useMap();

  const [values, setValues] = useReducer(stateReducer, initialState || {});
  const [touched, setTouched] = useReducer(stateReducer, {});
  const [validity, setValidity] = useReducer(stateReducer, {});
  const [errors, setError] = useReducer(stateReducer, {});
  const [formError, setFormError] = useState();
  const [pristine, setPristine] = useReducer(stateReducer, {});

  state.current = {
    values: values,
    touched: touched,
    validity: validity,
    errors: errors,
    formError: formError,
    pristine: pristine,
  };

  function getInitialValue(name) {
    return initialValues.has(name) ? initialValues.get(name) : initialState[name];
  }

  function updatePristine(name, value) {
    var comparator = comparators.get(name); // If comparator isn't available for an input, that means the input wasn't
    // mounted, or manually added via setField.

    comparator = typeof comparator === 'function' ? comparator : isEqual;
    setPristine({ [name]: !!comparator(getInitialValue(name), value) });
  }

  function setFieldState(name, value, inputValidity, inputTouched, inputError) {
    setValues({ [name]: value });
    setTouched({ [name]: inputTouched });
    setValidity({ [name]: inputValidity });
    setError({ [name]: inputError });
    updatePristine(name, value);
  }

  function setField(name, value) {
    // need to store the initial value via setField in case it's before the
    // input of the given name is rendered.
    if (!initialValues.has(name)) {
      initialValues.set(name, value);
    }

    setFieldState(name, value, true, true);
  }

  function setFields(fields = {}) {
    Object.keys(fields).forEach((name) => {
      const value = fields[name];
      setField(name, value);
    });
  }

  function clearField(name) {
    setField(name);
  }

  function resetField(name) {
    setField(name, getInitialValue(name));
  }

  function deleteField(name) {
    setValues((oldState) => {
      const newState = { ...oldState };
      delete newState[name];
      return newState;
    });
  }

  function isPristine() {
    return Object.keys(state.current.pristine).every(function (key) {
      return !!state.current.pristine[key];
    });
  }

  function forEach(cb) {
    Object.keys(state.current.values).forEach(cb);
  }

  return {
    get current() {
      return state.current;
    },
    setValues: setValues,
    setTouched: setTouched,
    setValidity: setValidity,
    setError: setError,
    setFormError: setFormError,
    setField: setField,
    setFields: setFields,
    setPristine: setPristine,
    deleteField: deleteField,
    updatePristine: updatePristine,
    initialValues: initialValues,
    resetField: resetField,
    clearField: clearField,
    forEach: forEach,
    isPristine: isPristine,
    comparators: comparators,
  };
}

const defaultFormOptions = {
  onBlur: () => null,
  onChange: () => null,
  onClear: () => null,
  onReset: () => null,
};

const defaultInputOptions = {};

export default function useForm(initialValues = {}, _formOptions = {}) {
  const formState = useFormState({ initialState: initialValues });
  const formOptions = {
    ...defaultFormOptions,
    ..._formOptions,
  };
  const { set: setDirty, get: isDirty } = useMap();
  const { set: setFieldOptions, get: getFieldOptions } = useMap();
  const referencedCallback = useReferencedCallback();

  function touch(name) {
    if (!formState.current.touched[name]) {
      formState.setTouched({ [name]: true });
    }
  }

  function validate({ name, value: _value, values: _values, e, touch: _touch = false }) {
    const value = _value || formState.current.values[name];
    const values = _values || formState.current.values;
    const inputOptions = getFieldOptions(name) || {};
    const isCheckbox = e?.target?.type === 'checkbox';
    const isString = typeof value === 'string' || value instanceof String;
    const hasNoValue =
      !value || value?.length === 0 || (isString && !value.replace(/\s/g, '')) || (isCheckbox && value === false);
    let error;
    let isValid = true;

    // custom validator fct
    if (typeof inputOptions.validate === 'function') {
      let result = inputOptions.validate(value, values, e);

      if (result !== true && result != null) {
        isValid = false;
        error = result !== false ? result : '';
      }
    }
    // html5 validation (if no error was caught in custom validate fct above)
    if (e && e.target && e.target.validity && !error) {
      isValid = e.target.validity.valid;
      error = e.target.validationMessage;
    }
    // validation if field is required
    if (
      inputOptions.requiredIf &&
      typeof inputOptions.requiredIf === 'function' &&
      inputOptions.requiredIf(values) &&
      hasNoValue
    ) {
      isValid = false;
      error = 'Please fill out this field.';
    }
    if (inputOptions.required && hasNoValue) {
      // TODO: add required support for different field types (empty array, "off", etc) as now it will work only for text
      isValid = false;
      error = 'Please fill out this field.';
    }

    formState.setValidity({ [name]: isValid });
    formState.setError({ [name]: error });
    if (_touch) touch(name);

    return error;
  }

  function validateAll() {
    const errors = {};
    formState.forEach((name) => {
      const error = validate({ name, touch: true });
      errors[name] = error;
    });
    return errors;
  }

  /**
   * The getter function (either bind or bindCustom) that returns props
   */
  const getter = (name, _inputOptions = {}, type) => {
    const hasValueInState = formState.current.values[name] !== undefined;
    const inputOptions = {
      ...defaultInputOptions,
      ..._inputOptions,
    };
    // const isNative = type === 'native';
    const isCustom = type === 'custom';

    // setting field options
    setFieldOptions(name, inputOptions);

    // This is used to cache input props that shouldn't change across re-renders.
    const key = ''.concat(type, '.').concat(name, '.');

    function setDefaultValue() {
      let value = inputOptions.defaultValue || '';
      // TODO: support arrays [] as default val
      formState.setValues({ [name]: value });
    }

    function getCompareFn() {
      if (typeof inputOptions.compare === 'function') {
        return inputOptions.compare;
      }
      return (value, other) => isEqual(value, other);
    }

    formState.comparators.set(name, getCompareFn());

    const inputProps = {
      name,
      get value() {
        // auto populating default values if an initial value is not provided
        if (!hasValueInState) setDefaultValue();
        // keep track of user-provided initial values on first render
        else if (!formState.initialValues.has(name)) formState.initialValues.set(name, formState.current.values[name]);
        // auto populating default touched
        if (formState.current.touched[name] == null) formState.setTouched({ [name]: false });
        // auto populating default pristine
        if (formState.current.pristine[name] == null) formState.setPristine({ [name]: true });
        return hasValueInState ? formState.current.values[name] : '';
      },
      get error() {
        return formState.current.errors[name];
      },
      onChange: referencedCallback('onChange.'.concat(key), function (e) {
        setDirty(name, true);
        formState.setFormError(null);
        const isCheckbox = !isCustom && e?.target?.type === 'checkbox';
        let value;

        if (isCustom) {
          value = e;
          if (value === undefined) {
            // setting value to its current state if onChange does not return
            // value to prevent React from complaining about the input switching
            // from controlled to uncontrolled
            value = formState.current.values[name];
          }
        } else {
          value = isCheckbox ? e.target.checked : e.target.value;
        }

        if (inputOptions.onChange) {
          inputOptions.onChange(value);
        }

        touch(name);

        const partialNewState = { [name]: value };
        const newValues = { ...formState.current.values, ...partialNewState };

        if (!inputOptions.validateOnBlur) {
          validate({ name, value, values: newValues, e });
        }

        formState.updatePristine(name, value);
        formState.setValues(partialNewState);

        if (formOptions.onChange) {
          setTimeout(() => formOptions.onChange(e, formState.current.values, newValues), 0);
        }
      }),
      onBlur: referencedCallback('onBlur.'.concat(key), function (e) {
        touch(name);
        if (inputOptions.onBlur) {
          inputOptions.onBlur(e);
        }

        if (!formState.current.touched[name] || isDirty(name)) {
          setDirty(name, false);
          validate({ name, e });
        }
      }),
    };

    return inputProps;
  };

  const formStateAPI = useRef({
    // keys from formState.current
    values: formState.current.values,
    touched: formState.current.touched,
    validity: formState.current.validity,
    errors: formState.current.errors,
    formError: formState.current.formError,
    pristine: formState.current.pristine,
    // ---
    isPristine: formState.isPristine,
    clearField: formState.clearField,
    resetField: formState.resetField,
    getField: (name) => (formState.current.values || {})[name],
    setField: formState.setField,
    setFields: formState.setFields,
    deleteField: formState.deleteField,
    setFieldError: function setFieldError(name, error) {
      formState.setValidity({ [name]: false });
      formState.setError({ [name]: error });
    },
    getFieldError: (name) => (formState.current.errors || {})[name],
    setFormError: function setFormError(error) {
      formState.setFormError(error);
    },
    clear: function clear() {
      formState.forEach(formState.clearField);
      formOptions.onClear();
    },
    reset: function reset() {
      formState.forEach(formState.resetField);
      formOptions.onReset();
    },
    validate: validateAll,
    handleSubmit: function (onSubmit, onSubmitError) {
      return async (e) => {
        if (e?.preventDefault) e.preventDefault();
        const formErrors = validateAll();
        const hasError = Object.values(formErrors).find((val) => !!val);
        if (!hasError) {
          await onSubmit(formState.current.values, !!hasError);
        } else if (onSubmitError) {
          await onSubmitError(formErrors);
        }
      };
    },
  }); // exposing current form state (e.g. values, touched, validity, etc)

  Object.keys(formState.current).forEach(function (key) {
    formStateAPI.current[key] = formState.current[key];
  });

  return {
    form: formStateAPI.current,
    bind: (...args) => getter(args[0], args[1], 'native'),
    bindCustom: (...args) => getter(args[0], args[1], 'custom'),
  };
}
