import {
  ApiFacilityType,
  FacilityType,
  ApiServiceRequestType,
  ServiceRequestType,
  ApiPatientType,
  PatientType,
  ApiCommentType,
  CommentType,
  CompositionType,
  AppointmentType,
} from './';
import moment from 'moment-timezone';
import zipcodeToTimezone from 'zipcode-to-timezone';
import he from 'he';
import capitalize from 'lodash/capitalize';

export const getOptionsFromEnum = (enums: Record<string, string>, sort = false) => {
  const values = Object.values(enums);
  if (sort) {
    values.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }
  return values.map((value) => ({
    value,
    label: capitalize(value.replaceAll('-', ' ')),
  }));
};

export const Condition = {
  highestPriority: 0,
};

export const BodyPartCoding = {
  highestPriority: 0,
};

export const Name = {
  highestPriority: 0,
};

export const Caregiver = {
  highestPriority: 0,
  codingHighestPriority: 0,
};

export const Insurance = {
  highestPriority: 0,
};

export const ServiceRequest = {
  referralDocumentIndex: 0,
};

export function transformApiFacilities(apiFacilities: Array<ApiFacilityType> = []): Array<FacilityType> {
  let transformed: Array<any> = [];
  try {
    transformed = (apiFacilities || [])
      .filter((i) => i)
      .map((apiFacility) => {
        const {
          address,
          id,
          identifier,
          name,
          meta,
          position,
          status,
          external_note,
          internal_note,
          telecom /*, managing_organization, meta, mode, resource_type*/,
          contact_information,
          referral_method,
          massage_therapy_new,
          ready_for_orientation,
        } = apiFacility;
        const facility_id = identifier?.find((i) => i.system.includes('facility-id'))?.value;
        const phone = telecom?.find((i) => i.system === 'phone')?.value;

        return {
          id,
          facility_id,
          name,
          address,
          position,
          status,
          phone,
          external_note,
          internal_note,
          meta,
          contact_information,
          referral_method,
          massage_therapy_new,
          ready_for_orientation,
        };
      });
  } catch (error) {
    console.log(error);
  }

  return transformed;
}

export function transformApiServiceRequests(
  apiServiceRequests: Array<ApiServiceRequestType> = []
): Array<ServiceRequestType> {
  let transformed: Array<any> = [];
  try {
    transformed = (apiServiceRequests || [])
      .filter((i) => i)
      .map((apiServiceRequest) => {
        const {
          id,
          assigned_provider,
          authored_on,
          identifier,
          occurrence_period,
          occurrencePeriod,
          insurance,
          note,
          consent,
          quantity,
          quantityQuantity,
          meta,
          status,
          subject,
          service_duration,
          reason_reference,
          revocation_reason,
          revocation_date,
          supporting_info,
          office_based_referral,
          condition_type,
          conditions,
          requested_location,
          va_facility_id,
          treatment_cadence,
          duration_ext_multiple_body_parts,
          duration_ext_multiple_body_parts_details,
          duration_ext_regions,
          duration_ext_regions_details,
          duration_ext_symptoms,
          duration_ext_symptoms_details,
          duration_ext_other,
          duration_ext_other_details,
          referring_organization,
        } = apiServiceRequest;
        const { start, end } = occurrence_period || occurrencePeriod || {};
        const patient_id =
          subject?.type === 'Patient' ? ((subject?.reference || '').split('/') || []).slice(-1).pop() : null;
        const referral_number = identifier?.find((i) => i.system.includes('referral-number'))?.value;
        const insurer = insurance?.[Insurance.highestPriority]?.insurer;
        const isTestData = !!meta?.tag?.find((t: any) => t?.code === 'testData');
        const hasDurationUpdatePending = !!meta?.tag?.find(
          (tag) => tag?.display === 'durationUpdatePending' && tag?.code === 'True'
        );
        const extensionApprovedOrRejected = !!meta?.tag?.find(
          (tag) => tag?.display === 'durationUpdateManualApproved' || tag?.display === 'durationUpdateRejected'
        );

        const condition = conditions?.[Condition.highestPriority];
        const chief_complaint = condition?.chief_complaint;
        const comorbidities = condition?.comorbidities;
        const provisional_diagnosis = condition?.provisional_diagnosis;
        const body_parts =
          (condition?.body_site || [])
            ?.map((bp: any) => {
              return bp?.coding?.[BodyPartCoding.highestPriority];
            })
            ?.filter((x: any) => x) || [];
        const diagnosis_codes = condition?.code?.coding?.map((c: any) => c.code) || [];
        const hasClinicalRationaleForExtension =
          duration_ext_multiple_body_parts || duration_ext_regions || duration_ext_symptoms || duration_ext_other;

        return {
          id,
          assigned_provider,
          patient_id,
          chief_complaint,
          comorbidities,
          provisional_diagnosis,
          body_parts,
          diagnosis_codes,
          issue_date: start,
          expiration_date: end,
          consent,
          note,
          status: status,
          insurer,
          referral_number,
          quantity,
          quantityQuantity,
          referral_document: reason_reference?.[ServiceRequest.referralDocumentIndex],
          additional_documents: supporting_info,
          created_at: authored_on,
          duration: service_duration || null,
          test: isTestData,
          revocation_reason,
          revocation_date,
          office_based_referral,
          hasExtensionRequest: hasDurationUpdatePending && !extensionApprovedOrRejected,
          requested_location,
          condition_type,
          va_facility_id,
          treatment_cadence,
          duration_ext_multiple_body_parts,
          duration_ext_multiple_body_parts_details,
          duration_ext_regions,
          duration_ext_regions_details,
          duration_ext_symptoms,
          duration_ext_symptoms_details,
          duration_ext_other,
          duration_ext_other_details,
          hasClinicalRationaleForExtension,
          referring_organization,
          insurance,
        };
      });
  } catch (error) {
    console.log(error);
  }

  return transformed;
}

export function transformApiPatients(apiPatients: Array<ApiPatientType> = []): Array<PatientType> {
  let transformed: Array<any> = [];
  try {
    transformed = (apiPatients || [])
      .filter((i) => i)
      .map((apiPatient) => {
        const {
          id,
          active,
          address,
          birth_date,
          telecom,
          /*communication,*/ meta,
          gender,
          identifier = [],
          name,
          service_requests,
          caregivers,
          removed_from_network,
          patient_ssn,
          hold_status,
          hold_status_reason,
          hold_status_date,
          hold_created_date,
          referring_organization,
        } = apiPatient;
        const family_name = name?.[Name.highestPriority]?.family;
        const given_names = name?.[Name.highestPriority]?.given || [];
        const referral_number = identifier.find((i) => i.system.includes('referral-number'))?.value;
        const edipi = identifier.find((i) => i.system.includes('edipi'))?.value;
        const icn = identifier.find((i) => i.system.includes('icn'))?.value;
        const member_id = identifier.find((i) => i.system.includes('/member_id'))?.value;
        const ahf_member_id = identifier.find((i) => i.system.includes('ahf_member_id'))?.value;
        const email = telecom?.find((i) => i.system === 'email')?.value;
        const phoneData = telecom?.find((i) => i.system === 'phone' && !i.use);
        const phone = phoneData?.value;
        const phoneRank = phoneData?.rank;
        const mobilePhone = telecom?.find((i) => i.system === 'phone' && i.use === 'mobile')?.value;
        const workPhone = telecom?.find((i) => i.system === 'phone' && i.use === 'work')?.value;
        let contactPreference = null;
        if (phoneRank === 1) contactPreference = 'phone-only';
        if (phoneRank === 2) contactPreference = 'phone-preferred';
        const caregiver = caregivers?.[Caregiver.highestPriority];
        const isTestData = !!meta?.tag?.find((t: any) => t?.code === 'testData');
        const addresses = (address || []).map((a: any) => {
          const { city, line, postal_code, state } = (a as any) || {};
          return {
            city,
            line,
            postal_code,
            state,
          };
        });

        return {
          id,
          active,
          address: addresses,
          birth_date,
          caregiver: caregiver
            ? {
                id: caregiver?.id,
                firstName: (caregiver?.name?.[Name.highestPriority]?.given || []).join(' '),
                lastName: caregiver?.name?.[Name.highestPriority]?.family,
                email: caregiver?.telecom?.find((i) => i.system === 'email')?.value,
                phone: caregiver?.telecom?.find((i) => i.system === 'phone')?.value,
                relationship:
                  caregiver?.relationship?.[(caregiver?.relationship || []).length - 1]?.coding?.[
                    Caregiver.codingHighestPriority
                  ]?.display,
              }
            : null,
          gender,
          referral_number,
          edipi,
          icn,
          member_id,
          ahf_member_id,
          given_names,
          family_name,
          email,
          phone,
          phoneMobile: mobilePhone,
          phoneWork: workPhone,
          contactPreference,
          service_requests: transformApiServiceRequests(service_requests || []),
          ssn: patient_ssn,
          test: isTestData,
          removed: removed_from_network === 'True',
          hold_status,
          hold_status_reason,
          hold_status_date,
          hold_created_date,
          referring_organization,
        };
      });
  } catch (error) {
    console.log(error);
  }

  return transformed;
}

export function transformApiComments(apiComments: Array<ApiCommentType> = []): Array<CommentType> {
  let transformed: Array<CommentType> = [];
  try {
    transformed = (apiComments || [])
      .filter((i) => i)
      .map((apiComment) => {
        const { id, appointment_id, dt_edited, dt_inserted, member_id, member, note } = apiComment;

        return {
          id: id + '',
          appointmentId: appointment_id + '',
          dateEdited: dt_edited,
          dateCreated: dt_inserted,
          memberId: member_id + '',
          memberName: member?.fname && member?.lname ? `${member.fname} ${member.lname}` : `Member ${member_id}`,
          member,
          text: note,
        };
      });
  } catch (error) {
    console.log(error);
  }

  return transformed;
}

export function transformApiSOAPNotes(apiCompositions: Array<CompositionType> = []): Array<CompositionType> {
  let transformed: Array<CompositionType> = [];
  try {
    transformed = (apiCompositions || [])
      .filter((i) => i)
      .map((apiComposition) => {
        const uploadedToHsrm = apiComposition.meta?.tag
          ?.filter((t: any) => t?.display === 'uploaded_to_hsrm')
          ?.sort((a, b) => moment(b.code).unix() - moment(a.code).unix());
        return {
          ...apiComposition,
          lastPackaged: uploadedToHsrm?.[0]?.code,
        };
      });
  } catch (error) {
    console.log(error);
  }

  return transformed;
}

export function decodeApiSOAPNotes(apiCompositions: Array<CompositionType> = []): Array<CompositionType> {
  let decoded: Array<CompositionType> = [];
  try {
    const order = ['subjective', 'objective', 'assessment', 'plan', 'treatment'];
    decoded = (apiCompositions || [])
      .filter((i) => i)
      .map((apiComposition) => {
        const { notes } = apiComposition;

        return {
          ...apiComposition,
          notes: notes
            .map(({ text, title }) => ({ text: he.decode(text).replace(/(?:\\r\\n|\\r|\\n)/g, ''), title }))
            .sort((a, b) => {
              return order.indexOf(a.title) - order.indexOf(b.title);
            }),
        };
      });
  } catch (error) {
    console.log(error);
  }

  return decoded;
}

export function transformApiAppointments(apiAppointments: Array<AppointmentType> = []): Array<AppointmentType> {
  let transformed: Array<AppointmentType> = [];
  try {
    transformed = (apiAppointments || [])
      .filter((i) => i)
      .map((apiAppointment) => {
        const { extension, identifier = [] } = apiAppointment;
        const sessionNumber = extension?.find((e) => e.url.includes('session_number'))?.value_integer;
        const ogAppointmentId = identifier.find((i) => i.system.includes('appointment_id'))?.value;
        const newApiAppointment = { ...apiAppointment, sessionNumber, ogAppointmentId };
        if (apiAppointment.address?.zip) {
          const tz = zipcodeToTimezone.lookup(apiAppointment.address.zip);
          if (tz) {
            const local = moment.tz(apiAppointment.date, tz);
            newApiAppointment.date = local.format();
          }
        }
        return newApiAppointment;
      });
  } catch (error) {
    console.log(error);
  }

  return transformed;
}

export function generateBookingData(data: any) {
  const {
    locationId,
    appointments,
    type = 'single',
    providerId,
    serviceRequestId,
    corporateEntityId,
    businessLineId,
    clientId,
    times,
    gender = 'b',
  } = data || {};

  const apiAppointments = appointments.map((appointment: any) => {
    const { length, service } = appointment;

    return {
      length,
      therapist_gender_requested: gender,
      service,
      therapist: providerId ? { id: providerId } : null,
    };
  });

  const bookingData: any = {
    booking: {
      appointments: apiAppointments,
      location: {
        id: locationId,
      },
      category: type,
      [type]: 1,
    },
  };

  // adding times
  if (times) {
    bookingData.booking['request_times'] = times;
  }

  // adding service request id if passed
  if (serviceRequestId) {
    bookingData.booking['service_request_id'] = serviceRequestId;
  }

  if (corporateEntityId) {
    bookingData.booking['corporate_entity_id'] = corporateEntityId;
  }

  if (businessLineId) {
    bookingData.booking['business_line_id'] = businessLineId;
  }

  if (clientId) {
    bookingData.booking['client'] = {
      id: clientId,
    };
  }

  return bookingData;
}
