import React, { useState, useEffect } from 'react';
import { Patient } from 'generated/graphql';
import PatientInformation from './PatientInformationGraphQL';
// import CaregiverInformation from './CaregiverInformation';
import { PatientFormProps } from './PatientFormGraphQL.types';

export default function PatientForm({ patient: propsPatient, editable, onSavePatient }: PatientFormProps) {
  const [patient, setPatient] = useState(propsPatient);
  const [editingPatient, setEditingPatient] = useState(false);
  // const [editCaregiver, setEditCaregiver] = useState(false);

  useEffect(() => {
    setPatient(propsPatient);
    setEditingPatient(false);
    // setEditCaregiver(false);
  }, [propsPatient]);

  const handleSavePatient = (updatedPatient?: Patient) => {
    setEditingPatient(false);
    if (updatedPatient) {
      setPatient(updatedPatient);
      onSavePatient?.();
    }
  };

  // TODO: Implement Edit Caregiver
  // const handleSaveCaregiver = (updatedPatient?: Patient) => {
  //   setEditCaregiver(false);
  //   if (updatedPatient) {
  //     setPatient(updatedPatient);
  //   }
  // };

  return (
    <>
      <PatientInformation
        patient={patient}
        editing={editable && editingPatient}
        onEdit={() => setEditingPatient(true)}
        onUndo={() => setEditingPatient(false)}
        onError={() => setEditingPatient(true)}
        onSave={handleSavePatient}
      />
      {/* <CaregiverInformation
        patient={patient}
        // edit={editable && editCaregiver}
        // onEdit={() => setEditCaregiver(true)}
        // onUndo={() => setEditCaregiver(false)}
        // onError={() => setEditCaregiver(true)}
        // onSave={handleSaveCaregiver}
      /> */}
    </>
  );
}
