import React, { useEffect, Fragment } from 'react';
import moment from 'moment';
import { MedicalApi } from 'apis';
import { PatientType } from 'apis/medical';
import { useState, useForm, useUIStore } from 'utils/hooks';
import PatientForm from 'components/forms/Patient';
import Modal, { ModalPropType } from '../templates/Modal';
import Grid from '@mui/material/Grid';
import { TextField, ToggleSection, ToggleSections, ServiceRequestFiles } from 'components';
import styles from './style.module.scss';

export type AppointmentMedicalReviewProps = ModalPropType & {
  appointmentId: any;
};

export default function AppointmentMedicalReview({
  appointmentId,
  onClose,
  scrollTop,
  modalProps,
}: AppointmentMedicalReviewProps) {
  const { openAlert } = useUIStore();
  const { bind, form } = useForm();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [patient, setPatient] = useState<PatientType | null>();
  const [notes, setNotes] = useState<any>();
  const [appointmentData, setAppointmentData] = useState<any>();

  useEffect(() => {
    async function fetchData() {
      // fetch notes
      let appointment = null;
      try {
        appointment = await MedicalApi.fetchOGAppointment(appointmentId as string);
        if (!appointment) {
          throw new Error();
        }
        setAppointmentData(appointment);
      } catch (e) {
        setError('Failed to fetch OG appointment.');
        setLoading(false);
        return;
      }

      if (appointment?.procedure_id) {
        try {
          const procedure = await MedicalApi.fetchProcedure(appointment.procedure_id as string);
          const patientId = (procedure?.subject?.reference || '').split('/')?.[1];
          if (patientId) {
            const p = await MedicalApi.fetchPatient(patientId);
            setPatient(p);
          }
          if (procedure && procedure.id) {
            const n = await MedicalApi.fetchProcedureNotes(procedure.id);
            setNotes(n);
          }
        } catch (e) {
          // setError('An error occured while fetching the soap notes')
        }
      } else {
        setError('No procedure ID in OG record.');
      }

      setLoading(false);
    }
    fetchData();
  }, [appointmentId]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (values: any) => {
    const { reviewNotes } = values;

    try {
      await MedicalApi.priorityReviewProcedure(appointmentData.procedure_id as string, reviewNotes);

      // allow to fail silently for now
      try {
        await MedicalApi.setOGAppointmentNotesAsReviewed(appointmentData.id);
      } catch (e) {
        console.error(e);
      }

      openAlert({ title: `Appointment Reviewed Successfully` });
      onClose?.(true);
    } catch (e) {
      setError('An error occured while reviewing the appointment');
      scrollTop();
      console.error(e);
    }
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <Modal
      {...modalProps}
      loading={loading}
      alert={error}
      title='Medical Review'
      description={<>This appointment was flagged for priority medical review by a provider.</>}
      className={styles.base}
      footerClassName={styles.footer}
      backdropClose={false}
      onSubmit={form.handleSubmit(onSubmit)}
      actions={[{ label: 'Save & Mark as Reviewed', action: 'submit' }]}>
      <Grid className={styles.section} container spacing={3}>
        <ToggleSection title="Provider's SOAP Notes from this appointment" disabled={!notes || loading}>
          <div className={styles.soapNotesContainer}>
            <ToggleSections
              sections={notes?.map((note: any) => {
                const { id, date_submitted, meta, notes: _notes } = note;
                return {
                  id,
                  icon: 'notes',
                  title: moment(date_submitted || meta?.lastUpdated).format('LLL'),
                  level: 1,
                  borders: true,
                  children: (
                    <div className={styles.noteContent}>
                      {_notes?.map((n: any) => {
                        const { title, text } = n;
                        return (
                          <Fragment key={title}>
                            <p className={styles.noteTitle}>{title}</p>
                            <p className={styles.noteDescription}>{text}</p>
                          </Fragment>
                        );
                      })}
                    </div>
                  ),
                };
              })}
            />
          </div>
        </ToggleSection>
      </Grid>

      <Grid className={styles.section} container spacing={3}>
        <ToggleSection title='Additional Information' disabled={loading || !patient}>
          {patient && <PatientForm patient={patient} marginBottom />}
          {appointmentData?.service_request_id && (
            <ServiceRequestFiles serviceRequestId={appointmentData.service_request_id} condensed />
          )}
        </ToggleSection>
      </Grid>

      <Grid className={styles.section} container spacing={3}>
        <p className={styles.sectionTitle}>Medical Review Notes</p>
        <TextField {...bind('reviewNotes', { required: true })} multiline {...fieldProps} />
      </Grid>
    </Modal>
  );
}
