import React from 'react';
import Modal, { ModalPropType } from '../templates/Modal';
import styles from './style.module.scss';

export type InfoProps = ModalPropType & {
  title: any;
  description?: any;
  closable?: boolean;
  modalProps?: any;
  okButtonState?: string;
  cancelButtonState?: string;
};

export default function Confirm({
  title,
  description,
  closable = true,
  okButtonState,
  cancelButtonState = 'danger',
  modalProps,
}: InfoProps) {
  return (
    <Modal
      {...modalProps}
      title={title}
      description={description}
      closable={closable}
      headerClassName={styles.header}
      footerClassName={styles.footer}
      actions={[
        { label: 'No', state: cancelButtonState, action: 'close' },
        { label: 'Yes', action: 'confirm', state: okButtonState, main: true },
      ]}
    />
  );
}
