import React from 'react';
import { Layout } from 'components';
// import styles from './style.module.scss';

export default function Home() {
  return (
    <Layout title='Home' margin={true}>
      <p>Coming soon</p>
    </Layout>
  );
}
