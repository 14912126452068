import React, { FC, Fragment } from 'react';
import cn from 'classnames';
import Icon from '../Icon';
import Divider from '@mui/material/Divider';
import { MenuListPropType } from './MenuList.types';
import styles from './style.module.scss';

const MenuList: FC<MenuListPropType> = ({ minWidth, items, onItemClick }) => {
  return (
    <ul className={styles.list} style={minWidth ? { minWidth } : {}}>
      {items?.map((item, index) => {
        const { icon: itemIcon, iconProps: itemIconProps, severity, newSection, label, onClick: itemOnClick } = item;
        return (
          <Fragment key={index}>
            {newSection && <Divider />}
            <li
              key={label}
              onClick={() => {
                onItemClick?.(item);
                itemOnClick?.();
              }}
              className={cn({ [styles[`menuItem--${severity}`]]: styles[`menuItem--${severity}`] })}>
              {itemIcon && (
                <Icon
                  name={itemIcon}
                  color='black'
                  size={22}
                  {...(itemIconProps || {})}
                  className={cn(styles.itemIcon, itemIconProps?.className)}
                />
              )}
              {label}
            </li>
          </Fragment>
        );
      })}
    </ul>
  );
};

export default MenuList;
