import { createUseStyles } from 'react-jss';
import { grey1, midnight, dustyBlue, error, yellowDark } from '@zeel-dev/ui-styles';

export const useStyles = createUseStyles({
  personaCardWrapper: {
    width: '100%',
    padding: '16px',
    background: grey1,
    display: 'flex',
    gap: '8px',
    position: 'relative',
  },
  border: {
    boxShadow: `4px 0 0 0 ${dustyBlue} inset`,
  },
  error: {
    boxShadow: `4px 0 0 0 ${error} inset`,
  },
  cardSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  addressSection: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  personaCardName: {
    fontSize: '14px',
    lineHeight: '24px',
    color: midnight,
    fontFamily: 'HKGrotesk-Bold',
  },
  personaText: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 500,
    fontFamily: 'HKGrotesk-Medium',
    color: midnight,
  },
  personaNpiText: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 500,
    color: midnight,
    textDecoration: 'underline',
  },
  officeLocationRadio: {
    display: 'flex',
    alignItems: 'flex-start !important',
    '& .MuiRadio-root': {
      padding: '0',
      marginRight: '8px',
    },
    '& .MuiFormControlLabel-label': {
      width: '100%',
    },
  },
  officeLocationLabel: {
    display: 'flex',
    gap: 4,
    fontSize: '14px',
    lineHeight: '24px',
    fontFamily: 'HKGrotesk-Medium',
  },
  officeLocation: {
    flex: 1,
    textDecoration: 'underline',
  },
  officeStatus: {
    flex: 1,
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    height: 'fit-content',
  },
  removeIconWrapper: {
    position: 'absolute',
    right: '6px',
    top: '10px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    '&:hover, &:focus, &:active': {
      background: 'none',
      border: 'none',
      outline: 'none',
    },
  },
  statusMessage: {
    marginTop: '8px',
    fontSize: '14px',
    lineHeight: '24px',
    fontFamily: 'HKGrotesk-Medium',
  },
  closedMessage: {
    color: error,
  },
  pausedMessage: {
    color: yellowDark,
  },
});
