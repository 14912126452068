import React, { useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { useState, useZccService } from 'utils/hooks';
import { ZCCApi } from 'apis';
import { ZCCSortByParameters, ZccVAFacilityType } from 'apis/zcc';
import { SectionLayout, Table, FilterButtonV3 } from 'components';
import FacilitySidePanel from 'components/sidePanels/Facility';
import styles from './style.module.scss';
import { formatPhoneNumber } from 'utils/helper';

enum FacilityColumn {
  VA_FACILITY_ID = 'vamc_facility_id',
  VA_FACILITY_NAME = 'vamc_name',
  LOCATION = 'address',
  INSURANCE_IDENTIFIER = 'insurer',
  PHONE = 'phone',
  ACTION = 'action',
}

const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export default function VAFacility() {
  const service = useZccService<ZccVAFacilityType>(ZCCApi.fetchVAFacilityItems, {
    initialParameters: {
      sortBy: {
        vamc_name: 'asc',
      },
      filters: {
        status: 'ACTIVE',
      },
    },
  });

  const [open, setOpen] = useState<boolean>(false);
  const [activeRowData, setActiveRowData] = useState<any>(null);
  const [highlightedRowId, setHighlightedRowId] = useState<string | null | undefined>();

  const rowClicked = (row: any) => {
    const { id } = row || {};
    if (open && activeRowData && id === activeRowData?.id) setOpen(false);
    else {
      setOpen(true);
      setActiveRowData(row);
    }
  };

  const sortChangeHandler = (sortModel: any) => {
    const sortBy: ZCCSortByParameters = {};
    sortModel?.forEach(({ field, sort }: any) => {
      sortBy[field] = sort;
    });
    if (!isEqual(service.parameters.sortBy, sortBy)) {
      service.setSortBy(sortBy);
    }
  };

  const rows = useMemo(() => {
    const r = [
      ...(service.data || []).map((item: ZccVAFacilityType, index: number) => ({
        id: index,
        ...item,
      })),
    ];
    return r;
  }, [service.data]);

  const columns = useMemo(
    () => [
      {
        id: FacilityColumn.VA_FACILITY_ID,
        label: 'Facility ID',
        width: 150,
        sortable: false,
      },
      { id: FacilityColumn.VA_FACILITY_NAME, label: 'Facility Name', width: 350 },
      { id: FacilityColumn.LOCATION, label: 'Location', width: 350, sortable: false },
      { id: FacilityColumn.INSURANCE_IDENTIFIER, label: 'Insurance Identifier', width: 200, sortable: false },
      {
        id: FacilityColumn.PHONE,
        label: 'Phone',
        width: 120,
        sortable: false,
        renderCell: ({ value }: any) => {
          return value && <span>{formatPhoneNumber(value)}</span>;
        },
      },
    ],
    []
  );

  const highlightRow = (rowId: string) => {
    setHighlightedRowId(rowId);
    setTimeout(() => setHighlightedRowId(null), 5000);
  };

  const sideContent = (
    <FacilitySidePanel
      open={open}
      onClose={() => setOpen(false)}
      id={activeRowData?.ng_location_id}
      onChange={() => {
        highlightRow(activeRowData?.id);
        service.refresh();
      }}
    />
  );

  return (
    <SectionLayout alert={service.error} title='VA Facilities' sideContent={sideContent}>
      <div className={styles.tableHeader}>
        <FilterButtonV3
          initialValue={service.parameters.filters}
          onChange={(newFilters: any) => {
            service.setFilters(newFilters);
          }}
          searches={[]}
          filters={[
            {
              id: 'vamc_name',
              label: 'Search for a Facility',
              placeholder: 'Facility Name',
              type: 'auto-complete-search',
              size: 'full',
              fetchItems: ZCCApi.searchVAFacilityName,
            },
            {
              id: 'vamc_state',
              label: 'Filter by State',
              placeholder: 'Search State',
              type: 'auto-complete',
              size: 'full',
              options: states.map((state) => ({ label: state, value: state })),
            },
            {
              id: 'status',
              label: 'Status',
              type: 'select',
              size: 'full',
              options: [
                { label: 'ACTIVE', value: 'ACTIVE' },
                { label: 'INACTIVE', value: 'INACTIVE' },
              ],
            },
          ]}
        />
      </div>
      <Table
        className={styles.table}
        loading={service.loading}
        name={`${rows.length} Facilities`}
        tableCellClassName={styles.tableCellClassName}
        columns={columns}
        rows={rows}
        rowHeight={76}
        onRowClick={(row: any) => rowClicked(row)}
        activeRowId={open ? activeRowData?.id : undefined}
        highlightedRowId={highlightedRowId}
        page={service.parameters.page}
        pageSize={service.parameters.pageSize}
        serverPagination
        serverRowCount={service.total}
        onPageChange={(page: any) => service.setPage(page)}
        onPageSizeChange={(pageSize: any) => service.setPageSize(pageSize)}
        rowsPerPageOptions={[100]}
        sortModel={service.sortModel}
        onSortModelChange={sortChangeHandler}
        rowClassName={styles.row}
      />
    </SectionLayout>
  );
}
