import React from 'react';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import styles from './style.module.scss';
import { ReadOnlySoapNotesHeaderProps } from './ReadOnlySoapNotesModal.types';
import { joinString } from 'utils/helper';

export default function ReadOnlySoapNotesHeader({
  quantity,
  submittedDate,
  appointment,
  patient,
}: ReadOnlySoapNotesHeaderProps) {
  return (
    <>
      <>
        <Grid item xs={12} style={{ marginBottom: '16px' }}>
          <p className={styles.title}>SOAP Note</p>
          {quantity && (
            <p className={styles.headerContent}>
              Session {appointment?.sessionNumber || ''}/{quantity.value} in referral
            </p>
          )}
          {appointment?.date && (
            <p className={styles.headerContent}>Appointment date {moment(appointment.date).format('LL')}</p>
          )}
          <p className={styles.headerContent}>
            Originally submitted on {moment(submittedDate).format('LL')}
            {appointment.practitioner && (
              <>
                {' '}
                by{' '}
                <span className={styles.emphasis}>
                  {joinString([...appointment.practitioner.name[0].given, appointment.practitioner.name[0].family])}
                </span>{' '}
                {appointment.practitioner.npi ? `(NPI ${appointment.practitioner.npi})` : ''}
              </>
            )}
          </p>
          {patient && (
            <p className={styles.headerContent}>
              <span>
                For patient{' '}
                <span className={styles.emphasis}>
                  {joinString([...(patient?.given_names || []), patient?.family_name])}
                </span>
              </span>
            </p>
          )}
        </Grid>
      </>
    </>
  );
}
