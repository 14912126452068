import { FC, useState, useMemo, useCallback } from 'react';
import { RequestItemProps } from './BookAppointment.types';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import styles from './style.module.scss';
import { OGBookingDate } from 'apis/medical';
import {
  Group,
  Chip,
  GarbageTrashDeleteBoldIcon,
  CalendarAppointmentAddIcon,
  ClockTimeIcon,
  EditPencilBoldIcon,
  CalendarIcon,
  BookingCalendar,
  BookingDayList,
} from '@zeel-dev/zeel-ui';
import cn from 'classnames';

const RequestItem: FC<RequestItemProps> = ({
  editing,
  request,
  deletable,
  onDelete,
  onEdit,
  onChange,
  bookingDates,
  highlightedDates,
}) => {
  const type = request?.type || 'range';
  const date = request?.date || null;
  const times = request?.times || [];
  const [showMonthView, setShowMonthView] = useState<boolean>(false);

  const bookingDatesList = useMemo(() => {
    return (bookingDates || []).map((d: OGBookingDate) => new Date(d.date));
  }, [bookingDates]);

  const onDateSelected = useCallback(
    (d: Date) => {
      onChange?.({ ...request, date: d, times: [] });
    },
    [request]
  );

  const addTime = useCallback(
    (label: string, startId: number, endId: number, range?: 'morning' | 'afternoon' | 'evening') => {
      const existingTimeEntryIndex = request?.times?.findIndex(
        (t) => (t.start === startId && t.end === endId) || (range && t.range === range)
      );
      const updatedTimes = [...(request.times || [])];
      if (existingTimeEntryIndex > -1) {
        updatedTimes.splice(existingTimeEntryIndex, 1);
      } else {
        updatedTimes.push({ label, start: startId, end: endId, range });
      }

      onChange?.({
        ...request,
        times: updatedTimes,
      });
    },
    [request?.times]
  );

  const findCurrentBookingDateObject = useCallback(
    (dateObj: Date) => {
      return bookingDates?.find((d: OGBookingDate) => isSameDay(new Date(d.date), dateObj));
    },
    [bookingDates]
  );

  const availableTimes = useMemo(() => {
    if (!request?.date) return [];
    const currentBookingDateObject = findCurrentBookingDateObject(request.date);
    if (!currentBookingDateObject) return [];

    const allTimes: Array<{ hours: number; minutes: number; label: string; id: number }> = [];
    (currentBookingDateObject.hours || []).forEach((h) => {
      (h.minutes || []).forEach((m) => {
        const [hour, minute] = m.date
          .split(' ')[1]
          .split(':')
          .map((n) => parseInt(n));
        allTimes.push({
          hours: m.end_hour_full_hour,
          minutes: m.end_minute,
          label: `${(hour > 12 ? hour - 12 : hour).toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}${
            hour < 12 ? 'am' : 'pm'
          }`,
          id: m.id,
        });
      });
    });
    return allTimes;
  }, [bookingDates, request?.date]);

  const availableRanges = useMemo(() => {
    if (!request?.date) return [];
    const currentBookingDateObject = findCurrentBookingDateObject(request.date);
    if (!currentBookingDateObject) return [];

    const firstAvailabilityObject = currentBookingDateObject.hours?.[0];
    const firstHour = firstAvailabilityObject?.full_hour;
    const firstId = firstAvailabilityObject?.minutes?.[0]?.id;

    const allRanges: Array<any> = [];

    let minHit = false;
    if (firstHour < 12) {
      allRanges.push({
        label: 'Morning (8am-noon)',
        range: 'morning',
        startId: firstId,
        endId: currentBookingDateObject.hours?.find((h) => h.full_hour === 12)?.minutes?.[0]?.id,
      });
      minHit = true;
    }
    if (firstHour < 17) {
      allRanges.push({
        label: 'Afternoon (noon-5pm)',
        range: 'afternoon',
        startId: minHit ? currentBookingDateObject.hours?.find((h) => h.full_hour === 12)?.minutes?.[0]?.id : firstId,
        endId: currentBookingDateObject.hours?.find((h) => h.full_hour === 17)?.minutes?.[0]?.id,
      });
    }

    const lastHour = currentBookingDateObject.hours?.[currentBookingDateObject.hours?.length - 1];
    const lastMinute = lastHour?.minutes?.[lastHour?.minutes?.length - 1]?.id;
    allRanges.push({
      label: 'Evening (5pm-10:30pm)',
      range: 'evening',
      startId: minHit ? currentBookingDateObject.hours?.find((h) => h.full_hour === 17)?.minutes?.[0]?.id : firstId,
      endId: lastMinute,
    });

    return allRanges;
  }, [bookingDates, request?.date]);

  const selectedDateLabel = useMemo(() => {
    if (!request.date) {
      return '';
    }
    let dateLabel = format(request.date, 'EEEE MMMM d');
    if (isToday(request.date)) dateLabel = `Today, ${dateLabel}`;
    if (isTomorrow(request.date)) dateLabel = `Tomorrow, ${dateLabel}`;
    return dateLabel;
  }, [request?.date]);

  return (
    <div className={styles.requestItemContainer}>
      {editing && (
        <>
          {!date && (
            <>
              <div className={styles.sectionHeader}>
                <p className={styles.sectionTitle}>Select {!deletable ? 'a Day' : 'Another Day'}</p>
                <p className={styles.sectionSubtitle}>More lead time may improve availability.</p>
                <CalendarAppointmentAddIcon size={24} />
              </div>

              <div className={styles.datePickerWrapper}>
                <button
                  type='button'
                  className={cn(styles.calendarSwitch)}
                  onClick={() => setShowMonthView((prev) => !prev)}>
                  {showMonthView ? 'Week View' : 'Month View'}
                  <CalendarIcon size={24} />
                </button>
                {showMonthView && (
                  <BookingCalendar
                    className={styles.monthlyPicker}
                    disablePast
                    maxDate={bookingDatesList[bookingDatesList.length - 1]}
                    highlightedDates={highlightedDates}
                    disableHighlightedDates
                    value={request.date ? new Date(request.date) : undefined}
                    onChange={(d) => onDateSelected(d)}
                    fullWidth
                  />
                )}
                {!showMonthView && (
                  <BookingDayList
                    availableDates={bookingDatesList}
                    value={request.date ? new Date(request.date) : undefined}
                    onChange={(d) => onDateSelected(d)}
                    highlightedDates={highlightedDates}
                    disableHighlightedDates
                  />
                )}
              </div>
            </>
          )}
          {date && (
            <>
              <p className={styles.dateTitle}>{selectedDateLabel}</p>
              {type === 'range' && (
                <>
                  <div className={styles.sectionHeader}>
                    <p className={styles.sectionTitle}>Select a Time of Day</p>
                    <p className={styles.sectionSubtitle}>
                      Multiple time windows may help improve chances of getting booked.
                    </p>
                    <ClockTimeIcon size={24} />
                  </div>
                  <Group className={styles.chipsGroup} gutter wrap>
                    {availableRanges.map((d) => {
                      return (
                        <Chip
                          key={d.range}
                          label={d.label}
                          selected={!!times?.find((t) => t.range === d.range)}
                          onClick={() => addTime(d.label, d.startId, d.endId, d.range)}
                        />
                      );
                    })}
                  </Group>
                </>
              )}
              {type === 'specific' && (
                <>
                  <div className={styles.sectionHeader}>
                    <p className={styles.sectionTitle}>Specific Times (Select All That Apply)</p>{' '}
                    <p className={styles.sectionSubtitle}>Multiple times may help improve chances of getting booked.</p>
                    <ClockTimeIcon size={24} />
                  </div>
                  <Group className={styles.chipsGroup} gutter wrap>
                    {availableTimes.map((d) => {
                      return (
                        <Chip
                          key={d.id}
                          className={styles.specificChip}
                          label={d.label}
                          selected={!!times?.find((t) => t.start === d.id && t.end === d.id)}
                          onClick={() => addTime(d.label, d.id, d.id)}
                        />
                      );
                    })}
                  </Group>
                </>
              )}
              <div className={styles.bottomLinkcontainer}>
                or,{' '}
                <a
                  className={styles.timeTypeLink}
                  onClick={() => onChange?.({ ...request, type: type === 'range' ? 'specific' : 'range', times: [] })}>
                  {type !== 'range' ? 'Choose a Time of Day' : 'Choose Specific Times'}
                </a>
              </div>
            </>
          )}
          {}
        </>
      )}
      {!editing && (
        <>
          <p className={styles.dateTitle}>{selectedDateLabel}</p>
          {(!request?.times || request?.times?.length === 0) && (
            <p className={styles.timesSelectedLabel}>No Times selected</p>
          )}
          {request?.times?.length > 0 && (
            <p className={styles.timesSelectedLabel}>{request.times.map((t) => t.label).join(', ')}</p>
          )}
        </>
      )}

      <div className={styles.iconsContainer}>
        {!editing && (
          <EditPencilBoldIcon
            size={24}
            onClick={() => {
              onEdit?.();
            }}
          />
        )}
        {deletable && (
          <GarbageTrashDeleteBoldIcon
            size={24}
            onClick={() => {
              onDelete?.();
            }}
          />
        )}
      </div>
      <div className={styles.breakline} />
    </div>
  );
};

export default RequestItem;
