import React, { ReactElement } from 'react';
import { useStyles } from './SearchResults.styles';
import { LoadingIcon } from '@zeel-dev/ui-icons';
import { grey6 } from '@zeel-dev/ui-styles';
import { SearchResultsProps } from './SearchField.types';

export default function SearchResults(props: SearchResultsProps): ReactElement {
  const classes = useStyles();

  return (
    <>
      <ul className={classes.wrapper} tabIndex={-1}>
        {props.searching ? (
          <li className={classes.searching}>
            <LoadingIcon spin color={grey6} /> Searching
          </li>
        ) : props.items.length > 0 ? (
          props.items.map((item, index: number) => (
            <li
              className={classes.listItem}
              key={`${item.id}-${index}`}
              onClick={() => props.onSelectItem(item)}
              tabIndex={0}
              aria-label={'provider list item'}>
              {item.value}
            </li>
          ))
        ) : (
          <li className={classes.noResults}>(No Results)</li>
        )}
      </ul>
    </>
  );
}
