import React from 'react';
import { useState, useEffect, useCallback, useMemo, useUIStore, useCombineFiles, useAuth } from 'utils/hooks';
import { MedicalApi, PractitionerApi, ZCCApi, TemplateApi, ZrefApi } from 'apis';
import { ServiceRequestForm } from 'components/forms';
import {
  Accordion,
  SidePanel,
  FieldTitle,
  ServiceRequestFiles,
  Button,
  ServiceRequestList,
  Icon,
  FilesContainerV2,
} from 'components';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { PractitionerForm, AppointmentsForm } from 'components/forms/Practitioner';
import PatientForm from 'components/forms/Patient';
import styles from './style.module.scss';
import { OfficeLocationForms } from 'components/forms/OfficeLocationForm';
import capitalize from 'lodash/capitalize';
import {
  PatientType,
  FacilityType,
  PatientHoldStatus,
  PatientHoldKeys,
  AppointmentType,
  DocumentType,
} from 'apis/medical';

import EvaluationFiles from 'components/EvaluationFiles';
import { MainNote, ActionTakenNotes } from 'components/forms/Notes';
import { ZccNote } from 'apis/zcc';
import { joinString } from 'utils/helper';
import moment from 'moment';
import FacilityV2Form from 'components/forms/FacilityV2';
import { PractitionerType } from 'apis/practitioner';
import { RequestStatusType } from 'apis/zref';
import ClinicalRationaleForExtensionForm from 'components/forms/ServiceRequest/ClinicalRationaleForExtensionForm';

export type ServiceRequestSidePanelPropType = {
  open?: boolean;
  onChange?: () => void;
  onClose?: () => void;
  id: string; // the service request id,
};

const documentTypes = [
  { key: 'evaluation', label: 'Evaluation (Qualtrics)' },
  { key: 'medical-document', label: 'Medical Documents' },
  { key: 'rfs', label: 'RFS Document' },
];

export default function ServiceRequest({ open, onClose, onChange, id }: ServiceRequestSidePanelPropType) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>();
  const [serviceRequest, setServiceRequest] = useState<any>();
  const [patient, setPatient] = useState<PatientType | null>();
  const [facility, setFacility] = useState<FacilityType | null>();
  const [practitionerFromNG, setPractitionerFromNG] = useState<PractitionerType | null>();
  const [appointments, setAppointments] = useState<AppointmentType[] | null>();
  const [zccReferral, setZCCReferral] = useState<{
    mainNote?: ZccNote;
    actionNote?: ZccNote[];
    rfsSubmitted?: boolean;
    assignedProvider?: string;
  } | null>();
  const [rfsDocuments, setRfsDocuments] = useState<DocumentType[] | undefined>();
  const [approvedSoapNotesDocuments, setApprovedSoapNotesDocuments] = useState<DocumentType[]>([]);
  const { openModal, openAlert } = useUIStore();
  const { user } = useAuth();

  const fetchData = useCallback(async () => {
    setServiceRequest(undefined);
    setPatient(undefined);
    setPractitionerFromNG(undefined);
    setAppointments(undefined);

    setError(null);
    setLoading(true);
    try {
      const [sr, _zccReferral, appts] = await Promise.all([
        MedicalApi.fetchServiceRequest(id),
        ZCCApi.fetchZccReferral(id),
        MedicalApi.fetchAppointmentsForServiceRequest(id),
      ]);
      const fetchPromise = [];
      if (sr?.patient_id) {
        fetchPromise.push(
          (async () => {
            const p = await MedicalApi.fetchPatient(sr.patient_id);
            setPatient(p);
          })()
        );
      } else {
        setPatient(null);
      }
      if (sr?.va_facility_id) {
        fetchPromise.push(
          (async () => {
            const f = await MedicalApi.fetchFacility(sr?.va_facility_id || '');
            setFacility(f);
          })()
        );
      } else {
        setFacility(null);
      }
      if (sr?.assigned_provider) {
        fetchPromise.push(
          (async () => {
            const _practitioner = await PractitionerApi.fetchPractitionerByZeelId(sr?.assigned_provider || '');
            setPractitionerFromNG(_practitioner);
          })()
        );
      } else {
        setPractitionerFromNG(undefined);
      }
      if (sr?.referral_number) {
        fetchPromise.push(
          (async () => {
            const requests = await ZrefApi.fetchRequestsByReferralId(sr.referral_number || '');
            setRfsDocuments(
              requests
                .filter((request) => request.status === RequestStatusType.Accepted)
                .map(
                  (request) =>
                    ({
                      id: request.request_id,
                      meta: {
                        fileName: `RFS_${request.initiating_referral_number}`,
                        mimeType: 'application/pdf',
                      },
                      displayName: 'RFS',
                      rightDescription: 'Approved',
                    } as DocumentType)
                )
            );
          })()
        );
        fetchPromise.push(
          (async () => {
            const soapNotes = await MedicalApi.fetchApprovedAndAmendedSoapNotes(sr.referral_number || '');
            setApprovedSoapNotesDocuments(
              soapNotes.map((soapNote) => ({
                id: soapNote.id,
                meta: {
                  fileName: `SOAP Note ${moment(soapNote.date_authored || soapNote.date_submitted).format(
                    'YYYY-MM-DD'
                  )}`,
                  fileSize: 0,
                  mimeType: 'application/pdf',
                },
                rightDescription: capitalize(soapNote.status),
              }))
            );
          })()
        );
      }
      await Promise.all(fetchPromise);
      setServiceRequest(sr);
      setAppointments(appts);
      setZCCReferral(_zccReferral);
    } catch (e) {
      setError('An error occurred while fetching the service request');
      console.error(e);
      setServiceRequest(null);
      setAppointments(null);
      setPatient(null);
      setFacility(null);
      setZCCReferral(null);
    }
    setLoading(false);
  }, [id]);

  const { openCombineFiles } = useCombineFiles({
    serviceRequest,
    patient,
    onError: (e) => {
      openAlert({ title: e, severity: 'error' });
    },
    downloadOnSuccess: true,
  });

  // fetch data on mount
  useEffect(() => {
    if (id) fetchData();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const close = () => {
    if (onClose) {
      onClose();
    }
  };

  const openUploadFilesModal = () => {
    openModal({
      id: 'contextual-document-upload',
      props: {
        documentTypes,
        contextualUploadHandler: async (data: any) => {
          try {
            const flattenedDocs = Object.values(data).flat(1);

            // uploading files
            const supportingDocuments: Array<{ description?: string; type?: string; url: string }> = [];
            flattenedDocs?.forEach((file: any) => {
              const { url, fileName, documentType } = file;
              supportingDocuments.push({
                description: documentTypes.find(({ key }) => key === documentType)?.label || fileName,
                url,
                type: documentType,
              });
            });

            await MedicalApi.addServiceRequestFiles(serviceRequest?.id, supportingDocuments);
          } catch (e) {
            throw new Error('Failed to save the uploaded evaluation files in the service-request.');
          }
        },
      },
      callback: (res: any) => {
        if (res) {
          fetchData();
        }
      },
    });
  };

  const openUploadConsentModal = (patientId: string, serviceRequestId: string) => {
    openModal({
      id: 'upload-consent',
      props: {
        patientId,
        serviceRequestId,
      },
      callback: (res: any) => {
        if (res) {
          fetchData();
        }
      },
    });
  };

  const openPatient = (patientId: string) => {
    openModal({
      id: 'patient',
      props: {
        patientId,
      },
      callback: () => {
        fetchData();
      },
    });
  };

  const revokeReferral = () => {
    openModal({
      id: 'revoke-referral',
      props: {
        serviceRequestId: id,
      },
      callback: () => {
        fetchData();
      },
    });
  };

  const openSwitchProviderOffice = () => {
    openModal({
      id: 'switch-provider-office',
      props: {
        serviceRequest,
        practitioner: practitionerFromNG,
      },
      callback: (res) => {
        if (res) fetchData();
      },
    });
  };

  const openAssignNewInOfficeProvider = () => {
    openModal({
      id: 'assign-new-in-office-provider',
      props: {
        serviceRequest,
        providerNPI: practitionerFromNG?.npi,
      },
      callback: (res) => {
        if (res) fetchData();
      },
    });
  };

  const openSwitchToInHomeReferral = () => {
    openModal({
      id: 'switch-to-in-home-referral',
      props: {
        serviceRequestId: serviceRequest.id,
      },
      callback: (res) => {
        if (res) {
          openModal({
            id: 'book-appointment-for-patient',
            props: {
              memberId: patient?.member_id,
              bookingFlowUrl: (OGMember: any) => {
                return `${process.env.REACT_APP_VA_FLOW_BOOK_URL}?fromZcc=1&email=${encodeURIComponent(
                  OGMember?.email
                )}`;
              },
            },
          });
        }
        fetchData();
      },
    });
  };

  const openBookNewAppointmentModal = useCallback(() => {
    openModal({
      id: 'book-appointment',
      props: {
        serviceRequestId: serviceRequest?.id,
        ogMemberId: patient?.member_id,
      },
      callback: (res: boolean) => {
        if (res) fetchData();
      },
    });
  }, [serviceRequest, patient, fetchData]);

  const openSubmitRFSModal = useCallback(() => {
    openModal({
      id: 'submit-rfs',
      props: {
        serviceRequestId: serviceRequest?.id || '',
        signatoryName: zccReferral?.assignedProvider || '',
      },
      callback: (res: boolean) => {
        if (res) fetchData();
      },
    });
  }, [serviceRequest, zccReferral]);

  const serviceRequestsListProp = useMemo(() => {
    return serviceRequest ? [serviceRequest] : undefined;
  }, [serviceRequest]);

  const openPutPatientOnHoldModal = async (patientId: string, patientName: string) => {
    openModal({
      id: 'put-patient-on-hold',
      props: {
        patientId,
        patientName,
      },
      callback: (res: any) => {
        if (res) {
          putActionNoteForOnHold(res);
        }
      },
    });
  };

  const openRemovePatientHoldModal = async (patientId: string, patientName: string, holdType?: string) => {
    openModal({
      id: 'remove-patient-hold',
      props: {
        patientId,
        patientName,
        holdType,
      },
      callback: ({ shouldRemove, reason }) => {
        if (shouldRemove) {
          putActionNoteForRemovingHold(reason);
        }
      },
    });
  };

  const addActionNoteForHold = async (text: string) => {
    if (!patient?.id) return;
    const noteAction = zccReferral?.actionNote?.length ? ZCCApi.updateNote : ZCCApi.createNote;

    await noteAction({
      note: [
        {
          text,
          pcc_agent: user?.pccAgentName,
        },
        ...(zccReferral?.actionNote || []),
      ],
      noteType: 'action',
      patientId: patient?.id,
    });
  };

  const putActionNoteForOnHold = async (res: any) => {
    const { hold_status, hold_status_reason, hold_status_date } = res;

    const text = `${PatientHoldStatus[hold_status as PatientHoldKeys]} - ${
      hold_status_date ? moment(hold_status_date).format('MM/DD/YYYY') : 'indefinite'
    }${hold_status_reason ? ` - ${hold_status_reason}` : ''}`;

    try {
      const patientName = joinString([...(patient?.given_names || []), patient?.family_name]);

      await addActionNoteForHold(text);

      openAlert({
        title: `Patient on Hold${hold_status_date ? '' : ' Indefinitely'}`,
        description: `You'll need to manually resume scheduling for ${patientName}`,
      });
      await fetchData();
    } catch {
      openAlert({ title: `Failed to save the action note`, severity: 'error' });
    }
  };

  const openTextConsentFormLinkModal = useCallback(() => {
    openModal({
      id: 'text-consent-form-link',
      props: {
        memberId: patient?.member_id,
        patientName: joinString([...(patient?.given_names || []), patient?.family_name]),
        phoneNumber: patient?.phone,
      },
    });
  }, [patient]);

  const putActionNoteForRemovingHold = async (reason?: string) => {
    const text = reason ? `Patient Hold Removed - ${reason}` : 'Patient Hold Removed';

    try {
      await addActionNoteForHold(text);

      openAlert({
        title: 'Patient Hold Removed',
      });
      await fetchData();
    } catch {
      openAlert({ title: `Failed to save the action note`, severity: 'error' });
    }
  };

  const isAhfReferral = () => {
    return patient?.referring_organization === 'ahf';
  };

  return (
    <SidePanel
      title='Medical Referral Record'
      loading={loading}
      alert={error}
      open={open}
      onClose={close}
      menuItems={
        !error
          ? [
              {
                icon: 'add-user',
                label: 'Assign New In-Office Provider',
                onClick: openAssignNewInOfficeProvider,
              },
              ...(serviceRequest?.assigned_provider
                ? [
                    {
                      icon: 'house',
                      label: 'Switch To In-Home Referral',
                      onClick: openSwitchToInHomeReferral,
                    },
                  ]
                : []),
              ...(serviceRequest?.assigned_provider
                ? [
                    {
                      icon: 'building',
                      label: 'Switch Provider’s Office',
                      onClick: openSwitchProviderOffice,
                    },
                  ]
                : []),
              {
                icon: 'download',
                label: 'Download Patient Records',
                onClick: () => openCombineFiles(),
              },
              ...(patient?.member_id
                ? [
                    {
                      icon: 'calendar',
                      label: 'Book New Appointment',
                      onClick: () => openBookNewAppointmentModal(),
                    },
                  ]
                : []),
              ...(!patient?.hold_status
                ? [
                    {
                      icon: 'calendar-appointment-pause',
                      label: 'Put Patient on Hold',
                      onClick: () =>
                        openPutPatientOnHoldModal(
                          serviceRequest?.patient_id,
                          joinString([...(patient?.given_names || []), patient?.family_name])
                        ),
                    },
                  ]
                : [
                    {
                      icon: 'calendar-checkmark',
                      label: 'Remove Patient Hold',
                      onClick: () =>
                        openRemovePatientHoldModal(
                          serviceRequest?.patient_id,
                          joinString([...(patient?.given_names || []), patient?.family_name]),
                          PatientHoldStatus[patient?.hold_status as PatientHoldKeys]
                        ),
                    },
                  ]),
              ...(!zccReferral?.rfsSubmitted
                ? [
                    {
                      icon: 'notes',
                      label: 'Submit a RFS',
                      onClick: () => openSubmitRFSModal(),
                    },
                  ]
                : []),
            ]
          : []
      }>
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}>
          <MainNote key={id} note={zccReferral?.mainNote} patientId={patient?.id} id={id} onChange={onChange} />
        </Grid>
        <Grid item xs={12}>
          <ActionTakenNotes
            key={id}
            notes={zccReferral?.actionNote}
            patientId={patient?.id}
            id={id}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <ServiceRequestForm collapsible marginBottom serviceRequest={serviceRequest} showFiles={false} />
        </Grid>
      </Grid>
      {serviceRequest?.hasClinicalRationaleForExtension && (
        <ClinicalRationaleForExtensionForm serviceRequest={serviceRequest} />
      )}
      {patient && (
        <Accordion icon='pad-with-star' title='Linked Referrals' expended={false} marginBottom>
          <ServiceRequestList showTitle={false} serviceRequests={patient.service_requests || []} />
        </Accordion>
      )}
      {!isAhfReferral && <FacilityV2Form title='Referring VA Facility' facility={facility} collapsible marginBottom />}
      {appointments && appointments.length > 0 && (
        <AppointmentsForm
          appointments={appointments}
          collapsible
          marginBottom
          canBookNewAppointment
          onBookNewAppointment={() => openBookNewAppointmentModal()}
        />
      )}
      {practitionerFromNG && (
        <Accordion icon='orientation-card' title='Assigned Provider for In-Office Referral' marginBottom>
          <PractitionerForm practitioner={practitionerFromNG} />
          <OfficeLocationForms
            readOnly
            practitionerId={practitionerFromNG.id}
            officeLocations={practitionerFromNG.office_based_address}
          />
        </Accordion>
      )}
      {patient && <PatientForm patient={patient} collapsible marginBottom />}
      <ServiceRequestFiles
        title='Attached Files'
        serviceRequests={serviceRequestsListProp}
        onActionClick={openUploadFilesModal}
        condensed
        filter={(d: any) => d?.type !== 'consent'}
      />
      <ServiceRequestFiles
        title='Consent Forms'
        serviceRequests={serviceRequestsListProp}
        actionText='Upload Consent Forms'
        onActionClick={() => openUploadConsentModal(serviceRequest?.patient_id, serviceRequest?.id)}
        filter={(d: any) => d?.type === 'consent'}
        condensed
      />
      {patient?.member_id && (
        <div className={styles.textConsentFormLink}>
          <Link color='inherit' onClick={() => openTextConsentFormLinkModal()}>
            <Icon name='smartphone' />
            Text Consent Form Link to Patient
          </Link>
        </div>
      )}
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}>
          <EvaluationFiles serviceRequestId={serviceRequest?.id} />
        </Grid>
        <Grid item xs={12}>
          <FilesContainerV2
            title='SOAP Notes'
            files={approvedSoapNotesDocuments}
            onRowClick={(rowIndex: number) => {
              openModal({
                id: 'read-only-soap-notes',
                props: {
                  noteId: approvedSoapNotesDocuments[rowIndex].id,
                  status: 'approved',
                },
              });
            }}
            downloadDocument={(soapNoteId: string) => TemplateApi.combineSoapNotesAndEvaluations([soapNoteId], [])}
            condensed
          />
        </Grid>
      </Grid>
      {rfsDocuments && rfsDocuments.length > 0 && (
        <FilesContainerV2
          loading={loading}
          title='RFS'
          titleIcon='swatch'
          files={rfsDocuments}
          downloadDocument={TemplateApi.getRfsDocumentById}
          condensed
        />
      )}
      <FieldTitle margin>Actions</FieldTitle>
      <div className={styles.actions}>
        {/* eslint-disable-next-line */}
        {/* <Button variant="outlined" color="primary" size="large" onClick={() => openUploadConsentModal(serviceRequest?.patient_id, serviceRequest?.id)}>Upload Consent Forms</Button> */}
        <Button variant='outlined' color='primary' size='large' onClick={() => openPatient(serviceRequest?.patient_id)}>
          Open Patient
        </Button>
        <Button variant='contained' color='primary' size='large' onClick={() => revokeReferral()}>
          Revoke Referral
        </Button>
      </div>
    </SidePanel>
  );
}
