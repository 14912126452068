import React, { useMemo } from 'react';
import { PatientHoldStatus, PatientType } from 'apis/medical';
import Grid from '@mui/material/Grid';
import { TextField } from 'components';
import Form, { FormProps } from 'components/Form';
import moment from 'moment';

export type HoldInformationProps = FormProps & {
  patient: PatientType;
};

export default function HoldInformation({ patient, ...rest }: HoldInformationProps) {
  const fieldProps = useMemo(
    () => ({
      fullWidth: true,
      InputProps: {
        disableUnderline: true,
        readOnly: true,
      },
      InputLabelProps: {
        shrink: true,
      },
    }),
    []
  );

  return (
    <Form {...rest} title='Hold Status' icon='calendar-appointment-pause' marginBottom>
      {patient.hold_status && (
        <Grid item xs={6}>
          <TextField value={PatientHoldStatus[patient.hold_status]} label='Hold Type' {...fieldProps} />
        </Grid>
      )}
      <Grid item xs={6}>
        <TextField
          value={patient.hold_created_date ? moment(patient.hold_created_date).format('MM/DD/YYYY') : ''}
          label='Date placed on hold'
          {...fieldProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={patient.hold_status_date ? moment(patient.hold_status_date).format('MM/DD/YYYY') : 'N/A'}
          label='Date Ending'
          {...fieldProps}
        />
      </Grid>
      {patient.hold_status !== 'trust_and_safety' && (
        <Grid item xs={6}>
          <TextField value={patient.hold_status_reason} label='Hold Reason' {...fieldProps} />
        </Grid>
      )}
    </Form>
  );
}
