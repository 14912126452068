import React, { FC } from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { Formik, Field } from 'formik';
import Form from 'components/Form';
import { TextField, TextFieldProps, fieldToTextField } from 'formik-mui';
import { DatePicker } from 'formik-mui-x-date-pickers';
import { PhoneField } from 'components';
import { useDispatch } from 'utils/hooks';
import { UIStore } from 'store';
import moment from 'moment';
import * as yup from 'yup';
import 'yup-phone';
import { PatientInformationProps } from './PatientFormGraphQL.types';
import { normalizePhoneNumber } from 'utils/helper';
import { MedicalApi } from 'apis';

const validationSchema = yup.object({
  icn: yup.string().notRequired(),
  edipi: yup.string().notRequired(),
  firstName: yup.string().required('Please fill out this field.'),
  familyName: yup.string().required('Please fill out this field.'),
  dateOfBirth: yup.date().nullable().typeError('Invalid Date Format').required('Please fill out this field.'),
  ssn: yup
    .string()
    .min(9, 'Social Security Number must be 9 digits')
    .max(9, 'Social Security Number must be 9 digits')
    .required('Please fill out this field.'),
  phone: yup.string().required('Please fill out this field.').phone('US', false, 'Invalid phone number'),
  contactPreference: yup.string().notRequired(),
  phoneMobile: yup.string().test('isMobile', 'Invalid phone number', (value) => {
    if (value) return yup.string().phone('US', false, 'Invalid phone number').isValidSync(value);
    return true;
  }),
  phoneWork: yup.string().test('isMobile', 'Invalid phone number', (value) => {
    if (value) return yup.string().phone('US', false, 'Invalid phone number').isValidSync(value);
    return true;
  }),
  address1: yup.string().required('Please fill out this field.'),
  unit: yup.string().notRequired(),
  city: yup.string().required('Please fill out this field.'),
  state: yup.string().required('Please fill out this field.'),
  zip: yup.string().required('Please fill out this field.'),
  email: yup.string().email('Invalid email').notRequired(),
  gender: yup.string().notRequired(),
  vetStatus: yup.string().notRequired(),
  memberId: yup.string().notRequired(),
  removed: yup.string().required('Please fill out this field.'),
});

function FormikPhoneField(props: TextFieldProps) {
  return <PhoneField {...fieldToTextField(props)} />;
}

const PatientInformation: FC<PatientInformationProps> = ({ patient, editing, onEdit, onUndo, onSave, onError }) => {
  const dispatch = useDispatch();

  const fieldProps = {
    variant: 'standard',
    fullWidth: true,
    InputProps: { disableUnderline: !editing, readOnly: !editing },
    InputLabelProps: {
      shrink: true,
    },
  };

  const readOnlyProps = { variant: 'standard', InputProps: { disableUnderline: true, readOnly: true } };

  const updatePatient = async (values: any, setSubmitting: (submitting: boolean) => void) => {
    if (patient) {
      try {
        const rankMap: Record<string, number> = {
          any: 0,
          'phone-only': 1,
          'phone-preferred': 2,
        };
        const telecom: any[] = [
          {
            system: 'phone',
            value: values.phone,
            rank: rankMap[values.contactPreference],
          },
        ];
        if (values.email) {
          telecom.push({ system: 'email', value: values.email });
        }
        if (values.phoneMobile) {
          telecom.push({
            use: 'mobile',
            system: 'phone',
            value: values.phoneMobile,
          });
        }
        if (values.phoneWork) {
          telecom.push({ use: 'work', system: 'phone', value: values.phoneWork });
        }

        const data = {
          birth_date: moment(values.dateOfBirth).format('YYYY-MM-DD'),
          address: {
            line: [values.address1].concat(values.unit ? [values.unit] : []),
            city: values.city,
            state: values.state,
            postal_code: values.zip,
          },
          gender: values.gender,
          name: {
            family: values.familyName,
            given: values.firstName.split(' '),
          },
          patient_ssn: values.ssn,
          telecom,
          removed_from_network: values.removed === 'Yes',
        };
        await MedicalApi.updatePatient(patient?.id, data);
        dispatch(
          UIStore.openAlert({
            title: `Patient information was successfully updated`,
          })
        );
        onSave?.({
          ...patient,
          icn: values.icn,
          edipi: values.edipi,
          given_names: values.firstName.split(' '),
          family_name: values.familyName,
          birth_date: moment(values.dateOfBirth).format('YYYY-MM-DD'),
          ssn: values.ssn,
          gender: values.gender,
          address: [data.address],
          email: values.email,
          removed: values.removed,
          contactPreference: values.contactPreference,
          phone: values.phone,
          phoneWork: values.phoneWork,
          phoneMobile: values.phoneMobile,
        });
      } catch (error) {
        console.log(error);
        dispatch(UIStore.openAlert({ title: `Failed to update patient information`, severity: 'error' }));
        onError?.();
      }
    } else {
      onSave?.();
    }
    setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        icn: patient?.icn ?? '',
        edipi: patient?.edipi ?? '',
        firstName: (patient?.name?.[0]?.given || []).join(' '),
        familyName: patient?.name?.[0]?.family ?? '',
        dateOfBirth: patient?.birthDate ? moment(patient.birthDate).format('YYYY-MM-DD') : null,
        ssn: patient?.last4 ?? '',
        phone: patient?.phone ? normalizePhoneNumber(patient?.phone?.[0] || '') : '',
        // contactPreference: patient?.contactPreference ?? 'any',
        // phoneMobile: patient?.phoneMobile ? normalizePhoneNumber(patient.phoneMobile) : '',
        // phoneWork: patient?.phoneWork ? normalizePhoneNumber(patient.phoneWork) : '',
        address1: patient?.address?.[0]?.line?.[0] ?? '',
        unit: patient?.address?.[0]?.line?.[1] ?? '',
        city: patient?.address?.[0]?.city ?? '',
        state: patient?.address?.[0]?.state ?? '',
        zip: patient?.address?.[0]?.postalCode ?? '',
        email: patient?.telecom?.find((a: any) => a.system === 'email')?.value ?? '',
        gender: patient?.gender ?? '',
        vetStatus: '',
        memberId: patient?.memberId ?? '',
        removed: patient?.removedFromNetwork ? 'Yes' : 'No',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSave?.();
        updatePatient(values, setSubmitting);
      }}>
      {({ resetForm, handleSubmit, dirty }) => (
        <Form
          title='Patient Information'
          icon='orientation-card'
          editable
          editing={editing}
          marginBottom
          onEdit={() => onEdit?.()}
          onUndo={() => {
            if (dirty) {
              const confirm = window.confirm('You have unsaved changes that will be lost');
              if (confirm) {
                onUndo?.();
                resetForm();
              }
            } else {
              onUndo?.();
            }
          }}
          dirty={dirty}
          onSave={handleSubmit}>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              label='Integration Control Number (ICN)'
              name='icn'
              {...fieldProps}
              {...readOnlyProps}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              label='EDIPI number'
              name='edipi'
              {...fieldProps}
              {...readOnlyProps}
            />
          </Grid>
          <Grid item xs={6}>
            <Field component={TextField} type='text' label='First Name' name='firstName' {...fieldProps} />
          </Grid>
          <Grid item xs={6}>
            <Field component={TextField} type='text' label='Last Name' name='familyName' {...fieldProps} />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={DatePicker}
              autoOk
              format='MM/DD/yyyy'
              disableFuture
              readOnly={!editing}
              label='Date of Birth'
              name='dateOfBirth'
              maxDateMessage='Cannot be greater than today'
              {...fieldProps}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              label='SSN'
              name='ssn'
              inputProps={{ maxLength: 9 }}
              {...fieldProps}
            />
          </Grid>
          <Grid item xs={6}>
            <Field component={FormikPhoneField} type='text' label='Phone Number' name='phone' {...fieldProps} />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              select
              label='Contact Preference (Optional)'
              name='contactPreference'
              {...fieldProps}>
              <MenuItem value='any'>Any</MenuItem>
              <MenuItem value='phone-only'>Phone Only</MenuItem>
              <MenuItem value='phone-preferred'>Phone Preferred</MenuItem>
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              component={FormikPhoneField}
              type='text'
              label='Mobile Phone Number (Optional)'
              name='phoneMobile'
              {...fieldProps}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={FormikPhoneField}
              type='text'
              label='Work Phone Number (Optional)'
              name='phoneWork'
              {...fieldProps}
            />
          </Grid>
          <Grid item xs={6}>
            <Field component={TextField} type='text' label='Address' name='address1' {...fieldProps} />
          </Grid>
          <Grid item xs={6}>
            <Field component={TextField} type='text' label='Unit Number (Optional)' name='unit' {...fieldProps} />
          </Grid>
          <Grid item xs={6}>
            <Field component={TextField} type='text' label='City' name='city' {...fieldProps} />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              label='State'
              name='state'
              inputProps={{ maxLength: 2 }}
              {...fieldProps}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              label='Zip'
              name='zip'
              inputProps={{ maxLength: 5 }}
              {...fieldProps}
            />
          </Grid>
          <Grid item xs={6}>
            <Field component={TextField} type='email' label='Email (Optional)' name='email' {...fieldProps} />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              select
              label='Patient Gender (Optional)'
              name='gender'
              {...fieldProps}>
              <MenuItem value=''>-</MenuItem>
              <MenuItem value='male'>Male</MenuItem>
              <MenuItem value='female'>Female</MenuItem>
              <MenuItem value='other'>Other</MenuItem>
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              label='Zeel Member Account ID'
              name='memberId'
              {...fieldProps}
              {...readOnlyProps}
            />
          </Grid>
          <Grid item xs={6}>
            <Field component={TextField} type='text' select label='Removed From Network' name='removed' {...fieldProps}>
              <MenuItem value='Yes'>Yes</MenuItem>
              <MenuItem value='No'>No</MenuItem>
            </Field>
          </Grid>
          <Grid item xs={6} />
        </Form>
      )}
    </Formik>
  );
};

export default PatientInformation;
