import React, { useEffect } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { MedicalApi, DocumentApi, EvaluationApi } from 'apis';
import { ServiceRequestType, CompositionType, DocumentType, PatientType } from 'apis/medical';
import { useState, useUIStore, useForm, useCombineFiles, useCopyToClipboard, useAuth } from 'utils/hooks';
import Modal, { ModalPropType } from '../templates/Modal';
import Grid from '@mui/material/Unstable_Grid2';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import { TextField, FilesContainer } from 'components';
import styles from './style.module.scss';
import { DuplicateCopyBoldIcon } from '@zeel-dev/zeel-ui';
import { DocumentMetaType } from 'apis/document';
import { convertUTCDateToLocalDate } from 'utils/helper';

export type CopyAppointmentToHsrmV2Props = ModalPropType & {
  serviceRequestId: string;
  isExpired?: boolean;
};

export default function CopyRevocationToHsrm({
  serviceRequestId,
  isExpired,
  onClose,
  scrollTop,
  modalProps,
}: CopyAppointmentToHsrmV2Props) {
  const { openAlert } = useUIStore();
  const { copyToClipboard } = useCopyToClipboard();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [serviceRequest, setServiceRequest] = useState<any>();
  const [patient, setPatient] = useState<any>();
  const [notes, setNotes] = useState<CompositionType[]>([]);
  const [evaluations, setEvaluations] = useState<DocumentType[]>([]);
  const { form } = useForm({
    expirationDate: null,
  });
  const { user } = useAuth();

  const { openCombineFiles, uploadedFiles, combinedSoapNoteIds, combinedQuestionnaireResponseIds } = useCombineFiles({
    serviceRequest,
    notes,
    evaluations,
    patient,
    onError: (e) => {
      setError(e);
      scrollTop();
    },
  });

  useEffect(() => {
    async function fetchData() {
      let sr: ServiceRequestType | null = null;
      let p: PatientType | null = null;

      if (serviceRequestId) {
        try {
          sr = (await MedicalApi.fetchServiceRequest(serviceRequestId)) as ServiceRequestType;
          setServiceRequest(sr);
          if (sr?.patient_id) {
            p = await MedicalApi.fetchPatient(sr.patient_id);
            setPatient(p);
          }

          let documents =
            sr?.additional_documents
              ?.filter((doc) => doc?.type === 'evaluation')
              .map(
                (doc: DocumentType): DocumentType => ({
                  url: doc.url,
                  description: doc.description,
                  id: (doc.url || '').split('/').pop() as string,
                  type: doc?.type,
                })
              ) ?? [];

          const promises: Array<Promise<DocumentMetaType | boolean>> = [];
          documents.forEach((d: any) => {
            promises.push(DocumentApi.fetchDocumentMeta(d.id).catch(() => false));
          });

          const fileMetas = await Promise.all(promises);
          const idsToRemove: Array<string> = [];
          (fileMetas || []).forEach((meta: any, i: number) => {
            if (!meta) {
              idsToRemove.push(documents[i].id);
            } else {
              const { filename: fileName, filesize: fileSize, mimetype: mimeType, tags, created_utc: created } = meta;
              documents[i].meta = {
                fileName,
                fileSize,
                mimeType,
                tags,
                created: convertUTCDateToLocalDate(created),
              };
            }
          });

          // removing ids where fetching meta failed (doc doesn't exist 404)
          documents = documents.filter((doc: DocumentType) => !idsToRemove.includes(doc.id));
          setEvaluations(documents);
        } catch (e) {
          setError('An error occured while fetching the referral #');
        }

        try {
          const pNotes = await MedicalApi.fetchAllSoapNote(serviceRequestId);
          pNotes.sort(
            (a: CompositionType, b: CompositionType) =>
              moment(a.date_submitted).unix() - moment(b.date_submitted).unix()
          );
          setNotes(pNotes);
        } catch (e) {
          setError('An error occured while fetching the notes');
        }
      }

      setLoading(false);
    }
    fetchData();
  }, [serviceRequestId]);

  const onSubmit = async () => {
    try {
      const promises = [
        MedicalApi.updateServiceRequest(serviceRequest?.id, {
          copied_to_hsrm: true,
          task_completed_by: user.memberId,
        }),
      ];
      // Mark combined soap notes and questionnaire responses as final
      promises.push(
        ...combinedSoapNoteIds.map((id) =>
          MedicalApi.updateComposition(id, {
            status: 'final',
            task_completed_by: user.memberId,
          })
        )
      );
      promises.push(
        ...combinedQuestionnaireResponseIds.map((id) =>
          EvaluationApi.updateQuestionnaireResponse(id, {
            status: 'final',
          })
        )
      );

      await Promise.all(promises);
      openAlert({
        title: `Service Request Marked as ${isExpired ? 'Expired' : 'Revoked'} in HSRM`,
      });
      onClose?.(true);
    } catch (e) {
      setError(`An error occured while marking the service request as ${isExpired ? 'expired' : 'revoked'} in HSRM`);
      scrollTop();
      console.error(e);
    }
  };

  const onSubmitError = async () => {
    form.setFormError('Please fix the errors below');
    scrollTop();
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    disabled: true,
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      style: { pointerEvents: 'none' },
      endAdornment: (
        <InputAdornment position='end'>
          <DuplicateCopyBoldIcon size={24} />
        </InputAdornment>
      ),
    },
  };

  const copy = (value = '') => {
    copyToClipboard(value);
  };

  const getDescription = (noteCount: number, evaluationCount: number) => {
    let description = 'Includes ';
    if (noteCount > 0) {
      description += `${noteCount} SOAP Note${noteCount > 1 ? 's' : ''}`;
    }

    if (noteCount > 0 && evaluationCount > 0) {
      description += ' and ';
    }

    if (evaluationCount > 0) {
      description += `${evaluationCount} Evaluation${evaluationCount > 1 ? 's' : ''}`;
    }

    return description;
  };

  return (
    <Modal
      {...modalProps}
      loading={loading}
      alert={error}
      title={isExpired ? 'Copy Expired Referral Documents to HSRM' : 'Revoke Referral in HSRM'}
      className={styles.base}
      footerClassName={styles.footer}
      onSubmit={form.handleSubmit(onSubmit, onSubmitError)}
      actions={[
        { label: 'Back', action: 'close' },
        { label: isExpired ? 'Mark Referral as Expired' : 'Mark Referral as Revoked', action: 'submit' },
      ]}>
      <Grid className={styles.section} container spacing={3}>
        <Grid xs={12}>
          <p className={styles.title}>1. Find referral in HSRM portal</p>
        </Grid>
        <Grid xs={6} className={styles.copyWrapper} onClick={() => copy(serviceRequest?.referral_number)}>
          <TextField value={serviceRequest?.referral_number} label='Referral #' {...fieldProps} />
        </Grid>
      </Grid>
      <Grid className={styles.section} container spacing={3}>
        <Grid xs={12}>
          <p className={styles.title}>2. Mark service as “{isExpired ? 'Expired' : 'Revoked'}” in HSRM</p>
        </Grid>
        <Grid xs={12} className={styles.copyWrapper} onClick={() => copy(serviceRequest?.revocation_reason)}>
          <TextField value={serviceRequest?.revocation_reason} label='Reason for Revocation' {...fieldProps} />
        </Grid>
      </Grid>
      <Grid className={styles.section} container spacing={3}>
        <Grid xs={12}>
          <p className={styles.title}>3. Upload Remaining Files to HSRM</p>
        </Grid>
        <Grid xs={12} className={cn(styles.files)}>
          <FilesContainer
            label='Files to Upload'
            showTitle={false}
            files={uploadedFiles?.map(({ file, soapNoteIds, appointmentIds, evaluationIds }) => ({
              id: file.id,
              url: file.location,
              description: getDescription(soapNoteIds.length, appointmentIds.length + evaluationIds.length),
              meta: {
                fileName: file?.meta?.filename,
                fileSize: file?.meta?.filesize,
                mimeType: file?.meta?.mimetype,
                tags: file?.meta?.tags,
                created: convertUTCDateToLocalDate(file?.meta?.created_utc),
              },
            }))}
            condensed
          />
          <Link
            component='button'
            variant='body2'
            underline='always'
            onClick={(e: any) => {
              e.preventDefault();
              openCombineFiles({
                approvedOnly: true,
              });
            }}>
            Choose Specific Files to Include
          </Link>
        </Grid>
      </Grid>
    </Modal>
  );
}
