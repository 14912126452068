import React, { ReactElement, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import { SearchIcon, XCircleFilledIcon } from '@zeel-dev/ui-icons';
import { grey3, midnight } from '@zeel-dev/ui-styles';
import SearchResults from './SearchResults';
import { useStyles } from './SearchField.styles';
import { useDebouncedCallback } from 'use-debounce';
import { SearchFieldProps } from './SearchField.types';

export default function SearchField(props: SearchFieldProps): ReactElement {
  const classes = useStyles();

  const onChangeHandler = useDebouncedCallback((searchValue) => {
    props.debounceCallback(searchValue);
  }, 600);

  const handleSearchOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    props.onChange(text);
    onChangeHandler.callback(text);
  };

  const errorMessage = useMemo(() => {
    if (props.errorMessage) {
      return props.errorMessage;
    } else if (props.searchResults && props.searchResults.length === 0) {
      return 'No Results';
    }
    return '';
  }, [props.errorMessage, props.searchResults]);

  return (
    <div className={classes.wrapper}>
      <TextField
        fullWidth
        variant={'outlined'}
        label={props.label}
        error={!!errorMessage}
        helperText={errorMessage}
        disabled={props.disabled}
        InputLabelProps={
          props.label
            ? {
                variant: 'outlined',
                shrink: true,
              }
            : {}
        }
        placeholder={props.placeholder}
        value={props.value || ''}
        onChange={handleSearchOnChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        required={props.required}
      />
      <button
        type={'button'}
        className={classes.button}
        onClick={() => props.value && props.value.length > 0 && props.onChange('')}>
        {props.value && props.value.length > 0 ? (
          !props.hideClearButton && <XCircleFilledIcon color={grey3} size={'24px'} tabIndex={-1} />
        ) : (
          <SearchIcon color={midnight} size={'24px'} tabIndex={-1} />
        )}
      </button>
      {((props.searchResults && props.searchResults.length > 0) || props.searching) && !props.disabled && (
        <SearchResults
          searching={props.searching}
          items={props.searchResults || []}
          onSelectItem={props.onItemSelect}
        />
      )}
    </div>
  );
}
