import React, { ReactElement } from 'react';
import { AvatarNeutralIcon } from '@zeel-dev/ui-icons';
import { useStyles } from './Persona.styles';

export type PersonaProps = {
  name: any;
  imageUrl?: any;
};

export default function Persona(props: PersonaProps): ReactElement {
  const classes = useStyles(props);

  return (
    <div className={classes.wrapper}>
      <div className={classes.imageWrapper}>
        <AvatarNeutralIcon size={'80px'} />
      </div>
      <div className={classes.nameWrapper}>{props.name}</div>
    </div>
  );
}
