const constants: any = {
  ROLES: {
    // need to match cognito group name, which translates in UI roles
    ADMIN: 'admin',
    MEDICAL: {
      DATA_ENTRY: 'medical-data-entry',
      CUSTOMER_SERVICE: 'medical-customer-service',
      REVIEWER: 'medical-reviewer',
      ADMIN: 'medical-admin',
      DOCTOR: 'medical-doctor',
      ALL: ['medical-data-entry', 'medical-customer-service', 'medical-reviewer', 'medical-admin', 'medical-doctor'],
    },
    TEST_DATA_CREATOR: 'test-data-creator',
  },
  CONSENT_FORMS: {
    // Medical Massage
    authorization_for_the_use_of_phi_form: 'Authorization for the use of phi',
    informed_consent_form: 'Informed Consent',
  },
  SERVICES: {
    ID: {
      MEDICAL: 7,
    },
  },
};

export default constants;
