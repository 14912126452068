import React, { FC, useState } from 'react';
import AppointmentsList from './AppointmentsList';
import { FormProps } from 'components/Form';
import { AppointmentType } from 'apis/medical';

type AppointmentsFormsProps = {
  appointments: AppointmentType[];
  canBookNewAppointment?: boolean;
  onBookNewAppointment?: () => void;
} & FormProps;

const AppointmentsForm: FC<AppointmentsFormsProps> = ({
  appointments,
  canBookNewAppointment,
  onBookNewAppointment,
  ...rest
}) => {
  const [appointmentsExpanded, setAppointmentsExpanded] = useState(false);

  return (
    <AppointmentsList
      appointments={appointmentsExpanded ? appointments : appointments.slice(0, 3)}
      expanded={appointmentsExpanded}
      canBookNewAppointment={canBookNewAppointment}
      onBookNewAppointment={onBookNewAppointment}
      onAppointmentsExpand={() => setAppointmentsExpanded(!appointmentsExpanded)}
      total={appointments.length}
      {...rest}
    />
  );
};

export default AppointmentsForm;
