import { FC, useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Icon, FieldTitle } from 'components';
import OfficeLocationForm from './OfficeLocationForm';
import { OfficeLocationType } from 'apis/practitioner';
import { PractitionerApi } from 'apis';
import { useUIStore } from 'utils/hooks';
import styles from './style.module.scss';

const OfficeLocationForms: FC<{ practitionerId: string; officeLocations: OfficeLocationType[]; readOnly?: boolean }> = (
  props
) => {
  const [editingIndex, setEditingIndex] = useState<number>(-1);
  const [officeLocations, setOfficeLocations] = useState<OfficeLocationType[]>(props.officeLocations || []);
  const [addingNew, setAddingNew] = useState(false);
  const { openAlert } = useUIStore();

  useEffect(() => {
    setOfficeLocations(props.officeLocations || []);
  }, [props.officeLocations]);

  const handleAdd = async (newLocation: OfficeLocationType) => {
    const newOfficeAddresses = [...officeLocations, newLocation];
    try {
      const res = await PractitionerApi.updatePractitioner(props.practitionerId, {
        office_based_address: newOfficeAddresses,
      });
      setOfficeLocations(res.office_based_address);
      openAlert({ title: `Office location was successfully added`, severity: 'success' });
    } catch (error) {
      openAlert({ title: `Failed to add office location`, severity: 'error' });
    }
    setAddingNew(false);
  };

  const handleSave = async (newLocation: OfficeLocationType) => {
    if (editingIndex === undefined) {
      return;
    }
    const newOfficeAddresses = [...officeLocations];
    newOfficeAddresses.splice(editingIndex, 1, newLocation);
    try {
      const res = await PractitionerApi.updatePractitioner(props.practitionerId, {
        office_based_address: newOfficeAddresses,
      });
      setOfficeLocations(res.office_based_address);
      openAlert({ title: `Office location was successfully updated`, severity: 'success' });
    } catch (error) {
      openAlert({ title: `Failed to update office location`, severity: 'error' });
    }
    setEditingIndex(-1);
  };

  const handleRemove = async () => {
    if (editingIndex === undefined) {
      return;
    }
    const oldOfficeAddresses = [...officeLocations];
    const newOfficeAddresses = [...officeLocations];
    newOfficeAddresses.splice(editingIndex, 1);
    setOfficeLocations(newOfficeAddresses);
    try {
      const res = await PractitionerApi.updatePractitioner(props.practitionerId, {
        office_based_address: newOfficeAddresses,
      });
      setOfficeLocations(res.office_based_address);
      openAlert({ title: `Office location was successfully removed`, severity: 'success' });
    } catch (error) {
      setOfficeLocations(oldOfficeAddresses);
      openAlert({ title: `Failed to remove office location`, severity: 'error' });
    }
    setEditingIndex(-1);
  };

  return (
    <>
      <FieldTitle
        icon='building'
        margin
        rightContent={
          !props.readOnly &&
          !addingNew &&
          editingIndex === -1 && <Icon name='add-circle' size={20} onClick={() => setAddingNew(true)} />
        }>
        Office Location
      </FieldTitle>
      {officeLocations.map((officeAddress, index) => (
        <Grid key={index} container spacing={3}>
          {index !== 0 && (
            <Grid item xs={12} marginTop={3}>
              <Divider />
            </Grid>
          )}
          <Grid item container>
            <OfficeLocationForm
              location={officeAddress}
              editable={!props.readOnly && !addingNew && (editingIndex === -1 || editingIndex === index)}
              removable={officeLocations.length > 1}
              onEdit={() => setEditingIndex(index)}
              onSave={handleSave}
              onRemove={handleRemove}
              onUndo={() => setEditingIndex(-1)}
              title={`Office ${index + 1}`}
            />
          </Grid>
        </Grid>
      ))}
      {!props.readOnly && !addingNew && editingIndex === -1 && (
        <Button className={styles.addButton} onClick={() => setAddingNew(true)}>
          <Icon name='add-circle' size={20} onClick={() => setEditingIndex(officeLocations.length)} />
          &nbsp;&nbsp;Add Another Office Location
        </Button>
      )}
      {addingNew && (
        <Grid container spacing={3}>
          {officeLocations.length !== 0 && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
          <Grid item container>
            <OfficeLocationForm
              title={`Office ${officeLocations.length + 1}`}
              location={null}
              addingNew
              editable
              onSave={handleAdd}
              onUndo={() => setAddingNew(false)}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default OfficeLocationForms;
