import { DocumentApi, ZCCApi } from 'apis';
import { ZCCSortByParameters, ZccEFaxType } from 'apis/zcc';
import cn from 'classnames';
import { Button, FilterButtonV3, SectionLayout, Table, Tooltip } from 'components';
import EFaxSidePanel from 'components/sidePanels/EFax';
import isEqual from 'lodash/isEqual';
import { useCallback, useMemo, MouseEvent } from 'react';
import { useCopyToClipboard, useState, useUIStore, useZccService } from 'utils/hooks';
import styles from './style.module.scss';
import { DownloadBoldIcon, DuplicateCopyBoldIcon, NewWindowBoldIcon } from '@zeel-dev/zeel-ui';
import { downloadFile, getCalendarTime, joinString } from 'utils/helper';

enum EFaxActions {
  MarkProcessed = 'mark_processed',
}

export default function EFax() {
  const service = useZccService<ZccEFaxType>(ZCCApi.fetchEFaxItems, {
    paramTypes: { processed: 'boolean' },
    initialParameters: {
      filters: {
        processed: false,
      },
      sortBy: { received_date: 'asc' },
    },
  });

  const [open, setOpen] = useState<boolean>();
  const [activeRowData, setActiveRowData] = useState<any>(null);
  const [highlightedRowId, setHighlightedRowId] = useState<string | null | undefined>();

  const { openModal } = useUIStore();
  const { copyToClipboard } = useCopyToClipboard();

  const rowClicked = (row: any) => {
    const { id } = row || {};
    if (open && activeRowData && id === activeRowData?.id) setOpen(false);
    else {
      setOpen(true);
      setActiveRowData(row);
    }
  };

  const sortChangeHandler = (sortModel: any) => {
    const sortBy: ZCCSortByParameters = {};
    sortModel?.forEach(({ field, sort }: any) => {
      sortBy[field] = sort;
    });
    if (!isEqual(service.parameters.sortBy, sortBy)) {
      service.setSortBy(sortBy);
    }
  };

  const highlightRow = (rowId: string) => {
    setHighlightedRowId(rowId);
    setTimeout(() => setHighlightedRowId(null), 5000);
  };

  const handleClickEFaxLink = useCallback(
    (documentId: string) => (e: MouseEvent<HTMLElement | SVGSVGElement>) => {
      e.stopPropagation();
      openModal({
        id: 'document-pdf-view',
        props: { docId: documentId, title: 'eFax Document', showPagination: true },
      });
    },
    [openModal]
  );

  const handleDownloadEFaxDocument = useCallback(
    (documentId: string) => async (e: MouseEvent<HTMLElement | SVGSVGElement>) => {
      e.stopPropagation();
      const [responseBlob, documentManifest] = await Promise.all([
        DocumentApi.downloadDocument(documentId),
        DocumentApi.fetchDocumentMeta(documentId),
      ]);
      const url = window.URL.createObjectURL(responseBlob);
      downloadFile(url, `${documentManifest?.filename || `file-${new Date().getTime()}`}`);
    },
    []
  );

  const handleMarkAsProcessed = useCallback(
    (eFaxId: string, documentId: string) => async (e: MouseEvent<HTMLElement | SVGSVGElement>) => {
      e.stopPropagation();
      openModal({
        id: 'process-e-fax-document',
        props: {
          documentId,
          eFaxId,
        },
        callback: (response) => {
          if (response) {
            service.refresh();
          }
        },
      });
    },
    [openModal, service]
  );

  const columns = useMemo(
    () => [
      { id: 'received_date', label: 'Date received', width: 120, type: 'moment', dateFormat: 'MMM DD, YYYY' },
      {
        id: 'received_time',
        label: 'Time received',
        width: 120,
        type: 'moment',
        dateFormat: 'hh:mm A',
        sortable: false,
      },
      {
        id: 'document_url',
        label: 'Link to view fax',
        width: 400,
        sortable: false,
        renderCell: ({ value, row }: { value: string; row: any }) => {
          return (
            <div className={styles.eFaxCell}>
              <Tooltip
                title={
                  <div className={styles.eFaxTooltip}>
                    <span>{value}</span>
                    <DuplicateCopyBoldIcon size={24} onClick={() => copyToClipboard(value)} />
                  </div>
                }
                placement='top'>
                <span className={styles.eFaxLink} onClick={handleClickEFaxLink(row.document_id)}>
                  eFax Link
                </span>
              </Tooltip>
              <NewWindowBoldIcon size={18} color='primary' onClick={handleClickEFaxLink(row.document_id)} />
              <DownloadBoldIcon size={18} color='primary' onClick={handleDownloadEFaxDocument(row.document_id)} />
            </div>
          );
        },
      },
      { id: 'phone_from', label: 'Fax sent from', width: 120 },
      {
        id: 'recent_note',
        label: 'Notes',
        width: 250,
        sortable: false,
        renderCell: ({ value }: any) => {
          return (
            <div className={styles.ellipsisText}>
              {value && <p className={cn(styles.recentNote, styles.ellipsisText)}>{value}</p>}
            </div>
          );
        },
      },
      { id: 'processed', label: 'Processed/unprocessed', width: 200 },
      {
        id: 'actions',
        label: 'Next Step',
        width: 500,
        sortable: false,
        renderCell: ({ value: actions, row }: any) => {
          const actionsList = [];
          if (actions?.includes(EFaxActions.MarkProcessed)) {
            actionsList.push(
              <Button state='primary' variant='outlined' onClick={handleMarkAsProcessed(row.id, row.document_id)}>
                Mark as Processed
              </Button>
            );
          }
          return <div className={styles.actions}>{actionsList}</div>;
        },
      },
    ],
    [handleClickEFaxLink, handleDownloadEFaxDocument, handleMarkAsProcessed, copyToClipboard]
  );

  const rows = useMemo(() => {
    const r = [
      ...(service.data || []).map((item: ZccEFaxType) => {
        const { _id, received_date, document_id, document_url, phone_from, notes, actions } = item;

        const recentNote = notes?.[0]
          ? `${joinString(
              [getCalendarTime(notes[0].timestamp, true, 'MMM D', true), notes[0].pcc_agent || ''],
              ', '
            )}: ${notes[0].text || ''}`
          : '';

        return {
          id: _id,
          received_date: received_date,
          received_time: received_date,
          document_id,
          document_url,
          phone_from,
          recent_note: recentNote,
          processed: item.processed ? 'Yes' : 'No',
          actions,
          data: item,
        };
      }),
    ];
    return r;
  }, [service.data]);

  const sideContent = (
    <EFaxSidePanel
      open={open}
      onClose={() => setOpen(false)}
      eFaxId={activeRowData?.id}
      onChange={() => {
        highlightRow(activeRowData?.id);
        service.refresh();
      }}
    />
  );

  return (
    <>
      <SectionLayout alert={service.error} title='eFax Queue' sideContent={sideContent}>
        <div className={styles.tableHeader}>
          <FilterButtonV3
            initialValue={service.parameters.filters}
            onChange={(newFilters: any) => {
              service.setFilters(newFilters);
            }}
            filters={[
              {
                id: 'processed',
                label: 'Processed',
                size: 'full',
                type: 'segments',
                options: [
                  { label: 'Yes', value: true },
                  { label: 'All', value: '' },
                  { label: 'No', value: false },
                ],
              },
            ]}
          />
        </div>
        <Table
          className={styles.table}
          loading={service.loading}
          name={`${rows.length} eFax${rows.length > 1 ? 'es' : ''}`}
          tableCellClassName={styles.tableCellClassName}
          columns={columns}
          rows={rows}
          rowHeight={76}
          onRowClick={(row: any) => rowClicked(row)}
          activeRowId={open ? activeRowData?.id : undefined}
          highlightedRowId={highlightedRowId}
          page={service.parameters.page}
          pageSize={service.parameters.pageSize}
          serverPagination
          serverRowCount={service.total}
          onPageChange={(page: any) => service.setPage(page)}
          onPageSizeChange={(pageSize: any) => service.setPageSize(pageSize)}
          rowsPerPageOptions={[50]}
          sortModel={service.sortModel}
          onSortModelChange={sortChangeHandler}
        />
      </SectionLayout>
    </>
  );
}
