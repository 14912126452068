import React, { useState, useEffect, useCallback } from 'react';
import Modal from '../templates/Modal';
import styles from './style.module.scss';
import { ProcessEFaxDocumentProps } from './ProcessEFaxDocument.types';
import { DocumentApi, MedicalApi, ZCCApi } from 'apis';
import { helper } from 'utils';
import { AutocompleteField, PDFView, TextField } from 'components';
import { useAuth, useForm, useUIStore } from 'utils/hooks';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import { ZccPatientType } from 'apis/zcc';
import { PatientType, ServiceRequestType } from 'apis/medical';
import { getDocumentUrl } from 'utils/helper';

export default function ProcessEFaxDocument({
  eFaxId,
  documentId,
  title,
  onClose,
  scrollTop,
  modalProps,
}: ProcessEFaxDocumentProps) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [base64String, setBase64String] = useState('');
  const [patientSearch, setPatientSearch] = useState();
  const [patients, setPatients] = useState<Array<ZccPatientType & { id: string }>>([]);
  const [loadingPatients, setLoadingPatients] = useState(false);
  const [serviceRequests, setServiceRequests] = useState<ServiceRequestType[]>([]);
  const [loadingServiceRequests, setLoadingServiceRequests] = useState(false);

  const { openAlert } = useUIStore();
  const { user } = useAuth();
  const { form, bind, bindCustom } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseBlob = await DocumentApi.downloadDocument(documentId);
        const base64 = await helper.blobToBase64(responseBlob);
        setBase64String(base64);
      } catch (e) {
        setError('An error occurred while loading eFax document');
        scrollTop();
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const onSubmit = useCallback(
    async (values: { serviceRequestId: string }) => {
      try {
        const promises = [
          ZCCApi.updateEFax(eFaxId, { processed: true, processed_by: user?.pccAgentName }),
          MedicalApi.addServiceRequestFiles(values.serviceRequestId, [
            {
              description: 'eFax Document',
              type: 'medical-document',
              url: getDocumentUrl(documentId),
            },
          ]),
        ];
        await Promise.all(promises);

        openAlert({ title: 'eFax document has been marked as processed' });
        onClose?.(true);
      } catch (e) {
        form.setFormError('An error occurred while processing the eFax');
        scrollTop();
      }
    },
    [documentId]
  );

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        setLoadingPatients(true);
        const { items } = await ZCCApi.fetchPatientItems({ filters: { patient_name: patientSearch } });
        setPatients(
          items.map((item) => ({
            ...item,
            id: item._id,
          }))
        );
      } catch (e) {
        form.setFormError('An error occurred while retrieving the patients');
        scrollTop();
      } finally {
        setLoadingPatients(false);
      }
    };

    if (patientSearch) fetchMembers();
  }, [patientSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchServiceRequests = async (patientId: string) => {
    try {
      setLoadingServiceRequests(true);
      const patient = await MedicalApi.fetchPatient(patientId);
      setServiceRequests(patient?.service_requests || []);
    } catch (e) {
      form.setFormError('An error occurred while retrieving the service requests');
      scrollTop();
    } finally {
      setLoadingServiceRequests(false);
    }
  };

  const onSubmitError = async () => {
    form.setFormError('Please fix the errors below');
    scrollTop();
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  const patientBind = bindCustom('patientId', { required: true });

  return (
    <Modal
      {...modalProps}
      loading={loading}
      alert={error || form.formError}
      actions={[
        { label: 'Cancel', action: 'close' },
        { label: 'Mark as Processed', action: 'submit' },
      ]}
      title={title || 'Process eFax Document'}
      className={styles.base}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={form.handleSubmit(onSubmit, onSubmitError)}>
      {!loading && (
        <>
          {!error && <PDFView file={base64String} showPagination width={720} />}
          <Grid container marginTop={3} spacing={1}>
            <Grid item xs={6}>
              <AutocompleteField
                filterOptions={(x: any) => x}
                getOptionLabel={(option: ZccPatientType) => option.patient_name}
                onInputChange={(value: any) => setPatientSearch(value)}
                options={patients}
                loading={loadingPatients}
                ListBoxProps={{ style: { maxHeight: '150px' } }}
                label='Assign to Patient'
                {...patientBind}
                onChange={(patient: PatientType) => {
                  patientBind.onChange(patient.id);
                  fetchServiceRequests(patient.id);
                }}
                {...fieldProps}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...bind('serviceRequestId', { required: true })}
                select
                label='Link to Referral'
                disabled={loadingServiceRequests || !form.values.patientId}
                loading={loadingServiceRequests}
                SelectProps={{
                  endAdornment: loadingServiceRequests ? <CircularProgress color='inherit' size={20} /> : null,
                }}
                {...fieldProps}>
                {serviceRequests?.map((sr: any, i: number) => (
                  <MenuItem
                    key={`${sr?.id}-${i}`}
                    value={sr?.id}
                    /* eslint-disable max-len */
                  >{`${sr?.referral_number} ${sr?.duration}min ${sr?.quantity?.available}/${sr?.quantity?.value} (${sr?.id})`}</MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </>
      )}
    </Modal>
  );
}
