import { useState } from 'react';
import Modal from '../templates/Modal';
import { PickCalendarDateProps } from './PickCalendarDate.types';
import { Calendar } from '@zeel-dev/zeel-ui';
import styles from './style.module.scss';
import moment from 'moment';

export default function PickCalendarDate({
  description,
  title,
  buttonTitle = 'Hold',
  onClose,
  modalProps,
}: PickCalendarDateProps) {
  const [selectedDate, setSelectedDate] = useState<Date>();

  return (
    <Modal
      {...modalProps}
      title={title}
      description={description}
      className={styles.base}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      actions={[
        {
          label: !selectedDate ? 'Select a Date' : `${buttonTitle} Until ${moment(selectedDate).format('MMMM DD')}`,
          disabled: !selectedDate,
          action: 'submit',
          onClick: () => onClose?.(selectedDate),
        },
      ]}>
      <Calendar
        fullWidth
        disablePast
        disabledDates={[new Date()]}
        value={selectedDate}
        onChange={(date: Date) => setSelectedDate(date)}
      />
    </Modal>
  );
}
