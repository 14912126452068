import qs from 'qs';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { useState, useEffect, useRef, useMemo } from '.';
import { ZccApiParameters, ZCCSortByParameters } from 'apis/zcc';
import { history } from 'utils';
import { parseUrl } from 'utils/history';
import merge from 'lodash/merge';
import uniq from 'lodash/uniq';
import cloneDeep from 'lodash/cloneDeep';
import { FilterExceptionType } from 'components/FilterButtonV3/FilterButtonV3.types';

export type PresetType = {
  id: string;
  label: string;
  key: string;
  value: string | string[];
};

export default function useZccService<TZccServiceItemType>(
  dataFetcher: (
    data: ZccApiParameters,
    cancelToken?: CancelToken
  ) => Promise<{ items: Array<TZccServiceItemType>; total: number }>,
  options?: {
    initialParameters?: Record<string, any>;
    paramTypes?: Record<string, 'boolean' | 'number' | 'string'>;
    bypassUrlRewrite?: boolean;
    fetchOnMount?: boolean;
    presets?: PresetType[];
    exceptions?: FilterExceptionType[];
    transformRequest?: (data: ZccApiParameters) => ZccApiParameters;
  }
) {
  const {
    initialParameters = {},
    paramTypes = {},
    bypassUrlRewrite = false,
    fetchOnMount = true,
    presets,
    exceptions,
  } = options ?? {};
  const urlParameters = bypassUrlRewrite ? {} : parseUrl(history?.location?.search, paramTypes, 'filters');
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [dataError, setDataError] = useState<string>();
  const [data, setData] = useState<Array<TZccServiceItemType>>([]);
  const [dataTotal, setDataTotal] = useState<number>();
  const [bypassRewrite] = useState(bypassUrlRewrite);
  const [parameters, setParameters] = useState<ZccApiParameters>(
    merge({}, initialParameters, {
      page: Number(urlParameters?.page) || 0,
      pageSize: Number(urlParameters?.pageSize) || 50,
      filters: urlParameters?.filters || {},
      sortBy: urlParameters?.sortBy || {},
    })
  );
  const parametersRef = useRef(parameters);

  const getPageData = async (cancelTokenSource?: CancelTokenSource) => {
    setDataLoading(true);
    const _params = parametersRef?.current || {};
    try {
      const dataParams = cloneDeep(_params);
      if (dataParams.filters) {
        Object.keys(dataParams.filters).forEach((key) => {
          if (dataParams.filters?.[key] === '' || dataParams.filters?.[key]?.length === 0) {
            delete dataParams.filters[key];
          } else if (typeof dataParams.filters?.[key] === 'string') {
            // Trim trailing spaces
            dataParams.filters[key] = dataParams.filters[key].trim();
          }
        });
        // Set url before replacing preset filters
        !bypassRewrite && history.push({ search: qs.stringify(dataParams) });
        // Replace preset filters with actual filters
        Object.keys(dataParams.filters).forEach((key) => {
          const preset = presets?.find((p) => p.id === key);
          if (preset) {
            if (dataParams.filters?.[key]) {
              if (Array.isArray(preset.value) && Array.isArray(dataParams.filters[preset.key])) {
                dataParams.filters[preset.key] = uniq([...preset.value, ...dataParams.filters[preset.key]]);
              } else if (!dataParams.filters[preset.key]) {
                dataParams.filters[preset.key] = preset.value;
              }
            }
            delete dataParams.filters?.[key];
          }
        });
        if ('exceptions' in dataParams.filters) {
          const exceptionIds = dataParams.filters.exceptions;
          delete dataParams.filters['exceptions'];
          exceptionIds.forEach((exceptionId: string) => {
            const exception = exceptions?.find(({ id }) => id === exceptionId);
            if (exception) {
              if (!dataParams.filters) {
                dataParams.filters = {};
              }
              if (Array.isArray(exception.value)) {
                dataParams.filters[exception.key] = uniq([
                  ...(dataParams.filters[exception.key] || []),
                  ...exception.value,
                ]);
              } else {
                dataParams.filters[exception.key] = exception.value;
              }
            }
          });
        }
      } else {
        !bypassRewrite && history.push({ search: qs.stringify(dataParams) });
      }
      const transformedDataParams = options?.transformRequest ? options.transformRequest(dataParams) : dataParams;
      const response = await dataFetcher(transformedDataParams, cancelTokenSource?.token);
      const { items, total } = response;
      setData(items);
      setDataTotal(total);
    } catch (e: any) {
      if (axios.isCancel(e)) return;
      if (e?.response?.status === 404) {
        setData([]);
      } else {
        setDataError(e?.message || 'An error occured while fetching the medical review items');
      }
    }
    setDataLoading(false);
  };

  const dataFetchHandler = () => {
    const cancelTokenSource = axios.CancelToken.source();
    getPageData(cancelTokenSource);
    return () => cancelTokenSource.cancel();
  };

  useEffect(() => {
    parametersRef.current = parameters;
    if (!fetchOnMount) {
      return;
    }
    return dataFetchHandler();
  }, [fetchOnMount, parameters.page, parameters.pageSize, parameters.filters, JSON.stringify(parameters.sortBy)]);

  const setPage = (pageNumber: number) => {
    if (parameters.page !== pageNumber) {
      setParameters({
        ...parameters,
        page: pageNumber,
      });
    }
  };

  const setPageSize = (pageSize: number) => {
    if (parameters.pageSize !== pageSize) {
      setParameters({
        ...parameters,
        pageSize: pageSize,
      });
    }
  };

  const setFilters = (filters: Record<string, string | unknown>, sortBy?: ZCCSortByParameters) => {
    setParameters({
      ...parameters,
      page: 0, // resetting page when filters change
      filters: { ...filters },
      sortBy: sortBy,
    });
  };

  const setSortBy = (sortBy: ZCCSortByParameters) => {
    setParameters({
      ...parameters,
      page: 0, // resetting page when filters change
      sortBy: sortBy,
    });
  };

  const sortModel = useMemo(
    () =>
      Object.keys(parameters.sortBy || {}).map((key) => ({
        field: key,
        sort: parameters.sortBy?.[key],
      })),
    [parameters]
  );

  return {
    parameters,
    setParameters,
    setPage,
    setPageSize,
    setFilters,
    setSortBy,
    sortModel,
    refresh: () => dataFetchHandler(),
    loading: dataLoading,
    error: dataError,
    data,
    total: dataTotal,
  };
}
