import React, { FC } from 'react';
import Grid from '@mui/material/Grid';
import { Formik, Field } from 'formik';
import Form from 'components/Form';
import { TextField, TextFieldProps, fieldToTextField } from 'formik-mui';
import { PhoneField } from 'components';
import { useUIStore } from 'utils/hooks';
import * as yup from 'yup';
import 'yup-phone';
import { PatientInformationProps } from './PatientForm.types';
import { normalizePhoneNumber } from 'utils/helper';
import { MedicalApi } from 'apis';
// import AddressField, { AddressSuggestionType } from 'components/fields/AddressField';

const validationSchema = yup.object({
  caregiverFirstName: yup.string().notRequired(),
  caregiverLastName: yup.string().notRequired(),
  caregiverPhone: yup.string().test('isMobile', 'Invalid phone number', (value) => {
    if (value) return yup.string().phone('US', false, 'Invalid phone number').isValidSync(value);
    return true;
  }),
  caregiverEmail: yup.string().email('Invalid email').notRequired(),
  caregiverRelationship: yup.string().notRequired(),
});

function FormikPhoneField(props: TextFieldProps) {
  return <PhoneField {...fieldToTextField(props)} />;
}

const CaregiverInformation: FC<PatientInformationProps> = ({
  patient,
  collapsible,
  editing,
  onEdit,
  onUndo,
  onSave,
  onError,
}) => {
  const { openAlert } = useUIStore();

  const fieldProps = {
    fullWidth: true,
    variant: 'standard',
    InputProps: { disableUnderline: !editing, readOnly: !editing },
    InputLabelProps: {
      shrink: true,
    },
  };

  const updatePatient = async (values: any, setSubmitting: (submitting: boolean) => void) => {
    if (patient) {
      try {
        const caregiver = {
          id: patient.caregiver?.id,
          name: {
            given: values.caregiverFirstName.split(' '),
            family: values.caregiverLastName,
          },
          relation_to_patient: values.caregiverRelationship,
          telecom: [
            ...(values.caregiverPhone
              ? [
                  {
                    system: 'phone',
                    value: values.caregiverPhone,
                  },
                ]
              : []),
            ...(values.caregiverEmail
              ? [
                  {
                    system: 'email',
                    value: values.caregiverEmail,
                  },
                ]
              : []),
          ],
        };
        // const types = await MedicalApi.fetchPatientRelationshipTypes();

        const data = {
          caregivers: [caregiver],
        };
        await MedicalApi.updatePatient(patient?.id, data);
        openAlert({
          title: `Caregiver information was successfully updated`,
        });
        onSave?.({
          ...patient,
          caregiver: {
            firstName: values.caregiverFirstName,
            lastName: values.caregiverLastName,
            phone: values.caregiverPhone,
            email: values.caregiverEmail,
            relationship: values.caregiverRelationship,
          },
        });
      } catch (error) {
        console.log(error);
        openAlert({ title: `Failed to update patient information`, severity: 'error' });
        onError?.();
      }
    } else {
      onSave?.();
    }
    setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        caregiverFirstName: patient?.caregiver?.firstName ?? '',
        caregiverLastName: patient?.caregiver?.lastName ?? '',
        caregiverEmail: patient?.caregiver?.email ?? '',
        caregiverPhone: patient?.caregiver?.phone ? normalizePhoneNumber(patient?.caregiver.phone) : '',
        caregiverRelationship: patient?.caregiver?.relationship ?? '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSave?.();
        updatePatient(values, setSubmitting);
      }}>
      {({ resetForm, handleSubmit, dirty }) => (
        <Form
          title='Caregiver Information'
          icon='user'
          collapsible={collapsible}
          editable
          editing={editing}
          marginBottom
          onEdit={() => onEdit?.()}
          onUndo={() => {
            if (dirty) {
              const confirm = window.confirm('You have unsaved changes that will be lost');
              if (confirm) {
                onUndo?.();
                resetForm();
              }
            } else {
              onUndo?.();
            }
          }}
          dirty={dirty}
          onSave={handleSubmit}>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              label='Caregiver’s First Name (Optional)'
              name='caregiverFirstName'
              {...fieldProps}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              label='Caregiver’s Last Name (Optional)'
              name='caregiverLastName'
              {...fieldProps}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={FormikPhoneField}
              type='text'
              label='Caregiver’s Phone Number (Optional)'
              name='caregiverPhone'
              {...fieldProps}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='email'
              label='Caregiver’s Email Address (Optional)'
              name='caregiverEmail'
              {...fieldProps}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              select
              label='Caregiver’s Relationship to Patient'
              name='caregiverRelationship'
              {...fieldProps}>
              <MenuItem value=''>-</MenuItem>
              <MenuItem value='Child'>Child</MenuItem>
              <MenuItem value='Parent'>Parent</MenuItem>
              <MenuItem value='Relative'>Relative</MenuItem>
              <MenuItem value='Sibling'>Sibling</MenuItem>
              <MenuItem value='Spouse/Partner'>Spouse/Partner</MenuItem>
              <MenuItem value='Professional Caregiver'>Professional Caregiver</MenuItem>
              <MenuItem value='Other'>Other</MenuItem>
            </Field>
          </Grid>
          <Grid item xs={6} /> */}
          <Grid item xs={12}>
            <Field
              component={TextField}
              type='text'
              label='Caregiver’s Relationship to Patient (Optional)'
              name='caregiverRelationship'
              {...fieldProps}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <AutocompleteField
              {...bindCustom('member', { required: edit })}
              filterOptions={(x: any) => x}
              getOptionLabel={(o: any) => `${o?.name} (${o?.id})`}
              onInputChange={(value: any) => setMemberSearch(value)}
              options={options}
              label="Link to existing Zeel Member account"
              {...fieldProps}
            />
          </Grid> */}
        </Form>
      )}
    </Formik>
  );
};

export default CaregiverInformation;
