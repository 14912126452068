import { useState } from 'react';
import Modal from '../templates/Modal';
import styles from './style.module.scss';
import { PractitionerType } from 'apis/practitioner';
import { AddressType } from 'apis/medical';
import { useUIStore } from 'utils/hooks';
import { AssignNewInOfficeProviderProps } from './AssignNewInOfficeProvider.types';
import PractitionerSearch from '../AddReferral/PractitionerSearch';
import { MedicalApi } from 'apis';
import { convertZccToZRefAddress } from 'utils/helper';

export default function AssignNewInOfficeProvider({
  serviceRequest,
  providerNPI,
  scrollTop,
  onClose,
  modalProps,
}: AssignNewInOfficeProviderProps) {
  const { openAlert } = useUIStore();
  const [selectedPractitioner, setSelectedPractitioner] = useState<PractitionerType>();
  const [matchingAddress, setMatchingAddress] = useState<AddressType>();
  const [error, setError] = useState<string>();

  const handleSubmit = async () => {
    if (!selectedPractitioner || !matchingAddress) {
      return;
    }
    try {
      await MedicalApi.updateServiceRequest(serviceRequest.id || '', {
        assigned_provider: selectedPractitioner.zeelId,
        requested_location: {
          address: matchingAddress,
        },
        office_based_referral: true,
      });
      openAlert({
        // eslint-disable-next-line max-len
        title: `${selectedPractitioner?.flattenName} has been assigned to this referral for appointments created from this point forward.`,
      });
      onClose?.(true);
    } catch (e) {
      setError('An error occurred while updating the referral');
      scrollTop();
      console.error(e);
    }
  };

  const requestLocation = serviceRequest.requested_location?.[0]?.address
    ? convertZccToZRefAddress(serviceRequest.requested_location[0].address)
    : undefined;

  return (
    <Modal
      {...modalProps}
      alert={error}
      title='Assign a New In-Office Provider'
      // eslint-disable-next-line max-len
      description='Select a new provider for this referral, effective immediately for new appointments created from this point forward. Existing appointments will retain the original provider until cancelled.'
      className={styles.base}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={handleSubmit}
      actions={[
        {
          label: 'Assign Provider to this Referral',
          action: 'submit',
          disabled: !selectedPractitioner || !matchingAddress,
        },
      ]}>
      <PractitionerSearch
        selectNewLocation
        npi={providerNPI}
        requestedLocation={requestLocation}
        onPractitionerSelected={setSelectedPractitioner}
        onMatchAddressChange={(address) => {
          setMatchingAddress(address);
        }}
      />
    </Modal>
  );
}
