import Modal from '../templates/Modal';
import React, { useState } from 'react';
import { useUIStore } from 'utils/hooks';
import { Icon } from 'components';
import { AlteryxApi } from 'apis';
import styles from './style.module.scss';
import { TextConsentFormLinkProps } from './TextConsentFormLink.types';
import { formatPhoneNumber } from 'utils/helper';

export default function TextConsentFormLink({
  memberId,
  patientName,
  phoneNumber,
  onClose,
  modalProps,
}: TextConsentFormLinkProps) {
  const [error, setError] = useState('');
  const { openAlert } = useUIStore();

  const handleSubmit = async () => {
    try {
      const token = await AlteryxApi.getAuthToken();
      await AlteryxApi.runConsentFormWorkflow(token, memberId);
      openAlert({
        title: `Consent Form Link Sent`,
        description: `A link has been sent via SMS to ${patientName} at ${formatPhoneNumber(phoneNumber)}`,
        severity: 'success',
      });
      onClose?.();
    } catch (e: any) {
      console.log(e);
      setError(e?.toString() || 'An error occurred while running the consent form workflow.');
    }
  };

  return (
    <Modal
      {...modalProps}
      className={styles.base}
      alert={error}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={handleSubmit}
      title={`Text Consent Form Link to ${patientName}?`}
      // eslint-disable-next-line max-len
      description='Do you want to send this patient a text message with a link to electronically sign any necessary consent forms?'
      actions={[
        {
          label: (
            <span className={styles.actionLabel}>
              <Icon name='smartphone' />
              Send Consent Form Link
            </span>
          ),
          action: 'submit',
        },
      ]}></Modal>
  );
}
