import { useState, useEffect } from 'react';
import { FilterSelectOptionType } from 'components/FilterButtonV3';
import { MedicalApi } from 'apis';
import uniqBy from 'lodash/uniqBy';

export default function useFetchFacilities() {
  const [facilities, setFacilities] = useState<Array<FilterSelectOptionType>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const nextFacilities = await MedicalApi.fetchFacilities({ all: true });
        setFacilities(uniqBy(nextFacilities, 'name').map((f) => ({ id: f.id, label: f.name, value: f.name })));
      } catch (error) {
        console.error('An error occurred while fetching the facilities', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return { facilities, loading };
}
