import { MedicalApi } from 'apis';
import { FacilityType } from 'apis/medical';
import SidePanel from 'components/SidePanel';
import FacilityV2Form from 'components/forms/FacilityV2';
import { useEffect, useState } from 'react';

export type FacilitySidePanelPropType = {
  open?: boolean;
  onClose?: () => void;
  id: string; // the patient id,
  onChange?: () => void;
};

export default function Facility({ open, onClose, id, onChange }: FacilitySidePanelPropType) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>();
  const [facility, setFacility] = useState<FacilityType | null>();
  const [editingFacility, setEditingFacility] = useState(true);

  const fetchData = async () => {
    setError(null);
    setLoading(true);
    try {
      const f = await MedicalApi.fetchFacilityById(id);
      setFacility(f);
    } catch (e) {
      setError('An error occurred while fetching the patient');
      console.error(e);
      setFacility(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    setEditingFacility(false);
  }, [facility]);

  const close = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSaveFacility = (updatedFacility?: FacilityType) => {
    setEditingFacility(false);
    if (updatedFacility) {
      setFacility(updatedFacility);
      onChange?.();
    }
  };

  return (
    <SidePanel title='VA Facilities' loading={loading} alert={error} open={open} onClose={close}>
      <FacilityV2Form
        editing={editingFacility}
        onSave={handleSaveFacility}
        onEdit={() => setEditingFacility(true)}
        onUndo={() => setEditingFacility(false)}
        onError={() => setEditingFacility(true)}
        facility={facility}
      />
    </SidePanel>
  );
}
