import React from 'react';
import cn from 'classnames';
import { useEffect, useState } from 'utils/hooks';
import styles from './style.module.scss';

export type BackdropPropType = {
  id?: string;
  closing?: boolean;
  onClick?: any;
  children?: React.ReactNode;
};

export default function Backdrop({ id, closing, onClick, children }: BackdropPropType) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    });
  }, []);

  return (
    <div id={id} className={cn(styles.backdrop, { [styles.show]: show && !closing })} onClick={onClick}>
      {children}
    </div>
  );
}
