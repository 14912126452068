import { Link } from '@mui/material';
import Grid from '@mui/material/Grid';
import { AppointmentType } from 'apis/medical';
import cn from 'classnames';
import { Form, Icon, Table } from 'components';
import Expandable from 'components/Expandable/Expandable';
import { FormProps } from 'components/Form';
import moment from 'moment';
import qs from 'qs';
import React, { MouseEventHandler, ReactElement, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { routes } from 'utils';
import styles from './style.module.scss';

type AppointmentsListProps = {
  appointments: AppointmentType[];
  expanded: boolean;
  canBookNewAppointment?: boolean;
  onBookNewAppointment?: () => void;
  onAppointmentsExpand: MouseEventHandler;
  total: number;
} & FormProps;

export default function AppointmentsList({
  total,
  expanded,
  appointments,
  canBookNewAppointment,
  onBookNewAppointment,
  onAppointmentsExpand,
  ...rest
}: AppointmentsListProps): ReactElement {
  const columns = useMemo(
    () => [
      {
        id: 'ogAppointmentId',
        label: 'OG/CMS ID',
        width: 130,
        disableColumnMenu: true,
        sortable: false,
        renderCell: ({ row }: any) => {
          return (
            <div className={cn(styles.referralCellWrapper, styles.ellipsisText)}>
              <NavLink
                to={{
                  pathname: routes.MEDICAL_APPOINTMENTS,
                  search: qs.stringify({
                    filters: { test_data: false, og_appointment_id: row?.ogAppointmentId },
                    page: 0,
                    pageSize: 50,
                  }),
                }}>
                <span className={styles.ellipsisText}>{row?.ogAppointmentId}</span>
              </NavLink>
            </div>
          );
        },
      },
      {
        id: 'start',
        label: 'Date/Time',
        width: 160,
        renderCell: ({ row }: any) => (row?.start ? moment(row.start).format('MM/DD/YY h:mm A') : ''),
      },
      { id: 'patient_name', label: 'Patient', width: 150, disableColumnMenu: true, sortable: false },
      {
        id: 'practitioner_name',
        label: 'Provider',
        width: 150,
        disableColumnMenu: true,
        sortable: false,
        renderCell: ({ row }: any) => {
          return (
            <div className={cn(styles.referralCellWrapper, styles.ellipsisText)}>
              <Icon
                name={row?.office_based ? 'building' : 'house'}
                title={row?.office_based ? 'in-office' : 'in-home'}
                size={16}
              />
              <span className={styles.ellipsisText}>{row?.practitioner_name}</span>
            </div>
          );
        },
      },
      { id: 'og_stage', label: 'Status', width: 100, disableColumnMenu: true, sortable: false },
    ],
    []
  );

  const showMoreText = useMemo(() => {
    if (total > 3) {
      if (!expanded) {
        return '+' + (total - 3) + ' More';
      } else {
        return 'Show Less';
      }
    } else {
      return;
    }
  }, [expanded, total]);

  const includeFooter = useMemo(() => {
    return total > 3;
  }, [total]);

  return (
    <Form title={'Appointments'} icon={'calendar'} {...rest}>
      <Grid container item>
        {canBookNewAppointment && (
          <Link color='inherit' className={styles.bookNewAppointment} onClick={onBookNewAppointment}>
            + Book New Appointment
          </Link>
        )}
        <Expandable
          expanded={expanded}
          onFooterClicked={includeFooter ? onAppointmentsExpand : undefined}
          footerText={showMoreText}>
          <Table autoHeight columns={columns} rows={appointments} footer={false} />
        </Expandable>
      </Grid>
    </Form>
  );
}
