import React, { useState, useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import cn from 'classnames';
import { CommentType } from 'apis/medical';
import { useForm, useAuth } from 'utils/hooks';
import styles from './style.module.scss';
import { Icon, FieldTitle, TextField, Button } from 'components';

import { CommentsProps } from './Comments.types';
import moment from 'moment';

export default function Comments({
  className,
  comments,
  onAddComment,
  onDeleteComment,
  onEditComment,
  defaultVisibleComments = 2,
}: CommentsProps) {
  const { user } = useAuth();
  const userMemberId = user?.memberId;
  const [addingComment, setAddingComment] = useState<boolean>(false);
  const [editingComment, setEditingComment] = useState<CommentType | null>();
  const [showAllComments, setShowAllComments] = useState<boolean>(false);
  const { bind, form } = useForm({
    comment: '',
  });
  const hasOlderComments = (comments || []).length > defaultVisibleComments;

  const startAddingComment = async () => {
    if (editingComment && form.getField('comment')?.trim()) {
      const result = await window.confirm(
        'You are currently editing a comment. Your changes will be lost if you start adding a new comment. Are you sure you want to proceed?' // eslint-disable-line max-len
      );
      if (!result) return;
    }
    form.resetField('comment');
    setEditingComment(null);
    setAddingComment(true);
  };

  const addComment = async () => {
    const newComment = form.getField('comment');
    await onAddComment?.(newComment);
    setAddingComment(false);
    form.resetField('comment');
  };

  const startEditingComment = async (comment: any) => {
    if (addingComment && form.getField('comment')?.trim()) {
      const result = await window.confirm(
        'You are currently adding a new comment. Your changes will be lost if you start editing another comment. Are you sure you want to proceed?' // eslint-disable-line max-len
      );
      if (!result) return;
    }
    setAddingComment(false);
    setEditingComment(comment);
    form.setField('comment', comment?.text);
  };

  const editComment = async () => {
    if (!editingComment?.id) return;
    const updatedComment = form.getField('comment');
    await onEditComment?.(editingComment?.id, updatedComment);
    setEditingComment(null);
    form.resetField('comment');
  };

  const deleteComment = async (commentId: string) => {
    const result = await window.confirm('Are you sure you want to delete this comment? This action is irreversible.');
    if (!result) return;
    await onDeleteComment?.(commentId);
    setAddingComment(false);
  };

  const commentInputProps = {
    variant: 'outlined',
    size: 'small',
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
  };

  const sortedComments = useMemo(() => {
    return orderBy(comments, ['dateCreated'], ['desc']);
  }, [comments]);

  return (
    <div className={cn(styles.base, className)}>
      <FieldTitle
        icon='chat-bubble'
        margin
        rightContent={
          !addingComment && (
            <p className={styles.addCommentLink} onClick={() => startAddingComment()}>
              Add Comment
              <Icon className={styles.icon} size={18} name='add-circle' />
            </p>
          )
        }>
        Comments
      </FieldTitle>
      {addingComment && (
        <div className={styles.newComment}>
          <TextField
            {...bind('comment')}
            placeholder={'Add a comment...'}
            className={styles.input}
            {...commentInputProps}
          />
          <div>
            <Button
              state='primary'
              className={styles.button}
              disabled={!form.getField('comment')?.trim()}
              onClick={addComment}>
              Save
            </Button>

            <Button state='secondary' className={styles.button} onClick={() => setAddingComment(false)}>
              Cancel
            </Button>
          </div>
        </div>
      )}
      <div className={styles.commentsContainer}>
        {sortedComments?.length === 0 && <p className={styles.noComments}>No comments</p>}
        {(showAllComments ? sortedComments : sortedComments.slice(0, defaultVisibleComments))?.map((comment: any) => {
          const { id: commentId, dateEdited, dateCreated, memberId, memberName, text } = comment;
          const edited = dateEdited !== dateCreated;
          const isEditing = editingComment && editingComment?.id == commentId;
          const isSelf = userMemberId == memberId;
          return (
            <div key={commentId} className={cn(styles.comment, { [styles['comment--self']]: isSelf })}>
              <p className={styles.topper}>
                <span className={styles.name}>{memberName}</span>
                {`${edited ? 'Edited ' : ''}${moment(dateEdited).format('LLL')}`}
              </p>
              {isEditing && (
                <>
                  <div className={styles.editComment}>
                    <TextField
                      {...bind('comment')}
                      placeholder={'Edit comment...'}
                      className={styles.input}
                      {...commentInputProps}
                    />
                    <div>
                      <Button
                        state='primary'
                        className={styles.button}
                        disabled={!form.getField('comment')?.trim()}
                        onClick={editComment}>
                        Update
                      </Button>

                      <Button state='secondary' className={styles.button} onClick={() => setEditingComment(null)}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </>
              )}
              {!isEditing && (
                <>
                  <p className={styles.text}>{text}</p>
                  {isSelf && (
                    <div className={styles.actions}>
                      <Icon
                        className={styles.icon}
                        size={25}
                        name='edit-pencil'
                        onClick={() => startEditingComment(comment)}
                      />
                      <Icon className={styles.icon} size={25} name='trash' onClick={() => deleteComment(commentId)} />
                    </div>
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>

      {hasOlderComments && (
        <p className={styles.viewOlderLink} onClick={() => setShowAllComments(!showAllComments)}>
          {showAllComments
            ? 'Hide Older Comments'
            : `View (${(comments || []).length - defaultVisibleComments}) Older Comments`}
        </p>
      )}
    </div>
  );
}
