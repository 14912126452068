import { createUseStyles } from 'react-jss';
import { grey2, grey4, midnight } from '@zeel-dev/ui-styles';

export const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
    position: 'absolute',
    top: '56px',
    background: 'white',
    boxShadow: `0 1px 3px 1px ${grey2}`,
    zIndex: '10',
    borderRadius: '6px',
    padding: '0',
    margin: '0',
  },
  listItem: {
    listStyle: 'none',
    backgroundImage: 'none',
    backgroundRepeat: 'none',
    backgroundPosition: 0,
    height: '48px',
    fontSize: '16px',
    lineHeight: '48px',
    color: midnight,
    padding: '0 15px',
    userSelect: 'none',
    cursor: 'pointer',
    '&:hover': {
      background: grey4,
      color: 'white',
    },
  },
  noResults: {
    listStyle: 'none',
    backgroundImage: 'none',
    backgroundRepeat: 'none',
    backgroundPosition: 0,
    height: '48px',
    fontSize: '16px',
    lineHeight: '48px',
    color: grey4,
    padding: '0 15px',
    userSelect: 'none',
  },
  searching: {
    height: '48px',
    fontSize: '16px',
    lineHeight: '48px',
    color: grey4,
    padding: '0 15px',
    userSelect: 'none',
    '& svg': {
      top: '2px',
    },
  },
});
