import React, { ReactElement, useMemo } from 'react';
import { Field, Formik } from 'formik';
import Form from 'components/Form';
import Grid from '@mui/material/Grid';
import { TextField, CheckboxWithLabel } from 'formik-mui';
import { getInputReadOnlyProps } from 'utils/helper';
import { ServiceRequestType } from 'apis/medical';
import { ClipboardIcon } from '@zeel-dev/zeel-ui';

type ClinicalRationaleForExtensionFormProps = {
  serviceRequest: ServiceRequestType;
};

export default function ClinicalRationaleForExtensionForm({
  serviceRequest,
}: ClinicalRationaleForExtensionFormProps): ReactElement {
  const inputReadOnlyProps = useMemo(() => {
    return getInputReadOnlyProps();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        duration_ext_multiple_body_parts: serviceRequest?.duration_ext_multiple_body_parts,
        duration_ext_multiple_body_parts_details: serviceRequest?.duration_ext_multiple_body_parts_details,
        duration_ext_regions: !!serviceRequest?.duration_ext_regions,
        duration_ext_regions_details: serviceRequest?.duration_ext_regions_details,
        duration_ext_symptoms: !!serviceRequest?.duration_ext_symptoms,
        duration_ext_symptoms_details: serviceRequest?.duration_ext_symptoms_details,
        duration_ext_other: !!serviceRequest?.duration_ext_other,
        duration_ext_other_details: serviceRequest?.duration_ext_other_details,
      }}
      onSubmit={() => undefined}>
      {({ values }) => (
        <Form
          icon={<ClipboardIcon size={30} />}
          title='Clinical Rationale for Extension'
          editable={false}
          marginBottom
          collapsible>
          {values.duration_ext_multiple_body_parts && (
            <>
              <Grid item xs={12}>
                <Field
                  component={CheckboxWithLabel}
                  name='duration_ext_multiple_body_parts'
                  type='checkbox'
                  Label={{ label: 'Multiple body parts require treatment as indicated by the provisional diagnosis' }}
                  onChange={undefined}
                />
              </Grid>
              <Grid item xs={12} marginLeft={4}>
                <Field
                  component={TextField}
                  multiline
                  type='text'
                  fullWidth
                  label='Which specific body parts?'
                  name='duration_ext_multiple_body_parts_details'
                  {...inputReadOnlyProps}
                />
              </Grid>
            </>
          )}
          {values.duration_ext_regions && (
            <>
              <Grid item xs={12}>
                <Field
                  component={CheckboxWithLabel}
                  name='duration_ext_regions'
                  type='checkbox'
                  Label={{
                    label:
                      // eslint-disable-next-line max-len
                      'Regions above and below the primary treatment area require concurrent treatment to maximize patient function',
                  }}
                  onChange={undefined}
                />
              </Grid>
              <Grid item xs={12} marginLeft={4}>
                <Field
                  component={TextField}
                  multiline
                  type='text'
                  fullWidth
                  label='Which regions and muscles?'
                  name='duration_ext_regions_details'
                  {...inputReadOnlyProps}
                />
              </Grid>
            </>
          )}
          {values.duration_ext_symptoms && (
            <>
              <Grid item xs={12}>
                <Field
                  component={CheckboxWithLabel}
                  name='duration_ext_symptoms'
                  type='checkbox'
                  Label={{
                    label:
                      // eslint-disable-next-line max-len
                      "Due to the complex nature of the patient's symptoms, extra time is required to properly address the issues",
                  }}
                  onChange={undefined}
                />
              </Grid>
              <Grid item xs={12} marginLeft={4}>
                <Field
                  component={TextField}
                  multiline
                  type='text'
                  label="Patient's symptoms that require them to need more time..."
                  name='duration_ext_symptoms_details'
                  fullWidth
                  {...inputReadOnlyProps}
                />
              </Grid>
            </>
          )}
          {values.duration_ext_other && (
            <>
              <Grid item xs={12}>
                <Field
                  component={CheckboxWithLabel}
                  name='duration_ext_other'
                  type='checkbox'
                  Label={{
                    label: 'Other',
                  }}
                  onChange={undefined}
                />
              </Grid>
              <Grid item xs={12} marginLeft={4}>
                <Field
                  component={TextField}
                  multiline
                  type='text'
                  label='Details'
                  name='duration_ext_other_details'
                  fullWidth
                  {...inputReadOnlyProps}
                />
              </Grid>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}
