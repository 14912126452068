import React, { ReactElement, useMemo } from 'react';
import { Field, Formik } from 'formik';
import Form from 'components/Form';
import Grid from '@mui/material/Grid';
import { TextField as CustomTextField } from 'components';
import { TextField } from 'formik-mui';
import Persona from '../Persona/Persona';
import { useStyles } from './PractitionerForm.styles';
import { formatAddress, getInputReadOnlyProps } from 'utils/helper';
import { PractitionerType } from 'apis/practitioner';

type ProviderFormProps = {
  practitioner: PractitionerType;
};

export default function PractitionerForm(props: ProviderFormProps): ReactElement {
  const { practitioner } = props;
  const classes = useStyles();

  const inputReadOnlyProps = useMemo(() => {
    return getInputReadOnlyProps(classes.sidePanelInput);
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: practitioner.email,
        phone: practitioner.phone,
        npi: practitioner.npi,
        networkStatus: practitioner.network_status,
        practitionerId: practitioner.id,
        recentAppointmentLocation: practitioner.service_area,
        address: formatAddress(practitioner.address?.[0]),
        unitNumber: practitioner.address?.[0]?.line?.[1] || '',
        validatedProvider: practitioner.validated_provider ? 'ON' : 'OFF',
        automatedReviewThreshold: `${practitioner.automated_review_threshold || 5} submissions`,
      }}
      onSubmit={() => undefined}>
      {({ values }) => (
        <Form title='Practitioner' icon='user' editable={false} marginBottom>
          <Grid item xs={12}>
            <Persona name={practitioner.flattenName} imageUrl={practitioner.photo} />
          </Grid>
          <Grid item xs={6}>
            <Field component={TextField} type='text' label='Practitioner Email' name='email' {...inputReadOnlyProps} />
          </Grid>
          <Grid item xs={6}>
            <Field component={TextField} type='text' label='Practitioner Phone' name='phone' {...inputReadOnlyProps} />
          </Grid>
          <Grid item xs={6}>
            <Field component={TextField} type='text' label='Practitioner NPI' name='npi' {...inputReadOnlyProps} />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              label='Network Status'
              name='networkStatus'
              {...inputReadOnlyProps}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              label='Practitioner ID'
              name='practitionerId'
              {...inputReadOnlyProps}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              label='Recent Appointment Location'
              name='recentAppointmentLocation'
              {...inputReadOnlyProps}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField name='address' label='Address' multiline value={values.address} {...inputReadOnlyProps} />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              label='Unit Number (Optional)'
              name='unitNumber'
              {...inputReadOnlyProps}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              label='Auto-Approval of SOAP Notes'
              name='validatedProvider'
              {...inputReadOnlyProps}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              type='text'
              label='Manual Review Entry'
              name='automatedReviewThreshold'
              {...inputReadOnlyProps}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
