import React from 'react';
import { useState, useForm, useUIStore } from 'utils/hooks';
import Modal, { ModalPropType } from '../templates/Modal';
import { ZrefApi } from 'apis';
import Grid from '@mui/material/Grid';
import { TextField } from 'components';
import styles from './style.module.scss';

export type SubmitRFSProps = ModalPropType & {
  serviceRequestId: string;
  signatoryName: string;
};

export default function SubmitRFS({ serviceRequestId, signatoryName, scrollTop, onClose, modalProps }: SubmitRFSProps) {
  const { openAlert } = useUIStore();
  const { bind, form } = useForm();
  const [error, setError] = useState<string>();

  const onSubmit = async (values: any) => {
    const { progressToDate, reason } = values;
    try {
      await ZrefApi.submitRFS(serviceRequestId, signatoryName, progressToDate, reason);
      openAlert({ title: 'Submitted the RFS successfully!', severity: 'success' });
      onClose?.(true);
    } catch (e: any) {
      setError(e?.toString() || 'An error occurred while submitting the RFS');
      scrollTop();
      console.error(e);
    }
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'filled',
    InputProps: {
      disableUnderline: true,
    },
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <Modal
      {...modalProps}
      alert={error}
      title='Submit a RFS'
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={form.handleSubmit(onSubmit)}
      actions={[
        { label: 'Back', action: 'close' },
        { label: 'Submit', action: 'submit' },
      ]}>
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}>
          <TextField
            {...bind('progressToDate', { required: true })}
            label='Initial Limitations and Progress'
            {...fieldProps}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField {...bind('reason', { required: true })} label='Reasons for Additional Services' {...fieldProps} />
        </Grid>
      </Grid>
    </Modal>
  );
}
