import { useSelector } from '.';
import { isAuthenticated, selectUser, selectToken } from 'store/Auth';
import { OGMemberType } from '../../apis/auth';

export default function useAuth() {
  const isAuth = useSelector(isAuthenticated);
  const user: {
    memberId: string;
    roles: string[];
    pccAgentName: string;
  } & OGMemberType = useSelector(selectUser);
  const token = useSelector(selectToken);
  return {
    isAuthenticated: isAuth,
    user,
    token,
  };
}
