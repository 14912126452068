import { FC } from 'react';
import moment from 'moment';
import { getCalendarTime } from 'utils/helper';
import Icon from '../../Icon';
import { ActionNoteProps } from './Notes.types';
import styles from './style.module.scss';

const ActionNote: FC<ActionNoteProps> = ({ note, onDelete }) => (
  <div>
    <div className={styles.details}>
      {note.pcc_agent && <span className={styles.name}>{note.pcc_agent}</span>}
      <span className={styles.time}>
        {getCalendarTime(note.timestamp, true)}, {moment.utc(note.timestamp).local().format('h:mma z')}
      </span>
      <Icon name='trash' size={16} onClick={onDelete} />
    </div>
    <p className={styles.note}>{note.text}</p>
  </div>
);

export default ActionNote;
