import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { routes } from 'utils';
import { UIStore } from 'store';
import { useSelector, useDispatch } from 'utils/hooks';
import { Icon } from 'components';

import styles from './style.module.scss';

export default function Menu() {
  const isMenuToggled = useSelector(UIStore.isMenuOpened);
  const isMenuExpanded = useSelector(UIStore.isMenuExpanded);
  const dispatch = useDispatch();

  const menus = [
    { icon: 'house', label: 'Home', route: routes.HOME },
    // { icon: 'calendar', label: 'Appointments', route: routes.APPOINTMENTS },
    // { icon: 'user', label: 'Providers', route: routes.PROVIDERS },
    // { icon: 'orientation-card', label: 'Customers', route: routes.CUSTOMERS },
    // { icon: 'spa-flower', label: 'Businesses', route: routes.BUSINESSES },
    { icon: 'medical-cross-circle', label: 'Medical', route: routes.MEDICAL },
    // { icon: 'gear', label: 'Settings', route: routes.SETTINGS },
  ];

  return (
    <div
      className={cn(styles.menu, {
        [styles['menu--hidden']]: !isMenuToggled,
        [styles['menu--expanded']]: isMenuExpanded,
      })}>
      <div className={styles.inner}>
        <ul>
          {menus.map((menu) => {
            const { icon, label, route } = menu;
            return (
              <NavLink key={label} to={route} activeClassName={styles.activeLink}>
                <li>
                  <Icon name={icon} className={styles.icon} />
                  <p>{label}</p>
                </li>
              </NavLink>
            );
          })}
        </ul>
      </div>
      <Icon className={styles.expandIcon} name='caret-right' onClick={() => dispatch(UIStore.toggleMenuExpansion())} />
    </div>
  );
}
