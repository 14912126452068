import React, { useCallback } from 'react';
import { history, routes } from 'utils';
import { useState, useEffect, useUIStore, useCombineFiles, useAuth } from 'utils/hooks';
import { MedicalApi, ZCCApi } from 'apis';
import { PatientHoldKeys, PatientHoldStatus, PatientType } from 'apis/medical';

import PatientForm from 'components/forms/Patient';
import { Button, SidePanel, ServiceRequestList, ServiceRequestFiles, FieldTitle, Icon } from 'components';
import Link from '@mui/material/Link';
import styles from './style.module.scss';
import { joinString } from 'utils/helper';
import moment from 'moment';

export type PatientSidePanelPropType = {
  open?: boolean;
  onClose?: () => void;
  id: string; // the patient id,
  onChange?: () => void;
};

export default function Patient({ open, onClose, id, onChange }: PatientSidePanelPropType) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>();
  const [patient, setPatient] = useState<PatientType | null>();
  const { openModal, openAlert } = useUIStore();
  const { user } = useAuth();

  const fetchData = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const p = await MedicalApi.fetchPatient(id);
      setPatient(p);
    } catch (e) {
      setError('An error occurred while fetching the patient');
      console.error(e);
      setPatient(null);
    }
    setLoading(false);
  }, [id]);

  const { openCombineFiles } = useCombineFiles({
    serviceRequest: patient?.service_requests?.[0],
    patient,
    onError: (e) => {
      openAlert({ title: e, severity: 'error' });
    },
    downloadOnSuccess: true,
  });

  // fetch data on mount
  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const close = () => {
    if (onClose) {
      onClose();
    }
  };

  const openLinkPatientToMember = useCallback(
    (memberId: any = null, memberName?: string | null) => {
      openModal({
        id: 'link-patient-to-member',
        props: {
          patientId: patient?.id,
          memberId,
          memberName,
        },
        callback: () => {
          fetchData();
        },
      });
    },
    [patient, fetchData]
  );

  const openCreateMemberAccountModal = useCallback(() => {
    openModal({
      id: 'create-member-account',
      props: {
        email: patient?.email,
        firstName: (patient?.given_names || []).join(' '),
        lastName: patient?.family_name,
        mobile: patient?.phoneMobile || patient?.phone,
        zip: patient?.address?.[0]?.postal_code,
        birthdate: patient?.birth_date,
        gender: patient?.gender,
        autoGeneratePassword: true,
      },
      callback: (createdMember: any) => {
        if (createdMember) {
          const fullName =
            createdMember?.fname && createdMember?.lname ? `${createdMember.fname} ${createdMember.lname}` : null;
          openLinkPatientToMember(createdMember?.id, fullName);
        }
      },
    });
  }, [patient, openLinkPatientToMember]);

  const openPutPatientOnHoldModal = async (patientId: string, patientName: string) => {
    openModal({
      id: 'put-patient-on-hold',
      props: {
        patientId,
        patientName,
      },
      callback: (res: any) => {
        if (res) {
          putActionNoteForOnHold(res);
        }
      },
    });
  };

  const openRemovePatientHoldModal = async (patientId: string, patientName: string, holdType?: string) => {
    openModal({
      id: 'remove-patient-hold',
      props: {
        patientId,
        patientName,
        holdType,
      },
      callback: ({ shouldRemove, reason }) => {
        if (shouldRemove) {
          putActionNoteForRemovingHold(reason);
        }
      },
    });
  };

  const addActionNoteForHold = async (text: string) => {
    const notes = await ZCCApi.fetchNotesFromPatient(id);
    const noteAction = notes.actionNote?.length ? ZCCApi.updateNote : ZCCApi.createNote;

    await noteAction({
      note: [
        {
          text,
          pcc_agent: user?.pccAgentName,
        },
        ...(notes.actionNote || []),
      ],
      noteType: 'action',
      patientId: id,
    });
  };

  const putActionNoteForOnHold = async (res: any) => {
    const { hold_status, hold_status_reason, hold_status_date } = res;

    const patientName = joinString([...(patient?.given_names || []), patient?.family_name]);

    const text = `${PatientHoldStatus[hold_status as PatientHoldKeys]} - ${
      hold_status_date ? moment(hold_status_date).format('MM/DD/YYYY') : 'indefinite'
    }${hold_status_reason ? ` - ${hold_status_reason}` : ''}`;

    try {
      await addActionNoteForHold(text);

      openAlert({
        title: `Patient on Hold${hold_status_date ? '' : ' Indefinitely'}`,
        description: `You'll need to manually resume scheduling for ${patientName}`,
      });
      await fetchData();
    } catch {
      openAlert({ title: `Failed to save the action note`, severity: 'error' });
    }
  };

  const putActionNoteForRemovingHold = async (reason?: string) => {
    const text = reason ? `Patient Hold Removed - ${reason}` : 'Patient Hold Removed';

    try {
      await addActionNoteForHold(text);

      openAlert({
        title: 'Patient Hold Removed',
      });
      await fetchData();
    } catch {
      openAlert({ title: `Failed to save the action note`, severity: 'error' });
    }
  };

  const openServiceRequest = (referralNumber: string) => {
    history.push(`${routes.MEDICAL_REFERRALS}?filters[va_referral_id]=${referralNumber}`);
    // dispatch(UIStore.openModal({
    //   id: 'info',
    //   props: {
    //     title: serviceRequest?.referral_number,
    //     content: <>
    //       <p>id: {serviceRequest?.id}</p>
    //       <p>expires: {serviceRequest?.expiration_date}</p>
    //       <p>quantity: {serviceRequest?.quantity?.available}/{serviceRequest?.quantity?.value} remaining</p>
    //     </>
    //   },
    // }));
  };

  // const openBookAppointmentForVeteran = () => {
  //   if (!patient?.member_id) return;

  //   openModal({
  //     id: 'book-appointment-for-patient',
  //     props: {
  //       memberId: patient?.member_id,
  //       bookingFlowUrl: (OGMember: any) => {
  //         return `${process.env.REACT_APP_VA_FLOW_BOOK_URL}?fromZcc=1&email=${encodeURIComponent(OGMember?.email)}`;
  //       },
  //     },
  //     callback: () => {
  //       fetchData();
  //     },
  //   });
  // };

  const openUploadConsentModal = useCallback(() => {
    openModal({
      id: 'upload-consent',
      props: {
        patientId: id,
      },
      callback: (res: any) => {
        if (res) {
          fetchData();
        }
      },
    });
  }, [id, fetchData]);

  const openTextConsentFormLinkModal = useCallback(() => {
    openModal({
      id: 'text-consent-form-link',
      props: {
        memberId: patient?.member_id,
        patientName: joinString([...(patient?.given_names || []), patient?.family_name]),
        phoneNumber: patient?.phone,
      },
    });
  }, [patient]);

  const openBookNewAppointmentModal = useCallback(() => {
    openModal({
      id: 'referral-picker',
      props: {
        patient,
      },
      callback: (srId: any) => {
        if (srId) {
          openModal({
            id: 'book-appointment',
            props: {
              serviceRequestId: srId,
              ogMemberId: patient?.member_id,
            },
            callback: (res: boolean) => {
              if (res) {
                fetchData();
              }
            },
          });
        }
      },
    });
  }, [patient, fetchData]);

  return (
    <SidePanel
      title='Patient Record'
      loading={loading}
      alert={error}
      open={open}
      onClose={close}
      menuItems={
        !error
          ? [
              {
                icon: 'download',
                label: 'Download Patient Records',
                onClick: () => openCombineFiles(),
              },
              ...(!patient?.hold_status
                ? [
                    {
                      icon: 'calendar-appointment-pause',
                      label: 'Put Patient on Hold',
                      onClick: () =>
                        openPutPatientOnHoldModal(
                          id,
                          joinString([...(patient?.given_names || []), patient?.family_name])
                        ),
                    },
                  ]
                : [
                    {
                      icon: 'calendar-checkmark',
                      label: 'Remove Patient Hold',
                      onClick: () =>
                        openRemovePatientHoldModal(
                          id,
                          joinString([...(patient?.given_names || []), patient?.family_name]),
                          PatientHoldStatus[patient?.hold_status as PatientHoldKeys]
                        ),
                    },
                  ]),
              ...(patient?.member_id
                ? [
                    {
                      icon: 'calendar',
                      label: 'Book New Appointment',
                      onClick: () => openBookNewAppointmentModal(),
                    },
                  ]
                : []),
            ]
          : []
      }>
      <PatientForm patient={patient} editable onSavePatient={() => onChange?.()} />
      <ServiceRequestList serviceRequests={patient?.service_requests} onClick={openServiceRequest} />
      <ServiceRequestFiles
        title='Consent Forms'
        serviceRequests={patient?.service_requests || undefined}
        actionText='Upload Consent Forms'
        onActionClick={() => openUploadConsentModal()}
        filter={(d: any) => d?.type === 'consent'}
        condensed
      />
      {patient?.member_id && (
        <div className={styles.textConsentFormLink}>
          <Link color='inherit' onClick={() => openTextConsentFormLinkModal()}>
            <Icon name='smartphone' />
            Text Consent Form Link to Patient
          </Link>
        </div>
      )}
      <FieldTitle margin className={styles.actionTitle}>
        Actions
      </FieldTitle>
      <div className={styles.actions}>
        <Button
          variant='outlined'
          color='primary'
          size='large'
          disabled={patient?.member_id}
          onClick={() => openCreateMemberAccountModal()}>
          Create Zeel Member account
        </Button>
        <Button
          variant='outlined'
          color='primary'
          size='large'
          disabled={patient?.member_id}
          onClick={() => openLinkPatientToMember()}>
          Link Patient to Member
        </Button>
        <Button
          variant='outlined'
          color='primary'
          size='large'
          disabled={!patient?.member_id}
          onClick={() => openBookNewAppointmentModal()}>
          Book VA appointment
        </Button>
        {!loading && !patient?.member_id && (
          <p className={styles.behalfVetWarning}>
            You can book a VA appointment on behalf of a veteran only when he/she has a zeel account
          </p>
        )}
      </div>
    </SidePanel>
  );
}
