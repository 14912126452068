import React, { useMemo } from 'react';
import { MedicalApi } from 'apis';
import { useState, useForm, useUIStore } from 'utils/hooks';
import Modal, { ModalPropType } from '../templates/Modal';
import moment from 'moment';
import { TextField } from 'components';
import styles from './style.module.scss';

export type UpdateReferralExpirationDateProps = ModalPropType & {
  serviceRequestId: string;
  expirationDate: string;
};

export default function UpdateReferralExpirationDate({
  serviceRequestId,
  expirationDate,
  onClose,
  modalProps,
}: UpdateReferralExpirationDateProps) {
  const [error, setError] = useState<string | null>();
  const { openAlert } = useUIStore();
  const { bind, form } = useForm({
    expirationDate: null,
  });

  const updateExpirationDate = async (values: any) => {
    try {
      await MedicalApi.updateServiceRequest(serviceRequestId, {
        expiration_date: values?.expirationDate,
      });

      openAlert({ title: 'Expiration date updated' });
      onClose?.(values?.expirationDate);
    } catch (e) {
      setError('An error occured while updating expiration date');
      console.error(e);
    }
  };

  const expirationFieldProps = useMemo(
    () => ({
      fullWidth: true,
      variant: 'outlined',
      InputLabelProps: {
        shrink: true,
      },
    }),
    []
  );

  return (
    <Modal
      {...modalProps}
      alert={error}
      title='Update referral’s expiration date'
      className={styles.base}
      actions={[{ label: 'Submit', action: 'submit' }]}
      onSubmit={form.handleSubmit(updateExpirationDate)}>
      <TextField
        {...bind('expirationDate', {
          required: true,
          validate: (val: any) => {
            if (val && moment(val, 'YYYY-MM-DD').diff(moment()) < 0) return 'Needs to be greater than the issue date';
            return null;
          },
        })}
        label='New Expiration Date'
        type='date'
        {...expirationFieldProps}
      />
      <p className={styles.currentExpDateHelper}>
        Current expiration date is {moment(expirationDate).format('MM/DD/YYYY')}
      </p>
    </Modal>
  );
}
