import { createUseStyles } from 'react-jss';
import { midnight } from '@zeel-dev/ui-styles';
import { PersonaProps } from './Persona';

export const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  imageWrapper: {
    borderRadius: '100%',
    width: '80px',
    height: '80px',
  },
  image: (props: PersonaProps) => ({
    backgroundImage: `url("${props.imageUrl}")`,
    borderRadius: '100%',
    width: '80px',
    height: '80px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }),
  nameWrapper: {
    color: midnight,
    fontSize: '18px',
    fontWeight: '700',
    paddingLeft: '20px',
  },
});
