import React from 'react';
import { Link } from 'react-router-dom';
import { history, routes } from 'utils';
import { /*useForm,*/ useAuth, useDispatch } from 'utils/hooks';
import { AuthStore, UIStore } from 'store';
import { Icon } from 'components';
import logo from 'assets/logo.svg';

import styles from './style.module.scss';

export default function Header() {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const logout = async () => {
    await dispatch(AuthStore.logout());
  };
  // const { bind/*, form*/ } = useForm({});

  return (
    <div className={styles.navigation}>
      <div className={styles.left}>
        <Icon name='hamburger-menu' className={styles.hamburger} onClick={() => dispatch(UIStore.toggleMenu())} />
        <img onClick={() => history.push(routes.HOME)} src={logo} className={styles.logo} alt='logo' />
      </div>
      <div className={styles.middle}>
        {/* <TextField
          {...bind('search')}
          className={styles.searchInput}
          size="small"
          type="search"
          variant="outlined"
          placeholder="Search CMS"
          InputProps={{
            style: { fontSize: 16, minWidth: '550px' },
            startAdornment: (
              <InputAdornment position="start">
                <Icon color="grey-5" name="search" />
              </InputAdornment>
            ),
          }}
        /> */}
      </div>
      <div className={styles.right}>
        <ul>
          <li>
            {isAuthenticated ? (
              <p className={styles.logout} onClick={logout}>
                Logout
              </p>
            ) : (
              <Link to={routes.LOGIN}>Login</Link>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}
