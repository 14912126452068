import { FC, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import { useAuth, useUIStore } from 'utils/hooks';
import { ZCCApi } from 'apis';
import { ZccNote } from 'apis/zcc';
import FieldTitle from '../../FieldTitle';
import Note from './Note';
import ActionNote from './ActionNote';
import { ActionTakenNotesProps } from './Notes.types';
import styles from './style.module.scss';

const ActionTakenNotes: FC<ActionTakenNotesProps> = ({ notes: originalNotes, patientId, id, onChange }) => {
  const [notes, setNotes] = useState<ZccNote[] | undefined>(originalNotes);
  const [editingNewNote, setEditingNewNote] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const { user } = useAuth();
  const { openAlert } = useUIStore();

  useEffect(() => {
    setEditingNewNote(false);
    setNotes(originalNotes);
  }, [originalNotes]);

  useEffect(() => {
    setEditingNewNote(false);
  }, [id]);

  const handleSaveNewNote = async (newNoteText: string) => {
    if (!patientId) {
      return;
    }
    setEditingNewNote(false);
    const prevNotes = notes ? [...notes] : undefined;
    try {
      const pccAgentName = user?.pccAgentName;
      const noteAction = notes?.length ? ZCCApi.updateNote : ZCCApi.createNote;
      setNotes([
        {
          text: newNoteText,
          timestamp: new Date().toUTCString(),
          pcc_agent: pccAgentName,
        },
        ...(notes || []),
      ]);
      await noteAction({
        note: [
          {
            text: newNoteText,
            pcc_agent: pccAgentName,
          },
          ...(notes || []),
        ],
        noteType: 'action',
        patientId,
      });
      onChange?.();
    } catch {
      openAlert({ title: `Failed to save the action note`, severity: 'error' });
      setNotes(prevNotes);
    }
  };

  const handleDeleteNote = async (noteIndex: number) => {
    if (!patientId) {
      return;
    }
    const prevNotes = [...(notes || [])];
    try {
      const newNotes = [...prevNotes];
      newNotes.splice(noteIndex, 1);
      setNotes(newNotes);
      await ZCCApi.updateNote({
        note: newNotes,
        noteType: 'action',
        patientId,
      });
    } catch {
      openAlert({ title: `Failed to delete the action note`, severity: 'error' });
      setNotes(prevNotes);
    }
  };

  return (
    <div>
      <FieldTitle icon='bullet-list' margin>
        Action Taken
      </FieldTitle>
      <Note
        label='Add an Action Taken...'
        note={''}
        saveButtonLabel='Save'
        showActions={editingNewNote}
        onSave={handleSaveNewNote}
        onClick={() => setEditingNewNote(true)}
        onCancel={() => setEditingNewNote(false)}
      />
      {notes?.slice(0, 2).map((note: ZccNote, index: number) => (
        <ActionNote key={index} note={note} onDelete={() => handleDeleteNote(index)} />
      ))}
      {notes && notes.length > 2 && (
        <>
          <Collapse in={!collapsed}>
            {notes?.slice(2).map((note: ZccNote, index: number) => (
              <ActionNote key={index} note={note} onDelete={() => handleDeleteNote(index)} />
            ))}
          </Collapse>
          <Button className={styles.expandBtn} onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? 'View' : 'Hide'} {notes.length - 2} Older Action{notes.length > 3 ? 's' : ''} Taken
          </Button>
        </>
      )}
    </div>
  );
};

export default ActionTakenNotes;
