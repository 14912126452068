import { ReactNode } from 'react';
import { UIStore } from 'store';
import { useDispatch } from 'react-redux';
import modals from 'components/modals/map';

type AlertHookProps = {
  id?: string;
  title: string;
  description?: ReactNode;
  severity?: 'success' | 'info' | 'warning' | 'error' | undefined;
  duration?: number;
  onClose?: () => void;
};

export default function useUIStore() {
  const dispatch = useDispatch();

  const openAlert = ({ id, title, description, severity, duration }: AlertHookProps) => {
    dispatch(UIStore.openAlert({ id, title, description, severity, duration }));
  };

  const closeAlert = (id: string) => {
    dispatch(UIStore.closeAlert(id));
  };

  const openModal = ({
    id,
    component,
    props,
    callback,
  }: {
    id: keyof typeof modals;
    component?: React.FC;
    props?: unknown;
    callback?: (response?: any) => void;
  }) => {
    dispatch(UIStore.openModal({ id, component, props, callback }));
  };

  const closeModal = ({ id, result }: { id: keyof typeof modals; result?: any }) => {
    dispatch(UIStore.closeModal({ id, result }));
  };

  return {
    openAlert,
    closeAlert,
    openModal,
    closeModal,
  };
}
