import { FC, useState, useCallback } from 'react';
import Modal from '../templates/Modal';
import styles from './style.module.scss';
import { ReferralPickerProps } from './ReferralPicker.types';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { Group, Card, ClipboardIcon } from '@zeel-dev/zeel-ui';

const ReferralPicker: FC<ReferralPickerProps> = ({ patient, onClose, modalProps }) => {
  const [selectedServiceRequestId, setSelectedServiceRequestId] = useState<string>();

  const handleSubmit = useCallback(async () => {
    onClose?.(selectedServiceRequestId);
  }, [onClose, selectedServiceRequestId]);

  return (
    <Modal
      {...modalProps}
      title='Book New Appointment'
      className={styles.base}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      backdropClose={false}
      onSubmit={handleSubmit}
      actions={[
        {
          label: 'Next',
          action: 'submit',
          disabled: !selectedServiceRequestId,
        },
      ]}>
      <h5 className={styles.label}>Select a Referral</h5>
      <Group className={styles.cardGroup} columns={1} verticalGutter fullWidth>
        {patient.service_requests?.map((sr) => (
          <Card
            key={sr.id}
            icon={<ClipboardIcon size={24} />}
            full
            selected={sr.id === selectedServiceRequestId}
            onClick={() => setSelectedServiceRequestId(sr.id)}>
            <p className={styles.referralNumber}>{sr.referral_number}</p>
            <p className={styles.line}>
              {sr.quantity.available} sessions remaining | exp. {format(parseISO(sr.expiration_date), 'MM/dd/yyyy')}
            </p>
          </Card>
        ))}
      </Group>
    </Modal>
  );
};

export default ReferralPicker;
