import { ModalPropType } from '../templates/Modal';

export type MarkRFSAsRejectedProps = ModalPropType & {
  rfsId: string;
};

export enum RFSRejectionCategoryType {
  Administrative = 'administrative',
  VAMC_Specific = 'vamc_specific',
  Clinical = 'clinical',
}
