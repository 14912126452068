import { createTheme as createThemeV5 } from '@mui/material/styles';
import { actionableGreen, error } from '@zeel-dev/ui-styles';

export const themeV5 = createThemeV5({
  palette: {
    primary: {
      main: actionableGreen,
    },
    secondary: {
      main: actionableGreen,
    },
    error: {
      main: `${error} !important`,
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});
