import React from 'react';
import { ZrefApi } from 'apis';
import { useState, useUIStore, useForm } from 'utils/hooks';
import Modal, { ModalPropType } from '../templates/Modal';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from 'components';
import styles from './style.module.scss';

export type RejectRequestForServicesProps = ModalPropType & {
  requestId: string;
  serviceRequestId?: string;
};

export default function RequestForServices({
  requestId,
  onClose,
  scrollTop,
  modalProps,
}: RejectRequestForServicesProps) {
  const { openAlert } = useUIStore();
  const { bind, form } = useForm();
  const [error, setError] = useState<string>();
  const reasons = ['Return to Provider for Clarification', 'Request Requires Further Justification', 'Poor Submission'];

  const onSubmit = async (values: any) => {
    const { reason } = values;

    try {
      await ZrefApi.rejectRequest(requestId, reason);

      openAlert({ title: `The request was rejected`, severity: 'info' });
      onClose?.(true);
    } catch (e) {
      setError('An error occured while rejecting the request');
      scrollTop();
      console.error(e);
    }
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <Modal
      {...modalProps}
      alert={error}
      title='What is the reason for rejecting this request for additional services?'
      className={styles.base}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={form.handleSubmit(onSubmit)}
      actions={[
        { label: 'Back', action: 'close' },
        { label: 'Submit Reason & Reject', action: 'submit' },
      ]}>
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}>
          <TextField {...bind('reason', { required: true })} select label='Reason' {...fieldProps}>
            {reasons.map((reason: string) => (
              <MenuItem key={reason} value={reason}>
                {reason}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...bind('description', { required: true })}
            multiline
            label='Please Further Describe the Reason for Rejecting the Request'
            {...fieldProps}
          />
        </Grid>
      </Grid>
    </Modal>
  );
}
