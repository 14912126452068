/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { AuthApi } from 'apis';
import { history, routes } from 'utils';
import { parseUrl } from 'utils/history';
import { useDispatch, useLocation, useRouteMatch, useAuth, useSelector } from 'utils/hooks';
import { AuthStore } from 'store';
import { Layout /*, TextField*/ } from 'components';
import Container from '@mui/material/Container';
// import styles from './style.module.scss';

export default function Login() {
  const { isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(false);
  const originUrl = useSelector(AuthStore.selectOriginUrl);
  const location = useLocation<{ from?: string }>();
  const codeFromRoute = useRouteMatch<{ code?: string }>()?.params?.code;
  const codeFromParam = parseUrl(location?.search)?.code; // will get code from route param (login/[code]) or else from a get param ?code=[code]
  const authCode = codeFromRoute || codeFromParam;
  const dispatch = useDispatch();
  const from = location?.state?.from;
  // const { form, bind } = useForm();

  // if already auth, kick to home
  // will check on mount and if auth state changes (to kick user to home in case of successful login)
  useEffect(() => {
    async function check() {
      setLoading(true);
      if (isAuthenticated) {
        dispatch(AuthStore.setOriginUrl(null)); // reset origin url when logging in
        history.replace(originUrl || routes.DEFAULT);
      } else if (authCode) {
        await dispatch(AuthStore.loginWithCode(authCode));
      } else {
        await dispatch(AuthStore.setOriginUrl(from));
        await AuthApi.authorize();
      }
      setLoading(false);
    }
    check();
  }, [isAuthenticated, authCode, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  // const onSubmit = async (values: any) => {
  //   const { email, password } = values;

  //   await dispatch(AuthStore.login(email, password));
  //   history.replace(from || routes.HOME);
  // }

  return (
    <Layout external title='Login' alert={from && `You must log in to view ${from}`} loading={loading}>
      <Container maxWidth='xs'>
        {/* <form onSubmit={form.handleSubmit(onSubmit)}>
          <TextField {...bind('email', { required: true, validateOnBlur: true })} type="email" label="Email" variant="outlined" fullWidth margin="normal" />
          <TextField {...bind('password', { required: true })} type="password" label="Password" variant="outlined" fullWidth margin="normal" />
          <Button className={styles.button} type="submit" variant="contained" size="large" color="primary" fullWidth>Submit</Button>
        </form> */}
      </Container>
    </Layout>
  );
}
