import { createUseStyles } from 'react-jss';
import { midnight, error, grey10 } from '@zeel-dev/ui-styles';

export const useStyles = createUseStyles({
  personaCardWrapper: {
    marginTop: '24px',
  },
  officeAddressLabel: {
    marginTop: '8px',
    fontSize: '14px',
    lineHeight: '24px',
    fontFamily: 'HKGrotesk-Bold',
    color: midnight,
  },
  officeAddress: {
    fontSize: '14px',
    lineHeight: '24px',
    fontFamily: 'HKGrotesk-Medium',
    color: midnight,
  },
  errorMessage: {
    marginTop: '8px',
    fontSize: '14px',
    lineHeight: '24px',
    fontFamily: 'HKGrotesk-Medium',
    color: error,
  },
  officeNote: {
    marginTop: '8px',
    fontSize: '14px',
    lineHeight: '24px',
    fontFamily: 'HKGrotesk-Medium',
    color: grey10,
  },
});
