import api from './api';
import * as AuthApi from './auth';
import * as MedicalApi from './medical';
import * as MemberApi from './member';
import * as PractitionerApi from './practitioner';
import * as ZCCApi from './zcc';
import * as EvaluationApi from './evaluation';
import * as TemplateApi from './template';
import * as DocumentApi from './document';
import * as AlteryxApi from './alteryx';
import * as ZrefApi from './zref';

export {
  AuthApi,
  MedicalApi,
  MemberApi,
  PractitionerApi,
  ZCCApi,
  EvaluationApi,
  TemplateApi,
  DocumentApi,
  AlteryxApi,
  ZrefApi,
};

export default api;
