import React from 'react';
import { EvaluationApi, TemplateApi } from 'apis';
import { useState, useEffect, useUIStore } from 'utils/hooks';
import { DocumentType } from 'apis/medical';
import FilesContainerV2, { FilesContainerProps } from '../FilesContainerV2';
import { QuestionnaireResponseType } from 'apis/evaluation';
import moment from 'moment';

export type EvaluationFilesProps = FilesContainerProps & {
  serviceRequestId?: string;
};

export default function EvaluationFiles(props: EvaluationFilesProps) {
  const { serviceRequestId } = props;
  const { openModal } = useUIStore();

  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<DocumentType[]>([]);

  useEffect(() => {
    async function fetchData() {
      if (serviceRequestId) {
        setLoading(true);
        try {
          const questionnaireResponses = await EvaluationApi.searchQuestionnaireResponseByServiceRequestId(
            serviceRequestId
          );

          // Filter out unique appointments because one appointment can have multiple questionnaire responses
          const filteredQuestionnaireResponses = [];
          const sessionMap = new Set<number>();
          for (const qr of questionnaireResponses) {
            if (!sessionMap.has(qr.session_number)) {
              sessionMap.add(qr.session_number);
              filteredQuestionnaireResponses.push(qr);
            }
          }
          filteredQuestionnaireResponses.sort((a, b) => a.session_number - b.session_number);

          const allDocuments: Array<any> = filteredQuestionnaireResponses.map((qr: QuestionnaireResponseType) => {
            return {
              id: qr.appointment_id,
              meta: {
                fileName: `Session #${qr.session_number} ${moment(qr.appointment_date).format('ll')}`,
                mimeType: 'application/pdf',
              },
              description: `${qr.provider_name} (NPI ${qr.provider_npi})`,
              rightDescription: 'Approved',
            };
          });

          setDocuments(allDocuments);
        } catch (error) {
          console.error('Error while fetching sr documents', error);
          setDocuments([]);
        }
      } else {
        setDocuments([]);
      }

      setLoading(false);
    }
    fetchData();
  }, [serviceRequestId]);

  return (
    <FilesContainerV2
      loading={loading}
      title='Evaluations'
      titleIcon='swatch'
      files={documents}
      noFilesText='There are no active Evaluations for this record.'
      onRowClick={(rowIndex: number) => {
        openModal({
          id: 'evaluation-pdf-view',
          props: { appointmentId: documents[rowIndex].id },
        });
      }}
      downloadDocument={TemplateApi.getEvaluationDocumentByAppointmentId}
      condensed
    />
  );
}
