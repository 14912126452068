import React from 'react';
import cn from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import styles from './style.module.scss';

export type SectionLayoutPropType = {
  title?: string;
  titleContent?: React.ReactNode;
  alert?: string;
  alertSeverity?: 'error' | 'success' | 'info' | 'warning' | undefined;
  children?: React.ReactNode;
  sideContent?: React.ReactNode;
  className?: string;
  margin?: boolean;
  loading?: boolean;
};

export default function SectionLayout({
  title,
  titleContent,
  alert,
  alertSeverity = 'error',
  children,
  sideContent,
  margin,
  className,
  loading,
}: SectionLayoutPropType) {
  return (
    <div className={cn(styles.content, { [styles['content--hasMargins']]: margin }, className)}>
      <div className={styles['content-main']}>
        {alert && (
          <Alert className={styles.alert} severity={alertSeverity}>
            {alert}
          </Alert>
        )}
        {(title || titleContent) && (
          <div className={styles.headerContainer}>
            {title && (
              <div>
                <h3 className={styles.title}>{title}</h3>
              </div>
            )}
            {titleContent && <div>{titleContent}</div>}
          </div>
        )}
        <div className={cn(styles.backdrop, { [styles['backdrop--hidden']]: !loading })}>
          <CircularProgress className={styles.spinner} color='inherit' />
        </div>
        {!loading && children && <div className={styles['content-main-children']}>{children}</div>}
      </div>
      {sideContent && <div className={styles['content-side']}>{sideContent}</div>}
    </div>
  );
}
