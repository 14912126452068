import { DocumentApi, TemplateApi } from 'apis';
import { useState, useDispatch, useUIStore } from 'utils/hooks';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { ServiceRequestType, CompositionType, DocumentType, PatientType } from 'apis/medical';
import moment from 'moment-timezone';
import { sleep, downloadFile } from 'utils/helper';
import uniq from 'lodash/uniq';

type CombineFilesProps = {
  serviceRequest?: ServiceRequestType | null;
  notes?: CompositionType[] | null;
  evaluations?: DocumentType[] | null;
  patient?: PatientType | null;
  downloadOnSuccess?: boolean;
  onError?: (error: string) => void;
};

export default function useCombineFiles({
  serviceRequest,
  notes,
  evaluations,
  patient,
  downloadOnSuccess,
  onError,
}: CombineFilesProps) {
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [combinedSoapNoteIds, setCombineSoapNoteIds] = useState<string[]>([]);
  const [combinedQuestionnaireResponseIds, setCombineQuestionnaireResponseIds] = useState<string[]>([]);
  const dispatch = useDispatch();
  const { openModal, closeModal } = useUIStore();

  const createCombinedPDF = async (
    selectedDocuments: {
      soapNoteIds: string[];
      appointmentIds: string[];
      evaluationIds: string[];
    },
    filename: string
  ) => {
    const { soapNoteIds, appointmentIds, evaluationIds } = selectedDocuments;
    if (soapNoteIds.length + appointmentIds.length === 0) {
      return await DocumentApi.mergeDocuments({
        document_ids: evaluationIds,
        filename,
      });
    }

    const responseBlob = await TemplateApi.combineSoapNotesAndEvaluations(soapNoteIds, appointmentIds);
    const combinedSoapNotesAndEvaluations = await DocumentApi.uploadDocument(responseBlob);
    if (evaluationIds.length === 0) {
      return combinedSoapNotesAndEvaluations;
    }
    return await DocumentApi.mergeDocuments({
      document_ids: [combinedSoapNotesAndEvaluations.id, ...evaluationIds],
      filename,
    });
  };

  const openCreatingModal = async (selectedDocuments: {
    soapNoteIds: string[];
    appointmentIds: string[];
    questionnaireResponseIds: string[];
    evaluationIds: string[];
  }) => {
    let filename = 'Referral';
    if (serviceRequest && serviceRequest.referral_number) filename += `_${serviceRequest.referral_number}_`;
    filename += `${moment().format('YYYY-MM-DD')}_Files`;

    dispatch(showLoading('creatingPDFBar'));
    openModal({
      id: 'creating-pdf',
      props: {
        filename,
      },
    });

    try {
      const { soapNoteIds, appointmentIds, questionnaireResponseIds, evaluationIds } = selectedDocuments;
      const file = await createCombinedPDF(selectedDocuments, filename);
      dispatch(hideLoading('creatingPDFBar'));
      await sleep(200);
      closeModal({ id: 'creating-pdf', result: null });
      openModal({
        id: 'pdf-created',
        props: { filename, fileCount: soapNoteIds.length + appointmentIds.length + evaluationIds.length },
        callback: async (updatedFilename: string | null) => {
          if (updatedFilename !== null) {
            if (downloadOnSuccess) {
              const responseBlob = await DocumentApi.downloadDocument(file?.id);
              const url = window.URL.createObjectURL(responseBlob);
              downloadFile(url, updatedFilename);
            } else {
              file.meta.filename = updatedFilename;
              setUploadedFiles([
                ...uploadedFiles,
                {
                  file,
                  soapNoteIds,
                  appointmentIds,
                  questionnaireResponseIds,
                  evaluationIds,
                },
              ]);
              setCombineSoapNoteIds(uniq([...combinedSoapNoteIds, ...soapNoteIds]));
              setCombineQuestionnaireResponseIds(
                uniq([...combinedQuestionnaireResponseIds, ...questionnaireResponseIds])
              );
            }
          }
          // try {
          //   await MedicalApi.setSoapNotesAsUploadedToHSRM(selectedDocuments);
          // } catch (e) {
          //   onError('An error occured while marking SOAP notes as uploaded to HSRM');
          //   scrollTop();
          //   console.error(e);
          // }
        },
      });
    } catch (e) {
      dispatch(hideLoading('creatingPDFBar'));
      await sleep(200);
      closeModal({ id: 'creating-pdf', result: null });
      onError?.('An error occured while merging documents');
      console.error(e);
    }
  };

  const openCombineFiles = (options?: { approvedOnly?: boolean }) => {
    const { approvedOnly = false } = options || {};
    openModal({
      id: 'combine-files',
      props: {
        serviceRequest,
        notes,
        evaluations,
        title: downloadOnSuccess
          ? `Download ${patient?.given_names?.join(' ')} ${patient?.family_name}’s Medical Records`
          : '',
        approvedOnly,
        hideBack: downloadOnSuccess,
      },
      callback: (response: any) => {
        if (response) {
          openCreatingModal(response);
        }
      },
    });
  };

  return {
    openCombineFiles,
    uploadedFiles,
    combinedSoapNoteIds,
    combinedQuestionnaireResponseIds,
  };
}
