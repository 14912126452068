import React, { useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import { AppointmentType } from 'apis/medical';
import { useForm } from 'utils/hooks';
import Grid from '@mui/material/Grid';
import { TextField, PhoneField, FieldTitle } from 'components';
import Form, { FormProps } from 'components/Form';
import styles from './style.module.scss';

export type AppointmentFormProps = FormProps & {
  appointment?: AppointmentType | null;
  showProvider?: boolean;
};

export default function PatientForm({ appointment, showProvider = true, editing, ...rest }: AppointmentFormProps) {
  const { bind, form } = useForm();

  useEffect(() => {
    form.setFields({
      id: appointment?.id,
      procedureId: appointment?.procedure_id,
      stage: appointment?.stage,
      status: appointment?.status,
      dateFormatted: moment(appointment?.date).tz('America/New_York').format('YYYY-MM-DD'),
      timeFormatted: moment(appointment?.date).tz('America/New_York').format('HH:mm z'),
      address: appointment?.address?.address1,
      unit: appointment?.address?.address2,
      city: appointment?.address?.city,
      state: appointment?.address?.state,
      zip: appointment?.address?.zip,
      duration: appointment?.duration ? `${appointment?.duration} min` : null,
      providerEmail: appointment?.provider?.email,
      providerPhone: appointment?.provider?.phone,
      providerFirstName: appointment?.provider?.fname,
      providerLastName: appointment?.provider?.lname,
      providerNpi: appointment?.provider?.npi,
    });
  }, [appointment]); // eslint-disable-line react-hooks/exhaustive-deps

  const fieldProps = useMemo(
    () => ({
      fullWidth: true,
      variant: 'outlined',
      InputProps: {
        readOnly: !editing,
      },
      InputLabelProps: {
        shrink: true,
      },
    }),
    [editing]
  );

  // TODO: support edit for dates (textfield on readonly, and datefield on edit)
  return (
    <Form title='Appointment Information' icon='calendar' form={form} {...rest}>
      <Grid item xs={6}>
        <TextField {...bind('id', { required: editing })} label='OG Appt ID' {...fieldProps} />
      </Grid>
      <Grid item xs={6}>
        <TextField {...bind('procedureId', { required: editing })} label='Procedure ID' {...fieldProps} />
      </Grid>
      <Grid item xs={6}>
        <TextField {...bind('stage', { required: editing })} label='Stage' {...fieldProps} />
      </Grid>
      <Grid item xs={6}>
        <TextField {...bind('status', { required: editing })} label='Status (NG procedure)' {...fieldProps} />
      </Grid>
      <Grid item xs={6}>
        <TextField {...bind('dateFormatted', { required: editing })} label='Date' {...fieldProps} />
      </Grid>
      <Grid item xs={6}>
        <TextField {...bind('timeFormatted', { required: editing })} label='Time' {...fieldProps} />
      </Grid>
      <Grid item xs={6}>
        <TextField {...bind('address', { required: editing })} label='Address' {...fieldProps} />
      </Grid>
      <Grid item xs={6}>
        <TextField {...bind('unit', { required: editing })} label='Unit Number (Optional)' {...fieldProps} />
      </Grid>
      <Grid item xs={6}>
        <TextField {...bind('city', { required: editing })} label='City' {...fieldProps} />
      </Grid>
      <Grid item xs={6}>
        <TextField {...bind('state', { required: editing })} label='State' {...fieldProps} />
      </Grid>
      <Grid item xs={6}>
        <TextField {...bind('zip', { required: editing })} label='Zip Code' {...fieldProps} />
      </Grid>
      <Grid item xs={6}>
        <TextField {...bind('duration', { required: editing })} label='Duration' {...fieldProps} />
      </Grid>

      {showProvider && (
        <>
          <Grid item xs={12}>
            <FieldTitle icon='user'>Provider Information</FieldTitle>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.providerTopInfo}>
              <div className={styles.avatar} />
              <p>
                {form.values?.providerFirstName} {form.values?.providerLastName}
              </p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <TextField {...bind('providerEmail', { required: editing })} label='Provider Email' {...fieldProps} />
          </Grid>
          <Grid item xs={6}>
            <PhoneField {...bind('providerPhone', { required: editing })} label='Provider Phone' {...fieldProps} />
          </Grid>
          <Grid item xs={6}>
            <TextField {...bind('providerNpi', { required: editing })} label='Provider NPI' {...fieldProps} />
          </Grid>
        </>
      )}
    </Form>
  );
}
