import React from 'react';
import { useState } from 'utils/hooks';
import ToggleSection from '../ToggleSection';

export type ToggleSectionPropType = {
  sections: Array<any>; // id is required for every sections,
  allowMultiple?: boolean; // allow multiple sections to be toggled simultaneously
  value?: any; // if controlled
  onChange?: (values?: { [id: string]: boolean }) => void; // callback when state changes
};

export default function ToggleSections({ sections = [], allowMultiple, onChange, value }: ToggleSectionPropType) {
  const isControlled = value !== null && value !== undefined;
  const initialValues: any = {};
  (sections || []).forEach((section: any) => {
    const { id, value: _value, defaultValue } = section;
    initialValues[id] = _value || defaultValue || false;
  });

  const [values, setValues] = useState(isControlled ? value : initialValues);
  const onToggle = (id: any, val: any) => {
    const newValues = {
      ...((isControlled ? value : values) || {}),
      [id]: val,
    };

    if (!allowMultiple) {
      Object.keys(newValues).forEach((key: any) => {
        if (key !== id) {
          newValues[key] = false;
        }
      });
    }

    setValues(newValues);

    if (onChange) {
      onChange(newValues);
    }
  };

  return (
    <>
      {(sections || []).map((section: any) => {
        const sectionValue = isControlled ? value?.[section.id] : values?.[section.id];
        return (
          <ToggleSection
            key={section?.id}
            {...section}
            value={sectionValue}
            onChange={(val: any) => onToggle(section.id, val)}>
            {section.children}
          </ToggleSection>
        );
      })}
    </>
  );
}
