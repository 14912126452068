import qs from 'qs';
import { useState, useEffect, useRef } from '.';
import { history } from 'utils';

export default function useZccGraphQLService(dataKey: string, dataFetcher: any, initialParameters = {}) {
  const urlParameters = qs.parse(history?.location?.search, { ignoreQueryPrefix: true });
  const [parameters, setParameters] = useState<any>({
    page: Number(urlParameters?.page) || 0,
    pageSize: Number(urlParameters?.pageSize) || 100,
    filters: urlParameters?.filters || {},
    sortBy: urlParameters?.sortBy || {},
    ...initialParameters,
  });
  const parametersRef = useRef(parameters);

  const getPageData = async () => {
    const _params = parametersRef?.current || {};

    history.push({ search: qs.stringify(_params) });
    const dataParams = { ..._params };
    Object.keys(dataParams?.filters || []).forEach((key) => {
      if (dataParams.filters[key] === '' || dataParams.filters[key]?.length === 0) {
        delete dataParams.filters[key];
      }
    });
    await dataFetcher(dataParams);
  };

  const dataFetchHandler = () => {
    getPageData();
  };

  useEffect(() => {
    parametersRef.current = parameters;
    dataFetchHandler();
  }, [parameters.page, parameters.pageSize, parameters.filters, JSON.stringify(parameters.sortBy)]);

  const setPage = (pageNumber: number) => {
    if (parameters.page !== pageNumber) {
      setParameters({
        ...parameters,
        page: pageNumber,
      });
    }
  };

  const setPageSize = (pageSize: number) => {
    if (parameters.pageSize !== pageSize) {
      setParameters({
        ...parameters,
        pageSize: pageSize,
      });
    }
  };

  const setFilters = (filters: any) => {
    setParameters({
      ...parameters,
      page: 0, // resetting page when filters change
      filters: { ...filters },
    });
  };

  const setSortBy = (sortBy: any) => {
    setParameters({
      ...parameters,
      page: 0, // resetting page when filters change
      sortBy: sortBy,
    });
  };

  return {
    parameters,
    setParameters,
    setPage,
    setPageSize,
    setFilters,
    setSortBy,
    refresh: () => dataFetchHandler(),
  };
}
