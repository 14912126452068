import React from 'react';
import { parseUrl } from 'utils/history';
import { useLocation, useRouteMatch } from 'utils/hooks';
import { Layout } from 'components';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import styles from './style.module.scss';

export default function CodeOnly() {
  const location = useLocation<{ from?: string }>();
  const codeFromRoute = useRouteMatch<{ code?: string }>()?.params?.code;

  // Get code from route param (login/[code]) or else from a get param ?code=[code]
  const codeFromParam = parseUrl(location?.search)?.code;
  const authCode = codeFromRoute || codeFromParam;

  return (
    <Layout external title='Code'>
      <Container maxWidth='xl'>
        <p>{authCode}</p>
        <br />
        {process.env.REACT_APP_ENV === 'development' && (
          <Link href={`http://localhost:3000/login/${authCode}`} className={styles.link}>
            Open on localhost
          </Link>
        )}
      </Container>
    </Layout>
  );
}
